<template>
    <div id="div_map">
        <div id="allmap"></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        mounted() {
            this.ready();
        },
        created() {
        },
        methods: {
            ready: function () {

                 this.$axios({
                    url: '/api/zzgl/GetXcrPoint',
                    method: 'get',
                 }).then(function (response) {
                     if (response.status === 200)
                     {
                         var map = new BMap.Map('allmap');
                         var point = new BMap.Point(120.954285, 31.293337);
                         map.centerAndZoom(point, 12);
                         map.addControl(new BMap.MapTypeControl());
                         map.enableScrollWheelZoom(true);//鼠标滑轮放大缩小功能
                         map.enableDoubleClickZoom(true);//地图双击放大缩小功能

                         var marker = new BMap.Marker(point);
                         map.addOverlay(marker);
                         marker.hide();
                         //let label = new BMap.Label("张浦镇政府", { position: point, offset: new BMap.Size(-25, -45) });  // 创建文本标注对象
                         //label.setStyle({
                         //    color: "red",
                         //    fontSize: "12px",
                         //    height: "20px",
                         //    lineHeight: "20px",
                         //    fontFamily: "微软雅黑"
                         //});
                         //map.addOverlay(label);

                         var icon1 = new BMap.Icon("img/巡查员坐标点图标.png", new BMap.Size(30, 40));

                         console.log(response.data.data);
                         var data = response.data.data;
                         for (var i = 0; i < data.length; i++) {
                             let point1 = new BMap.Point(parseFloat(data[i].geometry.x), parseFloat(data[i].geometry.y));

                             //坐标点
                             let marker1 = new BMap.Marker(point1, {icon:icon1});
                             map.addOverlay(marker1);

                             //文本
                             let label = new BMap.Label(data[i].title, { position: point1,offset: new BMap.Size(0, -41) } );  // 创建文本标注对象
                             label.setStyle({
                                 color: "#fff",
                                 backgroundColor: "rgba(0,0,0,0.5)",
                                 border: "0",
                                 borderRadius:'5px 5px',
                                 fontSize: "15px",
                                 height: "20px",
                                 lineHeight: "20px",
                                 transform: "translateX(-50%)",
                             });
                             map.addOverlay(label);

                         }
                     }
                 }).catch(function (error) { console.log(error) })
                
                this.sz_width_height();
            },
            sz_width_height() {
                //var w_width = document.querySelector(".ant-layout-content").innerWidth;
                var w_height = document.querySelector(".ant-layout-content").clientHeight-32;
                //document.getElementById("div_map").style.width = w_width + "px";
                document.getElementById("div_map").style.height = w_height + "px";
            }
        }
    }
</script>
<style>
    #allmap {
        width: 100%;
        height: 100%;
        margin: 0;
        font-family: "微软雅黑";
    }
</style>