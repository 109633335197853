<template>
  <div id="components-form-demo-advanced-search">
    <a-modal v-model="formAddOptions.visible" title="派单" width="800px">
      <a-spin :spinning="formAddOptions.spinning">
        <a-form :form="formAdd" :label-col="{ span:8 }" :wrapper-col="{ span:16 }" labelAlign="left">
          <a-form-item label="" style="display:none">
            <a-input v-decorator="['id']" />
          </a-form-item>

          <a-row type="flex" justify="space-between">
            <a-col :span="24">
              <a-row>
                <a-form-item lable="关联巡查问题" :label-col="{ span:8 }" :wrapper-col="{ span:16 }">
                  <a-select placeholder="请选择问题" :disabled="formAddOptions.viewMode" show-search option-filter-prop="children" :filter-option="filterOption" v-decorator="web_decorator_qymc">
                    <a-select-option v-for="items in problems_data" :key="items.value">
                      {{items.name}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between">
            <a-col :span="24">
              <a-row>
                <a-form-item label="标题" :label-col="{ span:8 }" :wrapper-col="{ span:16 }">
                  <a-input v-decorator="['title', { rules: [{ required: true, message: '请输入标题' },{max:300,message:'最大长度为300'}]}]" placeholder="工单标题" :disabled="formAddOptions.viewMode" />
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="24">
              <a-row>
                <a-form-item label="工单内容" :label-col="{ span:8 }" :wrapper-col="{ span:16 }">
                  <a-input type="textarea" :rows="5" v-decorator="['gdnr', { rules: [{ required: false, message: '请输入工单内容' },{max:3000,message:'最大长度为3000'}]}]" placeholder="业务范围" :disabled="formAddOptions.viewMode" />
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between">
            <a-col :span="24">
              <a-row>
                <a-form-item label="照片" :label-col="{ span:8 }" :wrapper-col="{ span:16 }">
                  <a-upload class='a' :disabled="formAddOptions.viewMode" name="a" :multiple="false" list-type="picture-card" :file-list="fileList" @preview="filesPreview" @change="filesChange" :customRequest="customFilesUpload">
                    <div v-if="fileList.length<1">
                      <a-icon type="plus" />
                      <div class="ant-upload-text">
                        上传照片
                      </div>
                    </div>
                  </a-upload>
                  <a-modal :visible="previewVisible" :footer="null" @cancel="filesCancelPreview">
                    <img alt="人像" style="width: 100%" :src="previewImage" />
                  </a-modal>
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between">
            <a-col :span="24">
              <a-row>
                <a-form-item label="办理人" :label-col="{ span:8 }" :wrapper-col="{ span:16 }">
                  <a-select @change="handleblrlxChange" v-decorator="['blrlx',{ rules: [{ required: true, message: '请选择办理人类型'}]},]" placeholder="" :disabled="formAddOptions.viewMode">
                    <a-select-option value="0">
                      维保公司
                    </a-select-option>
                    <a-select-option value="1">
                      网格员
                    </a-select-option>
                  </a-select>
                  <a-select style="margin-left: 20;" placeholder="请选择" :disabled="formAddOptions.viewMode" show-search option-filter-prop="children" :filter-option="filterOption" v-decorator="web_decorator_qymc">
                    <a-select-option v-for="items in blr_data" :key="items.value">
                      {{items.name}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="24">
              <a-row>
                <a-form-item label="办理意见" :label-col="{ span:8 }" :wrapper-col="{ span:16 }">
                  <a-input type="textarea" :rows="5" v-decorator="['blyj', { rules: [{ required: false, message: '请输入办理意见' },{max:3000,message:'最大长度为3000'}]}]" placeholder="业务范围" :disabled="formAddOptions.viewMode" />
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="24">
              <a-row>
                <a-form-item label="办理期限" :label-col="{ span:8 }" :wrapper-col="{ span:16 }">

                  <a-date-picker v-decorator="['blqx', config]" :disabled="formAddOptions.viewMode" />
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
      <template slot="footer">
        <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
        <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
      </template>
    </a-modal>
    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="">
            <a-select v-decorator="['teamId']" placeholder="巡查队伍" style="width:100%">
              <a-select-option v-for="item in teamData" :value="item.value">{{item.name}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="">
            <a-select v-decorator="['zzbh']" placeholder="巡查站闸" :disabled="formAddOptions.viewMode" style="width:100%">
              <a-select-option v-for="item in zzData" :value="item.value">{{item.name}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-range-picker id="xc_time" @change="onRangePickerChange" v-decorator="['updateTime']" />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-select placeholder="是否派单" v-decorator="['pdcs']" style="width:100%">
              <a-select-option value="0">
                否
              </a-select-option>
              <a-select-option value="1">
                是
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="[24,12]">
        <a-col :span="6" :style="{ textAlign: 'left' }">
          <a-button type='primary' @click="btnClick('按站闸查看')" style='margin-right: 5px'>按站闸查看</a-button>
        </a-col>
        <a-col :span="18" :style="{ textAlign: 'right' }">
          <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
          <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px',display:'none' }" @click="toggle">
            {{ expand ?'收起':'展开' }}
            <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>
    <div class="search-result-list" :style="{ marginTop: '0px' }">
      <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="20" :total="50" rowKey="id" :loading="loading" :scroll="scroll" @change="pageCurrentChange" position="bottom" tableLayout="fixed">
        <a slot="name" slot-scope="text,record" @click="openDlg(record,'view')">{{ text }}</a>
        <span slot="action" slot-scope="text, record">
          <!--  -->
          <a-button type='primary' size="small" key="派单" @click="btnClick('派单',record)" style='margin-right:5px;display:inline'>
            <!--<a-icon type="button.iconLink" />-->
            派单
          </a-button>

          <!--<a-tag v-else color="green" style="width:80px;text-align:center;cursor:pointer;">
            问题已解决
        </a-tag>-->
        </span>
        <a slot="title2" slot-scope="text,record" key="11" @click="btnClick('查看',record)">{{record.updateUser}}&nbsp;{{$jsc.formatDate_hz(record.updateTime)}}&nbsp;的巡查记录</a>
        <span slot="updateTime" slot-scope="text,record">{{$jsc.formatDate(text)}}</span>
      </a-table>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: '序号',
    dataIndex: 'rownum',
    width: 70,
    fixed: 'left',
    key: 'rownum'
  },
  {
    title: '巡查时间',
    dataIndex: 'updateTime',
    width: 120,
    key: 'updateTime',
    scopedSlots: { customRender: 'updateTime' },
    hide: true
  },
  {
    title: '巡查站闸',
    dataIndex: 'zzmc',
    width: 150,
    key: 'zzmc'
  },
  {
    title: '巡查工程',
    dataIndex: 'xmlx',
    width: 120,
    key: 'xmlx'
  },
  {
    title: '巡查队伍',
    dataIndex: 'team',
    width: 120,
    key: 'team'
  },
  {
    title: '巡查人',
    dataIndex: 'updateUser',
    width: 100,
    key: 'updateUser'
  },
  {
    title: '标题',
    dataIndex: 'title',
    width: 300,
    key: 'title',
    scopedSlots: { customRender: 'title2' }
  },
  {
    title: '检查项数',
    dataIndex: 'wtsl',
    width: 100,
    key: 'wtsl'
  },
  {
    title: '不合格项数',
    dataIndex: 'errsl',
    width: 110,
    key: 'errsl',
    scopedSlots: { customRender: 'errsl' }
  },
  {
    title: '已解决项数',
    dataIndex: 'resolvedCount',
    width: 110,
    key: 'resolvedCount'
  },
  {
    title: '派单次数',
    dataIndex: 'pdcs',
    width: 150,
    key: 'pdcs',
    hide: true
  },
  {
    title: '操作',
    key: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

const loading = true
const strWhere = 'del=0'
const action = 'add'
export default {
  data() {
    return {
      showexport: false,
      scroll: {
        y: 900,
        x: 1600
      },
      //actionList: {
      //    add: false,
      //    remove: false,
      //    edit: false
      //},
      config: {
        rules: [{ type: 'object', required: true, message: '请选择办理期限!' }]
      },
      buttons: [],
      teamData: [],
      zzData: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [],
      blr_data: [],
      blr_wbcompany_data: [],
      blr_wgy_data: [],
      problems_data: [],
      StartTime: '',
      EndTime: '',
      columns,
      loading,
      action,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      strWhere,
      expand: false,
      formAddOptions: {
        visible: false,
        submitLoading: false,
        submitVisible: true,
        viewMode: false,
        spinning: false
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
      formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
      pagination: {
        total: 0,
        pageSize: 15, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom'
      },
      web_decorator_qymc: [
        'companyorwgybh',
        { rules: [{ required: true, message: '请选择' }] }
      ]
    }
  },
  mounted() {
    console.log('////////////')
    console.log(this.$route)
    //将上面的按钮和表格内的按钮分出来
    if (this.$route.meta.buttons) {
      for (let i = 0; i < this.$route.meta.buttons.length; i++) {
        if (this.$route.meta.buttons[i].tag == 'inline') {
          this.inlineButtons.push(this.$route.meta.buttons[i])
        } else {
          this.buttons.push(this.$route.meta.buttons[i])
        }
      }
    }

    this.init()
    this.actions()
    window.onresize = () => {
      this.init()
    }
    this.bindList()
    this.bindDropDownData()
    //console.log(this.$route);
    //console.log(this.buttons);
    //console.log(this.inlineButtons);
  },
  methods: {
    bindDropDownData() {
      var _this = this
      this.$axios({
        url: '/api/zzgl/GetXcTeam',
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.teamData = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })

      this.$axios({
        url: '/api/Zzgl/getZzList',
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.zzData = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    btnClick(text, item) {
      let _this = this
      switch (text) {
        case '派单':
          this.gopd(item)
          break
        case '查看':
          this.views(item)
          break
        case '按站闸查看':
          this.$router.push({ path: 'check_record_has_problems' })
          break
        case '删除':
          this.$confirm({
            title: '操作确认',
            content: '确定要删除 ' + item.companyName + ' 吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.del(item)
            }
          })
          break
      }
    },
    views(value) {
      this.$router.push({
        path:
          'xuncha-myrecorder-details?xczzbh=' +
          value.xczzbh +
          '&probh=' +
          value.bh +
          '&titles=' +
          value.updateUser +
          ' ' +
          this.$jsc.formatDate_hz(value.updateTime) +
          ' 的巡查记录'
      })
    },
    onRangePickerChange(date, dateString) {
      console.log(dateString[0])
      console.log(dateString[1])
      this.StartTime = dateString[0]
      this.EndTime = dateString[1]
    },
    gopd(value) {
      this.$router.push('pd?xczzbh=' + value.xczzbh + '&probh=' + value.bh)
    },
    handleblrlxChange(value) {
      let blrlx = value

      if (blrlx == '0') {
        this.blr_data = this.blr_wbcompany_data
      } else if (blrlx == '1') {
        this.blr_data = this.blr_wgy_data
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    phoneCheck(rule, value, callbackFn) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!reg.test(value)) {
        callbackFn('请输入正确的手机号码')
        return
      }
      callbackFn()
    },
    SFZCheck(rule, value, callbackFn) {
      const reg = /^(\d{18}|\d{15}|\d{17}x)$/
      if (!reg.test(value)) {
        callbackFn('请输入正确的身份证号码')
        return
      }
      callbackFn()
    },
    // handleChange(value) {
    // 				console.log(value);
    // 				console.log(value.key,"__",value.label.trim());
    // 			},
    bindList: async function () {
      this.formSearch.validateFields((error, values) => {
        error = 1
        //console.log("value="+ JSON.stringify(values))
        this.strWhere = 'del=0 and isError=1'
        for (var obj in values) {
          if (values[obj]) {
            if (obj == 'teamId' || obj == 'zzbh') {
              this.strWhere += ' and ' + obj + " ='" + values[obj] + "'"
            }
            if (obj == 'updateTime') {
              this.strWhere +=
                ' and ' +
                obj +
                " between '" +
                this.StartTime +
                "' and '" +
                this.EndTime +
                "'"
            }
            if (obj == 'pdcs') {
              if (values[obj] == 0) {
                this.strWhere += ' and ' + obj + '=' + 0 + ''
              } else {
                this.strWhere += ' and ' + obj + '>' + 0 + ''
              }
            }
          }
        }
        console.log(this.strWhere)
        this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
      })

      this.loading = true

      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere)
      this.strWhere = this.strWhere.data
      await this.InitGrid()
    },
    InitGrid: function () {
      var _that = this
      _that.loading = true
      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName: 'v_zhsw_xcgl_xcProject',
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          // filesFields: 'bh',
          strWhere: _that.strWhere
        }
      })
        .then(function (response) {
          console.log(response)
          _that.loading = false
          if (response.status === 200) {
            _that.tableData = response.data.data
            _that.pagination.total = response.data.count
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    formSearchSubmit(e) {
      e.preventDefault()
      this.pagination.current = 1
      this.bindList()
    },
    formSearchReset() {
      this.formSearch.resetFields()
      this.strWhere = 'del=0'
      this.bindList()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    pageCurrentChange(pagination) {
      this.pagination.current = pagination.current
      this.bindList()
    },
    del(record) {
      this.$axios({
        url: '/api/Comon/deltag',
        method: 'post',
        data: {
          tableName: 'zzgl_wbry',
          field: 'id',
          value: record.id,
          source: 'web',
          loginid: ''
        }
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('删除成功')
              this.bindList()
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },
    openDlg: async function (record, action) {
      this.formAdd.resetFields()
      this.fileList = []
      this.formAddOptions.submitVisible = true
      this.formAddOptions.viewMode = false
      if (record !== undefined) {
        // 从后台取数据赋值
        this.formAddOptions.spinning = true
        var obj = await DesEncrypt(
          this,
          'id=zhangbiao' + record.id + 'zhangbiao'
        )
        obj = obj.data
        this.$axios
          .get(
            '/api/GetList/getListByTable?tableName=zzgl_wbry&strWhere=' +
              obj +
              '&filesFields=bh'
          )
          .then(response => {
            if (response.status == 200) {
              if (response.data.code == 0) {
                this.formAddOptions.visible = true
                var result = response.data.data
                console.log(response.data)
                setTimeout(() => {
                  result[0].yyqk = true
                  // var objArr = { qymc: result[0].qymc, lxr: result[0].lxr, yyqk: true }
                  // this.formAdd.setFieldsValue(objArr)
                  this.formAdd.setFieldsValue(result[0])
                  this.fileList = result[0].files_bh
                  this.editgetText('a')
                  this.formAddOptions.spinning = false
                }, 100)
              } else {
                this.$message.error(response.data.msg)
                this.formAddOptions.spinning = false
              }
            } else {
              // vue.prototype.$message.info('This is an error message');
            }
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error)
          })
        if (action === 'view') {
          // 预览模式
          this.formAddOptions.submitVisible = false
          this.formAddOptions.viewMode = true
        }
      } else {
        this.formAddOptions.visible = true
      }
    },
    filesCancelPreview() {
      this.previewVisible = false
    },
    async filesPreview(file) {
      //console.log(file.ext)
      // if (!file.url && !file.preview) {
      //   file.preview = await getBase64(file.originFileObj)
      // }
      // this.previewImage = file.url || file.preview
      // this.previewVisible = true
      location.href = file.url
    },
    filesChange: function (file) {
      if (file.file.status === 'removed') {
        this.fileList = file.fileList
      }
    },
    customFilesUpload(data) {
      const formData = new FormData()
      formData.append('file', data.file)
      formData.append('token', 'aiufpaidfupipiu') // 随便写一个token示例
      this.saveFile(formData)
    },
    formAddSubmit() {
      this.formAdd.validateFields((err, values) => {
        if (this.fileList.length == 0) {
          this.$message.error('请上传照片')
          return
        }
        if (!err) {
          this.formAddOptions.submitLoading = true
          values.files_bh = this.fileList
          values.blqx = values['blqx'].format('YYYY-MM-DD') //办理期限格式化
          this.$axios({
            url: '/api/zzgl/EditWbRy',
            method: 'post',
            data: values
          })
            .then(res => {
              if (res.status == 200) {
                if (res.data.code === 0) {
                  this.$message.info('保存成功')
                  this.formAddOptions.visible = false
                  this.formAddOptions.submitLoading = false
                  this.bindList()
                } else {
                  console.log(res)
                  throw new Error('服务器超时2')
                }
              } else {
                throw new Error('服务器超时2')
              }
            })
            .catch(error => {
              // 请求失败处理
              this.$message.error(error.message)
              this.formAddOptions.submitLoading = false
            })
        }
      })
    },
    saveFile(formData) {
      var that = this
      this.$axios({
        method: 'post',
        url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
        data: formData
      })
        .then(res => {
          if (res.status == 200) {
            var num = that.fileList.length
            that.fileList.push(res.data.data)
            // that.uploadgetText('a', num, res.data.data);
          } else {
            this.$message.error('服务器器超时')
          }
        })
        .catch(error => {
          that.$message.error(error.message)
          console.log(error)
        })
    },

    getcompanylist() {
      // 获取维保企业列表
      this.$axios({
        url: '/api/Zzgl/getWbCompany',
        method: 'get'
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code === 0) {
              this.blr_wbcompany_data = res.data.data
              this.blr_data = this.blr_wbcompany_data
            } else {
              console.log(res)
              throw new Error('服务器超时2')
            }
          } else {
            throw new Error('服务器超时2')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
          this.formAddOptions.submitLoading = false
        })
    },
    getwgylist() {
      // 获取网格员列表
      this.$axios({
        url: '/api/Zzgl/GetWgy',
        method: 'get'
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code === 0) {
              this.blr_wgy_data = res.data.data
            } else {
              console.log(res)
              throw new Error('服务器超时2')
            }
          } else {
            throw new Error('服务器超时2')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
          this.formAddOptions.submitLoading = false
        })
    },
    init() {
      var that = this
      size()
      this.getcompanylist()
      this.getwgylist()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector(
            '#components-form-demo-advanced-search>form'
          )
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('p')[0] - 270
          }
        })
      }
    },
    /**
     * @param {String} classname；upload的类名
     */
    editgetText(classname) {
      this.$nextTick(() => {
        var els = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span'
        )
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        //console.log(els)
        for (var i = 0, len = els.length; i < len; i++) {
          var type = els[i].textContent.split('.')[1]
          if (type !== 'png' && type !== 'jpg') {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = els[i].textContent
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + '...' + type
            }
            cel.innerText = temptext
            spanels[i].prepend(cel)
          }
        }
      })
    },
    /**
     * @param {String} classname；upload的类名
     * @param {Number} num；原有的已上传文件数量
     * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
     */
    uploadgetText(classname, num, response) {
      this.$nextTick(() => {
        //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        var tempNodeList = []
        for (var i = 0; i < spanels.length - num; i++) {
          // console.log(spanels[num+i])
          tempNodeList.push(spanels[num + i])
          for (var j = 0; j < tempNodeList.length; j++) {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = response.name
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + '...' + response.ext
            }
            cel.innerText = temptext
            tempNodeList[j].prepend(cel)
          }
        }
      })
    },
    actions() {
      //if (this.inlineButtons.length == 0) {
      //    columns.pop();
      //}
    }
  }
}

//function getBase64(file) {
//    return new Promise((resolve, reject) => {
//        const reader = new FileReader()
//        reader.readAsDataURL(file)
//        reader.onload = () => resolve(reader.result)
//        reader.onerror = error => reject(error)
//    })
//}
</script>
<style>
.ant-layout-footer {
  display: none;
}

#components-form-demo-advanced-search {
  background: white;
  box-sizing: border-box;
  padding: 20px 20px 0;
}

.ant-form-item {
  margin-bottom: 12px;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: !important;
}

/*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 1px 1px 1px 1px !important;
}
/*时间搜索框调整*/
.ant-calendar-picker {
  width: 100%;
}
</style>