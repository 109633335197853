<template>
  <div id="components-form-demo-advanced-search">
    <a-modal v-model="formAddOptions.visible" :title="opration" width="800px">
        <a-spin :spinning="formAddOptions.spinning">
            <a-form :form="formAdd"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 19 }"
                    labelAlign="right">
                <a-form-item label="" style="display: none">
                    <a-input v-decorator="['id']" />
                    <a-input v-decorator="['bh']" />
                    <!--<a-input v-decorator="['hysj']" />-->
                </a-form-item>

                <a-row type="flex" justify="space-around">
                    <a-col :span="24">
                        <a-form-item :label-col="{ span: 3 }"
                                     :wrapper-col="{ span: 21 }"
                                     label="会议主题">
                            <a-input v-decorator="[
                    'title',
                    { rules: [{ required: true, message: '请输入会议主题' }] },
                  ]"
                                     placeholder="请输入会议主题"
                                     :maxLength="50"
                                     :disabled="formAddOptions.viewMode" />
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="24">
                        <a-form-item :label-col="{ span: 3 }"
                                     :wrapper-col="{ span: 21 }"
                                     label="会议地点">
                            <a-input v-decorator="[
                    'hydd',
                    { rules: [{ required: false, message: '请输入会议地点' }] },
                  ]"
                                     placeholder="请输入会议地点"
                                     :maxLength="50"
                                     :disabled="formAddOptions.viewMode" />
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="24">
                        <a-form-item :label-col="{ span: 3 }"
                                     :wrapper-col="{ span: 21 }"
                                     label="会议时间">
                            <a-date-picker v-decorator="[
                    'hysj',
                    { rules: [{ required: true, message: '请选择会议时间' }] },
                  ]"
                                           style="width: 100%"
                                           :disabled="formAddOptions.viewMode" />
                        </a-form-item>
                    </a-col>
                </a-row>

                <!--<a-row>
          <a-col :span="24">
            <a-form-item
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
              label="与会人员"
            >
              <a-select
                mode="multiple"
                :disabled="formAddOptions.viewMode"
                style="width: 100%"
                placeholder="请选择与会人员"
                v-decorator="[
                  'yhryLoginid',
                  { rules: [{ required: true, message: '请选择与会人员' }] },
                ]"
              >
                <a-select-option v-for="item in yhryData" :value="item.value"
                  >{{ item.name }}1</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>-->
                <!-- <a-row  :span="24">
                      <a-col>
                          <a-form-item label="会议内容"  :label-col="{ span:3}" :wrapper-col="{ span:21 }">
                              <a-textarea type="textarea"  style="min-height:150px;" v-decorator="['hynr', { rules: [{ required: true, message: '请输入会议内容' }]}]" placeholder="请输入会议内容" :maxLength="500" :disabled="formAddOptions.viewMode"/>
                          </a-form-item>
                      </a-col>
                  </a-row> -->

                <a-row type="flex" justify="space-around">
                    <a-col :span="24">
                        <a-form-item :label-col="{ span: 3 }"
                                     :wrapper-col="{ span: 21 }"
                                     label="会议简介">
                            <a-textarea v-decorator="['hynr']" :rows="4"
                                        placeholder="会议简介"
                                        :maxLength="500"
                                        :disabled="formAddOptions.viewMode" />
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>

            <a-modal :visible="previewVisible" :footer="null" @cancel="filesCancelPreview">
                <img alt="附件" style="width: 120px;height:120px;" :src="previewImage" />
            </a-modal>

            <a-divider orientation="left">附件</a-divider>
            <a-upload class='a'
                    :disabled="formAddOptions.viewMode" name="a"
                    accept=".doc,.docx,.pdf"
                    :multiple="true"
                    list-type="picture-card"
                    :file-list="fileList"
                    @preview="filesPreview"
                    @change="filesChange"
                    :customRequest="customFilesUpload">
                <div v-if="fileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                        上传文件
                    </div>
                </div>
            </a-upload>

        </a-spin>
      <template slot="footer">
        <a-button
          key="back"
          @click="
            formAddOptions.visible = false;
            formAddOptions.dateVisible = false;
          "
          >关闭</a-button
        >
        <a-button
          key="submit"
          type="primary"
          :loading="formAddOptions.submitLoading"
          @click="formAddSubmit"
          v-if="formAddOptions.submitVisible"
          >确定</a-button
        >
      </template>
    </a-modal>

    <a-form
      :form="formSearch"
      @submit="formSearchSubmit"
      :label-col="{ span: 0 }"
      :wrapper-col="{ span: 24 }"
    >
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="">
            <a-input
              v-decorator="['title']"
              placeholder="请输入会议标题"
              :maxLength="40"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-input
              v-decorator="['hynr']"
              placeholder="请输入会议内容1"
              :maxLength="40"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-input
              v-decorator="['hydd']"
              placeholder="请输入会议地点"
              :maxLength="40"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-range-picker
              :placeholder="['开始时间', '结束时间']"
              @change="onRangePickerChange"
              v-decorator="['hysj']"
              style="width: 100%"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="[24, 12]">
        <a-col :span="18" :style="{ textAlign: 'left' }">
          <a-button
            type="primary"
            v-for="button in buttons"
            @click="btnClick(button.title)"
            style="margin-right: 5px"
            :key="button.title"
            >{{ button.title }}</a-button
          >
        </a-col>
        <a-col :span="6" :style="{ textAlign: 'right' }">
          <a-button
            type="primary"
            :style="{ width: '48px' }"
            html-type="submit"
            icon="search"
          ></a-button>
          <a-button
            type=""
            :style="{ width: '48px', marginLeft: '8px' }"
            @click="formSearchReset"
            icon="reload"
          ></a-button>
          <!--<a :style="{ marginLeft: '8px', fontSize: '12px',display:'none' }" @click="toggle">
                        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                    </a>-->
        </a-col>
      </a-row>
    </a-form>

    <div class="search-result-list" :style="{ marginTop: '0px' }">
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :pageSize="10"
        :total="50"
        rowKey="id"
        style="heigth: 20px"
        :loading="loading"
        :scroll="scroll"
        @change="pageCurrentChange"
        position="bottom"
        tableLayout="fixed"
      >
        <span
          style="
            display: -webkit-box;
            overflow: hidden;
            white-space: normal;
            text-overflow: ellipsis;
            word-wrap: break-word;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          "
          slot="hynrs"
          slot-scope="hynr"
          v-html="hynr"
        ></span>
        <span slot="action" slot-scope="text, record">
          <a-button
            :type="button.className"
            size="small"
            v-for="button in inlineButtons"
            :key="button.title"
            @click="btnClick(button.title, record)"
            style="margin-right: 5px"
            ><a-icon :type="button.iconLink"
          /></a-button>
        </span>
        <span slot="titles" slot-scope="text, record">
          <a @click="btnClick('查看', record)">{{ text }}</a>
        </span>
        <span slot="hysj" slot-scope="text, record">
          <span>{{ $jsc.formatDate(text) }}</span>
        </span>
        <span slot="hynr" slot-scope="text, record">
          <span v-if="text.length > 15"
            ><a :title="text" style="color: rgba(0, 0, 0, 0.65)">{{
              $jsc.m_substring(text)
            }}</a></span
          >
          <span v-else>{{ text }}</span>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
//import { quillEditor } from "vue-quill-editor"; //调用编辑器
//import "quill/dist/quill.core.css";
//import "quill/dist/quill.snow.css";
//import "quill/dist/quill.bubble.css";
const columns = [
  {
    title: "序号",
    dataIndex: "rownum",
    width: 70,
    key: "rownum",
  },
  {
    title: "会议标题",
    dataIndex: "title",
    width: 200,
    key: "title",
    scopedSlots: { customRender: "titles" },
  },
  {
    title: "会议内容",
    dataIndex: "hynr",
    width: 300,
    key: "hynr",
    scopedSlots: { customRender: "hynrs" },
  },
  {
    title: "会议地点",
    dataIndex: "hydd",
    width: 300,
    key: "hydd",
  },
  {
    title: "会议时间日期",
    dataIndex: "hysj",
    width: 180,
    key: "hysj",
    scopedSlots: { customRender: "hysj" },
  },
  {
    title: "与会人员",
    dataIndex: "yhry",
    width: 300,
    key: "yhry",
  },
  {
    title: "操作",
    key: "action",
    fixed: "right",
    width: 120,
    height: 30,
    scopedSlots: { customRender: "action" },
  },
];

const loading = true;
const strWhere = "del=0";
const action = "add";
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["link", "image", "video"],
  ["clean"], // remove formatting button
];
export default {
  //components: {
  //  quillEditor,
  //},

  data() {
    return {
      showexport: false,
      scroll: {
        x: 900,
      },
      opration: "",
      townData: [],
      zzData: [],
      xmlxData: [],
      qzLoginid: "",
      //actionList: {
      //    add: false,
      //    remove: false,
      //    edit: false
      //},
      StartTime: "",
      workContent: "", //富文本
      EndTime: "",
      yhryData: [],
      teamData: [],
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [{ title: "123" }, { title: "234" }],
      columns,
      loading,
        action,
        imageUrl: '',
        imagePath: '',
      previewVisible: false,
      previewImage: "",
      fileList: [],
      strWhere,
      expand: false,
      formAddOptions: {
        visible: false,
        submitLoading: false,
        submitVisible: true,
        viewMode: false,
        spinning: false,
        dateVisible: false,
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: "advanced_search" }),
      formAdd: this.$form.createForm(this, { name: "advanced_search11" }),

      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: "bottom",
      },
      //editorOption: {
      //  placeholder: "",
      //  theme: "snow", // or 'bubble'
      //  modules: {
      //    toolbar: {
      //      container: toolbarOptions, // 工具栏
      //      handlers: {
      //        image: function (value) {
      //          if (value) {
      //            document.querySelector("#uploadBotton").click();
      //          } else {
      //            this.quill.format("image", false);
      //          }
      //        },
      //      },
      //    },
      //  },
      //},
    };
  },
  mounted() {
    //将上面的按钮和表格内的按钮分出来
    console.log(this.$route.meta.buttons);
    for (let i = 0; i < this.$route.meta.buttons.length; i++) {
      if (this.$route.meta.buttons[i].tag == "inline") {
        this.inlineButtons.push(this.$route.meta.buttons[i]);
      } else {
        this.buttons.push(this.$route.meta.buttons[i]);
      }
    }
    this.init();
    this.actions();
    window.onresize = () => {
      this.init();
    };
    //this.bindYhry();
    this.bindList();
  },
  created() {},
  methods: {
    //bindYhry() {
    //  var _this = this;
    //  this.$axios({
    //    url: "/api/fxjc/GetYhryList",
    //    method: "get",
    //  })
    //    .then(function (response) {
    //      if (response.status === 200) {
    //        _this.yhryData = response.data.data;
    //      }
    //    })
    //    .catch(function (error) {
    //      console.log(error);
    //    });
    //},
    btnClick(text, item) {
      let _this = this;
      this.opration = text;
      switch (text) {
        case "新增":
          this.openDlg();
          break;
        case "编辑":
          this.openDlg(item);
          break;
        case "查看":
          this.openDlg(item, "view");
          break;
        case "删除":
          this.$confirm({
            title: "操作确认",
            content: "确定要删除?",
            okText: "确认",
            cancelText: "取消",
            onOk() {
              _this.del(item);
            },
          });
          break;
      }
    },
    bindList: async function () {
      this.formSearch.validateFields((error, values) => {
        error = 1;
        // console.log(values)
        this.strWhere = "del=0 ";
        for (var obj in values) {
          if (values[obj]) {
            if (obj == "hysj") {
              this.strWhere +=
                " and " +
                obj +
                " between '" +
                this.StartTime +
                "' and '" +
                this.EndTime +
                "'";
            } else {
              this.strWhere += " and " + obj + " like '%" + values[obj] + "%'";
            }
          }
        }
        this.strWhere = this.strWhere.replace(/'/g, "zhangbiao");
        console.log(this.strWhere);
      });
      this.loading = true;

      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere);
      this.strWhere = this.strWhere.data;
      await this.InitGrid();
    },

    //uploadSuccess(res) {
    //  //富文本
    //  console.log(res);
    //  // res为图片服务器返回的数据
    //  // 获取富文本组件实例
    //  let quill = this.$refs.myQuillEditor.quill;
    //  // 如果上传成功
    //  if (res.file.status === "done") {
    //    // 获取光标所在位置
    //    let length = quill.getSelection().index;
    //    // 插入图片  res.info为服务器返回的图片地址
    //    quill.insertEmbed(length, "image", res.file.response.data.url);
    //    // 调整光标到最后
    //    quill.setSelection(length + 1);
    //  } else {
    //    this.$message.error("图片插入失败");
    //  }
    //  // loading动画消失
    //  this.quillUpdateImg = false;
    //},
    InitGrid: function () {
      var _that = this;
      _that.loading = true;
      console.log(_that.strWhere);
      this.$axios({
        url: "/api/GetList/getListByTable",
        method: "get",
        params: {
          tableName: "v_fxjc_rcgl_hytzInfo",
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          filesFields: "",
          strWhere: _that.strWhere,
        },
      })
        .then(function (response) {
          console.log(response);
          _that.loading = false;
          if (response.status === 200) {
            _that.tableData = response.data.data;
            _that.pagination.total = response.data.count;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //onEditorChange({ quill, html, text }) {
    //  console.log("editor change!", quill, html, text);
    //  this.workContent = html;
    //},
    //beforeUpload() {
    //  // 显示loading动画
    //  this.quillUpdateImg = true; //富文本
    //},
    //onEditorReady(quill) {
    //  console.log("editor ready!", quill); //富文本
    //},
    onRangePickerChange(date, dateString) {
      this.StartTime = dateString[0];
      this.EndTime = dateString[1];
    },
    onShowSizeChange(current, pageSize) {
      console.log(current, pageSize);
      return;
    },
    formSearchSubmit(e) {
      e.preventDefault();
      this.pagination.current = 1;
      this.bindList();
    },
    formSearchReset() {
      this.formSearch.resetFields();
      this.strWhere = "del=0";
      this.bindList();
    },
    toggle() {
      this.expand = !this.expand;
      this.init();
    },
    pageCurrentChange(pagination) {
      console.log(pagination);
      //return;
      this.pagination.current = pagination.current;
      this.bindList();
    },
    del(record) {
      this.$axios({
        url: "/api/fxjc/DelHytzInfo?bh=" + record.bh,
        method: "get",
      })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info("删除成功");
              this.bindList();
            } else {
              throw new Error("服务器超时");
            }
          } else {
            throw new Error("服务器超时");
          }
        })
        .catch((error) => {
          // 请求失败处理
          this.$message.error(error.message);
        });
    },
    openDlg: async function (record, action) {
      var _this = this;
      this.formAdd.resetFields();
      this.fileList = [];
      this.formAddOptions.submitVisible = true;
      this.formAddOptions.viewMode = false;
      if (record !== undefined) {
        // 从后台取数据赋值
        this.formAddOptions.spinning = true;
        var obj = await this.$jsc.DesEncrypt(
          this,
          "id=zhangbiao" + record.id + "zhangbiao"
        );
        obj = obj.data;
        this.$axios
          .get(
            "/api/GetList/getListByTable?tableName=v_fxjc_rcgl_hytzInfo&filesFields=bh&strWhere=" + obj + ""
          )
          .then((response) => {
            if (response.status == 200) {
              if (response.data.code == 0) {
                this.formAddOptions.visible = true;
                var result = response.data.data;
                console.log(response.data);

                this.workContent = result[0].hynr;
                setTimeout(() => {
                  this.formAdd.setFieldsValue(result[0]);
                  this.formAdd.setFieldsValue({
                    yhryLoginid: result[0].yhryLoginid.split(","),
                    });
                    this.fileList = result[0].files_bh;
                  this.editgetText("a");
                  this.formAddOptions.spinning = false;
                }, 100);
              } else {
                this.$message.error(response.data.msg);
                this.formAddOptions.spinning = false;
              }
            } else {
              // vue.prototype.$message.info('This is an error message');
            }
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error);
          });
        if (action === "view") {
          // 预览模式
          this.formAddOptions.submitVisible = false;
          this.formAddOptions.viewMode = true;
        }
      } else {
        this.formAddOptions.visible = true;
      }
    },
    formAddSubmit() {
      this.formAdd.validateFields((err, values) => {
        //values.hynr = this.workContent;
        if (!err) {
          var yhry = "";
          //for (var i = 0; i < values["yhryLoginid"].length; i++) {
          //  yhry += values["yhryLoginid"][i] + ",";
          //}
          //if (yhry.length > 0) yhry = yhry.substring(0, yhry.length - 1);
          //values["yhryLoginid"] = yhry;
            values.files = this.fileList;
          this.formAddOptions.submitLoading = true;
            this.$axios({
                url: "/api/fxjc/EditHytzInfo",
                method: "post",
                data: values,
            })
                .then((res) => {
                    console.log(res);
                    if (res.status == 200) {
                if (res.data.code === 0) {
                  this.$message.info("保存成功");
                  this.formAddOptions.visible = false;
                  this.formAddOptions.submitLoading = false;
                  this.formAddOptions.dateVisible = false;
                  this.bindList();
                } else {
                  
                  this.$message.info(res.data.msg);
                  //throw new Error(res.data.msg);
                }
              } else {
                throw new Error("服务器超时2");
              }
            })
            .catch((error) => {
              // 请求失败处理
              this.$message.error(error.message);
              this.formAddOptions.submitLoading = false;
            });
        }
      });
    },

    filesCancelPreview() {
      this.previewVisible = false;
    },
    async filesPreview(file) {
      //console.log(file.ext)
      // if (!file.url && !file.preview) {
      //   file.preview = await getBase64(file.originFileObj)
      // }
      // this.previewImage = file.url || file.preview
      // this.previewVisible = true
      location.href = file.url;
    },
    filesChange: function (file) {
      if (file.file.status === "removed") {
        this.fileList = file.fileList;
      }
    },

      customFilesUpload(data) {
        const formData = new FormData()
        formData.append('file', data.file)
        formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
        this.saveFile(formData)
        //this.$jsc.picCompress(data.file, 600, this.saveFile)
    },
      saveFile(formData) {
          console.log(formData);
      this.$axios({
        method: "post",
        url: this.$axios.defaults.fileURL + "/api/FileSet/Upload",
        data: formData,
      })
          .then((res) => {
          if (res.status == 200) {
              var num = this.fileList.length
              this.fileList.push(res.data.data)
              this.uploadgetText('a', num, res.data.data);
          } else {
            throw new Error("服务器器超时");
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
          console.log(error);
        });
    },
    init() {
      var that = this;
      size();
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight;
        that.$nextTick(() => {
          var el = document.querySelector(
            "#components-form-demo-advanced-search>form"
          );
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split("p")[0] - 270;
          }
        });
      }
    },
    /**
     * @param {String} classname；upload的类名
     */
    editgetText(classname) {
        this.$nextTick(() => {
            var els = document.querySelectorAll(
                "." +
                classname +
                " .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span"
            );
            var spanels = document.querySelectorAll(
                "." +
                classname +
                " .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info"
            );
            //console.log(els)
            for (var i = 0, len = els.length; i < len; i++) {
                var type = els[i].textContent.split(".")[1];
          if (type !== "png" && type !== "jpg") {
            var cel = document.createElement("span");
            cel.style.display = "block";
            var temptext = els[i].textContent;
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + "..." + type;
            }
            cel.innerText = temptext;
            spanels[i].prepend(cel);
          }
        }
      });
    },
    /**
     * @param {String} classname；upload的类名
     * @param {Number} num；原有的已上传文件数量
     * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
     */
      uploadgetText(classname, num, response) {
          if (response.ext == ".jpg" || response.ext == ".png" || response.ext == ".gif" || response.ext == ".jpeg") {
              return false;
          }
      this.$nextTick(() => {
        //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
        var spanels = document.querySelectorAll(
          "." +
            classname +
            " .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info"
        );
        var tempNodeList = [];
        for (var i = 0; i < spanels.length - num; i++) {
          // console.log(spanels[num+i])
          tempNodeList.push(spanels[num + i]);
          for (var j = 0; j < tempNodeList.length; j++) {
            var cel = document.createElement("span");
            cel.style.display = "block";
            var temptext = response.name;
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + "..." + response.ext;
            }
            cel.innerText = temptext;
            tempNodeList[j].prepend(cel);
          }
        }
      });
    },
    actions() {
      if (this.inlineButtons.length == 0) {
        columns.pop();
      }
    },
  },
};

//function getBase64(file) {
//    return new Promise((resolve, reject) => {
//        const reader = new FileReader()
//        reader.readAsDataURL(file)
//        reader.onload = () => resolve(reader.result)
//        reader.onerror = error => reject(error)
//    })
//}
</script>
<style>
.ant-layout-footer {
  display: none;
}

#components-form-demo-advanced-search {
  background: white;
  box-sizing: border-box;
  padding: 20px 20px 0;
}

.ant-form-item {
  margin-bottom: 12px;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: !important;
}

/*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 1px 1px 1px 1px !important;
}
</style>