<template>
    <div class="wh-100p" ref="rootBox">
        <!-- 搜索 -->
        <a-space size="middle">
            <a-select  placeholder="区镇" style="width: 150px !important;"  v-model="cond.town">
                <a-select-option v-for="td in townData" :key="td.value" v-model="td.name">
                    {{ td.name }}
                </a-select-option>
            </a-select>
            <a-select  placeholder="预案等级" style="width: 150px !important;" v-model="cond.level">
                <a-select-option value="一级预案">一级预案</a-select-option>
                <a-select-option value="二级预案">二级预案</a-select-option>
                <a-select-option value="三级预案">三级预案</a-select-option>
            </a-select>
            <a-space>
                <a-button type="primary" icon="search" @click="onSearch" >搜索</a-button>
                <a-button icon="redo" @click="onReset">重置</a-button>
            </a-space>
        </a-space>

        <!-- 按钮 -->
        <div style="margin: 20px 0 10px;">
            <a-button type="primary" icon="plus" @click="onSet">新增</a-button>
        </div>
        
        <!-- 表格 -->
        <a-table
            rowKey="key"
            :columns="columns" 
            :data-source="dataSource"
            :pagination = "pagination"
            :loading="loading"
            :scroll="scroll"
            @change="onChangePage">
            <template slot="action" slot-scope="record">
                <a-space>
                    <a-button icon="edit" @click="onSet(record)"></a-button>
                    <a-button type="danger" icon="delete" @click="onDel(record)"></a-button>
                </a-space>
            </template>
        </a-table>


        <!-- 新增或编辑 -->
        <a-modal
            :title="setNumber ? '编辑' : '新增'"
            :visible="showModal"
            :confirm-loading="confirmLoading"
            @ok="onSubmit"
            @cancel="showModal=false">

            <a-form :form="form" :label-col="{ span: 5, offset: 3 }" :wrapper-col="{ span: 12 }" labelAlign="left">

                <a-form-item label="区镇">
                    <a-select v-decorator="['town',{ rules: [{ required: true, message: '请选择区镇'}]},]" placeholder="请选择区镇">
                        <a-select-option v-for="td in townData" :key="td.value" :value="td.name">
                            {{ td.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>


                <a-form-item label="巡查次数">
                    <a-input-number :min="1"  v-decorator="['count', { rules: [{ required: true, message: '请输入巡查次数' }]}]" placeholder="请输入巡查次数"></a-input-number>
                </a-form-item>

                <a-form-item label="巡查单位">
                    <a-select v-decorator="['unit',{ rules: [{ required: true, message: '请选择巡查单位'}]},]" placeholder="请选择巡查单位">
                        <a-select-option value="天">天</a-select-option>
                        <a-select-option value="周">周</a-select-option>
                        <a-select-option value="月">月</a-select-option>
                    </a-select>
                </a-form-item>


                <a-form-item label="预案等级" >
                    <a-select v-decorator="['level', { rules: [{ required: true, message: '请输入预案等级'}]},]" placeholder="请输入预案等级">
                        <a-select-option value="一级预案">一级预案</a-select-option>
                        <a-select-option value="二级预案">二级预案</a-select-option>
                        <a-select-option value="三级预案">三级预案</a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="预案说明" >
                    <a-input type="textarea" v-decorator="['explain', { rules: [{ required: true, message: '请输入预案说明'}]},]" placeholder="请输入预案说明"></a-input>
                </a-form-item>

            </a-form>

        </a-modal>
    </div>
</template>

<script>

    let columns = [
        {   
            title: '序号',
            dataIndex: 'key',
            width: '10%'
        },
        {
            title: '区镇',
            dataIndex: 'townName',
            width: '15%'
        },
        {
            title: '巡查频率',
            dataIndex: 'rate',
            width: '15%'
        },
        {
            title: '预案等级',
            dataIndex: 'yadj',
            width: '15%'
        },
        {
            title: '预案说明',
            dataIndex: 'yasm',
            width: '30%',
            ellipsis: true
        },
        {
            title: '操作',
            key: 'action',
            scopedSlots: { customRender: 'action' }
        },
    ]

    export default {
        data () {
            return {
                cond: {},
                columns: Object.freeze(columns),
                dataSource: [],
                pagination: {
                    total: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条`
                },
                loading: false,
                scroll: {
                    x: 0, 
                    y: 0
                },
                setNumber: '',
                showModal: false,
                confirmLoading: false,
                form: this.$form.createForm(this, { name: 'coordinated' }),
                townData: []
            }
        },
        computed: {
            hasCond () {
                return !!Object.keys(this.cond).length
            }
        },
        methods: {
            onSearch () {
                if (this.hasCond) {
                    this.pagination.current = 1
                    this.findTableData() 
                } 
            },
            onReset () {
                if (this.hasCond) { 
                    this.cond = {}
                    this.pagination.current = 1
                    this.findTableData()
                }
            },
            async transformSql (obj) {

                let strWhere = 'del = 0'
                
                if (Object.keys(obj).length) {
                    let sqlField = {
                        level: 'yadj',
                        town: 'townName'
                    }

                    Object.keys(obj).forEach(key => {
                        if (obj[key]) {
                            strWhere += " and " + sqlField[key] + " like '%" + obj[key] + "%'"
                        }
                    })

                    strWhere.replace(/'/g, 'zhangbiao')
                }

                strWhere = await this.$jsc.DesEncrypt(this, strWhere)
                return strWhere.data
            },
            onSet (record) {
                this.form.resetFields()
                this.showModal = true
                this.setNumber = record ? record.bh : ''

                if (this.setNumber) {
                    this.$nextTick(() => {
                        let { yadj, yasm, xcpl, xcpldw, townName } = record
                        this.form.setFieldsValue({
                            level: yadj,
                            explain: yasm,
                            count: xcpl,
                            unit: xcpldw,
                            town: townName
                        })
                    })
                }

                this.findTableData()
            },
            onDel (record) {
                this.$confirm({
                    title: '操作确认',
                    content: "确定要删除?",
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                        this.$axios({
                            url: '/api/Comon/deltag',
                            method: 'post',
                            data: {
                                tableName: 'slgcxczg_yasetup',
                                field: 'id',
                                value: record.id,
                                source: 'web',
                                loginid: ''
                            }
                        }).then(({ data: { code }}) => {
                            if (code === 0) {
                                this.findTableData()
                                return this.$message.success('提交成功')
                            }
                            this.$message.success('提交失败')
                        }).catch(err => err)
                    }
                })
            },
            onSubmit () {
                this.form.validateFields((err, fields) => {
                    if(err) return

                    this.confirmLoading = true

                    let { level, explain, count, town, unit } = fields
                    town = this.townData.find(td => td.name === town)

                    this.$axios({
                        url: '/api/rcxc/yasetup_add',
                        method: 'post',
                        data: {
                            bh: this.setNumber,
                            yadj: level,
                            yasm: explain,
                            xcpl: count,
                            xcpldw: unit,
                            townName: town.name,
                            townid: town.value
                        }
                    }).then(({ data: { code } }) => {
                        if (code === 0) {
                            this.confirmLoading = this.showModal = false
                            this.findTableData()
                            return this.$message.success('提交成功')
                        }

                        this.$message.error('提交失败')
                        
                    }).catch(err => err)
                })
            },
            onChangePage ({ current }) {
                this.pagination.current = current
                this.findTableData()
            },
            async findTableData () {
                this.loading = true
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'slgcxczg_yasetup',
                        page: this.pagination.current,
                        limit: 10,
                        filesFields: '',
                        strWhere: await this.transformSql(this.cond)
                    }
                }).then(res => {
                    let { code, count, data } = res.data
                    if (code === 0) {
                        this.pagination.total = count

                        data.forEach((rd, i) => {
                            rd.key = ++i + (this.pagination.current * 10 - 10)
                            rd.rate = `${rd.xcpl}次/1${rd.xcpldw}`
                        })
                        this.dataSource =  data
                        this.onAdjust()
                    }
                }).catch(err => err).finally (() => {
                    this.loading = false
                })
                
            },
            findTownData () {
                this.$axios({
                    url: '/api/Zzgl/GetTown',
                    method: 'get',
                }).then(({ data: { code, data : resData } }) => { 
                    if (code === 0) {
                        this.townData = resData
                    }
                }).catch(err => err)

            },
            onAdjust () {
                this.$nextTick(() => {
                    this.scroll.y = this.$refs.rootBox.offsetHeight - 205
                })
            }
        },
        created () {
            this.findTableData()
            this.findTownData()

            window.onresize =  (() => {
                let timer
                return () => {
                    timer && clearTimeout(timer)
                    timer = setTimeout( () => {
                        this.onAdjust()
                    }, 100)
                }
            })()
        },
        beforeDestroy () {
            window.onresize = null
        }
    }
</script>

<style lang="less" scoped>

</style>