<template>
    
    <div style="width:800px;">
        <a-button type="" @click="()=>$router.go(-1)">
            返回
        </a-button>
        <a-button type="primary" style="margin-left:8px;" @click="dfword">
            导出WORD
        </a-button>
        <a-descriptions title="老城区河道抓拍信息" :column="2" bordered class="bjxxViewTitle">
            <a-descriptions-item label="报警时间" style="width:300px;">
                {{bjxx.happenTime}}
            </a-descriptions-item>
            <a-descriptions-item label="报警类型">
                <span v-if="bjxx.alarmType=='4'">投垃圾</span>
                <span v-if="bjxx.alarmType=='402'">漂浮物</span>
                <span v-if="bjxx.alarmType=='403'">拖把清洗</span>
                <span v-if="bjxx.alarmType=='404'">捕钓</span>
                <span v-if="bjxx.alarmType=='405'">侵占岸线</span>
            </a-descriptions-item>
            <a-descriptions-item label="抓拍点">
                {{bjxx.devName}}
            </a-descriptions-item>
            <a-descriptions-item label="办理状态">
                <template v-if="bjxx.pdstate==10">
                    <span>已办结</span>
                </template>
                <template v-else>
                    <span v-if="bjxx.pdcs>0">已派单</span>
                    <span v-else-if="bjxx.pdcs==0">未派单</span>
                </template>

            </a-descriptions-item>
            <a-descriptions-item label="照片" :span="2">
                <img :src="$axios.defaults.fileURL+bjxx.snapshoot" style="width:630px; height:450px;">
            </a-descriptions-item>
            <template>
                <a-descriptions-item label="处理单位">
                    {{bjxx.clr}}
                </a-descriptions-item>
                <a-descriptions-item label="处理时间">
                    {{bjxx.clsj}}
                </a-descriptions-item>
                <a-descriptions-item label="处理内容" :span="2">
                    {{bjxx.clnr}}
                </a-descriptions-item>
                <a-descriptions-item label="处理后照片" :span="2">
                    <template v-for="(item,index) in bjxx.files_clfjbh">
                        <img :src="$axios.defaults.fileURL+item.path" style="width:630px; height:450px;"><br/>
                    </template>
                </a-descriptions-item>
            </template>
        </a-descriptions>
    </div>
</template>
<script>

    export default {
        data() {
            return {
                TableListSearchModel: {
                    pageIndex: 1,
                    pageSize: 1,
                    fields: "*",
                    where: {
                    }
                },
                bjxx: {},
                fileList: [{
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: 'http://pt.zhangpu-ks.cn/upload/ljfl/img/2021/5/25/f16fcdee-bb7b-42ca-995d-56d6ebeb36ab.jpg',
                }],
                previewVisible: false,
                previewImage: '',
                query: {
                    infobh: this.$route.query.infobh,
                }
            }
        },

        mounted() {

        },
        created() {
            //console.log(this.dataOptions);
            this.initData();
        },
        methods: {
            dfword() {
                this.$axios({
                    url: "/api/hdsp/shl_hdxw?bh=" + this.query.infobh,
                    method: "get",
                }).then((res) => {
                    console.log(this.$axios.defaults.baseURL + res.data.data);
                    location.href = this.$axios.defaults.baseURL + res.data.data;
                }).catch((res) => {

                });
            },
            filesPreview(file) {
                windows.open(file.url);
            },
            initData() {
                this.TableListSearchModel.where.bh = this.query.infobh;
                this.$axios({
                    url: "/api/hdsp/getList_hdsp_bjxx",
                    method: "post",
                    data: this.TableListSearchModel
                }).then((res) => {
                    this.bjxx = res.data.data.list[0];
                }).catch((res) => {

                });
            }
        }
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
</script>
<style>
    .bjxxViewTitle .ant-descriptions-title {
        font-size: 30px !important;
        text-align:center;
    }
</style>