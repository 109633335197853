<template>

    <div class="wh-100p" ref="rootBox">

        <!-- 搜索 -->
        <a-form 
            :form="form"
            layout="vertical" 
            :wrapper-col="{ span: 20 }" >
            
            <a-row>

                <a-col :span="6">
                    <a-form-item label="工单名称：">
                        <a-input v-decorator="['title']" autocomplete="off" />
                    </a-form-item>
                </a-col>

                <a-col :span="6">
                    <a-form-item label="办理人员：">
                        <a-input v-decorator="['send']" autocomplete="off" />
                    </a-form-item>
                </a-col>

                <a-col :span="6">
                    <a-form-item label="办理时间：">
                        <a-range-picker v-decorator="['sendtime']" />
                    </a-form-item>
                </a-col>

                <a-col :span="6">
                    <a-form-item label="审核人员：">
                        <a-input v-decorator="['updateTime']" autocomplete="off" />
                    </a-form-item>
                </a-col>

                <a-col :span="6">
                    <a-form-item label="审核时间：">
                        <a-range-picker v-decorator="['clsx']" />
                    </a-form-item>
                </a-col>

                <!-- 搜索按钮组 -->
                <a-col :span="12" style="marginTop: 29px;">
                    <a-form-item>
                        <a-space>
                            <a-button type="primary" icon="search" @click="search">搜索</a-button>
                            <a-button icon="redo" @click="reset">重置</a-button>
                        </a-space>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>

        <!-- 表格 -->
        <a-table 
            rowKey="id" 
            :columns="columns" 
            :data-source="dataSource" 
            :pagination="pagination" 
            :loading="loading" 
            :scroll="scroll"
            :rowClassName="rowClassName"
            @change="changePage">
            
            <router-link
                slot="name" 
                slot-scope="text, record"
                :to="{ path: './WorkOrderRecord', query: { orderbh: record.orderbh, bh: record.currentProcessbh }}">
                {{ text }}
            </router-link>

        </a-table>
    </div>
</template>

<script>
    import user from '@/user.js'

    const columns = [
        {
            title: "序号",
            dataIndex: "rownum",
            width: 60
        },
        {
            title: "工单名称",
            dataIndex: "title",
            scopedSlots: { customRender: "name" },
            width: 280,
            ellipsis: true
        },
        {
            title: "办理人员",
            dataIndex: "send",
            width: 120
        },
        {
            title: "办理时间",
            dataIndex: "view.sendtime",
            width: 120
        },
        {
            title: "审核人员",
            dataIndex: "updateUser",
            width: 120
        },
        {
            title: "审核时间",
            dataIndex: "view.updateTime",
            width: 120
        },
    ]
    
    const CONCAT_SQL = {

        ['title'] (value) {
            return ` and title like 'kejin${value}kejin' `
        },

        ['send'] (value) {
            return ` and send like 'kejin${value}kejin' `
        },

        ['sendtime'] (value) {
            return ` and sendtime>='${value.handleSt}' and sendtime<='${value.handleEt} 23:59:59' `
        },

        ['updateUser'] (value) {
            return ` and updateUser like 'kejin${value}kejin' `
        },

        ['updateTime'] (value) {
            return ` and updateTime>='${value.reviewSt}' and updateTime<='${value.reviewEt} 23:59:59' `
        }
    }

    export default {
        data() {
            return {
                form: this.$form.createForm(this, { name: "search" }),

                // table
                columns: Object.freeze(columns),
                dataSource: [],
                pagination: {
                    total: 0,
                    current: 1,
                    showTotal: (total) => `共 ${total} 条` // 分页中显示总的数据
                },
                scroll: {
                    x: 1050,
                    y: 0,
                    scrollToFirstRowOnChange: true
                },
                loading: false
            }
        },
        methods: {
            adjust () {
                this.$nextTick(() => {
                    this.scroll.y = this.$refs.rootBox.offsetHeight - 180
                })
            },
            validCond () {
                return this.form.isFieldsTouched()
            },
            getCond () {
                let field = this.form.getFieldsValue()

                // 处理时间格式
                if (field.sendtime) {
                    let handleSt = field.sendtime[0].format('YYYY-MM-DD')
                    let handleEt = field.sendtime[1].format('YYYY-MM-DD')
                    field.sendtime = { handleSt, handleEt }
                }

                if (field.updateTime) {
                    let reviewSt = field.updateTime[0].format('YYYY-MM-DD')
                    let reviewEt = field.updateTime[1].format('YYYY-MM-DD')
                    field.updateTime = { createSt, createEt }
                }

                return field
            },
            clearCond () {
                this.form.resetFields()
            },
            search () {
                if (!this.validCond()) return
                this.pagination.current = 1
                this.getDataSource()
            },
            reset () {
                if (!this.validCond()) return
                this.clearCond()
                this.pagination.current = 1
                this.getDataSource()
            },
            rowClassName (record, index) {
                let className = "dark_row"
                if (index % 2 === 0) className = "light_row"
                return className
            },
            changePage ({ current }) {
                this.pagination.current = current
                this.getDataSource()
            },
            // sql拼接和加密
            async handleSql(obj = {}) {
                let strWhere = ` del=0 and isEnd=2 and recLoginid='${user.user.userInfo.loginid}' `

                Object.keys(obj).forEach(key => {
                    let value = obj[key]
                    if (value !== undefined) {
                        strWhere += CONCAT_SQL[key](value)
                    }
                })
                
                strWhere = strWhere.replace(/'/g, 'zhangbiao')
                let { data } = await this.$jsc.DesEncrypt(this, strWhere)
                return data
            },

            // ajax
            async getDataSource () {
                this.loading = true

                this.$axios({
                    url: "/api/GetList/getListByTable",
                    method: "get",
                    params: {
                        tableName: "v_zhsw_workProcess",
                        page: this.pagination.current,
                        limit: 10,
                        strWhere: await this.handleSql(this.getCond()),
                    },
                })
                    .then(res => {
                        const { code, count, data } = res.data;
                        if (code === 0) {
                            this.pagination.total =count

                            data.forEach(item => {
                                item.view = {
                                    sendtime: item.sendtime.slice(0, 10),
                                    updateTime: item.updateTime.slice(0, 10)
                                }
                            })
                            this.dataSource = data
                            this.adjust()
                        }
                    })
                    .catch(err => err)
                    .finally(() => this.loading = false)
            }
        },
        created() {
            this.getDataSource()
            window.onresize =  (() => {
                let timer
                return () => {
                    timer && clearTimeout(timer)
                    timer = setTimeout(this.adjust, 100)
                }
            })()
        },
        beforeDestroy () {
            window.onresize = null
        }
    }
</script>

<style lang="less" scoped>
    // 隔行变色
    /deep/ .light_row { 
        background-color: #fff;
    }
    /deep/ .dark_row {
        background-color: #fafafa;
    }

    /deep/ .ant-form {
        margin-bottom: 25px;

        .ant-form-item {
            margin-bottom: 0;
        }
    }
</style>