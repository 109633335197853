<template>
    <div class="wh-100p" ref="rootBox">
        <!-- 搜索 -->
        <a-form 
            :form="form"
            layout="vertical" 
            :wrapper-col="{ span: 20 }" >
            
            <a-row>

                <a-col :span="6">
                <a-form-item label="工单编号：">
                    <a-input v-decorator="['orderNum']"  autocomplete="off" />
                </a-form-item>
                </a-col>

                <a-col :span="6">
                <a-form-item label="工单名称：">
                    <a-input v-decorator="['title']" autocomplete="off" />
                </a-form-item>
                </a-col>

                <a-col :span="6">
                <a-form-item label="巡查层级：">
                    <a-select v-decorator="['xccj']">
                        <a-select-option value="镇级">
                        镇级
                        </a-select-option>
                        <a-select-option value="市级">
                        市级
                        </a-select-option>
                    </a-select>
                </a-form-item>
                </a-col>

                <a-col :span="6">
                <a-form-item label="办理状态：">
                    <a-select v-decorator="['isEnd']">
                        <a-select-option :value="0">
                            正在办理
                        </a-select-option>
                        <a-select-option :value="1">
                            已办理
                        </a-select-option>
                    </a-select>
                </a-form-item>
                </a-col>

                <a-col :span="6">
                <a-form-item label="派单时间：">
                    <a-range-picker v-decorator="['createTime']" />
                </a-form-item>
                </a-col>

                <a-col :span="6">
                <a-form-item label="办理时间：">
                    <a-range-picker v-decorator="['clsx']" />
                </a-form-item>
                </a-col>

                <!-- 搜索按钮组 -->
                <a-col :span="12" style="marginTop: 29px;">
                <a-form-item>
                    <a-space>
                        <a-button type="primary" icon="search" @click="onSearch">搜索</a-button>
                        <a-button icon="redo" @click="onReset">重置</a-button>
                    </a-space>
                </a-form-item>
                </a-col>
            </a-row>
        </a-form>

        <!-- 表格 -->
        <a-table
            rowKey="id"
            :columns="columns" 
            :data-source="dataSource"
            :pagination = "pagination"
            :scroll="scroll"
            :rowClassName="rowClassName"
            :loading="loading"
            @change="onChangePage">

            <router-link
                slot="name" 
                slot-scope="text, record"
                :to="{ path: './WorkOrderRecord',  query: { orderbh: record.bh, bh: record.currentProcessbh }}">
                {{ text }}
            </router-link>

            <a-tag 
                slot="xccj" 
                slot-scope="text" 
                :color="text === '市级' ? '#108ee9' : '#13CDDC'">
                {{ text }}
            </a-tag>

            <a-tag slot="isEnd" slot-scope="text, record" :color="record.isEnd == 2 ? 'green' : 'orange'">
                {{ text }}
            </a-tag>
            
        </a-table>
    </div>
</template>

<script>

    const COLUMNS = [
        {
            title: "序号",
            dataIndex: "rownum",
            width: 70
        },
        {
            title: "工单编号",
            dataIndex: "orderNum",
            width: 105
        },
        {
            title: "工单名称",
            dataIndex: "title",
            width: 300,
            ellipsis: true,
            scopedSlots: { customRender: 'name' }
        },
        {
            title: "巡查层级",
            dataIndex: "xccj",
            width: 100,
            scopedSlots: { customRender: 'xccj' }
        },
        {
            title: "派单时间",
            dataIndex: "view.createTime",
            width: 120
        },
        {
            title: "办理时间",
            dataIndex: "view.clsx",
            width: 120
        },
        {
            title: "办理状态",
            dataIndex: "view.status",
            width: 100,
            scopedSlots: { customRender: 'isEnd' }
        },
    ]

    const CONCAT_SQL = {
        ['isEnd'] (value) {
            if (value === 1) {
                return ` and isEnd=2 `
            }
            return ` and isEnd<>2 `
        },

        ['orderNum'] (value) {
            return ` and send like 'kejin${value}kejin' `
        },

        ['title'] (value) {
            return ` and title like 'kejin${value}kejin' `
        },

        ['xccj'] (value) {
            return ` and xccj='${value}' `
        },

        ['clsx'] (value) {
            return ` and clsx>='${value.handleSt}' and clsx<='${value.handleEt} 23:59:59' `
        },

        ['createTime'] (value) {
            return ` and createTime>='${value.createSt}' and createTime<='${value.createEt} 23:59:59' `
        }
    }

    export default {
        data() {
            return {
                // form
                form: this.$form.createForm(this, { name: 'cond' }),

                // table
                columns: Object.freeze(COLUMNS),
                dataSource: [],
                pagination: {
                    total: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条`
                },
                scroll: { 
                    x: 1250, 
                    y: 0,
                    scrollToFirstRowOnChange: true
                },
                loading: false
            }
        },
        methods: {
            validCond () {
                return this.form.isFieldsTouched()
            },
            findCond () {
                let field = this.form.getFieldsValue()

                // 处理时间格式
                if (field.clsx) {
                    let handleSt = field.clsx[0].format('YYYY-MM-DD')
                    let handleEt = field.clsx[1].format('YYYY-MM-DD')
                    field.clsx = { handleSt, handleEt }
                }

                if (field.createTime) {
                    let createSt = field.createTime[0].format('YYYY-MM-DD')
                    let createEt = field.createTime[1].format('YYYY-MM-DD')
                    field.createTime = { createSt, createEt }
                }

                return field
            },
            resetCond () {
                this.form.resetFields()
            },
            onSearch () {
                if (!this.validCond()) return
                this.pagination.current = 1
                this.findDataSource()
            },
            onReset () {
                if (!this.validCond()) return
                this.resetCond()
                this.pagination.current = 1
                this.findDataSource()
            },
            rowClassName (record, index) {
                let className = "dark_row"
                if (index % 2 === 0) className = "light_row"
                return className
            },
            onChangePage ({ current }) {
                this.pagination.current = current
                this.findDataSource()
            },
            onAdjust () {
                this.$nextTick(() => {
                    this.scroll.y = this.$refs.rootBox.offsetHeight - 280
                })
            },

            // sql拼接和加密
            async handleSql(obj = {}) {
                let strWhere = ' del=0 '

                Object.keys(obj).forEach(key => {
                    let value = obj[key]
                    if (![undefined, ''].includes(value)) {
                        strWhere += CONCAT_SQL[key](value)
                    }
                })
                strWhere = strWhere.replace(/'/g, 'zhangbiao')
                let { data } = await this.$jsc.DesEncrypt(this, strWhere)
                return data
            },

            async findDataSource () {
            this.loading = true
            this.$axios({
                    url: "/api/GetList/getListByTable",
                    method: "get",
                    params: {
                        tableName: "v_zhsw_workOrder",
                        page: this.pagination.current,
                        limit: 10,
                        strWhere: await this.handleSql(this.findCond())
                    },
                })
                    .then(({ data: { code, count, data: resData } }) => {
                        if (code !== 0) return

                        resData.forEach(rd => {
                            rd.view = {
                                status: rd.isEnd === 2 ? '已办理' : '正在办理',
                                createTime: rd.createTime.slice(0, 10),
                                clsx: rd.clsx.slice(0, 10)
                            }
                        })

                        this.dataSource = resData
                        this.pagination.total = count
                        this.onAdjust()
                    })
                    .catch(err => err).finally(() => this.loading = false)
            }
        },
        created () {
            this.findDataSource()

            window.onresize =  (() => {
                let timer
                return () => {
                    timer && clearTimeout(timer)
                    timer = setTimeout( () => {
                        this.onAdjust()
                    }, 100)
                }
            })()
        },
        beforeDestroy () {
            window.onresize = null
        }
    }
</script>

<style lang="less" scoped>
// 隔行变色
    /deep/ .light_row { 
        background-color: #fff;
    }
    /deep/ .dark_row {
        background-color: #fafafa;
    }

    /deep/ .ant-form {
        margin-bottom: 25px;

        .ant-form-item {
            margin-bottom: 0;
        }
    }
</style>