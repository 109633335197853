<template>
	<div v-if="showAll">
		<p style="font-size: 18px;">设备状态</p>
		<a-row style="margin-top: 16px;">
			<a-col :xxl="4" :xl="8" :md="12" v-for="item,index in zhanzhaList" :key="index">
				<a-card hoverable style="max-width: 330px;margin-bottom: 10px;">
					<div class="cartit">{{item.addr}}</div>
                    <a-row>
                        <a-col :span="8">
                            <div class="c1">装机容量</div>
                            <div class="cg">160kw</div>
                        </a-col>
                        <a-col :span="8" style="padding-left: 5px;">
                            <div class="c1">排水流量</div>
                            <div class="cb">12.35m<sup>3</sup>/s</div>
                        </a-col>
                        <div class="ant-col ant-col-8" style="padding-left: 25px;">
                            <div class="c1">状态</div>
							<div class="c1">
								<span v-if="item.swn1>0" style="font-size:15px;color:#07B592">正常</span>
								<span v-else style="font-size:15px;color:red;">异常</span>
							</div>
                        </div>
                    </a-row>
				</a-card>
			</a-col>
		</a-row>
		<!--<div class="hdsqtablewrap">
		<table class="hdsqtable">
			<tr>
				<td>序号</td>
				<td>站闸名称</td>
				<td>内河水位</td>
				<td>外河水位</td>
				<td>警戒水位</td>
				<td>更新时间</td>
			</tr>
			<tr v-for="(item,index) in tabledata">
				<td>{{index+1}}</td>
				<td>{{item.name}}</td>
				<td>{{item.inner}}</td>
				<td>{{item.outter}}</td>
				<td>{{item.waring}}</td>
				<td>{{item.uodatetime}}</td>
			</tr>
		</table>
	</div>-->
		<div class="search-result-list" :style="{ marginTop: '0px' }">
			<a-table :columns="columns"
					 :data-source="sqData"
					 rowKey="id"
					 :loading="loading"
					 :scroll="scroll"
					 position="bottom"
					 tableLayout="fixed"
					 :pagination="false">
			</a-table>
		</div>

	</div>
	<div v-else>
		<p style="font-size: 18px;">{{town}}测量点</p>
		<a-row style="margin-top: 16px;">
			<a-col :span="6" v-for="(item,index) in eachTown" :key="item.title">
				<a-card hoverable style="width: 330px;margin-bottom: 10px;">
				    <div class="cartit">{{item.title}}</div>
					<a-row>
						<a-col :span="12">
							<div class="c1">{{item.key1}}</div>
							<div class="cg">{{item.value1}}</div>
						</a-col>
						<a-col :span="12" v-if="item.key2">
							<div class="c1">{{item.key2}}</div>
							<div class="cb">{{item.value2}}</div>
						</a-col>
					</a-row>
				</a-card>
			</a-col>
		</a-row>
		<a-row class="hdsq">
			<a-row type="flex" justify="space-between" align="middle" >
				<a-col>水流量走势图-内河</a-col>
				<!--<a-col>
					<a-date-picker placeholder="按年月日"/>
					<a-time-picker placeholder="具体时间" use12-hours format="h:mm:ss A" style="margin-left: 10px;"/>
				</a-col>-->
			</a-row>
			<a-row class="hdsq">
				<div style="width:100%;height: 300px;" ref="hdsq1"></div>
			</a-row>
		</a-row>
		<a-row class="hdsq">
			<a-row type="flex" justify="space-between" align="middle" >
				<a-col>水流量走势图-外河</a-col>
				<!--<a-col>
					<a-date-picker placeholder="按年月日"/>
					<a-time-picker placeholder="具体时间" use12-hours format="h:mm:ss A" style="margin-left: 10px;"/>
				</a-col>-->
			</a-row>
			<a-row class="hdsq">
				<div style="width:100%;height: 300px;" ref="hdsq2"></div>
			</a-row>
		</a-row>
	</div>
</template>
<script>
	import * as echarts from 'echarts'

    const columns = [
        {
            title: '序号',
            dataIndex: 'rownum',
            width: 70,
            key: 'rownum'
        },
        {
            title: '地区',
            dataIndex: 'addr',
            width: 100,
            key: 'addr',
        },
        {
            title: '外核水位',
            dataIndex: 'swn1',
            width: 100,
            key: 'swn1',
        },
        {
            title: '内核水位',
            dataIndex: 'swn2',
            width: 100,
            key: 'swn2',
        },
        {
            title: '更新时间',
            dataIndex: 'createdtime',
            key: 'createdtime',
            width: 100,
        }
    ]
    export default {
        data() {
			return {
                columns,
				loading: true,
                scroll: {
                },
                showAll:this.$route.query.town?false:true,
				town:this.$route.query.town,
				eachTown:[
                    { title: "设备状态", key1: "是否正常", value1:"暂无数据"},
                    { title: "已超警戒水位", key1: "当前水位", value1:"暂无数据",key2:"警戒水位",value2:"暂无数据"},
                    { title: "当前水位", key1: "内河", value1: "暂无数据", key2: "外河", value2:"暂无数据"},
                    { title: "当前流量", key1: "内河", value1: "暂无数据", key2: "外河", value2:"暂无数据"},
				],
				zhanzhaList:[
					{name:"牛桥港站闸"},
					{name:"斜路港站闸"},
					{name:"庙前港站闸"},
					{name:"金家庄北站闸"},
					{name:"小千灯浦站闸"},
					{name:"新开泾站闸"},
					{name:"泖泾港站闸"}
				],
				tabledata:[
					{
						name:"牛桥港站闸",
						inner:"230m",
						outter:"351m",
						waring:"250m",
						uodatetime:"2020/12/23"
					},
					{
						name:"斜路港站闸",
						inner:"230m",
						outter:"351m",
						waring:"250m",
						uodatetime:"2020/12/24"
					},
					{
						name:"庙前港站闸",
						inner:"230m",
						outter:"351m",
						waring:"250m",
						uodatetime:"2020/12/25"
					},
					{
						name:"金家庄北站闸",
						inner:"232m",
						outter:"358m",
						waring:"210m",
						uodatetime:"2020/12/26"
					},
					{
						name:"小千灯浦站闸",
						inner:"238m",
						outter:"321m",
						waring:"260m",
						uodatetime:"2020/12/27"
					},
					{
						name:"新开泾站闸",
						inner:"235m",
						outter:"321m",
						waring:"270m",
						uodatetime:"2020/12/28"
					},
					{
						name:"泖泾港站闸",
						inner:"240m",
						outter:"321m",
						waring:"240m",
						uodatetime:"2020/12/29"
					}
				],
				sqData: [],
				echarts_elem:""
            }
        },
        created: function () {
			console.log(this.$route);
			this.getsqInfo();
        },
        watch: {
			'$route.query.id'(val) {
                this.msg="我是internetOfThings/hdsq组件" + val
                if (val!=="0-0") {
                   this.showAll=false
				   this.town=this.$route.query.town
				   //var x=["00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00"]
				   //var y=[221,454,657,678,876,467,976,478,379,589,764,999]
				   //setTimeout(()=>{
				   //	this.setChart(x,y,this.$refs.hdsq1)
				   //	this.setChart(x,y,this.$refs.hdsq2)
				   //})
                }
				if(val==="0-0"){
					this.showAll=true
				}
			},
			'$route.query.uid'(val) {
                if (val=="ksslj") {
                    this.getsqInfo(val);
				} else {
					this.getqyInfo(val);
					this.getqyInfo_header(val);
                }
            },
        },
		methods: {
			//重点点位点击获取数据
			getsqInfo(uid) {
				if (!uid) { uid = "ksslj"; }
                this.$axios({
                    url: "/api/hdsq/GetSlzqzxgxx?uid="+uid,
                    method: "get"
                }).then(res => {
                    console.log(res)
                    if (res.data.code == 0) {
                        this.sqData = res.data.data
                        this.zhanzhaList = res.data.data
						this.sqData.forEach((item,index) => {
                            item['rownum'] = index + 1;
						})
                        this.loading = false
                    } else {
                        this.$message.error('网络繁忙，请稍后重试！');
                    }
                }).catch(err => {
                    this.$message.error('网络繁忙，请稍后重试！');
                })
			},
            getqyInfo(cid) {
                var _this = this;
				var myChart1;
				var myChart2;

                setTimeout(() => {
                    myChart1 = echarts.init(_this.$refs.hdsq1);
                    myChart2 = echarts.init(_this.$refs.hdsq2);
                    myChart1.showLoading();
                    myChart2.showLoading();
                })
                
                _this.$axios({
                    url: "/api/hdsq/GetSlyqdata_echarts?cid=" + cid,
                    method: "get"
                }).then(res => {
                    console.log(res)
                    if (res.data.code == 0) {
                        var data = res.data.data
                        var x = []
                        var y = []
                        var y2 = []
                        for (var i = 0; i < data.length; i++) {
                            x.push(data[i].sj);
                            y.push(data[i].swn1);
                            y2.push(data[i].swn2);
                        }
                        setTimeout(() => {
                            _this.setChart(x, y, _this.$refs.hdsq1)
                            _this.setChart(x, y2, _this.$refs.hdsq2)
                        })
                    } else {
                        this.$message.error('网络繁忙，请稍后重试！');
                    }
                }).catch(err => {
                    this.$message.error('网络繁忙，请稍后重试！');
                })
			},
            getqyInfo_header(cid) {
                var _this = this;
                this.$axios({
                    url: "/api/hdsq/GetSl_qyinfo_header?cid=" + cid,
                    method: "get"
                }).then(res => {
                    console.log(res)
                    if (res.data.code == 0) {
                        var data = res.data.data
                        _this.eachTown[0].value1 = data[0].sbstate;
                        _this.eachTown[1].value1 = data[0].jj_dqsw;
                        _this.eachTown[2].value1 = data[0].nh_dqsw;
                        _this.eachTown[2].value2 = data[0].wh_dqsw;
                    } else {
                        this.$message.error('网络繁忙，请稍后重试！');
                    }
                }).catch(err => {
                    this.$message.error('网络繁忙，请稍后重试！');
                })
            },
			setChart(xdata, ydata, dom) {
			    var data1 = ydata;
			    var data2 = [];
			    //定义两个上下箭头的矢量路径
			    var up = 'path://M286.031,265l-16.025,3L300,223l29.994,45-16.041-3-13.961,69Z';
			    var down = 'path://M216.969,292l16.025-3L203,334l-29.994-45,16.041,3,13.961-69Z'
			    var path = [up, down, up, down, down, down, up]
			    var option = {
			        backgroundColor: 'white',
			        color: ['#3398DB'],
			        tooltip: {
			            trigger: 'axis',
			            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
			                type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
			            }
			        },
			        grid: {
			            left: '3%',
			            top: '20%',
			            right: '4%',
			            bottom: '3%',
			            containLabel: true
			        },
			        xAxis: [
			            {
			                type: 'category',
			                data: xdata,
			                axisTick: {
			                    alignWithLabel: true
			                }
			            }
			        ],
			        yAxis: [
			            {
			                type: 'value',
			                splitLine: {
			                    lineStyle: {
			                        type: 'dashed',
			                        color: '#eee'
			                    }
			                }
			            }
			        ],
			        series: [
			            {
			                "name": "降水量",
			                type: 'bar',
			                symbolSize: [0, 0],
			                symbolOffset: [0, 0],
			                color: '#3AACF9',
			                symbolPosition: 'end',
			                label: {
			                    show: true,
			                    position: "top",
			                    fontSize: 30,
			                    fontWeight: 'bold',
			                    color: '#34DCFF'
			                },
			                data: data1
			            }
			        ]
			    };
				var myChart = echarts.init(dom)
                myChart.setOption(option);
			    window.addEventListener("resize", function () {
			        myChart.resize();
                })
                myChart.hideLoading();
			},
		}
    }
</script>
<style type="text/css">
	.cartit{margin-bottom: 10px;font-size: 18px;font-weight: bold;}
	.c1{color: #bbb;font-size: 13px;/*transform: scale(.7) translateX(-25px);*/}
	.cg,.cb{font-size: 15px;}
	.cg{color: #07B592;}
	.cb{color: #3AACF9;}
	.hdsqtablewrap{padding: 10px;background: white;}
	.hdsqtable{width: 100%; border: 1px solid #eee;border-collapse: collapse;background: white;}
	.hdsqtable td{border: 1px solid #bbb;}
	.hdsqtable td{height: 40px;padding: 5px;}
	.hdsq{
		margin-top: 16px;
		background: white;
		box-sizing: border-box;
		padding: 10px;
	}
    .ant-table-body, .ant-table-body th {
        background-color: white !important;
    }

</style>