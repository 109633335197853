import { render, staticRenderFns } from "./yjya.vue?vue&type=template&id=aef84920&scoped=true&"
import script from "./yjya.vue?vue&type=script&lang=js&"
export * from "./yjya.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aef84920",
  null
  
)

export default component.exports