<template>
    <div>
        <a-alert message="提示：分组中单位可拖动排序，人员信息通过修改排序字段的值来排序，排序方式为从小到大。" type="info" style=" margin-bottom:16px;"  show-icon/>
        <a-menu :style="menuStyle" v-if="menuVisible" @click="onRightMenu">
            <a-menu-item key="plus" v-if="rightMenuPlusVisible" @click="btnClick('新增单位')"><a-icon type="plus" />新增单位</a-menu-item>
            <a-menu-item key="edit" @click="btnClick('编辑树菜单')"><a-icon type="edit" />编辑</a-menu-item>
            <a-menu-item key="delete" @click="btnClick('删除树菜单')"><a-icon type="delete"  />删除</a-menu-item>
        </a-menu>
        <a-modal v-model="groupAddOptions.visible" :title="groupAddOptions.title" width="600px">

            <a-spin :spinning="groupAddOptions.spinning">
                <a-form :form="groupAdd" :label-col="{ span:4 }" :wrapper-col="{ span:20 }" labelAlign="left">
                    <a-form-item label="" style="display:none">
                        <a-input v-decorator="['bh']" />
                    </a-form-item>

                    <a-row type="flex" justify="space-between">
                        <a-col :span="24">
                            <a-form-item label="分组名">
                                <a-input v-decorator="['groupName', { rules: [{ required: true, message: '请输入分组名' },{max:20,message:'最大长度为20'}]}]" placeholder="分组名" :disabled="groupAddOptions.viewMode"/>
                            </a-form-item>
                        </a-col>
                        <!--<a-col :span="11">
                            <a-form-item label="排序">
                                <a-input type="number" v-decorator="['sort', { rules: [{ required: true, message: '请输入排序' }]}]" placeholder="排序" :disabled="groupAddOptions.viewMode" :maxLength="10" />
                            </a-form-item>
                        </a-col>-->
                    </a-row>

                </a-form>
            </a-spin>
            <template slot="footer">
                <a-button key="back" @click="groupAddOptions.visible=false">关闭</a-button>
                <a-button key="submit" type="primary" :loading="groupAddOptions.submitLoading" @click="groupAddSubmit" v-if="groupAddOptions.submitVisible">确定</a-button>
            </template>
        </a-modal>

        <a-modal v-model="departAddOptions.visible" :title="departAddOptions.title" width="600px">

            <a-spin :spinning="departAddOptions.spinning">
                <a-form :form="departAdd" :label-col="{ span:4 }" :wrapper-col="{ span:20 }" labelAlign="left">
                    <a-form-item label="" style="display:none">
                        <a-input v-decorator="['bh']" />
                    </a-form-item>

                    <a-row type="flex" justify="space-between">
                        <a-col :span="24">
                            <a-form-item label="单位名称">
                                <a-input v-decorator="['depart', { rules: [{ required: true, message: '请输入单位名' },{max:20,message:'最大长度为20'}]}]" placeholder="单位名称" :disabled="departAddOptions.viewMode" />
                            </a-form-item>
                        </a-col>
                        <!--<a-col :span="11">
                            <a-form-item label="排序">
                                <a-input type="number" v-decorator="['sort', { rules: [{ required: true, message: '请输入排序' }]}]" placeholder="排序" :disabled="departAddOptions.viewMode" :maxLength="10" />
                            </a-form-item>
                        </a-col>-->
                    </a-row>

                </a-form>
            </a-spin>
            <template slot="footer">
                <a-button key="back" @click="departAddOptions.visible=false">关闭</a-button>
                <a-button key="submit" type="primary" :loading="departAddOptions.submitLoading" @click="departAddSubmit" v-if="departAddOptions.submitVisible">确定</a-button>
            </template>
        </a-modal>
        <a-layout>
            <a-layout-sider :trigger="null" collapsible width="240" style="background-color: #fff;height:100%;">
                <a-card size="small" title="通讯录分组" style="width: 100%;" :bodyStyle="{height:'594px',overflow: 'auto'}">
                    <a slot="extra" @click="btnClick('新增分组')" title="新增分组" style=" margin:0px 6px;"><a-icon type="plus" /></a>

                    <a-tree draggable @drop="onDrop" @select="onSelect" @rightClick="onRightClick" :blockNode="true">
                        <a-tree-node key="0" title="全部" :value="{groupbh:'',departbh:''}">

                        </a-tree-node>
                        <template v-for="item in txlMenu">
                            <a-tree-node :key="item.id" :title="item.title" :value="{groupbh:item.groupbh,departbh:item.departbh}">
                                <template v-if="item.children.length>0">
                                    <a-tree-node :key="subItem.id" :title="subItem.title" is-leaf v-for="subItem in item.children" :value="{groupbh:subItem.groupbh,departbh:subItem.departbh}" />
                                </template>
                            </a-tree-node>
                        </template>
                    </a-tree>
                </a-card>
            </a-layout-sider>


            <a-layout-content :style="{ margin: '0px !important', padding: '0px 0px 0px 16px !important', background: '#fff', minHeight: '280px' , position: 'relative'}">
                <a-card size="small" title="人员列表" style="width: 100%">
                    <a slot="extra" @click="btnClick('新增')" title="新增人员" style=" margin:0px 6px;"><a-icon type="plus" /></a>
                    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
                        <a-row :gutter="8" type="flex" justify="space-between">
                            <a-form-item label="" style="display:none;">
                                <a-input placeholder="" v-decorator="[`groupbh`]" />
                                <a-input placeholder="" v-decorator="[`departbh`]" />
                            </a-form-item>
                            <a-col :span="4">
                                <a-form-item label="">
                                    <a-input placeholder="姓名" v-decorator="[`xm`]" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="5">
                                <a-form-item label="">
                                    <a-input placeholder="单位" v-decorator="[`bm`]" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="4">
                                <a-form-item label="">
                                    <a-input placeholder="分工" v-decorator="[`fg`]" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="4">
                                <a-form-item label=""><a-input placeholder="职务" v-decorator="[`zw`]"></a-input></a-form-item>
                            </a-col>

                            <a-col :span="6">
                                <a-form-item label="">
                                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-form>
                    <div class="search-result-list" :style="{ marginTop: '0px' }">
                        <a-table :columns="columns"
                                 :data-source="tableData"
                                 :pagination="pagination"
                                 :pageSize="20"
                                 :total="50"
                                 rowKey="id"
                                 :loading="tableLoading"
                                 :scroll="scroll"
                                 @change="pageCurrentChange"
                                 position="bottom"
                                 tableLayout="fixed">
                            <a slot="name" slot-scope="text,record" @click="openDlg(record,'view')">{{ text }}</a>
                            <span slot="action" slot-scope="text, record">
                                <a-button type='primary' size="small" key="edit" @click="btnClick('编辑',record)" style='margin-right: 5px'><a-icon type="edit" /></a-button>
                                <a-button type='danger' size="small" key="delete" @click="btnClick('删除',record)" style='margin-right: 5px'><a-icon type="delete" /></a-button>
                            </span>
                        </a-table>
                    </div>
                </a-card>
            </a-layout-content>
        </a-layout>



        <a-modal v-model="formAddOptions.visible" title="新增" width="800px">
            <a-spin :spinning="formAddOptions.spinning">
                <a-form :form="formAdd" :label-col="{ span:8 }" :wrapper-col="{ span:16 }" labelAlign="left">
                    <a-form-item label="" style="display:none">
                        <a-input v-decorator="['bh']" />
                    </a-form-item>

                    <a-row type="flex" justify="space-between">
                        <a-col :span="11">
                            <a-form-item label="姓名">
                                <a-input v-decorator="['xm', { rules: [{ required: true, message: '请输入姓名' },{max:50,message:'最大长度为50'}]}]" placeholder="姓名" :disabled="formAddOptions.viewMode" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="11">

                            <a-form-item label="职务">
                                <a-input v-decorator="['zw', { rules: [{ required: false, message: '请输入企业名称' },{max:50,message:'最大长度为50'}]}]" placeholder="职务" :disabled="formAddOptions.viewMode" />
                            </a-form-item>
                        </a-col>

                    </a-row>

                    <a-row type="flex" justify="space-between">
                        <a-col :span="11">
                            <a-form-item label="手机号">
                                <a-input placeholder="手机号" v-decorator="['phone',{ rules: [{ required: true, message: '' }],},]" style="width: 100%" :disabled="formAddOptions.viewMode">
                                </a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :span="11">
                            <a-form-item label="办公电话">
                                <a-input placeholder="办公电话" v-decorator="['officeTel',{ rules: [{ required: false, message: ' '}] }]" style="width: 100%" :disabled="formAddOptions.viewMode">
                                </a-input>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-between">
                        <a-col :span="11">
                            <a-form-item label="值班电话">
                                <a-input placeholder="值班电话" v-decorator="['zbdh',{ rules: [{ required: false, message: ' '}] }]" style="width: 100%" :disabled="formAddOptions.viewMode">
                                </a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :span="11">
                            <a-form-item label="分工">
                                <a-input placeholder="分工" v-decorator="['fg',{ rules: [{ required: false, message: ' '}] }]" style="width: 100%" :disabled="formAddOptions.viewMode">
                                </a-input>
                            </a-form-item>
                        </a-col>
                    </a-row>


                    <a-row type="flex" justify="space-between">
                        <a-col :span="11">
                            <a-form-item label="分组">
                                <a-select style="width:100%" placeholder="请选择分组" :disabled="formAddOptions.viewMode" v-decorator="['groupbh',{ rules: [{ required: true, message: '请选择分组'}]},]" @select="onSelect_groupName">
                                    <a-select-option v-for="items in txlGroup" :key="items.id" :value="items.bh">
                                        {{items.groupName}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="11">
                            <a-form-item label="单位">
                                <!--<a-input v-decorator="['bm', { rules: [{ required: true, message: '请输入企业名称' },{max:50,message:'最大长度为50'}]}]" placeholder="单位" :disabled="formAddOptions.viewMode" />-->
                                <a-select style="width:100%" placeholder="请选择单位" :disabled="formAddOptions.viewMode" v-decorator="['departbh',{ rules: [{ required: true, message: '请选择单位'}]},]">
                                    <a-select-option v-for="items in txlDepart" :key="items.id" :value="items.bh">
                                        {{items.depart}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-between" v-if="formAddOptions.sortVisible">
                        <a-col :span="11">
                            <a-form-item label="排序">
                                <a-input type="number" placeholder="排序" v-decorator="['sort',{ rules: [{ required: true, message: ' '}] }]" style="width: 100%" :disabled="formAddOptions.viewMode">
                                </a-input>
                            </a-form-item>
                        </a-col>

                    </a-row>

                    <a-row type="flex" justify="space-between">
                        <a-col :span="24">
                            <a-row>
                                <a-form-item label="备注" :label-col="{ span:10 }" :wrapper-col="{ span:24 }">
                                    <a-input type="textarea" :rows="5" v-decorator="['bz', { rules: [{ required: false, message: '请输入备注' },{max:500,message:'最大长度为500'}]}]" placeholder="备注" :disabled="formAddOptions.viewMode" />
                                </a-form-item>
                            </a-row>
                        </a-col>
                    </a-row>
                </a-form>


            </a-spin>
            <template slot="footer">
                <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
                <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
            </template>
        </a-modal>

    </div>
</template>
<script>

    const strWhere = 'del=0'
    const action = 'add'
    export default {
        data() {
            return {
                showexport: false,
                scroll: {
                    y: 400,
                    x: 1200
                },
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                tableData: [],
                select_data: [],
                select_teams: [],
                select_leader: [],
                select_zhanzha: [],
                txlDepart: [],
                txlGroup: [],
                txlMenu: [],
                txlGroup_default_selected: [],
                imageUrl: '',
                imagePath: '',
                baseUrl: this.$axios.defaults.fileURL,
                columns: [
                    {
                        title: '序号',
                        dataIndex: 'rownum',
                        width: 60,
                        key: 'rownum'
                    },
                    {
                        title: '排序',
                        dataIndex: 'sort',
                        width: 60,
                        key: 'sort'
                    },
                    {
                        title: '单位',
                        dataIndex: 'departName',
                        key: 'departName',
                        width: 130,
                        //scopedSlots: { customRender: 'name' }
                    },
                    {
                        title: '分工',
                        dataIndex: 'fg',
                        width: 100,
                        key: 'fg'
                    },
                    {
                        title: '姓名',
                        dataIndex: 'xm',
                        width: 120,
                        key: 'xm'
                    },
                    {
                        title: '职务',
                        dataIndex: 'zw',
                        width: 200,
                        key: 'zw'
                    },
                    {
                        title: '办公电话',
                        dataIndex: 'officeTel',
                        width: 100,
                        key: 'officeTel'
                    },
                    {
                        title: '移动电话',
                        dataIndex: 'phone',
                        width: 120,
                        key: 'phone',
                        //scopedSlots: { customRender: 'state' }
                    },
                    {
                        title: '值班电话',
                        dataIndex: 'zbdh',
                        width: 150,
                        key: 'zbdh',
                        //scopedSlots: { customRender: 'shzt' }
                    },
                    {
                        title: '备注',
                        dataIndex: 'bz',
                        width: 100,
                        key: 'bz',
                        //scopedSlots: { customRender: 'shzt' }
                    },
                    {
                        title: '分组',
                        dataIndex: 'groupName',
                        width: 160,
                        key: 'groupName',
                        //scopedSlots: { customRender: 'shzt' }
                    },
                    {
                        title: '操作',
                        width: 120,
                        key: 'action',
                        fixed: 'right',
                        scopedSlots: { customRender: 'action' }
                    }
                ],
                tableLoading: false,
                action,
                previewVisible: false,
                previewImage: "",
                fileList: [],
                strWhere,
                sort: "",
                expand: false,
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false,
                    sortVisible:true
                },
                groupAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false
                },
                departAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
                groupAdd: this.$form.createForm(this, { name: 'advanced_search111' }),
                departAdd: this.$form.createForm(this, { name: 'advanced_search1112' }),
                pagination: {
                    total: 0,
                    pageSize: 10, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
                    showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: 'bottom'
                },
                menuVisible: false,
                menuStyle: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    border: "1px solid #eee"
                },
                currentSelTree: {},
                rightMenuPlusVisible: false,
            }
        },

        mounted() {
            console.log(this.$route.meta);
            //将上面的按钮和表格内的按钮分出来
            for (let i = 0; i < this.$route.meta.buttons.length; i++) {
                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                }
                else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.init()
            this.actions()
            window.onresize = () => {
                this.init()
            };
            //this.bindGroupList();
            //console.log(this.$route);
            //console.log(this.buttons);
            //console.log(this.inlineButtons);

            this.bindGroupList1();
            this.bindList();
            this.bindGroup();
        },
        created: function () {

        },
        methods: {
            onSelect_groupName(value, option) {
                this.bindDepart();
            },
            onRightMenu(obj) {
                //console.log(obj);
            },
            bodyClick() {
                this.menuVisible = false;
                document.body.removeEventListener("click", this.bodyClick);
            },
            onRightClick(item) {
                this.currentSelTree = item.node.value;
                if (this.currentSelTree.groupbh == "" && this.currentSelTree.departbh == "") {
                    return false;
                }
                if (this.currentSelTree.departbh == "") {
                    this.rightMenuPlusVisible = true;
                }
                else {
                    this.rightMenuPlusVisible = false;
                }
                this.menuVisible = true;
                this.menuStyle.top = item.event.layerY +40+ "px";
                this.menuStyle.left = item.event.layerX +30+ "px";
                this.menuStyle.zIndex = 9000;
                document.body.addEventListener("click", this.bodyClick);
            },
            onDrop(info) {

                if (!info.dropToGap) {
                    this.$message.warning("不支持拖动到内部");
                    return;
                }

                if (info.node.value.departbh == "") {
                    this.$message.warning("不支持分组拖动");
                    return;
                }

                if (info.node.value.groupbh != info.dragNode.value.groupbh) {
                    this.$message.warning("不支持跨组拖动");
                    return;
                }

                const dropPos = info.node.pos.split('-');
                const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
                console.log(dropPosition, info.node.title);

                this.$axios({
                    url: '/api/txl/departSort',
                    method: 'get',
                    params: {
                        target: info.node.value.departbh,
                        source: info.dragNode.value.departbh,
                        fx: dropPosition,
                    }
                }).then((res) => {
                    console.log(res);
                    this.bindGroupList1();

                }).catch(function (error) {
                })
            },
            onSelect(selectedKeys, info) {
                var slots = info.node.value;
                this.formSearch.setFieldsValue({ "groupbh": slots.groupbh });
                this.formSearch.setFieldsValue({ "departbh": slots.departbh });
                this.bindList();
            },
            groupSelect(item) {
                this.formSearch.setFieldsValue({ "sgroupbh": item.key });
                this.bindList();
            },
            confirm(re) {
                // const value=2
                this.shenhe(2, re)
            },
            cancel(re) {
                // const value=1
                // console.log(re)
                this.shenhe(1, re)
            },

            btnClick(text, item) {
                let _this = this;
                switch (text) {
                    case '新增':
                        this.openDlg()
                        break
                    case "编辑":
                        this.openDlg(item);
                        break;
                    case "删除":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定要删除 " + item.xm + " 吗?",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.del(item);
                            }
                        });
                        break;
                    case "删除分组":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定要删除选中的分组吗?",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.delGroup(item);
                            }
                        });
                        break;
                    case "重置密码":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定要重置 " + item.name + " 的密码吗?",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.resetPwd(item);
                            }
                        });
                        break;
                    case "新增分组":
                        this.openDlgGroup('add');
                        break;
                    case "编辑树菜单":
                        if (this.currentSelTree.groupbh != "" && this.currentSelTree.departbh != "") {
                            this.openDlgDepart('edit');
                        }
                        else if(this.currentSelTree.groupbh != "" && this.currentSelTree.departbh == "") {
                            this.openDlgGroup('edit');
                        }
                        break;
                    case "删除树菜单":
                        if (this.currentSelTree.groupbh != "" && this.currentSelTree.departbh != "") {
                            this.$confirm({
                                title: '操作确认',
                                content: "删除单位将会同时删除其它所有人员，确定要删除吗?",
                                okText: '确认',
                                cancelText: '取消',
                                onOk() {
                                    _this.delDepart(item);
                                }
                            });
                        }
                        else if (this.currentSelTree.groupbh != "" && this.currentSelTree.departbh == "") {
                            this.$confirm({
                                title: '操作确认',
                                content: "删除分组将会同时删除其它所有单位和人员，确定要删除吗?",
                                okText: '确认',
                                cancelText: '取消',
                                onOk() {
                                    _this.delGroup(item);
                                }
                            });
                        }
                        break;
                    case "新增单位":
                        this.openDlgDepart('add');
                        break;

                }
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            image_remove() {
                this.imageUrl = "";
                this.imagePath = "";
            },
            phoneCheck(rule, value, callbackFn) {
                const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
                if (!reg.test(value)) {
                    callbackFn('请输入正确的手机号码')
                    return
                }
                callbackFn()
            },
            SFZCheck(rule, value, callbackFn) {
                const reg = /^(\d{18}|\d{15}|\d{17}x)$/
                if (!reg.test(value)) {
                    callbackFn('请输入正确的身份证号码')
                    return
                }
                callbackFn()
            },
            bindDepart: async function (value) {
                var sort = (await this.$jsc.DesEncrypt(this, "sort asc,id desc")).data;
                this.formAdd.setFieldsValue({"departbh":""})
                var groupbh = this.formAdd.getFieldValue("groupbh");
                console.log(groupbh);
                var _strWhere = (await this.$jsc.DesEncrypt(this, "del=0 and groupbh=zhangbiao" + groupbh + "zhangbiao")).data;
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'sys_txlDepart',
                        page: 1,
                        limit: 1000,
                        sort: sort,
                        fields: "depart,bh,id",
                        strWhere: _strWhere
                    }
                }).then((res) => {
                    this.txlDepart = res.data.data;
                    if (value != undefined) {
                        this.formAdd.setFieldsValue({ "departbh": value })
                    }
                }).catch(function (error) {
                })
            },
            bindGroup: async function () {
                var sort = (await this.$jsc.DesEncrypt(this, "sort asc,id asc")).data;
                var _strWhere = (await this.$jsc.DesEncrypt(this, "del=0")).data;

                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'sys_txlGroup',
                        page: 1,
                        limit: 1000,
                        sort: sort,
                        fields: "groupName,bh,id",
                        // filesFields: 'bh',
                        strWhere: _strWhere
                    }
                }).then((res) => {
                    this.txlGroup = res.data.data;
                }).catch(function (error) {
                })
            },
            bindGroupList1: function () {
                this.$axios({
                    url: 'http://ksssw.kejin.net.cn:8002/api/txl/getTxlMenu',
                    method: 'post',
                }).then((res) => {
                    //console.log(res);
                    this.txlMenu = res.data.data;
                    console.log(this.txlMenu);
                    //var temp = this.formSearch.getFieldValue("sgroupbh");
                    //if (temp == undefined) {
                    //    this.txlGroup_default_selected[0] = res.data.data[0].bh;
                    //    this.formSearch.setFieldsValue({ "sgroupbh": this.txlGroup_default_selected[0] });
                    //    //console.log(this.txlGroup_default_selected[0]);
                    //    //setTimeout(() => {
                    //    //    this.bindList();
                    //    //},1000);
                    //    //this.$nextTick(function () {
                    //    //    this.bindList();
                    //    //});
                    //    this.bindList();
                    //}
                    //else {
                    //    this.bindList();
                    //}


                }).catch(function (error) {
                })
            },
            bindList: async function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1
                    console.log(values)
                    this.strWhere = 'del=0 '
                    for (var obj in values) {
                        if (values[obj]) {
                            if (obj == 'xxxx') {
                                this.strWhere += ' and ' + obj + "=" + values[obj];
                            }
                            else if (obj == "groupbh" || obj == "departbh") {
                                this.strWhere += " and " + obj + "='" + values[obj] + "'";
                            }
                            else {
                                this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
                            }

                        }
                    }

                    this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
                })
                this.tableLoading = true

                this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere);
                this.sort =  await this.$jsc.DesEncrypt(this, "groupSort asc,departSort asc,sort asc");
                this.strWhere = this.strWhere.data;
                this.sort = this.sort.data;
                await this.InitGrid()
            },
            InitGrid: function () {
                var _that = this
                _that.tableLoading = true
                //let sort = await (this.$jsc.DesEncrypt(this, 'bm asc,xm asc')).data;
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'v_sys_txl',
                        page: _that.pagination.current,
                        limit: _that.pagination.pageSize,
                        "sort": _that.sort,
                        strWhere: _that.strWhere,
                    }
                }).then(function (response) {
                    _that.tableLoading = false
                    if (response.status === 200) {
                        _that.tableData = response.data.data
                        _that.pagination.total = response.data.count
                    }
                }).catch(function (error) {
                    console.log(error)
                })
            },
            formSearchSubmit(e) {
                e.preventDefault()
                this.pagination.current = 1
                this.bindList()
            },
            formSearchReset() {
                this.formSearch.resetFields()
                this.strWhere = 'del=0'
                this.bindList()
            },
            toggle() {
                this.expand = !this.expand
                this.init()
            },
            pageCurrentChange(pagination) {
                this.pagination.current = pagination.current
                this.bindList()
            },
            del(record) {
                this.$axios({
                    url: '/api/Comon/deltag',
                    method: 'post',
                    data: {
                        tableName: 'sys_txl',
                        field: 'id',
                        value: record.id,
                        source: 'web',
                        loginid: ''
                    }
                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info('删除成功');
                            this.bindList();
                        }
                        else {
                            throw new Error("服务器超时");
                        }
                    }
                    else {
                        throw new Error("服务器超时");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                })
            },
            delGroup() {
                let groupbh = this.currentSelTree.groupbh;
                this.$axios({
                    url: '/api/Comon/deltag',
                    method: 'post',
                    data: {
                        tableName: 'sys_txlGroup',
                        field: 'bh',
                        value: groupbh,
                        source: 'web',
                        loginid: ''
                    }
                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info('删除成功');
                            this.bindGroupList1();
                            this.bindList();
                        }
                        else {
                            throw new Error("服务器超时");
                        }
                    }
                    else {
                        throw new Error("服务器超时");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                })
            },
            delDepart() {
                let departbh = this.currentSelTree.departbh;
                this.$axios({
                    url: '/api/Comon/deltag',
                    method: 'post',
                    data: {
                        tableName: 'sys_txlDepart',
                        field: 'bh',
                        value: departbh,
                        source: 'web',
                        loginid: ''
                    }
                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info('删除成功');
                            this.bindGroupList1();
                            this.bindList();
                        }
                        else {
                            throw new Error("服务器超时");
                        }
                    }
                    else {
                        throw new Error("服务器超时");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                })
            },
            openDlgGroup: async function (action) {

                if (action == "add") {
                    this.groupAddOptions.title = "新增分组";
                    this.groupAdd.resetFields();
                    this.groupAddOptions.visible = true;
                }
                else {
                    this.groupAddOptions.title = "编辑分组";
                }
                this.groupAddOptions.submitVisible = true;
                //this.groupAddOptions.viewMode = false;



                if (action == "edit") {
                    var value = this.currentSelTree;
                    if (value.groupbh == "" || value.groupbh == undefined) {
                        this.$message.warning('请选中一个分组');
                        return false;
                    }
                    // 从后台取数据赋值
                    var obj = await this.$jsc.DesEncrypt(this, 'bh=zhangbiao' + value.groupbh + 'zhangbiao');
                    obj = obj.data;
                    this.$axios
                        .get('/api/GetList/getListByTable?tableName=sys_txlGroup&strWhere=' + obj + '')
                        .then((response) => {
                            if (response.status == 200) {
                                if (response.data.code == 0) {
                                    this.groupAddOptions.visible = true;
                                    var result = response.data.data;
                                    setTimeout(() => {
                                        this.groupAdd.setFieldsValue({ "groupName": result[0].groupName, "sort": result[0].sort, "bh": result[0].bh });
                                    }, 100);
                                }
                                else {
                                    this.$message.error(response.data.msg);
                                }
                            } else {
                                // vue.prototype.$message.info('This is an error message');
                            }
                        }).catch(function (error) { // 请求失败处理
                            console.log(error)
                        });
                }


            },
            openDlgDepart: async function (action) {

                if (action == "add") {
                    this.departAddOptions.title = "新增单位";
                    this.departAdd.resetFields();
                    this.departAddOptions.visible = true;
                }
                else {
                    this.departAddOptions.title = "编辑单位";
                }
                this.departAddOptions.submitVisible = true;

                if (action == "edit") {
                    var value = this.currentSelTree;
                    if (value.departbh == "" || value.departbh == undefined) {
                        this.$message.warning('请选中一个单位');
                        return false;
                    }
                    // 从后台取数据赋值
                    var obj = await this.$jsc.DesEncrypt(this, 'bh=zhangbiao' + value.departbh + 'zhangbiao');
                    obj = obj.data;
                    this.$axios
                        .get('/api/GetList/getListByTable?tableName=sys_txlDepart&strWhere=' + obj + '')
                        .then((response) => {
                            if (response.status == 200) {
                                if (response.data.code == 0) {
                                    this.departAddOptions.visible = true;
                                    var result = response.data.data;
                                    setTimeout(() => {
                                        this.departAdd.setFieldsValue({ "depart": result[0].depart, "sort": result[0].sort, "bh": result[0].bh });
                                    }, 100);
                                }
                                else {
                                    this.$message.error(response.data.msg);
                                }
                            } else {
                                // vue.prototype.$message.info('This is an error message');
                            }
                        }).catch(function (error) { // 请求失败处理
                            console.log(error)
                        });
                }


            },
            openDlg: async function (record, action) {
                this.formAdd.resetFields()
                this.fileList = []
                this.formAddOptions.submitVisible = true
                this.formAddOptions.viewMode = false
                if (record !== undefined) {
                    // 从后台取数据赋值

                    this.formAddOptions.spinning = true
                    var obj = await this.$jsc.DesEncrypt(this, 'bh=zhangbiao' + record.bh + 'zhangbiao');
                    obj = obj.data;
                    this.formAddOptions.sortVisible = true;
                    this.$axios
                        .get('/api/GetList/getListByTable?tableName=v_sys_txl&strWhere=' + obj + '')
                        .then((response) => {
                            if (response.status == 200) {
                                if (response.data.code == 0) {
                                    this.formAddOptions.visible = true;
                                    var result = response.data.data;
                                    setTimeout(() => {
                                        this.formAdd.setFieldsValue(result[0]);
                                        this.bindDepart(result[0].departbh);

                                        //setTimeout(() => {
                                        //    this.formAdd.setFieldsValue({ departbh: result[0].departbh });
                                        //}, 200);
                                        //this.$nextTick(function () {
                                        //    this.formAdd.setFieldsValue({ departbh: result[0].departbh });
                                        //})
                                        
                                        this.formAddOptions.spinning = false;
                                    }, 100);
                                }
                                else {
                                    this.$message.error(response.data.msg);
                                    this.formAddOptions.spinning = false;
                                }
                            } else {
                                // vue.prototype.$message.info('This is an error message');
                            }
                        })
                        .catch(function (error) { // 请求失败处理
                            console.log(error)
                        })
                    if (action === 'view') { // 预览模式
                        this.formAddOptions.submitVisible = false
                        this.formAddOptions.viewMode = true
                    }
                }
                else {
                    this.formAddOptions.visible = true;
                    this.formAddOptions.sortVisible = false;
                    let s = this.formSearch.getFieldsValue();
                    this.$nextTick(()=> {
                        this.bindDepart(s.departbh);
                        this.formAdd.setFieldsValue({ sort: 0, groupbh: s.groupbh })
                    })

                }
            },
            groupAddSubmit() {
                this.groupAdd.validateFields((err, values) => {
                    if (!err) {

                        this.groupAddOptions.submitLoading = true

                        this.$axios({
                            url: '/api/txl/txlGroupAdd',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('保存成功')
                                    this.groupAddOptions.visible = false
                                    this.groupAddOptions.submitLoading = false
                                    this.bindGroupList1();
                                } else {
                                    console.log(res);
                                    throw new Error("服务器超时2");
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.groupAddOptions.submitLoading = false
                        })

                    }
                })
            },
            departAddSubmit() {
                this.departAdd.validateFields((err, values) => {
                    if (!err) {
                        values.groupbh = this.currentSelTree.groupbh;
                        this.departAddOptions.submitLoading = true
                        this.$axios({
                            url: '/api/txl/txlDepartAdd',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('保存成功')
                                    this.departAddOptions.visible = false
                                    this.departAddOptions.submitLoading = false
                                    this.bindGroupList1();
                                } else {
                                    throw new Error("服务器超时2");
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.departAddOptions.submitLoading = false
                        })

                    }
                })
            },
            formAddSubmit() {
                this.formAdd.validateFields((err, values) => {
                    if (!err) {
                        console.log(values);
                        //return;
                        this.formAddOptions.submitLoading = true
                        this.$axios({
                            url: '/api/txl/txlAdd',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('保存成功')
                                    this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false
                                    this.bindList()
                                } else {
                                    throw new Error("服务器超时2");
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })

                    }
                })
            },
            init() {
                var that = this
                size()
                // this.getcompanylist()
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 270
                        }
                    })
                }

            },

            actions() {
                if (this.inlineButtons.length == 0) {
                    //this.columns.pop();
                }
            }
        }
    }

</script>
<style>
    .ant-layout-footer {
        display: none;
    }

    .ant-layout {
        background: #ffffff;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
        padding: 20px 20px 0;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }
</style>