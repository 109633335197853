<template>
    <div style="width:800px;">
        <workInfo ref="workInfo" :dataOptions="workInfo_Options" v-show="false"></workInfo>
        <workProcess ref="workProcess" :dataOptions="workProcess_Options" v-show="false"></workProcess>


        <a-descriptions title="老城区河道抓拍信息" :column="2" bordered class="bjxxViewTitle">
            <a-descriptions-item label="报警时间">
                {{bjxx.happenTime}}
            </a-descriptions-item>
            <a-descriptions-item label="报警类型">
                水葫芦抓拍
            </a-descriptions-item>
            <a-descriptions-item label="抓拍点">
                {{bjxx.devName}}
            </a-descriptions-item>
            <a-descriptions-item label="是否派单">
                <span v-if="bjxx.pdcs>0">已派单</span>
                <span v-else-if="bjxx.pdcs==0">未派单</span>
            </a-descriptions-item>
            <a-descriptions-item label="照片" :span="2">
                <img :src="$axios.defaults.fileURL+bjxx.snapshoot" style="width:630px; height:450px;">
            </a-descriptions-item>
            <template v-if="bjxx.clr!=null">
                <a-descriptions-item label="处理单位">
                    {{bjxx.clr}}
                </a-descriptions-item>
                <a-descriptions-item label="处理时间" v-if="bjxx.clsj!=null">
                    {{bjxx.clsj}}
                </a-descriptions-item>
                <a-descriptions-item label="处理内容" :span="2" v-if="bjxx.clsj!=null">
                    {{bjxx.clnr}}
                </a-descriptions-item>
                <a-descriptions-item label="处理后照片" :span="2" v-if="bjxx.files_clfjbh.length>0">
                    <img v-if="bjxx.files_clfjbh.length>0" :src="$axios.defaults.fileURL+bjxx.files_clfjbh[0].path" style="width:630px; height:450px;">
                </a-descriptions-item>
                <a-descriptions-item label="审批结果" v-if="bjxx.clzt!=null">
                    <span v-if="bjxx.clzt=='1'" style="color:red;">不同意</span>
                    <span v-if="bjxx.clzt=='2'" style="color:green">同意</span>
                </a-descriptions-item>
            </template>
        </a-descriptions>


        <a-form :form="formAdd">
            <div style="display:none">
                <a-input v-decorator="['id']" v-show="false" />
            </div>

            <a-row justify="space-between" :gutter="16" >
                <a-col :span="24" v-if="formAddVisible.step"  v-show="false">
                    <a-form-item label="处理类型" >
                        <a-select v-decorator="['step', { rules: [{ required: true, message: '请选择处理类型' }]}]" placeholder="请选择处理类型" style="width: 100%" @change="step_change">
                            <a-select-option value="申请办结">
                                申请办结
                            </a-select-option>
                            <a-select-option value="申请延期">
                                申请延期
                            </a-select-option>
                            <a-select-option value="申请变更办理单位">
                                申请变更办理单位
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="24" v-if="formAddVisible.recState">
                    <a-form-item label="审批结果">
                        <a-radio-group v-decorator="['recState', { rules: [{ required: true, message: '请选择审批结果' }]}]" button-style="solid" @change="recState_change">
                            <a-radio-button value="2">
                                同意
                            </a-radio-button>
                            <a-radio-button value="1">
                                不同意
                            </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                </a-col>
                <a-col :span="24" v-if="formAddVisible.clsx">
                    <a-form-item label="延期至">
                        <a-date-picker style="width: 100%;" v-decorator="['clsx_new', { rules: [{ required: true, message: '请选择延期后的时间' }]}]" placeholder="请选择延期后的时间" :disabled="formAddOptions.viewMode" />
                    </a-form-item>
                </a-col>
                <a-col :span="24" v-if="formAddVisible.recLoginid">
                    <a-form-item label="办理人">
                        <a-select v-decorator="['recLoginid_new',{ rules: [{ required: true, message: '请选择新处理人'}]},]" placeholder="选择新处理人" :disabled="formAddOptions.viewMode" :options="recData">
                        </a-select>
                    </a-form-item>
                </a-col>

                <a-col :span="24">
                    <a-form-item label="说明">
                        <a-textarea v-decorator="['recContent', { rules: [{ required: false, message: '请输入办理说明' }]}]" style="resize: none;height: 100px;" placeholder="办理说明" :disabled="formAddOptions.viewMode" />
                    </a-form-item>
                </a-col>
                <a-col :span="24" style="">
                    <a-upload class='a'
                              :disabled="formAddOptions.viewMode"
                              :multiple="false"
                              list-type="picture-card"
                              :file-list="fileList"
                              @preview="filesPreview"
                              @change="filesChange"
                              :customRequest="customFilesUpload">
                        <div v-if="fileList.length < 8">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">
                                上传附件
                            </div>
                        </div>
                    </a-upload>
                </a-col>


                <a-col :span="24" style="text-align:center;">
                    <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible" style="width:100px;">确定</a-button>
                    <a-button key="reset" type="" @click="resetFields" v-if="formAddOptions.submitVisible" style="margin-left:16px;width:100px;">重置</a-button>
                </a-col>
            </a-row>

        </a-form>

        <a-modal :visible="previewVisible" :footer="null" @cancel="filesCancelPreview">
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>

    </div>
</template>
<script>
    import workInfo from './workInfo'
    import workProcess from './workProcess'

    export default {
        data() {
            return {
                loading: '',
                action: '',
                previewVisible: false,
                previewImage: '',
                fileList: [],
                formAddOptions: {
                    visible: true,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false,
                },
                formAddVisible: {
                    clsx: false,
                    step: false,
                    recState: false,
                    recLoginid: false
                },
                recData: [
                    {
                        label: "张浦镇", value: "201228104026863957"
                    },
                    {
                        label: "锦溪镇", value: "201228104040753669"
                    },
                    {
                        label: "周市镇", value: "201228104054748966"
                    },
                    {
                        label: "陆家镇", value: "201228104116309456"
                    }
                ],
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
                workInfo_Options: {
                    workbh:""
                },
                workProcess_Options: {
                    workbh: ""
                },
                queryParam: {
                    processid: this.$route.query.processid,
                    workbh: this.$route.query.workbh,
                    infobh: this.$route.query.infobh,
                },
                clzj: "./work",
                processInfo: {},
                bjxx: {},
                TableListSearchModel: {
                    pageIndex: 1,
                    pageSize: 1,
                    fields: "*",
                    where: {
                    }
                },
            }
        },
        mounted() {
            
        },
        created() {
            this.workInfo_Options.workbh = this.queryParam.workbh;
            this.workProcess_Options.workbh = this.queryParam.workbh;
            //components.cardList = resolve => require(["./work"], resolve);
            //this.list = () => import('./work');
            
            this.$axios({
                url: '/api/work/getList_v_commonWorkProcess',
                method: 'post',
                data: {
                    where: { processid: this.queryParam.processid }
                }
            }).then(res => {              
                if (res.data.code == 0) {
                    this.processInfo = res.data.data.list[0];
                    this.setdoform();
                }
            }).catch(error => { // 请求失败处理

            });


            this.TableListSearchModel.where.bh = this.queryParam.infobh;
            this.$axios({
                url: "/api/hdsp/getList_hdsp_bjxx",
                method: "post",
                data: this.TableListSearchModel
            }).then((res) => {
                this.bjxx = res.data.data.list[0];
            }).catch((res) => {

            });
            
        },
        beforeUpdate() {
            setTimeout(() => {
                this.formAdd.setFieldsValue({ step: "申请办结" });
            }, 0)
        },

        components: {
            workInfo,
            workProcess,
            //cardList: resolve => require(["./work"], resolve),
        },
        methods: {
            recState_change(value, option) {
                if (this.processInfo.step == "申请变更办理单位") {
                    if (value.target.value == 2) {
                        this.formAddVisible.recLoginid = true;
                    }
                    else {
                        this.formAddVisible.recLoginid = false;
                    }
                }
            },
            step_change(value, option) {
                this.formAddVisible.clsx = false;
                switch (value) {
                    case "申请延期":
                        this.formAddVisible.clsx = true;
                        break;
                    default:
                        break;
                }
            },
            setdoform() {
                //console.log(processInfo);
                //设置干活页面表单内容
                //根据当前处理流程的step
                switch (this.processInfo.step) {
                    case "申请办结":
                        this.formAddVisible.recState = true;
                        this.formAddVisible.recLoginid = false;
                        break;
                    case "申请延期":
                        this.formAddVisible.recState = true;
                        break;
                    case "派单":
                        this.formAddVisible.step = true;
                        break;
                    case "申请变更办理单位":
                        this.formAddVisible.recState = true;
                        this.formAddVisible.recLoginid= true;
                        break;
                    default:
                        break;
                }
                
                this.formAdd.setFieldsValue({ recState: "2" });
                
            },
            btnClick(text, item) {
                let _this = this;
                switch (text) {
                    case '新增':
                        this.openDlg()
                        break
                    case "修改":
                        this.openDlg(item);
                        break;
                    case "删除":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定要删除 " + item.qymc + " 吗?",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.del(item);
                            }
                        });
                        break;
                }
            },
            formAddSubmit() {
                this.formAdd.validateFields((err, values) => {
                    if (!err) {
                        values.files_recfilesbh = this.fileList
                        values.recSource = "PC";
                        values.processid = this.queryParam.processid;
                        values.workbh = this.queryParam.workbh;
                        this.formAddOptions.submitLoading = true;
                        this.$axios({
                            url: '/api/work/workdo',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('处理成功')
                                    this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false
                                    this.$router.push("workList_do");
                                } else {
                                    console.log(res);
                                    throw new Error("服务器超时2");
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })
                    }
                })
            },
            resetFields() {
                this.formAdd.resetFields();
            },
            filesCancelPreview() {
                this.previewVisible = true
            },
            filesPreview(file) {
                location.href = file.url;
            },
            filesChange: function (file) {
                if (file.file.status === 'removed') {
                    this.fileList = file.fileList
                }
            },
            customFilesUpload(data) {
                const formData = new FormData()
                formData.append('file', data.file)
                this.saveFile(formData)
            },
            saveFile(formData) {
                this.$axios(
                    {
                        method: 'post',
                        url: this.$axios.defaults.fileURL + "/api/FileSet/Upload",
                        data: formData
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            //console.log(res);
                            var num = this.fileList.length
                            this.fileList.push(res.data.data)
                            this.uploadgetText('a', num, res.data.data);
                        }
                        else {
                            throw new Error("服务器器超时");
                        }
                    })
                    .catch((error) => {
                        this.$message.error(error.message);
                        console.log(error)
                    })
            },
            /**
             * @param {String} classname；upload的类名
             */
            editgetText(classname) {
                this.$nextTick(() => {
                    var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    //console.log(els)
                    for (var i = 0, len = els.length; i < len; i++) {
                        var type = els[i].textContent.split('.')[1]
                        if (type !== 'png' && type !== 'jpg') {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = els[i].textContent
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + type
                            }
                            cel.innerText = temptext
                            spanels[i].prepend(cel)
                        }
                    }
                })
            },
            /**
             * @param {String} classname；upload的类名
             * @param {Number} num；原有的已上传文件数量
             * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
             */
            uploadgetText(classname, num, response) {
                if (response.ext == ".jpg" || response.ext == ".png" || response.ext == ".gif" || response.ext == ".jpeg") {
                    return false;
                }
                this.$nextTick(() => {
                    //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    var tempNodeList = []
                    for (var i = 0; i < spanels.length - num; i++) {
                        // console.log(spanels[num+i])
                        tempNodeList.push(spanels[num + i])
                        for (var j = 0; j < tempNodeList.length; j++) {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = response.name
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + response.ext
                            }
                            cel.innerText = temptext
                            tempNodeList[j].prepend(cel)
                        }
                    }
                })
            },
        }
    }

</script>
<style>
    .bjxxViewTitle .ant-descriptions-title {
        font-size: 30px !important;
        text-align: center;
    }
</style>