<template>
  <div id="components-form-demo-advanced-search">
    <a-modal v-model="formAddOptions.visible" title="新增" width="800px">
      <a-spin :spinning="formAddOptions.spinning">
        <a-form :form="formAdd" :label-col="{ span:8 }" :wrapper-col="{ span:16 }" labelAlign="left">
          <a-form-item label="" style="display:none">
            <a-input v-decorator="['id']" />
          </a-form-item>

          <a-row type="flex" justify="space-between">
            <a-col :span="24">
              <a-row>
                <a-form-item :label-col="{ span:10 }" :wrapper-col="{ span:24 }">
                  <a-select placeholder="请选择维保企业" :disabled="formAddOptions.viewMode" show-search option-filter-prop="children" :filter-option="filterOption" v-decorator="web_decorator_qymc">
                    <a-select-option v-for="items in select_data" :key="items.value">
                      {{items.name}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between">
            <a-col :span="11">
              <a-form-item label="姓名">
                <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入姓名' },{max:50,message:'最大长度为50'}]}]" placeholder="姓名" :disabled="formAddOptions.viewMode" />
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item label="性别">
                <a-select v-decorator="['sex',{ rules: [{ required: true, message: '请选择性别'}]},]" placeholder="请选择" :disabled="formAddOptions.viewMode">
                  <a-select-option value="男">
                    男
                  </a-select-option>
                  <a-select-option value="女">
                    女
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between">
            <a-col :span="11">
              <a-form-item label="联系电话">
                <a-input v-decorator="[ 'lxdh',{ rules: [{ required: true, message: ' ' },{validator:phoneCheck.bind(this)}],},]" style="width: 100%" placeholder="联系电话" :disabled="formAddOptions.viewMode">
                  <!-- <a-select slot="addonBefore" v-decorator="['prefix', { initialValue: '86' }]" style="width: 70px" :disabled="formAddOptions.viewMode">
                        <a-select-option value="86">
                            +86
                        </a-select-option>
                        <a-select-option value="87">
                            +87
                        </a-select-option>
                    </a-select> -->
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-row>
                <a-form-item label="身份证号">
                  <a-input v-decorator="['sfzh', { rules: [{ required: true, message: ' ' },{validator:SFZCheck.bind(this)}],}]" placeholder="身份证号码" :disabled="formAddOptions.viewMode" />
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between">
            <a-col :span="11">
              <a-form-item label="职务">
                <a-input v-decorator="['job']" style="width: 100%" placeholder="职务" :disabled="formAddOptions.viewMode">
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item label="出生日期">
                <a-date-picker v-decorator="['birthday']" :defaultPickerValue="moment('1980-01-01')" style="width: 100%" :disabled="formAddOptions.viewMode" />
              </a-form-item>
            </a-col>
          </a-row>
          <!-- <a-row type="flex" justify="space-between">
       <a-col :span="24">
           <a-row>
               <a-form-item label="业务范围"   :label-col="{ span:10 }" :wrapper-col="{ span:24 }">
                   <a-input type="textarea" v-decorator="['businessScope', { rules: [{ required: false, message: '请输入业务范围' }]}]" placeholder="业务范围" :disabled="formAddOptions.viewMode" />
               </a-form-item>
           </a-row>
       </a-col>
    </a-row> -->
          <a-divider orientation="left"><span style="color:red;margin-right:4px;vertical-align: middle;">*</span>资质照片</a-divider>
        </a-form>
        <a-upload class='a' :disabled="formAddOptions.viewMode" name="a" :multiple="false" list-type="picture-card" :file-list="fileList" @preview="filesPreview" @change="filesChange" :customRequest="customFilesUpload">
          <div v-if="fileList.length<5">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传照片
            </div>
          </div>
        </a-upload>
        <a-modal :visible="previewVisible" :footer="null" @cancel="filesCancelPreview">
          <img alt="人像" style="width: 100%" :src="previewImage" />
        </a-modal>

        <a-divider orientation="left">头像</a-divider>
        <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :customRequest="customFilesUpload2" @change="handleChange" :disabled="headImg_disabled">
          <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width:80px;height:80px;" />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">
              上传头像
            </div>
          </div>
        </a-upload>
      </a-spin>
      <template slot="footer">
        <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
        <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
      </template>
    </a-modal>
    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="">
            <a-select v-decorator="[`companybh`]" placeholder="维保企业">
              <a-select-option v-for="items in select_data" :key="items.value">
                {{items.name}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="姓名" v-decorator="[`name`]" />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="联系电话" v-decorator="[`lxdh`]"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="身份证号码" v-decorator="[`sfzh`]"></a-input>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="[24,12]">
        <a-col :span="6" :style="{ textAlign: 'left' }">
          <a-button type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button>
          <!-- primary/waring/danger/green -->
        </a-col>
        <a-col :span="18" :style="{ textAlign: 'right' }">
          <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
          <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
            {{ expand ?'收起':'展开' }}
            <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>

    <div class="search-result-list" :style="{ marginTop: '0px' }">
      <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="20" :total="50" rowKey="id" :loading="loading" :scroll="scroll" @change="pageCurrentChange" position="bottom" tableLayout="fixed">
        <a slot="name" slot-scope="text,record" @click="openDlg(record,'view')">{{ text }}</a>
        <span slot="action" slot-scope="text, record">
          <a-popconfirm v-if="button.title=='审核'" v-for='button in inlineButtons' title="是否通过审核?" ok-text="通过" cancel-text="不通过" @confirm="confirm(record)" @cancel="cancel(record)">
            <a-button :type='button.className' size="small" :key="button.title" style='margin-right:5px;display:inline'>
              {{button.title}}
            </a-button>
          </a-popconfirm>
          <a-button v-if="button.title!='审核'" :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right:5px;display:inline'>
            <a-icon :type="button.iconLink" />
          </a-button>
        </span>
        <span slot="headImgPath" slot-scope="text, record">
          <img v-if="text" :src="baseUrl+text" style="height:50px;width:50px;" />
        </span>
        <span slot="state" slot-scope="text, record">
          <a-tag v-if="text==1" color="orange">不通过</a-tag>
          <a-tag v-if="text==2" color="green">通过</a-tag>
          <a-tag v-if="text!=1&&text!=2" color="red">未审核</a-tag>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
//import { DesEncrypt } from '@/utils/Jscript'
const loading = true
const strWhere = 'del=0'
const action = 'add'
export default {
  data() {
    return {
      showexport: false,
      scroll: {
        y: 900,
        x: 900
      },
      //actionList: {
      //    add: false,
      //    remove: false,
      //    edit: false
      //},
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [],
      select_data: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'rownum',
          width: 60,
          key: 'rownum'
        },
        {
          title: '头像',
          dataIndex: 'headImgPath',
          key: 'headImgPath',
          width: 100,
          scopedSlots: { customRender: 'headImgPath' }
        },
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          width: 100,
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '性别',
          dataIndex: 'sex',
          width: 80,
          key: 'sex'
        },

        {
          title: '联系电话',
          dataIndex: 'lxdh',
          width: 100,
          key: 'lxdh'
        },
        {
          title: '身份证',
          dataIndex: 'sfzh',
          width: 150,
          key: 'sfzh'
        },
        {
          title: '所属公司',
          dataIndex: 'companyName',
          width: 100,
          key: 'companyname'
        },
        {
          title: '职务',
          dataIndex: 'job',
          width: 120,
          key: 'job'
        },
        {
          title: '审核状态',
          dataIndex: 'state',
          width: 200,
          key: 'state',
          scopedSlots: { customRender: 'state' }
        },

        {
          title: '操作',
          width: 170,
          key: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading,
      action,
      previewVisible: false,
      headImg_disabled: false,
      previewImage: '',
      fileList: [],
      imageUrl: '', //'http://58.211.49.230:8051/upload/2020/10/15/193321/%E5%9B%9B%E8%AE%AE%E4%B8%A4%E5%85%AC%E5%BC%80.png',
      imagePath: '',
      baseUrl: this.$axios.defaults.fileURL,
      strWhere,
      expand: false,
      formAddOptions: {
        visible: false,
        submitLoading: false,
        submitVisible: true,
        viewMode: false,
        spinning: false
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
      formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
      pagination: {
        total: 0,
        pageSize: 15, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom'
      },
      web_decorator_qymc: [
        'companybh',
        { rules: [{ required: true, message: '请选择维保公司' }] }
      ]
    }
  },
  mounted() {
    console.log(this.$route.meta)
    //将上面的按钮和表格内的按钮分出来
    if (this.$route.meta.buttons) {
      for (let i = 0; i < this.$route.meta.buttons.length; i++) {
        if (this.$route.meta.buttons[i].tag == 'inline') {
          this.inlineButtons.push(this.$route.meta.buttons[i])
        } else {
          this.buttons.push(this.$route.meta.buttons[i])
        }
      }
    }

    this.init()
    this.actions()
    window.onresize = () => {
      this.init()
    }
    this.bindList()
    //console.log(this.$route);
    //console.log(this.buttons);
    //console.log(this.inlineButtons);
  },
  methods: {
    moment,
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        this.getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl
          this.loading = false
          console.log(info.file.originFileObj)
        })
      }
    },
    getBase64(img, callback) {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(img)
    },
    btnClick(text, item) {
      let _this = this
      switch (text) {
        case '新增':
          this.openDlg()
          break
        case '编辑':
          this.openDlg(item)
          break
        case '删除':
          this.$confirm({
            title: '操作确认',
            content: '确定要删除 ' + item.companyName + ' 吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.del(item)
            }
          })
          break
      }
    },
    image_remove() {
      this.imageUrl = ''
      this.imagePath = ''
    },
    confirm(record) {
      this.sh(record.id, 2)
    },
    cancel(record) {
      this.sh(record.id, 1)
    },
    sh(s_id, s_state) {
      var _this = this
      this.$axios({
        url: '/api/zzgl/ExamineWbry',
        method: 'post',
        data: { id: s_id, state: s_state }
      })
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.code == 0) {
              _this.$message.info(response.data.msg)
              _this.bindList()
            }
          }
        })
        .catch(function (error) {
          throw new Error('服务器超时')
        })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    phoneCheck(rule, value, callbackFn) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!reg.test(value)) {
        callbackFn('请输入正确的手机号码')
        return
      }
      callbackFn()
    },
    SFZCheck(rule, value, callbackFn) {
      const reg = /^(\d{18}|\d{15}|\d{17}x)$/
      if (!reg.test(value)) {
        callbackFn('请输入正确的身份证号码')
        return
      }
      callbackFn()
    },
    bindList: async function () {
      this.formSearch.validateFields((error, values) => {
        error = 1
        // console.log(values)
        this.strWhere = 'del=0 '
        for (var obj in values) {
          if (values[obj]) {
            if (obj == 'companybh') {
              this.strWhere += ' and ' + obj + "='" + values[obj] + "'"
            } else {
              this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
            }
          }
        }
        console.log(this.strWhere)
        this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
      })
      this.loading = true

      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere)
      this.strWhere = this.strWhere.data
      await this.InitGrid()
    },
    InitGrid: function () {
      var _that = this
      _that.loading = true
      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName: 'v_zzgl_wbry',
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          // filesFields: 'bh',
          strWhere: _that.strWhere
        }
      })
        .then(function (response) {
          console.log(response)
          _that.loading = false
          if (response.status === 200) {
            _that.tableData = response.data.data
            _that.pagination.total = response.data.count
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    formSearchSubmit(e) {
      e.preventDefault()
      this.pagination.current = 1
      this.bindList()
    },
    formSearchReset() {
      this.formSearch.resetFields()
      this.strWhere = 'del=0'
      this.bindList()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    pageCurrentChange(pagination) {
      this.pagination.current = pagination.current
      this.bindList()
    },
    del(record) {
      this.$axios({
        url: '/api/Comon/deltag',
        method: 'post',
        data: {
          tableName: 'zzgl_wbry',
          field: 'id',
          value: record.id,
          source: 'web',
          loginid: ''
        }
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('删除成功')
              this.bindList()
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },
    openDlg: async function (record, action) {
      this.formAdd.resetFields()
      this.fileList = []
      this.imageUrl = ''
      this.imagePath = ''
      this.formAddOptions.submitVisible = true
      this.formAddOptions.viewMode = false
      this.headImg_disabled = false
      if (record !== undefined) {
        // 从后台取数据赋值
        this.formAddOptions.spinning = true
        var obj = await this.$jsc.DesEncrypt(
          this,
          'id=zhangbiao' + record.id + 'zhangbiao'
        )
        obj = obj.data
        this.$axios
          .get(
            '/api/GetList/getListByTable?tableName=zzgl_wbry&strWhere=' +
              obj +
              '&filesFields=bh'
          )
          .then(response => {
            if (response.status == 200) {
              if (response.data.code == 0) {
                this.formAddOptions.visible = true
                var result = response.data.data
                console.log(response.data)
                setTimeout(() => {
                  result[0].yyqk = true
                  // var objArr = { qymc: result[0].qymc, lxr: result[0].lxr, yyqk: true }
                  // this.formAdd.setFieldsValue(objArr)
                  this.formAdd.setFieldsValue(result[0])
                  this.fileList = result[0].files_bh
                  this.imageUrl =
                    result[0].headImgPath == ''
                      ? ''
                      : this.$axios.defaults.fileURL + result[0].headImgPath
                  //this.getBase64(info.file.originFileObj, temp_url => {
                  //    this.imageUrl = temp_url;
                  //});
                  this.editgetText('a')
                  this.formAddOptions.spinning = false
                }, 100)
              } else {
                this.$message.error(response.data.msg)
                this.formAddOptions.spinning = false
              }
            } else {
              // vue.prototype.$message.info('This is an error message');
            }
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error)
          })
        if (action === 'view') {
          // 预览模式
          this.formAddOptions.submitVisible = false
          this.formAddOptions.viewMode = true
          this.headImg_disabled = true
        }
      } else {
        this.formAddOptions.visible = true
      }
    },
    filesCancelPreview() {
      this.previewVisible = false
    },
    async filesPreview(file) {
      //console.log(file.ext)
      // if (!file.url && !file.preview) {
      //   file.preview = await getBase64(file.originFileObj)
      // }
      // this.previewImage = file.url || file.preview
      // this.previewVisible = true
      location.href = file.url
    },
    async filesPreview2(file) {
      //console.log(file.ext)
      // if (!file.url && !file.preview) {
      //   file.preview = await getBase64(file.originFileObj)
      // }
      // this.previewImage = file.url || file.preview
      // this.previewVisible = true
      location.href = file.url
    },
    filesChange: function (file) {
      if (file.file.status === 'removed') {
        this.fileList = file.fileList
      }
    },
    customFilesUpload(data) {
      var _this = this
      const formData = new FormData()
      formData.append('file', data.file)
      formData.append('token', 'aiufpaidfupipiu') // 随便写一个token示例

      this.$jsc.picCompress(data.file, 600, this.saveFile)
    },
    customFilesUpload2(data) {
      const formData = new FormData()
      formData.append('file', data.file)
      //formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
      this.saveFile2(formData)
    },
    formAddSubmit() {
      this.formAdd.validateFields((err, values) => {
        if (this.fileList.length == 0) {
          this.$message.error('请上传资质照片')
          return
        }
        if (!err) {
          this.formAddOptions.submitLoading = true
          values.files_bh = this.fileList
          values.headImgPath = this.imagePath
          this.$axios({
            url: '/api/zzgl/EditWbRy',
            method: 'post',
            data: values
          })
            .then(res => {
              if (res.status == 200) {
                if (res.data.code === 0) {
                  this.$message.info('保存成功')
                  this.formAddOptions.visible = false
                  this.formAddOptions.submitLoading = false
                  this.bindList()
                } else {
                  console.log(res)
                  throw new Error('服务器超时2')
                }
              } else {
                throw new Error('服务器超时2')
              }
            })
            .catch(error => {
              // 请求失败处理
              this.$message.error(error.message)
              this.formAddOptions.submitLoading = false
            })
        }
      })
    },
    saveFile(formData) {
      var that = this
      this.$axios({
        method: 'post',
        url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
        data: formData
      })
        .then(res => {
          if (res.status == 200) {
            var num = that.fileList.length
            that.fileList.push(res.data.data)
            // that.uploadgetText('a', num, res.data.data);
          } else {
            throw new Error('服务器器超时')
          }
        })
        .catch(error => {
          that.$message.error(error.message)
          console.log(error)
        })
    },
    saveFile2(formData) {
      var that = this
      this.$axios({
        method: 'post',
        url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
        data: formData
      })
        .then(res => {
          if (res.status == 200) {
            this.imageUrl = res.data.data.url
            this.imagePath = res.data.data.path
          } else {
            throw new Error('服务器器超时')
          }
        })
        .catch(error => {
          that.$message.error(error.message)
          console.log(error)
        })
    },
    getcompanylist() {
      // 获取维保企业列表
      this.$axios({
        url: '/api/Zzgl/getWbCompany',
        method: 'get'
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code === 0) {
              this.select_data = res.data.data
            } else {
              console.log(res)
              throw new Error('服务器超时2')
            }
          } else {
            throw new Error('服务器超时2')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
          this.formAddOptions.submitLoading = false
        })
    },
    init() {
      var that = this
      size()
      this.getcompanylist()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector(
            '#components-form-demo-advanced-search>form'
          )
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('p')[0] - 270
          }
        })
      }
    },
    /**
     * @param {String} classname；upload的类名
     */
    editgetText(classname) {
      this.$nextTick(() => {
        var els = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span'
        )
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        //console.log(els)
        for (var i = 0, len = els.length; i < len; i++) {
          var type = els[i].textContent.split('.')[1]
          if (type !== 'png' && type !== 'jpg') {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = els[i].textContent
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + '...' + type
            }
            cel.innerText = temptext
            spanels[i].prepend(cel)
          }
        }
      })
    },
    /**
     * @param {String} classname；upload的类名
     * @param {Number} num；原有的已上传文件数量
     * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
     */
    uploadgetText(classname, num, response) {
      this.$nextTick(() => {
        //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        var tempNodeList = []
        for (var i = 0; i < spanels.length - num; i++) {
          // console.log(spanels[num+i])
          tempNodeList.push(spanels[num + i])
          for (var j = 0; j < tempNodeList.length; j++) {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = response.name
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + '...' + response.ext
            }
            cel.innerText = temptext
            tempNodeList[j].prepend(cel)
          }
        }
      })
    },
    actions() {
      if (this.inlineButtons.length == 0) {
        this.columns.pop()
      }
    }
  }
}

//function getBase64(file) {
//    return new Promise((resolve, reject) => {
//        const reader = new FileReader()
//        reader.readAsDataURL(file)
//        reader.onload = () => resolve(reader.result)
//        reader.onerror = error => reject(error)
//    })
//}
</script>
<style>
.ant-layout-footer {
  display: none;
}

#components-form-demo-advanced-search {
  background: white;
  box-sizing: border-box;
  padding: 20px 20px 0;
}

.ant-form-item {
  margin-bottom: 12px;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: !important;
}

/*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 1px 1px 1px 1px !important;
}
</style>