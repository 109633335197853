<template>
  <div id="components-form-demo-advanced-search">
    <a-modal v-model="formAddOptions.visible" title="新增" width="800px">
      <a-spin :spinning="formAddOptions.spinning">
        <a-form :form="formAdd" :label-col="{ span:8 }" :wrapper-col="{ span:16 }" labelAlign="left">
          <a-form-item label="" style="display:none">
            <a-input v-decorator="['id']" />
          </a-form-item>
          <a-row type="flex" justify="space-between">
            <a-col :span="11">
              <a-form-item label="登录名称">
                <a-input v-decorator="['loginName', { rules: [{ required: true, message: '请输入登录名称' },{max:12,message:'最大长度为12'},{min:6,message:'最小长度为6'}]}]" placeholder="登录名称" :disabled="formAddOptions.loginNameviewMode" />
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item label="企业名称">
                <a-input v-decorator="['companyName', { rules: [{ required: true, message: '请输入企业名称' },{max:50,message:'最大长度为50'}]}]" placeholder="企业名称" :disabled="formAddOptions.viewMode" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between">
            <a-col :span="11">
              <a-form-item label="负责人">
                <a-input v-decorator="['fzr', { rules: [{ required: true, message: '请输入负责人' },{max:50,message:'最大长度为50'}]}]" placeholder="企业负责人" :disabled="formAddOptions.viewMode" />
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item label="负责人电话">
                <a-input v-decorator="[ 'lxdh',{ rules: [{ required: true, message: ' ' },{validator:phoneCheck.bind(this)}],},]" placeholder="负责人电话" style="width: 100%" :disabled="formAddOptions.viewMode">
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-row>
                <a-form-item label="地址" :label-col="{ span:10 }" :wrapper-col="{ span:24 }">
                  <a-input v-decorator="['address', { rules: [{ required: false, message: '请输入企业地址' },{max:200,message:'最大长度为200'}]}]" placeholder="企业地址" :disabled="formAddOptions.viewMode" />
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between">
            <a-col :span="24">
              <a-row>
                <a-form-item label="业务范围" :label-col="{ span:10 }" :wrapper-col="{ span:24 }">
                  <a-input type="textarea" v-decorator="['businessScope', { rules: [{ required: false, message: '请输入业务范围' },{max:5000,message:'最大长度为5000'}]}]" placeholder="业务范围" :disabled="formAddOptions.viewMode" />
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between">
            <a-col :span="24">
              <a-row>
                <a-form-item label="备注" :label-col="{ span:10 }" :wrapper-col="{ span:24 }">
                  <a-input type="textarea" :rows="5" v-decorator="['remark', { rules: [{ required: false, message: '请输入备注' },{max:500,message:'最大长度为500'}]}]" placeholder="备注" :disabled="formAddOptions.viewMode" />
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
      <template slot="footer">
        <a-button key="back" @click="formAddOptions.visible=false;">关闭</a-button>
        <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
      </template>
    </a-modal>
    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="企业名称" v-decorator="[`companyName`]" />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="负责人" v-decorator="[`fzr`]" />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="负责人电话" v-decorator="[`lxdh`]"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="业务范围" v-decorator="[`businessScope`]"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <!-- <a-row :gutter="24" v-if="expand">
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="姓名" v-decorator="['field5']"></a-input></a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="姓名" v-decorator="['field6']"></a-input></a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="姓名" v-decorator="['field7']"></a-input></a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="姓名" v-decorator="['field3']"></a-input></a-form-item>
                </a-col>
            </a-row> -->
      <a-row :gutter="[24,12]">
        <a-col :span="6" :style="{ textAlign: 'left' }">
          <a-button type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button>
          <!-- primary/waring/danger/green -->
        </a-col>
        <a-col :span="18" :style="{ textAlign: 'right' }">
          <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
          <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
            {{ expand ?'收起':'展开' }}
            <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>
    <div class="search-result-list" :style="{ marginTop: '0px' }">
      <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="20" :total="50" rowKey="id" :loading="loading" :scroll="scroll" @change="pageCurrentChange" position="bottom" tableLayout="fixed">
        <a slot="companyName" slot-scope="text,record" @click="openDlg(record,'view')">{{ text }}</a>
        <span slot="action" slot-scope="text, record">
          <a-button v-if="button.title=='重置密码'" type='primary' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right:5px;display:inline'>{{button.title}}</a-button>
          <a-button v-if="button.title!='重置密码'" :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right:5px;display:inline'>
            <a-icon :type="button.iconLink" />
          </a-button>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
const loading = true
const strWhere = 'del=0'
const action = 'add'
export default {
  data() {
    return {
      showexport: false,
      scroll: {
        y: 900,
        x: 900
      },
      //actionList: {
      //    add: false,
      //    remove: false,
      //    edit: false
      //},
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'rownum',
          width: 60,
          fixed: 'left',
          key: 'rownum'
        },
        {
          title: '企业名称',
          dataIndex: 'companyName',
          key: 'companyName',
          width: 150,
          scopedSlots: { customRender: 'companyName' }
        },
        {
          title: '登录账号',
          dataIndex: 'loginName',
          width: 150,
          key: 'loginName'
        },
        {
          title: '企业负责人',
          dataIndex: 'fzr',
          width: 120,
          key: 'fzr'
        },

        {
          title: '负责人电话',
          dataIndex: 'lxdh',
          width: 120,
          key: 'lxdh'
        },
        {
          title: '地址',
          dataIndex: 'address',
          width: 150,
          key: 'address'
        },
        {
          title: '业务范围',
          dataIndex: 'businessScope',
          width: 250,
          key: 'businessScope'
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading,
      action,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      strWhere,
      expand: false,
      formAddOptions: {
        visible: false,
        submitLoading: false,
        submitVisible: true,
        viewMode: false,
        spinning: false,
        loginNameviewMode: false
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
      formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
      pagination: {
        total: 0,
        pageSize: 15, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom'
      }
    }
  },
  mounted() {
    console.log(this.$route)
    //将上面的按钮和表格内的按钮分出来
    console.log(this.$route)
    if (this.$route.meta.buttons) {
      for (let i = 0; i < this.$route.meta.buttons.length; i++) {
        if (this.$route.meta.buttons[i].tag == 'inline') {
          this.inlineButtons.push(this.$route.meta.buttons[i])
        } else {
          this.buttons.push(this.$route.meta.buttons[i])
        }
      }
    }

    this.init()
    //console.log(this.columns);
    this.actions()
    window.onresize = () => {
      this.init()
    }
    this.bindList()
    //console.log(this.$route);
    //console.log(this.buttons);
    //console.log(this.inlineButtons);
  },
  methods: {
    btnClick(text, item) {
      let _this = this
      switch (text) {
        case '新增':
          this.formAddOptions.loginNameviewMode = false
          this.openDlg()
          break
        case '编辑':
          this.formAddOptions.loginNameviewMode = true
          this.openDlg(item)
          break
        case '删除':
          this.$confirm({
            title: '操作确认',
            content: '确定要删除 ' + item.companyName + ' 吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.del(item)
            }
          })
          break
        case '重置密码':
          this.$confirm({
            title: '操作确认',
            content: '确定要重置 ' + item.companyName + ' 的密码吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.resetPwd(item)
            }
          })
          break
      }
    },
    resetPwd(value) {
      // console.log(value); return;
      this.$axios({
        url: '/api/zzgl/modiComPassword',
        method: 'post',
        data: {
          value: value.id,
          type: 'reset'
        }
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('重置成功')
              this.bindList()
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },
    phoneCheck(rule, value, callbackFn) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!reg.test(value)) {
        callbackFn('请输入正确的手机号码')
        return
      }
      callbackFn()
    },
    bindList: async function () {
      this.formSearch.validateFields((error, values) => {
        error = 1
        // console.log(values)
        this.strWhere = 'del=0 '
        for (var obj in values) {
          if (values[obj]) {
            this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
          }
        }
        //console.log(this.strWhere)
        this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
      })
      this.loading = true

      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere)
      this.strWhere = this.strWhere.data
      await this.InitGrid()
    },
    InitGrid: function () {
      var _that = this
      _that.loading = true
      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName: 'zzgl_wbCompany',
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          // filesFields: 'bh',
          strWhere: _that.strWhere
        }
      })
        .then(function (response) {
          _that.loading = false
          if (response.status === 200) {
            console.log(response.data.data)
            _that.tableData = response.data.data
            _that.pagination.total = response.data.count
          }
        })
        .catch(function (error) {
          //console.log(error)
        })
    },
    formSearchSubmit(e) {
      e.preventDefault()
      this.pagination.current = 1
      this.bindList()
    },
    formSearchReset() {
      this.formSearch.resetFields()
      this.strWhere = 'del=0'
      this.bindList()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    pageCurrentChange(pagination) {
      this.pagination.current = pagination.current
      this.bindList()
    },
    del(record) {
      this.$axios({
        url: '/api/Comon/deltag',
        method: 'post',
        data: {
          tableName: 'zzgl_wbCompany',
          field: 'id',
          value: record.id,
          source: 'web',
          loginid: ''
        }
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('删除成功')
              this.bindList()
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },
    openDlg: async function (record, action) {
      this.formAdd.resetFields()
      this.fileList = []
      this.formAddOptions.submitVisible = true
      this.formAddOptions.viewMode = false
      if (record !== undefined) {
        // 从后台取数据赋值
        this.formAddOptions.spinning = true
        var obj = await this.$jsc.DesEncrypt(
          this,
          'id=zhangbiao' + record.id + 'zhangbiao'
        )
        obj = obj.data
        this.$axios
          .get(
            '/api/GetList/getListByTable?tableName=zzgl_wbCompany&strWhere=' +
              obj +
              '&filesFields=bh'
          )
          .then(response => {
            if (response.status == 200) {
              if (response.data.code == 0) {
                this.formAddOptions.visible = true
                var result = response.data.data
                //console.log(response.data);
                setTimeout(() => {
                  result[0].yyqk = true
                  // var objArr = { qymc: result[0].qymc, lxr: result[0].lxr, yyqk: true }
                  // this.formAdd.setFieldsValue(objArr)
                  this.formAdd.setFieldsValue(result[0])
                  this.fileList = result[0].files_bh
                  this.editgetText('a')
                  this.formAddOptions.spinning = false
                }, 100)
              } else {
                this.$message.error(response.data.msg)
                this.formAddOptions.spinning = false
              }
            } else {
              // vue.prototype.$message.info('This is an error message');
            }
          })
          .catch(function (error) {
            // 请求失败处理
            //console.log(error)
          })
        if (action === 'view') {
          // 预览模式
          this.formAddOptions.submitVisible = false
          this.formAddOptions.viewMode = true
        }
      } else {
        this.formAddOptions.visible = true
      }
    },
    formAddSubmit() {
      this.formAdd.validateFields((err, values) => {
        if (!err) {
          this.formAddOptions.submitLoading = true
          this.$axios({
            url: '/api/zzgl/EditWbCompany',
            method: 'post',
            data: values
          })
            .then(res => {
              if (res.status == 200) {
                if (res.data.code === 0) {
                  this.$message.info('保存成功')
                  this.formAddOptions.visible = false
                  this.formAddOptions.submitLoading = false
                  this.bindList()
                } else {
                  //console.log(res);
                  throw new Error('服务器超时2')
                }
              } else {
                throw new Error('服务器超时2')
              }
            })
            .catch(error => {
              // 请求失败处理
              this.$message.error(error.message)
              this.formAddOptions.submitLoading = false
            })
        }
      })
    },
    saveFile(formData) {
      this.$axios({
        method: 'post',
        url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
        data: formData
      })
        .then(res => {
          if (res.status == 200) {
            var num = this.fileList.length
            this.fileList.push(res.data)
            this.uploadgetText('a', num, res.data)
          } else {
            throw new Error('服务器器超时')
          }
        })
        .catch(error => {
          this.$message.error(error.message)
          //console.log(error)
        })
    },
    init() {
      var that = this
      size()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector(
            '#components-form-demo-advanced-search>form'
          )
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('p')[0] - 270
          }
        })
      }
    },
    /**
     * @param {String} classname；upload的类名
     */
    editgetText(classname) {
      this.$nextTick(() => {
        var els = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span'
        )
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        //console.log(els)
        for (var i = 0, len = els.length; i < len; i++) {
          var type = els[i].textContent.split('.')[1]
          if (type !== 'png' && type !== 'jpg') {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = els[i].textContent
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + '...' + type
            }
            cel.innerText = temptext
            spanels[i].prepend(cel)
          }
        }
      })
    },
    /**
     * @param {String} classname；upload的类名
     * @param {Number} num；原有的已上传文件数量
     * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
     */
    uploadgetText(classname, num, response) {
      this.$nextTick(() => {
        //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        var tempNodeList = []
        for (var i = 0; i < spanels.length - num; i++) {
          // console.log(spanels[num+i])
          tempNodeList.push(spanels[num + i])
          for (var j = 0; j < tempNodeList.length; j++) {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = response.name
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + '...' + response.ext
            }
            cel.innerText = temptext
            tempNodeList[j].prepend(cel)
          }
        }
      })
    },
    actions() {
      if (this.inlineButtons.length == 0) {
        this.columns.pop()
      }
    }
  }
}

//function getBase64(file) {
//    return new Promise((resolve, reject) => {
//        const reader = new FileReader()
//        reader.readAsDataURL(file)
//        reader.onload = () => resolve(reader.result)
//        reader.onerror = error => reject(error)
//    })
//}
</script>
<style>
.ant-layout-footer {
  display: none;
}

#components-form-demo-advanced-search {
  background: white;
  box-sizing: border-box;
  padding: 20px 20px 0;
}

.ant-form-item {
  margin-bottom: 12px;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: !important;
}

/*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 1px 1px 1px 1px !important;
}
</style>