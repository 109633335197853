<template>
    <div class="editable-cell">
        <div v-if="editable" class="editable-cell-input-wrapper">
            <a-input-number :min="0" :value="value" @change="handleChange" @pressEnter="check" style="width:48px;"/>
            <a-icon
                type="check"
                class="editable-cell-icon-check"
                @click="check"
            />
        </div>
        <div v-else class="editable-cell-text-wrapper">
            {{ value }}
            <a-icon type="edit" class="editable-cell-icon" @click="edit" />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            text: Number,
        },
        data() {
            return {
                value: this.text,
                editable: false,
            }
        },
        methods: {
            handleChange(value) {
                this.value = value
            },
            check() {
                this.editable = false
                this.$emit('change', this.value)
            },
            edit() {
                this.editable = true
            }
        },
    }
</script>

<style lang="less" scoped>

    .editable-cell {
        position: relative;
    }

    .editable-cell-icon,
    .editable-cell-icon-check {
        position: absolute;
        right: 0;
        width: 20px;
        cursor: pointer;
    }

    .editable-cell-icon {
        line-height: 24px;
        display: none;
    }

    .editable-cell-icon-check {
        line-height: 36px;
    }

    .editable-cell:hover .editable-cell-icon {
        display: inline-block;
    }

    .editable-cell-icon:hover,
    .editable-cell-icon-check:hover {
        color: #108ee9;
    }


</style>