import axios from 'axios'

const SQLMapper = {
    ['like'] (key, value) {
        return ` and ${key} like '%${value}%' `
    },
    ['not'] (key, value) {
        return ` and ${key}<>'${value}' `
    },
    ['value'] (key, value) {
        return ` and ${key}='${value}' `
    },
    ['range'] (key, value) {
        return ` and ${key}>='${value.start}' and ${key}<='${value.end}' `
    }
}

export default class SQLCompiler {
    static async encrypt(query, option = {}, prefix = 'del=0') {
        if (typeof query === 'string') return await this.fetchEncrypted(this.replace(query))

        if (Object.prototype.toString.call(query) === '[object Object]') {
            let str = ''
            Object.keys(query).forEach(key => {
                if (!Object.keys(option).includes(key)) throw new Error(`数据与配置的key必须一一对应`)
    
                if (query[key] != undefined) {
                    if (!Object.keys(SQLMapper).includes(option[key])) throw new Error(`匹配类型 => ${Object.keys(SQLMapper).join(' ')}`)
                    str += SQLMapper[option[key]](key, query[key])
                }
            })
            return await this.fetchEncrypted(this.replace(prefix + str))
        }

        return ''

    }

    static async fetchEncrypted (query) {
        return axios({
            url: '/api/comon/DesEncrypt',
            method: 'get',
            params: { str: query }
        }).then(res => res.data).catch(err => err)
    }

    static replace (query) {
        return query.replace(/\%/g, 'kejin').replace(/'/g, 'zhangbiao').replace(/\+/g, 'zbiao')
    }
}