<template>
    <div>
        <div class="ant-descriptions-title">工单流程</div>
        <a-timeline>
            <!--<a-timeline-item color="green">
                Create a services site 2015-09-01
            </a-timeline-item>
            <a-timeline-item color="green">
                Create a services site 2015-09-01
            </a-timeline-item>
            <a-timeline-item color="red">
                <p>Solve initial network problems 1</p>
                <p>Solve initial network problems 2</p>
                <p>Solve initial network problems 3 2015-09-01</p>
            </a-timeline-item>
            <a-timeline-item>
                <p>Technical testing 1</p>
                <p>Technical testing 2</p>
                <p>Technical testing 3 2015-09-01</p>
            </a-timeline-item>
            <a-timeline-item color="gray">
                <p>Technical testing 1</p>
                <p>Technical testing 2</p>
                <p>Technical testing 3 2015-09-01</p>
            </a-timeline-item>
            <a-timeline-item color="gray">
                <p>Technical testing 1</p>
                <p>Technical testing 2</p>
                <p>Technical testing 3 2015-09-01</p>
            </a-timeline-item>-->
            <template v-for="(item,index) in processData">
                <a-timeline-item :color="item.recState==0 ? 'gray':'green'">
                    <p>{{item.step}} {{item.sendDateTime}}</p>

                    <template v-if="item.step=='申请延期'">
                        <p>发送：{{item.sendRealName}}  {{item.sendContent}}，延期至{{item.processClsx}}</p>
                        <p>
                            处理：{{item.recRealName}}
                            <span v-if="item.recState==0" style="color:gray;">未处理</span>
                            <span v-else>
                                <a-tag color="green" v-if="item.recState==2">同意</a-tag>
                                <a-tag color="red" v-if="item.recState==1">不同意</a-tag>
                                {{item.recContent}} [{{item.recDateTime}}]
                            </span>
                        </p>
                    </template>
                    <template v-else-if="item.step=='申请办结'">
                        <p>发送：{{item.sendRealName}}  {{item.sendContent}}</p>
                        <p>
                            处理：{{item.recRealName}}
                            <span v-if="item.recState==0" style="color:gray;">未处理</span>
                            <span v-else>
                                <a-tag color="green" v-if="item.recState==2">同意</a-tag>
                                <a-tag color="red" v-if="item.recState==1">不同意</a-tag>
                                {{item.recContent}} [{{item.recDateTime}}]
                            </span>
                        </p>
                    </template>
                    <template v-else-if="item.step=='申请变更办理单位'">
                        <p>发送：{{item.sendRealName}}  {{item.sendContent}}</p>
                        <p>
                            处理：{{item.recRealName}}
                            <span v-if="item.recState==0" style="color:gray;">未处理</span>
                            <span v-else>
                                <a-tag color="green" v-if="item.recState==2">同意</a-tag>
                                <a-tag color="red" v-if="item.recState==1">不同意</a-tag>
                                {{item.recContent}} [{{item.recDateTime}}]
                            </span>
                        </p>
                    </template>
                    <template v-else>
                        <p>发送：{{item.sendRealName}}  {{item.sendContent}}</p>
                        <p>
                            处理：{{item.recRealName}}
                            <span v-if="item.recState==0" style="color:gray;">未处理</span>
                            <span v-else>{{item.recContent}} [{{item.recDateTime}} ]</span>
                        </p>
                    </template>
                </a-timeline-item>
            </template>
        </a-timeline>
    </div>
</template>
<script>

    export default {
        data() {
            return {
                TableListSearchModel: {
                    pageIndex: 1,
                    pageSize: 20,
                    fields: "*",
                    order: "id asc",
                    where: {

                    }
                },
                processData: [],
            }
        },
        props: {
            'dataOptions': Object,
        },
        mounted() {

        },
        created() {
            this.TableListSearchModel.where.workbh = this.dataOptions.workbh;
            this.initData();
        },
        methods: {
            initData() {
                this.$axios({
                    url: "/api/work/getList_v_commonWorkProcess",
                    method: "post",
                    data: this.TableListSearchModel
                }).then((res) => {
                    this.processData = res.data.data.list;
                    console.log(this.processData);
                }).catch((res) => {

                });
            }
        }
    }

</script>
<style scoped>
</style>