<template>
    <div style="height:100%;" ref="rootbox">
        <!-- 搜索 -->
        <a-form :form="form" layout="inline" style="marginBottom: 15px;">

            <a-form-item>
                <a-input v-decorator="['title']"  placeholder="巡查名称"  />
            </a-form-item>

            <a-form-item>
                <a-input v-decorator="['xcgc']" placeholder="巡查工程"  />
            </a-form-item>

            <a-form-item >
                <a-select v-decorator="['gclx']" placeholder="巡查类型" style="width: 174px !important;">
                    <a-select-option value="排涝站">
                        排涝站
                    </a-select-option>
                    <a-select-option value="防洪闸">
                        防洪闸
                    </a-select-option>
                    <a-select-option value="堤防">
                        堤防
                    </a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item>
                <a-input v-decorator="['xcr']" placeholder="巡查人员"  />
            </a-form-item>

            <a-form-item>
                <a-range-picker v-decorator="['xcsj']"  :placeholder="['巡查开始时间', '巡查结束时间']" style="width: 215px !important;" />
            </a-form-item>

            <!-- <a-form-item >
                <a-select v-decorator="['isEnd']" placeholder="办理状态" style="width: 174px !important;">
                    <a-select-option value="not">
                        未办结
                    </a-select-option>
                    <a-select-option value="value">
                        已办结
                    </a-select-option>
                </a-select>
            </a-form-item> -->

            <!-- 搜索按钮组 -->
            <a-form-item>
                <a-space >
                    <a-button type="primary" icon="search" @click="operForm('search')">搜索</a-button>
                    <a-button icon="redo" @click="operForm('reset')">重置</a-button>
                </a-space>
            </a-form-item>

        </a-form>

        <!-- 列表 -->
        <a-table
            rowKey="id"
            :columns="columns" 
            :data-source="dataSource"
            :pagination="pagination"
            :scroll="scroll"
            :rowClassName="rowClassName"
            @change="onChangePage">
            
            <!-- 操作按钮组 -->
            <template slot="action" slot-scope="record">
                <a-space>
                    <a-button 
                        type="primary" 
                        icon="file-search"
                        size="small"
                        @click.stop="openModal(record)" />
                    <a-button 
                        type="danger" 
                        icon="delete"
                        size="small"
                        @click.stop="removeTableItem(record)" />
                </a-space>
            </template>
        </a-table>

        <!-- 详情 -->
        <a-modal 
            v-model="showing"
            :centered="true"
            :footer="null"
            title="巡查记录"
            :width="820"
            :destroyOnClose="true"
            @cancel="showing = false">

            <div class="modal-content">
                <!-- <a-space direction="vertical"> -->
                    <a-descriptions :column="2" style="marginBottom: 25px;">
                        <a-descriptions-item
                            v-for="item in infoInModal" 
                            :key="item.name"
                            :label="item.name">
                            {{ item.value }}
                        </a-descriptions-item>
                    </a-descriptions>

                    <a-table
                        rowKey="id"
                        size="small"
                        :columns="columnsInModal" 
                        :data-source="dataSourceInModal"
                        :pagination="paginationInModal"
                        :rowClassName="rowClassName"
                        @change="onChangePageInModal">

                        <span slot="isEnd" slot-scope="text">{{ text === 2 ? '已办结' : '未办结' }}</span>

                        <template slot="action" slot-scope="text, record">
                            <a-button 
                                type="primary" 
                                icon="file-search"
                                size="small"
                                @click="seeQu(record)"
                            />
                        </template>
                    </a-table>
                    <!-- <patrol-record-info :bh="bhGroup[0]"></patrol-record-info> -->
                <!-- </a-space> -->
            </div>

        </a-modal>

        <!-- 问题详情 -->
        <a-modal 
            v-model="quShowing"
            :centered="true"
            :footer="null"
            title="问题详情"
            :width="820"
            :destroyOnClose="true"
            @cancel="quShowing = false">
            <div class="modal-content">
                <a-space direction="vertical">
                    <patrol-info :bh="bhGroup[0]"></patrol-info>
                    <order-info :bh="bhGroup[1]"></order-info>
                </a-space>
            </div>
        </a-modal>

    </div>
</template>

<script>
    import SQLCompiler from '@/assets/js/SQLCompiler.js'

    import PatrolInfo from '../common/PatrolInfo'
    import OrderInfo from '../common/OrderInfo'

    const columns = [
        {   
            title: '序号',
            dataIndex: 'rownum',
            width: 80,
            align: 'center'
        },
        {
            title: '巡查名称',
            dataIndex: 'title',
            width: 220,
            ellipsis: true
        },
        {
            title: '巡查工程',
            dataIndex: 'xcgc',
            width: 120,
            ellipsis: true
        },
        {
            title: '巡查类型',
            dataIndex: 'gclx',
            width: 100,
            align: 'center'
        },
        {
            title: '巡查人员',
            dataIndex: 'xcr',
            align: 'center',
            width: 120,
        },
        {
            title: '巡查时间',
            dataIndex: 'xcsj_view',
            width: 120
        },
        {
            title: '检查项数',
            dataIndex: 'itemCount',
            align: 'center',
            width: 120,
        },
        // {
        //     title: '扣分',
        //     dataIndex: 'kf',
        //     align: 'center',
        //     width: 80
        // },
        // {
        //     title: '状态',
        //     dataIndex: 'isEnd_view',
        //     width: 80
        // },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 80,
            scopedSlots: { customRender: 'action' }
        },
    ]

    const columnsInModal = [
        {   
            title: '序号',
            dataIndex: 'rownum',
            align: 'center'
        },
        {
            title: '问题种类',
            dataIndex: 'title'
        },
        {
            title: '扣分',
            dataIndex: 'kf',
            align: 'center'
        },
        {
            title: '状态',
            dataIndex: 'isEnd',
            align: 'center',
            scopedSlots: { customRender: 'isEnd' }
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            scopedSlots: { customRender: 'action' }
        },
    ]

    const SQLOption = {
        'title': 'like',
        'xcgc': 'like',
        'xcr': 'like',
        'xcsj': 'range',
        'gclx': 'value',
        'isEnd': 'value'
    }

    const infoField = {
        xcgc: '巡查工程' ,
        xccj: '巡查层级',
        gclx: '工程类型',
        xcr: '巡查人员',
        xcrDepart: '巡查单位',
        createTime_view: '巡查时间' 
    }

    export default {
        components: {
            PatrolInfo,
            OrderInfo
        },
        data () {
            return {
                // form
                form: this.$form.createForm(this, { name: 'formSearch' }),
                // table
                columns,
                dataSource: [],
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showTotal: total => `总共 ${total} 条`
                },
                scroll: { 
                    x: '125%', 
                    y: 0,
                },
                // 对话框
                showing: false,
                infoInModal: [],
                columnsInModal,
                dataSourceInModal: [],
                paginationInModal: {
                    total: 0,
                    current: 1,
                    pageSize: 5,
                    showTotal: total => `总共 ${total} 条`
                },

                quShowing: false,
                bhGroup: []

            }
        },
        methods: {
            operForm (action) {
                const { isFieldsTouched, resetFields } = this.form
                if (!isFieldsTouched()) return
                if (action === 'reset') {
                    resetFields()
                }
                this.pagination.current = 1
                this.getDataSource()
            },
            rowClassName (record, index) {
                let className = "dark-row"
                if (index % 2 === 1) className = "light-row"
                return className
            },
            onChangePage (page) {
                this.pagination.current = page.current
                this.getDataSource()
            },
            calcScrollY () {
                this.scroll.y = document.body.offsetHeight - 312
            },
            formatFields (fields) {
                
                 // 处理时间格式
                if (fields.xcsj) {
                    let start = fields.xcsj[0].format('YYYY-MM-DD')
                    let end = fields.xcsj[1].format('YYYY-MM-DD') + ' 23:59:59'
                    fields.xcsj = { start, end }
                }

                // 状态
                // if (fields.isEnd) {
                //     SQLOption['isEnd'] = fields.isEnd
                //     fields.isEnd = 2
                // }

                return fields
            },
            removeTableItem (record) {
                this.$confirm({
                    title: '是否确认删除？',
                    okType: 'danger',
                    onOk: () => {
                        this.$axios({
                            url: '/api/Comon/deltag',
                            method: 'post',
                            data: {
                                tableName: 'slgcxczg_rcxcInfo',
                                field: 'id',
                                value: record.id,
                                source: 'web',
                                loginid: ''
                            }
                        }).then(res => {
                            if (res.status !== 200 || res.data.code !== 0) return
                            this.$message.success('删除成功')
                            this.getDataSource()
                        }).catch(err => err)
                    }
                })
                
            },
            async getDataSource () {
                    this.loading = true
                    const { current, pageSize } = this.pagination
                    const { getFieldsValue } = this.form
                    const preSQL =  `del=0 and xccj='${['镇级', '市级'][this.$route.path.slice(-1)]}'`

                    this.$axios({
                        url: '/api/GetList/getListByTable',
                        method: 'get',
                        params: {
                            tableName: 'v_slgcxczg_rcxcInfo',
                            page: current,
                            limit: pageSize,
                            strWhere: await SQLCompiler.encrypt(this.formatFields(getFieldsValue()), SQLOption, preSQL)
                        },
                    })
                        .then(res => {
                            let { code, count, data } = res.data
                            if (code !== 0) return
                            data.forEach(d => {
                                let { xcsj, isEnd } = d
                                d.xcsj_view = xcsj.slice(0, 10)
                                d.isEnd_view = isEnd === 2 ? '已办结': '未办结'
                            })
                            this.dataSource = data
                            this.pagination.total = count
                            this.$emit('loadend')
                        })
                        .catch(err => err)
                        .finally(() =>  this.loading = false)
            },

            async openModal (record) {
                this.showing = true
                this.getInfoInModal(record.bh)
                this.pagination.current = 1
                this.getQuInModal(record.bh)
            },
            async getInfoInModal (bh) {
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'slgcxczg_rcxcInfo',
                        page: 1,
                        limit: 10000,
                        strWhere: await SQLCompiler.encrypt(` del=0 and bh='${bh}' `),
                    }
                }).then(res => {
                    if (res.status !== 200 || res.data.code !== 0) return
                    // 处理特殊字段
                    const [data] = res.data.data
                    data.createTime_view = data.createTime.slice(0, 10)
                    this.infoInModal = Object.keys(infoField).map(key => ({ name: infoField[key], value: data[key] }))
                })
            },
            onChangePageInModal (page) {
                this.pagination.current = page.current
                this.getQuInModal()
            },
            seeQu (record) {
                console.log(record);
                this.quShowing = true
                this.bhGroup = [record.bh, record.orderbh]
            },  
            async getQuInModal (bh) {
                const { current, pageSize } = this.paginationInModal
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'v_slgcxczg_rcxcItem',
                        page: current,
                        limit: pageSize,
                        strWhere: await SQLCompiler.encrypt(`infobh='${bh}'`),
                        filesFields: 'bh'
                    },
                })
                    .then(res => {
                        if (res.status !== 200 || res.data.code !== 0) return
                        this.total = res.data.count
                        this.dataSourceInModal = res.data.data
                        console.log(this.dataSourceInModal)

                        // let temp = []
                        // res.data.data.forEach(d => {
                        //     let obj = {}
                        //     obj.title = d.title
                        //     obj.children = Object.keys(quField).map(key => {
                        //         return {
                        //             name: quField[key],
                        //             value: d[key]
                        //         }
                        //     })
                        //     temp.push(obj)
                        // })
                        // this.question = temp
                    })
            }
        },
        watch: {
            $route: {  
                handler () {
                    this.operForm('reset')
                    this.getDataSource()
                },
                immediate: true 
            }
        },
        mounted () {
            this.calcScrollY()
            window.addEventListener('resize', this._.debounce(this.calcScrollY, 150))
            this.$once('hook:beforeDestroy', () => window.removeEventListener('resize', this.calcScrollY))
        },
    }
</script>

<style lang="less" scoped>


    // 隔行变色
    /deep/ .light-row { 
        background-color: #fff;
    }
    /deep/ .dark-row {
        background-color: #fafafa;
    }


    // 总览对话框
    .modal-content {
        padding: 0 15px;
        height: 450px;
        overflow-y: auto;
    }

</style>