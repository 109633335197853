<template>
    <layout-content-with-drawer :sider-width="598" id="rootWrapper">

        <!-- 侧边栏 -->
        <template slot="sider">
            <!-- 搜索 -->
            <a-form :form="form" layout="inline" class="cond_form">
                <a-row>
                    <a-col :span="8">
                        <a-form-item>
                            <a-input v-decorator="['title']" placeholder="工单号" autocomplete="off" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item>
                            <a-input v-decorator="['xcgc']" placeholder="项目名称" autocomplete="off"  />
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item>
                            <a-input v-decorator="['xcr']" placeholder="巡查人" autocomplete="off"  />
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item>
                            <a-select v-decorator="['isError']" placeholder="是否存在问题">
                                <a-select-option value="1">是</a-select-option>
                                <a-select-option value="0">否</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                    <!-- 搜索按钮组 -->
                    <a-col :span="16" class="col_btn_group">
                        <a-space>
                            <a-button type="primary" icon="search" @click="onSearch">搜索</a-button>
                            <a-button icon="redo" @click="onReset">重置</a-button>
                        </a-space>
                    </a-col>

                </a-row>
            </a-form>

            <!-- 列表 -->
            <a-table
                rowKey="id"
                size="small"
                :columns="columns" 
                :data-source="dataSource"
                :row-selection="{
                    onChange: onChangeSelect
                }"
                :pagination = "pagination"
                :scroll="scroll"
                :customRow="customRow"
                :rowClassName="rowClassName"
                @change="onChangePage">

                <!-- 存在问题 -->
                <template slot="isError" slot-scope="value">
                    {{ value ? '是' : '否' }}
                </template>

                <!-- 操作按钮组 -->
                <template slot="action" slot-scope="record">
                    <a-space>
                        <a-button
                            type="primary"
                            icon="file-search"
                            size="small"
                            @click.stop="checkRecord(record)" />
                        <a-button 
                            size="small"
                            v-if="record.isError"
                            @click.stop="dispatch(record)">
                            派单
                        </a-button>
                    </a-space>
                </template>
            </a-table>
        </template>

        <!-- 内容 -->
        <template slot="content">
            <div id="map" class="wh-100p">
                <a-alert class="alert" :message="pointName" type="info"/>
            </div>
        </template>

        <!-- 其他 -->
        <template slot="extra">
            <!-- table record -->
            <a-modal 
                v-model="showRecord"
                :centered="true"
                :footer="null"
                :width="320"
                :mask="false"
                :maskClosable="false"
                >
                <a-row  :gutter="[0, 12]">
                    <a-col :span="24" v-for="rs in recordSelected" :key="rs.title">
                        {{ rs.title }}：{{ rs.value }}
                    </a-col>
                </a-row>
                <div style="textAlign:center;paddingTop:15px;">
                        <a-space>
                            <a-button type="link" @click="showWoRecord=true">
                                工单记录
                            </a-button>
                            <a-button type="primary" @click="showWo=true">
                                查看详情
                            </a-button>
                        </a-space>
                    </div>
            </a-modal>

            <!-- 工单记录 -->
            <a-modal
                v-model="showWoRecord"
                title="工单记录"
                class="wo_record_modal"
                :centered="true"
                :footer="null"
                :width="360">
                <a-timeline>
                    <a-timeline-item>
                        <p>操作状态：上报</p>
                        <p>操作人员：xxx</p>
                        <p>2020-09-29 08:32:15</p>
                    </a-timeline-item>
                    <a-timeline-item>
                        <p>操作状态：审核</p>
                        <p>操作人员：xxx</p>
                        <p>2020-09-29 08:32:15</p>
                    </a-timeline-item>
                    <a-timeline-item>
                        <p>操作状态：提交整改</p>
                        <p>操作人员：xxx</p>
                        <p>2020-09-29 08:32:15</p>
                    </a-timeline-item>
                    <a-timeline-item>
                        <p>操作状态：复审通过--完结</p>
                        <p>操作人员：xxx</p>
                        <p>2020-09-29 08:32:15</p>
                    </a-timeline-item>
                </a-timeline>
            </a-modal>

            <!-- 提防工单 -->
            <a-modal 
                v-model="showWo"
                title="站闸工单"
                class="wo_modal"
                :centered="true"
                :footer="null"
                :width="620">

                <a-tabs 
                    default-active-key="1" 
                    tab-position="left">
                    <a-tab-pane key="1" tab="巡检">
                        <a-row :gutter="[0, 24]">
                            <a-col :span="12">
                                <span>工单编号：</span>202104210028
                            </a-col>
                            <a-col :span="12">
                                <span>提防名称：</span>周家埭站闸
                            </a-col>
                            <a-col :span="12">
                                <span>所在圩区：</span>庙泾
                            </a-col>
                            <a-col :span="12">
                                <span>河道名称：</span>周家埭港
                            </a-col>
                            <a-col :span="12">
                                <span>上报时间：</span>2021-04-21 09:20:01
                            </a-col>
                            <a-col :span="12">
                                <span>上报人员：</span>xxx
                            </a-col>
                            <a-col :span="12">
                                <span>当前状态：</span>已完结
                            </a-col>
                            <a-col :span="12">
                                <span>事件地址：</span>苏州市昆山市星海公寓8号楼7室诗奈尔凯迪城店东北约74米 <a-icon type="environment" :style="{ color: '#1890ff' }" />
                            </a-col>
                        </a-row>
                        <a-divider orientation="left" :style="{ color: '#1890ff', fontSize:'16px', marginTop: '30px' }">站容站貌</a-divider>
                        <a-row :gutter="[0, 24]">
                            <a-col :span="24">
                                <span>问题详情：</span>房屋墙面不洁
                            </a-col>
                            <a-col :span="24">
                                <span>问题说明：</span>有垃圾
                            </a-col>
                            <a-col :span="24">
                                <span>现场照片：</span>
                                <a-space>
                                    <img src="/img/login-bg.png" alt="xxx">
                                    <img src="/img/login-bg.png" alt="xxx">
                                </a-space>
                            </a-col>
                        </a-row>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="审核">
                        <a-row :gutter="[0, 24]">
                            <a-col :span="24">
                                <span>是否建议整改：</span>是
                            </a-col>
                            <a-col :span="24">
                                <span>工单级别：</span>一般
                            </a-col>
                            <a-col :span="24">
                                <span>选择整改人：</span>xxx
                            </a-col>
                            <a-col :span="24">
                                <span>建议完成工单：</span>2021-04-21 09:20:01
                            </a-col>
                            <a-col :span="24">
                                <span>初审意见：</span>xxxxxx
                            </a-col>
                        </a-row>
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="整改">
                        <a-row :gutter="[0, 24]">
                            <a-col :span="24">
                                <span>建议完成时间：</span>2021-04-21 09:20:01
                            </a-col>
                            <a-col :span="24">
                                <span>整改人员：</span>xxx
                            </a-col>
                            <a-col :span="24">
                                <span>整改说明：</span>已上报维修
                            </a-col>
                            <a-col :span="24">
                                <span>现场照片：</span>
                                <a-space>
                                    <img src="/img/login-bg.png" alt="xxx">
                                    <img src="/img/login-bg.png" alt="xxx">
                                </a-space>
                            </a-col>
                        </a-row>
                    </a-tab-pane>
                    <a-tab-pane key="4" tab="复审">
                        <a-row :gutter="[0, 24]">
                            <a-col :span="24">
                                <span>是否办结：</span>是
                            </a-col>
                            <a-col :span="24">
                                <span>复审意见：</span>xxxxxx
                            </a-col>
                        </a-row>
                    </a-tab-pane>
                </a-tabs>

            </a-modal>
        </template>

    </layout-content-with-drawer>
</template>

<script>
    import moment from '@/assets/js/moment.js';

    import { loadMap } from '@/assets/js/map.js'

    import LayoutContentWithDrawer from '@/components/LayoutContentWithDrawer'

    const COLUMNS = [
        {   
            title: '序号',
            dataIndex: 'rownum',
            width: 60
        },
        {
            title: '工单号',
            dataIndex: 'title',
        },
        {
            title: '堤防名',
            dataIndex: 'xcgc',
            ellipsis: true
        },
        {
            title: '巡查人',
            dataIndex: 'xcr'
        },
        {
            title: '巡检人单位',
            dataIndex: 'xcrDepart',
            ellipsis: true
        },
        {
            title: '巡查时长',
            dataIndex: 'xcsc'
        },
        {
            title: '巡查时间',
            dataIndex: 'xcsj',
            width: 180
        },
        {
            title: '是否存在问题',
            dataIndex: 'isError',
            scopedSlots: { customRender: "isError" }
        },
        {
            title: '派单次数',
            dataIndex: 'pdcs'
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 96,
            scopedSlots: { customRender: 'action' }
        },
    ]

    let recordSelected = [
        {
            title: '所在河道',
            value: 'xxx'
        },
        {
            title: '所在联圩',
            value: 'xxx'
        },
        {
            title: '上报时间',
            value: '2020-09-29 08:32:15'
        },
        {
            title: '巡检人',
            value: 'xxx'
        },
        {
            title: '地点',
            value: '苏州市昆山市星海公寓8号楼7室诗奈尔凯迪城店东北约74米'
        },
        {
            title: '状态',
            value: 'xxx'
        }
    ]

    export default {
        components: {
            LayoutContentWithDrawer
        },
        data () {
            return {
                // map
                map: {},
                pointName: '堤防巡查',
                // form
                form: this.$form.createForm(this, { name: 'cond' }),
                // table
                columns: Object.freeze(COLUMNS),
                dataSource: [],
                pagination: {
                    total: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条`
                },
                scroll: { 
                    x: 1200, 
                    y: 0
                },

                // 对话框
                recordSelected,
                showRecord: false,
                showWoRecord: false,
                showWo: false
            }
        },
        methods: {
            initMap () {
                loadMap().then(({ MapInitial, ksSWInitial, GeometryAssist, GraphicsAssist }) => {
                    let mi = new MapInitial({
                        zoom: 9,
                        center_lng: 120.9808441,
                        center_lat: 31.34081901,
                        container: "map",
                        maptype: 1,
                        OMKey: "11"
                    })

                    let xcw = new ksSWInitial({ mi })
                    xcw.initial();  //智慧水利相关功能初始化
                    xcw.setAllBaseSubLayerVisibility(false) //关闭所有水系基础图层
                    xcw.setAllDBSubLayerVisibility(false) //关闭所有业务图层
                    xcw.setBaseWaterSubLayerVisibility('River', true) //打开河道图层，配置为false，则关闭河道图层，第一个参数为指代图层的target参数
                    xcw.setBaseWaterSubLayerVisibility('Lake', true) //打开湖泊图层
                    xcw.setBaseWaterSubLayerVisibility('TownBoundary', true) //打开乡镇界线图层
                    xcw.setBoundary('昆山市')

                    this.map = {
                        mi,
                        GeometryAssist,
                        GraphicsAssist
                    }
                })
            },
            drawPoint (record) {
                console.log('表单项', record)
                let { x, y } = record
                let { mi, GeometryAssist, GraphicsAssist } = this.map

                mi.zoomto(+x, +y)

                let gc = mi.getGC()
                let ga = new GraphicsAssist()
                let pnt = GeometryAssist.getPoint(+x, +y)

                ga.setSMS('/img/point.png', 24, 24)
                let pnt_graphic = ga.CreateGraphic(pnt,{})
                gc.add({
                    graphic:pnt_graphic,
                    id:"mmmss"
                })
            },
            findCond () {
                let field = this.form.getFieldsValue()
                let fieldKeys = Object.keys(field)
                let isFieldValues = fieldKeys.some(fk => field[fk])
                return {
                    field,
                    fieldKeys,
                    isFieldValues
                }
            },
            resetCond () {
                this.form.resetFields()
            },
            onSearch () {
                if (!this.findCond().isFieldValues) return
                this.pagination.current = 1
                this.findDataSource()
            },
            onReset () {
                if (!this.findCond().isFieldValues) return
                this.resetCond()
                this.pagination.current = 1
                this.findDataSource()
            },
            customRow (record) {
                return {
                    on: {
                        click: () => {
                            console.log('表单项', record)
                            this.pointName = record.xcgc
                        }
                    }
                }
            },
            rowClassName (record, index) {
                let className = "dark_row"
                if (index % 2 === 1) className = "light_row"
                return className
            },
            onChangeSelect (selectedRowKeys, selectedRows) {
                console.log(selectedRowKeys, selectedRows)
            },
            onChangePage ({ current }) {
                this.pagination.current = current
                this.findDataSource()
            },
            checkRecord (record) {
                console.log(record)
                this.showRecord = true
            },
            dispatch (record) {
                let titleList = JSON.stringify(COLUMNS.slice(0, -1))
                let data = JSON.stringify(record)
                this.$router.push({ 
                    path: './townxczgpd', 
                    query: { titleList, data }
                })
            },
            onAdjust () {
                this.$nextTick(() => {
                    let rootWrapperH = document.querySelector('#rootWrapper').offsetHeight
                    this.scroll.y = rootWrapperH - 200
                })
            },
            // sql拼接和加密
            async handleSql(obj = {}) {
                let strWhere = ` del=0 and gclx=2`
                
                if (Object.keys(obj).length) {
                    Object.keys(obj).forEach(key => {
                        if (obj[key]) {
                            strWhere +=  " and " + key + " like 'kejin" + obj[key] + "kejin'"
                        }
                    })
                    strWhere.replace(/'/g, 'zhangbiao')
                } 

                let { data } = await this.$jsc.DesEncrypt(this, strWhere)
                return data
            },
            async findDataSource () {
                this.loading = true

                this.$axios({
                    url: "/api/GetList/getListByTable",
                    method: "get",
                    params: {
                        tableName: "v_slgcxczg_rcxcInfo",
                        page: this.pagination.current,
                        limit: 10,
                        strWhere: await this.handleSql(this.findCond().field),
                        sort: await this.handleSql('order by num desc,num1 desc').data
                    },
                })
                    .then(({ data: { code, count, data: resData } }) => {
                        if (code !== 0) return
                        console.log('数据 ========== ', resData);
                        resData.forEach(rd => {
                            // 处理工单号格式
                            rd.title = rd.title.slice(0, -3)
                            // 处理巡查时长格式
                            rd.xcsc = moment.duration(rd.xcsc, 'seconds').format('hh:mm:ss', { trim: false })
                        })
                        this.dataSource = resData
                        this.pagination.total = count
                        this.onAdjust()
                    })
                    .catch(err => err)
                    .finally(() =>  this.loading = false)
            }
        },
        created () {
            this.initMap()
            this.findDataSource()

            window.onresize =  (() => {
                let timer
                return () => {
                    timer && clearTimeout(timer)
                    timer = setTimeout( () => {
                        this.onAdjust()
                    }, 100)
                }
            })()
        },
        beforeDestroy () {
            window.onresize = null
        }
    }
</script>

<style lang="less" scoped>
    .cond_form {
        margin-bottom: 25px;

        & /deep/ .ant-form-item {
            display: flex;
            margin-right: 0;
        }

        & /deep/ .ant-form-item-control-wrapper {
            flex: 1;
            padding: 0 3px;
        }

        .col_btn_group {
            height: 40px;
            line-height: 40px;
            text-align: right;
        }
    }

    // 隔行变色
    /deep/ .light_row { 
        background-color: #fff;
    }
    /deep/ .dark_row {
        background-color: #fafafa;
    }

     // 地图
    #map {
        position: relative;

        .alert {
            position: absolute;
            top: 25px;
            right: 25px;
            z-index: 999;
        }
    }

    // 工单记录对话框
    .wo_record_modal {

            p {
                font-size: 12px;
                margin-bottom: 0;
            }

            & p:not(:nth-child(3n))  {
                color: #303133;
            }
    
            & p:nth-child(3n) {
                color: #909399;
                margin-top: 4px;
            }

    }

    // 站闸工单对话框
    .wo_modal {
            
            span {
                color: #303133;
            }

            img { 
                width: 106px;
                height: 106px;
                vertical-align: top;
            }

            /deep/ .ant-tabs-tabpane {
                font-size: 12px;
            } 

    }
</style>