<template>
  <div id="components-form-demo-advanced-search">
    <a-modal v-model="formAddOptions.visible" title="人员管理" width="1200px">
      <a-spin :spinning="formAddOptions.spinning">
        <a-form :form="formAdd" :label-col="{ span:7 }" :wrapper-col="{ span:17 }" labelAlign="left">
          <a-form-item label="" style="display:none">
            <a-input v-decorator="['loginid']" />
          </a-form-item>

          <a-row type="flex" justify="space-between">
            <a-col :span="7">
              <a-form-item label="登陆账号">
                <a-input v-decorator="['loginName', { rules: [{ required: true, message: '请输入登陆账号' },{max:12,message:'最大长度为12'},{min:2,message:'最小长度为2'}]}]" placeholder="请输入登陆账号" :disabled="formAddOptions.viewMode"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="姓名">
                <a-input v-decorator="['username', { rules: [{ required: true, message: '请输入姓名' },{max:50,message:'最大长度为50'}]}]" placeholder="请输入姓名" :disabled="formAddOptions.viewMode"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="性别">
                <a-radio-group v-decorator="['sex', {  initialValue:'男' }]">
                  <a-radio value="男">男</a-radio>
                  <a-radio value="女">女</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="7">
              <a-form-item label="人员性质">
                <a-select v-decorator="['ryxz']" placeholder="请选择人员性质" style="width:100%">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option value="公务员">公务员</a-select-option>
                  <a-select-option value="事业编制">事业编制</a-select-option>
                  <a-select-option value="编外定岗人员">编外定岗人员</a-select-option>
                  <a-select-option value="编外警辅人员">编外警辅人员</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="所属单位">
                <!-- :disabled="qz_ifVisible" -->
                <a-select v-decorator="['departLoginid', { rules: [{ required: true, message: '请选择所属单位'}]}]" placeholder="请选择所属单位" style="width:100%">
                  <a-select-option v-for="item in ploginidData" :key="item.departLoginid" :value="item.loginid">{{ item.departName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="科室">
                <a-input v-decorator="['keshi']" placeholder="请输入科室" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="7">
              <a-form-item label="职级">
                <a-input v-decorator="['zhiji']" placeholder="请输入职级" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="职称">
                <a-input v-decorator="['zc']" placeholder="请输入职称" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="所属区镇">
                <a-select v-decorator="['townId']" placeholder="请选择所属区镇" :disabled="formAddOptions.viewMode">
                  <a-select-option v-for="item in townData" :value="item.value">{{item.name}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="7">
              <a-form-item label="职务">
                <a-input v-decorator="['zw']" placeholder="请输入职务" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="第一学历">
                <a-input v-decorator="['xueli']" placeholder="请输入第一学历" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="第二学历">
                <a-input v-decorator="['xueli2']" placeholder="请输入第二学历" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="7">
              <a-form-item label="政治面貌">
                <a-select v-decorator="['zzmm']" placeholder="请选择政治面貌" style="width:100%">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option value="中共党员">中共党员</a-select-option>
                  <a-select-option value="中共预备党员">中共预备党员</a-select-option>
                  <a-select-option value="共青团员">共青团员</a-select-option>
                  <a-select-option value="无党派人士">无党派人士</a-select-option>
                  <a-select-option value="群众">群众</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="身份证号">
                <a-input v-decorator="['idcard']" placeholder="请输入身份证号" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="籍贯">
                <a-input v-decorator="['native']" placeholder="请输入籍贯" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="7">
              <a-form-item label="民族">
                <a-input v-decorator="['mz']" placeholder="请输入民族" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
              </a-form-item>
            </a-col>
            <!--<a-col :span="7">
        <a-form-item label="角色权限">
            <a-select disabled v-decorator="['role', { rules: [{ required: true, message: '请选择角色权限' }], initialValue: '淀防处'}]" placeholder="请选择角色权限" style="width:100%">
                <a-select-option v-for="item in roleData" :key="item.roleId">{{item.roleName}}</a-select-option>
            </a-select>
        </a-form-item>
    </a-col>-->
            <a-col :span="7">
              <a-form-item label="电子邮箱">
                <a-input v-decorator="['email']" placeholder="请输入电子邮箱" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="担任现职时间">
                <a-date-picker v-decorator="['nowworktime']" style="width: 100%" :disabled="formAddOptions.viewMode" format="YYYY-MM-DD" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="7">
              <a-form-item label="参加工作时间">
                <a-date-picker v-decorator="['worktime']" style="width: 100%" :disabled="formAddOptions.viewMode" format="YYYY-MM-DD" />
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="出生日期">
                <a-date-picker v-decorator="['birthday']" style="width: 100%" :disabled="formAddOptions.viewMode" format="YYYY-MM-DD" />
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="入党时间">
                <a-date-picker v-decorator="['dytime']" style="width: 100%" :disabled="formAddOptions.viewMode" format="YYYY-MM-DD" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="7">
              <a-form-item label="办公电话">
                <a-input v-decorator="['officeTel']" placeholder="请输入办公电话" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="手机号码">
                <a-input v-decorator="['phone',{ rules: [{required: false}],}]" placeholder="请输入手机号码" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="手机号码2">
                <a-input v-decorator="['phone2',{ rules: [{required: false}],}]" placeholder="请输入手机号码2" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">

            <a-col :span="7">
              <a-form-item label="账号状态">
                <a-radio-group v-decorator="['state', {  initialValue:'0' }]">
                  <a-radio value="0">启用</a-radio>
                  <a-radio value="1">禁用</a-radio>
                  <a-radio value="2">锁定</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="7">
            </a-col>
          </a-row>
        </a-form>

        <a-divider orientation="left">头像</a-divider>
        <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :customRequest="customFilesUpload2" @change="handleChange">
          <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width:80px;height:80px;" />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">
              上传头像
            </div>
          </div>
        </a-upload>

      </a-spin>
      <template slot="footer">
        <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
        <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
      </template>
    </a-modal>
    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
      <a-row :gutter="24">
        <a-col :span="7">
          <a-form-item label="">
            <a-input v-decorator="['loginName']" placeholder="登录账号" :maxLength="40"></a-input>
          </a-form-item>
        </a-col>
        <!-- <a-col :span="7" style="padding-left: 0;">
                    <a-form-item label="">
                        <a-select v-decorator="['departLoginid']" placeholder="所属单位" style="width:100%" :disabled="qz_ifVisible">
                            <a-select-option v-for="item in ploginidData" :value="item.loginid">{{item.departName}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col> -->
        <a-col :span="7">
          <a-form-item label="">
            <a-input v-decorator="['username']" placeholder="姓名" :maxLength="40"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="3">
          <a-form-item label="">
            <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
            <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
            <a :style="{ marginLeft: '8px', fontSize: '12px',display:'none' }" @click="toggle">
              {{ expand ?'收起':'展开' }}
              <a-icon :type="expand ? 'up' : 'down'" />
            </a>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24" v-if="expand">
      </a-row>
      <a-row :gutter="[24,12]">
        <a-col :span="18" :style="{ textAlign: 'left' }">
          <a-button type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button>
          <a-button @click="btnClick('人脸机新增')" style='margin-right: 5px'>发送到人脸机</a-button>
          <a-button type='danger' @click="btnClick('人脸机删除')" style='margin-right: 5px'>从人脸机删除</a-button>
        </a-col>
        <a-col :span="6" :style="{ textAlign: 'right' }">
          <!--<a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    <a :style="{ marginLeft: '8px', fontSize: '12px',display:'none' }" @click="toggle">
                        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                    </a>-->
        </a-col>
      </a-row>
    </a-form>
    <div class="search-result-list" :style="{ marginTop: '0px' }">
      <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="10" :total="50" rowKey="ID" :loading="loading" :scroll="scroll" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" @change="pageCurrentChange" position="bottom" tableLayout="fixed">
        <span slot="action" slot-scope="text, record">
          <a-button :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right: 5px'>
            <a-icon :type="button.iconLink" />
          </a-button>
        </span>
        <span slot="state" slot-scope="text, record">
          <a-tag v-if="text==0" color="green">启用</a-tag>
          <a-tag v-if="text==1" color="red">禁用</a-tag>
          <a-tag v-if="text==2" color="orange">锁定</a-tag>
        </span>
        <span slot="ifrlj" slot-scope="text, record">

          <a-tag v-if="text==1" color="green">正常</a-tag>
          <a-tag v-if="text==0" color="red">禁用</a-tag>
        </span>
        <span slot="birthday" slot-scope="text, record">
          {{$jsc.formatDate(text)}}
        </span>
        <a slot="loginName" slot-scope="text,record" @click="openDlg(record,'view')">{{ text }}</a>

      </a-table>
    </div>

  </div>
</template>
<script>
import moment from 'moment'
const columns = [
  {
    title: '序号',
    dataIndex: 'rownum',
    width: 100,
    key: 'rownum'
  },
  {
    title: '登陆账号',
    dataIndex: 'loginName',
    width: 200,
    key: 'loginName',
    scopedSlots: { customRender: 'loginName' }
  },
  {
    title: '真实姓名',
    dataIndex: 'username',
    width: 150,
    key: 'username'
  },
  {
    title: '用户角色',
    dataIndex: 'roleTxt',
    width: 150,
    key: 'roleTxt'
  },
  {
    title: '人脸机',
    dataIndex: 'ifrlj',
    width: 100,
    key: 'ifrlj',
    scopedSlots: { customRender: 'ifrlj' }
  },
  {
    title: '账号状态',
    dataIndex: 'state',
    width: 100,
    key: 'state',
    scopedSlots: { customRender: 'state' }
  },
  {
    title: '性别',
    dataIndex: 'sex',
    width: 80,
    key: 'sex'
  },
  //{
  //    title: '出生年月',
  //    dataIndex: 'birthday',
  //    width: 130,
  //    key: 'birthday',
  //    scopedSlots: { customRender: 'birthday' }
  //},
  //{
  //    title: '手机号码',
  //    dataIndex: 'phone',
  //    key: 'phone',
  //    width: 130,
  //},
  {
    title: '所属单位',
    dataIndex: 'departName',
    width: 200,
    key: 'departName'
  },
  //{
  //    title: '办公电话',
  //    dataIndex: 'phone2',
  //    width: 130,
  //    key: 'phone2',
  //},
  //{
  //    title: '职务',
  //    dataIndex: 'zw',
  //    width: 130,
  //    key: 'zw',
  //},
  //{
  //    title: '籍贯',
  //    dataIndex: 'native',
  //    width: 100,
  //    key: 'sort1',
  //},
  //{
  //    title: '民族',
  //    dataIndex: 'mz',
  //    width: 100,
  //    key: 'mz',
  //},
  {
    title: '操作',
    key: 'action',
    fixed: 'right',
    width: 150,
    scopedSlots: { customRender: 'action' }
  }
]

const loading = true
const strWhere = 'del=0'
const action = 'add'
export default {
  data() {
    return {
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      showexport: false,
      scroll: {
        x: 900,
        y: 900
      },
      view_fields: {
        viewModel: false,
        title: '检查项',
        data: []
      },
      v_state: '0',
      opration: '',
      roleData: [],
      ploginidData: [],
      xmlxData: [],
      townData: [],
      // qz_ifVisible:false,
      qz_ifshow: false,
      qzLoginid: '',
      strWhere2: '',
      imageUrl: '', //'http://58.211.49.230:8051/upload/2020/10/15/193321/%E5%9B%9B%E8%AE%AE%E4%B8%A4%E5%85%AC%E5%BC%80.png',
      imagePath: '',
      //actionList: {
      //    add: false,
      //    remove: false,
      //    edit: false
      //},
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [],
      columns,
      loading,
      action,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      strWhere,
      expand: false,
      formAddOptions: {
        visible: false,
        submitLoading: false,
        submitVisible: true,
        viewMode: false,
        spinning: false
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
      formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom'
      }
    }
  },
  mounted() {
    //将上面的按钮和表格内的按钮分出来
    console.log(this.$route.meta.buttons)
    if (this.$route.meta.buttons) {
      for (let i = 0; i < this.$route.meta.buttons.length; i++) {
        if (this.$route.meta.buttons[i].tag == 'inline') {
          this.inlineButtons.push(this.$route.meta.buttons[i])
        } else {
          this.buttons.push(this.$route.meta.buttons[i])
        }
      }
    }

    this.init()
    this.actions()
    window.onresize = () => {
      this.init()
    }
    this.bindDropDownData()
    // if (this.$User.user.userInfo.role < 998) {
    //     this.formSearch.setFieldsValue({ departLoginid: this.$User.user.userInfo.loginid });
    //     this.bindList();
    //     this.qz_ifVisible = true;
    // } else {
    //     this.bindList();
    // }
    this.bindList()
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    bindDropDownData() {
      var _this = this

      this.$axios({
        url: '/api/Zzgl/GetTown',
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.townData = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })

      this.$axios({
        url: '/api/dfc/common/getdfcDepart',
        method: 'get'
      }).then(res => {
        if (res.status === 200) {
          this.ploginidData = res.data.data
        }
      })

      this.$axios({
        url: '/api/SysSet/GetRoleList',
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.roleData = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    btnClick(text, item) {
      let _this = this
      this.opration = text
      switch (text) {
        case '新增':
          this.openDlg()
          break
        case '编辑':
          this.openDlg(item)
          break
        case '查看':
          this.openDlg(item, 'view')
          break
        case '人脸机新增':
          this.$confirm({
            title: '操作确认',
            content: '确定要发送到人脸机吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.rljadd()
            }
          })

          break
        case '人脸机删除':
          this.$confirm({
            title: '操作确认',
            content: '确定要从人脸机删除吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.rljdel()
            }
          })

          break
        case '删除':
          this.$confirm({
            title: '操作确认',
            content: '确定要删除 ' + item.loginName + ' 吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.del(item)
            }
          })
          break
      }
    },
    bindList: async function () {
      this.formSearch.validateFields((error, values) => {
        error = 1
        // console.log(values)
        this.strWhere =
          "del=0 and (ploginid='201223163030156425' or departLoginid='201223163030156425')"
        for (var obj in values) {
          if (values[obj]) {
            if (obj != 'departLoginid') {
              this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
            } else {
              this.strWhere += ' and ' + obj + "='" + values[obj] + "'"
            }
          }
        }
        this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
        console.log(this.strWhere)
      })
      this.loading = true

      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere)
      this.strWhere = this.strWhere.data
      await this.InitGrid()
    },
    InitGrid: function () {
      var _that = this
      _that.loading = true
      console.log(_that.strWhere)
      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName: 'v_sys_userInfo',
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          filesFields: '',
          strWhere: _that.strWhere
        }
      })
        .then(function (response) {
          console.log(response) //return;
          _that.loading = false
          if (response.status === 200) {
            _that.tableData = response.data.data
            _that.pagination.total = response.data.count
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    phoneCheck(rule, value, callbackFn) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!reg.test(value)) {
        callbackFn('请输入正确的手机号码')
        return
      }
      callbackFn()
    },
    onShowSizeChange(current, pageSize) {
      console.log(current, pageSize)
      return
    },
    formSearchSubmit(e) {
      e.preventDefault()
      this.pagination.current = 1
      this.bindList()
    },
    formSearchReset() {
      this.formSearch.resetFields()
      this.strWhere = 'del=0'
      this.bindList()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    pageCurrentChange(pagination) {
      console.log(pagination)
      //return;
      this.pagination.current = pagination.current
      this.bindList()
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        this.getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl
          this.loading = false
          console.log(info.file.originFileObj)
        })
      }
    },
    getBase64(img, callback) {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(img)
    },
    customFilesUpload2(data) {
      const formData = new FormData()
      formData.append('file', data.file)
      //formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
      this.saveFile2(formData)
    },
    saveFile2(formData) {
      var that = this
      this.$axios({
        method: 'post',
        url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
        data: formData
      })
        .then(res => {
          if (res.status == 200) {
            console.log(res)
            this.imageUrl = res.data.data.url
            this.imagePath = res.data.data.path
          } else {
            throw new Error('服务器器超时')
          }
        })
        .catch(error => {
          that.$message.error(error.message)
          console.log(error)
        })
    },
    rljadd() {
      if (this.selectedRowKeys.length == 0) {
        this.$message.info('请选择行')
      } else {
        this.$axios({
          url: '/api/zzgl/addrlj_gzry',
          method: 'post',
          data: {
            ids: this.selectedRowKeys
          }
        })
          .then(res => {
            if (res.status == 200) {
              if (res.data.code == 0) {
                this.$message.info('已添加到人脸机')

                this.formAddOptions.submitLoading = false
                this.bindList()
              } else {
                if (res.data.code != '200') {
                  this.$message.info(res.data.msg + ',请重新上传照片')
                } else {
                  this.$message.info('已添加到人脸机')
                }

                this.formAddOptions.submitLoading = false
                // console.log(res);
                // throw new Error("服务器超时2");
              }
            } else {
              throw new Error('服务器超时2')
            }
          })
          .catch(error => {
            // 请求失败处理
            this.$message.error(error.message)
            this.formAddOptions.submitLoading = false
          })
      }

      console.log(JSON.stringify(this.selectedRowKeys))
    },
    rljdel() {
      if (this.selectedRowKeys.length == 0) {
        this.$message.info('请选择行')
      } else {
        //delrlj
        this.$axios({
          url: '/api/zzgl/delrlj_gzry',
          method: 'post',
          data: {
            ids: this.selectedRowKeys
          }
        })
          .then(res => {
            if (res.status == 200) {
              if (res.data.code === 0) {
                this.$message.info('已从人脸机中删除')

                this.formAddOptions.submitLoading = false
                this.bindList()
              } else {
                console.log(res)
                throw new Error('服务器超时2')
              }
            } else {
              throw new Error('服务器超时2')
            }
          })
          .catch(error => {
            // 请求失败处理
            this.$message.error(error.message)
            this.formAddOptions.submitLoading = false
          })
      }
    },
    del(record) {
      if (record.ifrlj == '1') {
        this.$message.info('请先从人脸机中删除')
        return
      }

      this.$axios({
        url: '/api/Comon/deltag',
        method: 'post',
        data: {
          tableName: 'sys_login',
          field: 'loginid',
          value: record.loginid,
          source: 'web',
          loginid: ''
        }
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('删除成功')
              this.bindList()
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },
    openDlg: async function (record, action) {
      this.formAdd.resetFields()
      this.fileList = []
      this.formAddOptions.submitVisible = true
      this.formAddOptions.viewMode = false
      if (record !== undefined) {
        // 从后台取数据赋值
        this.formAddOptions.spinning = true
        var obj = await this.$jsc.DesEncrypt(
          this,
          'id=zhangbiao' + record.ID + 'zhangbiao'
        )
        obj = obj.data
        this.$axios
          .get(
            '/api/GetList/getListByTable?tableName=v_sys_userInfo&strWhere=' +
              obj
          ) //+ '&filesFields=loginid'
          .then(response => {
            if (response.status == 200) {
              if (response.data.code == 0) {
                this.formAddOptions.visible = true
                var result = response.data.data
                console.log(response.data)
                setTimeout(() => {
                  this.formAdd.setFieldsValue(result[0])
                  this.formAdd.setFieldsValue({
                    state: result[0].state.toString(),
                    birthday: this.$jsc.formatDate(result[0].birthday),
                    nowworktime: this.$jsc.formatDate(result[0].nowworktime),
                    worktime: this.$jsc.formatDate(result[0].worktime),
                    dytime: this.$jsc.formatDate(result[0].dytime)
                  })
                  this.imageUrl =
                    result[0].headimg_path == ''
                      ? ''
                      : this.$axios.defaults.fileURL + result[0].headimg_path
                  this.editgetText('a')
                  this.formAddOptions.spinning = false
                }, 100)
              } else {
                this.$message.error(response.data.msg)
                this.formAddOptions.spinning = false
              }
            } else {
              // vue.prototype.$message.info('This is an error message');
            }
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error)
          })
        if (action === 'view') {
          // 预览模式
          this.formAddOptions.submitVisible = false
          this.formAddOptions.viewMode = true
        }
      } else {
        var _this = this
        // setTimeout(() => { if (_this.$User.user.userInfo.role < 998) { _this.formAdd.setFieldsValue({ departLoginid: _this.$User.user.userInfo.loginid }); } }, 100)
        this.formAddOptions.visible = true
      }
    },
    formAddSubmit() {
      this.formAdd.validateFields((err, values) => {
        if (!err) {
          console.log(values) //return;
          values.headimg_path = this.imagePath
          values.role = 50
          //if (values.nowworktime != null) { values.nowworktime = values['nowworktime'].format("YYYY-MM-DD"); }
          //if (values.dytime != null) { values.dytime = values['dytime'].format("YYYY-MM-DD"); }
          //if (values.worktime != null) { values.worktime = values['worktime'].format("YYYY-MM-DD"); }
          //if (values.birthday != null) { values.birthday = values['birthday'].format("YYYY-MM-DD"); }

          //values.nowworktime = values.nowworktime == null ? "" : moment(values['nowworktime']).format("YYYY-MM-DD");
          //values.dytime = values.dytime == null ? "" : moment(values['dytime']).format("YYYY-MM-DD");
          //values.worktime = values.worktime == null ? "" : moment(values['worktime']).format("YYYY-MM-DD");
          //values.birthday = values.birthday == null ? "" : moment(values['birthday']).format("YYYY-MM-DD");

          console.log(values.nowworktime)
          console.log(values.dytime)
          console.log(values.worktime)
          console.log(values.birthday) //return;
          this.formAddOptions.submitLoading = true
          this.$axios({
            url: '/api/SysSet/EditUserInfo',
            method: 'post',
            data: values
          })
            .then(res => {
              if (res.status == 200) {
                if (res.data.code === 0) {
                  this.$message.info('保存成功')
                  this.formAddOptions.visible = false
                  this.formAddOptions.submitLoading = false
                  this.bindList()
                } else {
                  console.log(res)
                  throw new Error('服务器超时2')
                }
              } else {
                throw new Error('服务器超时2')
              }
            })
            .catch(error => {
              // 请求失败处理
              this.$message.error(error.message)
              this.formAddOptions.submitLoading = false
            })
        }
      })
    },

    filesCancelPreview() {
      this.previewVisible = false
    },
    async filesPreview(file) {
      //console.log(file.ext)
      // if (!file.url && !file.preview) {
      //   file.preview = await getBase64(file.originFileObj)
      // }
      // this.previewImage = file.url || file.preview
      // this.previewVisible = true
      location.href = file.url
    },
    filesChange: function (file) {
      if (file.file.status === 'removed') {
        this.fileList = file.fileList
      }
    },
    customFilesUpload(data) {
      const formData = new FormData()
      formData.append('file', data.file)
      //formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
      this.saveFile(formData)
    },
    saveFile(formData) {
      this.$axios({
        method: 'post',
        url: 'http://192.168.0.220:8002/api/FileSet/Upload',
        data: formData
      })
        .then(res => {
          if (res.status == 200) {
            var num = this.fileList.length
            this.fileList.push(res.data.data)
            this.uploadgetText('a', num, res.data)
          } else {
            throw new Error('服务器器超时')
          }
        })
        .catch(error => {
          this.$message.error(error.message)
          console.log(error)
        })
    },
    init() {
      var that = this
      size()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector(
            '#components-form-demo-advanced-search>form'
          )
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('p')[0] - 270
          }
        })
      }
    },
    /**
     * @param {String} classname；upload的类名
     */
    editgetText(classname) {
      this.$nextTick(() => {
        var els = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span'
        )
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        //console.log(els)
        for (var i = 0, len = els.length; i < len; i++) {
          var type = els[i].textContent.split('.')[1]
          if (type !== 'png' && type !== 'jpg') {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = els[i].textContent
            if (temptext.length > 7) {
              temptext = temptext.substr(0, 3) + '...' + type
            }
            cel.innerText = temptext
            spanels[i].prepend(cel)
          }
        }
      })
    },
    /**
     * @param {String} classname；upload的类名
     * @param {Number} num；原有的已上传文件数量
     * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
     */
    uploadgetText(classname, num, response) {
      this.$nextTick(() => {
        //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        var tempNodeList = []
        for (var i = 0; i < spanels.length - num; i++) {
          // console.log(spanels[num+i])
          tempNodeList.push(spanels[num + i])
          for (var j = 0; j < tempNodeList.length; j++) {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = response.name
            if (temptext.length > 7) {
              temptext = temptext.substr(0, 3) + '...' + response.ext
            }
            cel.innerText = temptext
            tempNodeList[j].prepend(cel)
          }
        }
      })
    },
    actions() {
      if (this.inlineButtons.length == 0) {
        columns.pop()
      }
    }
  }
}

//function getBase64(file) {
//    return new Promise((resolve, reject) => {
//        const reader = new FileReader()
//        reader.readAsDataURL(file)
//        reader.onload = () => resolve(reader.result)
//        reader.onerror = error => reject(error)
//    })
//}
</script>
<style>
.ant-layout-footer {
  display: none;
}

#components-form-demo-advanced-search {
  background: white;
  box-sizing: border-box;
  padding: 20px 20px 0;
}

.ant-form-item {
  margin-bottom: 12px;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: !important;
}

/*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 1px 1px 1px 1px !important;
}
</style>