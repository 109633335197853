<template>
    <div>
        <a-menu mode="horizontal" id="topmenu">
            <template v-for="topButton in topButtons">
                <a-menu-item 
                    :key="topButton.id" 
                    @click="btnClick(topButton)">{{topButton.title}}
                </a-menu-item>
            </template>
        </a-menu>
    </div>
</template>
<script>
    export default {
        props: ['topButtons'],
        methods: {
            btnClick(topButton) {
                const { path } = topButton
                this.$router.push(path)
            }
        }
    }
</script>
<style>
    .ant-layout-header .ant-menu-item-active {
        color: #FFE08A !important;
        border-bottom: 2px solid #FFE08A !important;
    }
    .ant-layout-header .ant-menu-item-selected {
        color: #FFE08A !important;
        border-bottom: 2px solid #FFE08A !important;
    }
</style>