<template>
    <div id="components-form-demo-advanced-search">
        123
    </div>
</template>
<script>
    const loading = true
    const strWhere = 'del=0'
    const action = 'add'
    
    export default {
        data() {
            return {
                showexport: false,
                scroll: {
                    x: 2000,
                    y: 900
                },
                view_fields: {
                    viewModel: false,
                    title: this.$route.query.titles,
                    data:[]
                },
                activeKey:[],
                customStyle:'background: #fff;border-radius: 4px;margin-bottom: 0px;border: 1;overflow: hidden',
                opration: '',
                townData: [],
                zzData: [],
                xmlxData: [],
                qz_ifshow: false,
                qzLoginid: '',
                strWhere2:'',
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                tableData: [],
                loading,
                action,
                previewVisible: false,
                previewImage: '',
                fileList: [],
                strWhere,
                expand: false,
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
            }
        },
        mounted() {
            this.init()
            window.onresize = () => {
                this.init()
            }
        },
        created() {
            //this.load_viewData();
            this.dc();
        },
        methods: {
            dc() {
                const column = [
                    {
                        title: 'Name',
                        key: 'name',
                        type: 'text'
                    },
                    {
                        title: 'Pic',
                        key: 'pic',
                        type: 'image',
                        width: 80,
                        height: 50
                    }
                ]
                const data = [
                    {
                        name: 'xiao',
                        age: '18',
                        pic: 'https://profile.csdnimg.cn/9/8/3/2_xiaoxiaojie12321',
                        children: [{ a: 1, b: 2}]
                    },
                    {
                        name: 'jie',
                        age: '18',
                        pic: 'https://profile.csdnimg.cn/9/8/3/2_xiaoxiaojie12321',
                        children: [{ a: 1, b: 2 }]
                    }
                ]
                this.$table2excel(column, data, 'info')
            },
            load_viewData() {
                var _that = this
                this.$axios({
                    url: '/api/Zzgl/GetXczzInfo?xczzbh=516BF90D-F994-4A32-9231-2FE582EE1398',
                    method: 'get',
                }).then(function (response) {
                    console.log(response.data.data); //return;
                    if (response.status === 200) {
                        _that.view_fields.data = response.data.data
                    }
                }).catch(function (error) {
                    console.log(error)
                })
            },
            init() {
                var that = this
                size()
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 270
                        }
                    })
                }
            },
            /**
             * @param {String} classname；upload的类名
             */
            editgetText(classname) {
                this.$nextTick(() => {
                    var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    //console.log(els)
                    for (var i = 0, len = els.length; i < len; i++) {
                        var type = els[i].textContent.split('.')[1]
                        if (type !== 'png' && type !== 'jpg') {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = els[i].textContent
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + type
                            }
                            cel.innerText = temptext
                            spanels[i].prepend(cel)
                        }
                    }
                })
            },
            /**
             * @param {String} classname；upload的类名
             * @param {Number} num；原有的已上传文件数量
             * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
             */
            uploadgetText(classname, num, response) {
                this.$nextTick(() => {
                    //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    var tempNodeList = []
                    for (var i = 0; i < spanels.length - num; i++) {
                        // console.log(spanels[num+i])
                        tempNodeList.push(spanels[num + i])
                        for (var j = 0; j < tempNodeList.length; j++) {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = response.name
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + response.ext
                            }
                            cel.innerText = temptext
                            tempNodeList[j].prepend(cel)
                        }
                    }
                })
            },
            actions() {
                if (this.inlineButtons.length == 0) {
                    columns.pop();
                }
            }
        }
    }

    //function getBase64(file) {
    //    return new Promise((resolve, reject) => {
    //        const reader = new FileReader()
    //        reader.readAsDataURL(file)
    //        reader.onload = () => resolve(reader.result)
    //        reader.onerror = error => reject(error)
    //    })
    //}
</script>
<style>
    .ant-layout-footer {
        display: none;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
        padding: 20px 20px 0;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        font-size: 18px;
    }

    .ant-collapse > .ant-collapse-item p {
        font-size: 15px;
    }
    /*页面字体大小*/
    .ant-collapse > .ant-collapse-item p, .ant-collapse {
        font-size: 16px;
    }

        .ant-collapse > .ant-collapse-item > .ant-collapse-header {
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
        }

    .ant-tag {
        font-size: 15px;
    }
    /*结束*/
</style>