<template>
  <div id="components-form-demo-advanced-search1">
    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
      <a-row :gutter="24">
        <a-col :span="10">
          <a-form-item label="">
            <a-range-picker :placeholder="['开始时间','结束时间']" v-model="defaultValue" @change="onRangePickerChange" />
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="">
            <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
            <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
          </a-form-item>
        </a-col>
      </a-row>

      <!--<a-row :gutter="[24,12]">
                <a-col :span="18" :style="{ textAlign: 'right' }">
                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    <a :style="{ marginLeft: '8px', fontSize: '12px',display:'none' }" @click="toggle">
                        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                    </a>
                </a-col>
            </a-row>-->
    </a-form>
    <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="20" :total="50" rowKey="id" :loading="loading" :scroll="scroll" @change="pageCurrentChange" position="bottom" tableLayout="fixed">
      <span slot="state" slot-scope="text, record">
        <a-tag v-if="text=='达标'" color="green">{{text}}</a-tag>
        <a-tag v-if="text=='未达标'" color="red">{{text}}</a-tag>
        <a-tag v-if="text=='超标'" color="green">{{text}}</a-tag>
        <span v-if="text=='-'" style="width:20px;">—</span>
      </span>
      <span slot="oper" slot-scope="text, record">
        <a-button type="primary" v-if="record.state!='-'" @click="jumpList(record)">查看未查站闸</a-button>
      </span>
    </a-table>
  </div>
</template>
<script>
import moment from 'moment'
const strWhere = 'del=0'
export default {
  data() {
    return {
      scroll: {},
      dataopen: false, // 默认是否打开弹框
      defaultValue: [
        moment().format('yyyy-MM-DD'),
        moment().format('yyyy-MM-DD')
      ],
      setime: {
        starttime: moment().format('yyyy-MM-DD'),
        endtime: moment().format('yyyy-MM-DD'),

        starttime_sure: moment().format('yyyy-MM-DD'),
        endtime_sure: moment().format('yyyy-MM-DD')
      },
      queryParam: {
        years: ''
      },
      townData: [],
      zzData: [],
      showexport: false,
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'rnn',
          key: 'rnn',
          fixed: 'left',
          width: 100
        },
        {
          title: '队伍',
          dataIndex: 'dictName',
          key: 'dictName'
        },
        {
          title: '额定巡查站闸数',
          dataIndex: 'rated',
          key: 'rated'
        },
        {
          title: '实际巡查站闸数',
          dataIndex: 'reality',
          key: 'reality'
        },
        {
          title: '达标情况',
          dataIndex: 'state',
          key: 'state',
          scopedSlots: { customRender: 'state' }
        },
        {
          title: '',
          dataIndex: '',
          key: '',
          scopedSlots: { customRender: 'oper' }
        }
      ],
      loading: true,
      action: 'add',
      previewVisible: false,
      previewImage: '',
      fileList: [],
      strWhere,
      expand: false,
      formAddOptions: {
        visible: false,
        submitLoading: false,
        submitVisible: true,
        viewMode: false,
        spinning: false
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search2' }),
      formAdd: this.$form.createForm(this, { name: 'advanced_search3' }),
      pagination: {
        total: 0,
        pageSize: 15, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom'
      }
    }
  },
  created() {
    //this.setime.datetime = moment().format('yyyy-MM-DD');
    //this.setime.endtime = moment().format('yyyy-MM-DD');
    //setTimeout(() => {
    //    this.formSearch.setFieldsValue({ "setime": this.setimeDefault });
    //},100);
  },
  mounted() {
    if (this.$route.meta.buttons) {
      for (let i = 0; i < this.$route.meta.buttons.length; i++) {
        if (this.$route.meta.buttons[i].tag == 'inline') {
          this.inlineButtons.push(this.$route.meta.buttons[i])
        } else {
          this.buttons.push(this.$route.meta.buttons[i])
        }
      }
    }

    this.init()
    this.actions()
    window.onresize = () => {
      this.init()
    }
    //this.formSearch.setFieldsValue({ "setime": this.setimeDefault });
    this.bindList()
  },
  methods: {
    jumpList(item) {
      let path =
        'xuncha-myrecorder?type=&createTime1=' +
        this.setime.starttime_sure +
        '&createTime2=' +
        this.setime.endtime_sure +
        ''

      if (item.state == '未达标') {
        path += '&isExec=0'
      }
      //console.log(path);
      //this.setime.starttime_sure = this.setime.starttime;
      //this.setime.endtime_sure = this.setime.endtime;

      this.$router.push({ path: path })
    },
    onRangePickerChange(date, dateString) {
      this.setime.starttime = dateString[0]
      this.setime.endtime = dateString[1]
    },
    moment,
    openChangeYear(status) {
      if (status) {
        this.dataopen = true
      } else {
        this.dataopen = false
      }
    },
    panelChangeYear(value) {
      this.queryParam.years = value
      this.dataopen = false
      this.getdata(new Date(value).getFullYear())
    },
    getdata(value) {
      this.year = value
    },
    select_month(value) {
      this.month = value
    },
    bindList: async function () {
      await this.InitGrid()
    },
    InitGrid: function () {
      this.formSearch.validateFields((err, values) => {
        if (!err) {
          var _that = this
          _that.loading = true
          this.$axios({
            url:
              '/api/zzgl/GetXcxxByTeamId?starttime=' +
              this.setime.starttime +
              '&endtime=' +
              this.setime.endtime +
              '',
            method: 'get'
          })
            .then(response => {
              _that.loading = false
              if (response.status === 200) {
                console.log(response)
                _that.tableData = response.data.data
                _that.pagination.total = response.data.count

                this.setime.starttime_sure = this.setime.starttime
                this.setime.endtime_sure = this.setime.endtime
              }
            })
            .catch(function (error) {
              console.log(error)
            })
        }
      })

      //var sj_month = this.month == undefined ? "" : this.month;
    },
    formSearchSubmit(e) {
      e.preventDefault()
      //this.pagination.current = 1
      this.bindList()
    },
    pageCurrentChange(pagination) {
      this.pagination.current = pagination.current
      this.bindList()
    },
    formSearchReset() {
      //this.formSearch.resetFields()
      //this.strWhere = 'del=0'
      //this.formSearch.resetFields();
      //this.formSearch.setFieldsValue({ "setime": [moment('2015/01/01'), moment('2015/01/01')] });
      this.defaultValue = [
        moment().format('yyyy-MM-DD'),
        moment().format('yyyy-MM-DD')
      ]
      this.setime.starttime = this.defaultValue[0]
      this.setime.endtime = this.defaultValue[1]

      this.queryParam.years = ''
      this.year = ''
      this.month = undefined
      this.bindList()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    init() {
      var that = this
      size()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector(
            '#components-form-demo-advanced-search1>form'
          )
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('p')[0] - 270
          }
        })
      }
    },
    actions() {
      //if (this.inlineButtons.length == 0) {
      //    this.columns.pop();
      //}
    }
  }
}
</script>
<style>
.ant-calendar-picker {
  width: 100%;
}
</style>