<template>
    <!-- 巡查管理 -->
    <div>
        <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
            <a-row :gutter="24">
                <a-col :span="4">
                    <a-form-item label="">
                        <a-input v-decorator="['bh']" placeholder="编号" :maxLength="40" />
                    </a-form-item>
                </a-col>
                <a-col :span="4">
                    <a-form-item label="">
                        <a-select v-decorator="['townId']" placeholder="请选择区镇">
                            <a-select-option :value="item.value" v-for="(item, index) in townList" :key="index">{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="4">
                    <a-form-item label="">
                        <a-input v-decorator="['xmmc']" placeholder="项目名称" :maxLength="40" />
                    </a-form-item>
                </a-col>
                <a-col :span="4">
                    <a-form-item label="">
                        <a-select v-decorator="['isError']" placeholder="是否存在问题">
                            <a-select-option :value="true">是</a-select-option>
                            <a-select-option :value="false">否</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="4">
                    <a-form-item label="">
                        <a-button type="primary" :style="{ width: '48px' }" html-type="submit" icon="search"></a-button>
                        <a-button type="" :style="{ width: '48px', marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>

        <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="10" :total="50" rowKey="id" :loading="loading" :scroll="{x: true}" @change="pageCurrentChange" position="bottom" tableLayout="fixed">
            <span slot="isError" slot-scope="text">
                <span>{{ text ? '是' : '否' }}</span>
            </span>
            <span slot="action" slot-scope="text, record">
                <span v-for="button in inlineButtons" :key="button.title">
                    <a-button :type="button.className" size="small" @click="btnClick(button.title, record)" style="margin-right: 5px" :disabled="record.isEnd == 1">{{record.isEnd == 1 ? '已派单' : button.title}}</a-button>
                </span>
            </span>
        </a-table>
    </div>
</template>
<script>
const columns = [
    {
        title: "序号",
        dataIndex: "id",
        width: 70,
    },
    {
        title: "编号",
        dataIndex: "bh",
    },
    {
        title: "区镇",
        dataIndex: "town",
        ellipsis: true,
    },
    {
        title: "项目名称",
        dataIndex: "xmmc",
        ellipsis: true,
    },
    {
        title: "一级考核内容",
        dataIndex: "khnr1",
        ellipsis: true,
    },
    {
        title: "二级考核内容",
        dataIndex: "khnr2",
        ellipsis: true,
    },
    {
        title: "三级考核内容",
        dataIndex: "khnr3",
        ellipsis: true,
    },
    {
        title: "标准分值",
        dataIndex: "bzfz",
        ellipsis: true,
    },
    {
        title: "检查评分原则",
        dataIndex: "pfyz",
        ellipsis: true,
    },
    {
        title: "扣分",
        dataIndex: "kf",
        ellipsis: true,
    },
    {
        title: "是否存在问题",
        dataIndex: "isError",
        scopedSlots: { customRender: "isError" },
    },
    {
        title: "整改意见",
        dataIndex: "zgyj",
        ellipsis: true,
    },
    {
        title: "操作",
        dataIndex: "action",
        fixed: "right",
        width: 100,
        scopedSlots: { customRender: "action" },
    },
];

const loading = false;
const strWhere = "del=0";
const action = "add";
export default {
    data() {
        return {
            buttons: [],
            inlineButtons: [],
            townList: [],
            tableData: [],
            columns,
            loading,
            action,
            strWhere,
            formAddOptions: {
                visible: false,
                submitLoading: false,
                submitVisible: true,
                viewMode: false,
                spinning: false,
                dateVisible: false,
            },
            formSearch: this.$form.createForm(this, {
                name: "advanced_search",
            }),
            formAdd: this.$form.createForm(this, { name: "advanced_search11" }),
            pagination: {
                total: 0,
                pageSize: 10, // 每页中显示10条数据
                current: 1,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"], // 每页中显示的数据
                showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
                position: "bottom",
            },
        };
    },
    created() {
        // this.getList();
        this.getTown();
        this.bindList();
    },
    mounted() {
        //将上面的按钮和表格内的按钮分出来
        // console.log("按钮", this.$route.meta.buttons);
        for (let i = 0; i < this.$route.meta.buttons.length; i++) {
            if (this.$route.meta.buttons[i].tag == "inline") {
                this.inlineButtons.push(this.$route.meta.buttons[i]);
            } else {
                this.buttons.push(this.$route.meta.buttons[i]);
            }
        }
    },
    methods: {
        getTown() {
            this.$axios
                .get("/api/comon/GetTown", {
                    pageIndex: 1,
                    pageSize: 10,
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.code == 0) {
                        this.townList = res.data.data;
                    }
                });
        },
        bindList: async function () {
            this.formSearch.validateFields((error, values) => {
                error = 1;
                console.log('values', values)
                this.strWhere = "del=0 ";
                for (var obj in values) {
                    if (values[obj]) {
                        this.strWhere +=
                            " and " + obj + " like 'kejin" + values[obj] + "kejin'";
                    }
                }
                //console.log(this.strWhere)
                this.strWhere = this.strWhere.replace(/'/g, "zhangbiao");
            });
            this.loading = true;

            this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere);
            this.strWhere = this.strWhere.data;
            await this.InitGrid();
        },
        InitGrid: function () {
            var _that = this;
            _that.loading = true;
            this.$axios({
                url: "/api/GetList/getListByTable",
                method: "get",
                params: {
                    tableName: "v_fxjc_xcgl_rcxcInfo",
                    page: _that.pagination.current,
                    limit: _that.pagination.pageSize,
                    // filesFields: 'bh',
                    strWhere: _that.strWhere,
                },
            })
                .then(function (response) {
                    _that.loading = false;
                    if (response.status === 200) {
                        console.log(response.data.data);
                        _that.tableData = response.data.data;
                        _that.pagination.total = response.data.count;
                    }
                })
                .catch(function (error) {
                    //console.log(error)
                });
        },
        btnClick(text, item) {
            switch (text) {
                case "派单":
                    // this.openDlg(item)
                    let titleList = this.columns.map((item) => {
                        let newItem = new Object();
                        newItem.title = item.title;
                        newItem.dataIndex = item.dataIndex;
                        return newItem;
                    });
                    // slice去除序号和操作两列
                    titleList = JSON.stringify(titleList.slice(0, -1));
                    let inspectDetail = JSON.stringify(item);
                    // this.$emit("onSwitch", {
                    //     comp: "Edit",
                    //     titleList: titleList,
                    //     data: inspectDetail,
                    // });
                    this.$router.push({ path: './inspectRecordDispatch', query: {titleList: titleList, data: inspectDetail}})
                    break;
            }
        },
        formSearchSubmit(e) {
            e.preventDefault();
            this.pagination.current = 1;
            this.bindList();
        },
        formSearchReset() {
            this.formSearch.resetFields();
            this.strWhere = "del=0";
            this.bindList();
        },
        pageCurrentChange(pagination) {
            console.log(pagination);
            this.pagination.current = pagination.current;
        },
    },
};
</script>

<style>
</style>
