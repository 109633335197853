<template>
    <div id="components-form-demo-advanced-search">
		<a-modal v-model="formAddOptions.visible" title="评分" width="800px">
		        <a-spin :spinning="formAddOptions.spinning">
		            <a-form :form="formAdd" :label-col="{ span:8 }" :wrapper-col="{ span:16 }" labelAlign="left">
		                <a-form-item label="" style="display:none">
		                    <a-input v-decorator="['id']" />
		                </a-form-item>
		
		                
		                <a-row type="flex" justify="space-between">
		                    <a-col :span="24">
		                        <a-form-item label="评分">
		                          
									<a-rate v-model="fs" />
		                        </a-form-item>
		                    </a-col>
		                    
		                </a-row>
		              
		 
		            </a-form>
		           
		        
				</a-spin>
		        <template slot="footer">
		            <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
		            <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
		        </template>
		    </a-modal>
		    
			
        <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
            <a-row :gutter="24">
                <a-col :span="6">
                    <a-form-item label="">
                        <a-input placeholder="标题" v-decorator="['title']" />
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="派单人" v-decorator="['createUser']"></a-input></a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label="">
                        <a-select placeholder="是否回复" v-decorator="['isReply']" style="width:100%">
                            <a-select-option value="0">
                                否
                            </a-select-option>
                            <a-select-option value="2">
                                是
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
				<a-col :span="6">
				    <a-form-item label="">
				        <a-select placeholder="是否打分" v-decorator="['isdf']" style="width:100%">
				            <a-select-option value="0">
				                否
				            </a-select-option>
				            <a-select-option value="1">
				                是
				            </a-select-option>
				        </a-select>
				    </a-form-item>
				</a-col>
                <a-col :span="6">
                    <a-form-item label="">
                        <a-range-picker id="xc_time" :placeholder="['开始时间','结束时间']" @change="onRangePickerChange" v-decorator="['createTime']" />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="[24,12]">
                <a-col :span="6" :style="{ textAlign: 'left' }">
                    <a-button type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button>
                </a-col>
                <a-col :span="18" :style="{ textAlign: 'right' }">
                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    <a :style="{ marginLeft: '8px', fontSize: '12px' ,display:'none'}" @click="toggle">
                        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                    </a>
                </a-col>
            </a-row>
        </a-form>
        <div class="search-result-list" :style="{ marginTop: '0px' }">
            <a-table :columns="columns"
                     :data-source="tableData"
                     :pagination="pagination"
                     :pageSize="20"
                     :total="50"
                     rowKey="id"
                     :loading="loading"
                     :scroll="scroll"
                     @change="pageCurrentChange"
                     position="bottom"
                     tableLayout="fixed">
                <span slot="isReply" slot-scope="text,record"><span v-if="text==0" style="color:gray;">未回复</span><span v-if="text==2" style="color:green;">已回复</span></span>
                <span slot="isEnd" slot-scope="text,record"><span v-if="text==0" style="color:darkorange;">办理中</span><span v-if="text==2" style="color:green;">已办结</span></span>
                <span slot="action" slot-scope="text, record">
                    <a-button type="primary" size="small" key="0" @click="btnClick('办理',record)" style="margin-right:5px;display:inline" v-if="record.isEnd=='0'&&record.isReply=='2'&&record.createUserId==$User.user.userInfo.loginid">
                        <a-icon type="edit" />
                        办理
                    </a-button>
					
					<a-button type="primary" size="small" key="0" @click="btnClick('回复',record)" style="margin-right:5px;display:inline" v-if="record.isReply=='0'&&record.zxrLoginid==$User.user.userInfo.loginid">
						<a-icon type="edit" />
						回复
					</a-button>
					
					<a-button type="normal" size="small" key="11" @click="btnClick('评分',record)" v-if="record.df==null" style="display:inline;margin-right:5px;">
					    <a-icon type="smile" theme="twoTone" />
					    打分
					</a-button>
                    <a-button type="normal" size="small" key="1" @click="btnClick('查看',record)" style="display:inline;margin-right:5px;">
                        <a-icon type="search" />
                        查看
                    </a-button>
                    <a-button v-if="record.isEnd!='2'" :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right:5px;display:inline'>
                        <a-icon :type="button.iconLink" />
                    </a-button>
                </span>
                <a slot="title2" slot-scope="text,record" key="11" @click="btnClick('查看',record)">{{ toTime(record.createTime)+" " +get_typeinfo_txt(record.typeinfo)}}问题</a>
				<template slot="df" slot-scope="text,record">
                    <span v-if="text" style="color: gold;">{{ '★'.repeat(record.df) }}</span>
                    <span v-else>未评</span>
                </template>
            </a-table>
        </div>
    </div>
</template>
<script>
    const columns = [
        {
            title: '序号',
            dataIndex: 'rownum',
            width: 70,
            fixed: "left",
            key: 'rownum'
        },
        {
            title: '工单编号',
            dataIndex: 'num',
            width: 120,
            fixed: "left",
            key: 'title',
        },
        {
            title: '标题',
            dataIndex: 'title',
            width: 300,
            fixed: "left",
            key: 'title',
            scopedSlots: { customRender: 'title2' }
        },
		{
		    title: '得分',
		    dataIndex: 'df',
		    width: 300,		   
		    key: 'df',
		    scopedSlots: { customRender: 'df' }
		},
        {
            title: '派单人',
            dataIndex: 'createUser',
            width: 150,
            key: 'createUser'
        },
        {
            title: '派单时间',
            dataIndex: 'createTime',
            width: 200,
            key: 'createTime'
        },
        {
            title: '回复人',
            dataIndex: 'zxr',
            width: 200,
            key: 'zxr'
        },
        {
            title: '是否回复',
            dataIndex: 'isReply',
            width: 150,
            key: 'isReply',
            scopedSlots: { customRender: 'isReply' }
        },
        {
            title: '状态',
            dataIndex: 'isEnd',
            width: 150,
            key: 'isEnd',
            scopedSlots: { customRender: 'isEnd' }
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 210,
            scopedSlots: { customRender: 'action' }
        }
    ]

    const loading = true
    const strWhere = 'del=0'
    const action = 'add'
    export default {
        data() {
            return {
                showexport: false,
                scroll: {
                    x: 900,
                    y: 900
                },
                //actionList: {
                //    add: false,
                //    remove: false,
                //    edit: false
                //},
                config: {
                    rules: [{ type: 'object', required: true, message: '请选择办理期限!' }],
                },
				fs:0,
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                tableData: [],
                blr_data: [],
                blr_wbcompany_data: [],
                blr_wgy_data: [],
                problems_data: [],
                isEnd: '',
                xmbh: '',
                StartTime: '',
                EndTime: '',
                columns,
                loading,
                action,
                previewVisible: false,
                previewImage: '',
                fileList: [],
                strWhere,
                expand: false,
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
                pagination: {
                    total: 0,
                    pageSize: 15, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
                    showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: 'bottom'
                },
                web_decorator_qymc: ['companyorwgybh', { rules: [{ required: true, message: '请选择' }] }],
				
            }
        },
        mounted() {
            // this.isEnd = this.$route.query.isEnd;
			this.isEnd = 2;//已办结工单
            this.xmbh = this.$route.query.xmbh;
            //将上面的按钮和表格内的按钮分出来
            for (let i = 0; i < this.$route.meta.buttons.length; i++) {
                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                }
                else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.init()
            this.actions()
            window.onresize = () => {
                this.init()
            }
            this.bindList();
            //console.log(this.$route);
            //console.log(this.buttons);
            //console.log(this.inlineButtons);
        },
        watch: {
            // '$route.query.isEnd'(newVal, oldVal) {
            //     console.log(newVal);
            //     this.isEnd = newVal;
            //     this.bindList();
            // }
        },
        methods: {
			get_typeinfo_txt(typeinfo){
				//0-视频工单，1-雨情传感器工单，2-水情传感器工单，3-积水易涝传感器工单
				if(typeinfo=="0")
				{
					return "视频设备";
					
				}else if(typeinfo=="1")
				{
					return "雨情传感器";
					
				}else if(typeinfo=="2")
				{
					return "水情传感器";
				}
				else if(typeinfo=="3")
				{
					return "积水易涝传感器";
				}
			},
            btnClick(text, item) {
                let _this = this;
                switch (text) {
                    case '办理':
                        this.$router.push({ path: "camera-workSingle-sp?id=" + item.id + "&btnVisible=1" });
                        break
					case '回复':
						    this.$router.push({ path: "camera-workSingle-sp?id=" + item.id + "&btnVisible1=1&processId="+item.processId });
						    break
                    //case '派单':
                    //	    this.gopd(item)
                    //	    break
                    case "查看":
                        this.openDlg(item, 'view');
                        break;
					case "评分":
						this.openDlg(item, 'view1');
						break;
                    case "删除":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定要删除 " + item.title + " 吗?",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.del(item);
                            }
                        });
                        break;
                }
            },
            onRangePickerChange(date, dateString) {
                this.StartTime = dateString[0];
                this.EndTime = dateString[1];
            },
            gopd(value) {
                this.$router.push("camera-pd?probh=" + value.bh)
            },
            handleblrlxChange(value) {
                let blrlx = value;

                if (blrlx == '0') {
                    this.blr_data = this.blr_wbcompany_data;
                } else if (blrlx == '1') {
                    this.blr_data = this.blr_wgy_data;
                }

            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            phoneCheck(rule, value, callbackFn) {
                const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
                if (!reg.test(value)) {
                    callbackFn('请输入正确的手机号码')
                    return
                }
                callbackFn()
            },
            SFZCheck(rule, value, callbackFn) {
                const reg = /^(\d{18}|\d{15}|\d{17}x)$/
                if (!reg.test(value)) {
                    callbackFn('请输入正确的身份证号码')
                    return
                }
                callbackFn()
            },
            // handleChange(value) {
            // 				console.log(value);
            // 				console.log(value.key,"__",value.label.trim());
            // 			},
            bindList: async function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1
                    //console.log("value="+ JSON.stringify(values))
                    this.strWhere = 'del=0 and isEnd=' + this.isEnd;
					
					
					
                    if (this.xmbh) { this.strWhere += " and xmbh=" + this.xmbh; }
                    if (!this.isEnd) { this.strWhere = 'del=0'; }
					
					if(this.$User.user.userInfo.realName!='科进开发者')
					{
						 this.strWhere+="and (zxrLoginid='"+this.$User.user.userInfo.loginid+"' or createUserId='"+this.$User.user.userInfo.loginid+"' )" 
					}
					
                    for (var obj in values) {
                        if (values[obj]) {
                            if (obj == "title" || obj == "createUser" || obj == "zxr") {
                                this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
                            }
                            if (obj == "isReply") {
                                this.strWhere += ' and ' + obj + "='" + values[obj] + "'"
                            }
							if (obj == "isdf") {
								if(values[obj]==0)
								{
									this.strWhere += ' and  df is  null'
								}else if(values[obj]==1)
								{
									this.strWhere += ' and  df is not null'
								}
							    
							}
                            if (obj == "createTime") {
                                this.strWhere += ' and ' + obj + " between '" + this.StartTime + "' and '" + this.EndTime + "'"
                            }
                        }
                    }
                    console.log(this.strWhere)
                    this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
                })

                this.loading = true

                this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere);
                this.strWhere = this.strWhere.data;
                await this.InitGrid()
            },
            InitGrid: function () {
                var _that = this
                _that.loading = true
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'v_wlw_workFlow_slipInfo',
                        page: _that.pagination.current,
                        limit: _that.pagination.pageSize,
                        // filesFields: 'bh',
                        strWhere: _that.strWhere
                    }
                })
                    .then(function (response) {
                        console.log(response)
                        _that.loading = false
                        if (response.status === 200) {
                            _that.tableData = response.data.data
                            _that.pagination.total = response.data.count
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            formSearchSubmit(e) {
                e.preventDefault()
                this.pagination.current = 1
                this.bindList()
            },
            formSearchReset() {
                this.formSearch.resetFields()
                this.strWhere = 'del=0'
                this.bindList()
            },
            toggle() {
                this.expand = !this.expand
                this.init()
            },
            pageCurrentChange(pagination) {
                this.pagination.current = pagination.current
                this.bindList()
            },
            del(record) {
                this.$axios({
                    url: '/api/cameras/delWorkerOrder',
                    method: 'post',
                    data: {
                        //tableName: 'zzgl_workFlow_slipInfo',
                        //field: 'bh',
                        value: record.bh,
                        source: 'web',
                        //loginid: ''
                    }
                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info('删除成功');
                            this.bindList();
                        }
                        else {
                            throw new Error("服务器超时");
                        }
                    }
                    else {
                        throw new Error("服务器超时");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                })
            },
            openDlg: async function (record, action) {
                this.formAdd.resetFields()
                this.fileList = []
                this.formAddOptions.submitVisible = true
                this.formAddOptions.viewMode = false
                if (record !== undefined) {
                    //// 从后台取数据赋值
                    //this.formAddOptions.spinning = true
                    //var obj = await DesEncrypt(this, 'id=zhangbiao' + record.id + 'zhangbiao');
                    //obj = obj.data;
                    //this.$axios
                    //    .get('/api/GetList/getListByTable?tableName=zzgl_wbry&strWhere=' + obj + '&filesFields=bh')
                    //    .then((response) => {
                    //        if (response.status == 200) {
                    //            if (response.data.code == 0) {
                    //                this.formAddOptions.visible = true;
                    //                var result = response.data.data;
                    //                console.log(response.data);
                    //                setTimeout(() => {
                    //                    result[0].yyqk = true
                    //                    // var objArr = { qymc: result[0].qymc, lxr: result[0].lxr, yyqk: true }
                    //                    // this.formAdd.setFieldsValue(objArr)
                    //                    this.formAdd.setFieldsValue(result[0])
                    //                    this.fileList = result[0].files_bh
                    //                    this.editgetText('a')
                    //                    this.formAddOptions.spinning = false
                    //                }, 100);
                    //            }
                    //            else {
                    //                this.$message.error(response.data.msg);
                    //                this.formAddOptions.spinning = false;
                    //            }
                    //        } else {
                    //            // vue.prototype.$message.info('This is an error message');
                    //        }
                    //    })
                    //    .catch(function (error) { // 请求失败处理
                    //        console.log(error)
                    //    })
                    if (action === 'view') { // 预览模式
                        this.$router.push({ path: "camera-workSingle-sp?id=" + record.id + "&btnVisible=0" });
                    }else if (action === 'view1'){
						 setTimeout(() => {
						 this.formAdd.setFieldsValue({"id":record.id});
						  }, 100);
						 this.formAddOptions.visible = true;
						 
						
					}
                }
                else {
                    this.formAddOptions.visible = true;
                }
            },
            filesCancelPreview() {
                this.previewVisible = false
            },
            async filesPreview(file) {
                //console.log(file.ext)
                // if (!file.url && !file.preview) {
                //   file.preview = await getBase64(file.originFileObj)
                // }
                // this.previewImage = file.url || file.preview
                // this.previewVisible = true
                location.href = file.url
            },
            filesChange: function (file) {
                if (file.file.status === 'removed') {
                    this.fileList = file.fileList
                }
            },
            customFilesUpload(data) {
                const formData = new FormData()
                formData.append('file', data.file)
                formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
                this.saveFile(formData)
            },
            formAddSubmit() {
                this.formAdd.validateFields((err, values) => {
					
                    
                    if (!err) {
                        this.formAddOptions.submitLoading = true
                        values.df = this.fs
                       
                        this.$axios({
                            url: '/api/Cameras/uploaddf',
                            method: 'post',
                            data: values
                        }).then(res => {
							this.fs=0;
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('打分成功')
                                    this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false
                                    this.bindList()
                                } else {
                                    console.log(res);
                                    throw new Error("服务器超时2");
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })

                    }
                })
            },
            saveFile(formData) {
                var that = this;
                this.$axios(
                    {
                        method: 'post',
                        url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
                        data: formData
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            var num = that.fileList.length
                            that.fileList.push(res.data.data)
                            // that.uploadgetText('a', num, res.data.data);
                        }
                        else {
                            throw new Error("服务器器超时");
                        }
                    })
                    .catch((error) => {
                        that.$message.error(error.message);
                        console.log(error)
                    })
            },
            toTime(value) {
                return this.$jsc.formatDate_hz(value);
            },
            getcompanylist() {
                // 获取维保企业列表
                this.$axios({
                    url: '/api/Zzgl/getWbCompany',
                    method: 'get',

                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code === 0) {
                            this.blr_wbcompany_data = res.data.data;
                            this.blr_data = this.blr_wbcompany_data;
                        } else {
                            console.log(res);
                            throw new Error("服务器超时2");
                        }
                    }
                    else {
                        throw new Error("服务器超时2");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                    this.formAddOptions.submitLoading = false
                })
            },
            getwgylist() {
                // 获取网格员列表
                this.$axios({
                    url: '/api/Zzgl/GetWgy',
                    method: 'get',

                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code === 0) {
                            this.blr_wgy_data = res.data.data;
                        } else {
                            console.log(res);
                            throw new Error("服务器超时2");
                        }
                    }
                    else {
                        throw new Error("服务器超时2");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                    this.formAddOptions.submitLoading = false
                })
            },
            init() {
                var that = this
                size()
                this.getcompanylist()
                this.getwgylist()
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 270
                        }
                    })
                }
            },
            /**
             * @param {String} classname；upload的类名
             */
            editgetText(classname) {
                this.$nextTick(() => {
                    var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    //console.log(els)
                    for (var i = 0, len = els.length; i < len; i++) {
                        var type = els[i].textContent.split('.')[1]
                        if (type !== 'png' && type !== 'jpg') {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = els[i].textContent
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + type
                            }
                            cel.innerText = temptext
                            spanels[i].prepend(cel)
                        }
                    }
                })
            },
            /**
             * @param {String} classname；upload的类名
             * @param {Number} num；原有的已上传文件数量
             * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
             */
            uploadgetText(classname, num, response) {
                this.$nextTick(() => {
                    //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    var tempNodeList = []
                    for (var i = 0; i < spanels.length - num; i++) {
                        // console.log(spanels[num+i])
                        tempNodeList.push(spanels[num + i])
                        for (var j = 0; j < tempNodeList.length; j++) {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = response.name
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + response.ext
                            }
                            cel.innerText = temptext
                            tempNodeList[j].prepend(cel)
                        }
                    }
                })
            },
            actions() {
                //if (this.inlineButtons.length == 0) {
                //    columns.pop();
                //}
            }
        }
    }

    //function getBase64(file) {
    //    return new Promise((resolve, reject) => {
    //        const reader = new FileReader()
    //        reader.readAsDataURL(file)
    //        reader.onload = () => resolve(reader.result)
    //        reader.onerror = error => reject(error)
    //    })
    //}
</script>
<style>
    .ant-layout-footer {
        display: none;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
        padding: 20px 20px 0;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }

    /*时间搜索框调整*/
    .ant-calendar-picker {
        width: 100%;
    }
</style>