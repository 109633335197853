<template>
    <div>
        <!-- 条件搜索 -->
        <a-form :form="form" layout="inline" style="marginBottom:15px;">
            <a-form-item>
                <a-input v-decorator="['Name']" placeholder="闸泵站名称" />
            </a-form-item>

            <a-form-item>
                <a-input v-decorator="['JGCompany']" placeholder="监管单位" />
            </a-form-item>

            <a-form-item>
                <a-input v-decorator="['Village']" placeholder="村社区" />
            </a-form-item>

            <a-form-item>
                <a-input v-decorator="['Polder']" placeholder="圩区" />
            </a-form-item>

            <a-form-item v-if="!town">
                <a-input v-decorator="['Town']" placeholder="乡镇" />
            </a-form-item>

            <a-form-item>
                <a-select 
                    v-decorator="['Type']"
                    placeholder="分类"
                    style="width: 174px !important;" >
                    <a-select-option value="站闸">站闸</a-select-option>
                    <a-select-option value="泵站">泵站</a-select-option>
                    <a-select-option value="水闸">水闸</a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item>
                <a-space>
                    <a-button type="primary" icon="search" @click="operForm('search')">搜索</a-button>
                    <a-button icon="redo" @click="operForm('reset')" >重置</a-button>
                </a-space>
            </a-form-item>
        </a-form>


        <!-- 列表 -->
        <a-table
            :columns="columns" 
            :data-source="dataSource" 
            :rowKey="record => record.rnn"
            :loading="loading"
            :scroll="scroll"
            :pagination="pagination"
            @change="changePage">

            <template slot="name" slot-scope="name, record" >
                <a href="javascript:;" @click="checkRecord(record)">{{ name }}</a>
            </template>

            <template slot="action" slot-scope="record">
                <a-button icon="file-search" type="primary" @click="checkRecord(record)" />
            </template>

        </a-table>

        <!-- 详情 -->
        <a-drawer 
            width="480" 
            placement="right" 
            :closable="false" 
            :visible="showDrawer"
            @close="showDrawer = false">

            <a-descriptions 
                :title="record.Name" 
                :column="2"  
                bordered 
                size="small">
                
                <a-descriptions-item label="编码">
                    {{ record.Encoding }}
                </a-descriptions-item>

                <a-descriptions-item label="类别">
                    {{ record.Type }}
                </a-descriptions-item>

                <a-descriptions-item label="监管单位" :span="2">
                    {{ record.JGCompany }}
                </a-descriptions-item>
            
                <a-descriptions-item label="位置" :span="2">
                    村社区：{{ record.Village }}
                    <br/>
                    乡镇：{{ record.Town }}
                    <br/>
                    圩区：{{ record.Polder }}
                    <br/>
                    河道：{{ record.Location }}
                    <br/>
                    经度：{{ record.Longitude }}
                    <br/>
                    纬度：{{ record.Latitude }}
                </a-descriptions-item>

                <a-descriptions-item label="排涝站">
                    {{ record.plz }}
                </a-descriptions-item>

                <a-descriptions-item label="总户号">
                    {{ record.TotalHu }}
                </a-descriptions-item>

                <a-descriptions-item label="参数" :span="2">
                    应急电源：{{ record.EmergencyPower }}
                    <br/>
                    建设/改造时间：{{ record.ConstructDate }}
                    <br/>
                    概算投资万元：{{ record.EstimateInvestment }}
                    <br/>
                    暂定红线面积_平方米：{{ record.RedLineArea }}
                    <br/>
                    站闸底板标高_吴淞高程：{{ record.BHeight }}
                    <br/>
                    中心坐标x：{{ record.Coordinates_X }}
                    <br/>
                    中线坐标Y：{{ record.Coordinates_Y }}
                    <br/>
                    线路：{{ record.PowerLine }}
                    <br/>
                    变压器KVA：{{ record.KVA }}
                    <br/>
                    孔数：{{ record.ApertureNumber }}
                    <br/>
                    孔径：{{ record.ApertureSize }}
                    <br/>
                    门顶高：{{ record.GateHeight }}
                    <br/>
                    闸门形式：{{ record.SluiceType }}
                    <br/>
                    水泵型号：{{ record.PumpType }}
                    <br/>
                    水泵台数：{{ record.ECount }}
                    <br/>
                    水泵口径：{{ record.PumpCaliber }}
                    <br/>
                    电动机KW/台：{{ record.ElectricMotor }}
                    <br/>
                    排涝流量m3/s：{{ record.PLLL }}
                    <br/>
                    引水流量m3/s：{{ record.YSLL }}
                </a-descriptions-item>

                <a-descriptions-item label="鉴定" :span="2">
                    安全鉴定：{{ record.SafetyAssessment }}
                    <br/>
                    鉴定时间：{{ record.AssessmentTime }}
                    <br/>
                    鉴定结果：{{ record.AssessmentResult }}
                </a-descriptions-item>

                <a-descriptions-item label="备注" :span="2">
                    {{ record.备注 }}
                </a-descriptions-item>
                
                <a-descriptions-item label="处理备注" :span="2">
                    {{ record.处理备注 }}
                </a-descriptions-item>

                <a-descriptions-item label="对接备注" :span="2">
                    {{ record.djbz }}
                </a-descriptions-item>
                        
            </a-descriptions>

        </a-drawer>

    </div>
</template>

<script>
    // 表格行头字段
    const columns = Object.freeze([
        {
            title: '序号',
            dataIndex: 'rnn',
            width: 80,
            align: 'center'
        },
        {
            title: '类别',
            dataIndex: 'Type',
            width: 80
        },
        {
            title: '闸泵站名称',
            dataIndex: 'Name',
            width: 150,
            ellipsis: true,
            scopedSlots: { customRender: 'name' }
        },
        {
            title: '监管单位',
            dataIndex: 'JGCompany',
            ellipsis: true
        },
        {
            title: '村社区',
            dataIndex: 'Village',
            width: 120,
            align: 'center',
            ellipsis: true
        },
        {
            title: '乡镇',
            dataIndex: 'Town',
            width: 120,
            align: 'center',
            ellipsis: true
        },
        {
            title: '圩区',
            dataIndex: 'Polder',
            width: 120,
            align: 'center',
            ellipsis: true
        },
        {
            title: '河道',
            dataIndex: 'Location',
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: '备注',
            ellipsis: true
        },
        {
            title: '详情',
            key: 'detail',
            fixed: 'right',
            width: 80,
            scopedSlots: { customRender: 'action' },
        }
    ])

    export default {
        data () {
            return {
                // form
                form: this.$form.createForm(this, { name: 'formSearch' }),
                // table
                dataSource: [],
                columns,
                scroll: { 
                    x: '125%', 
                    y: 0,
                    scrollToFirstRowOnChange: true
                },
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                    showTotal: total => `总共${total}条`
                },
                loading: true,
                // drawer
                record: {},
                showDrawer: false
            }
        },
        computed: {
            town () {
                const { town, role } = this.$User.user.userInfo
                console.log(this.$User.user.userInfo);
                return role > 810 ? '' : town
            }
        },
        methods: {
            operForm (action) {
                const { isFieldsTouched,  resetFields} = this.form
                if (!isFieldsTouched()) return
                
                if (action === 'reset') {
                    resetFields()
                }
                this.pagination.current = 1
                this.getTableData()
            },
            calcScrollY () {
                this.scroll.y = document.body.offsetHeight - 342
            },
            checkRecord (record) {
                this.record = record
                this.showDrawer = true
            },
            changePage (page) {
                const { current } = page
                this.pagination.current = current
                this.getTableData()
            },
            getTableData () {
                this.loading = true
                const { current, pageSize } = this.pagination
                const { getFieldsValue } = this.form

                this.$axios({
                    url: '/api/GetList/get_v_base_zzxx_gr_List',
                    method: 'post',
                    data: {
                        pageIndex: current,
                        pageSize,
                        where: {
                            ...getFieldsValue(),
                            Town: this.town
                        }
                    }
                })
                    .then(res => {
                        if (res.status !== 200 || res.data.code !== 0) return 

                        const { list, total } = res.data.data
                        this.dataSource = list
                        this.pagination.total = total

                    })
                    .catch(err => err)
                    .finally(() => this.loading = false)
            }
        },

        created () {
            this.getTableData()
        },
        mounted () {
            this.calcScrollY()
            window.addEventListener('resize', this._.debounce(this.calcScrollY, 150))
            this.$once('hook:beforeDestroy', () => window.removeEventListener('resize', this.calcScrollY))
        },
    }
</script>
