<template>
    <div style="width:100%; height:100%;" ref="rootBox">
        <div id="MyMap" style="width:100%; height:100%;"></div>

        <div class="div_css_watersturation">
            <div class="div_header_watersturation">
                <span>水情相关信息</span>

                <a-select style="width:100px !important;float:right;margin-top:10px;margin-right:20px;" v-model="model_search" @change="change_search">
                    <a-select-option 
                        v-for="item in selData"
                        :key="item.id" 
                        :value="item.value">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </div>
            <div class="search-result-list" :style="{ marginTop: '0px' }">
                <a-table 
                        :columns="columns"
                        :data-source="tableData"
                        :pagination="pagination"
                        :pageSize="10"
                        :total="50"
                        rowKey="id"
                        :loading="loading"
                        :scroll="scroll"
                        @change="pageCurrentChange"
                        :customRow="rowClick">
                    <span slot="action" slot-scope="text, record">
                        <a-button type='primary' size="small" @click.stop="btnClick(record)">详情</a-button>
                    </span>
                    <a slot="lastTime" slot-scope="text" style="color:rgba(0, 0, 0, 0.65)">{{$jsc.formatDate_yMhm(text)}}</a>
                    <a slot="warningData" slot-scope="text">{{m_toFixed(text)}}</a>
                    <a slot="currentData" slot-scope="text">{{m_toFixed(text)}}</a>
                </a-table>
            </div>
        </div>

        <!-- 水位计详情 -->
        <a-modal 
            v-model="visible" 
            title="水情监测点" 
            :closable="false" 
            :footer="null"
            :width="900">

                <a-row v-if="detailsData.length" :gutter="[8, 16]">

                    <a-col :span="8" style="fontSize: 18px;">
                        <span style="color:dodgerblue">时间：</span>{{detailsData[0].lastTime}}
                    </a-col>
                    <a-col :span="8" style="fontSize: 18px;">
                        <span style="color:dodgerblue">警戒水位：</span>{{m_toFixed(detailsData[0].warningData)}}
                    </a-col>
                    <a-col :span="8" style="fontSize: 18px;">
                        <span style="color:dodgerblue">实时水位：</span>{{m_toFixed(detailsData[0].currentData)}}
                    </a-col>
                    <a-col :span="8" style="fontSize: 18px;">
                        <span style="color:dodgerblue">站点：</span>{{detailsData[0].address}}
                    </a-col>
                    <a-col :span="8" style="fontSize: 18px;" v-if="detailsData[0].groupCode==='ksslj'">
                        <span style="color:dodgerblue">来源：</span>{{detailsData[0].region}}
                    </a-col>

                </a-row>
            <div style="width:100%;height:400px;margin-top:20px;" ref="sqjcd"></div>
        </a-modal>

        <!-- 点击地图水位计展示数据 -->
        <a-modal 
            v-model="showWlg"  
            :footer="null"
            :closable="false"
            centered
            @cancel="showWlgData = {}">
            <p slot="title">
                <a-icon type="flag" :style="{ color: '#1E90FF' }" />
                {{ showWlgData ? showWlgData.address : '未查询到监测点信息' }}
            </p>
            <p>时间：{{ showWlgData ? showWlgData.lastTime : '未查询到监测点信息' }}</p>
            <p>水位（米）：{{ showWlgData ? m_toFixed(showWlgData.currentData) : '未查询到监测点信息' }}</p>
            <p>区域：{{ showWlgData ? showWlgData.region : '未查询到监测点信息' }}</p>
            <p v-if="showWlgData && showWlgData.groupCode === 'ksslj'">河道：{{ showWlgData ? showWlgData.river : '未查询到监测点信息' }}</p>
            <p v-else>圩区：{{ showWlgData ? showWlgData.polder : '未查询到监测点信息' }}</p>
            <p v-if="showWlgData && showWlgData.groupCode === 'ksslj'">来源：市防办</p>
        </a-modal>

        <div class="popContainer" v-if="loading_zz==true">
            <a-spin tip="Loading...">
                <div class="spin-content">
                </div>
            </a-spin>
        </div>
    </div>
</template>
<script>
    import * as esriLoader from 'esri-loader'
    import * as echarts from 'echarts'

    const columns = [
        {
            title: '站点',
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
            scopedSlots: { customRender: 'address' }
        },
        {
            title: '警戒水位',
            dataIndex: 'warningData',
            width: 90,
            key: 'warningData',
            scopedSlots: { customRender: 'warningData' }
        },
        {
            title: '实时水位',
            dataIndex: 'currentData',
            width: 90,
            key: 'currentData',
            scopedSlots: { customRender: 'currentData' }
        },
        {
            title: '更新时间',
            dataIndex: 'lastTime',
            key: 'lastTime',
            width: 120,
            scopedSlots: { customRender: 'lastTime' }
        },
        {
            title: '操作',
            key: 'action',
            width: 80,
            scopedSlots: { customRender: 'action' }
        }
    ]

    esriLoader.loadScript({
        url: 'http://118.31.71.54:8090/library/4.15/init.js',
        dojoConfig: {
            async: true,
            packages: [
                {
                    name: "app",
                    location: "http://118.31.71.54:8044/v0/app"
                }
            ]
        }
    })
    esriLoader.loadCss('http://118.31.71.54:8090/library/4.15/esri/themes/light/main.css')
    esriLoader.loadCss('http://118.31.71.54:8044/v0/GRSHGISLibs.css')

    let loadModulesUrl = [
        'http://118.31.71.54:8091/KSSmartWaterOneMapAPI/v1.5/app/MapInitial.js',
        'http://118.31.71.54:8091/KSSmartWaterOneMapAPI/v1.5/app/KSSmartWater/ksSWInitial.js',
        'http://118.31.71.54:8091/KSSmartWaterOneMapAPI/v1.5/app/test/pntlyrext2.js',
        'http://118.31.71.54:8090/library/4.15/esri/renderers/ClassBreaksRenderer.js',
        'http://118.31.71.54:8090/library/4.15/esri/symbols/PictureMarkerSymbol.js'
    ]

    export default {
        data() {
            return {
                scroll: {
                    y: 0
                },
                tableData: [],
                visible: false,
                detailsData: [],
                allData:[],
                selData: [],
                selectedRowKeys: '',
                selectedRows: [],
                mi: null,
                model_search:'ksslj',
                columns,
                loading: true,
                strWhere: 'del=0',
                loading_zz: false,
                pagination: {
                    total: 0,
                    pageSize: 10, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    size: 'small',
                    showTotal: total => `共有 ${total} 条数据` // 分页中显示总的数据
                },
                showWlg: false,
                showWlgData: {}
            }
        },
        created () {
            this.bind_selData()
            this.bindList(this.model_search)
        },
        mounted () {
            this.findMap()
            window.onresize = this.onResize()
        },
        methods: {
            findMap () {
                esriLoader.loadModules(loadModulesUrl)
                    .then(([MI, ksSWInitial, pntlyrext2, ClassBreaksRenderer, PictureMarkerSymbol]) => {
                        let mi = new MI({
                            zoom: 9,
                            center_lng: 121.333333,
                            center_lat: 31.288888,
                            container: "MyMap",
                            maptype: 1,
                            OMKey: "11"
                        })
                        this.mi = mi

                        let xcw = new ksSWInitial({ mi })
                        xcw.initial();  //智慧水利相关功能初始化
                        xcw.setAllBaseSubLayerVisibility(false) //关闭所有水系基础图层
                        xcw.setAllDBSubLayerVisibility(false) //关闭所有业务图层
                        xcw.setBaseWaterSubLayerVisibility('River', true) //打开河道图层，配置为false，则关闭河道图层，第一个参数为指代图层的target参数
                        xcw.setBaseWaterSubLayerVisibility('Lake', true) //打开湖泊图层
                        xcw.setBaseWaterSubLayerVisibility('TownBoundary', true) //打开乡镇界线图层
                        xcw.setBaseWaterSubLayerVisibility('Mask', true) //打开掩膜图层
                    
                        //配置分级符号
                        let sms1 = new PictureMarkerSymbol({
                            url: '/img/marker2.png',
                            width: '30px',
                            height: '30px'
                        })

                        let sms2 = new PictureMarkerSymbol({
                            url: '/img/marker7.png',
                            width: '30px',
                            height: '30px'
                        })

                        //配置分级渲染器
                        let renderer = new ClassBreaksRenderer({
                            field: 'lv' //此处为分级字段
                        })
                        renderer.addClassBreakInfo({
                            minValue: 1,
                            maxValue: 1,
                            symbol: sms1,
                            label: '级别1'
                        })
                        renderer.addClassBreakInfo({
                            minValue: 2,
                            maxValue: 2,
                            symbol: sms2,
                            label: '级别2'
                        })

                        this.$axios({
                            url: 'http://58.210.32.10:8081/api-polder/getPoints',
                            method: 'post',
                        }).then(res => {
                            let data = res.data.data.filter(item => {
                                return item.groupCode == 'ksslj'
                            })
                            data.forEach(d => {
                                d.lv = d.currentData >= d.warningData ? 2 : 1
                            })

                            let lyr = new pntlyrext2({
                                mi, //传递地图初始化对象
                                data, //数据 该项与dataUrl二选一.此处直接传递数组
                                renderer,  //地图渲染器 非专业用户不需要使用该属性
                                x_Field: 'lng', //用于展点的X字段
                                y_Field: 'lat', //用于展点的y字段
                                enableCluster: true, //是否开启聚合功能
                            })

                            lyr.initialClickOrMove({
                                view_click_handler: (g) => {
                                    this.showWlgData = g.attributes
                                    this.showWlg = true
                                }
                            })
                        })
                    })
            },
            btnClick(item) {
                this.data_details(item.address);
            },
            rowClick(record) {
                return {
                    on: {
                        click: () => {
                            this.selectedRowKeys = [record.roleid]
                            this.selectedRows = [record]
                            this.mi.zoomto(record.lng, record.lat, 15);
                        }
                    }
                }
            },
            change_search(value) {
                this.pagination.current = 1
                this.bindList(value);
            },
            data_details(zdmc) {
                var _this = this;
                _this.visible = true;
                _this.detailsData = [];
                _this.loading_zz = true;//开启遮罩
                //点击地图点位，匹配所有数据，显示详情
                for (var i = 0; i < _this.allData.length; i++) {
                    if (_this.allData[i].address == zdmc) {
                        _this.detailsData.push(_this.allData[i]);
                        break;
                    }
                }
                //详情中echarts数据
                setTimeout(() => {
                    if (_this.detailsData.length == 0) {
                        _this.$message.error("未检测到对应的监测点");
                        _this.bind_eacharts([], [], [], _this.$refs.sqjcd);
                        _this.loading_zz = false; 
                        } else {
                        var myChart1 = echarts.init(_this.$refs.sqjcd);
                        myChart1.showLoading();
                        _this.$axios({
                            url: 'http://58.210.32.10:8081/api-polder/getHisData',
                            method: 'post',
                            data: { "endTime": "", "pointId": _this.detailsData[0].id, "startTime": "" }
                        })
                            .then(function (response) {
                                console.log(response)
                                if (response.status === 200) {
                                    var data = response.data.data;
                                    var xArr = [], warnArr = [], currArr = [];
                                    if (data.length > 0) {
                                        var i_number = 0;
                                        if (data.length >= 12) i_number = data.length - 12;
                                        for (var i = i_number; i < data.length; i++) {
                                            xArr.push(_this.$jsc.formatDate_isTime(data[i].monitorTime));
                                            warnArr.push(_this.m_toFixed_number(data[i].warningData));
                                            currArr.push(_this.m_toFixed_number(data[i].data));
                                        }
                                        _this.bind_eacharts(xArr, warnArr, currArr, _this.$refs.sqjcd);
                                    } else {
                                        _this.$message.info("暂无历史数据");
                                        _this.bind_eacharts([], [], [], _this.$refs.sqjcd);
                                        _this.loading_zz = false;//关闭遮罩
                                        myChart1.hideLoading();
                                    }
                                }
                            })
                            .catch(function (error) {
                                console.log(error)
                            })
                    }
                })
            },
            bind_selData() {
                var _that = this
                this.$axios({
                    url: 'http://58.210.32.10:8002/api/hdsq/GetSlzqzxx',
                    method: 'get',
                })
                    .then(function (response) {
                        if (response.status === 200) {
                            _that.selData = response.data.data;//搜索下拉框数据
                            //获取所有点位数据
                            for (var i = 0; i < _that.selData.length; i++) {
                                _that.$axios({
                                    url: 'http://58.210.32.10:8081/api-polder/getPoints?regionCode=' + _that.selData[i].value,
                                    method: 'post',
                                })
                                    .then(function (response) {
                                        if (response.status === 200) {
                                            for (var i = 0; i < response.data.data.length; i++) {
                                                _that.allData.push(response.data.data[i])
                                            }
                                        }
                                    })
                                    .catch(function (error) {
                                        console.log(error)
                                    })
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            bindList(value) {
                var _that = this
                _that.loading = true
                this.$axios({
                    url: 'http://58.210.32.10:8081/api-polder/getPoints?regionCode=' + value,
                    method: 'post',
                })
                    .then(function (response) {
                        _that.loading = false
                        if (response.status === 200) {
                            console.log('table数据--------' + response.data.data)
                            _that.tableData = response.data.data
                            _that.pagination.total = response.data.data.length
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            bind_eacharts(xArr,warnArr,currArr,dom) {
                var _this = this;
                var myChart1 = echarts.init(dom);
                var option = {
                    color: ['#09bcb7', '#ff999a'],
                    grid: {
                        top: '20px',
                        left: '20px',
                        right: '20px',
                        bottom: '20px',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            label: {
                                backgroundColor: '#eee'
                            }
                        },
                    },
                    legend: {
                        show: true,
                        icon: 'circle',
                        right: 140,
                        itemWidth: 8,
                        itemHeight: 8,
                        textStyle: {
                            color: '#555'
                        },
                        data: ['实时水位','警戒水位']
                    },
                    calculable: true,
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#eee'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#555'
                            },
                            interval: 0,
                            rotate: 40
                        },
                        data: xArr
                    },
                    yAxis: {
                        type: 'value',
                        axistick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#eee'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#555'
                            },
                            formatter: function (value) {
                                var texts = [];
                                value = value + " m";
                                texts.push(value);
                                return texts;
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                type: 'dashed',
                                color: ['#999']
                            }
                        }
                    },
                    series: [{
                        type: 'line',
                        name: '实时水位',
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1, [{
                                    offset: 0,
                                    color: 'rgba(90, 163, 216, 1)'
                                },
                                {
                                    offset: 0.8,
                                    color: 'rgba(255, 255, 255, 1)'
                                }
                            ], false
                            ),
                            shadowColor: 'rgba(0,0,0,0.01)',
                            shadowBlur: 10

                        },
                        smooth: true,
                        lineStyle: {
                            color: 'dodgerblue'
                        },
                        data: currArr
                    }, {
                        type: 'line',
                        name: '警戒水位',
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1, [{
                                    offset: 0,
                                    color: 'rgba(255,153,154,0.7)'
                                },
                                {
                                    offset: 0.8,
                                    color: 'rgba(255,153,154,0.01)'
                                }
                            ], false
                            ),
                            shadowColor: 'rgba(0,0,0,0.01)',
                            shadowBlur: 10

                        },
                        smooth: true,
                        lineStyle: {
                            color: '#ff999a'
                        },
                        data: warnArr
                    }]
                };
                myChart1.setOption(option);
                _this.loading_zz = false;//关闭遮罩
                myChart1.hideLoading();
            },
            pageCurrentChange(pagination) {
                this.pagination.current = pagination.current
                this.bindList(this.model_search)
            },
            formatDate_hm (value) {
                let date = new Date(value);
                if (value == "0001-01-01 00:00:00" || value == null || value == "1900-01-01 00:00:00" || value == "") {
                    return "";
                } else {
                    let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
                    let mi = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
                    return h + ":" + mi;
                }
            },
            m_toFixed(value) {
                if (value != null && value != "Null" && value != undefined) {
                    return (parseInt(value) / 1000).toFixed(2) + " m";
                }else {
                    return "";
                }
            },
            m_toFixed_number(value) {
                if (value != null && value != "Null" && value != undefined) {
                    return parseFloat((parseInt(value) / 1000).toFixed(2));
                } else {
                    return "";
                }
            },
            onResize () {
                this.scroll.y = this.$refs.rootBox.offsetHeight - 280
                return this.onResize
            }
        }
    }
</script>
<style scoped>
    .ant-layout-content {
        padding: 0px !important;
        margin: 0px !important;
    }
    .div_css_watersturation {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 550px;
        background-color: #fff;
        border-radius: 10px;
    }
    .div_header_watersturation {
        height: 50px !important;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        background-color: dodgerblue !important;
    }
    .div_header_watersturation span{
        color:#fff !important;
        font-size:18px !important;
        line-height: 50px !important;
        margin-left: 10px !important;
    }
    .search-result-list >>> .ant-table-pagination.ant-pagination {
        margin-right: 16px;
    }
    .table_css_watersturation {
        width: 100%;
        height: 700px;
        text-align: center;
        font-size:16px;
    }
    .table_css_watersturation tr:nth-child(1){
        background-color:#D2E9FF;
        color: dodgerblue;
    }
    .table_css_watersturation tr:not(:nth-child(1)) td:nth-child(1){
        font-weight:bold;
    }
    .font_blue_watersturation {
        color: dodgerblue;
    }

    /*详情页面begin*/
    .div_details_watersturation {
        position: absolute;
        top: 300px;
        left: 700px;
        width: 400px;
        height: 300px;
        background-color: #fff;
    }
    .ant-modal-content {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    .ant-modal-header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: dodgerblue;
    }
    .ant-modal-title{
        color:#fff;
    }
    .dialog_watersturation div span{
        font-size:18px;
    }
    .dialog_watersturation div span:nth-of-type(2n+1) {
        color:dodgerblue;
    }

    .ant-table-pagination {
        margin-right: 20px !important;
    }
    /*遮罩*/
    .popContainer {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        background: rgba(0,0,0,0.3);
    }

    .spin-content {
        border: 1px solid #91d5ff;
        background-color: #e6f7ff;
        padding: 30px;
    }
    /*end*/
</style>
