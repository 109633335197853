<template>
    <a-layout class="wh-100p layout">
        <a-layout-header class="layout_header">
        
            <div class="layout_header_logo_box">
                <a-space>
                    <img src="/img/logo1.png" alt="logo">
                    <h2>昆山市防汛决策指挥管理平台</h2>
                </a-space>
            </div>
            <div class="layout_header_meun_box">
                <span>欢迎您！[{{ userName }}]</span>
                <ul>
                    <li v-for="m in menus" :key="m.key" @click="onClickMenu(m.key)">
                        <svg-icon 
                            :name="m.icon" 
                            class="icon" 
                            color="#fff"
                            width="24"
                            height="24" />
                        <span>{{ m.title }}</span>
                    </li>
                </ul>
            </div>
        </a-layout-header>

        <a-layout-content class="layout_content">
            <ul class="card_box">
                <li 
                    class="card"
                    v-for="card in cards" 
                    :key="card.text"
                    @click="onClickCard(card)"
                    :style="{ 
                        background: `${setGradient(card)}`,
                    }"
                >
                    <img class="pic" :src="card.imgSrc" />
                    <p class="text">{{ card.text }}</p>
                </li>
            </ul>
        </a-layout-content>

        <a-modal
            :visible="showModal"
            :width="1300"
            :closable="false"
            :footer="null"
            @cancel="showModal = false"
            @ok="showModal = false">
            <txlList></txlList>
        </a-modal>

    </a-layout>
</template>

<script>
    import txlList from '@/components/txlList.vue'
    import { pathFindRouter } from '../router.js'

    const colorList = [
        {
            start: '#80F3A2',
            end: '#34D1D2'
        },
        {
            start: '#FFEDAB',
            end: '#F76B1C'
        },
        {
            start: '#FFB5B5',
            end: '#9A00FF'
        },
        {
            start: '#F8FFB7',
            end: '#FFAE00'
        },
        {
            start: '#74F5FD',
            end: '#4885FF'
        },
        {
            start: '#F2C1FF',
            end: '#1F3DFF'
        },
        {
            start: '#FFDAB9',
            end: '#FF6E6B'
        }
    ]

    const MENUS = [
        {
            key: 0,
            icon: 'navigation',
            title: '通讯录'
        },
        {
            key: 1,
            icon: 'video',
            title: '视频中心'
        },
        {
            key: 2,
            icon: 'maintain',
            title: '主界面'
        },
        {
            key: 3,
            icon: 'exit',
            title: '退出系统'
        }
    ]

    export default {
        components: { txlList },
        data () {
            return {
                cards: [],
                menus: Object.freeze(MENUS),
                current: -1,
                showModal: false
            }
        },
        computed: {
            userName () {
                return this.$User.user.userInfo.realName
            }
        },
        methods: {
            setGradient (card) {
                return `linear-gradient(135deg, ${card.start}, ${card.end})`
            },
            onClickMenu(key) {
                switch (key) {
                    case 0:
                        return this.showModal = true
                    case 1:
                        break;
                    case 2:
                        this.$router.push("/desk");
                        break;
                    case 3:
                        return this.exit()

                }
            },
            exit () {
                this.$confirm({
                    title: '消息提示',
                    content: '确定要退出吗？',
                    onOk:() => {
                        this.$User.user.loginOut()
                    }
                })
            },
            onClickCard({ path, isLink }) {
                console.log({ path, isLink });
                if (!path) return this.$message.info('暂未开通功能')
                if (isLink) {
                    window.open(path)
                } else {
                    this.$router.push(path)
                }
            }
        },
        created () {
            let rs = this.$route.path.replace(/\/\w+$/, '')
            this.cards = pathFindRouter(rs).children
                .filter(item => item.isShow)
                .map((item, index) => {
                const { start, end } = colorList[index%6]
                return {
                    key: index,
                    text: item.title,
                    start,
                    end,
                    imgSrc: item.iconLink,
                    path: item.path,
                    isLink: item.path.startsWith('http')
                }
            })
        },
    }
</script>

<style lang="less" scoped>
    .layout {
        overflow: hidden;
        background-image: url("../assets/img/bg_1.png");
        background-size: 100% 100%;

        &_header {
            display: flex;
            justify-content: space-between;
            min-width: 1200px;
            height: 80px;
            background-image: url("../assets/img/top_bg_1.png");
            background-size: 100% 100%;

            &_logo_box {
                display: flex;
                align-items: center;
                height: 80px;

                img {
                    width: 50px;
                    height: 50px;
                }

                h2 {
                    margin-bottom: 0;
                    font-size: 24px;
                    color: #fff;
                }

            }

            &_meun_box {
                display: flex;
                align-items: center;
                height: 80px;
                font-size: 16px;
                color: #fff;

                ul {
                    display: flex;
                    margin-bottom: 0;

                    li {
                        display: flex;
                        align-items: center;
                        margin-left: 25px;
                        cursor: pointer;

                        &:active {
                            opacity: 0.7;
                        }


                        span {
                            margin-left: 8px;
                        }
                    }
                }
            }
        }

        &_content {
            position: relative;
            min-height: 600px;

            .card_box {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: 1080px;
                height: 560px;

                .card {
                    position: relative;
                    margin-right: 90px;
                    border-radius: 16px;
                    width: 200px;
                    height: 240px;
                    text-align: center;;
                    background-color: #fff;
                    transition: box-shadow 0.3s;
                    cursor: pointer;

                    &:nth-child(4n) {
                        margin-right: 0;
                    }

                    .pic {
                        width: 84px;
                        height: 84px;
                        position: absolute;
                        top: 52px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    
                    .text {
                        margin-top: 165px;
                        font-size: 30px;
                        color: #fff;
                    }

                }

            }
            
        }
    }

</style>