<template>
    <div style="width:100%; height:100%;">
        
        <h1 v-if="aaa"> 首页</h1>
        <a  @click="pathTo('/database/db1/db1-1')">子模块1</a>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                aaa: true
            }
        },
        watch: {
            $route: {
                handler: function (val, oldVal) {
                    if (val.path == "/database") {
                        this.aaa = true;
                    }
                },
                // 深度观察监听
                deep: true
            }
        },
        created: function () { },
        methods: {
            pathTo(path) {
                //this.aaa = false;
                this.$router.push(path)
            }
        }
    }
    </script>