<template>
    <a-table 
        :columns="columns" 
        :data-source="dataSource"
        rowKey="proj"
        bordered
        :pagination="false"
    >
    </a-table>
</template>

<script>
    const columns = [
        {
            title: '项目',
            dataIndex: 'proj',
            width: 80,
            align: 'center'
        },
        {
            title: '按季度统计',
            children: [
                {
                    title: '第一季度',
                    dataIndex: 's1',
                    align: 'center'
                },
                {
                    title: '第二季度',
                    dataIndex: 's2',
                    align: 'center'
                },
                {
                    title: '第三季度',
                    dataIndex: 's3',
                    align: 'center'
                },
                {
                    title: '第四季度',
                    dataIndex: 's4',
                    align: 'center'
                }
            ]
        }
    ]
    export default {
        data() {
            return {
                columns,
                dataSource: [
                    {
                        proj: '积水上报',
                        s1: 0,
                        s2: 0,
                        s3: 0,
                        s4: 0,
                    },
                    {
                        proj: '险情上报',
                        s1: 0,
                        s2: 0,
                        s3: 0,
                        s4: 0,
                    }
                ]
            }
        },
    }
</script>

<style lang="less" scoped>

</style>