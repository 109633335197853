<template>
	<a-spin :spinning="formAddOptions1.spinning" tip="雨情传感器设备更新中..">
    <!-- 雨情传感器列表 -->
    <div id="components-form-demo-advanced-search1">
		
        <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
            <a-row :gutter="24">
                <a-col :span="6">
                    <a-form-item label="">
                        <a-input placeholder="名称" v-decorator="[`name`]" />
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <!-- <a-form-item>
                        <a-select v-decorator="['type']" placeholder="请选择闸站类型" :disabled="true" style="width:100%">
                            <a-select-option value="水闸">
                                水闸
                            </a-select-option>
                            <a-select-option value="泵站">
                                泵站
                            </a-select-option>
                            <a-select-option value="站闸">
                                站闸
                            </a-select-option>
                        </a-select>
                    </a-form-item> -->
					<a-form-item label="">
					    <a-input placeholder="所在区镇" v-decorator="[`regionName`]" />
					</a-form-item>
                </a-col>
               
               <!-- <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="所在村社区" v-decorator="['village']"></a-input></a-form-item>
                </a-col> -->
            </a-row>
      
            <a-row :gutter="[24,12]">
                <a-col :span="6" :style="{ textAlign: 'left' }">
					<a-button type='primary'  @click="btnClick('更新全部视频')" style='margin-right: 5px' >更新设备</a-button>
                    <a-button type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button>
                </a-col>
                <a-col :span="18" :style="{ textAlign: 'right' }">
                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                    </a>
                </a-col>
            </a-row>
        </a-form>
        <a-modal v-model="formAddOptions.visible" title="详情" width="1300px">
            <a-spin :spinning="formAddOptions.spinning">
                <a-form :form="formAdd" :label-col="{ span:7 }" :wrapper-col="{ span:17 }" labelAlign="left">
                    <a-form-item label="" style="display:none">
                        <a-input v-decorator="['id']" />
                    </a-form-item>
                    <a-row type="flex" justify="space-between" :gutter="32">
                        <a-col :span="8">
                            <a-form-item label="名称">
                                <a-input v-decorator="['name', { rules: [{ required: false, message: '' }]}]" placeholder="" :disabled="true" :maxLength="20" />
                            </a-form-item>
                        </a-col>
						
						<a-col :span="8">
						    <a-form-item label="区镇名称">
						        <a-input v-decorator="['regionName', { rules: [{ required: false, message: '' }]}]" placeholder="" :disabled="true" :maxLength="20" />
						    </a-form-item>
						</a-col>
						
						<a-col :span="8">
						    <a-form-item label="横坐标">
						        <a-input v-decorator="['coorX', { rules: [{ required: false, message: '' }]}]" placeholder="" :disabled="true" :maxLength="20" />
						    </a-form-item>
						</a-col>
                      
                    </a-row>
                    <a-row type="flex" justify="space-between" :gutter="32">
                        <a-col :span="8">
                            <a-form-item label="纵坐标">
                                <a-input v-decorator="['coorY', { rules: [{ required: false, message: '' }]}]" placeholder="" :disabled="true" :maxLength="20" style="width:100%"/>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item label="经度">
                                <a-input v-decorator="['lng', { rules: [{ required: false, message: '' }]}]" placeholder="" :disabled="true" :maxLength="20" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item label="纬度">
                                <a-input v-decorator="['lat', { rules: [{ required: false, message: '' }]}]" placeholder="" :disabled="true" :maxLength="20" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                  </a-form>
            </a-spin>
            <template slot="footer">
                <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
                <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
            </template>
        </a-modal>
        <a-table :columns="columns"
                 :data-source="tableData"
                 :pagination="pagination"
                 :pageSize="20"
                 :total="50"
                 rowKey="id"
                 :loading="loading"
                 :scroll="scroll"
                 @change="pageCurrentChange"
                 position="bottom"
                 tableLayout="fixed">
            <span slot="xuhao" slot-scope="text, record">{{record.rownum}}</span>
            <a slot="name" slot-scope="text,record" @click="openDlg(record,'view')">{{ text }}</a>
           <!-- <span slot="Alive" slot-scope="text, record">{{record.Alive=="1"?"在线":"离线"}}</span> -->
			<!-- <span slot="sfzc" slot-scope="text, record">
			    <a-tag v-if="text==0" color="green">正常</a-tag>
			    <a-tag v-if="text==1" color="red">异常</a-tag>
			    
			</span> -->
           <!-- <span slot="CameraType" slot-scope="text, record">{{get_camera_type_text(record.CameraType)}}</span> -->
            <span slot="action" slot-scope="text, record">
				<a-button type='primary' size="small" key="派单" @click="btnClick('派单',record)" style='margin-right:5px;display:inline'>
				  
				    派单
				</a-button>
                <!-- <a-button type="primary" size="small" style='margin:0 5px' @click='lookproject(record)'>查看</a-button> -->
                <a-button :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right: 5px'><a-icon :type="button.iconLink" /></a-button>
            </span>
        </a-table>
    </div>
     </a-spin>
</template>
<script>

    const strWhere = 'del=0'
    export default {
        data() {
            return {
                scroll: {
                    // x: 3000,
                    // y: 900
                },
                showexport: false,
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                tableData: [],
                columns: [
                    {
                        title: '序号',
                        fixed: 'left',
                        width: 70,
                        scopedSlots: { customRender: 'xuhao' }
                    },
					
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                        width: 150,
                       
                        scopedSlots: { customRender: 'name' }
                    },
				
					{
					    title: '所在区镇',
					    dataIndex: 'regionName',
					    key: 'regionName',
						 width: 120,
					},
					{
					    title: '经度',
					    dataIndex: 'lng',
					    key: 'lng',
						 width: 120,
					},
                    {
                        title: '纬度',
                        dataIndex: 'lat',
                        key: 'lat',
						 width: 120,
                    },
                    {
                        title: '操作',
                        key: 'action',
                        fixed: 'right',
                        width: 180,
                        scopedSlots: { customRender: 'action' }
                    }
                ],
                loading: true,
                action: 'add',
                previewVisible: false,
                previewImage: '',
                fileList: [],
                strWhere,
                expand: false,
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false,
                    townEdit: false
                },
				formAddOptions1: {
				   
				    spinning: false
				  
				},
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: 'advanced_search2' }),
                formAdd: this.$form.createForm(this, { name: 'advanced_search3' }),
                pagination: {
                    total: 0,
                    pageSize: 15, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
                    showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: 'bottom'
                },
                xzlist: [],
				sfzc:''
            }
        },
        created() {

            // 模拟后台请求
            // this.bindList();
            // this.InitGrid()
            this.getTown()
        },
        mounted() {
		
			this.sfzc = this.$route.query.sfzc;
			
			
            for (let i = 0; i < this.$route.meta.buttons.length; i++) {

                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                }
                else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.init()
            this.actions()
            window.onresize = () => {
                this.init()
            }
            this.bindList();
        },
		watch: {
		    '$route.query.sfzc'(newVal, oldVal) {
		        console.log(newVal);
		        this.sfzc = newVal;
		        this.bindList();
		    }
		},
        methods: {
            getTown() {
                this.$axios({
                    url: '/api/zzgl/GetTown',
                    method: 'get'
                }).then(res => {
                    if (res.data.code == 0) {
                        this.xzlist = res.data.data
                    }
                })
            },
			get_camera_type_text(value){
				
				
				var _type_text="";
				if(value=="5"||value=="0")
				{
					_type_text="未知";
				}else if(value=="6")
				{
					_type_text="球机";
				}else if(value=="7")
				{
					_type_text="半球";
				}
				else if(value=="8")
				{
					_type_text="固枪";
				}
				else if(value=="9")
				{
					_type_text="遥控枪机";
				}
				return _type_text;
			},
            lookproject(item) {
                this.$router.push("/gcyxgl/hdshsy/xmgl-list-parent?townId="+item.townId+"&zzbh=" + item.bh)
            },
            btnClick(text, item) {
                console.log(text)
                let _this = this;
                switch (text) {
                    case '新增':
                        this.openDlg()
                        break;
					case '派单':
						    this.gopd(item)
						    break
                    case "编辑":
                        this.openDlg(item);
                        break;
					case "更新全部视频":
						this.updatecameras();
						    break;
                    case "删除":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定要删除 " + item.NickName + " 吗?",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.del(item);
                            }
                        });
                        break;
                }
            },
            bindList: async function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1
                    // console.log(values)
                    // this.strWhere = "del=0 and source='站闸'"
					 this.strWhere = "del=0 "
					// if (this.sfzc&&this.sfzc!='') { this.strWhere = 'del=0 and sfzc='+this.sfzc; }
					
                    for (var obj in values) {
                        if (values[obj]) {
                            this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
                        }
                    }
                    console.log(this.strWhere)
                    this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
                })
                this.loading = true
                this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere);
                this.strWhere = this.strWhere.data;
                await this.InitGrid()
            },
			gopd(value) {
			    //this.$router.push("pd?xczzbh=" + value.xczzbh+'&probh='+value.bh)
				  this.$router.push("camera-pd?xczzbh="+value.id+'&typeinfo=1&NickName='+value.name+'雨情传感器')
			},
            InitGrid: function () {
                var _that = this
                _that.loading = true
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'wlw_yq',
                        page: _that.pagination.current,
                        limit: _that.pagination.pageSize,
                        filesFields: 'bh',
                        strWhere: _that.strWhere
                    }
                })
                    .then(function (response) {
                        console.log(response)
                        _that.loading = false
                        if (response.status === 200) {
                            _that.tableData = response.data.data
                            _that.pagination.total = response.data.count
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            formSearchSubmit(e) {
                e.preventDefault()
                this.pagination.current = 1
                this.bindList()
            },
            pageCurrentChange(pagination) {
                this.pagination.current = pagination.current
                this.bindList()
            },
            formSearchReset() {
                this.formSearch.resetFields()
                this.strWhere = 'del=0'
                this.bindList()
            },
            toggle() {
                this.expand = !this.expand
                this.init()
            },
			updatecameras(){
				 this.formAddOptions1.spinning = true;
				 this.$axios
				     .get('/api/Cameras/UpdateYq')
				     .then((response) => {
				         if (response.status == 200) {
				             if (response.data.code == 0) {
				                 // this.formAddOptions.visible = true;
				                 var result = response.data.data;
				                 console.log(result[0]);
				                 setTimeout(() => {
				                     // result[0].yyqk = true
				                     // result[0].emergencyPower = result[0].emergencyPower == "true" ? true : false
				                     // result[0].safetyAssessment = result[0].safetyAssessment == "true" ? true : false
				                    
				                     // this.formAdd.setFieldsValue(result[0])
				                     //this.fileList = result[0].files_bh
				                     // this.formAddOptions.townEdit = true;
				                     this.formAddOptions1.spinning = false;
									  this.$message.info('更新成功');
									   this.bindList();
				                 }, 1000);
				             }
				             else {
				                 this.$message.error(response.data.msg);
				                 this.formAddOptions1.spinning = false;
				             }
				         } else {
				             // vue.prototype.$message.info('This is an error message');
							 this.formAddOptions1.spinning = false;
				         }
				     })
				     .catch(function (error) { // 请求失败处理
				         console.log(error)
				     })
			},
            openDlg: async function (record, action) {
                this.formAdd.resetFields()
                this.fileList = []
                this.formAddOptions.submitVisible = true
                this.formAddOptions.viewMode = false
                if (record !== undefined) {
                    // 从后台取数据赋值
                    this.formAddOptions.spinning = true
                    var obj = await this.$jsc.DesEncrypt(this, 'id=zhangbiao' + record.id + 'zhangbiao');
                    obj = obj.data;
                    this.$axios
                        .get('/api/GetList/getListByTable?tableName=wlw_yq&strWhere=' + obj + '&filesFields=bh')
                        .then((response) => {
                            if (response.status == 200) {
                                if (response.data.code == 0) {
                                    this.formAddOptions.visible = true;
                                    var result = response.data.data;
                                    console.log(result[0]);
                                    setTimeout(() => {
                                        // result[0].yyqk = true
                                        // result[0].emergencyPower = result[0].emergencyPower == "true" ? true : false
                                        // result[0].safetyAssessment = result[0].safetyAssessment == "true" ? true : false
                                       
                                        this.formAdd.setFieldsValue(result[0])
                                        //this.fileList = result[0].files_bh
                                        this.formAddOptions.townEdit = true;
                                        this.formAddOptions.spinning = false
                                    }, 1000);
                                }
                                else {
                                    this.$message.error(response.data.msg);
                                    this.formAddOptions.spinning = false;
                                }
                            } else {
                                // vue.prototype.$message.info('This is an error message');
                            }
                        })
                        .catch(function (error) { // 请求失败处理
                            console.log(error)
                        })
                    if (action === 'view') { // 预览模式
                        this.formAddOptions.submitVisible = false
                        this.formAddOptions.viewMode = true
                    }
                }
                else {
                    this.formAddOptions.visible = true;
                }
            },
            formAddSubmit() {
                this.formAdd.validateFields((err, values) => {
                    if (!err) {
                        // values.files_bh = this.fileList
                        // values.source = "站闸";
                        this.formAddOptions.submitLoading = true
                        this.$axios({
                            url: '/api/Cameras/EditCamera',
                            method: 'post',
                            data: values
                        }).then(res => {
                            console.log(res)
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('保存成功')
                                    this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false
                                    this.bindList()
                                } else {
                                    console.log(res);
                                    throw new Error("服务器超时2");
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })

                    }
                })
            },
            del(record) {
                this.$axios({
                    url: '/api/Comon/deltag',
                    method: 'post',
                    data: {
                        tableName: 'wlw_camera',
                        field: 'id',
                        value: record.id,
                        source: 'web',
                        loginid: ''
                    }
                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info('删除成功');
                            this.bindList();
                        }
                        else {
                            throw new Error("服务器超时");
                        }
                    }
                    else {
                        throw new Error("服务器超时");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                })
            },
            init() {
                var that = this
                size()
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search1>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 270
                        }
                    })
                }
            },
            actions() {
                // if (this.inlineButtons.length == 0) {
                //     this.columns.pop();
                // }
            }
        }
    }
</script>
<style>
</style>