<template>
    <div id="root">
        <a-modal v-model="video.visible" title="视频播放" width="800px" :title="video.title" :footer="null">
            <video autoplay="autoplay" :src="video.src" controls="controls" style="width:100%">
                您的浏览器不支持 video 标签。
            </video>
        </a-modal>
        <work ref="work" :dataOptions="work_Options"></work>
		<bjxx ref="bjxx" :dataOptions="work_Options"></bjxx>
        <a-form :form="formSearch"
                @submit="formSearchSubmit"
                :label-col="{ span: 0 }"
                :wrapper-col="{ span: 24 }">
            <a-row :gutter="24">
                <a-col :span="6">
                    <a-form-item label="">
                        <!--<a-input placeholder="点位" v-decorator="['devName']" />-->
                        <a-form-item label="">
                            <a-select placeholder="点位" v-decorator="['devName']" show-search :filter-option="filterOption" style="width:100%;">
                                <a-select-option value="风车浜老年大学桥南侧">风车浜老年大学桥南侧</a-select-option>
                                <a-select-option value="风车浜亭林新村南侧">风车浜亭林新村南侧</a-select-option>
                                <a-select-option value="致塘河西塘街下塘公侧">致塘河西塘街下塘公侧</a-select-option>
                                <a-select-option value="致塘河奥灶馆北">致塘河奥灶馆北</a-select-option>
                                <a-select-option value="致塘河富春桥">致塘河富春桥</a-select-option>
                                <a-select-option value="望山河东塘街北侧">望山河东塘街北侧</a-select-option>
                                <a-select-option value="望山河环北路北侧">望山河环北路北侧</a-select-option>
                                <a-select-option value="望山河马鞍山路南侧">望山河马鞍山路南侧</a-select-option>
                                <a-select-option value="后街河菜厂">后街河菜厂</a-select-option>
                                <a-select-option value="后街河昆山宾馆">后街河昆山宾馆</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label="">
                        <a-range-picker :placeholder="['发生开始时间','发生结束时间']" v-decorator="['happenTime_qj']" style="width:100%" />
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="">
                        <a-select placeholder="报警类型" v-decorator="['alarmType']">
                            <a-select-option value="4">投垃圾</a-select-option>
                            <a-select-option value="402">漂浮物</a-select-option>
                            <a-select-option value="403">拖把清洗</a-select-option>
                            <a-select-option value="404">捕钓</a-select-option>
                            <a-select-option value="405">侵占岸线</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="[24,12]">
                <a-col :span="6" :style="{ textAlign: 'left' }">
                    <a-button type='primary' @click="()=>$router.push('grid')" style='margin-right: 5px'>网格模式</a-button>
                    <!-- primary/waring/danger/green -->
                </a-col>
                <a-col :span="18" :style="{ textAlign: 'right' }">
                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    <!--<a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                    {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                </a>-->
                </a-col>
            </a-row>
        </a-form>

        <div class="search-result-list" :style="{ marginTop: '0px' }">
            <a-table :columns="columns"
                     :data-source="tableData"
                     :pagination="pagination"
                     :pageSize="10"
                     :total="50"
                     rowKey="id"
                     :loading="loading"
                     :scroll="scroll"
                     @change="pageCurrentChange"
                     >
        
                <span slot="stateTxt" slot-scope="text, record">
                    <a-tag color="pink" v-if="text==0">派单中</a-tag>
                    <a-tag color="purple" v-if="text==1">办理中</a-tag>
                    <a-tag color="orange" v-if="text==10">已办结</a-tag>
                </span>
               <span slot="titleTxt" slot-scope="text, record">
                    <a @click="bjxxView(record)">{{text}}</a>
                </span>
                <span slot="snapshootFormat" slot-scope="text, record">
                    <!--<a :href="$axios.defaults.fileURL+text" @click="play(record)" target="_blank"><img :src="$axios.defaults.fileURL+text" style="width:140px; height:85px;"></a>-->
                    <a @click="play(record)"><img :src="$axios.defaults.fileURL+text" style="width:140px; height:85px;"></a>
                </span>
                <!--<div slot="videoFormat" class="video-format" slot-scope="text, record" @click.stop="play(record)">
                    <a-icon type="play-circle" theme="filled" style="font-size:40px; color:aquamarine;" />
                    <img src="http://pt.zhangpu-ks.cn/upload/ljfl/img/2021/5/25/f16fcdee-bb7b-42ca-995d-56d6ebeb36ab.jpg" style="width:110px; height:75px;">
                    <a @click="play(record)">视频播放</a>
                </div>-->
                <span slot="clsxTxt" slot-scope="text, record">
                    {{moment(text).format("L")}}
                </span>
                <span slot="action" slot-scope="text, record">
					 <a-button v-if="$User.user.userInfo.role==1000" type='primary' size="small" @click="btnClick('报警信息',record)" style='margin-right: 5px'>编辑</a-button>
                    <a-button v-if="record.pdcs==0 && record.bjzt==0 && 1==2" type='primary' size="small" @click="btnClick('新建工单',record)" style='margin-right: 5px'>派单</a-button>
                    <!--<a-button v-if="record.pdcs>0" type='primary' size="small" @click="()=>$router.push('workView')" style='margin-right: 5px'>工单查看</a-button>-->
                    <a-button v-if="record.bjzt==0" type='' size="small" @click="btnClick('解除报警',record)" style='margin-right: 5px'>解除报警</a-button>
                    <a-button v-if="record.state!=10" type='danger' size="small" @click="btnClick('删除',record)" style='margin-right: 5px'><a-icon type="delete" /></a-button>
                </span>
                <span slot="pdcsFormat" slot-scope="text, record">
                    <a-tag color="blue" v-if="text>=1">是</a-tag>
                </span>
                <span slot="pdstateFormat" slot-scope="text, record">
                    <a-tag color="pink" v-if="text==0">派单中</a-tag>
                    <a-tag color="purple" v-if="text==1">办理中</a-tag>
                    <a-tag color="orange" v-if="text==10">已办结</a-tag>
                </span>
                <span slot="bjztFormat" slot-scope="text, record">
                    <a-tag color="red" v-if="text==0">报警中</a-tag>
                    <a-tag color="green" v-if="text==1">解除报警</a-tag>
                </span>
            </a-table>
        </div>

    </div>
</template>
<script>
    import moment from 'moment';
    import work from './work';
	import bjxx from './bjxx';
    export default {
        data() {
            return {
                filterOption(input, option) {
                    return (
                        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                },
                monthFormat: 'YYYY/MM',
                showexport: false,
                scroll: {
                    x: '100%',
                    y: 'max-content'
                },
                loading: false,
                check_ywba: true,
                opration: "",
                buttons: [],
                inlineButtons: [],
                tableData: [],
                columns: [
                    {
                        title: "序号",
                        dataIndex: "rnn",
                        width: 70,
                        key: "rnn",
                    },
                    {
                        title: "图片",
                        dataIndex: "snapshoot",
                        width: 180,
                        key: "snapshoot",
                        scopedSlots: { customRender: "snapshootFormat" },
                    },
                    //{
                    //    title: "视频",
                    //    dataIndex: "video",
                    //    width: 160,
                    //    key: "video",
                    //    scopedSlots: { customRender: "videoFormat" },
                    //},
                    {
                        title: "抓拍点",
                        dataIndex: "devName",
                        width: 140,
                        key: "devName",
                        scopedSlots: { customRender: "titleTxt" },
                    },

                    {
                        title: "发生时间",
                        dataIndex: "happenTime",
                        width: 180,
                        key: "happenTime",
                    },

                    //{
                    //    title: "设备ID",
                    //    dataIndex: "encodeDevIndexCode",
                    //    width: 120,
                    //    key: "encodeDevIndexCode",
                    //},
                    {
                        title: "报警类型",
                        width: 120,
                        customRender: function (item)
                        {
                            if (item.alarmType == "4") {
                                return "投垃圾";
                            }
                            else if (item.alarmType == "402") {
                                return "漂浮物";
                            }
                            else if (item.alarmType == "403") {
                                return "拖把清洗";
                            }
                            else if (item.alarmType == "404") {
                                return "捕钓";
                            }
                            else if (item.alarmType == "405") {
                                return "侵占岸线";
                            }
                        }
                    },
                    {
                        title: "报警状态",
                        dataIndex: "bjzt",
                        width: 120,
                        key: "bjzt",
                        scopedSlots: { customRender: "bjztFormat" },
                    },
                    {
                        title: "是否派单",
                        dataIndex: "pdcs",
                        width: 120,
                        key: "pdcs",
                        scopedSlots: { customRender: 'pdcsFormat' }
                    },
                    {
                        title: "办理状态",
                        dataIndex: "pdstate",
                        width: 120,
                        key: "pdstate",
                        scopedSlots: { customRender: 'pdstateFormat' }
                    },
                    {
                        title: '操作',
                        key: 'action',
                        width: 250,
                        fixed: 'right',
                        scopedSlots: { customRender: 'action' }
                    }
                ],
                TableListSearchModel: {
                    pageIndex: 1,
                    pageSize: 10,
                    fields: "*",
                    where: {
                    }
                },
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false,
                    title: "新增",
                },
                video: {
                    src: "",
                    visible: false,
                    title: "视频播放",
                },
                queryParam: {
                    kind: this.$route.query.kind
                },
                work_Options: {
                    bh: "",
                    id: "",
                    module: "河道视频抓拍",
                    showFile: false,
                },
                expand: false,
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: "advanced_search" }),
                pagination: {
                    total: 0,
                    pageSize: 10, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "50", "100"], // 每页中显示的数据
                    showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: "bottom",
                    showQuickJumper:true,

                },
                backData: {
                    currentPageIndex: 1,
                    searchData: {},
                },
            };
        },
        mounted() {
			
			  console.log('user',this.$User.user.userInfo.role)
            var obj = JSON.parse(localStorage.getItem("backData"));
            this.formSearch.setFieldsValue(obj.searchData);
            this.pagination.current = obj.currentPageIndex;

            this.formSearch.setFieldsValue({ kind: this.queryParam.kind });
            this.bindList();
        },
        watch: {
            '$route.query.kind'(newVal, oldVal) {
                this.formSearch.setFieldsValue({ "kind": newVal });
                this.queryParam.kind = newVal;
                this.bindList();
                this.setCallData();
            }
        },
        components: {
            work,
			bjxx,
        },
        created() {
            //this.TableListSearchModel.where.kind = this.$route.query.kind;
            //将上面的按钮和表格内的按钮分出来

            for (let i = 0; i < this.$route.meta.buttons.length; i++) {
                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                } else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.init();
            this.actions();

            window.onresize = () => {
                this.init();
            };

        },
        methods: {
            setCallData: function () {
                this.backData.currentPageIndex = this.pagination.current;
                this.backData.searchData = this.formSearch.getFieldsValue();
                localStorage.setItem("backData", JSON.stringify(this.backData));

            },
            bjxxView: function (record) {
                this.$router.push("bjxxView?infobh=" + record.bh);
            },
		
            play(item) {
                this.video.visible = true;
                this.video.src = this.$axios.defaults.fileURL + item.video;

            },
            workView: function (record) {
                this.$router.push("workView?workbh=" + record.bh);
            },
            moment,
            bindList: function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1;
                    this.TableListSearchModel.where = {};
                    this.TableListSearchModel.where.createUserLoginid = this.$User.user.userInfo.loginid;
                    this.TableListSearchModel.where.kind = '01';
                    for (var obj in values) {
                        if (values[obj]) {
                            if (obj == "clsx") {
                            } else {
                                this.TableListSearchModel.where[obj] = values[obj];
                            }
                        }
                    }
                });
                this.loading = true;
                this.InitGrid();
            },
            InitGrid: function () {
                this.loading = true;
                this.$axios({
                    url: "/api/hdsp/getList_hdsp_bjxx",
                    method: "post",
                    data: this.TableListSearchModel,

                }).then((res) => {
                    console.log(res);
                    this.loading = false;
                    if (res.status === 200) {
                        this.tableData = res.data.data.list;
                        this.pagination.total = res.data.data.total;
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            btnClick(text, item) {
                let _this = this;
                switch (text) {
                    case "查看":
                        this.views(item);
                        break;
                    case "编辑":
					
                        this.work_Options.bh = item.bh;
                        this.$refs.work.open();
                        break;
					case "报警信息":
					
						this.work_Options.bh = item.bh;
						this.$refs.bjxx.open1();
						break;
                    case "新建工单":
                        this.work_Options.bh = "";
                        this.work_Options.title = "" + item.devName + "的" + item.happenTime + "乱丢垃圾报警工单";
                        this.work_Options.info = "点位：" + item.devName + "，发生时间：" + item.happenTime + "，报警类型：乱丢垃圾。";
                        this.work_Options.infobh = item.bh;
                        this.$refs.work.open();
                        break;
                    case "导出":
                        this.$confirm({
                            title: '操作确认',
                            content: "是否导出?",
                            okText: '是',
                            cancelText: '否',
                            onOk() {
                                _this.dc();
                            }
                        });
                        break;
                    case "删除":
                        this.$confirm({
                            title: '操作确认',
                            content: "是否删除《" + item.devName + "》吗?",
                            okText: '是',
                            cancelText: '否',
                            onOk() {
                                _this.del(item);
                            }
                        });
                        break;
                    case "解除报警":
                        this.$confirm({
                            title: '操作确认',
                            content: "是否要解除《"+item.devName+"》的报警吗?",
                            okText: '是',
                            cancelText: '否',
                            onOk() {
                                _this.jcbj(item);
                            }
                        });
                        break;
                }
            },
            formAddSubmit() {
                this.$refs.work.formAddSubmit();
                this.formAddOptions.visible = false;
            },
            formSearchSubmit(e) {
                console.log(e);
                e.preventDefault();
                this.pagination.current = 1;
                this.TableListSearchModel.pageIndex = 1;
                this.bindList();
                this.setCallData();
            },
            formSearchReset() {
                this.formSearch.resetFields();
                this.TableListSearchModel.pageIndex = 1;
                this.pagination.current = 1;
                this.bindList();
                this.setCallData();
            },
            toggle() {
                this.expand = !this.expand;
                this.init();
            },
            pageCurrentChange(pagination) {
                this.pagination.current = pagination.current;
                this.TableListSearchModel.pageIndex = pagination.current;
                this.bindList();
                this.setCallData();
            },
            del(record) {
                this.$axios({
                    url: '/api/Comon/deltag',
                    method: 'post',
                    data: {
                        tableName: 'hdsp_bjxx',
                        field: 'id',
                        value: record.id,
                        source: 'web',
                        loginid: ''
                    }
                }).then((res) => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info("删除成功");
                            this.bindList();
                        } else {
                            throw new Error("服务器超时");
                        }
                    } else {
                        throw new Error("服务器超时");
                    }
                }).catch((error) => {
                    // 请求失败处理
                    this.$message.error(error.message);
                });
            },
            jcbj(record) {
                this.$axios({
                    url: "/api/hdsp/jcbj?bh=" + record.bh,
                    method: "get",
                }).then((res) => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info("解除成功");
                            this.bindList();
                        } else {
                            throw new Error("服务器超时");
                        }
                    } else {
                        throw new Error("服务器超时");
                    }
                }).catch((error) => {
                    // 请求失败处理
                    this.$message.error(error.message);
                });
            },
            init() {
                var that = this;
                size();
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight;
                    that.$nextTick(() => {
                        var el = document.querySelector(
                            "#root>form"
                        );
                        if (el) {
                            that.scroll.y =
                                innerHeight - getComputedStyle(el).height.split("p")[0] - 270;
                        }
                    });
                }
            },
            actions() {
                if (this.inlineButtons.length == 0) {
                    //this.columns.pop();
                }
            },
        },
    };

</script>
<style scoped>
    .video-format {
        position: relative;
        width: 110px; 
        height: 75px;
    }

    .video-format i {
        position: absolute;
        top: 50%;
        left:50%;
        z-index: 9;
        transform: translate(-50%, -50%);
    }

    .ant-layout-footer {
        display: none;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }

</style>