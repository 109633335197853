<template>
  <div id="components-form-demo-advanced-search">
    <a-modal
      v-model="formAddOptions.visible"
      :title="opration + '应急预案'"
      width="800px"
    >
      <a-spin :spinning="formAddOptions.spinning">
        <a-form
          :form="formAdd"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 21 }"
          labelAlign="right"
        >
          <a-form-item label="" style="display:none">
            <a-input v-decorator="['id']" />
            <a-input v-decorator="['bh']" />
            <!--<a-input v-decorator="['hysj']" />-->
          </a-form-item>

          <a-form-item label="预案类型">
            <a-select
              style="width: 100%"
              v-decorator="[
                'lx2',
                { rules: [{ required: true, message: '请选择预案类型' }] },
              ]"
              placeholder="请选择预案类型"
            >
              <a-select-option value="城市防洪">城市防洪</a-select-option>
              <a-select-option value="昆山防汛防旱"
                >昆山防汛防旱</a-select-option
              >
              <a-select-option value="防台风">防台风</a-select-option>
              <a-select-option value="苏州防汛防旱"
                >苏州防汛防旱</a-select-option
              >
            </a-select>
          </a-form-item>

          <a-form-item label="预案标题">
            <a-input
              v-decorator="[
                'title',
                { rules: [{ required: true, message: '请输入预案小标题' }] },
              ]"
              placeholder="请输入预案小标题"
              :maxLength="50"
              :disabled="formAddOptions.viewMode"
            />
          </a-form-item>

          <a-form-item label="预案内容">
            <quill-editor
              v-model="workContent"
              ref="myQuillEditor"
              :options="editorOption"
              @change="onEditorChange($event)"
              @ready="onEditorReady($event)"
              :disabled="formAddOptions.viewMode"
              style="line-height: 0 !important; height:300px"
            />
            <a-upload
              name="file"
              class="avatar-uploader"
              :multiple="true"
              :show-upload-list="false"
              action="http://58.210.32.10:8004/api/FileSet/Upload"
              @change="handleChange"
              :before-upload="beforeUpload"
              ><a-button id="uploadBotton" hidden></a-button>
            </a-upload>
          </a-form-item>
        </a-form>
      </a-spin>
      <template slot="footer">
        <a-button
          key="back"
          @click="
            formAddOptions.visible = false
            formAddOptions.dateVisible = false
          "
          >关闭</a-button
        >
        <a-button
          key="submit"
          type="primary"
          :loading="formAddOptions.submitLoading"
          @click="formAddSubmit"
          v-if="formAddOptions.submitVisible"
          >确定</a-button
        >
      </template>
    </a-modal>

    <a-form
      :form="formSearch"
      @submit="formSearchSubmit"
      :label-col="{ span: 0 }"
      :wrapper-col="{ span: 24 }"
    >
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="预案类型">
            <a-select
              style="width: 100%"
              placeholder="请选择预案类型"
              v-decorator="['lx2']"
            >
              <a-select-option value="城市防洪">城市防洪</a-select-option>
              <a-select-option value="昆山防汛抗旱"
                >昆山防汛抗旱</a-select-option
              >
              <a-select-option value="防台风">防台风</a-select-option>
              <a-select-option value="苏州防汛抗旱"
                >苏州防汛抗旱</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <a-form-item label="预案小标题">
            <a-input
              v-decorator="['title']"
              placeholder="请输入预案小标题"
              :maxLength="40"
            />
          </a-form-item>
        </a-col>

        <a-col :span="4" :style="{ marginTop: '42px', display: 'flex' }">
          <a-button type="primary" html-type="submit" icon="search"
            >搜索</a-button
          >
          <a-button
            type=""
            :style="{ marginLeft: '8px' }"
            @click="formSearchReset"
            >清空筛选</a-button
          >
        </a-col>
        <a-col :span="24" :style="{ textAlign: 'left' }">
          <!-- <a-button
            type="primary"
            v-for="button in buttons"
            @click="btnClick(button.title)"
            style="margin: 8px 0px 16px 0px"
            :key="button.title"
            >{{ button.title }}</a-button
          > -->
		  <a-button type='primary' title="新增"  key="新增" @click="btnClick('新增',record)" style='margin-right: 5px'>新增</a-button>
        </a-col>
      </a-row>
    </a-form>

    <div class="search-result-list" :style="{ marginTop: '0px' }">
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :pageSize="10"
        :total="50"
        rowKey="id"
        :loading="loading"
        :scroll="scroll"
        @change="pageCurrentChange"
        position="bottom"
        tableLayout="fixed"
      >
        <span slot="action" slot-scope="text, record">
        <!--  <a-button
            :type="button.className"
            size="small"
            v-for="button in inlineButtons"
            :key="button.title"
            @click="btnClick(button.title, record)"
            style="margin-right: 5px"
            ><a-icon :type="button.iconLink"
          /></a-button> -->
		  <a-button type='primary' title="编辑" size="small" key="编辑" @click="btnClick('编辑',record)" style='margin-right: 5px'><a-icon type="edit" /></a-button>
		  <a-button type='danger' title="删除" size="small" key="删除" @click="btnClick('删除',record)" style='margin-right: 5px'><a-icon type="delete" /></a-button>
        </span>
        <span slot="titles" slot-scope="text, record">
          <a @click="btnClick('查看', record)">{{ text }}</a>
        </span>
        <span slot="createTime" slot-scope="text">
          <span>{{ text }}</span>
        </span>
        <span slot="lx2" slot-scope="text">
          <a-tag :color="text === '城市防洪' ? 'volcano' : 'geekblue'">
            {{ text }}
          </a-tag>
        </span>
        <span slot="message" slot-scope="text" class="ql-snow ql-editor">
          <span>{{ $jsc.m_substring($jsc.delHtmlTag(text)) }}</span>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
import { quillEditor } from 'vue-quill-editor' //调用编辑器
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
const columns = [
  {
    title: '序号',
    dataIndex: 'rownum',
    width: 70,
    key: 'rownum',
  },

  {
    title: '预案类型',
    dataIndex: 'lx2',
    width: 100,
    key: 'lx2',
    scopedSlots: { customRender: 'lx2' },
  },
  {
    title: '预案小标题',
    dataIndex: 'title',
    width: 160,
    key: 'title',
    scopedSlots: { customRender: 'titles' },
  },
  {
    title: '预案内容',
    dataIndex: 'message',
    width: 300,
    key: 'message',
    scopedSlots: { customRender: 'message' },
    //ellipsis: true,
  },
  {
    title: '操作',
    key: 'action',
    fixed: 'right',
    width: 120,
    scopedSlots: { customRender: 'action' },
  },
]
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ['link', 'image', 'video'],
  ['clean'], // remove formatting button
]
const loading = true
const strWhere = 'del=0'
const action = 'add'
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      showexport: false,
      scroll: {
        x: 900,
      },
      opration: '',
      townData: [],
      zzData: [],
      xmlxData: [],
      qzLoginid: '',
      StartTime: '',
      EndTime: '',
      yhryData: [],
      teamData: [],
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [{ title: '123' }, { title: '234' }],
      columns,
      loading,
      zhlx: '',
      action,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      strWhere,
      workContent: '',
      expand: false,
      formAddOptions: {
        visible: false,
        submitLoading: false,
        submitVisible: true,
        viewMode: false,
        spinning: false,
        dateVisible: false,
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
      formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom',
      },
      editorOption: {
        placeholder: '',
        theme: 'snow', // or 'bubble'
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function(value) {
                if (value) {
                  document.querySelector('#uploadBotton').click()
                } else {
                  this.quill.format('image', false)
                }
              },
            },
          },
        },
      },
    }
  },
  mounted() {
    //将上面的按钮和表格内的按钮分出来
    console.log(this.$route.meta.buttons)
    for (let i = 0; i < this.$route.meta.buttons.length; i++) {
      if (this.$route.meta.buttons[i].tag == 'inline') {
        this.inlineButtons.push(this.$route.meta.buttons[i])
      } else {
        this.buttons.push(this.$route.meta.buttons[i])
      }
    }
    this.init()
    this.actions()
    window.onresize = () => {
      this.init()
    }
    this.bindYhry()
    this.bindList()
  },
  created() {
    document.onkeydown = function(e) {
      //按下回车提交
      let key = window.event.keyCode
      if (key == 13) {
        console.log('')
      }
    }
  },
  methods: {
    handleChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`)
        this.uploadSuccess(info)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
        this.uploadError()
      }
    },
    beforeUpload() {
      // 显示loading动画
      this.quillUpdateImg = true
    },
    uploadSuccess(res) {
      // res为图片服务器返回的数据
      // 获取富文本组件实例
      let quill = this.$refs.myQuillEditor.quill
      // 如果上传成功
      if (res.file.status === 'done') {
        // 获取光标所在位置
        let length = quill.getSelection().index
        // 插入图片  res.info为服务器返回的图片地址
        quill.insertEmbed(length, 'image', res.file.response.data.url)
        // 调整光标到最后
        quill.setSelection(length + 1)
      } else {
        this.$message.error('图片插入失败')
      }
      // loading动画消失
      this.quillUpdateImg = false
    },
    uploadError() {
      // loading动画消失
      this.quillUpdateImg = false
      this.$message.error('图片插入失败')
    },
    onEditorReady(quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.workContent = html
    },

    bindYhry() {
      var _this = this
      this.$axios({
        url: '/api/fxjc/GetYhryList',
        method: 'get',
      })
        .then(function(response) {
          if (response.status === 200) {
            _this.yhryData = response.data.data
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    removeHTMLTag(str) {
      str = str.replace(/<\/?[^>]*>/g, '') //去除HTML tag
      str = str.replace(/[ | ]*\n/g, '\n') //去除行尾空白
      //str = str.replace(/\n[\s| | ]*\r/g,'\n'); //去除多余空行
      str = str.replace(/ /gi, '') //去掉
      return str
    },
    btnClick(text, item) {
      let _this = this
      this.opration = text
      switch (text) {
        case '新增':
          this.openDlg()
          break
        case '编辑':
          this.openDlg(item)
          break
        case '查看':
          this.openDlg(item, 'view')
          break
        case '删除':
          this.$confirm({
            title: '操作确认',
            content: '确定要删除?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.del(item)
            },
          })
          break
      }
    },
    bindList: async function() {
      this.formSearch.validateFields((error, values) => {
        error = 1
        // console.log(values)
        this.strWhere = "del=0 and lx1='应急预案'"
        for (var obj in values) {
          if (values[obj]) {
            if (obj == 'createTime') {
              this.strWhere +=
                ' and ' +
                obj +
                " between '" +
                this.StartTime +
                "' and '" +
                this.EndTime +
                "'"
            } else if (obj == 'title') {
              this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
            } else {
              this.strWhere += ' and ' + obj + " = '" + values[obj] + "'"
            }
          }
        }
        this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
        console.log(this.strWhere)
      })
      this.loading = true
      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere)
      this.strWhere = this.strWhere.data
      await this.InitGrid()
    },
    InitGrid: function() {
      var _that = this
      _that.loading = true
      console.log(_that.strWhere)
      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName: 'v_fxjc_fxkp_newsInfo',
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          filesFields: '',
          strWhere: _that.strWhere,
        },
      })
        .then(function(response) {
          console.log(response)
          _that.loading = false
          if (response.status === 200) {
            _that.tableData = response.data.data
            _that.pagination.total = response.data.count
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    onRangePickerChange(date, dateString) {
      this.StartTime = dateString[0]
      this.EndTime = dateString[1]
    },
    onShowSizeChange(current, pageSize) {
      console.log(current, pageSize)
      return
    },
    formSearchSubmit(e) {
      e.preventDefault()
      this.pagination.current = 1
      this.bindList()
    },
    formSearchReset() {
      this.formSearch.resetFields()
      this.strWhere = 'del=0'
      this.bindList()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    pageCurrentChange(pagination) {
      console.log(pagination)
      //return;
      this.pagination.current = pagination.current
      this.bindList()
    },
    del(record) {
      this.$axios({
        url: '/api/Comon/deltag',
        method: 'post',
        data: {
          tableName: 'fxjc_fxkp_newsInfo',
          field: 'id',
          value: record.id,
          source: 'web',
          loginid: '',
        },
      })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('删除成功')
              this.bindList()
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch((error) => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },
    openDlg: async function(record, action) {
      // var _this = this
      this.workContent = ''
      this.formAdd.resetFields()
      this.fileList = []
      this.formAddOptions.submitVisible = true
      this.formAddOptions.viewMode = false
      if (record !== undefined) {
        // 从后台取数据赋值
        this.formAddOptions.spinning = true
        var obj = await this.$jsc.DesEncrypt(
          this,
          'id=zhangbiao' + record.id + 'zhangbiao'
        )
        obj = obj.data
        this.$axios
          .get(
            '/api/GetList/getListByTable?tableName=v_fxjc_fxkp_newsInfo&strWhere=' +
              obj +
              '&filesFields=bh'
          )
          .then((response) => {
            if (response.status == 200) {
              if (response.data.code == 0) {
                this.formAddOptions.visible = true
                var result = response.data.data
                console.log(response.data)
                this.workContent = result[0].message
                setTimeout(() => {
                  this.formAdd.setFieldsValue(result[0])
                  // this.formAdd.setFieldsValue({
                  //   yhryLoginid: result[0].yhryLoginid.split(','),
                  // })
                  this.editgetText('a')
                  this.formAddOptions.spinning = false
                }, 100)
              } else {
                this.$message.error(response.data.msg)
                this.formAddOptions.spinning = false
              }
            } else {
              // vue.prototype.$message.info('This is an error message');
            }
          })
          .catch(function(error) {
            // 请求失败处理
            console.log(error)
          })
        if (action === 'view') {
          // 预览模式
          this.formAddOptions.submitVisible = false
          this.formAddOptions.viewMode = true
        }
      } else {
        this.formAddOptions.visible = true
      }
    },

    formAddSubmit() {
      this.formAdd.validateFields((err, values) => {
        if (!err) {
          values.lx1 = '应急预案'
          values.message = this.workContent
          this.formAddOptions.submitLoading = true
          this.$axios({
            url: 'api/fxjc/EditFxkpNewsInfo',
            method: 'post',
            data: values,
          })
            .then((res) => {
              if (res.status == 200) {
                if (res.data.code === 0) {
                  this.$message.info('保存成功')
                  this.formAddOptions.visible = false
                  this.formAddOptions.submitLoading = false
                  this.formAddOptions.dateVisible = false
                  this.bindList()
                } else {
                  console.log(res)
                  this.$message.info(res.data.msg)
                  //throw new Error(res.data.msg);
                }
              } else {
                throw new Error('服务器超时2')
              }
            })
            .catch((error) => {
              // 请求失败处理
              this.$message.error(error.message)
              this.formAddOptions.submitLoading = false
            })
        }
      })
    },

    filesCancelPreview() {
      this.previewVisible = false
    },
    async filesPreview(file) {
      //console.log(file.ext)
      // if (!file.url && !file.preview) {
      //   file.preview = await getBase64(file.originFileObj)
      // }
      // this.previewImage = file.url || file.preview
      // this.previewVisible = true
      location.href = file.url
    },
    filesChange: function(file) {
      if (file.file.status === 'removed') {
        this.fileList = file.fileList
      }
    },
    customFilesUpload(data) {
      const formData = new FormData()
      formData.append('file', data.file)
      //formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
      this.saveFile(formData)
    },
    saveFile(formData) {
      this.$axios({
        method: 'post',
        url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
        data: formData,
      })
        .then((res) => {
          if (res.status == 200) {
            var num = this.fileList.length
            this.fileList.push(res.data.data)
            this.uploadgetText('a', num, res.data)
          } else {
            throw new Error('服务器器超时')
          }
        })
        .catch((error) => {
          this.$message.error(error.message)
          console.log(error)
        })
    },
    init() {
      var that = this
      size()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector(
            '#components-form-demo-advanced-search>form'
          )
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('p')[0] - 270
          }
        })
      }
    },
    /**
     * @param {String} classname；upload的类名
     */
    editgetText(classname) {
      this.$nextTick(() => {
        var els = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span'
        )
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        //console.log(els)
        for (var i = 0, len = els.length; i < len; i++) {
          var type = els[i].textContent.split('.')[1]
          if (type !== 'png' && type !== 'jpg') {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = els[i].textContent
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + '...' + type
            }
            cel.innerText = temptext
            spanels[i].prepend(cel)
          }
        }
      })
    },
    /**
     * @param {String} classname；upload的类名
     * @param {Number} num；原有的已上传文件数量
     * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
     */
    uploadgetText(classname, num, response) {
      this.$nextTick(() => {
        //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        var tempNodeList = []
        for (var i = 0; i < spanels.length - num; i++) {
          // console.log(spanels[num+i])
          tempNodeList.push(spanels[num + i])
          for (var j = 0; j < tempNodeList.length; j++) {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = response.name
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + '...' + response.ext
            }
            cel.innerText = temptext
            tempNodeList[j].prepend(cel)
          }
        }
      })
    },
    actions() {
      // if (this.inlineButtons.length == 0) {
      //   columns.pop()
      // }
    },
  },
}

//function getBase64(file) {
//    return new Promise((resolve, reject) => {
//        const reader = new FileReader()
//        reader.readAsDataURL(file)
//        reader.onload = () => resolve(reader.result)
//        reader.onerror = error => reject(error)
//    })
//}
</script>
<style>
#components-form-demo-advanced-search {
  background: white;
  box-sizing: border-box;
  padding: 20px 48px 0;
}

.ant-modal-body {
  padding-bottom: 8px;
}

.ql-editor {
  padding: 0;
}

.ql-snow .ql-editor img {
  width: 20%;
}

.ql-snow img {
  width: 20%;
}

.ant-modal-footer {
  padding-right: 24px;
  margin-top: 30px;
}

.ant-form-item {
  margin-bottom: 12px;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 1px 1px 1px 1px !important;
}
</style>
