<template>
    <a-table 
        :columns="columns" 
        :data-source="dataSource"
        rowKey="proj"
        bordered
        :pagination="false"
    >
    </a-table>
</template>

<script>
    const columns = [
        {
            title: '项目',
            dataIndex: 'proj',
            width: 80,
            align: 'center'
        },
        {
            title: '按月份统计',
            children: [

                {
                    title: '一月',
                    dataIndex: 'm1',
                    align: 'center'
                },
                {
                    title: '二月',
                    dataIndex: 'm2',
                    align: 'center'
                },
                {
                    title: '三月',
                    dataIndex: 'm3',
                    align: 'center'
                },
                {
                    title: '四月',
                    dataIndex: 'm4',
                    align: 'center'
                },
                {
                    title: '五月',
                    dataIndex: 'm5',
                    align: 'center'
                },
                {
                    title: '六月',
                    dataIndex: 'm6',
                    align: 'center'
                },
                {
                    title: '七月',
                    dataIndex: 'm7',
                    align: 'center'
                },
                {
                    title: '八月',
                    dataIndex: 'm8',
                    align: 'center'
                },
                {
                    title: '九月',
                    dataIndex: 'm9',
                    align: 'center'
                },
                {
                    title: '十月',
                    dataIndex: 'm10',
                    align: 'center'
                },
                {
                    title: '十一月',
                    dataIndex: 'm11',
                    align: 'center'
                },
                {
                    title: '十二月',
                    dataIndex: 'm12',
                    align: 'center'
                }
            ]
        }
    ]
    export default {
        data() {
            return {
                columns,
                dataSource: [
                    {
                        proj: '积水上报',
                        m1: 0,
                        m2: 0,
                        m3: 0,
                        m4: 0,
                        m5: 0,
                        m6: 0,
                        m7: 0,
                        m8: 0,
                        m9: 0,
                        m10: 0,
                        m11: 0,
                        m12: 0
                    },
                    {
                        proj: '险情上报',
                        m1: 0,
                        m2: 0,
                        m3: 0,
                        m4: 0,
                        m5: 0,
                        m6: 0,
                        m7: 0,
                        m8: 0,
                        m9: 0,
                        m10: 0,
                        m11: 0,
                        m12: 0
                    }
                ]
            }
        },
    }
</script>

<style lang="less" scoped>

</style>