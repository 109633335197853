<template>
    <div id="components-form-demo-advanced-search">
        <a-modal v-model="formAddOptions.visible" title="新增" width="500px">
            <a-spin :spinning="formAddOptions.spinning">
                <a-form :form="formAdd" :label-col="{ span:5 }" :wrapper-col="{ span:19 }" labelAlign="left">
                    <a-form-item label="" style="display:none">
                        <a-input v-decorator="['id']" />
                    </a-form-item>
                    <a-row type="flex" justify="space-between">
                        <a-col :span="24">
                            <a-form-item label="字典名">
                                <a-input v-decorator="['dictName', { rules: [{ required: true, message: '请输入字典名' }]}]" placeholder="字典名" :disabled="formAddOptions.viewMode" :maxLength="50" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row type="flex" justify="space-between">
                        <a-col :span="24">
                            <a-form-item label="字典值1">
                                <a-input v-decorator="['dictValue1']" placeholder="字典值1" :disabled="formAddOptions.viewMode" :maxLength="500" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row type="flex" justify="space-between">
                        <a-col :span="24">
                            <a-form-item label="字典值2">
                                <a-input v-decorator="['dictValue2']" placeholder="字典值1" :disabled="formAddOptions.viewMode" :maxLength="500" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row type="flex" justify="space-between">
                        <a-col :span="24">
                            <a-form-item label="排序">
                                <a-input-number v-decorator="['sort']" placeholder="排序" :disabled="formAddOptions.viewMode" width="100%" :max="9999" :min="0" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                </a-form>
            </a-spin>
            <template slot="footer">
                <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
                <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
            </template>
        </a-modal>
        <a-breadcrumb>
            <a-breadcrumb-item>根目录</a-breadcrumb-item>
            <template v-for="dictPath in dictPaths">
                <a-breadcrumb-item>
                    <template v-if="pid!=dictPath.value">
                        <a @click="degree({id:dictPath.value})">{{dictPath.name}}</a>
                    </template>
                    <template v-else>
                        {{dictPath.name}}
                    </template>
                </a-breadcrumb-item>
            </template>
        </a-breadcrumb>
        <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }" style=" margin-top:20px;">
            <a-row :gutter="24">
                <a-col :span="8">
                    <a-form-item label=""><a-input placeholder="字典名" v-decorator="[`dictName`]" /></a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label=""><a-input placeholder="字典值1" v-decorator="[`dictValue1`]" /></a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label=""><a-input placeholder="字典值2" v-decorator="[`dictValue2`]" /></a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="[24,12]">
                <a-col :span="6" :style="{ textAlign: 'left' }">
                    <a-button v-if="role>10" type='primary' @click="btnClick('新增')" style='margin-right: 5px'>新增</a-button>
                    <a-button type='danger' @click="btnClick('删除该分类')" style='margin-right: 5px' v-if="this.$User.user.userInfo.role>=999">删除该分类</a-button>
                    <!-- primary/waring/danger/green -->
                </a-col>
                <a-col :span="18" :style="{ textAlign: 'right' }">
                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    <!--<a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                    </a>-->
                </a-col>
            </a-row>
        </a-form>

        <div class="search-result-list" :style="{ marginTop: '0px' }">
            <a-table :columns="columns"
                     :data-source="tableData"
                     :pagination="pagination"
                     :pageSize="20"
                     :total="50"
                     rowKey="id"
                     :loading="loading"
                     :scroll="scroll"
                     @change="pageCurrentChange"
                     position="bottom"
                     tableLayout="fixed">
                <a slot="dictName" slot-scope="text,record" @click="degree(record)">{{ text }}</a>
                <a-tag color="volcano" slot="childrenCount" slot-scope="text,record">{{ text }}</a-tag>
                <span slot="action" slot-scope="text, record">
                    <a-button v-if="role>10" type='' size="small" @click="btnClick('编辑',record)" style='margin-right: 5px'><a-icon type="edit" /></a-button>
                    <a-button v-if="role>10" type='danger' size="small" @click="btnClick('删除',record)" style='margin-right: 5px'><a-icon type="delete" /></a-button>
                </span>
            </a-table>
        </div>
    </div>
</template>
<script>
    //import { DesEncrypt } from '@/utils/Jscript'
    const columns = [
        {
            title: '序号',
            dataIndex: 'rownum',
            width: 100,
            key: 'rownum'
        },
        {
            title: '字典名',
            dataIndex: 'dictName',
            key: 'dictName',
            width: 200,
            scopedSlots: { customRender: 'dictName' }
        },
        {
            title: '字典值1',
            dataIndex: 'dictValue1',
            key: 'dictValue1'
        },
        {
            title: '字典值2',
            dataIndex: 'dictValue2',
            key: 'dictValue2'
        },
        {
            title: '子节点数',
            dataIndex: 'childrenCount',
            width: 100,
            key: 'childrenCount',
            scopedSlots: { customRender: 'childrenCount' }
        },
        {
            title: '排序',
            dataIndex: 'sort',
            width: 100,
            key: 'sort'
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            scopedSlots: { customRender: 'action' }
        }
    ]

    const loading = true
    const strWhere = 'del=0'
    const action = 'add'
    export default {
        data() {
            return {
                role:0,
                pid: 0,
                showexport: false,
                scroll: {
                    x: 900,
                    y: 900
                },
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                tableData: [],
                columns,
                loading,
                action,
                previewVisible: false,
                previewImage: '',
                strWhere,
                expand: false,
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
                pagination: {
                    total: 0,
                    pageSize: 10, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
                    showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: 'bottom'
                },
                dictPaths: []
            }
        },
        watch: {
            '$route.query.id'(val) {
                if (val) {
                    this.pid = val;
                    this.bindList();
                }
            }
        },
        mounted() {
            this.pid = this.$route.query.id;
            this.setPath();
            this.init()
            this.actions()
            window.onresize = () => {
                this.init()
            }
            this.bindList();
            var user=localStorage.getItem("userInfo");
console.log('user',user);
  var usjson=JSON.parse(user)
    this.role=usjson.role;
        },
        methods: {
            btnClick(text, item) {
                let _this = this;
                switch (text) {
                    case '新增':
                        this.openDlg()
                        break
                    case "编辑":
                        this.openDlg(item);
                        break;
                    case "删除":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定要删除 " + item.dictName + " 吗?",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.del(item);
                            }
                        });
                        break;
                    case "删除该分类":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定要删除该分类吗 ? ",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.del({ id: _this.pid });
                                _this.$router.push({ path: "/system/dict-class" });
                            }
                        });
                        break;
                }
            },
            bindList: async function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1
                    this.strWhere = "pid=" + this.pid;
                    for (var obj in values) {
                        if (values[obj]) {
                            this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
                        }
                    }
                    console.log(this.strWhere)
                    this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
                })
                this.loading = true;
                this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere);
                this.strWhere = this.strWhere.data;
                await this.InitGrid()
            },
            InitGrid: function () {
                var _that = this
                _that.loading = true
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'v_sys_dict',
                        page: _that.pagination.current,
                        limit: _that.pagination.pageSize,
                        strWhere: _that.strWhere
                    }
                }).then(function (response) {
                    _that.loading = false
                    if (response.status === 200) {
                        _that.tableData = response.data.data
                        _that.pagination.total = response.data.count
                        _that.setPath();
                    }
                }).catch(function (error) {
                    console.log(error)
                })
            },
            formSearchSubmit(e) {
                e.preventDefault()
                this.pagination.current = 1
                this.bindList()
            },
            formSearchReset() {
                this.formSearch.resetFields()
                this.strWhere = 'del=0'
                this.bindList()
            },
            toggle() {
                this.expand = !this.expand
                this.init()
            },
            pageCurrentChange(pagination) {
                this.pagination.current = pagination.current
                this.bindList()
            },
            del(record) {
                this.$axios({
                    url: '/api/Comon/deldict',
                    method: 'post',
                    data: {
                        field: 'id',
                        value: record.id,
                        source: 'web',
                        loginid: ''
                    }
                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info('删除成功');
                            this.bindList();
                        }
                        else {
                            throw new Error("服务器超时");
                        }
                    }
                    else {
                        throw new Error("服务器超时");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                })
            },
            openDlg: async function (record, action) {

                this.formAdd.resetFields()
                this.formAddOptions.submitVisible = true
                this.formAddOptions.viewMode = false
                if (record !== undefined) {
                    // 从后台取数据赋值
                    this.formAddOptions.spinning = true
                    var obj = await this.$jsc.DesEncrypt(this, 'id=zhangbiao' + record.id + 'zhangbiao');
                    obj = obj.data;
                    this.$axios
                        .get('/api/GetList/getListByTable?tableName=sys_dict&strWhere=' + obj + '')
                        .then((response) => {
                            if (response.status == 200) {
                                if (response.data.code == 0) {
                                    this.formAddOptions.visible = true;
                                    var result = response.data.data;
                                    this.$nextTick(() => {
                                        this.formAdd.setFieldsValue(result[0])
                                        this.formAddOptions.spinning = false
                                    });
                                }
                                else {
                                    this.$message.error(response.data.msg);
                                    this.formAddOptions.spinning = false;
                                }
                            } else {
                                // vue.prototype.$message.info('This is an error message');
                            }
                        })
                        .catch(function (error) { // 请求失败处理
                            console.log(error)
                        })
                    if (action === 'view') { // 预览模式
                        this.formAddOptions.submitVisible = false
                        this.formAddOptions.viewMode = true
                    }
                }
                else {

                    this.formAddOptions.visible = true;
                    this.$nextTick(() => {
                        this.formAdd.setFieldsValue({ sort: "0" });
                    });
                }
            },
            formAddSubmit() {
                this.formAdd.validateFields((err, values) => {
                    if (!err) {
                        this.formAddOptions.submitLoading = true
                        values.pid = this.pid;
                        this.$axios({
                            url: '/api/xtsz/EditDictInfo',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('保存成功')
                                    this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false
                                    this.bindList()
                                } else {
                                    console.log(res);
                                    throw new Error("服务器超时2");
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })

                    }
                })
            },

            init() {
                var that = this
                size()
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 310
                        }
                    })
                }
            },

            degree(item) {
                //console.log(item);
                this.$router.push("dict-list?id=" + item.id);
            },
            actions() {
                //if (this.inlineButtons.length == 0) {
                //    columns.pop();
                //}
            },
            setPath() {
                this.$axios({
                    url: '/api/xtsz/GetDictPath?id=' + this.pid,
                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.dictPaths = res.data.data;
                            
                        }
                    }

                }).catch(error => { // 请求失败处理
                })
            }
        }
    }
</script>
<style>
    .ant-layout-footer {
        display: none;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
        padding: 20px 20px 0;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }
</style>