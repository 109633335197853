<template>
    <div class="module rootbox">
        <div class="qr">
            <img src="/img/slj_qr.png" alt="二维码">
            <h3>防汛防旱微信端</h3>

            <img src="/img/kssw.png" alt="二维码">
            <h3>智慧水务APP(安卓)</h3>
        </div>
        <div class="title"><img src="img/logo.png" /></div>
        <div class="top1">
            <span>【<span class="user">{{this.$User.user.userInfo.departName}}</span>】{{this.$User.user.userInfo.realName}}，欢迎登陆！</span>
            <ul class="there">
                <!--<li>
                <img src="../public/img/desktop.png" />
                <span><a href="昆山水务大数据_ExportedFlow/index.html" target="_blank">大数据展示</a></span>
            </li>-->
                <li v-for="(item,index) in syss" :key="index">
                    <img src="../public/img/setting.png" />
                    <span>
                        <a :href="$axios.defaults.baseURL+'/Home/ToForm?actionName=Index&controlerName=SysSet&loginid='+$User.user.userInfo.loginid" target="_blank">系统设置</a>
                    </span>
                    <!--<span><a @click="()=>$router.push({path:'/system'})" target="_blank">系统设置</a></span>-->
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <!--<img src="../public/img/setting.png" />
                    <span><a @click="()=>$router.push({path:'/system'})" target="_blank">系统设置</a></span>-->
                </li>
                <li>
                    <img src="../public/img/export.png" />
                    <span @click="out">退出登录</span>
                </li>
            </ul>
        </div>
        <!--<div class="top2">今日天气：昆山市，阴， 1~8°，多云转阴，东北风4-5级，相对湿度23%，体感温度5.1°</div>
    <div class="top3">今日水文：外河水位：3.02m，内河水位：2.80m</div>-->
        <div class="top4wrap">
            <ul class="top4">
                <li :class="item.todo" v-for="(item,index) in subs" :key="index" @click="pathTo(index,item)">
                    <span class="hex">
                        <span class="hexIn" :style="{backgroundImage: 'url(' + item.bg + ')'}" :title="item.title"></span>
                    </span>
                </li>
            </ul>
        </div>
       <div class="footer">{{$store.state.siteInfo.belongDepart}}</div>
        <a-modal v-model="subVisible" :title="subtitle" width="975px" :footer="null">
            <ul class="submk">
                <li v-for="(submk,index) in submks" :key="index" @click="pathToSub(submk.path)"><img :src="submk.iconLink" /></li>
            </ul>
        </a-modal>
    </div>
</template>
<script>
    import "../public/css/desk.css"
    import { findIdSon } from './router.js'
    

    export default {
        data() {
            return {
                subs: [],
                reqCount: {//用于记录获取提醒数量axios请求发送和返回数量，如果相等则说明全部执行完成
                    sendCount: 0,
                    recCount: 0,
                },
                syss: [],
                subVisible: false,
                submks: [],
                subtitle:"",
            }
        },
        created: function () {

            document.onkeydown = function (e) {   //按下回车提交
                let key = window.event.keyCode;
                if (key == 13) {
                    //that.login();
                }
            };

		    console.log(this.$User.user.asyncRoutes)
            //找出系统模块
            for (var i = 0; i < this.$User.user.asyncRoutes.length; i++) {
                //console.log(this.$router);
                if (this.$User.user.asyncRoutes[i].parentId == 0) {
                    if (this.$User.user.asyncRoutes[i].moduleType == 0 && this.$User.user.asyncRoutes[i].isShow==1) {
                        this.subs.push({ "id": this.$User.user.asyncRoutes[i].id, "path": this.$User.user.asyncRoutes[i].path, "title": this.$User.user.asyncRoutes[i].title, "todo": "", "bg": this.$User.user.asyncRoutes[i].iconLink });
                    }
                    else if (this.$User.user.asyncRoutes[i].moduleType == 2 && this.$User.user.asyncRoutes[i].isShow == 1) {
                        this.syss.push({ "id": this.$User.user.asyncRoutes[i].id, "path": this.$User.user.asyncRoutes[i].path, "title": this.$User.user.asyncRoutes[i].title, "todo": "", "bg": this.$User.user.asyncRoutes[i].iconLink });
                    }
                }
            }
            this.cpTodo(this.subs);
            //console.log(this.$User.user.asyncRoutes);

            
        },
        //watch: {
        //    reqCount: {
        //        handler(newName, oldName) {
        //            if (newName.sendCount == newName.recCount) {
        //                this.$forceUpdate();
        //            }
        //        },
        //        deep: true
        //    },
        //},
        methods: {
            out: function () {
                let pthis = this;
                this.$confirm({
                    title: '消息提示',
                    content: '确定要退出吗?',
                    onOk() {
                        pthis.$User.user.loginOut();
                    },
                    onCancel() { },
                });
            },
            pathTo(index, item) {
                var title = this.subs[index].title;

                if (title == "工程运行管理")
                {
                    this.submks = findIdSon(item.id);
                    this.subtitle = this.subs[index].title;
                    this.subVisible = true;
                    return false;
                }
                else if (title == "一张图管理") {
                    window.open("http://180.97.207.33:8091/web/_index.html?token=" + this.$User.user.token.access_token);
                    return false;
                }
                else if (item.path.indexOf("http") < 0) {
                    if (this.$route.path == item.path) return
					
                    this.$router.push({ path: item.path });
                }
                else if (this.subs[index].path == "404") {
                    this.$message.warning("暂未开放!");
                    return false;
                }        
                else {
                    window.open(item.path);
                }


                //if (title == "防汛决策系统") {
                //    //window.open("防汛决策管理_ExportedFlow/index.html");
                //    //return false;
                //}
                //else if (title == "河湖长制管理") {
                //    window.open("http://58.210.32.10:8005/Home/ToHzzForm?loginid=20114172759547998");
                //    return false;
                //}
                //else if (title == "公务用车管理系统") {
                //    window.open("http://180.97.207.33:8891/ksjdc/ptyh;JSESSIONID=9d9bddab-380b-4c8d-af3b-9efe9ac5b8d9");
                //    return false;
                //}
                //else if (title == "水资源管理信息系统") {
                //    window.open("http://58.210.218.30:25000/login.aspx?ticket=ST-213-G7f5m501cHdrSPjFZQqJ-cas.hl.com");
                //    return false;
                //}
                //else if (title == "河湖信息管理系统") {
                //    window.open("http://180.97.207.33:52212/kshh/index");
                //    return false;
                //}
                //else if (title == "排水许可管理系统") {
                //    window.open("http://180.97.207.29:8894");
                //    return false;
                //}
                //else if (title == "节约用水管理系统") {
                //    window.open("http://180.97.207.33:8891/jyys/ptyh;JSESSIONID=6060ed32-999b-46b4-87a5-cf3364956cb0");
                //    return false;
                //}
                //else if (title == "一张图管理") {
                //    window.open("http://180.97.207.33:8091/web/_index.html?token=" + this.$User.user.token.access_token);
                //    return false;
                //}
                //else if (this.subs[index].path == "404") {
                //    this.$message.warning("暂未开放!");
                //    return false;
                //}
                
                //else if (title == "党政办公管理" || title == "规划建设管理" || title == "水生管理" || title == "水业管理" || title == "工程运行管理" || title == "行政服务管理" || title == "政策法规管理")
                //{
                //    this.submks = findIdSon(item.id);
                //    this.subtitle = this.subs[index].title;
                //    this.subVisible = true;
                //    console.log(this.submks);
                //    return false;
                //}
                //this.$router.push(this.subs[index].path)
            },
            pathToSub(path) {
                if (path == "404") {
                    this.$message.warning("暂未开放!");
                    return false;
                }
                else if (path.indexOf("http") >= 0) {
                    if (this.$route.path == path) return
                    window.open(path);
                }
                else {
                    this.$router.push(path);
                }
            },
            cpTodo(arr) {//找出计算待办事项数量
                arr.forEach(item => {
                    item.todo = 0;
                    this.reqCount.sendCount++;
                    this.$axios({
                        url: '/api/comon/GetRemindByMkid?mkid=' + item.id,
                        method: 'get',
                    }).then(res => {
                        if (res.status == 200) {
                            if (res.data.code == 0) {
                                if (res.data.data.num > 0) {
                                    this.addTodo(res.data.data.mkid, res.data.data.num);
                                }
                            }
                        }
                        else {
                            //throw new Error("服务器超时2");
                        }
                        this.reqCount.recCount++;
                    }).catch(error => { // 请求失败处理
                        //this.$message.error(error.message);
                        //this.formAddOptions.submitLoading = false
                    });

                    if (item.children && item.children.length > 0) {
                        this.cpTodo(item.children);
                    }
                });
            },
            addTodo(mkid, num) {//添加菜单红点
                for (var i = 0; i < this.subs.length; i++) {
                    if (this.subs[i].id == mkid) {
                        this.subs[i].todo = "dot";
                        return false;
                    }
                }
            }
        },
    }
</script>

<style lang="less" scoped>
    .rootbox {
        overflow: hidden;
    }

    .qr {
        margin: 10px 0 0 10px;

        img {
            width: 120px;
            height: 120px;
        }

        h3 {
            color: #3382FF;
        }
    }
</style>
