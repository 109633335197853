<template>
  <div id="components">

    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
      <a-row :gutter="24">
        <a-col :span="5">
          <a-form-item label="">
            <a-select v-decorator="['teamId']" placeholder="巡查队伍" style="width:100%">
              <a-select-option v-for="item in teamData" :value="item.value">{{item.name}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="5">
          <a-form-item label="">
            <a-select v-decorator="['zzbh']" placeholder="巡查站闸" :disabled="formAddOptions.viewMode" style="width:100%">
              <a-select-option v-for="item in zzData" :value="item.value">{{item.name}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="5">
          <a-form-item label="">
            <a-range-picker id="xc_time" :placeholder="['创建开始时间','创建结束时间']" @change="onRangePickerChange" v-decorator="['createTime']" />
          </a-form-item>
        </a-col>
        <a-col :span="5" style=" min-width:100px;">
          <a-form-item>
            <a-select placeholder="是否巡查" v-decorator="['isExec']">
              <a-select-option value="">全部</a-select-option>
              <a-select-option value="0">
                否
              </a-select-option>
              <a-select-option value="1">
                是
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="">
            <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
            <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
            <a :style="{ marginLeft: '8px', fontSize: '12px',display:'none' }" @click="toggle">
              {{ expand ?'收起':'展开' }}
              <a-icon :type="expand ? 'up' : 'down'" />
            </a>
          </a-form-item>
        </a-col>
        <a-col :span="6" style="display:none;">
          <a-form-item label="">
            <a-select placeholder="是否派单" v-decorator="['pdcs']">
              <a-select-option value="0">
                否
              </a-select-option>
              <a-select-option value="1">
                是
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="[24,12]">
        <a-col :span="6" :style="{ textAlign: 'left' }">
          <a-button type='primary' @click="btnClick('按站闸查看')" style='margin-right: 5px'>按站闸查看</a-button>
          <a-button type='' @click="btnClick('导出')" style='margin-right: 5px'>导出</a-button>
        </a-col>
      </a-row>
    </a-form>
    <div class="search-result-list" :style="{ marginTop: '0px' }">
      <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="20" :total="50" rowKey="id" :loading="loading" :scroll="scroll" @change="pageCurrentChange" position="bottom" tableLayout="fixed">
        <a slot="title2" slot-scope="text,record" key="11" @click="btnClick('查看',record)">
          <template v-if="record.updateUser!=''">
            <span :class="record.ifread==1?'read1':'read2'">{{record.updateUser}}&nbsp;{{$jsc.formatDate_hz(record.updateTime)}}&nbsp;的巡查记录</span>
          </template>
          <template v-else>
            未巡查
          </template>
        </a>
        <span slot="errsl" slot-scope="text,record">
          <a-tag v-if="record.errsl>0" color="red">{{text}}</a-tag>
          <span v-else>{{text}}</span>
        </span>
        <span slot="updateTime" slot-scope="text,record">{{$jsc.formatDate(text)}}</span>
        <span slot="createTime" slot-scope="text,record">{{$jsc.formatDate(text)}}</span>
        <span slot="iscw" slot-scope="text,record">
          <a-tag v-if="record.isExec==1" color="green">是</a-tag>
          <a-tag v-else color="red">否</a-tag>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
const columns = [
  {
    title: '序号',
    dataIndex: 'rownum',
    width: 70,
    fixed: 'left',
    key: 'rownum'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: 120,
    key: 'createTime',
    scopedSlots: { customRender: 'createTime' },
    hide: true
  },
  {
    title: '巡查时间',
    dataIndex: 'updateTime',
    width: 120,
    key: 'updateTime',
    scopedSlots: { customRender: 'updateTime' },
    hide: true
  },
  {
    title: '巡查站闸',
    dataIndex: 'zzmc',
    width: 150,
    key: 'zzmc'
  },
  {
    title: '巡查工程',
    dataIndex: 'xmlx',
    width: 120,
    key: 'xmlx'
  },
  {
    title: '巡查队伍',
    dataIndex: 'team',
    width: 120,
    key: 'team'
  },
  //{
  //    title: '巡查人',
  //    dataIndex: 'updateUser',
  //    width: 120,
  //    key: 'updateUser'
  //},
  {
    title: '标题',
    dataIndex: 'title',
    width: 300,
    key: 'title',
    scopedSlots: { customRender: 'title2' }
  },
  {
    title: '检查项数',
    dataIndex: 'wtsl',
    width: 150,
    key: 'wtsl'
  },
  {
    title: '不合格项数',
    dataIndex: 'errsl',
    width: 150,
    key: 'errsl',
    scopedSlots: { customRender: 'errsl' }
  },
  {
    title: '是否巡查',
    dataIndex: 'isExec',
    width: 100,
    key: 'isExec',
    scopedSlots: { customRender: 'iscw' }
  }
  //{
  //    title: '已解决项数',
  //    dataIndex: 'resolvedCount',
  //    width: 150,
  //    key: 'resolvedCount',
  //},
  //{
  //    title: '派单次数',
  //    dataIndex: 'pdcs',
  //    width: 150,
  //    key: 'pdcs',
  //    hide: true
  //}
]

const loading = true
const strWhere = 'del=0'
const action = 'add'
export default {
  data() {
    return {
      showexport: false,
      scroll: {
        y: 900,
        x: 900
      },
      //actionList: {
      //    add: false,
      //    remove: false,
      //    edit: false
      //},
      config: {
        rules: [{ type: 'object', required: true, message: '请选择办理期限!' }]
      },
      zzData: [],
      teamData: [],
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [],
      blr_data: [],
      blr_wbcompany_data: [],
      blr_wgy_data: [],
      problems_data: [],
      type: '',
      view_fields: {
        viewModel: false,
        title: '检查项',
        data: []
      },
      StartTime: '',
      EndTime: '',
      columns,
      loading,
      action,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      strWhere,
      expand: false,
      formAddOptions: {
        visible: false,
        submitLoading: false,
        submitVisible: true,
        viewMode: false,
        spinning: false
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
      formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
      pagination: {
        total: 0,
        pageSize: 15, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom'
      },
      web_decorator_qymc: [
        'companyorwgybh',
        { rules: [{ required: true, message: '请选择' }] }
      ],
      backData: {
        currentPageIndex: 1,
        searchData: {}
      }
    }
  },
  watch: {
    '$route.query.type'(newVal, oldVal) {
      localStorage.setItem('backData', '{}')
      this.formSearch.resetFields()
      this.pagination.current = 1

      this.type = newVal
      this.bindList()
    }
  },
  mounted() {
    var obj = JSON.parse(localStorage.getItem('backData'))
    console.log(obj)
    this.formSearch.setFieldsValue(obj.searchData)
    this.pagination.current = obj.currentPageIndex

    //var isExec = this.$route.query.isExec;
    var createTime1 = this.$route.query.createTime1
    var createTime2 = this.$route.query.createTime2

    if (createTime1 && createTime2) {
      this.formSearch.setFieldsValue({
        createTime: [
          moment(createTime1, 'YYYY-MM-DD'),
          moment(createTime2, 'YYYY-MM-DD')
        ]
      })
    }

    //将上面的按钮和表格内的按钮分出来
    if (this.$route.meta.buttons) {
      for (let i = 0; i < this.$route.meta.buttons.length; i++) {
        if (this.$route.meta.buttons[i].tag == 'inline') {
          this.inlineButtons.push(this.$route.meta.buttons[i])
        } else {
          this.buttons.push(this.$route.meta.buttons[i])
        }
      }
    }

    this.init()
    this.actions()
    window.onresize = () => {
      this.init()
    }
    this.bindList()
    //this.pagination.current = parseInt(localStorage.getItem("current"));
    this.bindDropDownData()

    //localStorage.setItem("searchValue", "{}");
    //localStorage.setItem("current", 1);
    //console.log(this.$route);
    //console.log(this.buttons);
    //console.log(this.inlineButtons);
  },
  created() {
    this.type = this.$route.query.type
  },
  methods: {
    setCallData: function () {
      this.backData.currentPageIndex = this.pagination.current
      this.backData.searchData = this.formSearch.getFieldsValue()
      localStorage.setItem('backData', JSON.stringify(this.backData))
    },
    bindDropDownData() {
      var _this = this
      this.$axios({
        url: '/api/zzgl/GetXcTeam',
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.teamData = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })

      this.$axios({
        url: '/api/Zzgl/getZzList',
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.zzData = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    btnClick(text, item) {
      let _this = this
      switch (text) {
        case '查看':
          this.views(item)
          break
        case '按站闸查看':
          this.$router.push({ path: 'xuncha-myrecorder?type=' + this.type })
          break
        case '导出':
          this.$confirm({
            title: '操作确认',
            content: '是否导出?',
            okText: '是',
            cancelText: '否',
            onOk() {
              _this.dc()
            }
          })
          break
      }
    },
    dc() {
      console.log(this.strWhere)
      this.$axios({
        url: this.$axios.defaults.baseURL + '/api/zzgl/dcXcProject',
        method: 'post',
        data: { strWhere: this.strWhere, excelName: '巡查记录表' }
      })
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data)
            let link = document.createElement('a')
            document.body.appendChild(link)
            link.href = response.data.data
            link.download = '巡查记录表'
            link.style.display = 'none'
            link.click()
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    views(value) {
      console.log(value)
      this.view_fields.viewModel = true
      localStorage.setItem('current', this.pagination.current)
      localStorage.setItem(
        'searchValue',
        JSON.stringify(this.formSearch.getFieldsValue())
      )
      this.$router.push({
        path:
          'xuncha-myrecorder-details?xczzbh=' +
          value.xczzbh +
          '&probh=' +
          value.bh +
          '&titles=' +
          value.updateUser +
          ' ' +
          this.$jsc.formatDate_hz(value.updateTime) +
          ' 的巡查记录'
      })
    },
    onRangePickerChange(date, dateString) {
      this.StartTime = dateString[0]
      this.EndTime = dateString[1]
    },
    bindList: async function () {
      this.formSearch.validateFields((error, values) => {
        error = 1
        console.log(values)
        this.strWhere = 'del=0'
        switch (this.type) {
          case '1':
            this.strWhere +=
              " and createUserId='" + this.$User.user.userInfo.loginid + "'"
            break
          case '2':
            this.strWhere += ' and isError=1'
            break
          case '3':
            this.strWhere += ' and pdcs>0'
            break
        }
        for (var obj in values) {
          if (values[obj]) {
            if (obj == 'teamId' || obj == 'zzbh') {
              this.strWhere += ' and ' + obj + " ='" + values[obj] + "'"
            } else if (obj == 'createTime') {
              this.strWhere +=
                ' and ' +
                obj +
                " between '" +
                values[obj][0].format('YYYY-MM-DD') +
                "' and '" +
                values[obj][1].format('YYYY-MM-DD 23:59:59') +
                "'"
            } else {
              this.strWhere += ' and ' + obj + " ='" + values[obj] + "'"
            }
            //if (obj == "pdcs") {
            //    if (values[obj] == 0) {
            //        this.strWhere += ' and ' + obj + "=" + 0 + ""
            //    } else {
            //        this.strWhere += ' and ' + obj + ">" + 0 + ""
            //    }
            //}
          }
        }
        console.log(this.strWhere)
        this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
      })

      this.loading = true

      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere)
      this.strWhere = this.strWhere.data
      await this.InitGrid()
    },
    InitGrid: function () {
      var _that = this
      _that.loading = true
      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName: 'v_zhsw_xcgl_xcProject', //v_zzgl_xcgl_xclog
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          // filesFields: 'bh',
          strWhere: _that.strWhere,
          strFields:
            "*,ifread=dbo.f_zhsw_xcgl_xcProject_read('" +
            this.$User.user.userInfo.loginid +
            "',v_zhsw_xcgl_xcProject.id)"
        }
      })
        .then(function (response) {
          console.log(response)
          _that.loading = false
          if (response.status === 200) {
            _that.tableData = response.data.data
            _that.pagination.total = response.data.count
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    formSearchSubmit(e) {
      e.preventDefault()
      this.pagination.current = 1
      this.bindList()
      this.setCallData()
    },
    formSearchReset() {
      this.formSearch.resetFields()
      this.pagination.current = 1
      this.strWhere = 'del=0'
      this.bindList()
      this.setCallData()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    pageCurrentChange(pagination) {
      this.pagination.current = pagination.current
      this.bindList()
      this.setCallData()
    },
    openDlg: async function (record, action) {
      this.formAdd.resetFields()
      this.fileList = []
      this.formAddOptions.submitVisible = true
      this.formAddOptions.viewMode = false
      if (record !== undefined) {
        // 从后台取数据赋值
        this.formAddOptions.spinning = true
        var obj = await this.$jsc.DesEncrypt(
          this,
          'id=zhangbiao' + record.id + 'zhangbiao'
        )
        obj = obj.data
        this.$axios
          .get(
            '/api/GetList/getListByTable?tableName=zzgl_wbry&strWhere=' +
              obj +
              '&filesFields=bh'
          )
          .then(response => {
            if (response.status == 200) {
              if (response.data.code == 0) {
                this.formAddOptions.visible = true
                var result = response.data.data
                console.log(response.data)
                setTimeout(() => {
                  result[0].yyqk = true
                  // var objArr = { qymc: result[0].qymc, lxr: result[0].lxr, yyqk: true }
                  // this.formAdd.setFieldsValue(objArr)
                  this.formAdd.setFieldsValue(result[0])
                  this.fileList = result[0].files_bh
                  this.editgetText('a')
                  this.formAddOptions.spinning = false
                }, 100)
              } else {
                this.$message.error(response.data.msg)
                this.formAddOptions.spinning = false
              }
            } else {
              // vue.prototype.$message.info('This is an error message');
            }
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error)
          })
        if (action === 'view') {
          // 预览模式
          this.formAddOptions.submitVisible = false
          this.formAddOptions.viewMode = true
        }
      } else {
        this.formAddOptions.visible = true
      }
    },
    filesCancelPreview() {
      this.previewVisible = false
    },
    async filesPreview(file) {
      //console.log(file.ext)
      // if (!file.url && !file.preview) {
      //   file.preview = await getBase64(file.originFileObj)
      // }
      // this.previewImage = file.url || file.preview
      // this.previewVisible = true
      location.href = file.url
    },
    filesChange: function (file) {
      if (file.file.status === 'removed') {
        this.fileList = file.fileList
      }
    },
    customFilesUpload(data) {
      const formData = new FormData()
      formData.append('file', data.file)
      formData.append('token', 'aiufpaidfupipiu') // 随便写一个token示例
      this.saveFile(formData)
    },
    init() {
      var that = this
      size()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector('#components>form')
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('p')[0] - 270
          }
        })
      }
    },
    /**
     * @param {String} classname；upload的类名
     */
    editgetText(classname) {
      this.$nextTick(() => {
        var els = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span'
        )
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        //console.log(els)
        for (var i = 0, len = els.length; i < len; i++) {
          var type = els[i].textContent.split('.')[1]
          if (type !== 'png' && type !== 'jpg') {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = els[i].textContent
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + '...' + type
            }
            cel.innerText = temptext
            spanels[i].prepend(cel)
          }
        }
      })
    },
    /**
     * @param {String} classname；upload的类名
     * @param {Number} num；原有的已上传文件数量
     * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
     */
    uploadgetText(classname, num, response) {
      this.$nextTick(() => {
        //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        var tempNodeList = []
        for (var i = 0; i < spanels.length - num; i++) {
          // console.log(spanels[num+i])
          tempNodeList.push(spanels[num + i])
          for (var j = 0; j < tempNodeList.length; j++) {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = response.name
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + '...' + response.ext
            }
            cel.innerText = temptext
            tempNodeList[j].prepend(cel)
          }
        }
      })
    },
    actions() {
      //if (this.inlineButtons.length == 0) {
      //    columns.pop();
      //}
    }
  }
}

//function getBase64(file) {
//    return new Promise((resolve, reject) => {
//        const reader = new FileReader()
//        reader.readAsDataURL(file)
//        reader.onload = () => resolve(reader.result)
//        reader.onerror = error => reject(error)
//    })
//}
</script>
<style scoped>
.ant-layout-footer {
  display: none;
}

#components {
  background: white;
  box-sizing: border-box;
  padding: 20px 20px 0;
}
.read1 {
  font-weight: normal;
}
.read2 {
  font-weight: bold;
}
.ant-form-item {
  margin-bottom: 12px;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: !important;
}

/*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 1px 1px 1px 1px !important;
}
</style>