<template>
    <!-- 巡查管理 -->
    <div class="wh-100p" ref="rootBox">
        <a-form :form="form" @submit="onSearch">
            <a-row :gutter="24">
                <a-col :span="4">
                    <a-form-item>
                        <a-input v-decorator="['orderNo']" placeholder="编号" :maxLength="40" />
                    </a-form-item>
                </a-col>
                <a-col :span="4">
                    <a-form-item>
                        <a-select v-decorator="['townId']" placeholder="请选择区镇">
                            <a-select-option :value="item.value" v-for="(item, index) in townList" :key="index">{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="4">
                    <a-form-item>
                        <a-input v-decorator="['xmmc']" placeholder="项目名称" :maxLength="40" />
                    </a-form-item>
                </a-col>
                <a-col :span="4">
                    <a-form-item>
                        <a-select v-decorator="['isError']" placeholder="是否存在问题">
                            <a-select-option value="1">是</a-select-option>
                            <a-select-option value="0">否</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item>
                        <a-button type="primary"  html-type="submit" icon="search">搜索</a-button>
                        <a-button :style="{ marginLeft: '8px' }" @click="onReset" icon="reload">重置</a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>

        <a-table 
            rowKey="id"
            :columns="columns" 
            :data-source="tableData" 
            :pagination="pagination" 
            :loading="loading" 
            :scroll="scroll" 
            @change="onChangePage">
            <template slot="isError" slot-scope="text">
                <span>{{ text ? '是' : '否' }}</span>
            </template>
            <template slot="action" slot-scope="text, record">
                <a-button 
                    v-for="ib in inlineButtons" 
                    :key="ib.title" 
                    :type="ib.className" 
                    size="small" 
                    @click="onDispatch(ib.title, record)" 
                    style="margin-right: 5px" 
                    :disabled="record.isEnd === 1">
                        {{ record.isEnd === 1 ? '已派单' : ib.title }}
                    </a-button>
            </template>
        </a-table>
    </div>
</template>
<script>
let columns = [
    {
        title: "序号",
        dataIndex: "rownum",
        width: 70,
    },
    {
        title: "编号",
        dataIndex: "orderNo"
    },
    {
        title: "区镇",
        dataIndex: "town",
        ellipsis: true,
    },
    {
        title: "项目名称",
        dataIndex: "xmmc",
        ellipsis: true,
    },
    {
        title: "一级考核内容",
        dataIndex: "khnr1",
        ellipsis: true,
    },
    {
        title: "二级考核内容",
        dataIndex: "khnr2",
        ellipsis: true,
    },
    {
        title: "三级考核内容",
        dataIndex: "khnr3",
        ellipsis: true,
    },
    {
        title: "标准分值",
        dataIndex: "bzfz",
        width: 90,
    },
    {
        title: "检查评分原则",
        dataIndex: "pfyz",
        ellipsis: true,
    },
    {
        title: "扣分",
        dataIndex: "kf",
        width: 60
    },
    {
        title: "是否存在问题",
        dataIndex: "isError",
        width: 120,
        scopedSlots: { customRender: "isError" }
    },
    {
        title: "派单次数",
        dataIndex: "pdcs",
    },
    {
        title: "整改意见",
        dataIndex: "zgyj",
        ellipsis: true,
    },
    {
        title: "操作",
        dataIndex: "action",
        fixed: "right",
        width: 100,
        scopedSlots: { customRender: "action" },
    },
]

const PATH_TO_SQL = [
    {
        path: '/gcyxgl/slgcxczgxt/cityxczg_z1',
        value: 'gclx=0'
    },
    {
        path: '/gcyxgl/slgcxczgxt/cityxczg_z2',
        value: 'gclx=1'
    },
    {
        path: '/gcyxgl/slgcxczgxt/cityxczg_d',
        value: 'gclx=2'
    }
]

export default {
    data() {
        return {
            buttons: [],
            inlineButtons: [],

            townList: [],
            form: this.$form.createForm(this, { name: "search" }),

            tableData: [],
            columns: Object.freeze(columns),
            loading: false,
            scroll: {
                x: 1600,
                y: 0,
                scrollToFirstRowOnChange: true
            },
            pagination: {
                total: 0,
                current: 1,
                showTotal: (total) => `共 ${total} 条`
            }
        }
    },
    methods: {
        // 获取区镇
        getTown() {
            this.$axios.get("/api/comon/GetTown", {
                    pageIndex: 1,
                    pageSize: 10000,
                })
                .then(({ data: { code, data: resData } }) => {
                    if (code !== 0) return
                    this.townList = resData
                })
        },
        onDispatch(text, item) {
            if (text === '派单') {
                let titleList = this.columns.map(item => {
                    let newItem = new Object()
                    newItem.title = item.title
                    newItem.dataIndex = item.dataIndex
                    return newItem
                })
                titleList = JSON.stringify(titleList.slice(0, -1))
                let inspectDetail = JSON.stringify(item)
                this.$router.push({ path: './cityxczgpd', query: {titleList: titleList, data: inspectDetail}})
            }
        },
        // sql拼接和加密
        async handleSql(obj = {}) {
            let strWhere = ` del=0 and ${this.sql} `

            if (Object.keys(obj).length) {
                Object.keys(obj).forEach(key => {
                    if (obj[key]) {
                        strWhere +=  " and " + key + " like 'kejin" + obj[key] + "kejin'"
                    }
                })
                strWhere = strWhere.replace(/'/g, 'zhangbiao')
            } 

            let { data } = await this.$jsc.DesEncrypt(this, strWhere)
            return data
        },
        async findTableData () {
            this.loading = true

            this.$axios({
                url: "/api/GetList/getListByTable",
                method: "get",
                params: {
                    tableName: "v_fxjc_xcgl_rcxcInfo",
                    page: this.pagination.current,
                    limit: 10,
                    strWhere: await this.handleSql(this.findCond().field),
                },
            })
                .then(({ data: { code, count, data: resData } }) => {
                    if (code !== 0) return
                    this.tableData = resData
                    this.pagination.total = count
                    this.onAdjust()
                })
                .catch(err => err)
                .finally(() =>  this.loading = false)
        },
        findCond () {
            let field = this.form.getFieldsValue()
            let fieldKeys = Object.keys(field)
            let isFieldValues = fieldKeys.some(fk => field[fk])
            return {
                field,
                fieldKeys,
                isFieldValues
            }
        },
        resetCond () {
            this.form.resetFields()
        },
        onSearch() {
            if (!this.findCond().isFieldValues) return
            this.pagination.current = 1
            this.findTableData()

        },
        onReset() {
            if (!this.findCond().isFieldValues) return
            this.resetCond()
            this.pagination.current = 1
            this.findTableData()
        },
        onChangePage({ current }) {
            this.pagination.current = current
            this.findTableData()
        },
        watchRoute (val) {
            let { value } = PATH_TO_SQL.find(pts => pts.path === val.path)
            this.sql = value
            this.findTableData()
        },
        onAdjust () {
            this.$nextTick(() => {
                this.scroll.y = this.$refs.rootBox.offsetHeight - 180
            })
        }
    },
    watch: {
        $route: {  handler: 'watchRoute', immediate: true }
    },
    created() {
        this.getTown()
        window.onresize =  (() => {
            let timer
            return () => {
                timer && clearTimeout(timer)
                timer = setTimeout( () => {
                    this.onAdjust()
                }, 100)
            }
        })()
    },
    mounted() {
        let btns = this.$route.meta.buttons
        for (let i = 0; i < btns.length; i++) {
            if (btns[i].tag == "inline") {
                this.inlineButtons.push(btns[i])
                continue 
            } 
            this.buttons.push(btns[i])
        }
    },
    beforeDestroy () {
        window.onresize = null
    }
}
</script>

<style>
</style>
