<template>
	<a-row style="margin-top: 16px;">
		<a-col :xxl="6" :xl="8" :md="12" v-for="item in zhanzhaList" :key="index">
			<a-card hoverable style="max-width: 330px;margin-bottom: 10px;">
			    <div class="cartit">{{item.name}}</div>
				<a-row>
					<a-col :span="8">
						<div class="c1">站上水位</div>
						<div class="cg">20cm</div>
					</a-col>
					<a-col :span="8">
						<div class="c1">站下水位</div>
						<div class="cb">19cm<sup>3</sup>/s</div>
					</a-col>
					<a-col :span="8">
						<div class="c1">开度</div>
						<div class="cb">20%<sup>3</sup>/s</div>
					</a-col>
				</a-row>
			</a-card>
		</a-col>
	</a-row>
</template>

<script>
	export default{
		data(){
			return{
				zhanzhaList:[
					{name:"牛桥港站闸"},
					{name:"斜路港站闸"},
					{name:"庙前港站闸"},
					{name:"金家庄北站闸"},
					{name:"小千灯浦站闸"},
					{name:"新开泾站闸"},
					{name:"泖泾港站闸"}
				]
			}
		}
	}
</script>

<style>
</style>
