<template>
    <div>
        <a-form :form="formAdd">
            <div style="display:none">
                <a-input v-decorator="['id']" v-show="false" />
            </div>

            <a-row justify="space-between" :gutter="16">
                <a-col :span="24">
                    <a-form-item label="工单标题">
                        <a-input v-decorator="['title', { rules: [{ required: true, message: '请输入工单标题' }]}]" placeholder="工单标题" :disabled="formAddOptions.viewMode" />
                    </a-form-item>
                </a-col>
                <a-col :span="24">
                    <a-form-item label="工单内容">
                        <a-textarea v-decorator="['info', { rules: [{ required: true, message: '请输入工单内容' }]}]" style="resize: none;height: 100px;" placeholder="工单内容" :disabled="formAddOptions.viewMode" />
                    </a-form-item>
                </a-col>
                <a-col :span="24">
                    <a-upload class='a'
                              :disabled="formAddOptions.viewMode"
                              :multiple="false"
                              list-type="picture-card"
                              :file-list="fileList"
                              @preview="filesPreview"
                              @change="filesChange"
                              :customRequest="customFilesUpload">
                        <div v-if="fileList.length < 8">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">
                                上传附件
                            </div>
                        </div>
                    </a-upload>
                </a-col>

                <a-col :span="12">
                    <a-form-item label="到期时间">
                        <a-date-picker style="width: 100%;" v-decorator="['clsx', { rules: [{ required: true, message: '请输入到期时间' }]}]" placeholder="到期时间" :disabled="formAddOptions.viewMode" />
                    </a-form-item>
                </a-col>
                <a-col :span="24">
                    <a-form-item label="派单说明">
                        <a-textarea style="resize: none;height: 100px;" :disabled="formAddOptions.viewMode" v-decorator="['sendContent', { rules: [{ required: false, message: '请输入派单说明' }]}]" placeholder="派单说明" />
                    </a-form-item>
                </a-col>
                <a-col :span="24" style="text-align:center;">
                    <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible" style="width:100px;">确定</a-button>
                    <a-button key="reset" type="" @click="resetFields" v-if="formAddOptions.submitVisible" style="margin-left:16px;width:100px;">重置</a-button>
                </a-col>
            </a-row>

        </a-form>

        <a-modal :visible="previewVisible" :footer="null" @cancel="filesCancelPreview">
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>

    </div>
</template>
<script>

    export default {
        data() {
            return {
                loading: '',
                action: '',
                previewVisible: false,
                previewImage: '',
                fileList: [],
                formAddOptions: {
                    visible: true,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
                queryParam: {
                    processid: this.$route.query.processid,
                    workbh: this.$route.query.workbh,
                },

            }
        },
        props: {
            'dataOptions': Object,
        },
        mounted() {
            
        },
        created() {
        },
        methods: {
            btnClick(text, item) {
                let _this = this;
                switch (text) {
                    case '新增':
                        this.openDlg()
                        break
                    case "修改":
                        this.openDlg(item);
                        break;
                    case "删除":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定要删除 " + item.qymc + " 吗?",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.del(item);
                            }
                        });
                        break;
                }
            },
            formAddSubmit() {
                this.formAdd.validateFields((err, values) => {
                    if (!err) {
                        values.files_bh = this.fileList
                        values.sendSource = "PC";
                        this.formAddOptions.submitLoading = true
                        this.$axios({
                            url: '/api/work/pd',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('派单成功')
                                    this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false
                                } else {
                                    console.log(res);
                                    throw new Error("服务器超时2");
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })
                    }
                })
            },
            resetFields() {
                this.formAdd.resetFields();
            },
            filesCancelPreview() {
                this.previewVisible = true
            },
            filesPreview(file) {
                location.href = file.url;
            },
            filesChange: function (file) {
                if (file.file.status === 'removed') {
                    this.fileList = file.fileList
                }
            },
            customFilesUpload(data) {
                const formData = new FormData()
                formData.append('file', data.file)
                this.saveFile(formData)
            },
            saveFile(formData) {
                this.$axios(
                    {
                        method: 'post',
                        url: this.$axios.defaults.fileURL + "/api/FileSet/Upload",
                        data: formData
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            //console.log(res);
                            var num = this.fileList.length
                            this.fileList.push(res.data.data)
                            this.uploadgetText('a', num, res.data.data);
                        }
                        else {
                            throw new Error("服务器器超时");
                        }
                    })
                    .catch((error) => {
                        this.$message.error(error.message);
                        console.log(error)
                    })
            },
            /**
             * @param {String} classname；upload的类名
             */
            editgetText(classname) {
                this.$nextTick(() => {
                    var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    //console.log(els)
                    for (var i = 0, len = els.length; i < len; i++) {
                        var type = els[i].textContent.split('.')[1]
                        if (type !== 'png' && type !== 'jpg') {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = els[i].textContent
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + type
                            }
                            cel.innerText = temptext
                            spanels[i].prepend(cel)
                        }
                    }
                })
            },
            /**
             * @param {String} classname；upload的类名
             * @param {Number} num；原有的已上传文件数量
             * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
             */
            uploadgetText(classname, num, response) {
                if (response.ext == ".jpg" || response.ext == ".png" || response.ext == ".gif" || response.ext == ".jpeg") {
                    return false;
                }
                this.$nextTick(() => {
                    //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    var tempNodeList = []
                    for (var i = 0; i < spanels.length - num; i++) {
                        // console.log(spanels[num+i])
                        tempNodeList.push(spanels[num + i])
                        for (var j = 0; j < tempNodeList.length; j++) {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = response.name
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + response.ext
                            }
                            cel.innerText = temptext
                            tempNodeList[j].prepend(cel)
                        }
                    }
                })
            },
        }
    }

</script>
<style scoped>
</style>