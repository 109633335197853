<template>
    <div>
        <h1>新增事项</h1>
        <p>{{msg}}</p>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                msg: "我是msbl/add组件"
            }
        }
    }
</script>