<template>
	<div class="content1">
		<a-card :bordered="false">
            <div class="bottom">

                <!-- <div class="bordtitle">站闸相关设备数统计</div> -->
                统计年份：<a-date-picker placeholder="请选择年"
                                    mode="year"
                                    format="YYYY"
                                    v-model="queryParam.years"
                                    :open="dataopen"
                                    @openChange="openChangeYear"
                                    @panelChange="panelChangeYear" style="width:175px;" />

                <div class="bottom-left">

                    <div class="table">
                        <div style="text-align:center;font-size:30px;">
                            <h1>维保次数统计（维保公司）</h1>
                        </div>
                        <table>
                            <tr>
                                <td>序号</td>
                                <td>维保公司</td>
                                <td>1月</td>
                                <td>2月</td>
                                <td>3月</td>
                                <td>4月</td>
                                <td>5月</td>
                                <td>6月</td>
                                <td>7月</td>
                                <td>8月</td>
                                <td>9月</td>
                                <td>10月</td>
                                <td>11月</td>
                                <td>12月</td>
                                <td>合计</td>
                            </tr>
                            <tr v-for="(item,index) in datasource" :key="index">
                                <td>{{index+1}}</td>
                                <td>{{item.name}}</td>
                                <td>{{item.y1}}</td>
                                <td>{{item.y2}}</td>
                                <td>{{item.y3}}</td>
                                <td>{{item.y4}}</td>
                                <td>{{item.y5}}</td>
                                <td>{{item.y6}}</td>
                                <td>{{item.y7}}</td>
                                <td>{{item.y8}}</td>
                                <td>{{item.y9}}</td>
                                <td>{{item.y10}}</td>
                                <td>{{item.y11}}</td>
                                <td>{{item.y12}}</td>
                                <td>{{item.zs}}</td>
                            </tr>
                            <tr>
                                <td colspan="2">合计</td>
                                <td v-for="(t,i) in total" :key="i">{{t}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
		</a-card>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dataopen: false, // 默认是否打开弹框
				year:'',
				queryParam:{
				  years:'',
				},
				datasource:[],
				total:[]
			}
		},
		created(){
			this.getdata()
		},
		methods: {
			// 点击选择框事件 弹出日期组件选择年
			openChangeYear(status) {
		      if (status) {
		        this.dataopen = true;
		      } else {
		        this.dataopen = false;
		      }
		   },
		   // 选择年之后 关闭弹框
		   panelChangeYear(value){
		       this.queryParam.years = value;
		       this.dataopen = false;
			   this.getdata(new Date(value).getFullYear())
		   },
			getdata(year=''){
				this.$axios({
					url:'/api/zzgl/GetWbcsByCompany?year='+year
				}).then(res=>{
					if(res.data.code==0){
						this.datasource=res.data.data
						var temp=[]
						for(var i=0;i<this.datasource.length;i++){
							var temp1=[]
							Object.keys(this.datasource[i]).forEach((key,ii)=>{
								if(ii>1){
									temp1.push(this.datasource[i][key])
								}
							})
							temp.push(temp1)
						}
						this.total=this.getArray(temp)
					}
				})
			},
			getArray(array){
				var temp=[]
				var a=new Array(array[0].length)
				for(var i=0,length=a.length;i<length;i++){
					a[i]=0
				}
				for(var i=0,length=array.length;i<length;i++){
					for(var j=0,lh=a.length;j<lh;j++){
						a[j]+=array[i][j]
					}
				}
				return a
			}
		}
	}
</script>

<style>
	.content1{
		/* position: absolute;
		top: 0px;
		right: 0px;
		left: 0px;
		width: auto;
		height: auto; */
		/* background: #f0f2f5; */
	}
	
	.middle>div{
		background: white;
		position: relative;
	}
	
	.table{
		box-sizing: border-box;
		padding-top: 20px;
	}
	.bottom-left table{
		border-collapse: collapse;
		width: 100%;
		height: 100%;
	}
	.bottom-left table tr td{
		border: 1px solid rgba(0,0,0,.1);
		font-size: 15px;
		box-sizing: border-box;
		padding: 16px;
		text-align: center;
	}
	.bottom-left table tr:nth-child(1) td{
		height: 54px;
		background: #fafafa;
	}

	.bordtitle{
		position: absolute;
		box-sizing: border-box;
		padding-left: 16px;
		width: 100%;
		left: 0;
		top: 0;
		font-size: 16px;
		height: 56px;
		line-height: 56px;
		border-bottom: 1px solid rgba(0,0,0,.1);
		z-index: 1;
	}
	
</style>
