<template>
    <div class="hello-ezuikit-js">
        <a-layout id="components-layout-demo-top-side">
            <a-layout style="padding: 0 0; background: #fff">
                <a-layout-sider width="200" style="background: #fff">
                    <a-menu mode="inline"
                            :default-selected-keys="['1']"
                            :default-open-keys="['sub1']"
                            style="height: 100%">
                        <a-sub-menu key="sub1">
                            <span slot="title"><a-icon type="user" />监控群组1</span>
                            <template v-for="video in videoArr">
                                <a-menu-item :key="video.key" @click="changeVideo(video)">
                                    {{video.name}}
                                </a-menu-item>

                            </template>
                        </a-sub-menu>
                    </a-menu>
                </a-layout-sider>
                <a-layout-content :style="{ padding: '0 24px', minHeight: '280px' }">
                    <div id="video-container" style="width:900px"></div>
                </a-layout-content>
            </a-layout>
        </a-layout>
    </div>
</template>

<script>
    import EZUIKit from "ezuikit-js";
    export default {
        data() {
            return {
                player:null,
                videoArr: [
                    { key: 1, name: "千灯浦", url: "ezopen://open.ys7.com/E37164994/1.live" },
                    { key: 2, name: "西分圩", url: "ezopen://RMHBVQ@open.ys7.com/E37165008/1.live" },
                    {
                        key: 3, name: "新开泾", url: "ezopen://open.ys7.com/E41421493/1.live"
                    },
                    {
                        key: 4, name: "金家港", url: "ezopen://SXIZCC@open.ys7.com/E74509333/1.live"
                    },
                    { key: 5, name: "点位5", url: "ezopen://open.ys7.com/E37164994/1.live" },
                ],
                options: {
                    autoplay: true,
                    id: "video-container",
                    // accessToken: "at.dvpjzhi0c00zy2kg9giiu9ywal0xh806-9dbgbc4n5m-1ofudp5-ayzhvk3nb",
					 accessToken: "",
                    url: "ezopen://open.ys7.com/E37164994/1.live",
                    template: "standard", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                    // 视频上方头部控件
                    //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                    //plugin: ['talk'],                       // 加载插件，talk-对讲
                    // 视频下方底部控件
                    // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
                    // audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
                    // openSoundCallBack: data => console.log("开启声音回调", data),
                    // closeSoundCallBack: data => console.log("关闭声音回调", data),
                    // startSaveCallBack: data => console.log("开始录像回调", data),
                    // stopSaveCallBack: data => console.log("录像回调", data),
                    // capturePictureCallBack: data => console.log("截图成功回调", data),
                    // fullScreenCallBack: data => console.log("全屏回调", data),
                    // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
                    width: 900,
                    height: 550
                }
            }
        },
        methods: {
            changeVideo(video) {
                this.player.opt.url = video.url;
                this.player.play();
            },
        },
        created() {
           
        },
        mounted() {
			
			var _that = this
			this.$axios({
			    url: '/api/zzgl/GetYsyToken',
			    method: 'get',
			   
			}).then(function (response) {
			        console.log(response.data.data);
				
			        if (response.data.data.code == 200) {
						
			            _that.options.accessToken = response.data.data.data.accessToken
						
						_that.options.url = _that.videoArr[0].url;
						_that.player = new EZUIKit.EZUIKitPlayer(_that.options);
						
					
						
			        }
			    }).catch(function (error) {
			        console.log(error)
			    })
			
           

        }
    };
</script>
