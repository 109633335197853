<template>
    <div>

    </div>
</template>
<script>
    export default {
        data() {
            return {
            }
        },
        components: {
        },
        created: function () {
            this.$router.push("/gcyxgl/shlzp/cameraViewList?BindGroupName=水葫芦检测点位")
        },
        methods: {
        }
    }
</script>
<style>
</style>