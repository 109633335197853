<template>
    <div id="components-form-demo-advanced-search">
        <a-modal v-model="formAddOptions.visible" title="新增" width="800px">
            <a-spin :spinning="formAddOptions.spinning">
                <a-form :form="formAdd" :label-col="{ span:8 }" :wrapper-col="{ span:16 }" labelAlign="left">
                    <a-form-item label="" style="display:none">
                        <a-input v-decorator="['bh']" />
                    </a-form-item>
                    
                    <a-row type="flex" justify="space-between">
                       
						<a-col :span="11">
						    <a-form-item label="场所名称">
						        <a-input v-decorator="['csmc', { rules: [{ required: true, message: '请输入场所名称' },{max:50,message:'最大长度为50'}]}]" placeholder="场所名称" :disabled="formAddOptions.viewMode" />
						    </a-form-item>
						</a-col>
						<a-col :span="11">
						    <a-form-item label="面积">
						        <a-input v-decorator="['mj', { rules: [{ required: true, message: '请输入面积' },{max:50,message:'最大长度为50'}]}]" placeholder="面积" :disabled="formAddOptions.viewMode" />
						    </a-form-item>
						</a-col>
						
                    </a-row>
                    <a-row type="flex" justify="space-between">
                        <a-col :span="11">
                            <a-form-item label="最多容纳人数">
                                <a-input v-decorator="['zdrnrs', { rules: [{ required: true, message: '请输入最多容纳人数' },{max:50,message:'最大长度为500'}]}]" placeholder="最多容纳人数" :disabled="formAddOptions.viewMode" />
                            </a-form-item>
                        </a-col>
						<a-col :span="11">
						    <a-form-item label="地址">
						        <a-input v-decorator="['dz', { rules: [{ required: true, message: '请输入地址' },{max:50,message:'最大长度为500'}]}]" placeholder="地址" :disabled="formAddOptions.viewMode" />
						    </a-form-item>
						</a-col>
                       
                    </a-row>
                    <a-row type="flex" justify="space-between">
                        <a-col :span="11">
                            <a-form-item label="经度">
                                <a-input v-decorator="[ 'jd',{ rules: [{ required: false, message: '请填写经度 ' }],},]" style="width: 100%" placeholder="经度" :disabled="formAddOptions.viewMode">
                                  
                                </a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :span="11">
                            <a-row>
                                <a-form-item label="纬度">
                                    <a-input v-decorator="['wd', { rules: [{ required: false, message: ' ' }],}]" placeholder="纬度" :disabled="formAddOptions.viewMode" />
                                </a-form-item>
                            </a-row>
                        </a-col>
                    </a-row>
                    <a-row :span="24">
                        <a-col >
                            <a-form-item label="备注" :label-col="{ span:10 }" :wrapper-col="{ span:24 }" >
                                <a-input type="textarea"  :rows="5" v-decorator="['bz']" style="width: 100%" placeholder="备注" :disabled="formAddOptions.viewMode">
                                </a-input>
                            </a-form-item>
                        </a-col>
                        
                    </a-row>
			     </a-form>
            </a-spin>
            <template slot="footer">
                <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
                <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
            </template>
        </a-modal>
        <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
            <a-row :gutter="24">
               

                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="场所名称" v-decorator="[`csmc`]"></a-input></a-form-item>
                </a-col>
				<a-col :span="6">
				    <a-form-item label=""><a-input placeholder="地址" v-decorator="[`dz`]"></a-input></a-form-item>
				</a-col>
                
            </a-row>

            <a-row :gutter="[24,12]">
                <a-col :span="6" :style="{ textAlign: 'left' }">
					<a-button  type='primary'   key="新增" @click="btnClick('新增',record)" style='display:inline'>新增</a-button>
                    <a-button type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button>
                  
                </a-col>
                <a-col :span="18" :style="{ textAlign: 'right' }">
					
                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                    </a>
                </a-col>
            </a-row>
        </a-form>

        <div class="search-result-list" :style="{ marginTop: '0px' }">
            <a-table :columns="columns"
                     :data-source="tableData"
                     :pagination="pagination"
                     :pageSize="20"
                     :total="50"
                     rowKey="id"
                     :loading="loading"
                     :scroll="scroll"
                     @change="pageCurrentChange"
                     position="bottom"
                     tableLayout="fixed">
             
				 <a slot="csmc" slot-scope="text,record" key="11" @click="openDlg(record,'view')">{{record.csmc}}</a>
               
				<span slot="action" slot-scope="text, record">
                  
                   <a-button  type='edit' size="small"  key="编辑" @click="btnClick('编辑',record)" style='margin-right:5px;display:inline'><a-icon type="edit" /></a-button>
					<a-button  type='delete' size="small"  key="删除" @click="btnClick('删除',record)" style='margin-right:5px;display:inline'><a-icon type="delete" /></a-button>
                </span>
                <span slot="headImgPath" slot-scope="text, record">
                    <img v-if="text" :src="baseUrl+text" style="height:50px;width:50px;" />
                </span>
              
            </a-table>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    //import { DesEncrypt } from '@/utils/Jscript'
    const loading = true
    const strWhere = 'del=0'
    const action = 'add'
    export default {
        data() {
            return {
                showexport: false,
                scroll: {
                    y: 900,
                    x: 900
                },
              
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                tableData: [],
                select_data: [],
                columns: [
                    {
                        title: '序号',
                        dataIndex: 'rownum',
                        width: 60,
                        key: 'rownum'
                    },
                    
                    {
                        title: '场所名称',
                        dataIndex: 'csmc',
                        key: 'csmc',
                        width: 200,
                        scopedSlots: { customRender: 'csmc' }
                    },
                    {
                        title: '面积',
                        dataIndex: 'mj',
                        width: 100,
                        key: 'mj'
                    },

                    {
                        title: '最多容纳人数',
                        dataIndex: 'zdrnrs',
                        width: 100,
                        key: 'zdrnrs'
                    },
                    {
                        title: '地址',
                        dataIndex: 'dz',
                        width: 100,
                        key: 'dz'
                    },
					{
					    title: '添加日期',
					    dataIndex: 'createTime',
					    width: 200,
					    key: 'createTime'
					},
                    {
                        title: '经度',
                        dataIndex: 'jd',
                        width: 100,
                        key: 'jd'
                    },
                    {
                        title: '纬度',
                        dataIndex: 'wd',
                        width: 100,
                        key: 'wd'
                    },
                    
                    {
                        title: '操作',
                        width: 170,
                        key: 'action',
                        fixed: 'right',
                        scopedSlots: { customRender: 'action' }
                    }
                ],
                loading,
                action,
                previewVisible: false,
                headImg_disabled: false,
                previewImage: '',
                fileList: [],
                imageUrl: '',//'http://58.211.49.230:8051/upload/2020/10/15/193321/%E5%9B%9B%E8%AE%AE%E4%B8%A4%E5%85%AC%E5%BC%80.png',
                imagePath: '',
                baseUrl:this.$axios.defaults.fileURL,
                strWhere,
                expand: false,
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
                pagination: {
                    total: 0,
                    pageSize: 15, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
                    showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: 'bottom'
                },
                web_decorator_qymc: ['townId', { rules: [{ required: true, message: '请选择区镇' }] }],
            }
        },
        mounted() {
            console.log(this.$route.meta);
            //将上面的按钮和表格内的按钮分出来
            for (let i = 0; i < this.$route.meta.buttons.length; i++) {
                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                }
                else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.init()
            this.actions()
            window.onresize = () => {
                this.init()
            }
            this.bindList();
            
        },
        methods: {
            moment,
            handleChange(info) {
                if (info.file.status === 'uploading') {
                    this.loading = true;
                    return;
                }
                if (info.file.status === 'done') {
                    this.getBase64(info.file.originFileObj, imageUrl => {
                        this.imageUrl = imageUrl;
                        this.loading = false;
                        console.log(info.file.originFileObj);
                    });
                }
            },
            getBase64(img, callback) {
                const reader = new FileReader();
                reader.addEventListener('load', () => callback(reader.result));
                reader.readAsDataURL(img);
            },
            btnClick(text, item) {
                let _this = this;
                switch (text) {
                    case '新增':
                        this.openDlg()
                        break
                    case "编辑":
                        this.openDlg(item);
                        break;
                    case "删除":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定要删除吗?",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.del(item);
                            }
                        });
                        break;
                }
            },
            image_remove() {
                this.imageUrl = "";
                this.imagePath = "";
            },
            confirm(record) {
                this.sh(record.id,2);
            },
            cancel(record) {
                this.sh(record.id,1);
            },
            
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
          
            bindList: async function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1
                 
                    this.strWhere = 'del=0 '
                    for (var obj in values) {
                        if (values[obj]) {
                            if (obj == "lx") { this.strWhere += " and " + obj + "='" + values[obj] + "'"; }
							
                            else { this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"; }
                        }
                    }
                    console.log(this.strWhere)
                    this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
                })
                this.loading = true

                this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere);
                this.strWhere = this.strWhere.data;
                await this.InitGrid()
            },
            InitGrid: function () {
                var _that = this
                _that.loading = true
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'fxwz_bncs',
                        page: _that.pagination.current,
                        limit: _that.pagination.pageSize,
                        // filesFields: 'bh',
                        strWhere: _that.strWhere
                    }
                })
                    .then(function (response) {
                        console.log(response)
                        _that.loading = false
                        if (response.status === 200) {
                            _that.tableData = response.data.data
                            _that.pagination.total = response.data.count
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            formSearchSubmit(e) {
                e.preventDefault()
                this.pagination.current = 1
                this.bindList()
            },
            formSearchReset() {
                this.formSearch.resetFields()
                this.strWhere = 'del=0'
                this.bindList()
            },
            toggle() {
                this.expand = !this.expand
                this.init()
            },
            pageCurrentChange(pagination) {
                this.pagination.current = pagination.current
                this.bindList()
            },
            del(record) {
                this.$axios({
                    url: '/api/Comon/deltag',
                    method: 'post',
                    data: {
                        tableName: 'fxwz_bncs',
                        field: 'id',
                        value: record.id,
                        source: 'web',
                        loginid: ''
                    }
                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info('删除成功');
                            this.bindList();
                        }
                        else {
                            throw new Error("服务器超时");
                        }
                    }
                    else {
                        throw new Error("服务器超时");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                })
            },
            openDlg: async function (record, action) {
                this.formAdd.resetFields()
                this.fileList = []
                this.imageUrl = "";
                this.imagePath = "";
                this.formAddOptions.submitVisible = true
                this.formAddOptions.viewMode = false
                this.headImg_disabled = false;
                if (record !== undefined) {
                    // 从后台取数据赋值
                    this.formAddOptions.spinning = true
                    var obj = await this.$jsc.DesEncrypt(this, 'id=zhangbiao' + record.id + 'zhangbiao');
                    obj = obj.data;
                    this.$axios
                        .get('/api/GetList/getListByTable?tableName=fxwz_bncs&strWhere=' + obj + '&filesFields=bh')
                        .then((response) => {
                            if (response.status == 200) {
                                if (response.data.code == 0) {
                                    this.formAddOptions.visible = true;
                                    var result = response.data.data;
                                    console.log(response.data);
                                    setTimeout(() => {
                                       
                                        this.formAdd.setFieldsValue(result[0])
                                        
                                        this.editgetText('a')
                                        this.formAddOptions.spinning = false
                                    }, 100);
                                }
                                else {
                                    this.$message.error(response.data.msg);
                                    this.formAddOptions.spinning = false;
                                }
                            } else {
                              
                            }
                        })
                        .catch(function (error) { // 请求失败处理
                            console.log(error)
                        })
                    if (action === 'view') { // 预览模式
                        this.formAddOptions.submitVisible = false
                        this.formAddOptions.viewMode = true
                        this.headImg_disabled = true;
                    }
                }
                else {
                    this.formAddOptions.visible = true;
                }
            },
            filesCancelPreview() {
                this.previewVisible = false
            },
            async filesPreview(file) {
               
                location.href = file.url
            },
            async filesPreview2(file) {
              
                location.href = file.url
            },
            filesChange: function (file) {
                if (file.file.status === 'removed') {
                    this.fileList = file.fileList
                }
            },
            customFilesUpload(data) {
                var _this = this;
                const formData = new FormData()
                formData.append('file', data.file)
                formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例

                this.$jsc.picCompress(data.file, 600, this.saveFile);
            },
         
            formAddSubmit() {
                this.formAdd.validateFields((err, values) => {
                   
                    if (!err) {
                        this.formAddOptions.submitLoading = true
                        values.fj_files = this.fileList
                        values.headImgPath = this.imagePath;
                        this.$axios({
                            url: '/api/Fxfh/EditbncsInfo',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('保存成功')
                                    this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false
                                    this.bindList()
                                } else {
                                    console.log(res);
                                    throw new Error("服务器超时2");
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })

                    }
                })
            },
            saveFile(formData) {
                var that = this;
                this.$axios(
                    {
                        method: 'post',
                        url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
                        data: formData
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            var num = that.fileList.length
                            that.fileList.push(res.data.data)
                            // that.uploadgetText('a', num, res.data.data);
                        }
                        else {
                            throw new Error("服务器器超时");
                        }
                    })
                    .catch((error) => {
                        that.$message.error(error.message);
                        console.log(error)
                    })
            },
                    
            init() {
                var that = this
                size()
               
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 270
                        }
                    })
                }

            },
            /**
             * @param {String} classname；upload的类名
             */
            editgetText(classname) {
                this.$nextTick(() => {
                    var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    //console.log(els)
                    for (var i = 0, len = els.length; i < len; i++) {
                        var type = els[i].textContent.split('.')[1]
                        if (type !== 'png' && type !== 'jpg') {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = els[i].textContent
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + type
                            }
                            cel.innerText = temptext
                            spanels[i].prepend(cel)
                        }
                    }
                })
            },
            /**
             * @param {String} classname；upload的类名
             * @param {Number} num；原有的已上传文件数量
             * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
             */
            uploadgetText(classname, num, response) {
                this.$nextTick(() => {
                    //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    var tempNodeList = []
                    for (var i = 0; i < spanels.length - num; i++) {
                        // console.log(spanels[num+i])
                        tempNodeList.push(spanels[num + i])
                        for (var j = 0; j < tempNodeList.length; j++) {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = response.name
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + response.ext
                            }
                            cel.innerText = temptext
                            tempNodeList[j].prepend(cel)
                        }
                    }
                })
            },
            actions() {
                // if (this.inlineButtons.length == 0) {
                //     this.columns.pop();
                // }
            }
        }
    }

    //function getBase64(file) {
    //    return new Promise((resolve, reject) => {
    //        const reader = new FileReader()
    //        reader.readAsDataURL(file)
    //        reader.onload = () => resolve(reader.result)
    //        reader.onerror = error => reject(error)
    //    })
    //}
</script>
<style>
    .ant-layout-footer {
        display: none;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
        padding: 20px 20px 0;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }
</style>
