<template>
	<div class="content">
		<a-card :bordered="false">
            <div class="bottom">

                <!-- <div class="bordtitle">站闸相关设备数统计</div> -->
                统计年份：<a-date-picker placeholder="请选择年"
                                    mode="year"
                                    format="YYYY"
                                    v-model="queryParam.years"
                                    :open="dataopen"
                                    @openChange="openChangeYear"
                                    @panelChange="panelChangeYear" style="width:175px;"/>

                <div class="bottom-left">

                    <div>
                        <div style="text-align:center;font-size:30px;">
                            <h1>检查次数、发现问题统计（网格员）</h1>
                        </div>
                        <table cellspacing="0" cellpadding="0" class="table">
                            <tr>
                                <td rowspan="2">序号</td>
                                <td rowspan="2">网格员</td>
                                <td colspan="2">1月</td>
                                <td colspan="2">2月</td>
                                <td colspan="2">3月</td>
                                <td colspan="2">4月</td>
                                <td colspan="2">5月</td>
                                <td colspan="2">6月</td>
                                <td colspan="2">7月</td>
                                <td colspan="2">8月</td>
                                <td colspan="2">9月</td>
                                <td colspan="2">10月</td>
                                <td colspan="2">11月</td>
                                <td colspan="2">12月</td>
                                <td colspan="2">合计</td>
                            </tr>
                            <tr>


                                <td>检查工程数</td>
                                <td>有问题数</td>

                                <td>检查工程数</td>
                                <td>有问题数</td>

                                <td>检查工程数</td>
                                <td>有问题数</td>

                                <td>检查工程数</td>
                                <td>有问题数</td>

                                <td>检查工程数</td>
                                <td>有问题数</td>

                                <td>检查工程数</td>
                                <td>有问题数</td>

                                <td>检查工程数</td>
                                <td>有问题数</td>

                                <td>检查工程数</td>
                                <td>有问题数</td>

                                <td>检查工程数</td>
                                <td>有问题数</td>

                                <td>检查工程数</td>
                                <td>有问题数</td>

                                <td>检查工程数</td>
                                <td>有问题数</td>

                                <td>检查工程数</td>
                                <td>有问题数</td>

                                <td>检查工程数</td>
                                <td>有问题数</td>
                            </tr>
                            <tr v-for="(item,index) in datasource" :key="index">
                                <td>{{index}}</td>
                                <td>{{item.name}}</td>
                                
                                <td>{{item.y1_jcpros}}</td>
                                <td>{{item.y1_errcs}}</td>

                                <td>{{item.y2_jcpros}}</td>
                                <td>{{item.y2_errcs}}</td>

                                <td>{{item.y3_jcpros}}</td>
                                <td>{{item.y3_errcs}}</td>

                                <td>{{item.y4_jcpros}}</td>
                                <td>{{item.y4_errcs}}</td>

                                <td>{{item.y5_jcpros}}</td>
                                <td>{{item.y5_errcs}}</td>

                                <td>{{item.y6_jcpros}}</td>
                                <td>{{item.y6_errcs}}</td>

                                <td>{{item.y7_jcpros}}</td>
                                <td>{{item.y7_errcs}}</td>

                                <td>{{item.y8_jcpros}}</td>
                                <td>{{item.y8_errcs}}</td>

                                <td>{{item.y9_jcpros}}</td>
                                <td>{{item.y9_errcs}}</td>

                                <td>{{item.y10_jcpros}}</td>
                                <td>{{item.y10_errcs}}</td>

                                <td>{{item.y11_jcpros}}</td>
                                <td>{{item.y11_errcs}}</td>

                                <td>{{item.y12_jcpros}}</td>
                                <td>{{item.y12_errcs}}</td>

                                <td>{{item.z_jcpros}}</td>
                                <td>{{item.z_errcs}}</td>
                            </tr>
                            <tr>
                                <td colspan="2">合计</td>
                                <td v-for="(t,i) in total" :key="i">{{t}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
		</a-card>
	</div>
</template>

<script>
	export default {
		data() {
			return {
					dataopen: false, // 默认是否打开弹框
					year:'',
					queryParam:{
					  years:'',
					},
					datasource:[],
					total:[]
			}
		},
		created(){
			this.getdata()
		},
		 methods: {
		 	// 点击选择框事件 弹出日期组件选择年
		 	openChangeYear(status) {
		        if (status) {
		          this.dataopen = true;
		        } else {
		          this.dataopen = false;
		        }
		    },
		    // 选择年之后 关闭弹框
		    panelChangeYear(value){
		        this.queryParam.years = value;
		        this.dataopen = false;
				this.getdata(new Date(value).getFullYear())
		    },
			getdata(year=''){
				this.$axios({
					url:'/api/zzgl/GetJccsByWgy?year='+year
				}).then(res=>{
					if(res.data.code==0){
						this.datasource=res.data.data
						var temp=[]
						for(var i=0;i<this.datasource.length;i++){
							var temp1=[]
							Object.keys(this.datasource[i]).forEach((key,ii)=>{
								if(ii>0){
									temp1.push(this.datasource[i][key])
								}
							})
							temp.push(temp1)
						}
						this.total=this.getArray(temp)
					}
				})
			},
			/*  */
			getArray(array){
				var temp=[]
				var a=new Array(array[0].length)
				for(var i=0,length=a.length;i<length;i++){
					a[i]=0
				}
				for(var i=0,length=array.length;i<length;i++){
					for(var j=0,lh=a.length;j<lh;j++){
						a[j]+=array[i][j]
					}
				}
				return a
			}
		 }
	}
</script>

<style scoped>
	.content{
		/* position: absolute;
		top: 0px;
		right: 0px;
		left: 0px;
		width: auto;
		height: auto;
		background: #f0f2f5; */
	}
	
	.middle>div{
		background: white;
		position: relative;
	}
	/**/
	.table{
		width: 3000px;
		box-sizing: border-box;
		padding-top: 20px;
	}
	/*.bottom-left table{
		border-collapse: collapse;
		width: 100%;
		height: 100%;
	}*/
	.bottom-left table tr td{
		border: 1px solid rgba(0,0,0,.1);
		font-size: 15px;
		box-sizing: border-box;
		padding: 5px;
		text-align: center;
	}
	/*.bottom-left table tr:nth-child(1) td{
		height: 54px;
		background: #fafafa;
	}*/

	.bordtitle{
		position: absolute;
		box-sizing: border-box;
		padding-left: 16px;
		width: 100%;
		left: 0;
		top: 0;
		font-size: 16px;
		height: 56px;
		line-height: 56px;
		border-bottom: 1px solid rgba(0,0,0,.1);
		z-index: 1;
	}
	
</style>

