import AMapLoader from '@amap/amap-jsapi-loader'

const plugins = []
export const loadAMap = () => {
    return new Promise(resolve => {
        AMapLoader.load({
            key: '3d202c2d865a22fe9d4f8ca3184cd556',
            version: '2.0', 
            plugins: plugins.map(plugin => `AMap.${plugin}`)
        })
            .then(AMap => resolve(AMap))
            .catch(e => console.log('地图加载问题 :>> ', e))
    })
}