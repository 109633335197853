<template>
    <div>
        <work ref="work" :dataOptions="work_Options"></work>
        <a-form :form="formSearch"
                @submit="formSearchSubmit"
                :label-col="{ span: 0 }"
                :wrapper-col="{ span: 24 }">
            <a-row :gutter="24">
                <a-col :span="6">
                    <a-form-item label="">
                        <a-input placeholder="工单标题" v-decorator="['title']" />
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label="">
                        <a-range-picker :placeholder="['到期时限start','到期时限end']" v-decorator="['clsx_qj']" style="width:100%" />
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label="">
                        <a-input placeholder="办理人" v-decorator="['zbdw']" />
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label="">
                        <a-select placeholder="工单状态" v-decorator="['state']">
                            <a-select-option value="0">派单中</a-select-option>
                            <a-select-option value="1">正在办理</a-select-option>
                            <a-select-option value="2">作废</a-select-option>
                            <a-select-option value="10">已办结</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="[24,12]">
                <a-col :span="6" :style="{ textAlign: 'left' }">
                    <a-button type='primary' @click="btnClick('新建工单')" style='margin-right: 5px'>新建工单</a-button>
                    <!-- primary/waring/danger/green -->
                </a-col>
                <a-col :span="18" :style="{ textAlign: 'right' }">
                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    <!--<a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                    {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                </a>-->
                </a-col>
            </a-row>
        </a-form>

        <div class="search-result-list" :style="{ marginTop: '0px' }">
            <a-table :columns="columns"
                     :data-source="tableData"
                     :pagination="pagination"
                     :pageSize="10"
                     :total="50"
                     rowKey="id"
                     :loading="loading"
                     :scroll="scroll"
                     @change="pageCurrentChange"
                     position="bottom"
                     tableLayout="fixed">

                <span slot="stateTxt" slot-scope="text, record">
                    <a-tag color="pink" v-if="text==0">派单中</a-tag>
                    <a-tag color="purple" v-if="text==1">办理中</a-tag>
                    <a-tag color="orange" v-if="text==10">已办结</a-tag>
                </span>
                <span slot="titleTxt" slot-scope="text, record">
                    <a @click="workView(record)">{{text}}</a>
                </span>
                <span slot="clsxTxt" slot-scope="text, record">
                    {{moment(text).format("L")}}
                </span>
                <span slot="action" slot-scope="text, record">
                    <a-button v-if="record.state!=10" type='' size="small" @click="btnClick('编辑',record)" style='margin-right: 5px'><a-icon type="edit" /></a-button>
                    <a-button v-if="record.state!=10" type='danger' size="small" @click="btnClick('删除',record)" style='margin-right: 5px'><a-icon type="delete" /></a-button>
                </span>

            </a-table>
        </div>

    </div>
</template>
<script>
    import moment from 'moment';
    import work from './work'
    export default {
        data() {
            return {
                monthFormat: 'YYYY/MM',
                showexport: false,
                scroll: {
                    x: 800,
                },
                loading: false,
                check_ywba: true,
                opration: "",
                buttons: [],
                inlineButtons: [],
                tableData: [],
                columns: [
                    {
                        title: "序号",
                        dataIndex: "rnn",
                        width: 70,
                        key: "rnn",
                    },
                    {
                        title: "工单标题",
                        dataIndex: "title",
                        width: 300,
                        key: "title",
                        scopedSlots: { customRender: "titleTxt" },
                        ellipsis: true,
                    },
                    {
                        title: "内容简介",
                        dataIndex: "info",
                        width: 300,
                        key: "info",
                        ellipsis: true,
                    },
                    {
                        title: "到期时限",
                        dataIndex: "clsx",
                        width: 150,
                        key: "clsx",
                        scopedSlots: { customRender: "clsxTxt" },
                    },
                    {
                        title: "当前步骤",
                        dataIndex: "currentStep",
                        width: 100,
                        key: "currentStep",
                    },

                    {
                        title: "派单人",
                        dataIndex: "createUser",
                        width: 120,
                        key: "createUser",
                    },
                    {
                        title: "派单时间",
                        dataIndex: "posttime",
                        width: 180,
                        key: "posttime",
                    },
                    {
                        title: "办理人",
                        dataIndex: "zbdw",
                        width: 150,
                        key: "zbdw",
                    },
                    {
                        title: "工单状态",
                        dataIndex: "state",
                        width: 150,
                        key: "state",
                        scopedSlots: { customRender: "stateTxt" },
                    },
                    {
                        title: '操作',
                        key: 'action',
                        fixed: 'right',
                        scopedSlots: { customRender: 'action' }
                    }
                ],
                TableListSearchModel: {
                    pageIndex: 1,
                    pageSize: 20,
                    fields: "*",
                    where: {
                    }
                },
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false,
                    title: "新增",
                },
                queryParam: {
                    kind: this.$route.query.kind
                },
                work_Options: {
                    bh: "",
                    id: "",
                },
                expand: false,
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: "advanced_search" }),
                pagination: {
                    total: 0,
                    pageSize: 10, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "50", "100"], // 每页中显示的数据
                    showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: "bottom",
                },
            };
        },
        mounted() {
            this.formSearch.setFieldsValue({ kind: this.queryParam.kind });
            this.bindList();
        },
        watch: {
            '$route.query.kind'(newVal, oldVal) {
                this.formSearch.setFieldsValue({ "kind": newVal });
                this.queryParam.kind = newVal;
                this.bindList();
            }
        },
        components: {
            work,
        },
        created() {
            //this.TableListSearchModel.where.kind = this.$route.query.kind;
            //将上面的按钮和表格内的按钮分出来

            for (let i = 0; i < this.$route.meta.buttons.length; i++) {
                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                } else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.init();
            this.actions();

            window.onresize = () => {
                this.init();
            };

        },
        methods: {
            workView: function (record) {
                this.$router.push("workView?workbh=" + record.bh);
            },
            moment,
            bindList: function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1;
                    this.TableListSearchModel.where = {};
                    this.TableListSearchModel.where.createUserLoginid = this.$User.user.userInfo.loginid;
                    for (var obj in values) {
                        if (values[obj]) {
                            if (obj == "clsx") {
                            } else {
                                this.TableListSearchModel.where[obj] = values[obj];
                            }
                        }
                    }
                });
                this.loading = true;
                this.InitGrid();
            },
            InitGrid: function () {
                this.loading = true;
                this.$axios({
                    url: "/api/work/getList_v_commonWork",
                    method: "post",
                    data: this.TableListSearchModel,

                }).then((res) => {
                    console.log(res);
                    this.loading = false;
                    if (res.status === 200) {
                        this.tableData = res.data.data.list;
                        this.pagination.total = res.data.data.total;
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            btnClick(text, item) {
                let _this = this;
                switch (text) {
                    case "查看":
                        this.views(item);
                        break;
                    case "编辑":
                        this.work_Options.bh = item.bh;
                        this.$refs.work.open();
                        break;
                    case "新建工单":
                        this.work_Options.bh = "";
                        this.$refs.work.open();
                        break;
                    case "导出":
                        this.$confirm({
                            title: '操作确认',
                            content: "是否导出?",
                            okText: '是',
                            cancelText: '否',
                            onOk() {
                                _this.dc();
                            }
                        });
                        break;
                    case "删除":
                        this.$confirm({
                            title: '操作确认',
                            content: "是否删除《" + item.title + "》吗?",
                            okText: '是',
                            cancelText: '否',
                            onOk() {
                                _this.del(item);
                            }
                        });
                        break;
                }
            },
            formAddSubmit() {
                this.$refs.work.formAddSubmit();
                this.formAddOptions.visible = false;
            },
            formSearchSubmit(e) {
                console.log(e);
                e.preventDefault();
                this.pagination.current = 1;
                this.bindList();
            },
            formSearchReset() {
                this.formSearch.resetFields();
                this.bindList();
            },
            toggle() {
                this.expand = !this.expand;
                this.init();
            },
            pageCurrentChange(pagination) {
                console.log(pagination);
                //return;
                this.pagination.current = pagination.current;
                this.bindList();
            },
            del(record) {
                this.$axios({
                    url: "/api/work/delWork?bh=" + record.bh,
                    method: "get",
                }).then((res) => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info("删除成功");
                            this.bindList();
                        } else {
                            throw new Error("服务器超时");
                        }
                    } else {
                        throw new Error("服务器超时");
                    }
                })
                    .catch((error) => {
                        // 请求失败处理
                        this.$message.error(error.message);
                    });
            },

            init() {
                var that = this;
                size();
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight;
                    that.$nextTick(() => {
                        var el = document.querySelector(
                            "#components-form-demo-advanced-search>form"
                        );
                        if (el) {
                            that.scroll.y =
                                innerHeight - getComputedStyle(el).height.split("p")[0] - 270;
                        }
                    });
                }
            },
            actions() {
                if (this.inlineButtons.length == 0) {
                    //this.columns.pop();
                }
            },
        },
    };

</script>
<style scoped>
    .ant-layout-footer {
        display: none;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }
</style>