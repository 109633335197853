<template>
    <div>
        <!-- 条件搜索 -->
        <a-form :form="form" layout="inline" style="marginBottom:15px;">
            <a-form-item>
                <a-input v-decorator="['Name']" placeholder="堤防名称" />
            </a-form-item>

            <a-form-item>
                <a-input v-decorator="['UnitedPolder']" v-if="!town" placeholder="联圩" />
            </a-form-item>

            <a-form-item>
                <a-input v-decorator="['RLName']" placeholder="河湖" />
            </a-form-item>

            <a-form-item>
                <a-input v-decorator="['Side']" placeholder="岸别" />
            </a-form-item>

            <a-form-item>
                <a-select 
                    v-decorator="['Type']"
                    placeholder="分类"
                    style="width: 174px !important;" >
                    <a-select-option value="圩堤">圩堤</a-select-option>
                    <a-select-option value="河堤">河堤</a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item>
                <a-space>
                    <a-button type="primary" icon="search" @click="operForm('search')">搜索</a-button>
                    <a-button icon="redo" @click="operForm('reset')" >重置</a-button>
                </a-space>
            </a-form-item>
        </a-form>

        <!-- 列表 -->
        <a-table
            :columns="columns" 
            :data-source="dataSource" 
            :rowKey="record => record.rnn"
            :loading="loading"
            :scroll="scroll"
            :pagination="pagination"
            @change="changePage">

            <template slot="name" slot-scope="name, record" >
                <a href="javascript:;" @click="checkRecord(record)">{{ name }}</a>
            </template>

            <template slot="action" slot-scope="record">
                <a-button icon="file-search" type="primary" @click="checkRecord(record)" />
            </template>

        </a-table>

        <!-- 详情 -->
        <a-drawer 
            width="480" 
            placement="right" 
            :closable="false" 
            :visible="showDrawer"
            @close="showDrawer=false">

            <a-descriptions 
                :title="record.Name" 
                :column="2"  
                bordered 
                size="small">

                <a-descriptions-item label="类别">
                    {{ record.Type }}
                </a-descriptions-item>

                <a-descriptions-item label="等级">
                    {{ record.Grade  }}
                </a-descriptions-item>

                <a-descriptions-item label="位置" :span="2">
                    联圩：{{ record.UnitedPolder }}
                    <br/>
                    河湖：{{ record.RLName }}
                    <br/>
                    岸别：{{ record.Side }}
                </a-descriptions-item>

                <a-descriptions-item label="参数" :span="2">
                    长度(km)：{{ record.Length }}
                    <br/>
                    堤顶宽度：{{ record.Width }}
                    <br/>
                    堤顶高程：{{ record.Elevation }}
                    <br/>
                    迎水坡比：{{ record.OSRatio }}
                    <br/>
                    背水坡比：{{ record.BSRatio }}
                    <br/>
                    防汛道路：{{ record.FCRoad }}
                    <br/>
                    建设/改造时间：{{ record.ConstructDate }}
                    <br/>
                    险峰险段描述：{{ record.DangerDescription }}
                    <br/>
                    材质：{{ record.MaterialQuality }}
                    <br/>
                    堤防编码：{{ record.Encode }}
                    <br/>
                    堤防起点：{{ record.SLocation }}
                    <br/>
                    堤防止点：{{ record.ELocation }}
                </a-descriptions-item>

                <a-descriptions-item label="备注" :span="2">
                    {{ record.bz }}
                </a-descriptions-item>
                
                <a-descriptions-item label="备注2" :span="2">
                    {{ record.备注2 }}
                </a-descriptions-item>
                        
            </a-descriptions>

        </a-drawer>

    </div>
</template>

<script>
    // 表格行头字段
    const columns = Object.freeze([
        {
            title: '序号',
            dataIndex: 'rnn',
            width: 80,
            align: 'center'
        },
        {
            title: '类别',
            dataIndex: 'Type',
            width: 80
        },
        {
            title: '堤防名称',
            dataIndex: 'Name',
            width: 150,
            ellipsis: true,
            scopedSlots: { customRender: 'name' }
        },
        {
            title: '联圩',
            dataIndex: 'UnitedPolder',
            align: 'center',
            width: 120,
            ellipsis: true
        },
        {
            title: '河湖',
            dataIndex: 'RLName',
            width: 120,
            ellipsis: true
        },
        {
            title: '岸别',
            dataIndex: 'Side',
            width: 180,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'bz',
            ellipsis: true
        },
        {
            title: '详情',
            key: 'detail',
            fixed: 'right',
            width: 80,
            scopedSlots: { customRender: 'action' },
        }
    ])

    export default {
        data () {
            return {
                // form
                form: this.$form.createForm(this, { name: 'formSearch' }),
                // table
                dataSource: [],
                columns,
                scroll: { 
                    x: '120%', 
                    y: 0,
                    scrollToFirstRowOnChange: true
                },
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                    showTotal: total => `总共${total}条`
                },
                loading: true,
                // drawer
                record: {},
                showDrawer: false
            }
        },

        computed: {
            town () {
                const { town, role } = this.$User.user.userInfo
                console.log(this.$User.user.userInfo);
                return role > 810 ? '' : town
            }
        },

        methods: {
            operForm (action) {
                const { isFieldsTouched,  resetFields} = this.form

                if (!isFieldsTouched()) return
                if (action === 'reset') {
                    resetFields()
                }
                this.pagination.current = 1
                this.getTableData()
            },
            calcScrollY () {
                this.scroll.y = document.body.offsetHeight - 342
            },
            checkRecord (record) {
                this.record = record
                this.showDrawer = true
            },
            changePage (page) {
                const { current } = page
                this.pagination.current = current
                this.getTableData()
            },
            getTableData () {
                this.loading = true
                const { current, pageSize } = this.pagination
                const { getFieldsValue } = this.form
                this.$axios({
                    url: '/api/GetList/get_v_base_dfxx_gr_List',
                    method: 'post',
                    data: {
                        pageIndex: current,
                        pageSize,
                        where: {
                            ...getFieldsValue(),
                            GSD: this.town
                        }
                    }
                })
                    .then(res => {
                        if (res.status !== 200 || res.data.code !== 0) return 

                        const { list, total } = res.data.data
                        this.dataSource = list
                        this.pagination.total = total

                    })
                    .catch(err => err)
                    .finally(() => this.loading = false)
            }
        },

        created () {
            this.getTableData()
        },
        mounted () {
            this.calcScrollY()
            window.addEventListener('resize', this._.debounce(this.calcScrollY, 150))
            this.$once('hook:beforeDestroy', () => window.removeEventListener('resize', this.calcScrollY))
        },
    }
</script>
