<template>
    <div id="components-form-demo-advanced-search">

        <div style="text-align:center;">
            <span style="font-size:25px;font-weight:bold;">{{this.$route.query.titles}}</span>
        </div>
        <div>
            <div>
                <a-button type='normal' @click="back" style='margin: 5px'>返回</a-button><span style="color:red;">注：双击单元格可编辑内容</span>
            </div>
            <table width="100%">
                <tr v-if="index==0" v-for="(item,index) in tableData" style="background:#fafafa;height:57px;" class="trCss">
                    <td class="wz_td">{{item.name}}</td>
                    <td v-for="item2 in item.value">{{item2.wzmc}}</td>
                </tr>
                <tr v-if="index>0" v-for="(item,index) in tableData" class="trCss">
                    <td>{{item.name}}</td>
                    <td class="wz_td" v-for="(item2,index2) in item.value" @dblclick="editInput('a'+index+index2,item2)" style="cursor:pointer;">
                        <span :id="'a'+index+index2+'1'">{{item2.wzsl}}</span>
                        <input :id="'a'+index+index2" class="ant-input1" style="display:none;" @blur="input_onBlur('a'+index+index2,item2)" />
                    </td>
                </tr>
            </table>
        </div>

    </div>
</template>
<script>

    const loading = true
    const strWhere = 'del=0'
    const action = 'add'
    export default {
        data() {
            return {
                showexport: false,
                scroll: {
                },
                view_fields: {
                    viewModel: false,
                    title: '检查项',
                    data: []
                },
                opration: '',
                townData: [],
                zzData: [],
                xmlxData: [],
                qz_ifshow: false,
                qzLoginid: '',
                strWhere2: '',
                isOpen: 0,
                date_week: false,
                date_month: false,
                switch_isbool:true,
                N_max: 10000,
                town_zzmc: '',
                //actionList: {
                //    add: false,
                //    remove: false,
                //    edit: false
                //},
                teamData: [],
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                tableData: [],
                loading,
                action,
                previewVisible: false,
                previewImage: '',
                fileList: [],
                strWhere,
                expand: false,
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false,
                    dateVisible: false,
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
                pagination: {
                    total: 0,
                    pageSize: 10, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
                    showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: 'bottom'
                }
            }
        },
        mounted() {
            //将上面的按钮和表格内的按钮分出来
            console.log(this.$route.meta.buttons);
            for (let i = 0; i < this.$route.meta.buttons.length; i++) {
                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                }
                else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.init()
            this.actions()
            window.onresize = () => {
                this.init()
            }
            this.bindList();
        },
        created() {
        },
        methods: {
            editInput(id, item) {
                //console.log(item.wzsl); return;
                document.getElementById(id+'1').style.display = "none";
                document.getElementById(id).style.display = "block";
                document.getElementById(id).focus();
                document.getElementById(id).value = item.wzsl;
            },
            input_onBlur(id, item) {
                var _this = this;
                item.wzsl = document.getElementById(id).value;
                document.getElementById(id).style.display = "none";
                document.getElementById(id + '1').style.display = "block";
                this.$axios({
                    url: '/api/fxjc/EditWzcbxx',
                    method: 'post',
                    data: item
                    //params: {
                    //    wzid: item.wzid,
                    //    wzmc: item.wzmc,
                    //    unit: item.unit,
                    //    townId: item.townId,
                    //    town: item.town,
                    //    planbh: item.planbh,
                    //    wzsl: item.wzsl,
                    //}
                })
                    .then(function (response) {
                        console.log(response.data.data); //return; 
                        if (response.status === 200) {
                            _this.bindList();
                        }
                    })
                    .catch(function (error) {
                        console.log("服务器异常")
                    })
            },
            bindList: async function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1
                    // console.log(values)
                    this.strWhere = 'del=0 '
                    for (var obj in values) {
                        if (values[obj]) {
                            this.strWhere += " and " + obj + " like '%" + values[obj] + "%'"
                        }
                    }
                    this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
                    console.log(this.strWhere);
                })
                this.loading = true

                this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere);
                this.strWhere = this.strWhere.data;
                await this.InitGrid()
            },
            InitGrid: function () {
                var _that = this
                _that.loading = true
                console.log(_that.strWhere);
                this.$axios({
                    url: '/api/fxjc/GetWzPlan?planbh='+this.$route.query.planbh,
                    method: 'get',
                })
                    .then(function (response) {
                        console.log(response.data.data); //return; 
                        _that.loading = false
                        if (response.status === 200) {
                            _that.tableData = response.data.data
                            _that.pagination.total = response.data.count
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            onRangePickerChange(date, dateString) {
                this.StartTime = dateString[0];
                this.EndTime = dateString[1];
            },
            onShowSizeChange(current, pageSize) {
                console.log(current, pageSize);
                return;
            },
            formSearchSubmit(e) {
                e.preventDefault()
                this.pagination.current = 1
                this.bindList()
            },
            formSearchReset() {
                this.formSearch.resetFields()
                this.strWhere = 'del=0'
                this.bindList()
            },
            toggle() {
                this.expand = !this.expand
                this.init()
            },
            pageCurrentChange(pagination) {
                console.log(pagination);
                //return;
                this.pagination.current = pagination.current
                this.bindList()
            },
            del(record) {
                this.$axios({
                    url: '/api/Comon/deltag',
                    method: 'post',
                    data: {
                        tableName: 'zhsw_xcgl_yaxx',
                        field: 'id',
                        value: record.id,
                        source: 'web',
                        loginid: ''
                    }
                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info('删除成功');
                            this.bindList();
                        }
                        else {
                            throw new Error("服务器超时");
                        }
                    }
                    else {
                        throw new Error("服务器超时");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                })
            },
            openDlg: async function (record, action) {
                var _this = this;
                this.formAdd.resetFields()
                this.fileList = []
                this.formAddOptions.submitVisible = true
                this.formAddOptions.viewMode = false
                if (record !== undefined) {
                    // 从后台取数据赋值
                    this.formAddOptions.spinning = true
                    var obj = await this.$jsc.DesEncrypt(this, 'id=zhangbiao' + record.id + 'zhangbiao');
                    obj = obj.data;
                    this.$axios
                        .get('/api/GetList/getListByTable?tableName=v_zhsw_xcgl_yaxx&strWhere=' + obj + '&filesFields=bh')
                        .then((response) => {
                            if (response.status == 200) {
                                if (response.data.code == 0) {
                                    this.formAddOptions.visible = true;
                                    var result = response.data.data;
                                    console.log(response.data);
                                    this.function_jcpl(result[0].jg_unit.toString());
                                    setTimeout(() => {
                                        this.formAdd.setFieldsValue({
                                            id: result[0].id,
                                            title: result[0].title,
                                            townId: result[0].townId,
                                            jg_unit: result[0].jg_unit.toString(),
                                            zxsj: result[0].zxsj.toString(),
                                            jg_num: result[0].jg_num,
                                            teamId: result[0].teamId,
                                            startTime_y: parseInt(result[0].startTime.split('-')[0]),
                                            startTime_r: parseInt(result[0].startTime.split('-')[1]),
                                            endTime_y: parseInt(result[0].endTime.split('-')[0]),
                                            endTime_r: parseInt(result[0].endTime.split('-')[1]),
                                        });
                                        this.isOpen = result[0].isOpen == true ? 1 : 0;
                                        this.editgetText('a')
                                        this.formAddOptions.spinning = false
                                    }, 100);
                                }
                                else {
                                    this.$message.error(response.data.msg);
                                    this.formAddOptions.spinning = false;
                                }
                            } else {
                                // vue.prototype.$message.info('This is an error message');
                            }
                        })
                        .catch(function (error) { // 请求失败处理
                            console.log(error)
                        })
                    if (action === 'view') { // 预览模式
                        this.formAddOptions.submitVisible = false
                        this.formAddOptions.viewMode = true
                    }
                }
                else {
                    this.formAddOptions.visible = true;
                }
            },
            formAddSubmit() {
                this.formAdd.validateFields((err, values) => {
                    if (!err) {
                        //if (values.startTime_y < 10) { values.startTime_y = "0" + values.startTime_y; }
                        //if (values.startTime_r < 10) { values.startTime_r = "0" + values.startTime_r; }
                        //if (values.endTime_y < 10) { values.endTime_y = "0" + values.endTime_y; }
                        //if (values.endTime_r < 10) { values.endTime_r = "0" + values.endTime_r; }
                        values["startTime"] = values.startTime_y + "-" + values.startTime_r;
                        values["endTime"] = values.endTime_y + "-" + values.endTime_r;
                        values.isOpen = this.isOpen;
                        //console.log(values); return;
                        this.formAddOptions.submitLoading = true
                        this.$axios({
                            url: '/api/zzgl/EditXcYa',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('保存成功')
                                    this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false
                                    this.formAddOptions.dateVisible = false
                                    this.bindList()
                                } else {
                                    console.log(res);
                                    this.$message.info(res.data.msg)
                                    //throw new Error(res.data.msg);
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })
                    }
                })
            },
            back() {
                window.history.go(-1);
            },
            filesCancelPreview() {
                this.previewVisible = false
            },
            async filesPreview(file) {
                //console.log(file.ext)
                // if (!file.url && !file.preview) {
                //   file.preview = await getBase64(file.originFileObj)
                // }
                // this.previewImage = file.url || file.preview
                // this.previewVisible = true
                location.href = file.url
            },
            filesChange: function (file) {
                if (file.file.status === 'removed') {
                    this.fileList = file.fileList
                }
            },
            customFilesUpload(data) {
                const formData = new FormData()
                formData.append('file', data.file)
                //formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
                this.saveFile(formData)
            },
            saveFile(formData) {
                this.$axios(
                    {
                        method: 'post',
                        url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
                        data: formData
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            var num = this.fileList.length
                            this.fileList.push(res.data.data)
                            this.uploadgetText('a', num, res.data);
                        }
                        else {
                            throw new Error("服务器器超时");
                        }
                    })
                    .catch((error) => {
                        this.$message.error(error.message);
                        console.log(error)
                    })
            },
            init() {
                var that = this
                size()
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 270
                        }
                    })
                }
            },
            /**
             * @param {String} classname；upload的类名
             */
            editgetText(classname) {
                this.$nextTick(() => {
                    var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    //console.log(els)
                    for (var i = 0, len = els.length; i < len; i++) {
                        var type = els[i].textContent.split('.')[1]
                        if (type !== 'png' && type !== 'jpg') {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = els[i].textContent
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + type
                            }
                            cel.innerText = temptext
                            spanels[i].prepend(cel)
                        }
                    }
                })
            },
            /**
             * @param {String} classname；upload的类名
             * @param {Number} num；原有的已上传文件数量
             * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
             */
            uploadgetText(classname, num, response) {
                this.$nextTick(() => {
                    //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    var tempNodeList = []
                    for (var i = 0; i < spanels.length - num; i++) {
                        // console.log(spanels[num+i])
                        tempNodeList.push(spanels[num + i])
                        for (var j = 0; j < tempNodeList.length; j++) {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = response.name
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + response.ext
                            }
                            cel.innerText = temptext
                            tempNodeList[j].prepend(cel)
                        }
                    }
                })
            },
            actions() {
                //if (this.inlineButtons.length == 0) {
                //    columns.pop();
                //}
            }
        }
    }

    //function getBase64(file) {
    //    return new Promise((resolve, reject) => {
    //        const reader = new FileReader()
    //        reader.readAsDataURL(file)
    //        reader.onload = () => resolve(reader.result)
    //        reader.onerror = error => reject(error)
    //    })
    //}
</script>
<style>
    .ant-layout-footer {
        display: none;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
        padding: 20px 20px 0;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }
    .ant-input1 {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-variant: tabular-nums;
        list-style: none;
        font-feature-settings: 'tnum';
        position: relative;
        display: inline-block;
        width: 20%;
        height: 32px;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        line-height: 1.5;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        transition: all 0.3s;
        width: 100px;
    }
    input:focus {
        outline: none;
        border: 1px solid #1890ff;
    }
    .trCss {
        border-bottom: 1px solid #e8e8e8;
    }
    table > tr > td {
        padding: 10px 10px;
        overflow-wrap: break-word;
    }
    .wz_td {
        width: 150px;
        height: 57px;
    }
</style>