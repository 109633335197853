<template>
    <div>
        <!-- 描述列表 -->
        <a-descriptions title="基本信息" layout="vertical" :column="{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }">
            <a-descriptions-item v-for="(item, index) in dataList" :key="index" :label="item.title">
                {{item.data}}
            </a-descriptions-item>
        </a-descriptions>

        <a-row style="height: 40px; line-height: 40px;">
            <a-col :span="24" style="font-size: 18px; font-weight: bold; color: rgba(0, 0, 0, 0.85);">
                发起派单
            </a-col>
        </a-row>
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="交办单位" prop="town">
                <a-select v-model="form.town" placeholder="请选择交办单位">
                    <a-select-option :value="item.value" v-for="(item, index) in townList" :key="index">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="整改意见" prop="desc">
                <a-input v-model="form.desc" type="textarea" placeholder="请填写整改意见" />
            </a-form-model-item>
            <a-form-model-item label="处理时限" prop="date">
                <a-date-picker v-model="form.date" type="date" placeholder="请选择处理时限" style="width: 100%;" />
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 14, offset: 2 }">
                <a-button type="primary" @click="onSubmit">
                    提交
                </a-button>
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
export default {
    // props: {
    //     titleList: {
    //         type: String,
    //         default: "",
    //     },
    //     data: {
    //         type: String,
    //         default: "",
    //     },
    // },
    data() {
        return {
            rowData: null,
            dataList: [],
            townList: [],
            labelCol: { span: 2 },
            wrapperCol: { span: 22 },
            form: {
                name: "",
                town: undefined,
                date: undefined,
                desc: "",
            },
            rules: {
                town: [
                    {
                        required: true,
                        message: "不能为空",
                        trigger: "change",
                    },
                ],
                desc: [
                    {
                        required: true,
                        message: "不能为空",
                        trigger: "blur",
                    },
                ],
                date: [
                    {
                        required: true,
                        message: "不能为空",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    created() {
        console.log(this.$route);
        if (this.$route.query.titleList) {
            let titleList = JSON.parse(this.$route.query.titleList);
            console.log(titleList);
            let data = JSON.parse(this.$route.query.data);
            this.rowData = data;
            this.getDescList(titleList, data);
            this.getUnit();
        } else {
            this.$message.error("非法打开页面");
        }
    },
    methods: {
        /**
         * 获取描述列表
         * 通过对比标题和内容的key,创建新的数组
         */
        getDescList(titleList, data) {
            // 获取对象的keys数组
            let keysList = Object.keys(data);
            let array = [];
            for (let i in titleList) {
                for (let j in keysList) {
                    if (titleList[i].dataIndex == keysList[j]) {
                        let d;
                        // 是否存在问题 布尔值转字符串
                        if (titleList[i].dataIndex == "isError") {
                            switch (data[keysList[j]]) {
                                case true:
                                    d = "是";
                                    break;
                                case false:
                                    d = "否";
                                    break;
                            }
                        } else {
                            d = data[keysList[j]];
                        }
                        array.push({
                            title: titleList[i].title,
                            data: d,
                        });
                    }
                }
            }
            this.dataList = array;
        },
        /**
         * 获取交办单位
         * @method getUnit
         * @param {number} pageIndex 当前页
         * @param {number} pageSize 每页条数
         * @return {void} 返回值说明
         */
        getUnit() {
            this.$axios
                .get("/api/smallpro/GetRcxcJbdx", {
                    pageIndex: 1,
                    pageSize: 10,
                })
                .then((res) => {
                    if (res.data.code == 0) {
                        this.townList = res.data.data;
                    }
                });
        },
        /**
         * 表单提交
         * 验证表单通过后执行提交
         */
        onSubmit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.$axios
                        .post("/api/workorder/pd", {
                            infobh: this.rowData.bh, // 编号
                            currentrecLoginid: this.form.town,
                            infos: this.form.desc,
                            clsx: this.form.date,
                        })
                        .then((res) => {
                            console.log(res);
                            if (res.data.code == 0) {
                                this.$message.success("提交成功");
                                this.$router.go(-1)
                            }
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
};
</script>

<style scoped>
</style>