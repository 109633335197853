<template>
    <a-spin :spinning="formAddOptions1.spinning" tip="全部视频更新中..">
        <div id="components-form-demo-advanced-search1">

            <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
                <a-row :gutter="24">
                    <a-col :span="6">
                        <a-form-item label="">
                            <a-input placeholder="摄像头名称" v-decorator="[`ALARM_NAME`]" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-item>
                            <a-select v-decorator="['ALARM_LEVEL']" placeholder="请选择告警等级" style="width:100%">
                                <a-select-option value="">
                                    全部告警等级
                                </a-select-option>
                                <a-select-option value="1">
                                    一般等级
                                </a-select-option>
                                <a-select-option value="2">
                                    警告等级
                                </a-select-option>
                                <a-select-option value="3">
                                    严重等级
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                        <!-- <a-form-item label="">
                            <a-input placeholder="所在区镇" v-decorator="[`townName`]" />
                        </a-form-item> -->
                    </a-col>
                    <a-col :span="6">
                        <a-form-item label="">
                            <a-input placeholder="告警描述" v-decorator="['ALARM_DESCRIBE']"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-item>
                            <a-select v-decorator="['sfcl']" placeholder="请选择状态" style="width:100%">
                                <a-select-option value="">
                                    全部状态
                                </a-select-option>
                                <a-select-option value="0">
                                    未处理
                                </a-select-option>
                                <a-select-option value="1">
                                    已处理
                                </a-select-option>

                            </a-select>
                        </a-form-item>
                        <!-- <a-form-item label="">
                            <a-input placeholder="所在区镇" v-decorator="[`townName`]" />
                        </a-form-item> -->
                    </a-col>
                    <!-- <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="所在村社区" v-decorator="['village']"></a-input></a-form-item>
                </a-col> -->
                </a-row>
                <!--    <a-row :gutter="24" v-if="expand">
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="监管单位" v-decorator="['JgCompany']"></a-input></a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="地点" v-decorator="['location']"></a-input></a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="总户号" v-decorator="['toatlHu']"></a-input></a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="线路" v-decorator="['powerLine']"></a-input></a-form-item>
                </a-col>
            </a-row> -->
                <a-row :gutter="[24,12]">
                    <a-col :span="6" :style="{ textAlign: 'left' }">
                        <!-- <a-button type='primary'  @click="btnClick('更新全部视频')" style='margin-right: 5px' >更新全部视频</a-button>
                    <a-button type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button> -->
                    </a-col>
                    <a-col :span="18" :style="{ textAlign: 'right' }">
                        <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                        <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                        <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                            {{ expand ?'收起':'展开' }}
                            <a-icon :type="expand ? 'up' : 'down'" />
                        </a>
                    </a-col>
                </a-row>
            </a-form>
            <a-modal v-model="formAddOptions.visible" title="告警详情" width="1300px">
                <a-spin :spinning="formAddOptions.spinning">
                    <a-form :form="formAdd" :label-col="{ span:7 }" :wrapper-col="{ span:17 }" labelAlign="left">
                        <a-form-item label="" style="display:none">
                            <a-input v-decorator="['id']" />
                        </a-form-item>
                        <a-row type="flex" justify="space-between" :gutter="32">
                            <a-col :span="8">
                                <a-form-item label="摄像头名称">
                                    <a-input v-decorator="['ALARM_NAME', { rules: [{ required: false, message: '' }]}]" placeholder="" :disabled="true" :maxLength="200" />
                                </a-form-item>
                            </a-col>

                            <a-col :span="8">
                                <a-form-item label="告警设备ID">
                                    <a-input v-decorator="['DEVICE_ID', { rules: [{ required: false, message: '' }]}]" placeholder="" :disabled="true" :maxLength="200" />
                                </a-form-item>
                            </a-col>

                            <a-col :span="8">
                                <a-form-item label="告警源ip">
                                    <a-input v-decorator="['ALARM_ADDRESS', { rules: [{ required: false, message: '' }]}]" placeholder="" :disabled="true" :maxLength="200" />
                                </a-form-item>
                            </a-col>
                            <!-- <a-col :span="8">
                            <a-form-item label="闸站类型">
                                <a-select v-decorator="['type',{ rules: [{ required: true, message: '请输入闸站类型'}]},]" placeholder="请选择" :disabled="true" style="width:100%">
                                    <a-select-option value="排涝站">
                                        排涝站
                                    </a-select-option>
                                    <a-select-option value="防洪闸">
                                        防洪闸
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="8">
                            <a-form-item label="所在区镇">
                               
                                <a-select v-decorator="['townId',{ rules: [{ required: true, message: '请输入闸站类型'}]},]" placeholder="请选择区镇" :disabled="formAddOptions.viewMode || formAddOptions.townEdit"  style="width:100%" >
                                    <a-select-option v-for="(item,index) in xzlist" :key="index" :value="item.value">
                                        {{item.name}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col> -->
                        </a-row>
                        <a-row type="flex" justify="space-between" :gutter="32">
                            <a-col :span="8">
                                <a-form-item label="告警源类型">
                                    <a-input v-decorator="['ALARM_TYPE', { rules: [{ required: false, message: '' }]}]" placeholder="" :disabled="true" :maxLength="20" style="width:100%" />

                                </a-form-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-item label="告警等级">
                                    <!--   <a-input v-decorator="['ALARM_LEVEL', { rules: [{ required: false, message: '' }]}]" placeholder="" :disabled="true" :maxLength="20" /> -->
                                    <a-select v-decorator="['ALARM_LEVEL',{ rules: [{ required: true, message: '请选择告警等级'}]},]" placeholder="请选择" :disabled="true" style="width:100%">
                                        <a-select-option value="1">
                                            一般
                                        </a-select-option>
                                        <a-select-option value="2">
                                            警告
                                        </a-select-option>
                                        <a-select-option value="3">
                                            严重
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-item label="告警状态">
                                    <!-- <a-input v-decorator="['ALARM_STATUS', { rules: [{ required: false, message: '' }]}]" placeholder="" :disabled="true" :maxLength="20" /> -->
                                    <a-select v-decorator="['ALARM_STATUS',{ rules: [{ required: true, message: '请选择告警状态'}]},]" placeholder="请选择" :disabled="true" style="width:100%">
                                        <a-select-option value="1">
                                            持续中
                                        </a-select-option>
                                        <a-select-option value="2">
                                            已关闭
                                        </a-select-option>
                                        <a-select-option value="3">
                                            已删除
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <a-row type="flex" justify="space-between" :gutter="32">
                            <a-col :span="8">
                                <a-form-item label="告警触发时间">
                                    <a-input v-decorator="['ALARM_TRIGGER_TIME', { rules: [{ required: false, message: '' }]}]" placeholder="" :disabled="true" :maxLength="200" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-item label="告警恢复时间">
                                    <a-input v-decorator="['ALARM_FINISH_TIME', { rules: [{ required: false, message: '' }]}]" placeholder="" :disabled="true" :maxLength="200" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="8">
                                <!-- <a-form-item label="PTZ 控制类型">
                              
                                   <a-select v-decorator="['PtzType',{ rules: [{ required: true, message: ''}]},]" placeholder="" :disabled="true" style="width:100%">
                                       <a-select-option value="2">
                                           设备控制
                                       </a-select-option>
                                       <a-select-option value="3">
                                           不能控制
                                       </a-select-option>
                                   </a-select>
                              
                            </a-form-item> -->
                                <a-form-item label="告警描述">
                                    <a-input v-decorator="['ALARM_DESCRIBE', { rules: [{ required: false, message: '' }]}]" placeholder="" :disabled="true" :maxLength="2000" />
                                </a-form-item>
                            </a-col>
                        </a-row>

                    </a-form>
                </a-spin>
                <template slot="footer">
                    <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
                    <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
                </template>
            </a-modal>
            <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="20" :total="50" rowKey="id" :loading="loading" :scroll="scroll" @change="pageCurrentChange" position="bottom" tableLayout="fixed">
                <span slot="xuhao" slot-scope="text, record">{{record.rownum}}</span>
                <a slot="ALARM_NAME" slot-scope="text,record" @click='playVideo(record)'>{{ text }}</a>
                <!-- <span slot="Alive" slot-scope="text, record">{{record.Alive=="1"?"在线":"离线"}}</span> -->
                <span slot="ALARM_LEVEL" slot-scope="text, record">
                    <a-tag v-if="text==1" color="green">一般</a-tag>
                    <a-tag v-if="text==2" color="red">警告</a-tag>
                    <a-tag v-if="text==3" color="red">严重</a-tag>

                </span>
                <span slot="sfcl" slot-scope="text, record">
                    <a-tag v-if="text==1" color="green">已处理</a-tag>
                    <a-tag v-if="text==0" color="red">未处理</a-tag>
                </span>
                <span slot="ALARM_TRIGGER_TIME" slot-scope="text,record">{{$jsc.formatDate_isTime_new(text)}}</span>
                <!-- <span slot="CameraType" slot-scope="text, record">{{get_camera_type_text(record.CameraType)}}</span> -->
                <span slot="action" slot-scope="text, record">
                    <a-button type='primary' size="small" key="派单" @click="btnClick('派单',record)" style='margin-right:5px;' v-if='record.sfcl==0'>
                        派单
                    </a-button>
                    <a-button type='' size="small" @click="openDlg(record,'view')">
                        报警详情
                    </a-button>

                    <!-- v-if='record.sfcl==0' -->
                    <!-- <a-button type="primary" size="small" style='margin:0 5px' @click='lookproject(record)'>查看</a-button> -->
                    <a-button :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right: 5px'>
                        <a-icon :type="button.iconLink" />
                    </a-button>
                </span>
            </a-table>
            <a-modal v-if="videoVisible" v-model="videoVisible" :title="videoTitle" :footer="null" @cancel="handleCancel" :width="760">
                <!-- <video id="videojs" class="video-js vjs-default-skin vjs-big-play-centered" style="width: 710px; height: 400px;" /> -->
                <video id="videojs" autoplay="muted" controls style="width: 100%; height: 100%;"></video>
            </a-modal>
        </div>
    </a-spin>
</template>
<script>
import moment from "moment";
const strWhere = "del=0";
const apiServer = "http://58.210.32.10";
export default {
    data() {
        return {
            scroll: {
                x: 900,
                y: 900,
            },
            showexport: false,
            buttons: [],
            inlineButtons: [],
            actionArry: [],
            tableData: [],
            columns: [
                {
                    title: "序号",
                    fixed: "left",
                    width: 70,
                    scopedSlots: { customRender: "xuhao" },
                },

                {
                    title: "摄像头名称",
                    dataIndex: "nickname",
                    key: "nickname",
                    width: 150,

                    scopedSlots: { customRender: "ALARM_NAME" },
                },
                {
                    title: "报警设备ID",
                    dataIndex: "DEVICE_ID",
                    key: "DEVICE_ID",
                    width: 150,
                },
                //{
                //    title: "告警源类型",
                //    dataIndex: "ALARM_TYPE",
                //    key: "ALARM_TYPE",
                //    width: 120,
                //},
                {
                    title: "告警等级",
                    dataIndex: "ALARM_LEVEL",
                    key: "ALARM_LEVEL",
                    width: 80,
                    scopedSlots: { customRender: "ALARM_LEVEL" },
                },

                // {
                //     title: '所在区镇',
                //     dataIndex: 'town',
                //     key: 'town'
                // },
                {
                    title: "告警触发时间",
                    dataIndex: "ALARM_TRIGGER_TIME",
                    width: 150,
                    key: "ALARM_TRIGGER_TIME",
                    scopedSlots: { customRender: "ALARM_TRIGGER_TIME" },
                },
                {
                    title: "告警描述",
                    dataIndex: "ALARM_DESCRIBE",
                    key: "ALARM_DESCRIBE",
                    width: 120,
                },

                {
                    title: "状态",
                    key: "sfcl",
                    dataIndex: "sfcl",
                    width: 120,
                    scopedSlots: { customRender: "sfcl" },
                },

                {
                    title: "操作",
                    key: "action",
                    fixed: "right",
                    width: 180,
                    scopedSlots: { customRender: "action" },
                },
            ],
            loading: true,
            action: "add",
            previewVisible: false,
            previewImage: "",
            fileList: [],
            strWhere,
            expand: false,
            formAddOptions: {
                visible: false,
                submitLoading: false,
                submitVisible: true,
                viewMode: false,
                spinning: false,
                townEdit: false,
            },
            formAddOptions1: {
                spinning: false,
            },
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
            formSearch: this.$form.createForm(this, {
                name: "advanced_search2",
            }),
            formAdd: this.$form.createForm(this, { name: "advanced_search3" }),
            pagination: {
                total: 0,
                pageSize: 15, // 每页中显示10条数据
                current: 1,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"], // 每页中显示的数据
                showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
                position: "bottom",
            },
            xzlist: [],
            sfzc: "",

            videoVisible: false,
            videoTitle: "标题",
            obj: {
                rtcPrefix: apiServer + ":36085", // rtc连接api前缀
                commonPrefix: apiServer + ":36000", // 基础api前缀，登录/登出,登录保活等
                replayPrefix: apiServer + ":36001", // 获取视频流地址/保活实时视频 前缀
                usrName: "admin",
                passWd: "ksws12345@abc",
                loginInterval: null, // 登录保活定时器
                videoInterval: null, // 视频保活定时器
                loginKeeplive: 20, // 登录保活间隔，默认20秒
                videoKeeplive: 20, // 登录保活间隔，默认20秒
                AuthorizationId: "",
                SessionId: "",
                chanpubid: "",
                hlsPlayer: null,
                pc: null,
                rvideoObj: null,
            },
        };
    },
    created() {
        // 模拟后台请求
        // this.bindList();
        // this.InitGrid()
        this.getTown();
    },
    mounted() {
        // this.sfzc = this.$route.query.sfzc;
        console.log(this.$route);
        for (let i = 0; i < this.$route.meta.buttons.length; i++) {
            if (this.$route.meta.buttons[i].tag == "inline") {
                this.inlineButtons.push(this.$route.meta.buttons[i]);
            } else {
                this.buttons.push(this.$route.meta.buttons[i]);
            }
        }
        this.init();
        this.actions();
        window.onresize = () => {
            this.init();
        };
        this.bindList();
        this.login();
    },
    watch: {
        // '$route.query.sfzc'(newVal, oldVal) {
        //     console.log(newVal);
        //     this.sfzc = newVal;
        //     this.bindList();
        // }
    },
    methods: {
        getTown() {
            this.$axios({
                url: "/api/zzgl/GetTown",
                method: "get",
            }).then((res) => {
                if (res.data.code == 0) {
                    this.xzlist = res.data.data;
                }
            });
        },
        // get_camera_type_text(value){

        // 	var _type_text="";
        // 	if(value=="5"||value=="0")
        // 	{
        // 		_type_text="未知";
        // 	}else if(value=="6")
        // 	{
        // 		_type_text="球机";
        // 	}else if(value=="7")
        // 	{
        // 		_type_text="半球";
        // 	}
        // 	else if(value=="8")
        // 	{
        // 		_type_text="固枪";
        // 	}
        // 	else if(value=="9")
        // 	{
        // 		_type_text="遥控枪机";
        // 	}
        // 	return _type_text;
        // },
        lookproject(item) {
            this.$router.push(
                "/gcyxgl/hdshsy/xmgl-list-parent?townId=" +
                    item.townId +
                    "&zzbh=" +
                    item.bh
            );
        },
        btnClick(text, item) {
            console.log(text);
            let _this = this;
            switch (text) {
                case "新增":
                    this.openDlg();
                    break;
                case "派单":
                    this.gopd(item);
                    break;
                case "编辑":
                    this.openDlg(item);
                    break;
                case "更新全部视频":
                    this.updatecameras();
                    break;
                case "删除":
                    this.$confirm({
                        title: "操作确认",
                        content: "确定要删除 " + item.NickName + " 吗?",
                        okText: "确认",
                        cancelText: "取消",
                        onOk() {
                            _this.del(item);
                        },
                    });
                    break;
            }
        },
        bindList: async function () {
            this.formSearch.validateFields((error, values) => {
                error = 1;
                // console.log(values)
                // this.strWhere = "del=0 and source='站闸'"
                this.strWhere = "del=0 ";

                for (var obj in values) {
                    if (values[obj]) {
                        this.strWhere +=
                            " and " + obj + " like '%" + values[obj] + "%'";
                    }
                }
                console.log(this.strWhere);
                this.strWhere = this.strWhere.replace(/'/g, "zhangbiao");
            });
            this.loading = true;
            this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere);
            this.strWhere = this.strWhere.data;
            await this.InitGrid();
        },
        gopd(value) {
            // this.$router.push("pd?xczzbh=" + value.xczzbh+'&probh='+value.bh)
            //typeinfo:0-视频工单，1-雨情传感器工单，2-水情传感器工单，3-积水易涝传感器工单
            this.$router.push(
                "camera-pd?xczzbh=" +
                    value.ID +
                    "&typeinfo=0&NickName=" +
                    value.ALARM_NAME
            );
        },
        InitGrid: function () {
            var _that = this;
            _that.loading = true;
            this.$axios({
                url: "/api/GetList/getListByTable",
                method: "get",
                params: {
                    tableName: "v_wlw_alarmcenter",
                    page: _that.pagination.current,
                    limit: _that.pagination.pageSize,
                    filesFields: "id",
                    strWhere: _that.strWhere,
                },
            })
                .then(function (response) {
                    console.log(response);
                    _that.loading = false;
                    if (response.status === 200) {
                        _that.tableData = response.data.data;
                        _that.pagination.total = response.data.count;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        formSearchSubmit(e) {
            e.preventDefault();
            this.pagination.current = 1;
            this.bindList();
        },
        pageCurrentChange(pagination) {
            this.pagination.current = pagination.current;
            this.bindList();
        },
        formSearchReset() {
            this.formSearch.resetFields();
            this.strWhere = "del=0";
            this.bindList();
        },
        toggle() {
            this.expand = !this.expand;
            this.init();
        },
        updatecameras() {
            this.formAddOptions1.spinning = true;
            this.$axios
                .get("/api/Cameras/UpdateCamera")
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.code == 0) {
                            // this.formAddOptions.visible = true;
                            var result = response.data.data;
                            console.log(result[0]);
                            setTimeout(() => {
                                // result[0].yyqk = true
                                // result[0].emergencyPower = result[0].emergencyPower == "true" ? true : false
                                // result[0].safetyAssessment = result[0].safetyAssessment == "true" ? true : false

                                // this.formAdd.setFieldsValue(result[0])
                                //this.fileList = result[0].files_bh
                                // this.formAddOptions.townEdit = true;
                                this.formAddOptions1.spinning = false;
                                this.$message.info("更新成功");
                                this.bindList();
                            }, 1000);
                        } else {
                            this.$message.error(response.data.msg);
                            this.formAddOptions1.spinning = false;
                        }
                    } else {
                        // vue.prototype.$message.info('This is an error message');
                        this.formAddOptions1.spinning = false;
                    }
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        openDlg: async function (record, action) {
            this.formAdd.resetFields();
            this.fileList = [];
            this.formAddOptions.submitVisible = true;
            this.formAddOptions.viewMode = false;
            console.log("zjxzjx");
            console.log(record);
            if (record !== undefined) {
                // 从后台取数据赋值
                this.formAddOptions.spinning = true;
                var obj = await this.$jsc.DesEncrypt(
                    this,
                    "id=zhangbiao" + record.ID + "zhangbiao"
                );
                obj = obj.data;
                this.$axios
                    .get(
                        "/api/GetList/getListByTable?tableName=wlw_alarmcenter&strWhere=" +
                            obj +
                            "&filesFields=id"
                    )
                    .then((response) => {
                        if (response.status == 200) {
                            if (response.data.code == 0) {
                                this.formAddOptions.visible = true;
                                var result = response.data.data;
                                console.log(result[0]);
                                setTimeout(() => {
                                    // result[0].yyqk = true
                                    // result[0].emergencyPower = result[0].emergencyPower == "true" ? true : false
                                    // result[0].safetyAssessment = result[0].safetyAssessment == "true" ? true : false

                                    this.formAdd.setFieldsValue(result[0]);
                                    //this.fileList = result[0].files_bh
                                    // this.formAddOptions.townEdit = true;
                                    this.formAddOptions.spinning = false;
                                }, 1000);
                            } else {
                                this.$message.error(response.data.msg);
                                this.formAddOptions.spinning = false;
                            }
                        } else {
                            // vue.prototype.$message.info('This is an error message');
                        }
                    })
                    .catch(function (error) {
                        // 请求失败处理
                        console.log(error);
                    });
                if (action === "view") {
                    // 预览模式
                    this.formAddOptions.submitVisible = false;
                    this.formAddOptions.viewMode = true;
                }
            } else {
                this.formAddOptions.visible = true;
            }
        },
        formAddSubmit() {
            this.formAdd.validateFields((err, values) => {
                if (!err) {
                    // values.files_bh = this.fileList
                    // values.source = "站闸";
                    this.formAddOptions.submitLoading = true;
                    this.$axios({
                        url: "/api/Cameras/EditCamera",
                        method: "post",
                        data: values,
                    })
                        .then((res) => {
                            console.log(res);
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info("保存成功");
                                    this.formAddOptions.visible = false;
                                    this.formAddOptions.submitLoading = false;
                                    this.bindList();
                                } else {
                                    console.log(res);
                                    throw new Error("服务器超时2");
                                }
                            } else {
                                throw new Error("服务器超时2");
                            }
                        })
                        .catch((error) => {
                            // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false;
                        });
                }
            });
        },
        del(record) {
            this.$axios({
                url: "/api/Comon/deltag",
                method: "post",
                data: {
                    tableName: "wlw_camera",
                    field: "id",
                    value: record.id,
                    source: "web",
                    loginid: "",
                },
            })
                .then((res) => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info("删除成功");
                            this.bindList();
                        } else {
                            throw new Error("服务器超时");
                        }
                    } else {
                        throw new Error("服务器超时");
                    }
                })
                .catch((error) => {
                    // 请求失败处理
                    this.$message.error(error.message);
                });
        },
        init() {
            var that = this;
            size();
            function size() {
                var innerHeight =
                    window.innerHeight || document.body.innerHeight;
                that.$nextTick(() => {
                    var el = document.querySelector(
                        "#components-form-demo-advanced-search1>form"
                    );
                    if (el) {
                        that.scroll.y =
                            innerHeight -
                            getComputedStyle(el).height.split("p")[0] -
                            270;
                    }
                });
            }
        },
        actions() {
            if (this.inlineButtons.length == 0) {
                //this.columns.pop();
            }
        },
        // 登录视频账号
        login() {
            let passWd = this.obj.passWd;
            passWd = this.$md5(passWd);
            fetch(
                this.obj.commonPrefix +
                    "/ivms/login?UsrName=" +
                    this.obj.usrName +
                    "&Passwd=" +
                    passWd
            )
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    console.log("视频登录", res);
                    if (res.errcode === 0) {
                        if (res.AuthorizationId) {
                            this.obj.AuthorizationId = res.AuthorizationId;
                        }
                        this.keepAlive("login");
                    } else {
                        alert(res.errdesc);
                    }
                });
        },
        playVideo(record) {
            console.log(record);
            this.videoTitle = record.ALARM_NAME;
            this.obj.chanpubid = record.DEVICE_ID;
            this.videoVisible = true;
            // setTimeout(() => {
            //     this.initVideo();
            // }, 100);
            this.$nextTick(() => {
                this.initVideo();
            });
        },
        // 初始化视频
        initVideo() {
            let _this = this;
            let obj = this.obj;
            const chanpubid = obj.chanpubid;
            const AuthorizationId = obj.AuthorizationId;
            if (!AuthorizationId) {
                this.$message.error("未查到授权id");
                return;
            }
            obj.rvideoObj = document.querySelector("#videojs");
            obj.pc = new RTCPeerConnection(null);
            obj.pc.onaddstream = function (event) {
                console.log(event);
                console.log(obj.rvideoObj);
                // obj.rvideoObj.prop('srcObject', event.stream)
                obj.rvideoObj.srcObject = event.stream;
                _this.keepAlive("video");
            };
            new Promise(function (resolve, reject) {
                obj.pc.addTransceiver("audio", {
                    direction: "recvonly",
                });
                obj.pc.addTransceiver("video", {
                    direction: "recvonly",
                });
                obj.pc.createOffer(
                    function (offer) {
                        resolve(offer);
                    },
                    function (reason) {
                        reject(reason);
                    }
                );
            })
                .then(function (offer) {
                    return obj.pc.setLocalDescription(offer).then(function () {
                        return offer;
                    });
                })
                .then(function (offer) {
                    return new Promise(function (resolve, reject) {
                        fetch(
                            obj.replayPrefix +
                                "/ivms/sipsvr/realplay2?streamtranstype=6" +
                                "&chanpubid=" +
                                chanpubid +
                                "&AuthorizationId=" +
                                AuthorizationId
                        )
                            .then((res) => {
                                return res.json();
                            })
                            .then((res) => {
                                console.log(res);
                                if (res && res.errcode === 0) {
                                    const url = obj.rtcPrefix + "/rtc/v1/play/";
                                    const params = {
                                        streamurl: res.rtcurl,
                                        clientip: null,
                                        sdp: offer.sdp,
                                    };
                                    obj.SessionId = res.SessionId;
                                    console.log("Generated offer: ", params);
                                    fetch(url, {
                                        method: "POST",
                                        body: JSON.stringify(params),
                                    })
                                        .then((res) => {
                                            return res.json();
                                        })
                                        .then((res) => {
                                            console.log(res);
                                            let sdp = res.sdp;
                                            resolve(sdp);
                                        });
                                }
                            });
                    });
                })
                .then(function (answer) {
                    return obj.pc.setRemoteDescription(
                        new RTCSessionDescription({
                            type: "answer",
                            sdp: answer,
                        })
                    );
                })
                .catch(function (reason) {
                    obj.pc.close();
                    throw reason;
                });
        },
        stopVideo() {
            // if (this.obj.hlsPlayer) {
            //     fetch(
            //         this.obj.replayPrefix +
            //             "/ivms/sipsvr/close_realplay?SessionId=" +
            //             this.obj.SessionId +
            //             "&AuthorizationId=" +
            //             this.obj.AuthorizationId
            //     )
            //         .then((res) => {
            //             return res.json();
            //         })
            //         .then((res) => {
            //             console.log("关闭视频", res);
            //         });
            //     this.obj.hlsPlayer.dispose();
            //     this.obj.hlsPlayer = null;
            // }
            let obj = this.obj;
            obj.rvideoObj.srcObject = null;
            const pc = obj.pc;
            if (pc) {
                pc.close();
            }
            clearInterval(this.obj.loginInterval);
            clearInterval(this.obj.videoInterval);
        },
        handleCancel() {
            this.stopVideo();
        },
        // 保活
        keepAlive(type) {
            let obj = this.obj;
            if (type === "login") {
                let url = `${obj.commonPrefix}/ivms/keeplive?AuthorizationId=${obj.AuthorizationId}`;
                obj.loginInterval = setInterval(() => {
                    fetch(url)
                        .then((res) => {
                            return res.json();
                        })
                        .then((res) => {
                            if (res.errcode === 0) {
                                console.log("登录保活成功！");
                            } else {
                                console.log("登录保活失败：" + res.errdesc);
                            }
                        });
                }, 1000 * obj.loginKeeplive);
            } else if (type === "video") {
                let url = `${obj.replayPrefix}/ivms/sipsvr/video_keeplive?AuthorizationId=${obj.AuthorizationId}&SessionId=${obj.SessionId}`;
                obj.videoInterval = setInterval(() => {
                    fetch(url)
                        .then((res) => {
                            return res.json();
                        })
                        .then((res) => {
                            if (res.errcode === 0) {
                                console.log("视频保活成功！");
                            } else {
                                console.log("视频保活失败：" + res.errdesc);
                            }
                        });
                }, 1000 * obj.videoKeeplive);
            }
        },
    },
};
</script>
<style>
</style>