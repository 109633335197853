<template>
    <div id="components-form-demo-advanced-search">
        <a-modal v-model="formAddOptions.visible" :title="opration" width="800px">
            <a-spin :spinning="formAddOptions.spinning">
                <a-form :form="formAdd" :label-col="{ span:6 }" :wrapper-col="{ span:18 }" labelAlign="right">

                    <a-form-item label="" style="display:none;">
                        <a-input v-decorator="['cbid']" />
                        <a-input v-decorator="['town']" />
                        <a-input v-decorator="['wzmc']" />
                        <a-input v-decorator="['type']" />
                        <!--<a-input v-decorator="['jzsj']" />-->
                    </a-form-item>

                    <a-row type="flex" justify="space-between">
                        <a-col :span="12">
                            <a-form-item label="物资">
                                <a-select v-decorator="['wzid',{ rules: [{ required: true, message: '请选择物资'}]},]" placeholder="请选择物资" style="width:100%" >
                                    <a-select-option v-for="item in dictData" :value="item.id">{{item.dictName}}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item :label="opration+'数量'">
                                <a-input-number :min="1" v-decorator="['amount', { rules: [{ required: true, message: '请输入入库数量' }]}]" placeholder="请输入入库数量"></a-input-number>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-between">
                        <a-col :span="12">
                            <a-form-item :label="opration+'地点'">
                                <a-input v-decorator="['address', { rules: [{ required: true, message: '请输入库地点' }]}]" placeholder="请输入库地点" :disabled="formAddOptions.viewMode" :maxLength="50" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item :label="opration+'时间'">
                                <a-date-picker v-decorator="['sj', { rules: [{ required: true, message: '请选择上报截止日期' }]}]" style="width: 100%" :disabled="formAddOptions.viewMode" />
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-between">
                        <a-col :span="12">
                            <a-form-item label="负责人">
                                <a-input v-decorator="['fzr', { rules: [{ required: true, message: '请输入库地点' }]}]" placeholder="请输入库地点" :disabled="formAddOptions.viewMode" :maxLength="50" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item :label="opration2">
                                <a-input v-decorator="['source', { rules: [{ required: true, message: '请输入来源' }]}]" placeholder="请输入来源" :disabled="formAddOptions.viewMode" :maxLength="50" />
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-between">
                        <a-col :span="24">
                            <a-form-item label="备注" :label-col="{ span:3 }" :wrapper-col="{ span:21 }">
                                <a-textarea v-decorator="['remark']" placeholder="请输入备注" :maxLength="500" />
                            </a-form-item>
                        </a-col>
                    </a-row>

                </a-form>
            </a-spin>
            <template slot="footer">
                <a-button key="back" @click="formAddOptions.visible=false;formAddOptions.dateVisible=false;">关闭</a-button>
                <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
            </template>
        </a-modal>

        <!--<a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
        <a-row :gutter="24">
            <a-col :span="8">
                <a-form-item label="">
                    <a-input v-decorator="['title']" placeholder="请输入计划标题" :maxLength="40" />
                </a-form-item>
            </a-col>
            <a-col :span="8">
                <a-form-item label="">
                    <a-select v-decorator="['isdb']" placeholder="请选择是否达标" >
                        <a-select-option value="是">是</a-select-option>
                        <a-select-option value="否">否</a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
            <a-col :span="8">
                <a-form-item label="">
                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                </a-form-item>
            </a-col>

        </a-row>

        <a-row :gutter="[24,12]">
            <a-col :span="18" :style="{ textAlign: 'left' }">
                <a-button type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button>
            </a-col>-->
        <!--<a-col :span="6" :style="{ textAlign: 'right' }">
        <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
        <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
        <a :style="{ marginLeft: '8px', fontSize: '12px',display:'none' }" @click="toggle">
            {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
        </a>
    </a-col>-->
        <!--</a-row>
    </a-form>-->

        <div style="text-align:center;margin-bottom:40px;">
            <span style="font-size:25px;font-weight:bold;">物资报备</span>
        </div>
        <div class="search-result-list" :style="{ marginTop: '0px' }">
            <a-table :columns="columns"
                     :data-source="tableData"
                     :pagination="false"
                     :pageSize="10"
                     :total="50"
                     rowKey="id"
                     :loading="loading"
                     :scroll="scroll"
                     @change="pageCurrentChange"
                     position="bottom"
                     tableLayout="fixed">
                <span slot="action" slot-scope="text, record">
                    <a-button v-if="button.title=='计划数管理'" :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right: 5px'>{{button.title}}</a-button>
                    <a-button v-if="button.title!='计划数管理'" :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right: 5px'><a-icon :type="button.iconLink" /></a-button>
                </span>
                <span slot="titles" slot-scope="text, record">
                    <a @click="$router.push({path:'/fxjcsystem/wzjh-children-list?titles='+text+'&planbh='+record.bh})">{{text}}</a>
                </span>
                <span slot="action" slot-scope="text, record">
                    <a-button type='normal' size="small" @click="btnClick('+',record)" style='margin-right: 5px;width:25px;'>+</a-button>
                    <a-button type='normal' size="small" @click="btnClick('-',record)" style='margin-right: 5px;width:25px;'>-</a-button>
                </span>
                <span slot="records" slot-scope="text, record">
                    <a v-if="text>0" @click="$router.push({path:'/fxjcsystem_new/fxwz/wzbb-crkjl-list?townId='+record.townId+'&wzid='+record.wzid+'&wzmc='+record.wzmc})">{{text}} 条</a>
                    <span v-else>{{text}} 条</span>
                </span>
                <span slot="wzsl" slot-scope="text, record">
                    <span>{{text}}</span>
                </span>
                <span slot="xysl" slot-scope="text, record">
                    <span>{{text}}</span>
                </span>
            </a-table>
        </div>

    </div>
</template>
<script>
    const columns = [
        {
            title: '序号',
            dataIndex: 'rn',
            width: 70,
            key: 'rn'
        },
        {
            title: '物资名称',
            dataIndex: 'wzmc',
            width: 100,
            key: 'wzmc',
            scopedSlots: { customRender: 'wzmc' }
        },
        {
            title: '计划储备',
            dataIndex: 'wzsl',
            width: 100,
            key: 'wzsl',
            scopedSlots: { customRender: 'wzsl' }
        },
        {
            title: '本/区镇现有储备',
            dataIndex: 'xysl',
            width: 100,
            key: 'xysl',
            scopedSlots: { customRender: 'xysl' }
        },
        {
            title: '出/入库记录',
            dataIndex: 'records',
            key: 'records',
            width: 100,
            scopedSlots: { customRender: 'records' }
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 150,
            scopedSlots: { customRender: 'action' }
        }
    ]

    const loading = true
    const strWhere = 'del=0'
    const action = 'add'
    export default {
        data() {
            return {
                showexport: false,
                scroll: {
                },
                opration: '',
                opration2: '',
                townData: [],
                zzData: [],
                xmlxData: [],
                qzLoginid: '',
                //actionList: {
                //    add: false,
                //    remove: false,
                //    edit: false
                //},
                teamData: [],
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                dictData:[],
                tableData: [],
                columns,
                loading,
                action,
                isready_wz: false,
                previewVisible: false,
                previewImage: '',
                fileList: [],
                strWhere,
                strWhere3:'',
                expand: false,
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false,
                    dateVisible: false,
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
                pagination: {
                    total: 0,
                    pageSize: 10, // 每页中显示10条数据
                    showSizeChanger: true,
                    current: 1,
                    pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
                    showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: 'bottom'
                }
            }
        },
        mounted() {
            //将上面的按钮和表格内的按钮分出来
            console.log(this.$route.meta.buttons);
            for (let i = 0; i < this.$route.meta.buttons.length; i++) {
                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                }
                else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.init()
            this.actions()
            window.onresize = () => {
                this.init()
            }
            this.bindList();
        },
        created() {
            //if (this.$User.user.userInfo.townId == "" || this.$User.user.userInfo.townId == null) { this.$message.info("账号不完整，请设置账号的所属区镇"); return; }
        },
        methods: {
            btnClick(text, item) {
                let _this = this;
                switch (text) {
                    case '新增':
                        this.openDlg()
                        break
                    case "+":
                        this.opration = "入库";
                        this.opration2 = "来源";
                        this.isready_wz = true;
                        this.openDlg(item);
                        break;
                    case "-":
                        this.opration = "出库";
                        this.opration2 = "目的地";
                        this.isready_wz = true;
                        this.openDlg(item);
                        break;
                    case "查看":
                        this.openDlg(item, "view");
                        break;
                    case "删除":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定要删除?",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.del(item);
                            }
                        });
                        break;
                }
            },
            bindList: async function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1
                    // console.log(values)
                    //this.strWhere = 'del=0 '
                    //for (var obj in values) {
                    //    if (values[obj]) {
                    //        if (obj == "title") {
                    //            this.strWhere += " and " + obj + " like '%" + values[obj] + "%'";
                    //        } else {
                    //            this.strWhere += " and " + obj + " ='" + values[obj] + "'";
                    //        }
                    //    }
                    //}
                    //this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
                    //console.log(this.strWhere);
                })
                this.loading = true

                //this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere);
                //this.strWhere = this.strWhere.data;
                await this.InitGrid()
            },
            InitGrid: function () {
                //console.log("////////////////////");
                //console.log(this.$User.user.userInfo);
                
                var _that = this
                _that.loading = true
                console.log(_that.strWhere);
                this.$axios({
                    url: '/api/fxjc/GetWzcbxxByTownId?townId=' + this.$User.user.userInfo.loginid,
                    method: 'get',
                })
                    .then(function (response) {
                        console.log(response);
                        _that.loading = false
                        if (response.status === 200) {
                            _that.tableData = response.data.data
                            //_that.pagination.total = response.data.count
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            bindList_dict: async function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1
                    this.strWhere2 = "pid='2102'";
                    for (var obj in values) {
                        if (values[obj]) {
                            this.strWhere2 += ' and ' + obj + " like '%" + values[obj] + "%'"
                        }
                    }
                    this.strWhere2 = this.strWhere2.replace(/'/g, 'zhangbiao')
                })
                this.loading = true;
                this.strWhere2 = await this.$jsc.DesEncrypt(this, this.strWhere2);
                this.strWhere2 = this.strWhere2.data;
                this.strWhere3 = this.strWhere2;
                await this.InitGrid_dict()
            },
            InitGrid_dict: function () {
                var _that = this
                _that.loading = true

                console.log(_that.strWhere2)
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'v_sys_dict',
                        page: _that.pagination.current,
                        limit: _that.pagination.pageSize,
                        strWhere: _that.strWhere2
                    }
                }).then(function (response) {
                    _that.loading = false
                    if (response.status === 200) {
                        _that.dictData = response.data.data
                    }
                }).catch(function (error) {
                    console.log(error)
                })
            },
            onRangePickerChange(date, dateString) {
                this.StartTime = dateString[0];
                this.EndTime = dateString[1];
            },
            onShowSizeChange(current, pageSize) {
                console.log(current, pageSize);
                return;
            },
            formSearchSubmit(e) {
                e.preventDefault()
                this.pagination.current = 1
                this.bindList()
            },
            formSearchReset() {
                this.formSearch.resetFields()
                this.strWhere = 'del=0'
                this.bindList()
            },
            toggle() {
                this.expand = !this.expand
                this.init()
            },
            pageCurrentChange(pagination) {
                console.log(pagination);
                //return;
                this.pagination.current = pagination.current
                this.bindList()
            },
            del(record) {
                this.$axios({
                    url: '/api/Comon/deltag',
                    method: 'post',
                    data: {
                        tableName: 'fxjc_wzgl_wzPlan',
                        field: 'id',
                        value: record.id,
                        source: 'web',
                        loginid: ''
                    }
                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info('删除成功');
                            this.bindList();
                        }
                        else {
                            throw new Error("服务器超时");
                        }
                    }
                    else {
                        throw new Error("服务器超时");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                })
            },
            openDlg: async function (record, action) {
                this.bindList_dict();
                var _this = this;
                this.formAdd.resetFields()
                this.fileList = []
                this.formAddOptions.submitVisible = true
                this.formAddOptions.viewMode = false
                //if (record !== undefined) {
                //    // 从后台取数据赋值
                //    this.formAddOptions.spinning = true
                //    var obj = await this.$jsc.DesEncrypt(this, 'id=zhangbiao' + record.id + 'zhangbiao');
                //    obj = obj.data;
                //    this.$axios
                //        .get('/api/GetList/getListByTable?tableName=v_fxjc_wzgl_wzPlan&strWhere=' + obj + '&filesFields=bh')
                //        .then((response) => {
                //            if (response.status == 200) {
                //                if (response.data.code == 0) {
                //                    this.formAddOptions.visible = true;
                //                    var result = response.data.data;
                //                    console.log(response.data);
                //                    setTimeout(() => {
                //                        //this.formAdd.setFieldsValue({
                //                        //    id: result[0].id,
                //                        //    title: result[0].title,
                //                        //    townId: result[0].townId,
                //                        //    jg_unit: result[0].jg_unit.toString(),
                //                        //    zxsj: result[0].zxsj.toString(),
                //                        //    jg_num: result[0].jg_num,
                //                        //    teamId: result[0].teamId,
                //                        //    startTime_y: parseInt(result[0].startTime.split('-')[0]),
                //                        //    startTime_r: parseInt(result[0].startTime.split('-')[1]),
                //                        //    endTime_y: parseInt(result[0].endTime.split('-')[0]),
                //                        //    endTime_r: parseInt(result[0].endTime.split('-')[1]),
                //                        //});
                //                        //this.isOpen = result[0].isOpen == true ? 1 : 0;
                //                        this.formAdd.setFieldsValue(result[0])
                //                        this.editgetText('a')
                //                        this.formAddOptions.spinning = false
                //                    }, 100);
                //                }
                //                else {
                //                    this.$message.error(response.data.msg);
                //                    this.formAddOptions.spinning = false;
                //                }
                //            } else {
                //                // vue.prototype.$message.info('This is an error message');
                //            }
                //        })
                //        .catch(function (error) { // 请求失败处理
                //            console.log(error)
                //        })
                //    if (action === 'view') { // 预览模式
                //        this.formAddOptions.submitVisible = false
                //        this.formAddOptions.viewMode = true
                //    }
                //}
                //else {
                setTimeout(() => this.formAdd.setFieldsValue({
                    wzid: record.wzid,
                    cbid: record.cbid,
                    town: record.town,
                    wzmc: record.wzmc,
                    type: _this.opration,
                }), 100);
                    this.formAddOptions.visible = true;
                //}
            },
            formAddSubmit() {
                this.formAdd.validateFields((err, values) => {
                    if (!err) {
                        //console.log(values); return;
                        values.townId = this.$User.user.userInfo.departLoginid;
                        this.formAddOptions.submitLoading = true
                        this.$axios({
                            url: '/api/fxjc/InOrOutWzxx',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('保存成功')
                                    this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false
                                    this.formAddOptions.dateVisible = false
                                    this.bindList()
                                } else {
                                    console.log(res);
                                    this.$message.info(res.data.msg)
                                    //throw new Error(res.data.msg);
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })
                    }
                })
            },

            filesCancelPreview() {
                this.previewVisible = false
            },
            async filesPreview(file) {
                //console.log(file.ext)
                // if (!file.url && !file.preview) {
                //   file.preview = await getBase64(file.originFileObj)
                // }
                // this.previewImage = file.url || file.preview
                // this.previewVisible = true
                location.href = file.url
            },
            filesChange: function (file) {
                if (file.file.status === 'removed') {
                    this.fileList = file.fileList
                }
            },
            customFilesUpload(data) {
                const formData = new FormData()
                formData.append('file', data.file)
                //formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
                this.saveFile(formData)
            },
            saveFile(formData) {
                this.$axios(
                    {
                        method: 'post',
                        url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
                        data: formData
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            var num = this.fileList.length
                            this.fileList.push(res.data.data)
                            this.uploadgetText('a', num, res.data);
                        }
                        else {
                            throw new Error("服务器器超时");
                        }
                    })
                    .catch((error) => {
                        this.$message.error(error.message);
                        console.log(error)
                    })
            },
            init() {
                var that = this
                size()
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 270
                        }
                    })
                }
            },
            /**
             * @param {String} classname；upload的类名
             */
            editgetText(classname) {
                this.$nextTick(() => {
                    var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    //console.log(els)
                    for (var i = 0, len = els.length; i < len; i++) {
                        var type = els[i].textContent.split('.')[1]
                        if (type !== 'png' && type !== 'jpg') {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = els[i].textContent
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + type
                            }
                            cel.innerText = temptext
                            spanels[i].prepend(cel)
                        }
                    }
                })
            },
            /**
             * @param {String} classname；upload的类名
             * @param {Number} num；原有的已上传文件数量
             * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
             */
            uploadgetText(classname, num, response) {
                this.$nextTick(() => {
                    //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    var tempNodeList = []
                    for (var i = 0; i < spanels.length - num; i++) {
                        // console.log(spanels[num+i])
                        tempNodeList.push(spanels[num + i])
                        for (var j = 0; j < tempNodeList.length; j++) {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = response.name
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + response.ext
                            }
                            cel.innerText = temptext
                            tempNodeList[j].prepend(cel)
                        }
                    }
                })
            },
            actions() {
                //if (this.inlineButtons.length == 0) {
                //    columns.pop();
                //}
            }
        }
    }

    //function getBase64(file) {
    //    return new Promise((resolve, reject) => {
    //        const reader = new FileReader()
    //        reader.readAsDataURL(file)
    //        reader.onload = () => resolve(reader.result)
    //        reader.onerror = error => reject(error)
    //    })
    //}
</script>
<style>
    .ant-layout-footer {
        display: none;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
        padding: 20px 20px 0;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }
</style>