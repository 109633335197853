<template>
    <div id="components-form-demo-advanced-search">

        <a-spin :spinning="formAddOptions.spinning">
            <a-form :form="formAdd" :label-col="{ span:3}" :wrapper-col="{ span:21}" labelAlign="left">
                <a-form-item label="" style="display:none">
                    <a-input v-decorator="['xmbh']" />
                    <a-input v-decorator="['xczzbh']" />
                </a-form-item>

                <a-row type="flex" justify="space-between" v-if="false">
                    <a-col :span="12">
                        <a-row>
                            <a-form-item label="所属站闸">
                                <p>{{zzmc}}</p>
                            </a-form-item>
                        </a-row>
                    </a-col>
                    <a-col :span="12">
                        <a-row>
                            <a-form-item label="所属工程">
                                <p>{{xmlx}}</p>
                            </a-form-item>
                        </a-row>
                    </a-col>
                </a-row>

                <a-row type="flex" justify="space-between" v-if="title_visible">
                    <a-col :span="24">
                        <a-row>
                            <a-form-item label="关联巡查问题">
                                <a-select placeholder="请选择问题" :disabled="formAddOptions.viewMode" v-decorator="['wtbh', { rules: [{ required: true, message: '请选择问题' }]}]" @change="xcwt_Select" show-search option-filter-prop="children" :filter-option="filterOption" style="width:100%;">
                                    <a-select-option v-for="items in xcwt_DropData"
                                                     :value="items.bh">
                                        {{items.jcx}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-row>
                    </a-col>
                </a-row>

                <a-row type="flex" justify="space-between">
                    <a-col :span="24">
                        <a-row>
                            <a-form-item label="标题">
                                <a-input v-decorator="['title', { rules: [{ required: true, message: '请输入标题' },{max:300,message:'最大长度为300'}]}]" placeholder="工单标题" :disabled="formAddOptions.viewMode" />
                            </a-form-item>
                        </a-row>
                    </a-col>
                </a-row>

                <a-row type="flex" justify="space-between">
                    <a-col :span="24">
                        <a-row>
                            <a-form-item label="工单内容">
                                <a-input type="textarea" :rows="5" v-decorator="['info', { rules: [{ required: true, message: '请输入工单内容' },{max:3000,message:'最大长度为3000'}]}]" placeholder="工单内容" :disabled="formAddOptions.viewMode" />
                            </a-form-item>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row type="flex" justify="space-between">
                    <a-col :span="24">
                        <a-row>
                            <a-form-item label="照片">
                                <a-upload class='a'
                                          :disabled="formAddOptions.viewMode" name="a"
                                          :multiple="true"
                                          list-type="picture-card"
                                          :file-list="fileList"
                                          @preview="filesPreview"
                                          @change="filesChange"
                                          :customRequest="customFilesUpload">
                                    <div v-if="fileList.length<5">
                                        <a-icon type="plus" />
                                        <div class="ant-upload-text">
                                            上传照片
                                        </div>
                                    </div>
                                </a-upload>
                                <a-modal :visible="previewVisible" :footer="null" @cancel="filesCancelPreview">
                                    <img alt="人像" style="width: 100%" :src="previewImage" />
                                </a-modal>
                            </a-form-item>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row type="flex" justify="space-between">
                    <a-col :span="24">
                        <a-row>
                            <a-form-item label="办理人">
                                <a-row type="flex">
                                    <a-col>
                                        <a-select style="width:150px !important;" @change="handleblrlxChange" placeholder="请选择" v-decorator="['zxrType',{ rules: [{ required: true, message:'请选择办理人类型'}]},]" :disabled="formAddOptions.viewMode">
                                            <a-select-option value="维保公司">
                                                维保公司
                                            </a-select-option>
                                            <a-select-option value="网格员">
                                                网格员
                                            </a-select-option>
                                        </a-select>
                                    </a-col>
                                    <a-col>
                                        <a-select style="width:300px !important;margin-left: 20px;" placeholder="请选择" :disabled="formAddOptions.viewMode" v-decorator="['zxrLoginid',{ rules: [{ required: true, message:'请选择办理人'}]},]">
                                            <a-select-option v-for="items in blr_data"
                                                             :key="items.value">
                                                {{items.name}}
                                            </a-select-option>
                                        </a-select>
                                    </a-col>
                                </a-row>
                            </a-form-item>
                        </a-row>
                    </a-col>
                </a-row>

                <a-row type="flex" justify="space-between">
                    <a-col :span="24">
                        <a-row>
                            <a-form-item label="办理意见">
                                <a-input type="textarea" :rows="5" v-decorator="['sendinfo', { rules: [{ required: true, message: '请输入办理意见' },{max:3000,message:'最大长度为3000'}]}]" placeholder="办理意见" :disabled="formAddOptions.viewMode" />
                            </a-form-item>
                        </a-row>
                    </a-col>
                </a-row>

                <a-row type="flex" justify="space-between">
                    <a-col :span="24">
                        <a-row>
                            <a-form-item label="办理期限">

                                <a-date-picker v-decorator="['qx', config]" :disabled="formAddOptions.viewMode" />
                            </a-form-item>
                        </a-row>
                    </a-col>
                </a-row>
            </a-form>
        </a-spin>
        <a-row type="flex" justify="center">
            <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit">确定</a-button>
        </a-row>
    </div>
</template>
<script>
    import moment from "moment";

    const loading = true
    const strWhere = 'del=0'
    const action = 'add'
    export default {
        data() {
            return {
                showexport: false,
                scroll: {
                    y: 900
                },
                //actionList: {
                //    add: false,
                //    remove: false,
                //    edit: false
                //},
                config: {
                    rules: [{ type: 'object', required: true, message: '请选择办理期限!' }],
                },
                title_visible: false,
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                xcwt_DropData: [],
                xcwt_Data: [],
                blr_data: [],
                blr_wbcompany_data: [],
                blr_wgy_data: [],
                problems_data: [],
                zzmc: '',
                xmlx: '',
                wtbh: '',
                blrlxtext: "666",
                loading,
                action,
                previewVisible: false,
                previewImage: '',
                fileList: [],
                strWhere,
                expand: false,
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
                pagination: {
                    total: 0,
                    pageSize: 15, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
                    showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: 'bottom'
                }

            }
        },
        watch: {
            '$route.query.xczzbh': function (newVal, oldVal) {
                this.title_visible = false;
            }
        },
        mounted() {
            if (this.$route.query.xczzbh != "") { this.title_visible = true }
            //this.formAdd.setFieldsValue({
            //      "blrlx": "维保公司",
            //  })
            //将上面的按钮和表格内的按钮分出来
            for (let i = 0; i < this.$route.meta.buttons.length; i++) {
                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                }
                else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.formAdd.setFieldsValue({
                qx: moment(this.$jsc.formatDate_first7(new Date())),
            })
            this.InitGrid();
            this.init()
            this.actions()
            window.onresize = () => {
                this.init()
            }
        },
        methods: {
            moment,
            InitGrid: function () {
                var _that = this
                var xczzbh = this.$route.query.xczzbh;
                var probh = this.$route.query.probh ? this.$route.query.probh : "";
                this.$axios({
                    url: "/api/zzgl/GetErrXcInfo_new?xczzbh=" + xczzbh + "&wtbh=" + this.wtbh + '&probh=' + probh,
                    method: 'get',
                })
                    .then(function (response) {
                        console.log(response)
                        if (response.status === 200) {
                            if (_that.wtbh == "") {
                                _that.xcwt_DropData = response.data.data
                            }
                            _that.xcwt_Data = response.data.data
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            xcwt_Select(value, ele) {
                for (var i = 0; i < this.xcwt_Data.length; i++) {
                    if (this.xcwt_Data[i].bh == value) {
                        this.zzmc = this.xcwt_Data[i].zzmc;
                        this.xmlx = this.xcwt_Data[i].xmlx;
                        this.fileList = this.xcwt_Data[i].filesPath;
                        this.formAdd.setFieldsValue({
                            title: this.xcwt_Data[i].xcr + " " + this.$jsc.formatDate_hz(this.xcwt_Data[i].xcsj) + " " + "巡查的" + this.xcwt_Data[i].xmlx + "问题",
                            info: this.xcwt_Data[i].zzmc + this.xcwt_Data[i].jcx + "，联系人及电话：" + this.xcwt_Data[i].xcr + "" + this.xcwt_Data[i].lxdh + "",
                            xmbh: this.xcwt_Data[i].xmbh,
                            xczzbh: this.$route.query.xczzbh,
                        })
                    }
                }
            },
            btnClick(text, item) {
                let _this = this;
                switch (text) {
                    case '新增':
                        this.openDlg()
                        break
                    case "编辑":
                        this.openDlg(item);
                        break;
                    case "删除":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定要删除 " + item.companyName + " 吗?",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.del(item);
                            }
                        });
                        break;
                }
            },
            handleblrlxChange(value) {
                let blrlx = value;
                if (blrlx == '维保公司') {
                    this.blr_data = this.blr_wbcompany_data;
                } else if (blrlx == '网格员') {
                    this.blr_data = this.blr_wgy_data;
                }

            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            phoneCheck(rule, value, callbackFn) {
                const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
                if (!reg.test(value)) {
                    callbackFn('请输入正确的手机号码')
                    return
                }
                callbackFn()
            },
            SFZCheck(rule, value, callbackFn) {
                const reg = /^(\d{18}|\d{15}|\d{17}x)$/
                if (!reg.test(value)) {
                    callbackFn('请输入正确的身份证号码')
                    return
                }
                callbackFn()
            },
            toggle() {
                this.expand = !this.expand
                this.init()
            },

            openDlg: async function (record, action) {
                this.formAdd.resetFields()
                this.fileList = []
                this.formAddOptions.submitVisible = true
                this.formAddOptions.viewMode = false
                if (record !== undefined) {
                    // 从后台取数据赋值
                    this.formAddOptions.spinning = true
                    var obj = await this.$jsc.DesEncrypt(this, 'id=zhangbiao' + record.id + 'zhangbiao');
                    obj = obj.data;
                    this.$axios
                        .get('/api/GetList/getListByTable?tableName=zzgl_wbry&strWhere=' + obj + '&filesFields=bh')
                        .then((response) => {
                            if (response.status == 200) {
                                if (response.data.code == 0) {
                                    this.formAddOptions.visible = true;
                                    var result = response.data.data;
                                    console.log(response.data);
                                    setTimeout(() => {
                                        result[0].yyqk = true
                                        // var objArr = { qymc: result[0].qymc, lxr: result[0].lxr, yyqk: true }
                                        // this.formAdd.setFieldsValue(objArr)
                                        this.formAdd.setFieldsValue(result[0])
                                        this.fileList = result[0].files_bh
                                        this.editgetText('a')
                                        this.formAddOptions.spinning = false
                                    }, 100);
                                }
                                else {
                                    this.$message.error(response.data.msg);
                                    this.formAddOptions.spinning = false;
                                }
                            } else {
                                // vue.prototype.$message.info('This is an error message');
                            }
                        })
                        .catch(function (error) { // 请求失败处理
                            console.log(error)
                        })
                    if (action === 'view') { // 预览模式
                        this.formAddOptions.submitVisible = false
                        this.formAddOptions.viewMode = true
                    }
                }
                else {
                    this.formAddOptions.visible = true;
                }
            },
            filesCancelPreview() {
                this.previewVisible = false
            },
            async filesPreview(file) {
                //console.log(file.ext)
                // if (!file.url && !file.preview) {
                //   file.preview = await getBase64(file.originFileObj)
                // }
                // this.previewImage = file.url || file.preview
                // this.previewVisible = true
                location.href = file.url
            },
            filesChange: function (file) {
                if (file.file.status === 'removed') {
                    this.fileList = file.fileList
                }
            },
            customFilesUpload(data) {
                const formData = new FormData()
                formData.append('file', data.file)
                //formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
                this.saveFile(formData)
            },
            formAddSubmit() {
                this.formAdd.validateFields((err, values) => {
                    //if(this.fileList.length==0)
                    //{
                    //	this.$message.error('请上传照片');
                    //	return;
                    //}
                    if (!err) {
                        this.formAddOptions.submitLoading = true
                        values.files_bh = this.fileList
                        values.qx = values['qx'].format('YYYY-MM-DD')//办理期限格式化

                        console.log(values);
                        //return;

                        this.$axios({
                            url: '/api/zzgl/InsWorkOrders_new',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('保存成功')
                                    // this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false
                                    this.$router.push({ path: "/gcyxgl/hdshsy/workSingle-List?isEnd=0" })
                                } else {
                                    console.log(res);
                                    throw new Error("服务器超时2");
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })
                    }
                })
            },
            saveFile(formData) {
                var that = this;
                this.$axios(
                    {
                        method: 'post',
                        url: this.$axios.defaults.fileURL + "/api/FileSet/Upload",
                        data: formData
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            var num = that.fileList.length
                            that.fileList.push(res.data.data)
                            // that.uploadgetText('a', num, res.data.data);
                        }
                        else {
                            throw new Error("服务器器超时");
                        }
                    })
                    .catch((error) => {
                        that.$message.error(error.message);
                        console.log(error)
                    })
            },

            getcompanylist() {
                // 获取维保企业列表
                this.$axios({
                    url: '/api/Zzgl/getWbCompany',
                    method: 'get',

                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code === 0) {
                            this.blr_wbcompany_data = res.data.data;
                            this.blr_data = this.blr_wbcompany_data;
                        } else {
                            console.log(res);
                            throw new Error("服务器超时2");
                        }
                    }
                    else {
                        throw new Error("服务器超时2");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                    this.formAddOptions.submitLoading = false
                })
            },
            getwgylist() {
                // 获取网格员列表
                this.$axios({
                    url: '/api/Zzgl/GetXcy',
                    method: 'get',

                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code === 0) {
                            this.blr_wgy_data = res.data.data;
                        } else {
                            console.log(res);
                            throw new Error("服务器超时2");
                        }
                    }
                    else {
                        throw new Error("服务器超时2");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                    this.formAddOptions.submitLoading = false
                })
            },
            init() {
                var that = this
                size()
                this.getcompanylist()
                this.getwgylist()
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 270
                        }
                    })
                }

            },
            /**
             * @param {String} classname；upload的类名
             */
            editgetText(classname) {
                this.$nextTick(() => {
                    var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    //console.log(els)
                    for (var i = 0, len = els.length; i < len; i++) {
                        var type = els[i].textContent.split('.')[1]
                        if (type !== 'png' && type !== 'jpg') {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = els[i].textContent
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + type
                            }
                            cel.innerText = temptext
                            spanels[i].prepend(cel)
                        }
                    }
                })
            },
            /**
             * @param {String} classname；upload的类名
             * @param {Number} num；原有的已上传文件数量
             * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
             */
            uploadgetText(classname, num, response) {
                this.$nextTick(() => {
                    //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    var tempNodeList = []
                    for (var i = 0; i < spanels.length - num; i++) {
                        // console.log(spanels[num+i])
                        tempNodeList.push(spanels[num + i])
                        for (var j = 0; j < tempNodeList.length; j++) {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = response.name
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + response.ext
                            }
                            cel.innerText = temptext
                            tempNodeList[j].prepend(cel)
                        }
                    }
                })
            },
            actions() {
                if (this.inlineButtons.length == 0) {
                    // columns.pop();
                }
            }
        }
    }

    //function getBase64(file) {
    //    return new Promise((resolve, reject) => {
    //        const reader = new FileReader()
    //        reader.readAsDataURL(file)
    //        reader.onload = () => resolve(reader.result)
    //        reader.onerror = error => reject(error)
    //    })
    //}
</script>
<style>
    /* .ant-layout-footer {
           display: none;
       }
    */
    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
        padding: 20px 20px 0;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
         display: block !important;
       }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }
</style>