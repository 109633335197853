<template>
    <div>
        "http://118.31.71.54:8044/v0/app/MapInitial.js", "http://118.31.71.54:8044/v0/app/KSSmartWater/ksSWInitial.js", "http://118.31.71.54:8044/v0/app/KSSmartWater/test.js", "http://118.31.71.54:8044/v0/app/GLayer/PntFtLayerExt.js"

    </div>
</template>
<script>
    export default {
        //name: 'hdshsytop',
        data() {
            return {

            }
        },
        
    }
</script>