<template>
    <div>
        <workInfo ref="workInfo" :dataOptions="workInfo_Options"></workInfo>
        <workProcess ref="workProcess" :dataOptions="workProcess_Options"></workProcess>

        <!--<a-modal :visible="previewVisible" :footer="null" @cancel="filesCancelPreview">
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>-->

    </div>
</template>
<script>
    import workInfo from './workInfo'
    import workProcess from './workProcess'

    export default {
        data() {
            return {
                loading: '',
                action: '',
                previewVisible: false,
                previewImage: '',
                fileList: [],
                formAddOptions: {
                    visible: true,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false,
                },
                formAddVisible: {
                    clsx: false,
                    step: false,
                    recState: false,
                    recLoginid: false
                },
                workInfo_Options: {
                    workbh:""
                },
                workProcess_Options: {
                    workbh: ""
                },
                queryParam: {
                    processid: this.$route.query.processid,
                    workbh: this.$route.query.workbh,
                },
                clzj: "./work",
                processInfo: {},
            }
        },
        mounted() {
            
        },
        created() {
            this.workInfo_Options.workbh = this.queryParam.workbh;
            this.workProcess_Options.workbh = this.queryParam.workbh;            
        },
        updated(){
            
        },
        components: {
            workInfo,
            workProcess,
        },
        methods: {
        }
    }

</script>
<style scoped>
</style>