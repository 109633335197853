<template>
    <div id="components-form-demo-advanced-search">
        <a-modal v-model="formAddOptions.visible" title="新增" width="800px">
            <a-spin :spinning="formAddOptions.spinning">
                <a-form :form="formAdd" :label-col="{ span:7 }" :wrapper-col="{ span:17 }" labelAlign="left">
                    <a-form-item label="" style="display:none">
                        <a-input v-decorator="['id']" />
                    </a-form-item>
                    <a-row type="flex" justify="space-between">
                        <a-col :span="11">
                            <a-form-item label="企业名称">
                                <a-input v-decorator="['qymc', { rules: [{ required: true, message: '请输入企业名称' }]}]" placeholder="企业名称" :disabled="formAddOptions.viewMode" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="11">
                            <a-form-item label="联系人手机">
                                <a-input v-decorator="[ 'lxrdh',{ rules: [{ required: false, message: '请输入联系人手机' }],},]" style="width: 100%" :disabled="formAddOptions.viewMode">
                                    <a-select slot="addonBefore" v-decorator="['prefix', { initialValue: '86' }]" style="width: 70px" :disabled="formAddOptions.viewMode">
                                        <a-select-option value="86">
                                            +86
                                        </a-select-option>
                                        <a-select-option value="87">
                                            +87
                                        </a-select-option>
                                    </a-select>
                                </a-input>
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row type="flex" justify="space-between">
                        <a-col :span="11">
                            <a-form-item label="职务">
                                <a-input v-decorator="['lxrzw', { rules: [{ required: false, message: '请输入职务' }]}]" placeholder="职务" :disabled="formAddOptions.viewMode" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="11">
                            <a-row>
                                <a-form-item label="走访人姓名">
                                    <a-input v-decorator="['zfr', { rules: [{ required: true, message: '请输入走访人姓名' }]}]" placeholder="走访人姓名" :disabled="formAddOptions.viewMode" />
                                </a-form-item>
                            </a-row>
                        </a-col>
                    </a-row>
                    <a-row type="flex" justify="space-between">
                        <a-col :span="11">
                            <a-form-item label="走访日期">
                                <a-date-picker style="width: 100%;" v-decorator="['zfsj', { rules: [{ required: false, message: '请输入走访日期' }]}]" placeholder="走访日期" :disabled="formAddOptions.viewMode" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="11">
                            <a-form-item label="类型">
                                <a-select v-decorator="['lxr',{ rules: [{ required: true, message: '请输入联系人'}]},]" placeholder="请选择" :disabled="formAddOptions.viewMode">
                                    <a-select-option value="male">
                                        male
                                    </a-select-option>
                                    <a-select-option value="female">
                                        female
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <!--  -->
                    <!--  -->
                    <a-row :gutter="[20,0]" type="flex" justify="space-between">
                        <a-col :span="19">
                            <a-form-item label="总体情况" :label-col="{ span:4 }" :wrapper-col="{ span:17 }">
                                <a-radio-group name="totalsituration" :default-value="1" :disabled="formAddOptions.viewMode">
                                    <a-radio :value="1">
                                        非常好
                                    </a-radio>
                                    <a-radio :value="2">
                                        好
                                    </a-radio>
                                    <a-radio :value="3">
                                        一般
                                    </a-radio>
                                    <a-radio :value="4">
                                        差
                                    </a-radio>
                                    <a-radio :value="5">
                                        非常差
                                    </a-radio>
                                </a-radio-group>
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row type="flex" justify="space-between">
                        <a-col :span="24">
                            <a-form-item label="当前企业生产运营情况" :label-col="{ span:10 }" :wrapper-col="{ span:24 }">
                                <a-textarea style="resize: none;height: 100px;" :disabled="formAddOptions.viewMode" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[20,0]" type="flex" justify="space-between">
                        <a-col :span="19">
                            <a-form-item label="产值" :label-col="{ span:4 }" :wrapper-col="{ span:17 }">
                                <a-radio-group default-value="a" button-style="solid" :disabled="formAddOptions.viewMode">
                                    <a-radio-button value="a">
                                        高增长
                                    </a-radio-button>
                                    <a-radio-button value="b">
                                        一般增长
                                    </a-radio-button>
                                    <a-radio-button value="c">
                                        稳定
                                    </a-radio-button>
                                    <a-radio-button value="d">
                                        下降
                                    </a-radio-button>
                                    <a-radio-button value="e">
                                        严重下降
                                    </a-radio-button>
                                </a-radio-group>
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-divider orientation="left">附件上传</a-divider>
                </a-form>
                <a-upload class='a'
                          :disabled="formAddOptions.viewMode" name="a"
                          :multiple="false"
                          list-type="picture-card"
                          :file-list="fileList"
                          @preview="filesPreview"
                          @change="filesChange"
                          :customRequest="customFilesUpload">
                    <div v-if="fileList.length < 8">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">
                            上传图片
                        </div>
                    </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="filesCancelPreview">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
            </a-spin>
            <template slot="footer">
                <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
                <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
            </template>
        </a-modal>
        <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
            <a-row :gutter="24">
                <a-col :span="6">
                    <a-form-item label="">
                        <a-input placeholder="企业名称" v-decorator="[`qymc`]" />
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="联系人" v-decorator="[`lxr`]" /></a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="姓名" v-decorator="['field3']"></a-input></a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="姓名" v-decorator="['field3']"></a-input></a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24" v-if="expand">
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="姓名" v-decorator="['field5']"></a-input></a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="姓名" v-decorator="['field6']"></a-input></a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="姓名" v-decorator="['field7']"></a-input></a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="姓名" v-decorator="['field3']"></a-input></a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="[24,12]">
                <a-col :span="6" :style="{ textAlign: 'left' }">
                    <a-button type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button>
                    <a-button type='primary' @click="()=>this.$router.push({ path: '/leave/add' })" style='margin-right: 5px'>跳同级组件</a-button>
                    <!-- primary/waring/danger/green -->
                </a-col>
                <a-col :span="18" :style="{ textAlign: 'right' }">
                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                    </a>
                </a-col>
            </a-row>
        </a-form>
        <div class="search-result-list" :style="{ marginTop: '0px' }">
            <a-table :columns="columns"
                     :data-source="tableData"
                     :pagination="pagination"
                     :pageSize="20"
                     :total="50"
                     rowKey="id"
                     :loading="loading"
                     :scroll="scroll"
                     @change="pageCurrentChange"
                     position="bottom"
                     tableLayout="fixed">
                <a slot="qymc" slot-scope="text,record" @click="openDlg(record,'view')">{{ text }}</a>
                <span slot="action" slot-scope="text, record">
                    <a-button :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right: 5px'><a-icon :type="button.iconLink" /></a-button>
                </span>
            </a-table>
        </div>
    </div>
</template>
<script>
    const columns = [
        {
            title: '序号',
            dataIndex: 'rownum',
            width: 100,
            key: 'rownum'
        },
        {
            title: '企业名称',
            dataIndex: 'qymc',
            key: 'qymc',
            width: 150,
            scopedSlots: { customRender: 'qymc' }
        },
        {
            title: '联系人',
            dataIndex: 'lxr',
            width: 100,
            key: 'lxr'
        },
        {
            title: '联系人职务',
            dataIndex: 'lxrzw',
            width: 200,
            key: 'lxrzw'
        },
        {
            title: '联系人电话',
            dataIndex: 'lxrdh',
            width: 200,
            key: 'lxrdh'
        },
        {
            title: '走访人',
            dataIndex: 'zfr',
            width: 100,
            key: 'zfr'
        },
        {
            title: '走访时间',
            dataIndex: 'zfsj',
            width: 300,
            key: 'zfsj'
        },
        {
            title: '随访人员',
            dataIndex: 'sfry',
            width: 500,
            key: 'sfry'
        },
        {
            title: '情况',
            key: 'yyqk',
            dataIndex: 'yyqk'
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            scopedSlots: { customRender: 'action' }
        }
    ]

    const loading = true
    const strWhere = 'del=0'
    const action = 'add'
    export default {
        data() {
            return {
                showexport: false,
                scroll: {
                    x: 2000,
                    y: 900
                },
                //actionList: {
                //    add: false,
                //    remove: false,
                //    edit: false
                //},
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                tableData: [],
                columns,
                loading,
                action,
                previewVisible: false,
                previewImage: '',
                fileList: [],
                strWhere,
                expand: false,
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
                pagination: {
                    total: 0,
                    pageSize: 15, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
                    showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: 'bottom'
                }
            }
        },
        mounted() {
            //将上面的按钮和表格内的按钮分出来
            for (let i = 0; i < this.$route.meta.buttons.length; i++) {
                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                }
                else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.init()
            this.actions()
            window.onresize = () => {
                this.init()
            }
            this.bindList();
            //console.log(this.$route);
            //console.log(this.buttons);
            //console.log(this.inlineButtons);
        },
        methods: {
            btnClick(text, item) {
                let _this = this;
                switch (text) {
                    case '新增':
                        this.openDlg()
                        break
                    case "修改":
                        this.openDlg(item);
                        break;
                    case "删除":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定要删除 " + item.qymc + " 吗?",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.del(item);
                            }
                        });
                        break;
                }
            },
            bindList: async function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1
                    // console.log(values)
                    this.strWhere = 'del=0 '
                    for (var obj in values) {
                        if (values[obj]) {
                            this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
                        }
                    }
                    console.log(this.strWhere)
                    this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
                })
                this.loading = true

                this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere);
                this.strWhere = this.strWhere.data;
                await this.InitGrid()
            },
            InitGrid: function () {
                var _that = this
                _that.loading = true
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'jjfw_qyzf',
                        page: _that.pagination.current,
                        limit: _that.pagination.pageSize,
                        filesFields: 'bh',
                        strWhere: _that.strWhere
                    }
                })
                    .then(function (response) {
                        console.log(response)
                        _that.loading = false
                        if (response.status === 200) {
                            _that.tableData = response.data.data
                            _that.pagination.total = response.data.count
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            formSearchSubmit(e) {
                e.preventDefault()
                this.pagination.current = 1
                this.bindList()
            },
            formSearchReset() {
                this.formSearch.resetFields()
                this.strWhere = 'del=0'
                this.bindList()
            },
            toggle() {
                this.expand = !this.expand
                this.init()
            },
            pageCurrentChange(pagination) {
                this.pagination.current = pagination.current
                this.bindList()
            },
            del(record) {
                this.$axios({
                    url: '/api/Comon/deltag',
                    method: 'post',
                    data: {
                        tableName: 'jjfw_qyzf',
                        field: 'id',
                        value: record.id,
                        source: 'web',
                        loginid: ''
                    }
                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info('删除成功');
                            this.bindList();
                        }
                        else {
                            throw new Error("服务器超时");
                        }
                    }
                    else {
                        throw new Error("服务器超时");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                })
            },
            openDlg: async function (record, action) {
                this.formAdd.resetFields()
                this.fileList = []
                this.formAddOptions.submitVisible = true
                this.formAddOptions.viewMode = false
                if (record !== undefined) {
                    // 从后台取数据赋值
                    this.formAddOptions.spinning = true
                    var obj = await this.$jsc.DesEncrypt(this, 'id=zhangbiao' + record.id + 'zhangbiao');
                    obj = obj.data;
                    this.$axios
                        .get('/api/GetList/getListByTable?tableName=jjfw_qyzf&strWhere=' + obj + '&filesFields=bh')
                        .then((response) => {
                            if (response.status == 200) {
                                if (response.data.code == 0) {
                                    this.formAddOptions.visible = true;
                                    var result = response.data.data;
                                    console.log(response.data);
                                    setTimeout(() => {
                                        result[0].yyqk = true;
                                        if (result[0].zfsj == "1900-01-01 00:00:00") {
                                            result[0].zfsj = "";
                                        }
                                        // var objArr = { qymc: result[0].qymc, lxr: result[0].lxr, yyqk: true }
                                        // this.formAdd.setFieldsValue(objArr)
                                        this.formAdd.setFieldsValue(result[0])
                                        this.fileList = result[0].files_bh
                                        this.editgetText('a')
                                        this.formAddOptions.spinning = false
                                    }, 100);
                                }
                                else {
                                    this.$message.error(response.data.msg);
                                    this.formAddOptions.spinning = false;
                                }
                            } else {
                                // vue.prototype.$message.info('This is an error message');
                            }
                        })
                        .catch(function (error) { // 请求失败处理
                            console.log(error)
                        })
                    if (action === 'view') { // 预览模式
                        this.formAddOptions.submitVisible = false
                        this.formAddOptions.viewMode = true
                    }
                }
                else {
                    this.formAddOptions.visible = true;
                }
            },
            formAddSubmit() {
                this.formAdd.validateFields((err, values) => {
                    if (!err) {
                        values.files_bh = this.fileList
                        this.formAddOptions.submitLoading = true
                        this.$axios({
                            url: '/api/Template/qyzf',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('保存成功')
                                    this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false
                                    this.bindList()
                                } else {
                                    console.log(res);
                                    throw new Error("服务器超时2");
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })

                    }
                })
            },

            filesCancelPreview() {
                this.previewVisible = true
            },
            async filesPreview(file) {
                //console.log(file.ext)
                // if (!file.url && !file.preview) {
                //   file.preview = await getBase64(file.originFileObj)
                // }
                // this.previewImage = file.url || file.preview
                // this.previewVisible = true
                location.href = file.url
            },
            filesChange: function (file) {
                if (file.file.status === 'removed') {
                    this.fileList = file.fileList
                }
            },
            customFilesUpload(data) {
                const formData = new FormData()
                formData.append('file', data.file)
                formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
                this.saveFile(formData)
            },
            saveFile(formData) {
                this.$axios(
                    {
                        method: 'post',
                        url: this.$axios.defaults.fileURL + "/api/FileSet/Upload",
                        data: formData
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            //console.log(res);
                            var num = this.fileList.length
                            this.fileList.push(res.data.data)
                            this.uploadgetText('a', num, res.data.data);
                        }
                        else {
                            throw new Error("服务器器超时");
                        }
                    })
                    .catch((error) => {
                        this.$message.error(error.message);
                        console.log(error)
                    })
            },
            init() {
                var that = this
                size()
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 270
                        }
                    })
                }
            },
            /**
             * @param {String} classname；upload的类名
             */
            editgetText(classname) {
                this.$nextTick(() => {
                    var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    //console.log(els)
                    for (var i = 0, len = els.length; i < len; i++) {
                        var type = els[i].textContent.split('.')[1]
                        if (type !== 'png' && type !== 'jpg') {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = els[i].textContent
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + type
                            }
                            cel.innerText = temptext
                            spanels[i].prepend(cel)
                        }
                    }
                })
            },
            /**
             * @param {String} classname；upload的类名
             * @param {Number} num；原有的已上传文件数量
             * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
             */
            uploadgetText(classname, num, response) {
                if (response.ext == ".jpg" || response.ext == ".png" || response.ext == ".gif" || response.ext == ".jpeg") {
                    return false;
                }
                this.$nextTick(() => {
                    //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    var tempNodeList = []
                    for (var i = 0; i < spanels.length - num; i++) {
                        // console.log(spanels[num+i])
                        tempNodeList.push(spanels[num + i])
                        for (var j = 0; j < tempNodeList.length; j++) {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = response.name
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + response.ext
                            }
                            cel.innerText = temptext
                            tempNodeList[j].prepend(cel)
                        }
                    }
                })
            },
            actions() {
                if (this.inlineButtons.length == 0) {
                    columns.pop();
                }
            }
        }
    }

    //function getBase64(file) {
    //    return new Promise((resolve, reject) => {
    //        const reader = new FileReader()
    //        reader.readAsDataURL(file)
    //        reader.onload = () => resolve(reader.result)
    //        reader.onerror = error => reject(error)
    //    })
    //}
</script>
<style>
    .ant-layout-footer {
        display: none;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
        padding: 20px 20px 0;
    }


</style>