<template>
    <div>
        <a-spin :tip="spin.message" :spinning="spin.spinning">
            <h1>{{zzname}} {{yyear}}年{{mmonth}}月 百分考核表</h1>
            <div>
                <div style="float:left;">
                    <a-button style="width:100px; z-index:9999;" type="" @click="()=>$router.go(-1)">返回</a-button>
                    <a-button type="primary" @click="dcpdf" style="margin-left:16px;">导出</a-button>
                </div>
                <div style="float:right;">
                    <h2>总分值：{{zscore}}分，总扣分：{{zscore-zdf}}分，总得分：{{zdf}}分</h2>
                </div>
                <div style="clear:both;"></div>
            </div>

            <template v-for="(item,index) in data">
                <a-card :title="item.xmmc+'--'+item.message" style="margin-top:12px;">
                    <a slot="extra">分值：{{item.score}}，<font color="red">扣分：{{item.kf}}</font>，<font color="green">得分：{{item.df}}</font></a>
                    <template v-for="(subItem,index) in item.test">
                        <a-divider v-if="index>0" />
                        <div>
                            <div style="float:left;">
                                <p>扣分：{{subItem.kf}}</p>
                                <p>扣分原因：{{subItem.kfyy}}</p>
                                <p>
                                    照片：
                                    <template v-for="(imgitem,index) in subItem.filesList">
                                        <a target="_blank" :href="imgitem.url"><img :src="imgitem.url" style="height:100px; width:100px;" /></a>
                                    </template>
                                </p>
                            </div>
                            <div style="float:right;">
                                <a-button type="danger" @click="del(subItem)">删除</a-button>
                            </div>
                        </div>
                    </template>

                    <p v-if="item.test.length==0">无扣分</p>
                </a-card>
            </template>
        </a-spin>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                data: [],
                zzname: this.$route.query.zzname,
                yyear: this.$route.query.yyear,
                mmonth: this.$route.query.mmonth,
                zzbh: this.$route.query.zzbh,
                subbh: this.$route.query.subbh,
                zscore: 100,
                zkf: 0,
                zdf:100,
                spin: {
                    message: "加载中...",
                    spinning: false,
                }
            };
        },
        computed: {

        },
        mounted() {

        },
        created() {
            this.bindData();


        },
        methods: {
            dcpdf() {
                this.spin.spinning = true;
                this.spin.message = "正在生成PDF，请稍候...";
                this.$axios({
                    url: this.$axios.defaults.fileURL + "/api/dcpages/htmlTopdf_dfckhb?yyear=" + this.yyear + "&mmonth=" + this.mmonth + "&zzbh=" + this.zzbh + "&subbh=" + this.subbh + "&zzname=" + this.zzname,
                    method: 'get',
                }).then(res => {
                    console.log(res.data.data);
                    //location.href = res.data;
                    this.spin.spinning = false;
                    window.open(res.data.data);
                }).catch(error => { // 请求失败处理
                    this.$message.error("生成失败");
                    this.spin.spinning = false;
                })
            },
            bindData() {
                this.$axios({
                    url: '/api/Xckh/getkfResult',
                    method: 'get',
                    params: {
                        subbh: this.subbh,
                        zzbh: this.zzbh,
                        yyear: this.yyear,
                        mmonth: this.mmonth
                    }
                }).then((res) => {
                    if (res.data.code == 0) {
                        this.data = res.data.data.data;
                        this.zscore = res.data.data.zscore;
                        this.zkf = res.data.data.zkf;
                        this.zdf = res.data.data.zdf;
                        console.log(this.data);
                    }
                }).catch(function (error) {
                    console.log(error)
                });
            },
            del(record) {
                var _this = this;
                this.$confirm({
                    title: '操作确认',
                    content: "确定要删除 " + record.kfyy + " 吗?",
                    okText: '确认',
                    cancelText: '取消',
                    onOk() {
                        _this.$axios({
                            url: '/api/Comon/deltag',
                            method: 'post',
                            data: {
                                tableName: 'zzgl_xcgl_test',
                                field: 'id',
                                value: record.id,
                                source: 'web',
                                loginid: ''
                            }
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code == 0) {
                                    _this.$message.info('删除成功');
                                    _this.bindData();
                                }
                                else {
                                    throw new Error("服务器超时");
                                }
                            }
                            else {
                                throw new Error("服务器超时");
                            }
                        }).catch(error => { // 请求失败处理
                            _this.$message.error(error.message);
                        });
                    }
                });



            }
        },
    };
</script>
<style scoped>
    h1 {
        font-size: 24px;
        text-align: center;
    }

    h2 {
        text-align: right;
    }
</style>