<template>
    <div>

        <a-descriptions :column="2" style="marginBottom: 25px;">
            <a-descriptions-item
                v-for="item in info" 
                :key="item.name"
                :label="item.name">
                {{ item.value }}
            </a-descriptions-item>
        </a-descriptions>

        <a-descriptions style="marginBottom: 15px;" :column="1" bordered v-for="(item1,index) in question" :key="index">
            <span slot="title" style="fontSize: 16px;">{{ item1.title }}</span>
            <a-descriptions-item
                v-for="item2 in item1.children" 
                :key="item2.name"
                :label="item2.name">
                <template v-if="Array.isArray(item2.value)">
                    <a 
                    class="current-img-link"
                    target="_blank" 
                    v-for="item3 in item2.value" 
                    :key="item3.url" 
                    :href="item3.url">
                        <img :src="item3.url" :alt="item3.name">
                    </a>
                </template>
                <template v-else>
                    {{ item2.value }}
                </template>
            </a-descriptions-item>
        </a-descriptions>

    </div>
</template>

<script>
    import SQLCompiler from '@/assets/js/SQLCompiler.js'

    const infoField = {
        xcgc: '巡查工程' ,
        xccj: '巡查层级',
        gclx_view: '工程类型',
        xcr: '巡查人员',
        xcrDepart: '巡查单位',
        createTime_view: '巡查时间' 
    }

    const quField = {
        info: '问题描述',
        kf: '扣分',
        files_bh: '现场照片',
    }

    export default {
        props: ['bh'],
        data() {
            return {
                info: [],
                question: []
            }
        },
        methods: {
            async getPatrolInfo () {
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'slgcxczg_rcxcInfo',
                        page: 1,
                        limit: 10000,
                        strWhere: await SQLCompiler.encrypt(` del=0 and bh='${this.bh}' `),
                    }
                }).then(res => {
                    let { code, data } = res.data
                    if (!code === 0) return
                        // 处理特殊字段
                        let { createTime,  gclx} = data[0]
                        data[0].createTime_view =  createTime.slice(0, 10)
                        data[0].gclx_view = ['排涝站', '防洪闸', '堤防'][gclx]
                        this.info = Object.keys(infoField).map(key => {
                            return {
                                name: infoField[key],
                                value: data[0][key]
                            }
                        })
                }).catch(err => err)
            },
            async getPatrolQu () {
                this.$axios({
                        url: '/api/GetList/getListByTable',
                        method: 'get',
                        params: {
                            tableName: 'v_slgcxczg_rcxcItem',
                            page: 1,
                            limit: 10000,
                            strWhere: await SQLCompiler.encrypt(` infobh='${this.bh}' `),
                            filesFields: 'bh'
                        },
                    })
                        .then(res => {
                            let { code, data } = res.data
                            if (code !== 0) return

                            let temp = []
                            data.forEach(d => {
                                let obj = {}
                                obj.title = d.title
                                obj.children = Object.keys(quField).map(key => {
                                    return {
                                        name: quField[key],
                                        value: d[key]
                                    }
                                })
                                temp.push(obj)
                            })
                            this.question = temp
                        })
                        .catch(err => err)
            },
        },
        created () {
            this.$nextTick(() => {
                this.getPatrolInfo()
                this.getPatrolQu()
            })
        },
    }
</script>

<style lang="less" scoped>
    .current-img-link {
        img {
            vertical-align: top;
            width: 100px;
            height: 100px;
        }

        &:not(:last-child) {
            margin-right: 5px;
        }
    }
</style>