import { render, staticRenderFns } from "./global_statistics.vue?vue&type=template&id=756f9732&scoped=true&"
import script from "./global_statistics.vue?vue&type=script&lang=js&"
export * from "./global_statistics.vue?vue&type=script&lang=js&"
import style0 from "./global_statistics.vue?vue&type=style&index=0&id=756f9732&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "756f9732",
  null
  
)

export default component.exports