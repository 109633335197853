<template>
    <layout-content-with-drawer :sider-width="498">
        <!-- 侧边栏  -->
        <template slot="sider">
            <!-- 搜索 -->
            <a-form :form="form" layout="inline" class="cond_form">
                <a-row>
                    <a-col :span="8">
                        <a-form-item>
                            <a-input v-decorator="['woNumber']" placeholder="工单编号"  />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item>
                            <a-select v-decorator="['polder']" placeholder="所在圩区">
                                <a-select-option value="1">1</a-select-option>
                                <a-select-option value="2">2</a-select-option>
                                <a-select-option value="3">3</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item>
                            <a-input v-decorator="['sgName']" placeholder="所在河道"  />
                        </a-form-item>
                    </a-col>

                    <a-col :span="16">
                        <a-form-item>
                            <a-range-picker v-decorator="['date']" showTime style="width:100%;" />
                        </a-form-item>
                    </a-col>
                    
                    <a-col :span="8">
                        <a-form-item>
                            <a-select v-decorator="['status']" placeholder="工单状态">
                                <a-select-option value="待审核">待审核</a-select-option>
                                <a-select-option value="待审核">待整改</a-select-option>
                                <a-select-option value="待审核">已完结</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <!-- 搜索按钮组 -->
                    <a-col :span="24" class="col_btn_group">
                        <a-space>
                            <a-button type="primary" icon="search" @click="onSearch">搜索</a-button>
                            <a-button icon="redo" @click="onReset">重置</a-button>
                        </a-space>
                    </a-col>
                </a-row>
            </a-form>
            <!-- 列表 -->
            <a-table
                rowKey="id"
                size="small"
                :columns="columns" 
                :data-source="dataSource"
                :pagination = "pagination"
                :scroll="scroll"
                :customRow="customRow"
                :rowClassName="rowClassName"
                @change="onChangePage">
                <template slot="action" slot-scope="record">
                    <a-button
                        type="primary"
                        icon="file-search"
                        size="small"
                        @click.stop="onLookup(record)" />
                </template>
            </a-table>
        </template>

        <!-- 内容 -->
        <template slot="content">
            <!-- 存放地图位置 -->
            <div id="map" class="wh-100p"></div>
        </template>

        <!-- 其他 -->
        <template slot="extra">
            <!-- table record -->
            <a-modal 
                v-model="showRecord"
                :centered="true"
                :footer="null"
                :width="320"
                :mask="false"
                :maskClosable="false"
                >
                <a-row  :gutter="[0, 12]">
                    <a-col :span="24" v-for="rs in recordSelected" :key="rs.title">
                        {{ rs.title }}：{{ rs.value }}
                    </a-col>
                </a-row>
                <div style="textAlign:center;paddingTop:15px;">
                        <a-space>
                            <a-button type="link" @click="showWoRecord=true">
                                工单记录
                            </a-button>
                            <a-button type="primary" @click="showWo=true">
                                查看详情
                            </a-button>
                        </a-space>
                    </div>
            </a-modal>

            <!-- 工单记录 -->
            <a-modal
                v-model="showWoRecord"
                title="工单记录"
                class="wo_record_modal"
                :centered="true"
                :footer="null"
                :width="360">
                <a-timeline>
                    <a-timeline-item>
                        <p>操作状态：上报</p>
                        <p>操作人员：xxx</p>
                        <p>2020-09-29 08:32:15</p>
                    </a-timeline-item>
                    <a-timeline-item>
                        <p>操作状态：审核</p>
                        <p>操作人员：xxx</p>
                        <p>2020-09-29 08:32:15</p>
                    </a-timeline-item>
                    <a-timeline-item>
                        <p>操作状态：提交整改</p>
                        <p>操作人员：xxx</p>
                        <p>2020-09-29 08:32:15</p>
                    </a-timeline-item>
                    <a-timeline-item>
                        <p>操作状态：复审通过--完结</p>
                        <p>操作人员：xxx</p>
                        <p>2020-09-29 08:32:15</p>
                    </a-timeline-item>
                </a-timeline>
            </a-modal>

            <!-- 提防工单 -->
            <a-modal 
                v-model="showWo"
                title="站闸工单"
                class="wo_modal"
                :centered="true"
                :footer="null"
                :width="620">

                <a-tabs 
                    default-active-key="1" 
                    tab-position="left">
                    <a-tab-pane key="1" tab="巡检">
                        <a-row :gutter="[0, 24]">
                            <a-col :span="12">
                                <span>工单编号：</span>202104210028
                            </a-col>
                            <a-col :span="12">
                                <span>提防名称：</span>周家埭站闸
                            </a-col>
                            <a-col :span="12">
                                <span>所在圩区：</span>庙泾
                            </a-col>
                            <a-col :span="12">
                                <span>河道名称：</span>周家埭港
                            </a-col>
                            <a-col :span="12">
                                <span>上报时间：</span>2021-04-21 09:20:01
                            </a-col>
                            <a-col :span="12">
                                <span>上报人员：</span>xxx
                            </a-col>
                            <a-col :span="12">
                                <span>当前状态：</span>已完结
                            </a-col>
                            <a-col :span="12">
                                <span>事件地址：</span>苏州市昆山市星海公寓8号楼7室诗奈尔凯迪城店东北约74米 <a-icon type="environment" :style="{ color: '#1890ff' }" />
                            </a-col>
                        </a-row>
                        <a-divider orientation="left" :style="{ color: '#1890ff', fontSize:'16px', marginTop: '30px' }">站容站貌</a-divider>
                        <a-row :gutter="[0, 24]">
                            <a-col :span="24">
                                <span>问题详情：</span>房屋墙面不洁
                            </a-col>
                            <a-col :span="24">
                                <span>问题说明：</span>有垃圾
                            </a-col>
                            <a-col :span="24">
                                <span>现场照片：</span>
                                <a-space>
                                    <img src="/img/login-bg.png" alt="xxx">
                                    <img src="/img/login-bg.png" alt="xxx">
                                </a-space>
                            </a-col>
                        </a-row>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="审核">
                        <a-row :gutter="[0, 24]">
                            <a-col :span="24">
                                <span>是否建议整改：</span>是
                            </a-col>
                            <a-col :span="24">
                                <span>工单级别：</span>一般
                            </a-col>
                            <a-col :span="24">
                                <span>选择整改人：</span>xxx
                            </a-col>
                            <a-col :span="24">
                                <span>建议完成工单：</span>2021-04-21 09:20:01
                            </a-col>
                            <a-col :span="24">
                                <span>初审意见：</span>xxxxxx
                            </a-col>
                        </a-row>
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="整改">
                        <a-row :gutter="[0, 24]">
                            <a-col :span="24">
                                <span>建议完成时间：</span>2021-04-21 09:20:01
                            </a-col>
                            <a-col :span="24">
                                <span>整改人员：</span>xxx
                            </a-col>
                            <a-col :span="24">
                                <span>整改说明：</span>已上报维修
                            </a-col>
                            <a-col :span="24">
                                <span>现场照片：</span>
                                <a-space>
                                    <img src="/img/login-bg.png" alt="xxx">
                                    <img src="/img/login-bg.png" alt="xxx">
                                </a-space>
                            </a-col>
                        </a-row>
                    </a-tab-pane>
                    <a-tab-pane key="4" tab="复审">
                        <a-row :gutter="[0, 24]">
                            <a-col :span="24">
                                <span>是否办结：</span>是
                            </a-col>
                            <a-col :span="24">
                                <span>复审意见：</span>xxxxxx
                            </a-col>
                        </a-row>
                    </a-tab-pane>
                </a-tabs>

            </a-modal>
        </template>

    </layout-content-with-drawer>
</template>

<script>
    import { loadMap } from '@/assets/js/map.js'
    import LayoutContentWithDrawer from '@/components/LayoutContentWithDrawer'

    let columns = [
        {   
            title: '工单编号',
            dataIndex: 'a'
        },
        {
            title: '所在联圩',
            dataIndex: 'g'
        },
        {
            title: '所在河道',
            dataIndex: 'b'
        },
        {
            title: '上报时间',
            dataIndex: 'c'
        },
        {
            title: '工单耗时',
            dataIndex: 'd'
        },
        {
            title: '是否超期',
            dataIndex: 'e',
            sorter: (a, b) => a.e.localeCompare(b.e)
        },
        {
            title: '状态',
            dataIndex: 'f'
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            scopedSlots: { customRender: 'action' }
        }
    ]

    let recordSelected = [
        {
            title: '所在河道',
            value: 'xxx'
        },
        {
            title: '所在联圩',
            value: 'xxx'
        },
        {
            title: '上报时间',
            value: '2020-09-29 08:32:15'
        },
        {
            title: '巡检人',
            value: 'xxx'
        },
        {
            title: '地点',
            value: '苏州市昆山市星海公寓8号楼7室诗奈尔凯迪城店东北约74米'
        },
        {
            title: '状态',
            value: 'xxx'
        }
    ]

    export default {
        components: {
            LayoutContentWithDrawer
        },
        data () {
            return {
                // form
                form: this.$form.createForm(this, { name: 'cond' }),
                // table
                columns: Object.freeze(columns),
                dataSource: [
                    {
                        id: 0,
                        a: '123',
                        b: '123',
                        c: '123',
                        d: '123',
                        e: '是',
                        f: '123',
                        g: '123'
                    },
                    {
                        id: 1,
                        a: '1234',
                        b: '1234',
                        c: '1234',
                        d: '1234',
                        e: '否',
                        f: '1234',
                        g: '1234'
                    },
                    {
                        id: 2,
                        a: '12345',
                        b: '12345',
                        c: '12345',
                        d: '12345',
                        e: '是',
                        f: '12345',
                        g: '12345'
                    }
                ],
                pagination: {
                    total: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条`
                },
                scroll: {
                    x: 700,
                    y: 900
                },
                recordSelected,
                // 对话框
                showRecord: false,
                showWoRecord: false,
                showWo: false
            }
        },
        methods: {
            initMap () {
                loadMap().then(([MI, ksSWInitial]) => {
                    let mi = new MI({
                        zoom: 10,
                        center_lng: 120.966666,
                        center_lat: 31.322222,
                        container: "map",
                        maptype: 1,
                        OMKey: "11"
                    })
                    this.mi = mi

                    let xcw = new ksSWInitial({ mi })
                    xcw.initial();  //智慧水利相关功能初始化
                    xcw.setAllBaseSubLayerVisibility(false) //关闭所有水系基础图层
                    xcw.setAllDBSubLayerVisibility(false) //关闭所有业务图层
                    xcw.setBaseWaterSubLayerVisibility('River', true) //打开河道图层，配置为false，则关闭河道图层，第一个参数为指代图层的target参数
                    xcw.setBaseWaterSubLayerVisibility('Lake', true) //打开湖泊图层
                    xcw.setBaseWaterSubLayerVisibility('TownBoundary', true) //打开乡镇界线图层
                    xcw.setBaseWaterSubLayerVisibility('Mask', true) //打开掩膜图层
                    xcw.setBoundary('昆山市')
                    
                })
            },
            onSearch () {
                console.log('搜索')
            },
            onReset () {
                console.log('重置')
            },
            customRow (record) {
                return {
                    on: {
                        click: () => {
                            console.log(record)
                        }
                    }
                }
            },
            rowClassName (record, index) {
                let className = "dark_row"
                if (index % 2 === 1) className = "light_row"
                return className
            },
            onChangePage ({ current }) {
                this.pagination.current = current
            },
            onLookup (record) {
                console.log(record)
                this.showRecord = true
            }
        },
        created () {
            this.initMap()
        }
    }
</script>

<style lang="less" scoped>
    .cond_form {

        & /deep/ .ant-form-item {
            display: flex;
            margin-right: 0;
        }

        & /deep/ .ant-form-item-control-wrapper {
            flex: 1;
            padding: 0 3px;
        }

        .col_btn_group {
            margin: 3px 0 15px;
            padding-right: 3px;
            text-align: right;
        }
    }

    // 隔行变色
    /deep/ .light_row { 
        background-color: #fff;
    }
    /deep/ .dark_row {
        background-color: #fafafa;
    }

    // 工单记录对话框
    .wo_record_modal {

            p {
                font-size: 12px;
                margin-bottom: 0;
            }

            & p:not(:nth-child(3n))  {
                color: #303133;
            }
    
            & p:nth-child(3n) {
                color: #909399;
                margin-top: 4px;
            }

    }

    // 站闸工单对话框
    .wo_modal {
            
            span {
                color: #303133;
            }

            img { 
                width: 106px;
                height: 106px;
                vertical-align: top;
            }

            /deep/ .ant-tabs-tabpane {
                font-size: 12px;
            } 

    }
</style>