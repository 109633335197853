<template>
    <div style="width:100%; height:100%;">
        <h1>防汛系统首页</h1>
        <a @click="()=>$router.push({path:'/fxjcsystem/fxwz'})">防汛物资管理</a>
    </div>
</template>
<script>
    
    import { pathFindRouter } from '../router.js'

    export default {
        data() {
            return {

            }
        },
        created: function () {
            let path = this.$route.path;
            //var temp = pathFindRouter("/fxjcsystem_new");
            console.log(path+"");
        }
    }
</script>
