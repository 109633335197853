<template>
    <div>
        <h1>我的请假记录</h1>
        <p>{{msg}}</p>
        <template v-for="button in buttons">
            <a-button type="primary" :key="button.title">
                {{button.title}}
            </a-button>
        </template>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                msg: "我是leave/list组件",
                buttons: this.$route.meta.buttons
            }
        },
        created() {
            console.log(this.$route)
        }
    }
</script>