<template>
    <div>
        <!-- 条件搜索 -->
        <a-form :form="form" layout="inline" style="marginBottom:15px;">
            <a-form-item>
                <a-input v-decorator="['Name']" placeholder="闸泵站名称" />
            </a-form-item>

            <a-form-item>
                <a-input v-decorator="['JGCompany']" placeholder="监管单位" />
            </a-form-item>

            <a-form-item>
                <a-input v-decorator="['Village']" placeholder="村社区" />
            </a-form-item>

            <a-form-item>
                <a-input v-decorator="['Town']" placeholder="乡镇" />
            </a-form-item>

            <a-form-item>
                <a-input v-decorator="['Polder']" placeholder="圩区" />
            </a-form-item>

            <a-form-item>
                <a-select v-decorator="['Type']" placeholder="分类" style="width: 174px !important;" >
                    <a-select-option value="站闸">站闸</a-select-option>
                    <a-select-option value="泵站">泵站</a-select-option>
                    <a-select-option value="水闸">水闸</a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item>
                <a-select v-decorator="['yadj']" placeholder="预案等级" style="width: 174px !important;">
                    <a-select-option value="一级预案">一级预案</a-select-option>
                    <a-select-option value="二级预案">二级预案</a-select-option>
                    <a-select-option value="三级预案">三级预案</a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item>
                <a-space>
                    <a-button type="primary" icon="search" @click="operForm('search')">搜索</a-button>
                    <a-button icon="redo" @click="operForm('reset')" >重置</a-button>
                </a-space>
            </a-form-item>
        </a-form>

        <!-- 表格 -->
        <a-table
            :rowKey="record => record.rnn"
            :columns="columns" 
            :data-source="dataSource"
            :pagination="pagination"
            :scroll="scroll"
            :loading="loading"
            @change="changePage">
            <template slot="title">
                堤防巡检的计划说明：每月巡查堤防一次，原则上堤上巡查，可根据实际情况坐船巡查。  
            </template>
            <template slot="action" slot-scope="record">
                <a-button icon="setting" @click.stop="setPlan(record)"></a-button>
            </template>
        </a-table>

        <!-- 设置 -->
        <a-modal 
            v-model="showModal" 
            title="关联预案" 
            okText="关联"
            centered
            :width="750"
            :closable="false"
            :afterClose="afterClose"
            @ok="ok">

            <template v-if="recordNeedSet.yadj">
                <a-tag color="green"  style="marginBottom:10px;">
                    已关联预案：<span>{{ recordNeedSet.yadj }} </span>
                </a-tag>
            </template>
            

            <a-table
                :rowKey="record => record.rownum"
                size="small"
                :columns="modalColumns" 
                :data-source="modalDataSource"
                :pagination = "modalPagination"
                :customRow="customRow"
                :row-selection="rowSelection"
                @change="changeModalPage">
            </a-table>

        </a-modal>
    </div>
</template>

<script>
    import SQLCompiler from '@/assets/js/SQLCompiler.js'

    const columns = Object.freeze([
        {
            title: '序号',
            dataIndex: 'rnn',
            width: 80,
            align: 'center'
        },
        {
            title: '类别',
            dataIndex: 'Type',
            width: 80
        },
        {
            title: '闸泵站名称',
            dataIndex: 'Name',
            width: 150,
            ellipsis: true,
            scopedSlots: { customRender: 'name' }
        },
        {
            title: '监管单位',
            dataIndex: 'JGCompany',
            ellipsis: true
        },
        {
            title: '村社区',
            dataIndex: 'Village',
            width: 120,
            align: 'center',
            ellipsis: true
        },
        {
            title: '乡镇',
            dataIndex: 'Town',
            width: 120,
            align: 'center',
            ellipsis: true
        },
        {
            title: '圩区',
            dataIndex: 'Polder',
            width: 120,
            align: 'center',
            ellipsis: true
        },
        {
            title: '河道',
            dataIndex: 'Location',
            ellipsis: true
        },
        {
            title: '预案等级',
            dataIndex: 'yadj',
            width: 100,
        },
        {
            title: '操作',
            key: 'action',
            width: 82,
            fixed: 'right',
            scopedSlots: { customRender: 'action' }
        },
    ])
    const modalColumns = Object.freeze([
        {   
            title: '序号',
            dataIndex: 'rownum',
            width: 60,
            align: 'center'
        },
        {
            title: '区镇',
            dataIndex: 'townName',
            width: 80,
        },
        {
            title: '巡查频率',
            dataIndex: 'rate',
            width: 100,
        },
        {
            title: '预案等级',
            dataIndex: 'yadj',
            width: 100
        },
        {
            title: '预案说明',
            dataIndex: 'yasm',
            ellipsis: true,
            width: 160
        },
    ])

    export default {
        data () {
            return {
                // form
                form: this.$form.createForm(this, { name: 'formSearch' }),
                // table
                dataSource: [],
                columns,
                scroll: { 
                    x: '125%', 
                    y: 0,
                    scrollToFirstRowOnChange: true
                },
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                    showTotal: total => `总共${total}条`
                },
                loading: true,
                recordNeedSet: {},
                // modal
                showModal: false,
                modalDataSource: [],
                modalColumns,
                modalPagination: {
                    current: 1,
                    pageSize: 7,
                    total: 0,
                    showTotal: total => `总共${total}条`
                },
                selectedRowKeys: []
            }
        },

        computed: {
            rowSelection () {
                return  {
                    type: 'radio',
                    columnTitle: '选择',
                    columnWidth: 40,
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: selectedRowKeys => this.selectedRowKeys = selectedRowKeys
                }
            }
        },

        methods: {
            operForm (action) {
                const { isFieldsTouched,  resetFields } = this.form

                if (!isFieldsTouched()) return
                if (action === 'reset') {
                    resetFields()
                }
                this.pagination.current = 1
                this.getTableData()
            },
            calcScrollY () {
                this.scroll.y = document.body.offsetHeight - 396
            },
            changePage (page) {
                const { current } = page
                this.pagination.current = current
                this.getTableData()
            },
            setPlan (record) {
                this.recordNeedSet = record
                this.showModal = true
            },
            getTableData () {
                this.loading = true
                const { current, pageSize } = this.pagination
                const { getFieldsValue } = this.form
                this.$axios({
                    url: '/api/GetList/get_v_base_zzxx_gr_List',
                    method: 'post',
                    data: {
                        pageIndex: current,
                        pageSize,
                        where: getFieldsValue()
                    }
                })
                    .then(res => {
                        if (res.status !== 200 || res.data.code !== 0) return 

                        const { list, total } = res.data.data
                        this.dataSource = this._.map(list, value => {
                            return value
                        })
                        this.pagination.total = total

                    })
                    .catch(err => err)
                    .finally(() => this.loading = false)
            },
            customRow (record) {
                const { rownum } = record
                return {
                    on: {
                        click: () => {
                            this.selectedRowKeys = [rownum]
                        }
                    }
                }
            },
            changeModalPage(page) {
                const { current } = page
                this.modalPagination.current = current
                this.getModalData()
            },
            ok () {
                const selectedRowKey = this.selectedRowKeys[0]
                if (!selectedRowKey) return
                const { bh, yadj } =  this._.find(this.modalDataSource, { rownum: selectedRowKey })
                const { BID } = this.recordNeedSet
                this.$axios({
                    url: '/api/rcxc/gcya_update',
                    method: 'post',
                    data: {
                        gcid: BID,
                        yadj,
                        yadjbh: bh
                    }
                })
                    .then(res => {
                        if (res.status !== 200 || res.data.code !== 0) return this.$message.error('关联失败')
                        this.showModal = false
                        this.getTableData()
                        this.$message.success('关联成功')
                    })

            },
            afterClose () {
                this.selectedRowKeys = []
                if (this.modalPagination.current !== 1) {
                    this.modalPagination.current = 1
                    this.getModalData()
                }
            },
            async getModalData () {
                const { current, pageSize } = this.modalPagination
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'slgcxczg_yasetup',
                        page: current,
                        limit: pageSize,
                        strWhere: await SQLCompiler.encrypt('del=0')
                    }
                })
                    .then(res => {
                        if (res.status !== 200 || res.data.code !== 0) return
                        const { count, data } = res.data;
                        this.modalPagination.total = count
                        this.modalDataSource = this._.map(data, value => {
                            return {
                                ...value,
                                rate: `${value.xcpl}次/一${value.xcpldw}`
                            }
                        })
                    })
            }
        },
        created () {
            this.getTableData()
            this.getModalData()
        },
        mounted () {
            this.calcScrollY()
            window.addEventListener('resize', this._.debounce(this.calcScrollY, 150))
            this.$once('hook:beforeDestroy', () => window.removeEventListener('resize', this.calcScrollY))
        },
    }
</script>

<style lang="less" scoped>
    .cond_form {
        & /deep/ .ant-form-item {
            display: flex;
        }

        & /deep/ .ant-form-item-control-wrapper {
            flex: 1;
        }
    }
</style>