<template>
    <div class="root-box">
        <h3 class="head-title">{{ `昆山市${['排涝站', '防洪站', '堤防'][routeQuery.type]}长效管护现场评估表` }}</h3>
        <a-table
            rowKey="id"
            :columns="columns" 
            :data-source="dataSource"
            :loading="loading"
            bordered
            :pagination="false">
            <template slot="customTitle" >
                <div>
                    <span>检查时间：{{ routeQuery.xcsj }}</span>
                    <span style="marginLeft: 60px">得分：{{ routeQuery.total }}</span>
                </div>
            </template>
        </a-table>
    </div>
</template>

<script>
    import SQLCompiler from '@/assets/js/SQLCompiler.js'
    export default {
        data() {
            return {
                columns: [],
                dataSource: [],
                loading: true
            }
        },
        computed: {
            routeQuery () {
                return this.$route.query
            }
        },
        methods: {
            async getDetailOfAssess (id, bh, townName, xcgc) {
                this.loading = true
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: `f_slgcxczg_rcxcInfo_tj_df_detail(${id},'${bh}')`,
                        page: 1,
                        limit: 10000,
                        sort: await SQLCompiler.encrypt('id1 asc')
                    }
                })
                    .then(res => {
                        const { code, data } = res.data
                        if (code !== 0) return
                        this.dataSource = data

                        let mergeRules = []
                        for (let [i, d] of data.entries()) {
                            const sameRule =  mergeRules.find(mr => mr.name === d.zb2)
                            if (sameRule) {
                                sameRule.count++
                                continue 
                            }
                            mergeRules.push({
                                name: d.zb2,
                                index: i,
                                count: 1
                            })
                        }

                        this.columns = [
                            {
                                title: `检查区镇：${townName}`,
                                children: [
                                    {
                                        title: '考核内容',
                                        dataIndex: 'zb2',
                                        width: 120,
                                        customRender: (text, row, index) => {
                                            
                                            const ruleFound = mergeRules.find(mr => mr.index === index)
                                            if (ruleFound) {
                                                return { children: text, attrs: { rowSpan: ruleFound.count } }
                                            }
                                    
                                            return { children: text, attrs: { rowSpan: 0 } }
                                        },
                                    }
                                ]
                            },
                            {
                                title: `站闸名称：${xcgc}`,
                                children:[
                                    {
                                        title: '管护标准与要求',
                                        dataIndex: 'zb3',
                                        width: 240,
                                    }
                                ]
                            },
                            {
                                slots: { title: 'customTitle' },
                                children: [
                                    {
                                        title: '标准分值',
                                        dataIndex: 'bzfz',
                                        align: 'center',
                                        width: 90,
                                    },
                                    {
                                        title: '检查评分原则',
                                        dataIndex: 'kfbz',
                                        width: 160,
                                    },
                                    {
                                        title: '扣分',
                                        dataIndex: 'kf',
                                        align: 'center',
                                        width: 90,
                                    },
                                    {
                                        title: '备注',
                                        dataIndex: '',
                                        align: 'center',
                                        width: 160,
                                    }
                                ]
                            }
                        ]
                    })
                    .catch(err => err)
                    .finally(() => this.loading = false)
            }
        },
        created () {
            const { type, bh, townName, xcgc } = this.routeQuery
            this.getDetailOfAssess(['2256', '2223', '2222'][type], bh, townName, xcgc)
        }
    }
</script>

<style lang="less" scoped>
    .head-title {
        font-size: 28px;
        text-align: center;
    }

    /deep/ .ant-table {

        .ant-table-thead tr  {
    
            &:last-child th {
                color: #525759;
            }
    
            th {
                background-color: #fff;
            }
        }

        .ant-table-tbody {
            .ant-table-row:hover > td {
                background-color: #fff !important;
            }
        }
    }
</style>