<template>
    <div>
        <p style="text-align: center;font-size: 18px;">{{$route.query.title}}测量点</p>
        <a-row :gutter="16">
            <a-col :span="4">
                <a-statistic :value-style="{ color: '#cf1322' }" title="状态" value="正常" style="background: white;padding: 10px;" />
            </a-col>
            <a-col :span="4">
                <a-statistic :value-style="{ color: '#3AACF9' }" title="今日降雨量" :value="header_data.jrjyl" style="background: white;padding: 10px;" />
            </a-col>
            <a-col :span="4">
                <a-statistic :value-style="{ color: '#FF88FF' }" title="昨日降雨量" :value="header_data.zrjyl" style="background: white;padding: 10px;" />
            </a-col>
            <a-col :span="4">
                <a-statistic :value-style="{ color: '#BB55FF' }" title="本月降雨量" :value="header_data.byjyl" style="background: white;padding: 10px;" />
            </a-col>
            <a-col :span="4">
                <a-statistic :value-style="{ color: '#FAA46B' }" title="本年降雨量" :value="header_data.bnjyl" style="background: white;padding: 10px;" />
            </a-col>
            <a-col :span="4">
                <a-statistic :value-style="{ color: '#39D8D8' }" title="去年降雨量" :value="header_data.qnjyl" style="background: white;padding: 10px;" />
            </a-col>
            <a-col :span="4">
                <a-statistic :value-style="{ color: '#FAA46B' }" title="风速" :value="header_data.fs" style="background: white;padding: 10px;" />
            </a-col>
            <a-col :span="4">
                <a-statistic :value-style="{ color: '#39D8D8' }" title="风向" :value="header_data.fx" style="background: white;padding: 10px;" />
            </a-col>
            <a-col :span="4">
                <a-statistic :value-style="{ color: '#FAA46B' }" title="温度" :value="header_data.wd" style="background: white;padding: 10px;" />
            </a-col>
            <a-col :span="4">
                <a-statistic :value-style="{ color: '#39D8D8' }" title="相对温度" :value="header_data.xdsd" style="background: white;padding: 10px;" />
            </a-col>
        </a-row>
        <a-row class="jcx">
            <a-row type="flex" justify="space-between" align="middle">
                <a-col>时段降水量</a-col>
                <!--<a-col>
                <a-date-picker @change="onChange" />
            </a-col>-->
            </a-row>
            <a-row class="jcx">
                <div style="width:100%;height: 300px;" ref="zxt"></div>
            </a-row>
        </a-row>
        <!-- <a-row style="margin-top: 16px;">
        <a-card hoverable style="width: 300px">
            <template slot="actions" class="ant-card-actions">
              <span>随便来点文字占位</span>
            </template>
            <a-card-meta title="Card title" description="This is the description">
            </a-card-meta>
        </a-card>
    </a-row> -->
        <userSelecter ref="userSelecter" :sured="sured" :dataOptions="dataOptions"></userSelecter>
        <a @click="openSelecter">打开人员选择器</a>

        <userSelecter ref="userSelecter1" :sured="sured1"></userSelecter>
        <a @click="openSelecter1">打开人员选择器</a>
    </div>
</template>
<script>
    import * as echarts from 'echarts'
    import userSelecter from '../components/userSelecter'
	export default {
        data() {
            return {
				StationId: this.$route.query.StationId,
                header_data: [],
                
                dataOptions: {
                    title:"人员选择器",
                    leftUrl: "/api/comon/GetDepartList_select",
                    contentUrl: "/api/comon/GetRyInfoList_select",
                    single: false,
                    height:700,
                }
            }
        },
        created: function () {
        },
        components: {
            userSelecter
        },
		mounted() {
			var x=["00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00"]
			var y=[221,454,657,678,876,467,976,478,379,589,764,999]
            setTimeout(() => {
                this.setChart(x, y)
            });
            
		},
        watch: {
            '$route.query.StationId'(val) {
                if (val) {
                    this.bindList(val);
				}
            }
        },
        methods: {
            sured(item) {
                console.log(item);
            },
            sured1(item) {
                console.log(item);
            },
            openSelecter() {
                console.log(1);
                this.$refs.userSelecter.open();
            },
            openSelecter1() {
                this.$refs.userSelecter1.open();
            },
            onChange(date, dateString) {
			  console.log(date, dateString);
			},
            bindList(val) {
				this.$axios({
					url: "/api/slxx/GetStationInfo?StationId=" + val,
                    method: "get"
                }).then(res => {
                    console.log(res)
					if (res.data.code == 0) {
						this.header_data = res.data.data;
                    } else {
                        this.$message.error('网络繁忙，请稍后重试！');
                    }
                }).catch(err => {
                    this.$message.error('网络繁忙，请稍后重试！');
                })
            },
			setChart(xdata, ydata) {
			    var data1 = ydata;
			    var data2 = [];
			    //定义两个上下箭头的矢量路径
			    var up = 'path://M286.031,265l-16.025,3L300,223l29.994,45-16.041-3-13.961,69Z';
			    var down = 'path://M216.969,292l16.025-3L203,334l-29.994-45,16.041,3,13.961-69Z'
			    var path = [up, down, up, down, down, down, up]
			    var option = {
			        backgroundColor: 'white',
			        color: ['#3398DB'],
			        tooltip: {
			            trigger: 'axis',
			            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
			                type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
			            }
			        },
			        grid: {
			            left: '3%',
			            top: '20%',
			            right: '4%',
			            bottom: '3%',
			            containLabel: true
			        },
			        xAxis: [
			            {
			                type: 'category',
			                data: xdata,
			                axisTick: {
			                    alignWithLabel: true
			                }
			            }
			        ],
			        yAxis: [
			            {
			                type: 'value',
			                splitLine: {
			                    lineStyle: {
			                        type: 'dashed',
			                        color: '#eee'
			                    }
			                }
			            }
			        ],
			        series: [
			            {
			                "name": "降水量",
			                type: 'bar',
			                symbolSize: [0, 0],
			                symbolOffset: [0, 0],
			                color: 'orange',
			                symbolPosition: 'end',
			                label: {
			                    show: true,
			                    position: "top",
			                    fontSize: 30,
			                    fontWeight: 'bold',
			                    color: '#34DCFF'
			                },
			                data: data1
			            }
			        ]
			    };
			    var myChart = echarts.init(this.$refs.zxt)
			    myChart.setOption(option)
			    window.addEventListener("resize", function () {
			        myChart.resize();
			    })
			},
		}
    }
</script>
<style type="text/css" scoped>
	.ant-statistic {
		white-space: nowrap;
	}
	.jcx{
		margin-top: 16px;
		background: white;
		box-sizing: border-box;
		padding: 10px;
	}

</style>
