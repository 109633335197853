<template>
  <div class="container-rescue">
    <a-form
      @submit="formSearchSubmit"
      :label-col="{ span: 0 }"
      :wrapper-col="{ span: 24 }"
      :model="fromSearch"
    >
      <a-row :gutter="24">
        <a-col :span="5">
          <a-form-item label="抢险工程:">
            <a-input
              v-model="fromSearch.project"
              placeholder="请输入抢险工程"
            />
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="救援类别:">
            <a-select v-model="fromSearch.type" placeholder="请选择救援类别">
              <a-select-option value="民兵应急分队"
                >民兵应急分队</a-select-option
              >
              <a-select-option value="应急救援队伍"
                >应急救援队伍</a-select-option
              >
              <a-select-option value="应急专家库">应急专家库</a-select-option>
              <a-select-option value="应急志愿者">应急志愿者</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="单位名称:">
            <a-input
              v-model="fromSearch.company"
              placeholder="请输入部门名称"
            />
          </a-form-item>
        </a-col>

        <a-col :span="4">
          <a-form-item label="联系人:">
            <a-input v-model="fromSearch.contacts" placeholder="请输入联系人" />
          </a-form-item>
        </a-col>
        <a-col :span="5">
          <a-form-item style="margin-top:38px">
            <a-button type="primary" html-type="submit" icon="search"
              >搜索</a-button
            >
            <a-button
              type=""
              style="margin-left:8px"
              @click="formSearchReset"
              icon="reload"
              >清空</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div>
      <a-button
        type="primary"
        class="editable-add-btn"
        @click="handleAdd"
        style="margin-bottom:12px"
      >
        新增
      </a-button>
      <a-table
        bordered
        :data-source="showList"
        :columns="columns"
        :pagination="pagination"
        @change="pageCurrentChange"
        @showSizeChange="showSizeChange"
        ><template slot="type" slot-scope="text">
          <a-tag
            :color="
              text == '应急专家库' || text == '应急救援队伍' ? 'red' : 'blue'
            "
            >{{ text }}</a-tag
          >
        </template>
        <template slot="operation" slot-scope="text, record">
          <span>
            <a href="javascript:;" @click="() => edit(record.key)">编辑 </a>
            <span style="color:#d9d9d9"> | </span>
            <a-popconfirm
              v-if="showList.length"
              title="确定删除吗?"
              @confirm="() => onDelete(record)"
            >
              <a href="javascript:;" style="color:#f5222d"> 删除</a>
            </a-popconfirm>
          </span>
        </template>
      </a-table>
    </div>
    <EditableCell
      :list="activeList"
      :visible="visible"
      @handleOk="handleOk"
      @handleCancel="handleCancel"
      :confirmLoading="confirmLoading"
    ></EditableCell>
  </div>
</template>

<script>
const EditableCell = {
  template: `
  <a-modal
      title="新增抢险队伍"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
  <a-form-model
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        :layout="'horizontal'"
        :rules="rules"
        :model="from"
        ref="ruleForm"
      >
       <a-form-model-item label="救援类别:"  prop="type">
          <a-select v-model="from.type" placeholder="请选择救援类别" >
            <a-select-option value="民兵应急分队">民兵应急分队</a-select-option>
            <a-select-option value="应急救援队伍">应急救援队伍</a-select-option>
            <a-select-option value="应急专家库">应急专家库</a-select-option>
            <a-select-option value="应急志愿者">应急志愿者</a-select-option>
          </a-select>
        </a-form-model-item>
         <a-form-model-item label="抢险工程:"  prop="project">
          <a-input v-model="from.project" placeholder="请输入抢险工程" />
        </a-form-model-item>
        <a-form-model-item label="部门名称:"  prop="company">
          <a-input v-model="from.company" placeholder="请输入单位名称" />
        </a-form-model-item>
        <a-form-model-item label="队伍人数:" prop="team">
          <a-input v-model="from.team" placeholder="请输入队伍人数" />
        </a-form-model-item>
        <a-form-model-item label="地址:">
          <a-input v-model="from.address" placeholder="请输入地址" />
        </a-form-model-item>
        <a-form-model-item label="联系人:">
          <a-input v-model="from.contacts" placeholder="请输入联系人" />
        </a-form-model-item>
        <a-form-model-item label="电话:" prop="phone">
          <a-input v-model="from.phone" placeholder="请输入电话" />
        </a-form-model-item>
      </a-form-model>
      </a-modal>
    `,
  props: {
    visible: false,
    confirmLoading: false,
    list: {},
  },
  data() {
    return {
      rules: {
        type: [
          {
            required: true,
            message: '请选择救援类别',
            trigger: 'blur',
          },
        ],
        project: [
          {
            required: true,
            message: '请输入抢险工程',
            trigger: 'blur',
          },
        ],
        company: [
          {
            required: true,
            message: '请输入单位名称',
            trigger: 'blur',
          },
        ],
        team: [
          {
            pattern: /^[0-9]*$/,
            message: '请输入数字',
            trigger: 'change',
          },
        ],
        phone: [
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请输入正确的手机号码',
            trigger: 'change',
          },
        ],
      },
    }
  },
  methods: {
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit('handleOk')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleCancel() {
      this.$refs.ruleForm.resetFields()
      this.$emit('handleCancel')
    },
  },
  computed: {
    from() {
      return this.list
    },
  },
}

export default {
  components: {
    EditableCell,
  },
  data() {
    return {
      activeList: {}, // 选中的信息
      showList: [], //展示的列表
      visible: false, // 显示弹窗
      dataSource: [], //暂时存放数据的备胎数组
      confirmLoading: false, //异步关闭弹窗
      fromSearch: {}, //搜索的数据
      pagination: {
        total: 0,
        current: 1, //当前页
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '15', '20'], //每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'key',
          width: '30px',
          scopedSlots: { customRender: 'key' },
        },
        {
          title: '抢险工程',
          dataIndex: 'project',
          scopedSlots: { customRender: 'project' },
        },
        {
          title: '救援类别',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '部门名称',
          dataIndex: 'company',
          scopedSlots: { customRender: 'company' },
        },

        {
          title: '队伍人数',
          dataIndex: 'team',
          width: '90px',
          scopedSlots: { customRender: 'team' },
        },
        {
          title: '地址',
          dataIndex: 'address',
          scopedSlots: { customRender: 'address' },
        },
        {
          title: '联系人',
          dataIndex: 'contacts',
          width: '120px',
          scopedSlots: { customRender: 'contacts' },
        },
        {
          title: '电话',
          dataIndex: 'phone',
          width: '180px',
          scopedSlots: { customRender: 'phone' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'operation' },
        },
      ],
    }
  },
  mounted() {
    this.getShowList()
  },
  methods: {
    //请求列表数据
    getShowList(from = {}) {
      const { type, company, project, contacts } = from
      this.$axios({
        url: '/api/sjtb/getList_fxjc_qxdw',
        method: 'post',
        data: {
          pageIndex: this.pagination.current,
          pageSize: this.pagination.pageSize,
          where: {
            bh: '',
            kind: type,
            name: company,
            gc: project,
            lxr: contacts,
          },
        },
      }).then((res) => {
        console.log(res.data.data)
        this.pagination.total = res.data.data.total
        this.dataSource = []
        res.data.data.list.forEach((item) => {
          const { rnn, kind, name, rs, address, lxr, tel, bh, id, gc } = item
          const itemData = {
            id,
            bh,
            project: gc,
            key: rnn,
            type: kind,
            company: name,
            team: rs,
            address: address,
            contacts: lxr,
            phone: tel,
          }
          this.dataSource.push(itemData)
        })
        this.setShowList(this.dataSource)
      })
    },

    //当前页码跳转
    pageCurrentChange(pagination) {
      console.log(pagination)
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.getShowList()
    },

    //改变页码尺寸
    showSizeChange(current, size) {
      this.pagination.current = current
      this.pagination.pageSize = size
      this.getShowList()
    },

    //更新列表数据
    setShowList(arr) {
      //深拷贝 展示列表数据
      this.showList = JSON.parse(JSON.stringify(arr))
    },
    //新增
    handleAdd() {
      this.activeList = {
        type: undefined,
        project: '',
        company: '',
        team: '',
        address: '',
        contacts: '',
        phone: '',
      }
      this.visible = true
    },
    //弹窗确定
    handleOk() {
      this.confirmLoading = true
      const {
        bh,
        type,
        project,
        address,
        company,
        contacts,
        phone,
        team,
      } = this.activeList
      this.$axios({
        url: '/api/sjtb/qxdwAdd',
        method: 'post',
        data: {
          bh,
          kind: type,
          gc: project,
          name: company,
          rs: team,
          address: address,
          lxr: contacts,
          tel: phone,
          x: '',
          y: '',
        },
      }).then((res) => {
        console.log(res)
        this.getShowList()
        this.$message.success('保存成功', 2)
        this.visible = false
        this.confirmLoading = false
      })
    },
    //弹窗取消
    handleCancel() {
      this.getShowList()
      this.visible = false
    },
    //编辑
    edit(key) {
      this.visible = true
      this.activeList = this.showList.find((item) => {
        return item.key == key
      })
    },
    //删除
    onDelete(record) {
      this.$axios({
        url: '/api/Comon/deltag',
        method: 'post',
        data: {
          tableName: 'fxjc_qxdw',
          field: 'id',
          value: record.id,
          source: 'web',
          loginid: '',
        },
      }).then((res) => {
        if (res.status == 200) {
          this.$message.success('删除成功', 2)
          this.getShowList()
        }
      })
    },
    //搜索
    formSearchSubmit() {
      this.getShowList(this.fromSearch)
      console.log(this.fromSearch)
    },
    //重制搜索
    formSearchReset() {
      this.fromSearch = {
        type: undefined,
        company: '',
        project: '',
        contacts: '',
      }
    },
  },
}
</script>

<style lang="less" scoped>
.container-rescue {
  padding: 0px 16px;
}
</style>
