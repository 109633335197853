<template>
    <div id="components-form-demo-advanced-search">
        <a-spin :tip="spin.message" :spinning="spin.spinning">
            <div class="workSingle-sp_dcpdf">
               <!-- <a-button type="primary" @click="dcpdf();">
                    导出PDF
                </a-button> -->
            </div>
            <a-modal v-model="formAddOptions.visible" title="继续派单" width="800px">
                <a-spin :spinning="formAddOptions.spinning">
                    <a-form :form="formAdd" :label-col="{ span:8 }" :wrapper-col="{ span:16 }" labelAlign="left">
                        <a-form-item label="" style="display:none">
                            <a-input v-decorator="['id']" />
                           <a-input v-decorator="['infobh']" />
                            <a-input v-decorator="['townId']" />
                            <a-input v-decorator="['xmlx']" />
                            <a-input v-decorator="['xczzbh']" />
                            <a-input v-decorator="['zzbh']" />
                            <a-input v-decorator="['xmbh']" />
                        </a-form-item>
                        <a-row type="flex" justify="space-between">
                            <a-col :span="24">
                                <a-row>
                                    <a-form-item label="办理人" :label-col="{ span:4 }" :wrapper-col="{ span:20 }">
                                   <!--     <a-select @change="handleblrlxChange" v-decorator="['recType',{ rules: [{ required: true, message: '请选择办理人类型'}]},]" placeholder="请选择" :disabled="formAddOptions.viewMode">
                                            <a-select-option value="维保公司">
                                                维保公司
                                            </a-select-option>
                                            <a-select-option value="网格员">
                                                网格员
                                            </a-select-option>
                                        </a-select> -->
                                        <a-select placeholder="请选择" :disabled="formAddOptions.viewMode" show-search option-filter-prop="children" :filter-option="filterOption" v-decorator="['recLoginid',{ rules: [{ required: true, message: '请选择办理人'}]},]">
                                            <a-select-option v-for="items in blr_data"
                                                             :key="items.value">
                                                {{items.name}}
                                            </a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </a-row>
                            </a-col>
                        </a-row>
                        <a-row type="flex" justify="space-between">
                            <a-col :span="24">
                                <a-row>
                                    <a-form-item label="附件" :label-col="{ span:4 }" :wrapper-col="{ span:20 }">
                                        <a-upload class='a'
                                                  :disabled="formAddOptions.viewMode" name="a"
                                                  :multiple="false"
                                                  list-type="picture-card"
                                                  :file-list="fileList"
                                                  @preview="filesPreview"
                                                  @change="filesChange"
                                                  :customRequest="customFilesUpload">
                                            <div v-if="fileList.length<1">
                                                <a-icon type="plus" />
                                                <div class="ant-upload-text">
                                                    上传照片
                                                </div>
                                            </div>
                                        </a-upload>
                                        <a-modal :visible="previewVisible" :footer="null" @cancel="filesCancelPreview">
                                            <img alt="人像" style="width: 100%" :src="previewImage" />
                                        </a-modal>
                                    </a-form-item>
                                </a-row>
                            </a-col>
                        </a-row>
                        <a-row type="flex" justify="space-between">
                            <a-col :span="24">
                                <a-row>
                                    <a-form-item label="办理意见" :label-col="{ span:4 }" :wrapper-col="{ span:20 }">
                                        <a-input type="textarea" :rows="5" v-decorator="['sendInfo', { rules: [{ required: true, message: '请输入办理意见' },{max:3000,message:'最大长度为3000'}]}]" placeholder="办理意见" :disabled="formAddOptions.viewMode" />
                                    </a-form-item>
                                </a-row>
                            </a-col>
                        </a-row>

                        <a-row type="flex" justify="space-between">
                            <a-col :span="24">
                                <a-row>
                                    <a-form-item label="办理期限" :label-col="{ span:4 }" :wrapper-col="{ span:20 }">

                                        <a-date-picker v-decorator="['qx', config]" :disabled="formAddOptions.viewMode" />
                                    </a-form-item>
                                </a-row>
                            </a-col>
                        </a-row>
                    </a-form>
                </a-spin>
                <template slot="footer">
                    <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
                    <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
                </template>
            </a-modal>
			
            <!--<a-divider orientation="left">
                工单信息
            </a-divider>-->

            <a-descriptions title="工单信息" layout="vertical">
                <a-descriptions-item label="标题" span="1">
                    {{DetailsData.title}}

                </a-descriptions-item>
                <a-descriptions-item label="工单编号" span="2">
                    {{DetailsData.num}}
                </a-descriptions-item>
                <a-descriptions-item label="派单人" span="1">
                    {{DetailsData.createUser}}
                </a-descriptions-item>
                <a-descriptions-item label="派单时间" span="2">
                    {{DetailsData.createTime}}
                </a-descriptions-item>
                <a-descriptions-item label="内容" span="3">
                    {{DetailsData.info}}
                </a-descriptions-item>
                <!-- <a-descriptions-item label="供应评价" span="3">
                    {{DetailsData.info}}
                </a-descriptions-item> -->
                <a-descriptions-item label="附件" span="3">
                    <a v-for="item in DetailsData.files_bh" :href="item.url" target="_blank" class="filesaaa"><img :src="item.url" style="width:100px;height:100px;" /></a>
                </a-descriptions-item>
            </a-descriptions>

            <hr />

            <!--<a-divider orientation="left">
                回复记录
            </a-divider>-->
            <a-descriptions style="margin-top:20px;" title="办理记录" layout="vertical"></a-descriptions>
            <a-timeline>
                <div v-for="item,index in DetailsData.processList" id="rec_font">
                    <a-timeline-item>
                        <span>{{$jsc.formatDate(item.qx)}}</span>
                        <div>
                            <span>{{item.send}}：</span><span>{{item.sendInfo}}</span><br />
                            <a v-for="item2 in item.files_bh" :href="item2.url" target="_blank"><img :src="item2.url" style="width:100px;height:100px;margin:10px 10px 10px 0" /></a><br />
                            <span>{{item.rec}} 回复：</span>
                            <span v-if="item.recInfo">{{item.recInfo}}<span style="color:gray;">[{{$jsc.formatDate(item.rectime)}}]</span></span>
                            <span v-else style="color:red;">未回复</span><br />
                            <a v-for="item2 in item.files_recbh" :href="item2.url" target="_blank"><img :src="item2.url" style="width:100px;height:100px;margin:10px 10px 10px 0" /></a>
                        </div>
                    </a-timeline-item>
                </div>
                <a-timeline-item v-if="DetailsData.isEnd==2">
                    <span>{{DetailsData.createTime}}</span>
                    <div style="color:green;">{{DetailsData.createUser}}：已办结，关闭工单</div>
                </a-timeline-item>
            </a-timeline>
            <div style="margin-top:20px;" v-if="btnVisible1">
                <a-descriptions style="margin-top:20px;" title="回复" layout="vertical"></a-descriptions>
				
				<a-form :form="formHf" @submit="formHfSubmit" :label-col="{ span:8 }" :wrapper-col="{ span:16 }" labelAlign="left">
				    <a-form-item label="" style="display:none">
				        <a-input v-decorator="['id']" />
				        <!-- <a-input v-decorator="['infobh']" />
				        <a-input v-decorator="['townId']" />
				        <a-input v-decorator="['xmlx']" />
				        <a-input v-decorator="['xczzbh']" />
				        <a-input v-decorator="['zzbh']" />
				        <a-input v-decorator="['xmbh']" /> -->
				    </a-form-item>
				<a-row type="flex" justify="space-between">
				    <a-col :span="24">
				        <a-row>
				            <a-form-item label="运维服务商" :label-col="{ span:4 }" :wrapper-col="{ span:20 }">
				                <a-input type="text"  v-decorator="['ywfws', { rules: [{ required: true, message: '请输入运维服务商' },{max:300,message:'最大长度为300'}]}]" placeholder="运维服务商" :disabled="formAddOptions.viewMode" />
				            </a-form-item>
				        </a-row>
				    </a-col>
				</a-row>
					<a-row type="flex" justify="space-between">
					    <a-col :span="24">
					        <a-row>
					            <a-form-item label="回复内容" :label-col="{ span:4 }" :wrapper-col="{ span:20 }">
					                <a-input type="textarea" :rows="5" v-decorator="['recInfo', { rules: [{ required: true, message: '请输入办理意见' },{max:3000,message:'最大长度为3000'}]}]" placeholder="回复内容" :disabled="formAddOptions.viewMode" />
					            </a-form-item>
					        </a-row>
					    </a-col>
					</a-row>
				    <a-row type="flex" justify="space-between">
				        <a-col :span="24">
				            <a-row>
				                <a-form-item label="附件" :label-col="{ span:4 }" :wrapper-col="{ span:20 }">
				                    <a-upload class='a'
				                              :disabled="formAddOptions.viewMode" name="a"
				                              :multiple="false"
				                              list-type="picture-card"
				                              :file-list="fileList1"
				                              @preview="filesPreview"
				                              @change="filesChange"
				                              :customRequest="customFilesUpload1">
				                        <div v-if="fileList1.length<1">
				                            <a-icon type="plus" />
				                            <div class="ant-upload-text">
				                                上传照片
				                            </div>
				                        </div>
				                    </a-upload>
				                    <a-modal :visible="previewVisible" :footer="null" @cancel="filesCancelPreview">
				                        <img alt="人像" style="width: 100%" :src="previewImage" />
				                    </a-modal>
				                </a-form-item>
				            </a-row>
				        </a-col>
				    </a-row>
				   <a-row :gutter="[24,12]">
				       <a-col :span="24" :style="{ textAlign: 'center' }">
				          <!-- <a-button type="primary" :style="{ width:'78px' }" :loading="formAddOptions.submitLoading" html-type="submit" >提交</a-button> -->
						   <a-button type="primary" :style="{ width:'78px' }" :loading="formAddOptions.submitLoading"  @click="formHfSubmit" >提交</a-button>
						  
				       </a-col>
				       
				   </a-row>
				
				  
				</a-form>
				
            </div>
            <div style="margin-top:20px;" v-if="btnVisible">
                <a-button type="danger" @click="btnClick('未解决','')">没解决，继续派单</a-button>
                <a-button type="primary" @click="btnClick('已解决','')" style="margin-left:10px;background-color:green;border-color:green;">已解决，关闭该单</a-button>
            </div>
        </a-spin>
    </div>
</template>
<script>
    import moment from "moment";
    const loading = true
    const strWhere = 'del=0'
    const action = 'add'
    export default {
        data() {
            return {
                showexport: false,
                scroll: {
                    y: 900
                },
                //actionList: {
                //    add: false,
                //    remove: false,
                //    edit: false
                //},
                config: {
                    rules: [{ type: 'object', required: true, message: '请选择办理期限!' }],
                },
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                DetailsData: [],
                blr_data: [],
                blr_wbcompany_data: [],
                blr_wgy_data: [],
                problems_data: [],
                btnVisible: false,
				btnVisible1: false,
                StartTime: '',
                EndTime: '',
                //columns,
                loading,
                action,
                previewVisible: false,
                previewImage: '',
                fileList: [],
				fileList1: [],
                strWhere,
                expand: false,
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
				formHf: this.$form.createForm(this, { name: 'advanced_search12' }),
                spin: {
                    message: "加载中...",
                    spinning:false,
                },
				// processId=''
            }
        },
        mounted() {
            if (this.$route.query.btnVisible == "1") { this.btnVisible = true }
			
			if (this.$route.query.btnVisible1 == "1") {
				
				this.btnVisible1 = true 
				
			}
			if (this.$route.query.processId) {
				
				var that=this;
				setTimeout(function(){
					that.formHf.setFieldsValue({
					     "id": that.$route.query.processId,
					 });
					
				},1000);
			}
			 
            this.init();
            this.bindList();
        },
        //url: this.$axios.defaults.fileURL + '/dcpages/dfcWorkOrder?id=234234&token=' + this.$User.user.token.access_token,
        methods: {
            dcpdf() {
                this.spin.spinning = true;
                this.spin.message = "正在生成PDF，请稍候...";
                this.$axios({
                    url: this.$axios.defaults.fileURL + "/api/dcpages/htmlTopdf_dfcOrder?id=" + this.$route.query.id,
                    method: 'get',
                }).then(res => {
                    console.log(res.data.data);
                    //location.href = res.data;
                    this.spin.spinning = false;
                    window.open(res.data.data);
                }).catch(error => { // 请求失败处理
                })
            },
            moment,
            btnClick(text, item) {
                let _this = this;
                switch (text) {
                    case '未解决':
                        this.continueWork();
                        break;
                    case "已解决":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定关闭该单?",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.closeWork();
                            }
                        });
                        break;
                }
            },
            continueWork: async function () {
                this.formAddOptions.visible = true;
                this.handleblrlxChange(this.DetailsData.processList[this.DetailsData.processList.length - 1].recType);
                this.$nextTick(() => {
                    this.formAdd.setFieldsValue({
                        recType: this.DetailsData.processList[this.DetailsData.processList.length - 1].recType,
                        recLoginid: this.DetailsData.processList[this.DetailsData.processList.length - 1].recLoginid,
                        infobh: this.DetailsData.bh,
                        townId: this.DetailsData.townId,
                        xmlx: this.DetailsData.xmlx,
                        xczzbh: this.DetailsData.xczzbh,
                        zzbh: this.DetailsData.zzbh,
                        xmbh: this.DetailsData.xmbh,
                        qx: moment(this.$jsc.formatDate_custom(this.DetailsData.qx_new) == "" ? this.$jsc.formatDate_custom(new Date()) : this.$jsc.formatDate_custom(this.DetailsData.qx_new)),
                    })
                })
            },
            closeWork() {
                this.$axios({
                    url: '/api/cameras/KnotWorkOrders_new?id=' + this.$route.query.id,
                    method: 'post',
                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code === 0) {
                            this.$message.info('已关闭')
                            this.$router.push({ path: "camera-workSingle-List" })
                        } else {
                            console.log(res);
                            throw new Error("服务器超时2");
                        }
                    }
                    else {
                        throw new Error("服务器超时2");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                    this.formAddOptions.submitLoading = false
                })
            },
            onRangePickerChange(date, dateString) {
                console.log(dateString[0]);
                console.log(dateString[1]);
                this.StartTime = dateString[0];
                this.EndTime = dateString[1];
            },
            handleblrlxChange(value) {
                this.$nextTick(() => { this.formAdd.setFieldsValue({ recLoginid: '' }) })
                if (value == '维保公司') {
                    this.blr_data = this.blr_wbcompany_data;
                } else if (value == '网格员') {
                    this.blr_data = this.blr_wgy_data;
                }

            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            phoneCheck(rule, value, callbackFn) {
                const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
                if (!reg.test(value)) {
                    callbackFn('请输入正确的手机号码')
                    return
                }
                callbackFn()
            },
            SFZCheck(rule, value, callbackFn) {
                const reg = /^(\d{18}|\d{15}|\d{17}x)$/
                if (!reg.test(value)) {
                    callbackFn('请输入正确的身份证号码')
                    return
                }
                callbackFn()
            },
            bindList: async function () {
                this.InitGrid()
            },
            InitGrid: function () {
                var _that = this
                this.$axios({
                    url: '/api/cameras/GetWorkOrderDetails_new?id=' + this.$route.query.id,
                    method: 'get',
                })
                    .then(function (response) {
                        console.log(response);
                        if (response.status === 200) {
                            console.log(response.data.data); //return;
                            _that.DetailsData = response.data.data
                            console.log('object :>> ', response.data.data);
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            filesCancelPreview() {
                this.previewVisible = false
            },
            async filesPreview(file) {
                //console.log(file.ext)
                // if (!file.url && !file.preview) {
                //   file.preview = await getBase64(file.originFileObj)
                // }
                // this.previewImage = file.url || file.preview
                // this.previewVisible = true
                location.href = file.url
            },
            filesChange: function (file) {
                if (file.file.status === 'removed') {
                    this.fileList = file.fileList
                }
            },
            customFilesUpload(data) {
                const formData = new FormData()
                formData.append('file', data.file)
                //formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
                this.saveFile(formData)
            },
			customFilesUpload1(data) {
			    const formData = new FormData()
			    formData.append('file', data.file)
			    //formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
			    this.saveFile1(formData)
			},
            formAddSubmit() {
                this.formAdd.validateFields((err, values) => {
                    //if(this.fileList.length==0)
                    //{
                    //	this.$message.error('请上传照片');
                    //	return;
                    //}
                    if (!err) {
                        this.formAddOptions.submitLoading = true
                        values.files_bh = this.fileList
                        values.qx = values['qx'].format('YYYY-MM-DD')//办理期限格式化
                        this.$axios({
                            url: '/api/cameras/InsWorkProcess_new',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('保存成功')
                                    this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false
                                    this.$router.push({ path: "camera-workSingle-List" })
                                } else {
                                    console.log(res);
                                    throw new Error("服务器超时2");
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })

                    }
                })
            },
			formHfSubmit() {
			    this.formHf.validateFields((err, values) => {
			        //if(this.fileList.length==0)
			        //{
			        //	this.$message.error('请上传照片');
			        //	return;
			        //}
			        if (!err) {
			            this.formAddOptions.submitLoading = true
			            values.files_bh = this.fileList1
						
			            // values.qx = values['qx'].format('YYYY-MM-DD')//办理期限格式化
			            this.$axios({
			                url: '/api/cameras/ReplyWorkProcess_wb',
			                method: 'post',
			                data: values
			            }).then(res => {
			                if (res.status == 200) {
			                    if (res.data.code === 0) {
			                        this.$message.info('保存成功')
			                        this.formAddOptions.visible = false
			                        this.formAddOptions.submitLoading = false
			                        this.$router.push({ path: "camera-workSingle-List" })
			                    } else {
			                        console.log(res);
			                        throw new Error("服务器超时2");
			                    }
			                }
			                else {
			                    throw new Error("服务器超时2");
			                }
			            }).catch(error => { // 请求失败处理
			                this.$message.error(error.message);
			                this.formAddOptions.submitLoading = false
			            })
			
			        }
			    })
			},
            saveFile(formData) {
                var that = this;
                this.$axios(
                    {
                        method: 'post',
                        url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
                        data: formData
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            var num = that.fileList.length
                            that.fileList.push(res.data.data)
                            // that.uploadgetText('a', num, res.data.data);
                        }
                        else {
                            throw new Error("服务器器超时");
                        }
                    })
                    .catch((error) => {
                        that.$message.error(error.message);
                        console.log(error)
                    })
            },
			saveFile1(formData) {
			    var that = this;
			    this.$axios(
			        {
			            method: 'post',
			            url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
			            data: formData
			        })
			        .then((res) => {
			            if (res.status == 200) {
			                var num = that.fileList.length
			                that.fileList1.push(res.data.data)
			                // that.uploadgetText('a', num, res.data.data);
			            }
			            else {
			                throw new Error("服务器器超时");
			            }
			        })
			        .catch((error) => {
			            that.$message.error(error.message);
			            console.log(error)
			        })
			},

            getcompanylist() {
                // 获取维保企业列表
                this.$axios({
                    url: '/api/Cameras/getDepart',
                    method: 'get',

                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code === 0) {
                            this.blr_wbcompany_data = res.data.data;
                            this.blr_data = this.blr_wbcompany_data;
                        } else {
                            console.log(res);
                            throw new Error("服务器超时2");
                        }
                    }
                    else {
                        throw new Error("服务器超时2");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                    this.formAddOptions.submitLoading = false
                })
            },
            getwgylist() {
                // 获取网格员列表
                this.$axios({
                    url: '/api/Zzgl/GetXcy',
                    method: 'get',

                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code === 0) {
                            console.log(res.data.data);
                            this.blr_wgy_data = res.data.data;
                        } else {
                            console.log(res);
                            throw new Error("服务器超时2");
                        }
                    }
                    else {
                        throw new Error("服务器超时2");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                    this.formAddOptions.submitLoading = false
                })
            },
            init() {
                var that = this
                size()
                this.getcompanylist()
                this.getwgylist()
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 270
                        }
                    })
                }
            },
            /**
             * @param {String} classname；upload的类名
             */
            editgetText(classname) {
                this.$nextTick(() => {
                    var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    //console.log(els)
                    for (var i = 0, len = els.length; i < len; i++) {
                        var type = els[i].textContent.split('.')[1]
                        if (type !== 'png' && type !== 'jpg') {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = els[i].textContent
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + type
                            }
                            cel.innerText = temptext
                            spanels[i].prepend(cel)
                        }
                    }
                })
            },
            /**
             * @param {String} classname；upload的类名
             * @param {Number} num；原有的已上传文件数量
             * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
             */
            uploadgetText(classname, num, response) {
                this.$nextTick(() => {
                    //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    var tempNodeList = []
                    for (var i = 0; i < spanels.length - num; i++) {
                        // console.log(spanels[num+i])
                        tempNodeList.push(spanels[num + i])
                        for (var j = 0; j < tempNodeList.length; j++) {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = response.name
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + response.ext
                            }
                            cel.innerText = temptext
                            tempNodeList[j].prepend(cel)
                        }
                    }
                })
            },
            actions() {
                //if (this.inlineButtons.length == 0) {
                //    columns.pop();
                //}
            }
        }
    }

    //function getBase64(file) {
    //    return new Promise((resolve, reject) => {
    //        const reader = new FileReader()
    //        reader.readAsDataURL(file)
    //        reader.onload = () => resolve(reader.result)
    //        reader.onerror = error => reject(error)
    //    })
    //}
</script>
<style>
    .ant-layout-footer {
        display: none;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
        padding: 20px 20px 0;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }

    form .ant-select, form .ant-cascader-picker {
        width: 48%;
        margin-right: 10px;
    }
    /*页面字体大小设置*/
    /**/
    .ant-descriptions-title {
        font-size: 20px;
    }

    .ant-descriptions-item-label {
        font-size: 17px;
        font-weight: bold;
    }

    .ant-descriptions-item-content {
        font-size: 16px;
    }

    #rec_font span {
        font-size: 16px;
    }

    .ant-divider-horizontal.ant-divider-with-text-left {
        font-size: 20px;
        font-weight: bold;
    }

    .filesaaa {
        display: inline-block;
        margin-right: 10px;
    }

    .workSingle-sp_dcpdf {
        position: absolute;
        top: 40px;
        right: 100px;
    }
    /*结束*/
</style>