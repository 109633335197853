<template>
    <div class="box">
        <a-form :form="formSearch" layout="inline" class="form-search">
        
            <a-form-item>
                <a-select
                    style="width: 124px !important;"
                    v-decorator="['loginid', { rules: [{ required: true, message: ' ' }] }]"
                    placeholder="巡察人员"
                >
                    <a-select-option 
                        v-for="item in personnel" 
                        :key="item.bh" 
                        :value="item.bh"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item >
                <a-date-picker 
                    style="width: 124px;"
                    v-decorator="['checkDate', { rules: [{ required: true, message: ' ' }] } ]" 
                    :allowClear="false"
                    :popupStyle="{ zIndex: 10001 }"
                    placeholder="巡察日期" 
                    valueFormat="YYYY-MM-DD" />
            </a-form-item>

            <a-form-item>
                <a-button type="primary" @click="search">查询</a-button>
            </a-form-item>
        </a-form>
        <div id="viewDiv" style="width:100%; height:100%;"></div>
    </div>
</template>
<script>
    import SQLCompiler from '@/assets/js/SQLCompiler'
    import * as esriLoader from 'esri-loader'
    esriLoader.loadScript({
        url: 'http://180.97.207.33:8091/arcgis_js_api/library/4.17/init.js',
        dojoConfig: {
            async: true,
            packages: [{
                name: 'app',
                location: 'http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/app'
            }]
        }
    });

    esriLoader.loadCss('http://180.97.207.33:8091/arcgis_js_api/library/4.17/esri/themes/light/main.css')
    esriLoader.loadCss('http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/GRSHGISLibs.css')

    export default {
        data() {
            return {
                formSearch:  this.$form.createForm(this, { name: 'formSearch' }),
                personnel: []
            }
        },
        methods: {
            initMap () {
            esriLoader.loadModules([
                'http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/app/MapInitial.js',
                'http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/app/KSSmartWater/ksSWInitial.js',
                'http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/app/Assist/GeometryAssist.js',
                'http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/app/Assist/GraphicsAssist.js'
            ])
                .then(classList => {
                    const [MI, ksSWInitial, GeometryAssist, GraphicsAssist] = classList
                    var mi = new MI({
                        container: "viewDiv",
                        useBSW: false,
                        mapsource: 1,
                        maptype: 1,
                        OMKey: this.$User.user.token.access_token,
                        view_click_handler: function (arg) {
                            console.log(arg.mapPoint.longitude + "," + arg.mapPoint.latitude);
                        }
                    });
                    mi.initialAllCommonWidget();  //初始化所有Widget
                    var xcw = new ksSWInitial({mi: mi})
                    xcw.initial();  //智慧水利相关功能初始化
                    xcw.setDBLyrShowInMap([]);  //移除所有业务图层
                    xcw.setBoundary("淀防处");  //设置淀防处掩膜
                    xcw.zoomtoXZQ("淀防处");  //定位至淀防处
                    xcw.setBaseWaterSubLayerVisibility("Sluice", true);//打开闸站图层
                    xcw.setDefExpression("Sluice", "JGCompany='昆山市淀山湖水域综合管理中心'");  //只显示管理单位为淀防处的闸站
                    xcw.setBaseWaterSubLayerVisibility("Mask", true);

                    this.$on('drawPath', caroods => {

                        if (this._.isEmpty(caroods)) return this.$message.warning('无巡察轨迹')

                        const gc = mi.getGC()
                        gc.removeById('path')
                        
                        const ga = new GraphicsAssist()

                        // 画路径
                        const pln = GeometryAssist.getPolyline(caroods)
                    
                        const pln_graphic = ga.CreateGraphic(pln, {})
                        pln_graphic.symbol = {
                            type: 'simple-line',
                            color: [255, 69, 0, 0.7],
                            width: 4,
                            style: 'solid',
                            join: 'round'
                        }
                        
                        gc.add({
                            graphic:pln_graphic,
                            id:'path'
                        })

                        mi.zoomto(pln.extent)

                        // 画点
                        // 起点
                        const startCarood = GeometryAssist.getPoint(...this._.first(caroods))
                        const pnt_graphic_start = ga.CreateGraphic(startCarood, {})
                        pnt_graphic_start.symbol = {
                            type: 'picture-marker',
                            url: '/img/start-point.png',
                            width: 19,
                            height: 31,
                            yoffset: 18,
                        }
                        gc.add({ graphic: pnt_graphic_start, id: 'start_point' })
                        // 终点
                        const endCarood = GeometryAssist.getPoint(...this._.last(caroods))
                        const pnt_graphic_end = ga.CreateGraphic(endCarood, {})
                        pnt_graphic_end.symbol = {
                            type: 'picture-marker',
                            url: '/img/end-point.png',
                            width: 19,
                            height: 31,
                            yoffset: 18
                        }
                        gc.add({ graphic: pnt_graphic_end, id: 'end_point' })

                    })
                })
            },
            async getPersonnel () {
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'v_zhsw_xcryxx',
                        page: 1,
                        limit: 10000,
                        strWhere: await SQLCompiler.encrypt('del=0')
                    }
                })
                    .then(res => {
                        if (res.status !== 200 || res.data.code !== 0) return
                        this.personnel = res.data.data
                    })
            },
            search () {
                const { validateFields } = this.formSearch
                validateFields ((err, values) => {
                    if (err) return
                    this.getPathCarood(values)
                })

            },
            getPathCarood (query) {
                this.$axios.get('/api/dfc/common/getCheckPath', { params: query })
                    .then(res => {
                        if (res.status !== 200 || res.data.code !== 0) return
                        const caroods = this._.map(res.data.data, item => [+item.x, +item.y])
                        this.$emit('drawPath', caroods)
                    })
            }
        },
        created: function () {
            this.initMap()
            this.getPersonnel()
        }
    }
</script>


<style scoped>
    .ant-layout-content {
        padding: 16px !important;
        margin: 0px !important;
    }

    .box {
        position: relative;
        height: 100%;
    }

    .form-search {
        position: absolute;
        top: 12px;
        left: 80px;
        z-index: 10000;
    }
</style>
