<!--考核主题-->
<template>
  <div id="components-form-demo-advanced-search">
    <a-modal v-model="formAddOptions.visible" :title="opration" width="700px">
      <a-spin :spinning="formAddOptions.spinning">
        <a-form :form="formAdd" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }" labelAlign="right">
          <a-form-item label="" style="display:none">
            <a-input v-decorator="['bh']" />
          </a-form-item>
          <a-row type="flex" justify="space-between">
            <a-col :span="24">
              <a-form-item label="标题">
                <a-input v-decorator="[
                    'title',{ rules: [{ required: true, message: '请输入标题' }] }]" placeholder="请输入标题" :maxLength="50" :disabled="formAddOptions.viewMode" />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="版本: ">
                <a-input v-decorator="[
                    'ver',{ rules: [{ required: false, message: '请输入版本' }] }]" placeholder="请输入版本" :maxLength="50" :disabled="formAddOptions.viewMode" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="24">
              <a-form-item label="备注">
                <a-input v-decorator="['remark',{ rules: [{ required: false, message: '备注' }] }]" placeholder="备注" :maxLength="40" :disabled="formAddOptions.viewMode" type="textarea" style="height:150px;" />
              </a-form-item>
            </a-col>
          </a-row>

        </a-form>
      </a-spin>
      <template slot="footer">
        <a-button key="back" @click="
            formAddOptions.visible = false;
            formAddOptions.dateVisible = false;
          ">关闭</a-button>
        <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
      </template>
    </a-modal>

    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="">
            <a-input v-decorator="['title']" placeholder="标题" :maxLength="40" />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-input v-decorator="['ver']" placeholder="版本" style="width: 100%">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="[24, 12]">
        <a-col :span="18" :style="{ textAlign: 'left' }">
          <a-button type="primary" v-for="button in buttons" @click="btnClick(button.title)" style="margin-right: 5px" :key="button.title">{{ button.title }}</a-button>
        </a-col>
        <a-col :span="6" :style="{ textAlign: 'right' }">
          <a-button type="primary" :style="{ width: '48px' }" html-type="submit" icon="search"></a-button>
          <a-button type="" :style="{ width: '48px', marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px', display: 'none' }" @click="toggle">
            {{
 expand ? "收起" : "展开"
                        }}
            <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>

    <div class="search-result-list" :style="{ marginTop: '0px' }">
      <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="10" :total="50" rowKey="id" :loading="loading" :scroll="scroll" @change="pageCurrentChange" position="bottom" tableLayout="fixed">
        <span slot="action" slot-scope="text, record">
          <a-button type="primary" size="small" @click="btnClick('设为当前', record)" style="margin-right: 5px">设为当前
          </a-button>
          <a-button :type="button.className" size="small" v-for="button in inlineButtons" :key="button.title" @click="btnClick(button.title, record)" style="margin-right: 5px">
            <a-icon :type="button.iconLink" />
          </a-button>
        </span>
        <span slot="title1" slot-scope="text, record">
          <a @click="()=>$router.push('kh1List?subbh='+record.bh+'&title='+record.title)">{{ text }}</a>
        </span>
        <span slot="createUser" slot-scope="text, record">
          <span>{{ text }}/{{record.createTime}}</span>
        </span>
        <span slot="optionUpdateUser" slot-scope="text, record">
          <span>{{ text }}/{{ record.optionsUpdateTime }}</span>
        </span>
        <span slot="isuseText" slot-scope="text, record">
          <span>
            <a-tag color="orange" v-if="text">是</a-tag>
          </span>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
const loading = true
const strWhere = 'del=0'
const action = 'add'
export default {
  data() {
    return {
      showexport: false,
      scroll: {
        x: 900
      },
      view_fields: {
        viewModel: false,
        title: '检查项',
        data: []
      },
      check_ywba: true,
      opration: '',
      townData: [],
      qzLoginid: '',
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'rownum',
          width: 70,
          key: 'rownum'
        },
        {
          title: '标题',
          dataIndex: 'title',
          width: 150,
          key: 'title',
          scopedSlots: { customRender: 'title1' }
        },
        {
          title: '版本',
          dataIndex: 'ver',
          width: 100,
          key: 'ver'
        },
        {
          title: '考核项数',
          dataIndex: 'khCount',
          width: 100,
          key: 'khCount'
        },
        {
          title: '创建人/时间',
          dataIndex: 'createUser',
          width: 150,
          key: 'createUser',
          scopedSlots: { customRender: 'createUser' }
        },
        {
          title: '考核项最近更新人/时间',
          dataIndex: 'optionsUpdateUser',
          width: 150,
          key: 'optionsUpdateUser',
          scopedSlots: { customRender: 'optionUpdateUser' }
        },
        {
          title: '是否当前考核',
          dataIndex: 'isuse',
          width: 150,
          key: 'isuse',
          scopedSlots: { customRender: 'isuseText' }
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 200,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading,
      action,
      startTime: '',
      endTime: '',
      previewVisible: false,
      previewImage: '',
      fileList: [],
      strWhere,
      expand: false,
      formAddOptions: {
        visible: false,
        submitLoading: false,
        submitVisible: true,
        viewMode: false,
        spinning: false,
        dateVisible: false
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
      formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom'
      }
    }
  },
  mounted() {
    //将上面的按钮和表格内的按钮分出来
    console.log(this.$route.meta.buttons)
    if (this.$route.meta.buttons) {
      for (let i = 0; i < this.$route.meta.buttons.length; i++) {
        if (this.$route.meta.buttons[i].tag == 'inline') {
          this.inlineButtons.push(this.$route.meta.buttons[i])
        } else {
          this.buttons.push(this.$route.meta.buttons[i])
        }
      }
    }

    this.init()
    this.actions()
    window.onresize = () => {
      this.init()
    }
    this.bindList()
  },
  created() {},
  methods: {
    btnClick(text, item) {
      let _this = this
      this.opration = text
      switch (text) {
        case '新增':
          this.openDlg()
          break
        case '编辑':
          this.openDlg(item)
          break
        case '查看':
          this.openDlg(item, 'view')
          break
        case '删除':
          this.$confirm({
            title: '操作确认',
            content: '确定要删除?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.del(item)
            }
          })
          break
        case '设为当前':
          this.$confirm({
            title: '操作确认',
            content: '确定要设为当前考核吗，只能存在一个当前考核',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this
                .$axios({
                  url: '/api/Xckh/setisuse',
                  method: 'get',
                  params: {
                    id: item.id
                  }
                })
                .then(res => {
                  if (res.data.code == 0) {
                    _this.$message.success('设置成功')
                    _this.bindList()
                  } else {
                    throw new Error('设置失败')
                  }
                })
                .catch(error => {
                  // 请求失败处理
                  _this.$message.error(error.message)
                })
            }
          })
          break
      }
    },

    setuse: function () {},
    bindList: async function () {
      this.formSearch.validateFields((error, values) => {
        error = 1
        // console.log(values)
        this.strWhere = 'del=0 '
        for (var obj in values) {
          if (values[obj]) {
            if (obj == 'townId') {
              this.strWhere += ' and ' + obj + " = '" + values[obj] + "'"
            } else {
              this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
            }
          }
        }
        this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
        console.log(this.strWhere)
      })
      this.loading = true

      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere)
      this.strWhere = this.strWhere.data
      await this.InitGrid()
    },
    InitGrid: function () {
      var _that = this
      _that.loading = true

      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName: 'v_zzgl_xckh_sub',
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          filesFields: '',
          strWhere: _that.strWhere
        }
      })
        .then(function (response) {
          console.log(response)
          _that.loading = false
          if (response.status === 200) {
            _that.tableData = response.data.data
            _that.pagination.total = response.data.count
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    onRangePickerChange(date, dateString) {
      this.StartTime = dateString[0]
      this.EndTime = dateString[1]
    },
    onShowSizeChange(current, pageSize) {
      console.log(current, pageSize)
      return
    },
    formSearchSubmit(e) {
      console.log(e)
      e.preventDefault()
      this.pagination.current = 1
      this.bindList()
    },
    formSearchReset() {
      this.formSearch.resetFields()
      this.strWhere = 'del=0'
      this.bindList()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    pageCurrentChange(pagination) {
      console.log(pagination)
      //return;
      this.pagination.current = pagination.current
      this.bindList()
    },
    del(record) {
      this.$axios({
        url: '/api/Comon/deltag',
        method: 'post',
        data: {
          tableName: 'zzgl_xckh_sub',
          field: 'id',
          value: record.id,
          source: 'web',
          loginid: ''
        }
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('删除成功')
              this.bindList()
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },
    openDlg: async function (record, action) {
      var _this = this
      this.formAdd.resetFields()
      this.fileList = []
      this.formAddOptions.submitVisible = true
      this.formAddOptions.viewMode = false
      if (record !== undefined) {
        // 从后台取数据赋值
        this.formAddOptions.spinning = true
        var obj = await this.$jsc.DesEncrypt(
          this,
          'bh=zhangbiao' + record.bh + 'zhangbiao'
        )

        obj = obj.data

        this.$axios
          .get(
            '/api/GetList/getListByTable?tableName=zzgl_xckh_sub&strWhere=' +
              obj
          )
          .then(response => {
            if (response.status == 200) {
              if (response.data.code == 0) {
                this.formAddOptions.visible = true
                //  _that.loading = false;
                var result = response.data.data

                setTimeout(() => {
                  this.formAdd.setFieldsValue(result[0])
                  this.formAddOptions.spinning = false
                }, 100)
              } else {
                this.$message.error(response.data.msg)
                this.formAddOptions.spinning = false
              }
            } else {
              // vue.prototype.$message.info('This is an error message');
            }
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error)
          })
        if (action === 'view') {
          // 预览模式
          this.formAddOptions.submitVisible = false
          this.formAddOptions.viewMode = true
        }
      } else {
        this.formAddOptions.visible = true
      }
    },
    formAddSubmit() {
      this.formAdd.validateFields((err, values) => {
        if (!err) {
          this.formAddOptions.submitLoading = true
          this.$axios({
            url: 'api/xckh/EditKhsubInfo',
            method: 'post',
            data: values
          })
            .then(res => {
              if (res.status == 200) {
                if (res.data.code === 0) {
                  this.$message.info('保存成功')
                  this.formAddOptions.visible = false
                  this.formAddOptions.submitLoading = false
                  this.formAddOptions.dateVisible = false
                  this.bindList()
                } else {
                  console.log(res)
                  throw new Error('服务器超时')
                }
              } else {
                throw new Error('服务器超时2')
              }
            })
            .catch(error => {
              // 请求失败处理
              this.$message.error(error.message)
              this.formAddOptions.submitLoading = false
            })
        }
      })
    },
    init() {
      var that = this
      size()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector(
            '#components-form-demo-advanced-search>form'
          )
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('p')[0] - 270
          }
        })
      }
    },

    actions() {
      if (this.inlineButtons.length == 0) {
        this.columns.pop()
      }
    }
  }
}

//function getBase64(file) {
//    return new Promise((resolve, reject) => {
//        const reader = new FileReader()
//        reader.readAsDataURL(file)
//        reader.onload = () => resolve(reader.result)
//        reader.onerror = error => reject(error)
//    })
//}
</script>
<style>
.ant-layout-footer {
  display: none;
}

#components-form-demo-advanced-search {
  background: white;
  box-sizing: border-box;
  padding: 20px 20px 0;
}

.ant-form-item {
  margin-bottom: 12px;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: !important;
}

/*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 1px 1px 1px 1px !important;
}
</style>