<template>
    <div>
        <a-form :form="form" layout="inline">
            <a-form-item>
                <a-range-picker v-decorator="['rangeDate']" valueFormat="YYYY-MM-DD" />
            </a-form-item>
            <a-form-item>
                <a-button icon="search" type="primary" @click="search">搜索</a-button>
            </a-form-item>
        </a-form>

        <a-table
            class="table"
            :row-key="record => record.nickName"
            :columns="columns" 
            :data-source="dataSource"
            :pagination="false"
            bordered
            :loading="loading"
        >
        </a-table>

    </div>
</template>

<script>
    import moment from 'moment'
    const  columns = [
        {
            title: '水葫芦点位',
            dataIndex: 'nickName',
            align: 'center'
        },
        {
            title: '面积（平方米）',
            dataIndex: 'mj',
            align: 'center'
        },
        {
            title: '重量（吨）',
            dataIndex: 'zl',
            align: 'center'
        }
    ]
    export default {
        data() {
            return {
                form: this.$form.createForm(this, { name: 'formSearch' }),
                columns,
                dataSource: [],
                loading: true
            }
        },
        methods: {
            search () {
                const { isFieldsTouched, getFieldsValue } = this.form
                if (!isFieldsTouched()) return
                const [starttime, endtime] = getFieldsValue().rangeDate
                this.getDataForTable({ starttime, endtime })
            },
            getDataForTable (params) {
                this.loading = true
                this.$axios({
                    url: '/api/hdsp/tj_shlhztj',
                    methods: 'get',
                    params
                })
                    .then(res => {
                        if (res.status === 200 && res.data.code === 0) {
                            console.log(res.data.data);
                            const { data: rawData } = res.data

                            if (rawData.length !== 0) {
                                this.dataSource = [ 
                                    ...rawData, 
                                    {
                                        nickName: '总计',
                                        mj: this._.round(this._.reduce(rawData, (sum, t) => sum + t.mj, 0), 2),
                                        zl: this._.round(this._.reduce(rawData, (sum, t) => sum + t.zl, 0), 2),
                                    }
                                ]
                            }
                        }
                    })
                    .finally (() => this.loading = false)
            }
        },
        created () {
            const now = moment().format('YYYY-MM-DD')
            this.getDataForTable({ starttime: now, endtime: now })
            this.$nextTick(() => {
                this.form.setFieldsValue({ rangeDate: [now, now]})
            })
        },
    }
</script>

<style lang="less" scoped>
    .table::v-deep {
        margin-top:15px;

        .ant-table-tbody tr td {
            font-size: 16px;
        }
        
        .ant-table-tbody tr:last-child td:first-child {
            font-weight: 700;
            background: #fafafa;
        }
    }
</style>