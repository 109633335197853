<!--景观河报警信息列表-->
<template>
    <div id="components-form-demo-advanced-search">
        <a-modal v-model="video.visible" title="视频播放" width="800px" :title="video.title" :footer="null">
            <video autoplay="autoplay" :src="video.src" controls="controls" style="width:100%">
                您的浏览器不支持 video 标签。
            </video>
        </a-modal>
        <a-form :form="formSearch"
                @submit="formSearchSubmit"
                :label-col="{ span: 0 }"
                :wrapper-col="{ span: 24 }">
            <a-row :gutter="24">
                <a-col :span="6">
                    <a-form-item label="">
                        <a-input v-decorator="['title']"
                                 placeholder="点位"
                                 :maxLength="40" />
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label="">
                        <a-range-picker :placeholder="['发生开始时间','发生结束时间']" v-decorator="['happenTime_qj']" style="width:100%" />
                    </a-form-item>
                </a-col>
				<!-- 新增搜索 报警类型-->
				<a-col :span="8">
				    <a-form-item label="">
				        <a-select placeholder="报警类型" v-decorator="['alarmType']">
				            <a-select-option value="4">投垃圾</a-select-option>
				            <a-select-option value="402">漂浮物</a-select-option>
				            <a-select-option value="403">拖把清洗</a-select-option>
				            <a-select-option value="404">捕钓</a-select-option>
				            <a-select-option value="405">侵占岸线</a-select-option>
				        </a-select>
				    </a-form-item>
				</a-col>
				<!-- 改 -->
				<a-row :gutter="[24,12]">
				    <a-col :span="6" :style="{ textAlign: 'left' }">
				        <a-button type='primary' @click="()=>$router.push('list')" :style="{ marginLeft: '10px' }">列表模式</a-button>
				
				        <!-- primary/waring/danger/green -->
				    </a-col>
				    <a-col :span="18" :style="{ textAlign: 'right' }">
				        <a-button type="primary" :style="{ width:'48px',marginRight: '8px' }" html-type="submit" icon="search"></a-button>
				        <a-button type="" :style="{ width:'48px',marginRight: '8px' }" @click="formSearchReset" icon="reload"></a-button>
				        <!--<a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
				        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
				    </a>-->
				    </a-col>
				</a-row>
				<!-- 原 -->
                <!-- <a-col :span="6">
                    <a-form-item label="">
                        <a-button type="primary"
                                  :style="{ width: '48px' }"
                                  html-type="submit"
                                  icon="search"></a-button>
                        <a-button type=""
                                  :style="{ width: '48px', marginLeft: '8px' }"
                                  @click="formSearchReset"
                                  icon="reload"></a-button>
                    </a-form-item>
                </a-col> -->
            </a-row>

        </a-form>
        <!-- <a-button type='primary' @click="()=>$router.push('list')" style='margin-right: 5px'>列表模式</a-button> -->

        <div class="search-result-list" :style="{ marginTop: '20px' }">
            <ul class="list">
                <li v-for="(item,index) in tableData">
                    <a-button class="list-del" size="small" type="danger" v-if="delShow[index]" @click="del(item)">删除</a-button>
                    <div class="list-pic" @click="play(item)" @mouseover="onmouseover(index)" @mouseout="onmouseout(index)">
                        <!--<img class="smallpic" src="http://pt.zhangpu-ks.cn/upload/ljfl/img/2021/5/25/f16fcdee-bb7b-42ca-995d-56d6ebeb36ab.jpg" style="width:100px; height:150px;" />-->
                        <img class="bigpic" :src="$axios.defaults.fileURL+item.snapshoot" style="width:300px; height:150px;" />
                        <!--<a-icon type="play-circle" theme="filled" style="font-size:40px; color:aquamarine;" />-->
                    </div>
                    <div class="list-txt">
                        <h1>{{item.devName}}</h1>
						<h1 v-if="item.alarmType=='4'">报警类型：投垃圾</h1>
						<h1 v-if="item.alarmType=='402'">报警类型：漂浮物</h1>
						<h1 v-if="item.alarmType=='403'">报警类型：拖把清洗</h1>
						<h1 v-if="item.alarmType=='404'">报警类型：捕钓</h1>
						<h1 v-if="item.alarmType=='405'">报警类型：侵占岸线</h1>
						<div>
							<h1 style="display: inline-block">报警状态：</h1>
							<h1 v-if="item.bjzt=='0'" :style="{color: 'red',display: 'inline-block'}">  报警中</h1>
							<h1 v-else :style="{display: 'inline-block'}"> 未报警</h1>
						</div>
                        <div>发生时间：{{item.happenTime}}</div>
						<!-- <button style="width: 60px;">派单</button> 
						<button style=" width: 80px; margin-left: 60px;">解除派单</button> -->
						
                    </div>
                </li>
                <a-pagination v-model="pagination.current" :total="pagination.total" @change="change" :showTotal="pagination.showTotal" :pageSize="queryParam.pageSize" />
            </ul>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    export default {
        data() {
            return {
                showexport: false,
                scroll: {
                    x: 900,
                },
				
                buttons: [],
                inlineButtons: [],
                tableData: [],
                loading: false,
                expand: false,
                queryParam: {
                    pageSize: 10,
                    pageIndex: 1,

                },
                video: {
                    src: "",
                    visible: false,
                    title: "视频播放",
                },
			
                delShow: [],
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: "advanced_search" }),
                formAdd: this.$form.createForm(this, { name: "advanced_search11" }),
                pagination: {
                    total: 0,
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "50", "100"], // 每页中显示的数据
                    showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: "bottom",
                },
            };
        },
        mounted() {
            //将上面的按钮和表格内的按钮分出来
            console.log(this.$route.meta.buttons);
            for (let i = 0; i < this.$route.meta.buttons.length; i++) {
                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                } else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.init();
            window.onresize = () => {
                this.init();
            };
            this.bindList();
        },
        created() {
        },
        methods: {
            onmouseover(index) {
                //console.log(index);
                this.delShow[index] = true
                //console.log(this.delShow);
                this.$forceUpdate();
            },
            onmouseout(index) {
                this.delShow[index] = false
                //this.$forceUpdate();
            },
            play(item) {
                this.video.visible = true;
                this.video.src = "http://ksssw.kejin.net.cn:8004" + item.video;
                //this.videoUrl = require('http://pt.zhangpu-ks.cn/upload/ljfl/video/2021/7/9/5503235b-a2ba-45eb-8e0f-c29b3a20ed19.mp4')

            },
            change(pageIndex) {
                this.queryParam.pageIndex = pageIndex;
                this.bindList();
            },
            btnClick(text, item) {
                let _this = this;
                this.opration = text;
                switch (text) {
                    case "新增":
                        this.openDlg();
                        break;
                    case "编辑":

                        this.openDlg(item);
                        break;
                    case "查看":
                        this.openDlg(item, "view");
                        break;
                    case "删除":
                        this.$confirm({
                            title: "操作确认",
                            content: "确定要删除?",
                            okText: "确认",
                            cancelText: "取消",
                            onOk() {
                                _this.del(item);
                            },
                        });
                        break;
                    case "设为当前":
                        this.$confirm({
                            title: "操作确认",
                            content: "确定要设为当前考核吗，只能存在一个当前考核",
                            okText: "确认",
                            cancelText: "取消",
                            onOk() {
                                _this.$axios({
                                    url: "/api/Xckh/setisuse",
                                    method: "get",
                                    params: {
                                        id: item.id
                                    }
                                }).then((res) => {
                                    if (res.data.code == 0) {
                                        _this.$message.success("设置成功");
                                        _this.bindList();
                                    }
                                    else {
                                        throw new Error("设置失败");
                                    }
                                }).catch((error) => {
                                    // 请求失败处理
                                    _this.$message.error(error.message);
                                });
                            },
                        });
                        break;
                }
            },
            bindList: function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1;
                    this.queryParam.where = {};
                    this.queryParam.where.kind = '01';
                    for (var obj in values) {
                        if (values[obj]) {
                            if (obj == "happenTime_qj") {
                                this.queryParam.where[obj] = moment(values[obj][0]).format("L") + "-" + moment(values[obj][1]).format("L");
                            } else {
                                this.queryParam.where[obj] = values[obj];
                            }
                        }
                    }
                    this.loading = true;
                    this.InitGrid();
                });

            },
            InitGrid: function () {
                this.$axios({
                    url: "/api/hdsp/getList_hdsp_bjxx",
                    method: "post",
                    data: this.queryParam,

                }).then((res) => {
                    //console.log(response);
                    this.loading = false;
                    if (res.status === 200) {
                        this.tableData = res.data.data.list;
                        this.pagination.total = res.data.data.total;
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            onShowSizeChange(current, pageSize) {
                console.log(current, pageSize);
                return;
            },
            formSearchSubmit(e) {
                console.log(e);
                e.preventDefault();
                this.pagination.current = 1;
                this.bindList();
            },
            formSearchReset() {
                this.formSearch.resetFields();
                this.strWhere = "del=0";
                this.bindList();
            },
            pageCurrentChange(pagination) {
                console.log(pagination);
                //return;
                this.pagination.current = pagination.current;
                this.bindList();
            },
            del(record) {
                let _this = this;
                this.$confirm({
                    title: '操作确认',
                    content: "是否删除?",
                    okText: '是',
                    cancelText: '否',
                    onOk() {

                        _this.$axios({
                            url: "/api/Comon/deltag",
                            method: "post",
                            data: {
                                tableName: "hdsp_bjxx",
                                field: "id",
                                value: record.id,
                                source: "web",
                                loginid: "",
                            },
                        }).then((res) => {
                            if (res.status == 200) {
                                if (res.data.code == 0) {
                                    this.$message.info("删除成功");
                                    this.bindList();
                                } else {
                                    throw new Error("服务器超时");
                                }
                            } else {
                                throw new Error("服务器超时");
                            }
                        }).catch((error) => {
                            // 请求失败处理
                            this.$message.error(error.message);
                        });

                    }
                });

                
            },
            init() {
                var that = this;
                size();
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight;
                    that.$nextTick(() => {
                        var el = document.querySelector(
                            "#components-form-demo-advanced-search>form"
                        );
                        if (el) {
                            that.scroll.y =
                                innerHeight - getComputedStyle(el).height.split("p")[0] - 270;
                        }
                    });
                }
            },
        },
    };

</script>
<style scoped>
    .list li {
        display: inline-block;
        margin: 0px 20px 20px 0px;
        box-shadow: 0px 0px 10px #c2c2c2;
        border-radius: 8px 8px 0px 0px;
        overflow: hidden;
    }

        .list li .smallpic {
            overflow: hidden
        }

    .list .list-txt {
        padding: 12px;
    }

    .list li {
        position: relative
    }

    .list .list-pic i {
        position: absolute;
        top: 30%;
        right: 42%;
        z-index: 9;
    }

    .list .list-del {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .title{
        font-size:20px;
    }
    h1{
        font-size:16px !important;
    }
</style>