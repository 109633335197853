<template>
	<div id="components-layout-demo-basic" v-if="showasider">
		<a-layout>
            <a-layout-sider :trigger="null" collapsible :width="menu_width" style="background-color: #fff;overflow:auto;height:100%;">

                <a-directory-tree multiple @select="onSelect" @expand="onExpand" v-if="istree">
                    <a-tree-node key="0-0" title="重要点位" value="ksslj"></a-tree-node>
                    <a-tree-node v-for="(item,index) in slzData" :key="String('1'+'-'+index)" :title="item.name">
                        <template v-if="item.child">
                            <a-tree-node v-for="(subitem,subindex) in item.child" :key="String('2'+'-'+subindex+subitem.id)" :title="subitem.name" :value="subitem.id" is-leaf />
                        </template>
                    </a-tree-node>
                </a-directory-tree>

                <a-directory-tree multiple @select="onSelect_jsl" @expand="onExpand" v-if="istree_jsl">
                    <a-tree-node v-for="item in jslData" :key="item.StationID" :title="item.name" :value="item.StationID" is-leaf />
                </a-directory-tree>

            </a-layout-sider>
			<a-layout-content :style="{ margin: '16px !important', padding: '16px !important', background: '#fff', minHeight: '280px' , position: 'relative'}">
				<router-view></router-view>
			</a-layout-content>
		</a-layout>
	</div>
	<div v-else class="nosider">
		<router-view></router-view>
	</div>
</template>
<script>
    export default {
        data() {
			return {
				showasider:this.$route.path=="/internetOfThings/bzgq"?false:true,
				qzlist:[
					{name:"ceshi1",value:1},
					{name:"ceshi2",value:2},
					{name:"ceshi3",value:3,children:[
						{name:"ceshi3-1",value:4},
						{name:"ceshi3-2",value:5}
					]},
                ],
                menu_width:220,
                slzData: [],
                jslData: [],
				parentPath: '',
				istree: false,
				istree_jsl: true,
				//selectedKeys:this.$route.query.id?[this.$route.query.id]:["0-0"],
				//expandedKeys:this.$route.query.id?[this.$route.query.id]:["0-0"]
            }
        },
		watch:{
			"$route.path"(va){
				if(va=="/internetOfThings/bzgq"){
					this.showasider=false
					return
				}
					
				if (va == "/internetOfThings/jsl") {
					this.istree = false;
                    this.istree_jsl = true;
                    this.menu_width = 220
                }else if(va == "/internetOfThings/home_tj"){
					this.istree = false;
					this.istree_jsl = true;
					this.menu_width = 220
				}
				 else {
					this.istree = true;
                    this.istree_jsl = false;
                    this.menu_width = 220
                }
				this.showasider=true
				//this.selectedKeys=["0-0"]
				//this.expandedKeys = ["0-0"]
			}
		},
		mounted() {
			setTimeout(() => {
                console.log(this.$refs.ulScroll1.scrolltop);
			},100)
        },
		created() {
            this.bind_left_menu();
        },
		methods: {
            bind_left_menu() {
                //其他菜单
                this.$axios({
                    url: "/api/hdsq/GetSlzqzxx",
                    method: "get"
                }).then(res => {
                    console.log(res)
                    if (res.data.code == 0) {
                        this.slzData = res.data.data
                    } else {
                        this.$message.error('网络繁忙，请稍后重试！');
                    }
                }).catch(err => {
                    this.$message.error('网络繁忙，请稍后重试！');
				})

                //降水量菜单
                this.$axios({
                    url: "/api/slxx/GetStationList",
                    method: "get"
                }).then(res => {
                    console.log(res)
                    if (res.data.code == 0) {
                        this.jslData = res.data.data
                    } else {
                        this.$message.error('网络繁忙，请稍后重试！');
                    }
                }).catch(err => {
                    this.$message.error('网络繁忙，请稍后重试！');
                })
			},
            onSelect(keys, event) {
                console.log(keys);
                //this.selectedKeys = keys
                //this.expandedKeys = keys
                var uid = event.selectedNodes[0].value
				if (keys[0] == "0-0") {
					this.$router.push(this.$route.path + '?id=0-0&town=&uid=' + uid)
                    return
				}
				var name = event.selectedNodes[0].title,
					tn = name ? name : "";
                if (uid) { this.$router.push(this.$route.path + '?id=' + keys[0] + '&town=' + tn + '&uid=' + uid) }
            },
			onSelect_jsl(keys, event) {
                var StationId = event.selectedNodes[0].value
                var name = event.selectedNodes[0].title ? event.selectedNodes[0].title : "";
                if (StationId) { this.$router.push(this.$route.path + '?StationId=' + StationId + '&title=' + name) }
            },
			onExpand() {

            },
        }
    };
</script>
<style>
	#components-layout-demo-basic{height: 100%;}
	.routerwrap{height: 100%;}
	.routerwrap>.router{background: rgba(238,238,238,.4);height: 100%;box-sizing: border-box;padding: 10px;overflow: auto;}
	.nosider{background: #f0f2f5;position: absolute;left: 0;right: 0;top: 0;height: 100%;overflow: auto;}
</style>
