<template>
    <div class="lzj_content">
        <div class="lzj_top">
            <ul>
                <li>
                    <span>视频总数</span>
                    <span style="color: #3382FF;">{{six.视频正常数+six.视频异常数}}</span>
                </li>
                <li>
                    <span>视频正常数</span>
                    <span style="color:  #F8532A;">{{six.视频正常数}}</span>
                </li>
                <li>
                    <span>视频异常数</span>
                    <span style="color: #6979F8;">{{six.视频异常数}}</span>
                </li>
				<li>
				    <span>视频告警数</span>
				    <span style="color: #FF4D4F">{{six.视频告警数}}</span>
				</li>
                <li>
                    <span>水情监测设备数</span>
                    <span style="color: #3CB38D;">{{six.水情监测设备数}}</span>
                   <!-- <span>合格次数:{{six.rightCheckedPros}}，不合格次数:{{six.errCheckedPros}}</span> -->
                </li>
                <li>
                    <span>雨情监测设备数</span>
                    <span style="color: #3382FF;">{{six.雨情监测设备数}}</span>
                </li>
                <li>
                    <span>积水易涝设备数</span>
                    <span style="color: #FF4D4F">{{six.积水易涝设备数}}</span>
                </li>
				<li>
				    <span>派单总数</span>
				    <span style="color: #FF4D4F">{{six.派单未办结数+six.派单已办结数}}</span>
				</li>
				<li>
				    <span>派单未办结数</span>
				    <span style="color: #FF4D4F">{{six.派单未办结数}}</span>
				</li>
				<li>
				    <span>派单已办结数</span>
				    <span style="color: #FF4D4F">{{six.派单已办结数}}</span>
				</li>
				
				
            </ul>
        </div>
        <div class="middle">
            <div>
                <div class="bordtitle">视频告警统计图</div>
                <div class="charts" ref="charts"></div>
            </div>
        </div>
        <div class="bottom" v-if="false">
            <div class="bottom-left">
                <div class="bordtitle">站闸相关设备数统计</div>
                <div class="table">
                    <table>
                        <tr>
                            <td>序号</td>
                            <td>站闸</td>
                            <td>项目数</td>
                            <td>检查项数</td>
                        </tr>
                        <tr v-for="(item,index) in datasource" :key="index">
                            <td>{{index+1}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.pros}}</td>
                            <td>{{item.infos}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="bottom-right">
                <div class="bordtitle">各项目检查次数占比图</div>
                <!-- <div ref="pie" class="pie" style="height: 419px;"></div> -->
            </div>
        </div>
    </div>
</template>
<script>
    import * as echarts from 'echarts'
    export default {
        data() {
            return {
                six: {
                    zzs: '--',
                    prozs: '--',
                    infos: '--',
                    wgys: '--',
                    checkedProzs: '--',
                    rightCheckedPros: '--',
                    errCheckedPros: '--',
                    pdcs: '--',
                    dbsl: '--'
                },
                datasource: [{ name: "--", pros: '--', infos: '--' }]
            }
        },
        created() {
            this.GetGkxx()
            this.GetXcLogZst()
            this.GetZxsbxx()
            this.GetProCheckCount()
        },
        mounted() {
            // setTimeout(()=>{
            // 	this.setChart()
            // 	this.piechart()
            // })
        },
        methods: {
            GetGkxx() {
                this.$axios({
                    url: '/api/zzgl/GetGkxx_wlw'
                }).then(res => {
                    if (res.data.code == 0) {
                        this.six = res.data.data
                    }
                })
            },
            GetXcLogZst() {
                this.$axios({
                    url: '/api/zzgl/Get_sp_tj_wlw'
                }).then(res => {
                    var xdata = [], ydata = []
                    if (res.data.code == 0) {
                        console.log(res.data.data);
                        // for (var i = 0; i < res.data.data.length; i++) {
                        //     xdata.push(res.data.data[i].name)
                        //     ydata.push(res.data.data[i].value)
                        // }
						xdata=res.data.data.xAxis;
						ydata=res.data.data.value;
                        this.setChart(xdata, ydata)
                    }
                })
            },
            GetZxsbxx() {
                this.$axios({
                    url: '/api/zzgl/GetZxsbxx'
                }).then(res => {
                    if (res.data.code == 0) {
                        this.datasource = res.data.data
                    }
                })
            },
            GetProCheckCount() {
                this.$axios({
                    url: '/api/zzgl/GetProCheckCount'
                }).then(res => {
                    if (res.data.code == 0) {
						if(res.data.data.length==0)return
                        this.piechart(res.data.data)
                    }
                })
            },
            setChart(xdata, ydata) {
                //var data1 = ydata;
                //var data2 = [];
                //定义两个上下箭头的矢量路径
                //var up = 'path://M286.031,265l-16.025,3L300,223l29.994,45-16.041-3-13.961,69Z';
                //var down = 'path://M216.969,292l16.025-3L203,334l-29.994-45,16.041,3,13.961-69Z'
                //var path = [up, down, up, down, down, down, up]
                var option = {
                    backgroundColor: 'white',
                    color: '#3398DB',
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        top: '20%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: xdata,
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            splitLine: {
                                lineStyle: {
                                    type: 'dashed',
                                    color: '#eee'
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            "name": "告警次数",
                            //type: 'pictorialBar',
                            type: 'bar',
                            symbolSize: [0, 0],
                            symbolOffset: [0, 0],
                            color: 'orange',
                            symbolPosition: 'end',
                            label: {
                                show: true,
                                position: "top",
                                fontSize: 30,
                                fontWeight: 'bold',
                                color: '#34DCFF'
                            },
                            data: ydata//[1,1,1,1,1,1,1,1,1,1,1,1]
                        }
                    ]
                };
                var myChart = echarts.init(this.$refs.charts)
                myChart.setOption(option)
                window.addEventListener("resize", function () {
                    myChart.resize();
                })
            },
            piechart(piedata) {
                var option = {
                    color: ['#37a2da', '#32c5e9', '#9fe6b8', '#ffdb5c', '#ff9f7f', '#fb7293', '#e7bcf3', '#8378ea'],
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br />{b} : {c} ({d}%)"
                    },
                    toolbox: {
                        show: true,
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    legend: {
                        type: "scroll",
                        orient: 'vertical',
                        left: '5%',
                        align: 'left',
                        top: 'middle',
                        textStyle: {
                            color: '#8C8C8C',
                            fontSize: 20
                        },
                        // height:150
                    },
                    series: [
                        {
                            name: '检查次数',
                            type: 'pie',
                            radius: [0, '75%'],
                            center: ["60%", "center"],
                            label: {
                                show: true,
                                normal: {
                                    fontSize: 20
                                }
                            },
                            data: piedata
                        }
                    ]
                }
                var myChart = echarts.init(this.$refs.pie)
                myChart.setOption(option)
                window.addEventListener("resize", function () {
                    myChart.resize();
                })
            }
        }
    }
</script>
<style scoped>
    .lzj_content {
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        width: auto;
        height: auto;
        background: #f0f2f5;
    }

    .lzj_top > ul {
        display: flex;
        justify-content: space-between;
    }

        .lzj_top > ul > li {
            display: flex;
            /* width: 100%; */
            flex: 2;
            flex-direction: column;
            list-style: none;
            background: white;
            margin-right: 16px;
            padding: 16px;
            font-size: 16px;
            cursor: default;
        }

            .lzj_top > ul > li:nth-child(4) {
                flex: 3;
            }

            .lzj_top > ul > li:last-child {
                margin-right: 0;
            }

            .lzj_top > ul > li > span:nth-child(2) {
                font-size: 30px;
                font-weight: bold;
            }

    .middle > div {
        background: white;
        position: relative;
    }

    .charts {
        height: 450px;
        box-sizing: border-box;
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
    }

        .bottom > div {
            width: 100%;
            background: white;
            /* height: 400px; */
        }

            .bottom > div:first-child {
                margin-right: 16px;
            }

    .bottom-left {
        box-sizing: border-box;
        padding: 16px;
        position: relative;
    }

    .table {
        box-sizing: border-box;
        padding-top: 56px;
    }

    .bottom-left table {
        border-collapse: collapse;
        width: 100%;
        height: 100%;
    }

        .bottom-left table tr td {
            border: 1px solid rgba(0,0,0,.1);
            font-size: 15px;
            box-sizing: border-box;
            padding: 16px;
            text-align: center;
        }

        .bottom-left table tr:nth-child(1) td {
            height: 54px;
            background: #fafafa;
        }

    .bottom-right {
        position: relative;
    }

    .bordtitle {
        position: absolute;
        box-sizing: border-box;
        padding-left: 16px;
        width: 100%;
        left: 0;
        top: 0;
        font-size: 16px;
        height: 56px;
        line-height: 56px;
        border-bottom: 1px solid rgba(0,0,0,.1);
        z-index: 1;
    }

    .pie {
        height: 100%;
        box-sizing: border-box;
        padding-top: 50px;
    }

    .ant-layout-content {
        overflow-x: hidden !important;
    }
</style>