<template>
    <div id="map" style="height: 100%;"></div>
</template>

<script>
    import * as esriLoader from 'esri-loader'

    esriLoader.loadScript({
        url: 'http://180.97.207.33:8091/arcgis_js_api/library/4.17/init.js',
        dojoConfig: {
            async: true,
            packages: [{
                name: 'app',
                location: 'http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/app'
            }]
        }
    });

    esriLoader.loadCss('http://180.97.207.33:8091/arcgis_js_api/library/4.17/esri/themes/light/main.css');
    esriLoader.loadCss('http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/GRSHGISLibs.css');

    const loadModulesUrl = [
        'http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/app/MapInitial.js',
        'http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/app/KSSmartWater/ksSWInitial.js',
        'http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/app/test/pntlyrext2.js'
    ]

    function loadMap() {
        return new Promise(resolve => {
            esriLoader.loadModules(loadModulesUrl).then(resolve)
        })
    }
    export default {
        methods: {
            async initMap () {
                const [
                    MI,
                    ksSWInitial,
                    pntlyrext2
                ] = await loadMap()

                const mi = new MI({
                    container: 'map',
                    useBSW: true,
                    mapsource: 1,
                    maptype: 1,
                    OMKey: this.$User.user.token.access_token,
                    tolerance: 30,
                    isUseTile: true
                })
                const xcw = new ksSWInitial({ mi })
                xcw.initial() //智慧水利相关功能初始化
                xcw.setAllBaseSubLayerVisibility(false) //关闭所有水系基础图层
                xcw.setAllDBSubLayerVisibility(false) //关闭所有业务图层
                xcw.setBaseWaterSubLayerVisibility('River', true) //打开河道图层，配置为false，则关闭河道图层，第一个参数为指代图层的target参数
                xcw.setBaseWaterSubLayerVisibility('Lake', true) //打开湖泊图层
                xcw.setBaseWaterSubLayerVisibility('TownBoundary', true) //打开乡镇界线图层
                xcw.setBaseWaterSubLayerVisibility('CountyBoundary', true) //打开乡镇界线图层
                mi.zoomto(120.9808441, 31.34081901, 10)

                const mapData = await this.getDataForMap()

                new pntlyrext2({
                    mi,
                    id: 'layerId5',
                    title: '水工程分布图',
                    data: mapData,
                    x_Field: 'jx',
                    y_Field: 'jy',
                    markerPicUrl: '/img/point.png',
                    // labelNm: 'Name',
                    // labelTxtSymbol: {
                    //     type: 'text',
                    //     color: 'blue',
                    //     haloColor: 'white',
                    //     haloSize: '1px',
                    //     xoffset: -8,
                    //     yoffset: -20,
                    //     font: {
                    //         size: 12,
                    //         weight: 'bold'
                    //     }
                    // }
                })
            },
            getDataForMap () {
                const data = { pageIndex: 1, pageSize: 10000 }
                return this.$axios.post('/api/GetList/get_v_base_zzxx_gr_List', data)
                    .then(res => {
                        if (res.status === 200 && res.data.code === 0) return res.data.data.list
                    })
            }
        },
        mounted () {
            this.initMap()
        },
    }
</script>

<style lang="less" scoped>

</style>