<template>
    <div>
        
    </div>
</template>
<script>
    export default {
        data() {
            return {
            }
        },
        components: {
        },
        created: function () {
            this.$router.push("/gcyxgl/hdsp/cameraViewList?BindGroupName=城区新增摄像头")
        },
        methods: {
        }
    }
</script>
<style>
</style>