<template>
    <a-layout class="layout" id="layout">

        <a-layout-sider 
            id="layoutSider"
            :width="sw" 
            :collapsible="true" 
            :collapsedWidth="0" 
            :trigger="null" >
            
            <div style="minWidth:354px;height:100%">
                <slot name="sider"></slot>
            </div>
            
            <!-- 滑动线 -->
            <div class="slider-bar" ref="sliderBar"></div>
        </a-layout-sider>

        <slot name="content"></slot>
        
        <slot name="extra"></slot>
    </a-layout>
</template>

<script>
    export default {
        props: {
            siderWidth: {
                type: Number,
                default: 320
            }
        },
        data () {
            return {
                sw: this.siderWidth
            }
        },
        methods: {
            initTelescopiDoor () {
                let cloneEl = null,
                    initX = 0,
                    cloneElX = 0,
                    cloneElMoveX = 0,
                    layout = document.querySelector('#layout'),
                    sliderBar = this.$refs.sliderBar

                sliderBar.addEventListener('mousedown', e => {
                    let { left } = window.getComputedStyle(sliderBar)
                    cloneEl = sliderBar.cloneNode(false)
                    cloneEl.classList.add('clone')
                    cloneEl.style.left = left
                    layout.appendChild(cloneEl)
                    
                    initX = e.clientX
                    cloneElX = Number.parseInt(left)

                    document.addEventListener('mousemove', move)
                    document.addEventListener('mouseup', () => {
                        document.removeEventListener('mousemove', move)
                        this.sw = cloneElMoveX
                        layout.removeChild(cloneEl)
                    }, { once: true })
                    
                })

                let move = e => {
                    e.preventDefault()
                    cloneElMoveX = e.clientX - initX + cloneElX

                    if (cloneElMoveX < 0) {
                        cloneEl.style.left = 0
                        return cloneElMoveX = 0
                    }

                    if (cloneElMoveX > layout.offsetWidth) {
                        console.log(layout.offsetWidth);
                        cloneEl.style.left = layout.offsetWidth - cloneEl.offsetWidth  + 'px'
                        return cloneElMoveX = layout.offsetWidth
                    }

                    cloneEl.style.left = cloneElMoveX + 'px'
                }

            }
        },
        mounted () {
            this.initTelescopiDoor()
        }
    }
</script>

<style lang="less" scoped>
    .layout {
        overflow: hidden;
        position: relative;
        height: 100%;
        background-color: #fff;

    }

    /deep/ .ant-layout-sider {
        overflow: hidden;
        position: relative;
        padding-right: 10px;
        background-color: #fff;
        transition: all .3s;
        box-shadow: 2px 0 8px #ccc;
    } 

    .slider-bar {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999;
        width: 3px;
        height: 100%;
        background-color: #ccc;
        
        cursor: w-resize;
    }

    .slider-bar.clone {
        opacity: .7;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
    }
</style>