<template>
  <div id="components-form-demo-advanced-search">

    <a-modal v-model="formAddOptions.visible" :title="opration" width="800px">
      <a-spin :spinning="formAddOptions.spinning">
        <a-form :form="formAdd" :label-col="{ span:6 }" :wrapper-col="{ span:18 }" labelAlign="right">

          <a-form-item label="" style="display:none">
            <a-input v-decorator="['id']" />
            <a-input v-decorator="['zzbh',{ initialValue:this.$route.query.bh }]" />
            <a-input v-decorator="['townId',{ initialValue:this.$route.query.townId }]" />
          </a-form-item>

          <a-row type="flex" justify="space-between">
            <a-col :span="20">
              <a-form-item label="应急预案">
                <a-select v-decorator="['yaid',{ rules: [{ required: true, message: '请选择检查频率'}]},]" placeholder="请选择检查频率" style="width:100%" @change="select_yjya">
                  <a-select-option v-for="item in yjyaData" :value="item.id">{{item.title}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between" class="displayCss-none2">
            <a-col :span="20">
              <a-form-item label="检查频率">
                <a-select v-decorator="['jg_unit',{ rules: [{ required: true, message: '请选择检查频率'}]},]" placeholder="请选择检查频率" style="width:100%" @change="Change_jcpl">
                  <a-select-option value="0">N天一次</a-select-option>
                  <a-select-option value="1">N周一次</a-select-option>
                  <a-select-option value="2">N月一次</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between" v-if="formAddOptions.dateVisible" class="displayCss-none2">
            <a-col :span="20">
              <a-form-item :label="week_month_text">
                <a-select v-decorator="['zxsj',{ rules: [{ required: true, message: '请选择'+week_month_text}]},]" :placeholder="'请选择'+week_month_text" style="width:100%">
                  <a-select-option v-if="date_week" v-for="(item,index) in dateWeek_Data" :value="String(item.value)">{{item.name}}</a-select-option>
                  <a-select-option v-if="date_month" v-for="(item,index) in 28" :value="String(index+1)">{{index+1}}号</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between" class="displayCss-none2">
            <a-col :span="20">
              <a-form-item label="N的值">
                <a-input-number :min="1" :max="N_max" v-decorator="['jg_num', { rules: [{ required: true, message: '请输入N的值' }]}]" placeholder="请输入N的值"></a-input-number>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="20">
              <a-form-item label="检查队伍">
                <a-select v-decorator="['teamId',{ rules: [{ required: true, message: '请选择检查队伍'}]},]" placeholder="请选择检查队伍" style="width:100%">
                  <a-select-option v-for="item in teamData" :value="item.value">{{item.name}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between" class="displayCss-none2">
            <a-col :span="20">
              <a-row>
                <a-form-item label="开始时间">
                  <a-row type="flex">
                    <a-col>
                      <a-select v-decorator="['startTime_y',{ rules: [{ required: true, message: '请选择月份'}]},]" placeholder="月份" style="width:150px;">
                        <a-select-option v-for="(item,index) in 12" :value="index+1">{{index+1}}月</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col>
                      <a-select v-decorator="['startTime_r',{ rules: [{ required: true, message: '请选择日期'}]},]" placeholder="日期" style="width:150px;margin-left:15px;">
                        <a-select-option v-for="(item,index) in 28" :value="index+1">{{index+1}}号</a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between" class="displayCss-none2">
            <a-col :span="20">
              <a-row>
                <a-form-item label="结束时间">
                  <a-row type="flex">
                    <a-col>
                      <a-select v-decorator="['endTime_y',{ rules: [{ required: true, message: '请选择月份'}]},]" placeholder="月份" style="width:150px;">
                        <a-select-option v-for="(item,index) in 12" :value="index+1">{{index+1}}月</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col>
                      <a-select v-decorator="['endTime_r',{ rules: [{ required: true, message: '请选择日期'}]},]" placeholder="日期" style="width:150px;margin-left:15px;">
                        <a-select-option v-for="(item,index) in 28" :value="index+1">{{index+1}}号</a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
      <template slot="footer">
        <a-button key="back" @click="formAddOptions.visible=false;">关闭</a-button>
        <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
      </template>
    </a-modal>

    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="">
            <a-input v-decorator="['name']" placeholder="请输入站闸（堤防）名称"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-select v-decorator="['townId']" placeholder="请选择区镇" :disabled="qz_ifVisible" style="width:100%" @change="select_qz">
              <a-select-option v-for="item in townData" :value="item.value">{{item.name}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-select v-decorator="['bh']" placeholder="请选择站闸" style="width:100%">
              <a-select-option v-for="item in zzData" :value="item.value">{{item.name}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-select v-decorator="['source']" placeholder="请选择类型" style="width:100%">
              <a-select-option value="站闸">站闸</a-select-option>
              <a-select-option value="堤防">堤防</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24" v-if="expand">
        <a-col :span="6">
          <a-form-item label="">
            <a-select v-decorator="['isExec']" placeholder="请选择开关状态" style="width:100%">
              <a-select-option value="1">开</a-select-option>
              <a-select-option value="0">关</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[24,12]">
        <a-col :span="6" :style="{ textAlign: 'left' }">
          <a-button v-if="btn_plxz" type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button>
          <!-- primary/waring/danger/green -->
        </a-col>
        <a-col :span="18" :style="{ textAlign: 'right' }">
          <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
          <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px',display:'' }" @click="toggle">
            {{ expand ?'收起':'展开' }}
            <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>
    <a-table :columns="columns" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :data-source="tableData" :pagination="pagination" :pageSize="20" :total="50" rowKey="id" :loading="loading" :scroll="scroll" @change="pageCurrentChange" position="bottom" tableLayout="fixed">
      <span slot="zkg" slot-scope="text,record">
        <a-switch v-if="record.isExec==0" checked-children="开" un-checked-children="关" @click="status_edit(record)" />
        <a-switch v-if="record.isExec==1" checked-children="开" un-checked-children="关" default-checked @click="status_edit(record)" />
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="primary" size="small" style='margin:0 5px' @click='lookproject(record)'>计划管理</a-button>
      </span>
      <span slot="xczzs" slot-scope="text, record">
        <a-tag color="green" style="text-align:center;cursor:pointer;" @click="view_xcjl(record)">
          {{text}}&nbsp;条
        </a-tag>
      </span>
      <!--<span slot="checkboxs" slot-scope="text, record">
                <a-checkbox @change="checked_table" :value="record">
                </a-checkbox>
            </span>-->
    </a-table>
  </div>
</template>
<script>
const strWhere = 'del=0'
export default {
  data() {
    return {
      scroll: {},
      townData: [],
      zzData: [],
      qzLoginid: '',
      showexport: false,
      qz_ifVisible: false,
      btn_plxz: false,
      check_isbool: false,
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [],
      teamData: [],
      yjyaData: [],
      checked_tableData: [],
      selectedRowKeys: [],
      addInfo: {
        send_number: 0,
        back_number: 0,
        yes_data: [],
        no_data: []
      },
      opration: '',
      N_max: 10000,
      week_month_text: '',
      dateWeek_Data: [
        { name: '周一', value: 1 },
        { name: '周二', value: 2 },
        { name: '周三', value: 3 },
        { name: '周四', value: 4 },
        { name: '周五', value: 5 },
        { name: '周六', value: 6 },
        { name: '周日', value: 7 }
      ],
      columns: [
        //{
        //    title: '',
        //    dataIndex: 'checkboxs',
        //    key: 'checkboxs',
        //    fixed: 'left',
        //    width: 100,
        //    scopedSlots: { customRender: 'checkboxs' }
        //},
        {
          title: '序号',
          dataIndex: 'rownum',
          key: 'rownum',
          fixed: 'left',
          width: 100
        },
        {
          title: '站闸（堤防）名称',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '所属区镇',
          dataIndex: 'town',
          key: 'town'
        },
        {
          title: '计划任务数',
          dataIndex: 'zplans',
          key: 'zplans'
        },
        {
          title: '计划开启数',
          dataIndex: 'onplans',
          key: 'onplans'
        },
        {
          title: '计划关闭数',
          dataIndex: 'offplans',
          key: 'offplans'
        },
        {
          title: '计划总开关',
          dataIndex: 'zkg',
          key: 'zkg',
          scopedSlots: { customRender: 'zkg' }
        },
        {
          title: '生成巡查记录查看',
          dataIndex: 'xczzs',
          key: 'xczzs',
          scopedSlots: { customRender: 'xczzs' }
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: true,
      action: 'add',
      previewVisible: false,
      previewImage: '',
      fileList: [],
      strWhere,
      expand: false,
      formAddOptions: {
        visible: false,
        submitLoading: false,
        submitVisible: true,
        viewMode: false,
        spinning: false
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search2' }),
      formAdd: this.$form.createForm(this, { name: 'advanced_search3' }),
      pagination: {
        total: 0,
        pageSize: 15, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom'
      },
      xzlist: ['乡镇一', '乡镇二', '乡镇三', '乡镇四', '乡镇五']
    }
  },
  watch: {
    checked_tableData: function (newval, oldval) {
      if (this.checked_tableData.length > 0) {
        this.btn_plxz = true
      } else {
        this.btn_plxz = false
      }
    },
    addInfo: {
      handler(newval, oldval) {
        if (this.addInfo.send_number == this.addInfo.back_number) {
          if (this.addInfo.no_data.length < 1) {
            this.$message.info('保存成功')
            this.reload_tableData()
          } else {
            if (this.addInfo.no_data[0] != '500') {
              var yesStr = '',
                noStr = ''
              for (var i = 0; i < this.addInfo.yes_data.length; i++) {
                yesStr += this.addInfo.yes_data[i] + '、'
              }
              if (yesStr.length > 0) {
                yesStr = yesStr.substring(0, yesStr.length - 1) + '  添加成功，'
              }
              for (var i = 0; i < this.addInfo.no_data.length; i++) {
                noStr += this.addInfo.no_data[i] + '、'
              }
              noStr = noStr.substring(0, noStr.length - 1)
              this.$message.info(
                yesStr + noStr + '  已有相同开始时间与结束时间的排班计划'
              )
              this.reload_tableData()
            } else {
              throw new Error('服务器错误')
            }
          }
        }
      },
      deep: true
    }
  },
  created() {
    // 模拟后台请求
    // this.bindList();
    // this.InitGrid()
    this.getTown()
  },
  mounted() {
    //console.log(this.$route)
    if (this.$route.meta.buttons) {
      for (let i = 0; i < this.$route.meta.buttons.length; i++) {
        if (this.$route.meta.buttons[i].tag == 'inline') {
          this.inlineButtons.push(this.$route.meta.buttons[i])
        } else {
          this.buttons.push(this.$route.meta.buttons[i])
        }
      }
    }

    this.init()
    this.actions()
    window.onresize = () => {
      this.init()
    }
    this.getTown()
    this.bind_zzxx('')
    this.bindDropDownData()
    this.bindList()
  },
  methods: {
    onSelectChange(selectedRowKeys, selectedRow) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      console.log('selectedRow changed: ', selectedRow)
      this.selectedRowKeys = selectedRowKeys
      this.checked_tableData = selectedRow
    },
    select_qz(value) {
      this.bind_zzxx(value)
    },
    //checked_table(e) {
    //    if (e.target.checked) { this.checked_tableData.push(e.target.value); }
    //    else { this.checked_tableData.splice(this.checked_tableData.indexOf(e.target.value), 1); }
    //    console.log(e);
    //    console.log(this.checked_tableData);
    //},
    getTown() {
      this.$axios({
        url: '/api/zzgl/GetTown',
        method: 'get'
      }).then(res => {
        if (res.data.code == 0) {
          this.xzlist = res.data.data
          this.townData = res.data.data
        }
      })
    },
    reload_tableData() {
      this.formAddOptions.visible = false
      this.formAddOptions.submitLoading = false
      this.bindList()
      this.selectedRowKeys = []
      this.checked_tableData = []
    },
    bindDropDownData() {
      var _this = this
      //队伍
      this.$axios({
        url: '/api/zzgl/GetXcTeam',
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.teamData = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })
      //应急预案
      this.$axios({
        url: '/api/Zzgl/GetXcYa',
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.yjyaData = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    bind_zzxx(value) {
      var _this = this

      this.$axios({
        url: '/api/Zzgl/getZzxx?townId=' + value,
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.zzData = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    select_yjya(value) {
      var _this = this
      this.$axios({
        url: '/api/Zzgl/GetXcYa?id=' + value,
        method: 'get'
      })
        .then(function (response) {
          console.log(response)
          if (response.status === 200) {
            var result = response.data.data
            _this.function_jcpl(result[0].jg_unit.toString())
            setTimeout(() => {
              _this.formAdd.setFieldsValue({
                jg_unit: result[0].jg_unit.toString(),
                zxsj: result[0].zxsj.toString(),
                jg_num: result[0].jg_num,
                //teamId: result[0].teamId,
                startTime_y: parseInt(result[0].startTime.split('-')[0]),
                startTime_r: parseInt(result[0].startTime.split('-')[1]),
                endTime_y: parseInt(result[0].endTime.split('-')[0]),
                endTime_r: parseInt(result[0].endTime.split('-')[1])
              })
            }, 100)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    Change_jcpl(value) {
      this.formAdd.setFieldsValue({ zxsj: '' })
      this.function_jcpl(value)
    },
    function_jcpl(value) {
      switch (value) {
        case '1':
          this.formAddOptions.dateVisible = true
          this.date_week = true
          this.date_month = false
          this.N_max = 7
          this.week_month_text = '星期几'
          break
        case '2':
          this.formAddOptions.dateVisible = true
          this.date_month = true
          this.date_week = false
          this.N_max = 12
          this.week_month_text = '一个月中的几号'
          break
        default:
          this.formAddOptions.dateVisible = false
          this.N_max = 10000
          break
      }
    },
    status_edit(record) {
      this.$axios({
        url: '/api/zzgl/changeExec?id=' + record.id + '&kind=zz',
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    view_xcjl(item) {
      this.$router.push(
        'pbjh_scxcrw?bh=' +
          item.bh +
          '&townId=' +
          item.townId +
          '&town=' +
          item.town +
          '&name=' +
          item.name
      )
    },
    lookproject(item) {
      this.$router.push(
        'pbjh_sz_children?bh=' +
          item.bh +
          '&townId=' +
          item.townId +
          '&town=' +
          item.town +
          '&name=' +
          item.name
      )
    },
    btnClick(text, item) {
      console.log(text)
      let _this = this
      this.opration = text
      switch (text) {
        case '批量新增排班计划':
          this.openDlg()
          break
        case '编辑':
          this.openDlg(item)
          break
        case '删除':
          this.$confirm({
            title: '操作确认',
            content: '确定要删除 ' + item.name + ' 吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.del(item)
            }
          })
          break
      }
    },
    bindList: async function () {
      this.formSearch.validateFields((error, values) => {
        error = 1
        // console.log(values)
        this.strWhere = 'del=0' // and source='站闸'
        for (var obj in values) {
          if (values[obj]) {
            if (obj == 'name') {
              this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
            } else {
              this.strWhere += ' and ' + obj + "='" + values[obj] + "'"
            }
          }
        }
        //console.log(this.strWhere)
        this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
      })
      this.loading = true
      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere)
      this.strWhere = this.strWhere.data
      await this.InitGrid()
    },
    InitGrid: function () {
      var _that = this
      _that.loading = true
      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName: 'v_zzgl_zzxx',
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          filesFields: 'bh',
          strWhere: _that.strWhere
        }
      })
        .then(function (response) {
          console.log(response)
          _that.loading = false
          if (response.status === 200) {
            _that.tableData = response.data.data
            _that.pagination.total = response.data.count
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    openDlg: async function (record, action) {
      this.formAdd.resetFields()
      this.fileList = []
      this.formAddOptions.submitVisible = true
      this.formAddOptions.viewMode = false
      if (record !== undefined) {
        // 从后台取数据赋值
        this.formAddOptions.spinning = true
        var obj = await this.$jsc.DesEncrypt(
          this,
          'id=zhangbiao' + record.id + 'zhangbiao'
        )
        obj = obj.data
        this.$axios
          .get(
            '/api/GetList/getListByTable?tableName=v_zhsw_xcgl_xcPlan&strWhere=' +
              obj +
              '&filesFields=bh'
          )
          .then(response => {
            if (response.status == 200) {
              if (response.data.code == 0) {
                this.formAddOptions.visible = true
                var result = response.data.data
                console.log(response.data)
                this.function_jcpl(result[0].jg_unit.toString())
                setTimeout(() => {
                  this.formAdd.setFieldsValue({
                    id: result[0].id,
                    yaid: result[0].yaid,
                    jg_unit: result[0].jg_unit.toString(),
                    zxsj: result[0].zxsj,
                    jg_num: result[0].jg_num,
                    teamId: result[0].teamId,
                    startTime_y: parseInt(result[0].startTime.split('-')[0]),
                    startTime_r: parseInt(result[0].startTime.split('-')[1]),
                    endTime_y: parseInt(result[0].endTime.split('-')[0]),
                    endTime_r: parseInt(result[0].endTime.split('-')[1])
                  })
                  this.editgetText('a')
                  this.formAddOptions.spinning = false
                }, 100)
              } else {
                this.$message.error(response.data.msg)
                this.formAddOptions.spinning = false
              }
            } else {
              // vue.prototype.$message.info('This is an error message');
            }
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error)
          })
        if (action === 'view') {
          // 预览模式
          this.formAddOptions.submitVisible = false
          this.formAddOptions.viewMode = true
        }
      } else {
        this.formAddOptions.visible = true
      }
    },
    formAddSubmit() {
      this.addInfo.send_number = 0
      this.addInfo.back_number = 0
      this.addInfo.yes_data = []
      this.addInfo.no_data = []
      this.formAdd.validateFields((err, values) => {
        if (!err) {
          values['startTime'] = values.startTime_y + '-' + values.startTime_r
          values['endTime'] = values.endTime_y + '-' + values.endTime_r
          //console.log(this.checked_tableData); return;
          this.formAddOptions.submitLoading = true
          for (var i = 0; i < this.checked_tableData.length; i++) {
            values.townId = this.checked_tableData[i].townId
            values.zzbh = this.checked_tableData[i].bh
            values.zzmc = this.checked_tableData[i].name
            this.addInfo.send_number++
            this.$axios({
              url: '/api/zzgl/EditPbPlan',
              method: 'post',
              data: values
            })
              .then(res => {
                this.addInfo.back_number++
                if (res.status == 200) {
                  if (res.data.code === 0) {
                    //console.log(res.data.callback);
                    this.addInfo.yes_data.push(res.data.callback)
                  } else {
                    //console.log(res.data.callback);
                    this.addInfo.no_data.push(res.data.callback)
                    //this.formAddOptions.submitLoading = false
                  }
                } else {
                  this.addInfo.no_data.push('500')
                  //throw new Error("服务器超时2");
                }
              })
              .catch(error => {
                // 请求失败处理
                this.addInfo.back_number++
                this.addInfo.no_data.push('500')
                //this.$message.error(error.message);
                this.formAddOptions.submitLoading = false
              })
          }
        }
      })
    },
    formSearchSubmit(e) {
      e.preventDefault()
      this.pagination.current = 1
      this.bindList()
    },
    pageCurrentChange(pagination) {
      this.pagination.current = pagination.current
      this.bindList()
    },
    formSearchReset() {
      this.formSearch.resetFields()
      this.strWhere = 'del=0'
      this.bindList()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    del(record) {
      this.$axios({
        url: '/api/Comon/deltag',
        method: 'post',
        data: {
          tableName: 'zzgl_zzxx',
          field: 'id',
          value: record.id,
          source: 'web',
          loginid: ''
        }
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('删除成功')
              this.bindList()
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },
    init() {
      var that = this
      size()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector(
            '#components-form-demo-advanced-search>form'
          )
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('p')[0] - 270
          }
        })
      }
    },
    actions() {
      //if (this.inlineButtons.length == 0) {
      //    this.columns.pop();
      //}
    }
  }
}
</script>
<style>
.displayCss-none2 {
  display: none;
}
</style>