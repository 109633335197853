<template>

    <a-layout id="components-layout-demo-custom-trigger">

        <a-modal v-model="formAddOptions.visible" title="修改密码" width="400px" height="400px">
            <a-spin :spinning="formAddOptions.spinning">
                <a-form :form="formAdd" :label-col="{ span:7 }" :wrapper-col="{ span:17 }" labelAlign="left">
                    <a-form-item label="" style="display:none">
                        <a-input v-decorator="['loginid']" />
                    </a-form-item>
                    <a-row type="flex" justify="space-between">
                        <a-col :span="24">
                            <a-form-item label="原密码">
                                <a-input :model="fields.oldpassword" v-decorator="['oldpassword',{ rules: [{ required: true, message: '请输入原密码' }]}]" placeholder="" :disabled="formAddOptions.viewMode" :maxLength="16" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="24">
                            <a-row>
                                <a-form-item label="新密码">
                                    <a-input type="password" v-decorator="['password', { rules: [{required: true,message:'请输入新密码'},{ pattern: new RegExp(/^(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{8,16}$/,'g'), message: '新密码格式不正确，8到16个字符，必须包含大小写字母，数字两种及以上组合' }]}]" placeholder="" :disabled="formAddOptions.viewMode" :maxLength="16" />
                                </a-form-item>
                            </a-row>
                        </a-col>
                        <a-col :span="24">
                            <a-row>
                                <a-form-item label="确认新密码">
                                    <a-input type="password" v-decorator="['dbpassword', { rules: [{ required: true, message: '请输入确认新密码' },{validator: handleConfirmPass}]}]" placeholder="" :disabled="formAddOptions.viewMode" :maxLength="16" />
                                </a-form-item>
                            </a-row>
                        </a-col>
                    </a-row>
                </a-form>
            </a-spin>
            <template slot="footer">
                <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
                <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
            </template>
        </a-modal>
        <a-layout-header class="blue-top" style="padding: 0">
            <div class="logo2wrap">
                <img class="logo2" src="../public/img/logo1.png" />
                <span>{{toptitle}}</span>
            </div>

            <a-menu mode="horizontal" id="topmenu">

                <a-menu-item key="mai1l" style="width:50px;">
                    <a-icon class="trigger"
                            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                            @click="() =>(collapsed = !collapsed)" />
                </a-menu-item>
                <a-menu-item key="mail" style="width:50px;" @click="()=>$router.push('/desk')"><a-icon type="home" /></a-menu-item>
                <a-sub-menu>
                    <span slot="title" @click="out" class="submenu-title-wrapper"><a-icon type="user" />{{this.$User.user.userInfo.realName}}</span>
                    <a-menu-item key="setting:3" @click="()=>this.formAddOptions.visible=true">
                        密码修改
                    </a-menu-item>
                    <a-menu-item key="setting:4" @click="out">
                        退出登陆
                    </a-menu-item>
                </a-sub-menu>
            </a-menu>
            <component v-bind:is="top" :topButtons="topButtons"></component>
        </a-layout-header>
    

        <a-layout>
            <!-- 侧边栏 -->
            <a-layout-sider v-model="collapsed" :trigger="null" collapsible width="256">
                <!-- <div class="logo1">
                    <span v-if="!collapsed">民生办理</span>
                </div> -->

                <a-menu theme="dark" mode="inline" :default-selected-keys="['1']" @openChange="onOpenChange" :open-keys="openKeys" @click="menuClick">
                    <!-- 一级菜单 -->
                    <template v-for="(item,index) in meun">
                        <template v-if="item.children">
                            <!--  <a-sub-menu :key="item.id" v-if="item.isShow==1"> -->

                            <a-sub-menu v-if="item.isShow==1" :key="'root_'+index">
                                <span slot="title">
                                    <a-icon :type="item.iconLink==''?'appstore':item.iconLink" />
                                    <span><a-badge :count="item.todo" dot>{{item.title }}</a-badge></span>
                                </span>
                                <!-- 二级菜单 -->
                                <template v-for="subItem in item.children" >
                                    <!--<a-menu-item :key="subItem.id" @click="tocontent(subItem)" v-if="subItem.isShow==1">
        <a-badge :count="subItem.todo" dot>{{ subItem.title }}</a-badge>
    </a-menu-item>-->

                                    <template v-if="subItem.children.length>0 && subItem.isShow === 1">
                                        <a-sub-menu :key="subItem.id" :title="subItem.title">
                                            <span slot="title" class="navtitle">
                                                <a-icon :type="subItem.iconLink==''?'appstore':subItem.iconLink" />
                                                <span><a-badge :count="subItem.todo" dot>{{subItem.title}}1</a-badge></span>
                                            </span>

                                            <!-- 三级菜单 -->
                                            <template v-for="subItem2 in subItem.children">
                                                <template  v-if="subItem2.isShow === 1">
                                                    <a-menu-item :key="subItem2.id" @click="tocontent(subItem2)">
                                                        <a-badge :count="subItem2.todo" dot>{{subItem2.title}}</a-badge>
                                                    </a-menu-item>
                                                </template>
                                            </template>
                                        </a-sub-menu>
                                    </template>
                                    <template v-else>
                                        <a-menu-item :key="subItem.id" @click="tocontent(subItem)" v-if="subItem.isShow==1">
                                            <span><a-badge :count="subItem.todo" dot>{{ subItem.title }}</a-badge></span>
                                        </a-menu-item>
                                    </template>


                                </template>
                            </a-sub-menu>

                            <!-- 处理菜单第一次点击会收缩起来的问题 zjx 2021-02-21 -->
                            <!--<a-sub-menu key="sub1" v-if="item.isShow==1&&index==0" :key="index">
                                <span slot="title">
                                    <a-icon :type="item.iconLink==''?'appstore':item.iconLink" />
                                    <span><a-badge :count="item.todo" dot>{{item.title }}</a-badge></span>
                                </span>
                                <template v-for="subItem in item.children">
                                    <a-menu-item :key="subItem.id" @click="tocontent(subItem)" v-if="subItem.isShow==1">
                                        <a-badge :count="subItem.todo" dot>{{ subItem.title }}</a-badge>
                                    </a-menu-item>

                                </template>
                            </a-sub-menu>-->


                        </template>
                        <template v-else>
                            <a-menu-item :key="item.id" @click="tocontent(item)" v-if="item.isShow==1">
                                <a-icon type="folder" />
                                <span><a-badge :count="item.todo" dot>{{ item.todo }}{{ item.title }}</a-badge></span>
                            </a-menu-item>
                        </template>
                    </template>
                </a-menu>
            </a-layout-sider>
            <a-layout-content :style="{ margin: '16px !important', padding: '16px  !important', background: '#fff', minHeight: '280px' , position: 'relative'}">
                <router-view></router-view>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script>


    import { pathFindRouter } from './router.js'
    var sub = "";
    export default {
        data() {
            return {
                toptitle: '',
                collapsed: false,
                meun: [],
                topButtons: [],
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false
                },
                fields: {
                    oldpassword: "",
                    password: "",
                    dbpassword: ""
                },
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
                openKeys: ['root_0'],
                reqCount: {//用于记录获取提醒数量axios请求发送和返回数量，如果相等则说明全部执行完成
                    sendCount: 0,
                    recCount: 0,
                },
            };
        },
        created: function () {
            var _this = this;
            let path = this.$route.path;
            let ppath = path.replace("/" + path.split('/')[path.split('/').length - 1], "");//找到本模块path
            if (ppath == "") {
                ppath = path;
            }
            sub = ppath;
            var temp = pathFindRouter(ppath);
            let mkmeun = temp.children;//找出本模块菜单
            this.toptitle = temp.title;
            for (var i = 0; i < mkmeun.length; i++) {
                if (mkmeun[i].moduleType == 0) {

                    this.meun.push(mkmeun[i]);
                }
                else if (mkmeun[i].moduleType == 1 || mkmeun[i].moduleType == 2) {
                    this.topButtons.push(mkmeun[i]);
                }
            }
            this.cpTodo(this.meun);
        },
        computed: {
            top() {
                return () => import("." + sub + "/top")
            }
        },
        watch: {
            reqCount: {
                handler(newName, oldName) {
                    if (newName.sendCount == newName.recCount) {
                        //console.log(newName);
                        this.$forceUpdate();
                    }
                },
                deep: true
            },
        }
        ,
        methods: {
            cpTodo(arr) {//找出计算待办事项数量
                arr.forEach(item => {
                    item.todo = 0;
                    this.reqCount.sendCount++;
                    this.$axios({
                        url: '/api/comon/GetRemindByMkid?mkid=' + item.id,
                        method: 'get',
                    }).then(res => {
                        //console.log(res);
                        if (res.status == 200) {
                            if (res.data.code == 0) {
                                //console.log(res.data);
                                if (res.data.data.num > 0) {
                                    //console.log(res.data.data.parentid);
                                    this.addTodo(res.data.data.mkid, res.data.data.num);
                                    this.addTodo(res.data.data.parentid, 10);
                                    //this.updateTodo(this.meun, res.data.data.mkid, res.data.data.num);
                                }
                            }
                        }
                        else {
                            //throw new Error("服务器超时2");
                        }
                        this.reqCount.recCount++;
                    }).catch(error => { // 请求失败处理
                        //this.$message.error(error.message);
                        //this.formAddOptions.submitLoading = false
                    });

                    if (item.children && item.children.length > 0) {
                        this.cpTodo(item.children);
                    }
                });
            },
            addTodo(mkid, num) {//添加菜单红点
                var _this = this;
                let blmeun = function (meun) {
                    for (var i = 0; i < meun.length; i++) {
                        if (meun[i].id == mkid) {
                            //this.$set(meun[i], "todo", "2");
                            //_this.$set(meun[i], 'todo', num)
                            meun[i].todo = num;

                            return false;
                        }
                        if (meun[i].children) {
                            blmeun(meun[i].children);
                        }
                    }
                }
                blmeun(this.meun);

            },
            removeTodo(mkid) {//消除菜单红点
                var _this = this;
                let blmeun = function (meun) {
                    for (var i = 0; i < meun.length; i++) {
                        if (meun[i].id == mkid) {
                            meun[i].todo = 0;
                            return false;
                        }
                        if (meun[i].children) {
                            blmeun(meun[i].children);
                        }
                    }
                }
                blmeun(this.meun);
            },
            sonTodo(parentId) {//找同级菜单红点数
                var reval = 0;
                var pmeun = function (meun) {
                    for (var i = 0; i < meun.length; i++) {
                        //console.log(meun[i]);
                        if (meun[i].parentId == parentId) {//子菜单如果有红点，则把父菜单也加上
                            reval += meun[i].todo;
                        }

                        if (meun[i].children) {
                            pmeun(meun[i].children);
                        }
                    }
                }
                pmeun(this.meun);
                return reval;
            },
            findParentid(mkid) {//根据ID找出父菜单id
                var reval = "";
                var pmeun = function (meun) {
                    for (var i = 0; i < meun.length; i++) {
                        //console.log(meun[i]);
                        if (meun[i].id == mkid) {
                            reval += 1;
                            reval = meun[i].parentId;
                            return reval;
                        }
                        if (meun[i].children) {
                            pmeun(meun[i].children);
                        }
                    }
                }
                pmeun(this.meun);
                return reval;
            },
            tocontent(item) {
                if (item.path.indexOf("http") < 0) {
                    if (item.path == "nopage") {
                        alert("暂未开放");
                        return false;
                    }
                    
                    if (this.$route.path == item.path) return
                    localStorage.setItem("backData", "{\"currentPageIndex\":1}");
                    this.$router.push({ path: item.path });
                }
                else {
                    window.open(item.path);
                }
            },
            out: function () {
                let pthis = this;
                this.$confirm({
                    title: '消息提示',
                    content: '确定要退出吗?',
                    onOk() {
                        pthis.$User.user.loginOut();
                    },
                    onCancel() { },
                });
            },
            formAddSubmit() {
                this.formAdd.setFieldsValue({ "loginid": this.$User.user.userInfo.loginid });
                this.formAdd.validateFields((err, values) => {
                    if (!err) {
                        this.formAddOptions.submitLoading = true
                        this.$axios({
                            url: '/api/Template/modiPassword',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('修改成功，下次登陆请使用新密码');
                                    this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false;
                                    this.formAdd.resetFields();
                                } else {
                                    throw new Error(res.data.msg);
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })
                    }
                })
            },
            handleConfirmPass(rule, value, callback) {
                rule;
                if (this.password && this.password !== value) {
                    callback('两次密码输入不一致！')
                }
                // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
                callback()
            },
            onOpenChange(openKeys) {
                if (openKeys.length >= 2) {
                    if (openKeys[openKeys.length-1].toString().indexOf("root") < 0) {//这个表示不是第一级菜单，不要做手风琴效果,菜单key带前缀root的表示是一级菜单
                        this.openKeys = openKeys;
                    }
                    else {
                        this.openKeys = [openKeys[openKeys.length - 1]];
                    }
                }
                else {
                    if (openKeys.length > 0) {
                        this.openKeys = [openKeys[openKeys.length - 1]];
                    }
                    else {
                        this.openKeys = [];
                    }
                }
            },
            menuClick(obj) {
                this.removeTodo(obj.key);
                var parentid = this.findParentid(obj.key);
                var son = this.sonTodo(parentid);

                if (son <= 0) {
                    this.removeTodo(parentid);
                }
                this.$forceUpdate();

            }
        }
    };
</script>
<style>
    .blue-top {
        background-image: url(../public/img/top_bg.png);
        background-size: cover;
        background-position: center;
    }

    .logo2wrap {
        display: flex;
        align-items: center;
        padding-top: 4px;
        float: left;
    }

    .logo2 {
        width: 50px;
        height: 50px;
        margin-left: 44px;
        vertical-align: top;
    }

        .logo2 + span {
            color: white;
            font-size: 28px;
            letter-spacing: 4px;
            text-shadow: 2px 2px 4px rgba(41, 97, 184, 0.5);
            padding-left: 17px;
            margin-top: -4px;
            font-family: '微软雅黑';
            font-weight: bold;
        }

    #components-layout-demo-custom-trigger .trigger {
        font-size: 18px;
        line-height: 64px;
        cursor: pointer;
        transition: color 0.3s;
    }

    #topmenu {
        float: right;
        background: transparent;
        color: white;
    }

        #topmenu li {
            height: 64px;
            line-height: 64px;
            text-align: center;
            font-size: 16px;
        }

            #topmenu li i {
                font-size: 16px;
            }


    /* .logo1 {
           height: 50px;
           text-align: center;
           line-height: 50px;
       }
           .logo1 span {
               color: #fff;
               font-size: 25px;
           }
    */
    .ant-layout {
        height: 100%;
    }

    .ant-menu-horizontal .ant-menu-item {
        margin-top: 0px;
        padding: 0 8px;
    }

    .ant-menu-item .anticon {
        margin-right: 0px;
    }

    .ant-menu-horizontal > .ant-menu-submenu:hover {
        color: #FFE08A;
    }

    .ant-menu-submenu-title:hover {
        color: #FFE08A;
    }

    #components-layout-demo-custom-trigger .trigger:hover {
        color: #FFE08A !important;
    }
</style>