<template>
    <div style="width:100%; height:100%;" ref="rootBox">
        <div id="MyMap" style="width:100%; height:100%;"></div>

        <div class="div_css_watersturation">
            <div class="div_header_watersturation">
                <span>积水点相关信息</span>
            </div>
            <div class="search-result-list" :style="{ marginTop: '0px' }">
                <a-table :columns="columns"
                        :data-source="tableData"
                        :pagination="pagination"
                        :pageSize="10"
                        :total="50"
                        rowKey="id"
                        :loading="loading"
                        :scroll="scroll"
                        @change="pageCurrentChange"
                        position="bottom"
                        tableLayout="fixed"
                        :customRow="rowClick">
                    <span slot="action" slot-scope="text, record">
                        <a-button type='primary' size="small" @click.stop="btnClick('view',record)">详情</a-button>
                    </span>
                    <a slot="currentData" slot-scope="text">{{m_toFixed(text)}}</a>
                </a-table>
            </div>
        </div>

        <a-modal v-model="yl_visible" :title="yl_details_title" :closable="false" :footer="null" class="dialog_watersturation" width="1000px">
            <div style="text-align:center;" v-if="yl_detailsData.length>0">
                <span style="margin-left: 40px;">积水点：</span><span>{{yl_detailsData[0].name}}</span>
                <span style="margin-left: 40px;">当前深度：</span><span>{{m_toFixed(yl_detailsData[0].currentData)}}</span>
            </div>
            <div style="width:100%;height:400px;margin-top:20px;" ref="jsylqy"></div>
        </a-modal>

        <div class="popContainer" v-if="loading_zz==true">
            <a-spin tip="Loading...">
                <div class="spin-content">
                </div>
            </a-spin>
        </div>

        <!-- 点击地图积水易涝展示数据 -->
        <a-modal 
            v-model="showWptf"  
            :footer="null"
            :closable="false"
            centered
            @cancel="showWptfData = {}">
            <!-- <view v-if="curMarker && curMarker.hasNvr" class="flex align-center">
                视频查看：
                <image
                    src="/static/monitor_light.png"
                    style="width: 40rpx; height: 40rpx;"
                    @click="
                        modalName = null;
                        tabCur = 1;
                    "
                ></image>
                （点击查看）
            </view>  -->
            <p slot="title">
                <a-icon type="flag" :style="{ color: '#1E90FF' }" />
                {{ showWptfData ? showWptfData.address : '暂无数据' }}
            </p>
            <p>时间：{{ showWptfData ? showWptfData.lastTime : '暂无数据' }}</p>
            <p>积水深度（米）：{{ showWptfData ? showWptfData.currentData : '暂无数据' }}</p>
            <p>区域：{{ showWptfData ? showWptfData.name : '暂无数据' }}</p>
        </a-modal>

    </div>
</template>
<script>
    import * as esriLoader from 'esri-loader'
    import * as echarts from 'echarts'
    
    const columns = [
        {
            title: '积水点',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            scopedSlots: { customRender: 'name' }
        },
        {
            title: '当前深度',
            dataIndex: 'currentData',
            width: 90,
            key: 'currentData',
            scopedSlots: { customRender: 'currentData' }
        },
        {
            title: '操作',
            key: 'action',
            width: 80,
            scopedSlots: { customRender: 'action' }
        }
    ]

    esriLoader.loadScript({
        url: 'http://118.31.71.54:8090/library/4.15/init.js',
        dojoConfig: {
            async: true,
            packages: [
                {
                    name: "app",
                    location: "http://118.31.71.54:8044/v0/app"
                }
            ]
        }
    })
    esriLoader.loadCss('http://118.31.71.54:8090/library/4.15/esri/themes/light/main.css')
    esriLoader.loadCss('http://118.31.71.54:8044/v0/GRSHGISLibs.css')

    let loadModulesUrl = [
        'http://118.31.71.54:8091/KSSmartWaterOneMapAPI/v1.5/app/MapInitial.js',
        'http://118.31.71.54:8091/KSSmartWaterOneMapAPI/v1.5/app/KSSmartWater/ksSWInitial.js',
        'http://118.31.71.54:8091/KSSmartWaterOneMapAPI/v1.5/app/test/pntlyrext2.js',
        'http://118.31.71.54:8090/library/4.15/esri/renderers/ClassBreaksRenderer.js',
        'http://118.31.71.54:8090/library/4.15/esri/symbols/PictureMarkerSymbol.js'
    ]

    export default {
        data() {
            return {
                scroll: {
                    y: 0
                },
                tableData: [],
                yl_details_title:'',
                yl_visible: false,
                yl_detailsData: [],
                mi: null,
                model_search: 'ksslj',
                columns,
                loading: true,
                strWhere: 'del=0',
                //xcw,
                loading_zz: false,
                pagination: {
                    total: 0,
                    pageSize: 10, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    size: 'small',
                    showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: 'bottom'
                },
                showWptf: false,
                showWptfData: {}
            }
        },
        created () {
            this.bindList("","")
        },
        mounted () {
            this.findMap()
            window.onresize = this.onResize()
        },
        methods: {
            findMap () {
                esriLoader.loadModules(loadModulesUrl)
                    .then(([MI, ksSWInitial, pntlyrext2, ClassBreaksRenderer, PictureMarkerSymbol]) => {
                        let mi = new MI({
                            zoom: 9,
                            center_lng: 120.9808441,
                            center_lat: 31.34081901,
                            container: "MyMap",
                            maptype: 1,
                            OMKey: "11"
                        })
                        this.mi = mi

                        let xcw = new ksSWInitial({ mi })
                        xcw.initial();  //智慧水利相关功能初始化
                        xcw.setAllBaseSubLayerVisibility(false) //关闭所有水系基础图层
                        xcw.setAllDBSubLayerVisibility(false) //关闭所有业务图层
                        xcw.setBaseWaterSubLayerVisibility('River', true) //打开河道图层，配置为false，则关闭河道图层，第一个参数为指代图层的target参数
                        xcw.setBaseWaterSubLayerVisibility('Lake', true) //打开湖泊图层
                        xcw.setBaseWaterSubLayerVisibility('TownBoundary', true) //打开乡镇界线图层
                        xcw.setBaseWaterSubLayerVisibility('Mask', true) //打开掩膜图层
                        xcw.setBoundary('昆山市')
                        //配置分级符号
                        let sms1 = new PictureMarkerSymbol({
                            url: '/img/marker1.png',
                            width: '30px',
                            height: '30px'
                        })
                        let sms2 = new PictureMarkerSymbol({
                            url: '/img/marker2.png',
                            width: '30px',
                            height: '30px'
                        })
                        let sms3 = new PictureMarkerSymbol({
                            url: '/img/marker3.png',
                            width: '30px',
                            height: '30px'
                        })
                        let sms4 = new PictureMarkerSymbol({
                            url: '/img/marker7.png',
                            width: '30px',
                            height: '30px'
                        })
                        //配置分级渲染器
                        var renderer = new ClassBreaksRenderer({
                            field: 'lv' //此处为分级字段
                        })
                        renderer.addClassBreakInfo({
                            minValue: 1,
                            maxValue: 1,
                            symbol: sms1,
                            label: '级别1'
                        })
                        renderer.addClassBreakInfo({
                            minValue: 2,
                            maxValue: 2,
                            symbol: sms2,
                            label: '级别2'
                        })
                        renderer.addClassBreakInfo({
                            minValue: 3,
                            maxValue: 3,
                            symbol: sms3,
                            label: '级别3'
                        })
                        renderer.addClassBreakInfo({
                            minValue: 4,
                            maxValue: 4,
                            symbol: sms4,
                            label: '级别4'
                        })
                        this.$axios({
                            url: 'http://58.210.32.10:8081/api-ponding/getPoints',
                            method: 'post',
                        }).then(res => {
                            let { data } = res.data
                            data.forEach(d => {
                                let { currentData } = d
                                if (currentData >= 20) {
                                    d.lv = 4
                                } else if (currentData >= 10) {
                                    d.lv = 3
                                } else if (currentData > 0) {
                                    d.lv = 2
                                } else {
                                    d.lv = 1
                                }
                            })

                            let lyr = new pntlyrext2({
                                mi, //传递地图初始化对象
                                data, //数据 该项与dataUrl二选一.此处直接传递数组
                                renderer,  //地图渲染器 非专业用户不需要使用该属性
                                x_Field: 'lng', //用于展点的X字段
                                y_Field: 'lat', //用于展点的y字段
                                enableCluster: true, //是否开启聚合功能
                            })

                            lyr.initialClickOrMove({
                                view_click_handler: (g) => {
                                    this.showWptfData = g.attributes
                                    this.showWptf = true
                                }
                            })

                        })
                    })
            },
            bindList(value1,value2) {
                var _that = this
                _that.loading = true
                this.$axios({
                    url: 'http://58.210.32.10:8081/api-ponding/getPoints',
                    method: 'post',
                })
                    .then(function (response) {
                        console.log(response)
                        _that.loading = false
                        if (response.status === 200) {
                            _that.tableData = response.data.data
                            _that.pagination.total = response.data.data.length
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            btnClick(text, item) {
                this.yl_data_details(item);
            },
            yl_data_details(item) {
                var _this = this;
                _this.yl_visible = true;
                _this.yl_detailsData = [];
                _this.yl_detailsData.push(item);
                _this.loading_zz = true;//开启遮罩
                //详情中echarts数据
                setTimeout(() => {
                    var myChart1 = echarts.init(_this.$refs.jsylqy);
                    myChart1.showLoading();
                    _this.$axios({
                        url: 'http://58.210.32.10:8081/api-ponding/getHisData',
                        method: 'post',
                        data: { startTime: "", endTime: "", pointId: item.id }
                    })
                        .then(function (response) {
                            console.log(response)
                            if (response.status === 200) {
                                var data = response.data.data;
                                var xArr = [], yArr = [];
                                if (data.length > 0) {
                                    var i_number = 0;
                                    if (data.length >= 12) i_number = data.length - 12;
                                    for (var i = i_number; i < data.length; i++) {
                                        xArr.push(_this.$jsc.formatDate_isTime(data[i].monitorTime));
                                        yArr.push(_this.m_toFixed_number(data[i].data));
                                    }
                                    _this.yl_bind_eacharts(xArr, yArr, _this.$refs.jsylqy);
                                } else {
                                    _this.$message.info("暂无历史数据");
                                    _this.yl_bind_eacharts([],[], _this.$refs.jsylqy);
                                    _this.loading_zz = false;//关闭遮罩
                                    myChart1.hideLoading();
                                }
                            }
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                })
            },
            yl_bind_eacharts(xArr, yArr,dom) {
                console.log(yArr)
                var _this = this;
                var option = {
                    color: ['#09bcb7', '#ff999a'],
                    grid: {
                        top: '20px',
                        left: '20px',
                        right: '20px',
                        bottom: '20px',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            label: {
                                backgroundColor: '#eee'
                            }
                        },
                    },
                    legend: {
                        show: true,
                        icon: 'circle',
                        right: 140,
                        itemWidth: 8,
                        itemHeight: 8,
                        textStyle: {
                            color: '#555'
                        },
                        data: ['当前深度']
                    },
                    calculable: true,
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#eee'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#555'
                            },
                            interval: 0,
                            rotate:40
                        },
                        data: xArr
                    },
                    yAxis: {
                        type: 'value',
                        axistick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#eee'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#555'
                            },
                            formatter: function (value) {
                                var texts = [];
                                value = value + " m";
                                texts.push(value);
                                return texts;
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                type: 'dashed',
                                color: ['#999']
                            }
                        }
                    },
                    series: [{
                        type: 'line',
                        name: '当前深度',
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1, [{
                                    offset: 0,
                                    color: 'rgba(90, 163, 216, 1)'
                                },
                                {
                                    offset: 0.8,
                                    color: 'rgba(255, 255, 255, 1)'
                                }
                            ], false
                            ),
                            shadowColor: 'rgba(0,0,0,0.01)',
                            shadowBlur: 10

                        },
                        smooth: true,
                        lineStyle: {
                            color: 'dodgerblue'
                        },
                        data: yArr
                    }]
                };
                var myChart1 = echarts.init(dom);
                myChart1.setOption(option);
                _this.loading_zz = false;//关闭遮罩
                myChart1.hideLoading();
            },
            rowClick(record, index) {
                return {
                    on: {
                        click: (e) => {
                            this.mi.zoomto(record.lng, record.lat, 15);
                        }
                    }
                }
            },
            pageCurrentChange(pagination) {
                console.log(pagination);
                //return;
                this.pagination.current = pagination.current
                this.bindList("","")
            },
            formatDate_hm(value) {
                let date = new Date(value);
                if (value == "0001-01-01 00:00:00" || value == null || value == "1900-01-01 00:00:00" || value == "") {
                    return "";
                } else {
                    let h = date.getHours()+1 < 10 ? "0" + date.getHours() : date.getHours();
                    let mi = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
                    return h + ":" + mi;
                }
            },
            m_toFixed(value) {
                if (value != null && value != "Null" && value != undefined) {
                    return (parseInt(value) / 1000).toFixed(2) + " m";
                } else {
                    return "";
                }
            },
            m_toFixed_number(value) {
                if (value != null && value != "Null" && value != undefined) {
                    return parseFloat((parseInt(value) / 1000).toFixed(2));
                } else {
                    return "";
                }
            },
            onResize () {
                this.scroll.y = this.$refs.rootBox.offsetHeight - 185
                return this.onResize
            }
        }
    }
</script>
<style scoped>
    .ant-layout-content {
        padding: 0px !important;
        margin: 0px !important;
    }

    .div_css_watersturation {
        position: absolute;
        top: 15px;
        left: 10px;
        width: 350px;
        background-color: #fff;
        border-radius: 10px;
    }

    .div_header_watersturation {
        height: 50px !important;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        background-color: dodgerblue !important;
    }

    .search-result-list >>> .ant-table-pagination.ant-pagination {
        margin-right: 16px;
    }

        .div_header_watersturation span {
            color: #fff !important;
            font-size: 18px !important;
            line-height: 50px !important;
            margin-left: 10px !important;
        }

    .table_css_watersturation {
        width: 100%;
        height: 700px;
        text-align: center;
        font-size: 16px;
    }

        .table_css_watersturation tr:nth-child(1) {
            background-color: #D2E9FF;
            color: dodgerblue;
        }

        .table_css_watersturation tr:not(:nth-child(1)) td:nth-child(1) {
            font-weight: bold;
        }

    .font_blue_watersturation {
        color: dodgerblue;
    }

    /*详情页面begin*/
    .div_details_watersturation {
        position: absolute;
        top: 300px;
        left: 700px;
        width: 400px;
        height: 300px;
        background-color: #fff;
    }

    .ant-modal-content {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .ant-modal-header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: dodgerblue;
    }

    .ant-modal-title {
        color: #fff;
    }

    .dialog_watersturation div span {
        font-size: 18px;
    }

        .dialog_watersturation div span:nth-of-type(2n+1) {
            color: dodgerblue;
        }
    /*end*/
    /*.ant-select {
        color: #fff !important;
        font-size: 18px !important;
    }
    .ant-select-arrow {
        color: #fff !important;
        font-size: 18px !important;
    }
    .ant-select-selection {
        background-color: none !important;
        border: none !important;
    }*/
    .ant-table-pagination {
        margin-right: 20px !important;
    }
    /*遮罩*/
    .popContainer {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        background: rgba(0,0,0,0.3);
    }
    .spin-content {
        border: 1px solid #91d5ff;
        background-color: #e6f7ff;
        padding: 30px;
    }
    /*end*/
</style>
