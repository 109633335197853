<template>
    <div>

        <a-descriptions :column="2" style="marginBottom: 25px;">
            <a-divider slot="title" orientation="left">
                <span style="color:#EC2FA2;">巡查信息</span>
            </a-divider>
            <a-descriptions-item
                v-for="item in info" 
                :key="item.name"
                :label="item.name">
                {{ item.value }}
            </a-descriptions-item>
        </a-descriptions>

        <a-descriptions style="marginBottom: 15px;" :column="2" bordered>
            <span slot="title" style="fontSize: 16px;">{{ question.title }}</span>
            <a-descriptions-item
                v-for="item in question.children" 
                :key="item.name"
                :span="item.span"
                :label="item.name">
                <template v-if="Array.isArray(item.value)">
                    <a 
                    class="current-img-link"
                    target="_blank" 
                    v-for="subItem in item.value" 
                    :key="subItem.url" 
                    :href="subItem.url">
                        <img :src="subItem.url" :alt="subItem.name">
                    </a>
                </template>
                <template v-else>
                    {{ item.value }}
                </template>
            </a-descriptions-item>
        </a-descriptions>

    </div>
</template>

<script>
    import SQLCompiler from '@/assets/js/SQLCompiler.js'

    const infoField = {
        xcgc: '巡查工程' ,
        xccj: '巡查层级',
        gclx: '工程类型',
        xcr: '巡查人员',
        xcrDepart: '巡查单位',
        xcsj_view: '巡查时间' 
    }

    const quField = {
        dictName: { name: '标准要求', span: 2 },
        dictValue1: { name: '扣分标准', span: 2 },
        dictValue2: { name: '标准分值', span: 2 },
        info: { name: '问题描述', span: 2 },
        kf:   { name: '扣分', span: 2 },
        files_bh: { name: '问题照片', span: 2 }

    }

    export default {
        props: ['bh'],
        data() {
            return {
                info: [],
                question: []
            }
        },
        methods: {
            async getData () {
                this.$axios({
                url: '/api/GetList/getListByTable',
                method: 'get',
                params: {
                    tableName: 'v_slgcxczg_rcxcItem',
                    page: 1,
                    limit: 10000,
                    strWhere: await SQLCompiler.encrypt(` bh='${this.bh}' `),
                    filesFields: 'bh'
                },
            })
                .then(async res => {
                    let { code, data } = res.data
                    if (code !== 0) return
                    let current = data[0]

                    // 信息
                    let { xcsj,  gclx} = current
                        current.xcsj_view =  xcsj.slice(0, 10)
                        this.info = Object.keys(infoField).map(key => {
                            return {
                                name: infoField[key],
                                value: current[key]
                            }
                    })

                    // 问题
                    let obj = {}
                    obj.title = current.title
                    console.log(current);
                    let dicts = await this.getDict(current.dictid1)
                    Object.assign(current, dicts.find(item => item.id === current.dictid2))
                    obj.children = Object.keys(quField).map(key => {
                        return {
                            name: quField[key].name,
                            span: quField[key].span,
                            value: current[key]
                        }
                    })
                    this.question = obj
                })
                .catch(err => err)
            },
            async getDict (id) {
                return this.$axios({
                    url: '/api/comon/GetDict',
                    method: 'get',
                    params: { id }
                }).then(async res => {
                    let { code, data } = res.data
                    if (code !== 0) return
                    return data
                }).catch(err => err)
            },
        },
        created () {
            this.$nextTick(() => {
                this.getData()
            })
        },
    }
</script>

<style lang="less" scoped>
    .current-img-link {
        img {
            vertical-align: top;
            width: 100px;
            height: 100px;
        }

        &:not(:last-child) {
            margin-right: 5px;
        }
    }

    /deep/ .ant-descriptions-item-label {
        width: 120px;
    }
</style>