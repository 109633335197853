<template>
    <a-descriptions title="工单详细" :column="2">
        <a-descriptions-item label="工单标题">
            {{workInfo.title}}
        </a-descriptions-item>
        <a-descriptions-item label="主办单位">
            {{workInfo.zbdw}}
        </a-descriptions-item>
        <a-descriptions-item label="到期时间">
            {{workInfo.clsx}}
        </a-descriptions-item>
        <a-descriptions-item label="当前步骤">
            {{workInfo.currentStep}}
        </a-descriptions-item>
        <a-descriptions-item label="工单状态" :span="2">
            <span v-if="workInfo.state==0">派单中</span>
            <span v-else-if="workInfo.state==1">办理中</span>
            <span v-else-if="workInfo.state==10">已办结</span>
        </a-descriptions-item>
        <a-descriptions-item label="工单内容" :span="2">
            {{workInfo.info}}
        </a-descriptions-item>
    </a-descriptions>
</template>
<script>

    export default {
        data() {
            return {
                TableListSearchModel: {
                    pageIndex: 1,
                    pageSize: 20,
                    fields: "*",
                    where: {
                    }
                },
                workInfo: {},
            }
        },
        props: {
            'dataOptions': Object,
        },
        mounted() {

        },
        created() {
            //console.log(this.dataOptions);
            this.initData();
        },
        methods: {
            initData() {
                this.$axios({
                    url: "/api/work/getList_v_commonWork",
                    method: "post",
                    data: this.TableListSearchModel
                }).then((res) => {
                    this.workInfo = res.data.data.list[0];
                }).catch((res) => {

                });
            }
        }
    }

</script>
<style scoped>
</style>