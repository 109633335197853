<template>
    <div>
        <p>{{msg}}</p>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                msg: "我是internetOfThings/home组件"
            }
        },
        created: function () {
            console.log(this.$router);
        }
    }
</script>
<style type="text/css">

</style>