<template>
    <div>

        <a-radio-group style="marginBottom: 25px;" v-model="tableType" @change="changeTableType">
            <a-radio-button value="WSC">污水厂</a-radio-button>
            <a-radio-button value="BZ">泵站</a-radio-button>
            <a-radio-button value="GWJC">管网液位监测点</a-radio-button>
            <a-radio-button value="NCWS">农污监测点</a-radio-button>
        </a-radio-group>
        
        <!-- 列表 -->
        <a-table
            :columns="columns" 
            :data-source="dataSource" 
            rowKey="id"
            :loading="loading"
            :scroll="scroll"
            :pagination="pagination"
            @change="changePage">

            <template slot="name" slot-scope="name, record" >
                <a href="javascript:;" @click="seeRecord(record)">{{ name }}</a>
            </template>

            <template slot="action" slot-scope="record">
                <a-button icon="file-search" type="primary" @click="seeRecord(record)" />
            </template>

        </a-table>

        <!-- 详情 -->
        <a-drawer 
            width="480" 
            placement="right" 
            :closable="false" 
            :visible="showDrawer"
            @close="showDrawer = false">

            <a-card size="small" style="marginBottom: 15px;" v-for="item in record" :key="item.id">
                <div slot="title" style="textAlign:center;">{{ item.name }}</div>
                <p>时间：{{  item.monitorTime }}</p>
                <p>数据：{{ item.data }}</p>
                <p>单位：{{ item.unit }}</p>
            </a-card>

        </a-drawer>

    </div>
</template>

<script>
    // 表格行头字段
    const columns = [
        {
            title: '点位名称',
            dataIndex: 'name',
            width: 160,
            ellipsis: true,
            scopedSlots: { customRender: 'name' },
        },
        {
            title: '地址',
            dataIndex: 'address',
            width: 160,
            ellipsis: true
        },
        {
            title: '区镇名称',
            dataIndex: 'regionName',
            width: 100,
            align: 'center'
        },
        {
            title: '区镇编码',
            dataIndex: 'regionCode',
            width: 100,
            align: 'center'
        },
        {
            title: '横坐标',
            dataIndex: 'coorX',
            width: 135
        },
        {
            title: '纵坐标',
            dataIndex: 'coorY',
            width: 135
        },
        {
            title: '经度',
            dataIndex: 'lng',
            width: 120
        },
        {
            title: '纬度',
            dataIndex: 'lat',
            width: 120
        },
        {
            title: '详情',
            key: 'detail',
            fixed: 'right',
            width: 80,
            scopedSlots: { customRender: 'action' },
        }
    ]

    export default {
        data () {
            return {
                tableType: 'WSC',
                // table
                dataSource: [],
                columns,
                scroll: { 
                    y: 0,
                    scrollToFirstRowOnChange: true
                },
                pagination: {
                    current: 1,
                    showTotal: total => `总共${total}条`
                },
                loading: true,
                // drawer
                record: {},
                showDrawer: false
            }
        },

        methods: {
            calcScrollY () {
                this.scroll.y = document.body.offsetHeight - 300
            },
            changeTableType () {
                this.pagination.current = 1
                this.getTableData()
            },
            changePage (page) {
                const { current } = page
                this.pagination.current = current
                this.getTableData()
            },
            async seeRecord (record) {
                const { id } = record
                const indexs = await this.getIndexs(id)
                const indexsInfo = await this.getIndexsInfo(id, this._.map(indexs, 'id'))

                this.record = this._.map(indexs, item => this._.assign(item, this._.find(indexsInfo, { indexId: item.id })))
                this.showDrawer = true
            },
            getTableData () {
                this.loading = true
                this.$axios({
                    baseURL: 'http://58.210.32.10:8081',
                    url: '/api-sewage/getPoints',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    params: {  type: this.tableType }
                })
                    .then(res => {
                        if (res.status !== 200 || !res.data.success) return
                        this.dataSource = res.data.data
                    })
                    .finally(() => this.loading = false)
            },

            getIndexs (pointId) {
                return this.$axios({
                    baseURL: 'http://58.210.32.10:8081',
                    url: '/api-sewage/getIndexes',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    params: {  pointId }
                })
                    .then(res => {
                        if (res.status !== 200 || !res.data.success) return
                        return res.data.data
                    })
            },

            getIndexsInfo (pointId, indexIds) {
                return this.$axios({
                    baseURL: 'http://58.210.32.10:8081',
                    url: '/api-sewage/getLastData',
                    method: 'post',
                    data: { pointId, indexIds }
                })
                    .then(res => {
                        if (res.status !== 200 || !res.data.success) return
                        return res.data.data
                    })
            }
        },

        created () {
            this.getTableData()
        },
        mounted () {
            this.calcScrollY()
            window.addEventListener('resize', this._.debounce(this.calcScrollY, 150))
            this.$once('hook:beforeDestroy', () => window.removeEventListener('resize', this.calcScrollY))
        },
    }
</script>
