<template>
  <div id="components">
    <a-button type="primary" @click="dc" style="margin-left:0px;">导出</a-button>
    <h1>{{yyear}}年{{mmonth}}月站闸百分考核表排名</h1>

    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="">
            <a-input v-decorator="['zzname']" placeholder="站闸名称" :maxLength="40" />
          </a-form-item>
        </a-col>
        <a-col :span="6" style="padding-top:5px;">
          <a-button type="primary" :style="{ width: '48px' }" html-type="submit" icon="search"></a-button>
          <a-button type="" :style="{ width: '48px', marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
        </a-col>
      </a-row>
    </a-form>

    <div class="search-result-list" :style="{ marginTop: '0px' }">
      <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="10" :total="50" rowKey="id" :loading="loading" :scroll="scroll" @change="pageCurrentChange" position="bottom" tableLayout="fixed">

        <span slot="titleTxt" slot-scope="text, record">
          {{record.yyear}}年{{record.mmonth}}月
        </span>
        <span slot="zzname_slot" slot-scope="text, record">
          <a @click="()=>$router.push('kh4List?yyear='+record.yyear+'&mmonth='+record.mmonth+'&zzbh='+record.zzbh+'&subbh='+record.subbh+'&zzname='+record.zzname)">{{text}}</a>
        </span>
      </a-table>
    </div>

  </div>
</template>
<script>
const loading = true
const strWhere = 'del=0'
const action = 'add'
export default {
  data() {
    return {
      mmonth: this.$route.query.mmonth,
      yyear: this.$route.query.yyear,
      showexport: false,
      scroll: {
        x: 1000
        //y:900
      },
      check_ywba: true,
      opration: '',
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [],
      columns: [
        {
          title: '排名',
          dataIndex: 'rownum',
          width: 70,
          key: 'rownum'
        },
        {
          title: '考核时间',
          dataIndex: 'yyear',
          width: 200,
          key: 'yyear',
          scopedSlots: { customRender: 'titleTxt' }
        },
        {
          title: '考核站闸',
          dataIndex: 'zzname',
          width: 200,
          key: 'zzname',
          scopedSlots: { customRender: 'zzname_slot' }
        },
        {
          title: '总分',
          dataIndex: 'zscore',
          width: 150,
          key: 'zscore'
        },
        {
          title: '扣分次数',
          dataIndex: 'kfcount',
          width: 150,
          key: 'kfcount'
        },
        {
          title: '总扣分',
          dataIndex: 'zkf',
          width: 150,
          key: 'zkf'
        },
        {
          title: '总得分',
          dataIndex: 'zdf',
          width: 150,
          key: 'zdf'
        }

        //{
        //    title: "操作",
        //    key: "action",
        //    fixed: "right",
        //    width: 120,
        //    scopedSlots: { customRender: "action" },
        //},
      ],
      loading,
      action,
      strWhere,
      sort: 'zdf desc',
      expand: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom'
      }
    }
  },
  mounted() {
    //将上面的按钮和表格内的按钮分出来
    //console.log(this.$route.meta.buttons);
    console.log(this.getCallData())
    this.formSearch.setFieldsValue(this.getCallData().searchData)
    this.pagination.current = this.getCallData().currentPageIndex

    if (this.$route.meta.buttons) {
      for (let i = 0; i < this.$route.meta.buttons.length; i++) {
        if (this.$route.meta.buttons[i].tag == 'inline') {
          this.inlineButtons.push(this.$route.meta.buttons[i])
        } else {
          this.buttons.push(this.$route.meta.buttons[i])
        }
      }
    }

    this.init()
    this.actions()
    window.onresize = () => {
      this.init()
    }
    this.bindList()
  },
  components: {},
  created() {},
  methods: {
    dc() {
      var _this = this
      this.$confirm({
        title: '操作确认',
        content: '是否导出?',
        okText: '是',
        cancelText: '否',
        onOk() {
          var key = 'dc'
          _this.$message.loading({ content: '导出中，请等待...', key })
          _this
            .$axios({
              url: _this.$axios.defaults.baseURL + '/api/dfcdc/dckhjl',
              method: 'get',
              params: {
                zzname: '',
                yyear: _this.yyear,
                mmonth: _this.mmonth,
                fileName: '考核表'
              }
            })
            .then(function (response) {
              if (response.status === 200) {
                let link = document.createElement('a')
                document.body.appendChild(link)
                link.href = response.data.data
                link.download = '考核表'
                link.style.display = 'none'
                link.click()
                _this.$message.success({
                  content: '导出成功!',
                  key,
                  duration: 2
                })
              }
            })
            .catch(function (error) {
              _this.$message.error('导出失败')
            })
        }
      })
    },
    setCallData: function () {
      localStorage.setItem(
        'backData',
        '{"currentPageIndex":' +
          this.pagination.current +
          ',"searchData":' +
          JSON.stringify(this.formSearch.getFieldsValue()) +
          '}'
      )
    },
    getCallData: function () {
      console.log(localStorage.getItem('backData'))
      var reval
      try {
        reval = JSON.parse(localStorage.getItem('backData'))
      } catch (ex) {
        console.log(ex)
        reval = { currentPageIndex: 1 }
      }
      return reval
    },
    bindList: async function () {
      this.formSearch.validateFields((error, values) => {
        error = 1
        // console.log(values)
        this.strWhere = '1=1 '
        for (var obj in values) {
          if (values[obj]) {
            if (obj == 'townId') {
              this.strWhere += ' and ' + obj + " = '" + values[obj] + "'"
            } else {
              this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
            }
          }
        }
        this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
        console.log(this.strWhere)
      })
      this.loading = true

      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere)
      this.strWhere = this.strWhere.data

      this.sort = 'zdf desc'
      this.sort = (await this.$jsc.DesEncrypt(this, this.sort)).data

      await this.InitGrid()
    },
    InitGrid: function () {
      var _that = this
      _that.loading = true

      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName:
            'f_zzgl_xcgl_khResult_zz(' + this.yyear + ',' + this.mmonth + ')',
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          filesFields: '',
          sort: this.sort,
          strWhere: _that.strWhere
        }
      })
        .then(function (response) {
          console.log(response)
          _that.loading = false
          if (response.status === 200) {
            _that.tableData = response.data.data
            _that.pagination.total = response.data.count
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },

    formSearchSubmit(e) {
      e.preventDefault()
      this.pagination.current = 1
      this.bindList()
      this.setCallData()
    },
    formSearchReset() {
      this.formSearch.resetFields()
      this.strWhere = 'del=0'
      this.bindList()
      this.setCallData()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    pageCurrentChange(pagination) {
      console.log(pagination)
      //return;
      this.pagination.current = pagination.current
      this.bindList()
      this.setCallData()
    },
    del(record) {
      this.$axios({
        url: '/api/Comon/deltag',
        method: 'post',
        data: {
          tableName: 'zzgl_xckh_Option',
          field: 'id',
          value: record.id,
          source: 'web',
          loginid: ''
        }
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('删除成功')
              this.bindList()
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },

    init() {
      var that = this
      size()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector('#components>form')
          //console.log(getComputedStyle(el).height.split("px")[0] - 280);
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('px')[0] - 330
          }
        })
      }
    },
    actions() {
      if (this.inlineButtons.length == 0) {
        //this.columns.pop();
      }
    }
  }
}
</script>
<style scoped>
h1 {
  font-size: 24px;
  text-align: center;
}
</style>