<template>
    <div id="components-form-demo-advanced-search">
        <a-modal v-model="video.visible" title="视频播放" width="800px" :title="video.title" :footer="null">
            <video autoplay="autoplay" :src="video.src" controls="controls" style="width:100%">
                您的浏览器不支持 video 标签。
            </video>
        </a-modal>
        <work ref="work" :dataOptions="work_Options"></work>
        <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
            <a-row :gutter="24">
                <a-col :span="8">
                    <!--<a-form-item label="">
                        <a-input placeholder="抓拍点" v-decorator="['devName']" />
                    </a-form-item>-->
                    <a-form-item label="">
                        <a-select placeholder="抓拍点" v-decorator="['devName']" show-search :filter-option="filterOption"
                            style="width:100%;">
                            <a-select-option value="爱渡码头">爱渡码头</a-select-option>
                            <a-select-option value="假日半岛">假日半岛</a-select-option>
                            <a-select-option value="锦溪洋泾港桥段">锦溪洋泾港桥段</a-select-option>
                            <a-select-option value="吴淞江大桥点">吴淞江大桥点</a-select-option>
                            <a-select-option value="马庄水务站">马庄水务站</a-select-option>
                            <a-select-option value="石牌南沙巷">石牌南沙巷</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="">
                        <a-range-picker :placeholder="['发生开始时间', '发生结束时间']" v-decorator="['happenTime_qj']"
                            style="width:100%" />
                    </a-form-item>
                </a-col>
                <!--<a-col :span="6">
                    <a-form-item label="">
                        <a-select placeholder="是否派单" v-decorator="['ifpd']">
                            <a-select-option value="1">是</a-select-option>
                            <a-select-option value="0">否</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>-->
                <a-col :span="8">
                    <a-form-item label="">
                        <a-select placeholder="量级" v-decorator="['amount']">
                            <a-select-option value="微量">微量</a-select-option>
                            <a-select-option value="少量">少量</a-select-option>
                            <a-select-option value="大量">大量</a-select-option>
                            <a-select-option value="巨量">巨量</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label="">
                    </a-form-item>
                </a-col>

            </a-row>
            <a-row :gutter="[24, 12]">
                <a-col :span="6" :style="{ textAlign: 'left' }">
                    <a-button type='primary' @click="() => $router.push('grid')" style='margin-right: 5px'>网格模式
                    </a-button>

                    <!-- primary/waring/danger/green -->
                </a-col>
                <a-col :span="18" :style="{ textAlign: 'right' }">
                    <a-button type="primary" :style="{ width: '48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width: '48px', marginLeft: '8px' }" @click="formSearchReset"
                        icon="reload"></a-button>
                    <a-button type="danger" style="vertical-align: top; margin-left: 8px;"
                        :disabled="!selectedRowKeys.length" @click="onClickDel">
                        删除
                    </a-button>
                    <!--<a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
    </a>-->
                </a-col>
            </a-row>
        </a-form>

        <div class="search-result-list" :style="{ marginTop: '0px' }">
            <a-table :columns="columns" :data-source="tableData" :pagination="pagination" rowKey="id" :loading="loading" :scroll="scroll"
                @change="pageCurrentChange" tableLayout="fixed"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">

                <span slot="stateTxt" slot-scope="text, record">
                    <a-tag color="pink" v-if="text == 0">派单中</a-tag>
                    <a-tag color="purple" v-if="text == 1">办理中</a-tag>
                    <a-tag color="orange" v-if="text == 10">已办结</a-tag>
                </span>
                <span slot="titleTxt" slot-scope="text, record">
                    <a @click="bjxxView(record)">{{ text }}</a>
                </span>
                <span slot="snapshootFormat" slot-scope="text, record">
                    <a :href="$axios.defaults.fileURL + text" target="_blank"><img :src="$axios.defaults.fileURL + text"
                            style="width:140px; height:85px;"></a>
                </span>
                <span slot="clsxTxt" slot-scope="text, record">
                    {{ moment(text).format("L") }}
                </span>
                <span slot="action" slot-scope="text, record">
                    <a-button v-if="record.state != 10" type='danger' size="small" @click="btnClick('删除', record)"
                        style='margin-right: 5px'>
                        <a-icon type="delete" />
                    </a-button>
                </span>
                <span slot="pdcsFormat" slot-scope="text, record">
                    <a-tag color="blue" v-if="text >= 1">是</a-tag>
                </span>
                <span slot="pdstateFormat" slot-scope="text, record">
                    <a-tag color="pink" v-if="text == 0">派单中</a-tag>
                    <a-tag color="purple" v-if="text == 1">办理中</a-tag>
                    <a-tag color="orange" v-if="text == 10">已办结</a-tag>
                </span>
                <span slot="bjztFormat" slot-scope="text, record">
                    <a-tag color="red" v-if="text == 1">报警中</a-tag>
                    <a-tag color="green" v-if="text == 2">解除报警</a-tag>
                </span>
            </a-table>
        </div>

    </div>
</template>
<script>
import moment from 'moment';
import work from './work'
export default {
    data() {

        return {
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            monthFormat: 'YYYY/MM',
            showexport: false,
            scroll: {
                x: '100%',
                y: 'max-content',
            },
            loading: false,
            check_ywba: true,
            opration: "",
            buttons: [],
            inlineButtons: [],
            tableData: [],
            columns: [
                {
                    title: "序号",
                    dataIndex: "rnn",
                    width: 70,
                    key: "rnn",
                },
                {
                    title: "图片",
                    dataIndex: "snapshoot",
                    width: 170,
                    key: "snapshoot",
                    scopedSlots: { customRender: "snapshootFormat" },
                },
                {
                    title: "抓拍点",
                    dataIndex: "devName",
                    width: 140,
                    key: "devName",
                    scopedSlots: { customRender: "titleTxt" },
                },

                {
                    title: "发生时间",
                    dataIndex: "happenTime",
                    width: 180,
                    key: "happenTime",
                },


                //{
                //    title: "报警类型",
                //    width: 120,
                //    customRender: function () { return "水葫芦抓拍"; }
                //},
                {
                    title: "面积(平方米)",
                    width: 120,
                    dataIndex: "mj",
                    key: "mj",
                },
                {
                    title: "重量(斤)",
                    width: 120,
                    customRender: function (item) {
                        //console.log(item);
                        return (item.mj * 20 * 5).toFixed(2);
                    }
                },
                {
                    title: "量级",
                    dataIndex: "amount",
                    width: 120,
                    key: "amount",
                },
                {
                    title: '操作',
                    key: 'action',
                    width: '100',
                    fixed: 'right',
                    scopedSlots: { customRender: 'action' }
                }
            ],
            selectedRowKeys: [],
            TableListSearchModel: {
                pageIndex: 1,
                pageSize: 10,
                fields: "*",
                where: {
                }
            },
            formAddOptions: {
                visible: false,
                submitLoading: false,
                submitVisible: true,
                viewMode: false,
                spinning: false,
                title: "新增",
            },
            queryParam: {
                kind: this.$route.query.kind,
                amount: this.$route.query.amount
            },
            work_Options: {
                bh: "",
                id: "",
                module: "河道漂浮物抓拍",
                showFile: false,
            },
            video: {
                src: "",
                visible: false,
                title: "视频播放",
            },
            expand: false,
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
            formSearch: this.$form.createForm(this, { name: "advanced_search" }),
            pagination: {
                total: 0,
                pageSize: 10, // 每页中显示10条数据
                current: 1,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"], // 每页中显示的数据
                showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
                position: "bottom",
                showQuickJumper: true,
            },
            backData: {
                currentPageIndex: 1,
                searchData: {},
            },
        };
    },
    mounted() {
        var obj = JSON.parse(localStorage.getItem("backData"));
        this.formSearch.setFieldsValue(obj.searchData);
        this.pagination.current = obj.currentPageIndex;
        this.formSearch.setFieldsValue({ kind: this.queryParam.kind });
        this.formSearch.setFieldsValue({ "amount": this.queryParam.amount });
        this.bindList();
    },
    watch: {
        '$route.query.amount'(newVal, oldVal) {
            if (newVal != "") {
                this.formSearch.setFieldsValue({ "amount": newVal });
            }
            else {
                this.formSearch.resetFields();
            }
            this.pagination.current = 1;
            this.TableListSearchModel.pageIndex = 1;
            localStorage.setItem("backData", "{\"currentPageIndex\":1}");
            this.bindList();
        }
    },
    components: {
        work,
    },
    beforeUpdated() {

    },
    created() {
        //this.TableListSearchModel.where.kind = this.$route.query.kind;
        //将上面的按钮和表格内的按钮分出来
        //this.queryParam.amount;
        //setTimeout(() => {
        //    this.formSearch.setFieldsValue({ "amount": this.queryParam.amount });
        //}, 0);
        for (let i = 0; i < this.$route.meta.buttons.length; i++) {
            if (this.$route.meta.buttons[i].tag == "inline") {
                this.inlineButtons.push(this.$route.meta.buttons[i]);
            } else {
                this.buttons.push(this.$route.meta.buttons[i]);
            }
        }
        this.init();
        this.actions();

        window.onresize = () => {
            this.init();
        };

    },
    methods: {
        setCallData: function () {
            this.backData.currentPageIndex = this.pagination.current;
            this.backData.searchData = this.formSearch.getFieldsValue();
            localStorage.setItem("backData", JSON.stringify(this.backData));
        },
        play(item) {
            this.video.visible = true;
            this.video.src = "http://ksssw.kejin.net.cn:8004" + item.video;
        },
        bjxxView: function (record) {
            this.$router.push("bjxxView?infobh=" + record.bh);
        },
        moment,
        bindList: function () {
            this.formSearch.validateFields((error, values) => {
                error = 1;
                this.TableListSearchModel.where = {};
                this.TableListSearchModel.where.createUserLoginid = this.$User.user.userInfo.loginid;
                this.TableListSearchModel.where.kind = "02";

                for (var obj in values) {
                    if (values[obj]) {
                        if (obj == "clsx") {
                        } else {
                            this.TableListSearchModel.where[obj] = values[obj];
                        }
                    }
                }
            });
            this.loading = true;
            this.InitGrid();
        },
        InitGrid: function () {
            this.loading = true;
            this.$axios({
                url: "/api/hdsp/getList_hdsp_bjxx",
                method: "post",
                data: this.TableListSearchModel,

            }).then((res) => {
                console.log(res);
                this.loading = false;
                if (res.status === 200) {
                    this.tableData = res.data.data.list;
                    this.pagination.total = res.data.data.total;
                }
            }).catch(function (error) {
                console.log(error);
            });
        },
        btnClick(text, item) {
            let _this = this;
            switch (text) {
                case "查看":
                    this.views(item);
                    break;
                case "编辑":
                    this.work_Options.bh = item.bh;
                    this.$refs.work.open();
                    break;
                case "新建工单":
                    this.work_Options.bh = "";
                    this.work_Options.title = item.devName + "的" + item.happenTime + "漂浮物报警工单";
                    this.work_Options.info = "点位：" + item.devName + "，发生时间：" + item.happenTime + "，报警类型：水葫芦。";
                    this.work_Options.infobh = item.bh;
                    this.$refs.work.open();
                    break;
                case "导出":
                    this.$confirm({
                        title: '操作确认',
                        content: "是否导出?",
                        okText: '是',
                        cancelText: '否',
                        onOk() {
                            _this.dc();
                        }
                    });
                    break;
                case "删除":
                    this.$confirm({
                        title: '操作确认',
                        content: "是否删除《" + item.devName + "》吗?",
                        okText: '是',
                        cancelText: '否',
                        onOk() {
                            _this.del(item);
                        }
                    });
                    break;
                case "解除报警":
                    this.$confirm({
                        title: '操作确认',
                        content: "是否要解除《" + item.devName + item.happenTime + "》的报警吗?",
                        okText: '是',
                        cancelText: '否',
                        onOk() {
                            _this.jcbj(item);
                        }
                    });
                    break;
            }
        },
        formAddSubmit() {
            this.$refs.work.formAddSubmit();
            this.formAddOptions.visible = false;
        },
        formSearchSubmit(e) {
            console.log(e);
            e.preventDefault();
            this.pagination.current = 1;
            this.TableListSearchModel.pageIndex = 1;
            this.bindList();
            this.setCallData();
        },
        formSearchReset() {
            this.formSearch.resetFields();
            this.bindList();
            this.setCallData();
        },
        toggle() {
            this.expand = !this.expand;
            this.init();
        },
        pageCurrentChange(pagination) {
            console.log(pagination);
            //return;
            this.pagination.current = pagination.current;
            this.TableListSearchModel.pageIndex = pagination.current;
            this.bindList();
            this.setCallData();
        },
        del(record) {
            this.$axios({
                url: '/api/Comon/deltag',
                method: 'post',
                data: {
                    tableName: 'hdsp_bjxx',
                    field: 'id',
                    value: record.id,
                    source: 'web',
                    loginid: ''
                }
            }).then((res) => {
                if (res.status == 200) {
                    if (res.data.code == 0) {
                        this.$message.info("删除成功");
                        this.bindList();
                    } else {
                        throw new Error("服务器超时");
                    }
                } else {
                    throw new Error("服务器超时");
                }
            }).catch((error) => {
                // 请求失败处理
                this.$message.error(error.message);
            });
        },
        jcbj(record) {
            this.$axios({
                url: "/api/hdsp/jcbj?bh=" + record.bh,
                method: "get",
            }).then((res) => {
                if (res.status == 200) {
                    if (res.data.code == 0) {
                        this.$message.info("解除成功");
                        this.bindList();
                    } else {
                        throw new Error("服务器超时");
                    }
                } else {
                    throw new Error("服务器超时");
                }
            }).catch((error) => {
                // 请求失败处理
                this.$message.error(error.message);
            });
        },
        init() {
            var that = this;
            size();
            function size() {
                var innerHeight = window.innerHeight || document.body.innerHeight;
                that.$nextTick(() => {
                    var el = document.querySelector(
                        "#components-form-demo-advanced-search>form"
                    );
                    if (el) {
                        that.scroll.y =
                            innerHeight - getComputedStyle(el).height.split("p")[0] - 270;
                    }
                });
            }
        },
        actions() {
            if (this.inlineButtons.length == 0) {
                //this.columns.pop();
            }
        },
        onSelectChange(selectedRowKeys) {
            console.log(selectedRowKeys);
            this.selectedRowKeys = selectedRowKeys;
        },
        onClickDel() {
            this.$confirm({
                title: '操作确认',
                content: "确认删除所选内容吗?",
                okText: '是',
                cancelText: '否',
                onOk: () => {
                    this.selectedRowKeys.forEach(item => {
                        this.del({ id: item })
                    })
                    this.selectedRowKeys = []
                }
            });
        }
    },
};

</script>
<style scoped>
.video-format {
    position: relative;
    width: 110px;
    height: 75px;
}

.video-format i {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9;
    transform: translate(-50%, -50%);
}

.ant-layout-footer {
    display: none;
}

#components-form-demo-advanced-search {
    background: white;
    box-sizing: border-box;
}

.ant-form-item {
    margin-bottom: 12px;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    display:  !important;
}

/*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

.ant-upload-list-picture-card .ant-upload-list-item-name {
    margin: 1px 1px 1px 1px !important;
}
</style>