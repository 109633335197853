<template>
  <div id="components-form-demo-advanced-search">
    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="堤防名称" v-decorator="[`Name`]" />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="所属区镇" v-decorator="['town']"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
            <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24" v-if="expand">
      </a-row>
      <a-row :gutter="[24,12]">
        <a-col :span="6" :style="{ textAlign: 'left' }">
          <a-button type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button>
        </a-col>
        <!--<a-col :span="18" :style="{ textAlign: 'right' }">
                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                    </a>
                </a-col>-->
      </a-row>
    </a-form>
    <a-modal v-model="formAddOptions.visible" title="新增" width="800px">
      <a-spin :spinning="formAddOptions.spinning">
        <a-form :form="formAdd" :label-col="{ span:7 }" :wrapper-col="{ span:17 }" labelAlign="left">
          <a-form-item label="" style="display:none">
            <a-input v-decorator="['id']" />
          </a-form-item>

          <a-row type="flex" justify="space-between" :gutter="32">
            <a-col :span="12">
              <a-form-item label="堤防名称">
                <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入堤防名称' }]}]" placeholder="请输入堤防名称" :disabled="formAddOptions.viewMode" :maxLength="20" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="所属区镇">
                <!-- <a-input v-decorator="['town', { rules: [{ required: true, message: '请输入所属区镇' }]}]" placeholder="所属区镇" :disabled="formAddOptions.viewMode" :maxLength="20" /> -->
                <a-select v-decorator="['townId',{ rules: [{ required: true, message: '请选择所属区镇'}]},]" placeholder="请选择所属区镇" :disabled="formAddOptions.viewMode">
                  <a-select-option v-for="(item,index) in xzlist" :key="index" :value="item.value">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between" :gutter="32">
            <a-col :span="12">
              <a-form-item label="堤防长度（米）">
                <a-input-number :min="0" v-decorator="['dykeLength']" placeholder="请输入堤防长度" :disabled="formAddOptions.viewMode" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="堤防宽度（米）">
                <a-input-number :min="0" v-decorator="['dykeWidth']" placeholder="请输入堤防长度" :disabled="formAddOptions.viewMode" />
              </a-form-item>
            </a-col>
          </a-row>

        </a-form>
      </a-spin>
      <template slot="footer">
        <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
        <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
      </template>
    </a-modal>
    <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="20" :total="50" rowKey="id" :loading="loading" :scroll="scroll" @change="pageCurrentChange" position="bottom" tableLayout="fixed">
      <span slot="xuhao" slot-scope="text, record">{{record.rownum}}</span>
      <a slot="Name" slot-scope="text,record" @click="openDlg(record,'view')">{{ text }}</a>
      <span slot="emergency" slot-scope="text, record">{{record.emergencyPower?"有":"无"}}</span>
      <span slot="safety" slot-scope="text, record">{{record.safetyAssessment?"有":"无"}}</span>
      <span slot="action" slot-scope="text, record">
        <a-button type="primary" size="small" style='margin:0 5px' @click='lookproject(record)'>点位查看</a-button>
       <!-- <a-button :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right: 5px'>
          <a-icon :type="button.iconLink" />
        </a-button> -->
		<a-button v-if="role > 10" type='primary' title="编辑" size="small" key="编辑" @click="btnClick('编辑',record)" style='margin-right: 5px'><a-icon type="edit" /></a-button>
		<a-button v-if="role> 10" type='danger' title="删除" size="small" key="删除" @click="btnClick('删除',record)" style='margin-right: 5px'><a-icon type="delete" /></a-button>
      </span>
    </a-table>
  </div>
</template>
<script>
const strWhere = 'del=0'
export default {
  data() {
    return {
      role:0,
      scroll: {
        x: 900,
        y: 900
      },
      showexport: false,
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [],
      columns: [
        {
          title: '序号',
          fixed: 'left',
          width: 70,
          scopedSlots: { customRender: 'xuhao' }
        },
        {
          title: '堤防名称',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          scopedSlots: { customRender: 'Name' }
        },
        {
          title: '所属区镇',
          dataIndex: 'town',
          key: 'town'
        },
        {
          title: '长度（：m）',
          dataIndex: 'dykeLength',
          key: 'dykeLength'
        },
        {
          title: '宽度（：m）',
          dataIndex: 'dykeWidth',
          key: 'dykeWidth'
        },
        {
          title: '点位数量',
          dataIndex: 'pros',
          key: 'pros'
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 185,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: true,
      action: 'add',
      previewVisible: false,
      previewImage: '',
      fileList: [],
      strWhere,
      expand: false,
      formAddOptions: {
        visible: false,
        submitLoading: false,
        submitVisible: true,
        viewMode: false,
        spinning: false
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search2' }),
      formAdd: this.$form.createForm(this, { name: 'advanced_search3' }),
      pagination: {
        total: 0,
        pageSize: 15, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom'
      },
      xzlist: ['乡镇一', '乡镇二', '乡镇三', '乡镇四', '乡镇五']
    }
  },
  created() {
    // 模拟后台请求
    // this.bindList();
    // this.InitGrid()
    this.getTown();
    var user=localStorage.getItem("userInfo");
console.log('user',user);
  var usjson=JSON.parse(user)
    this.role=usjson.role;
  // alert(this.role);
  },
  mounted() {
    console.log(this.$route)
    if (this.$route.meta.buttons) {
      for (let i = 0; i < this.$route.meta.buttons.length; i++) {
        if (this.$route.meta.buttons[i].tag == 'inline') {
          this.inlineButtons.push(this.$route.meta.buttons[i])
        } else {
          this.buttons.push(this.$route.meta.buttons[i])
        }
      }
    }

    this.init()
    this.actions()
    window.onresize = () => {
      this.init()
    }
    this.bindList()
  },
  methods: {
    getTown() {
      this.$axios({
        url: '/api/zzgl/GetTown',
        method: 'get'
      }).then(res => {
        if (res.data.code == 0) {
          this.xzlist = res.data.data
        }
      })
    },
    lookproject(item) {
      this.$router.push(
        '/gcyxgl/hdshsy/dfdw-list-parent?townId=' +
          item.townId +
          '&zzbh=' +
          item.bh
      )
    },
    btnClick(text, item) {
      console.log(text)
      let _this = this
      switch (text) {
        case '新增':
          this.openDlg()
          break
        case '编辑':
          this.openDlg(item)
          break
        case '删除':
          this.$confirm({
            title: '操作确认',
            content: '确定要删除 ' + item.name + ' 吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.del(item)
            }
          })
          break
      }
    },
    bindList: async function () {
      this.formSearch.validateFields((error, values) => {
        error = 1
        // console.log(values)
        this.strWhere = "del=0 and source='堤防'"
        for (var obj in values) {
          if (values[obj]) {
            this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
          }
        }
        //console.log(this.strWhere)
        this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
      })
      this.loading = true
      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere)
      this.strWhere = this.strWhere.data
      await this.InitGrid()
    },
    InitGrid: function () {
      var _that = this
      _that.loading = true
      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName: 'v_zzgl_zzxx',
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          filesFields: 'bh',
          strWhere: _that.strWhere
        }
      })
        .then(function (response) {
          console.log(response)
          _that.loading = false
          if (response.status === 200) {
            _that.tableData = response.data.data
            _that.pagination.total = response.data.count
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    formSearchSubmit(e) {
      e.preventDefault()
      this.pagination.current = 1
      this.bindList()
    },
    pageCurrentChange(pagination) {
      this.pagination.current = pagination.current
      this.bindList()
    },
    formSearchReset() {
      this.formSearch.resetFields()
      this.strWhere = 'del=0'
      this.bindList()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    openDlg: async function (record, action) {
      this.formAdd.resetFields()
      this.fileList = []
      this.formAddOptions.submitVisible = true
      this.formAddOptions.viewMode = false
      if (record !== undefined) {
        // 从后台取数据赋值
        this.formAddOptions.spinning = true
        var obj = await this.$jsc.DesEncrypt(
          this,
          'id=zhangbiao' + record.id + 'zhangbiao'
        )
        obj = obj.data
        this.$axios
          .get(
            '/api/GetList/getListByTable?tableName=zzgl_zzxx&strWhere=' +
              obj +
              '&filesFields=bh'
          )
          .then(response => {
            if (response.status == 200) {
              if (response.data.code == 0) {
                this.formAddOptions.visible = true
                var result = response.data.data
                // console.log(response.data);
                setTimeout(() => {
                  result[0].yyqk = true
                  result[0].emergencyPower =
                    result[0].emergencyPower == 'true' ? true : false
                  result[0].safetyAssessment =
                    result[0].safetyAssessment == 'true' ? true : false
                  // var objArr = { qymc: result[0].qymc, lxr: result[0].lxr, yyqk: true }
                  // this.formAdd.setFieldsValue(objArr)
                  this.formAdd.setFieldsValue(result[0])
                  this.fileList = result[0].files_bh
                  this.formAddOptions.spinning = false
                }, 100)
              } else {
                this.$message.error(response.data.msg)
                this.formAddOptions.spinning = false
              }
            } else {
              // vue.prototype.$message.info('This is an error message');
            }
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error)
          })
        if (action === 'view') {
          // 预览模式
          this.formAddOptions.submitVisible = false
          this.formAddOptions.viewMode = true
        }
      } else {
        this.formAddOptions.visible = true
      }
    },
    formAddSubmit() {
      this.formAdd.validateFields((err, values) => {
        if (!err) {
          values.files_bh = this.fileList
          values.source = '堤防'
          this.formAddOptions.submitLoading = true
          this.$axios({
            url: '/api/zzgl/EditZzxxInfo',
            method: 'post',
            data: values
          })
            .then(res => {
              console.log(res)
              if (res.status == 200) {
                if (res.data.code === 0) {
                  this.$message.info('保存成功')
                  this.formAddOptions.visible = false
                  this.formAddOptions.submitLoading = false
                  this.bindList()
                } else {
                  console.log(res)
                  throw new Error('服务器超时2')
                }
              } else {
                throw new Error('服务器超时2')
              }
            })
            .catch(error => {
              // 请求失败处理
              this.$message.error(error.message)
              this.formAddOptions.submitLoading = false
            })
        }
      })
    },
    del(record) {
      this.$axios({
        url: '/api/Comon/deltag',
        method: 'post',
        data: {
          tableName: 'zzgl_zzxx',
          field: 'id',
          value: record.id,
          source: 'web',
          loginid: ''
        }
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('删除成功')
              this.bindList()
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },
    init() {
      var that = this
      size()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector(
            '#components-form-demo-advanced-search>form'
          )
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('p')[0] - 270
          }
        })
      }
    },
    actions() {
      // if (this.inlineButtons.length == 0) {
      //   this.columns.pop()
      // }
    }
  }
}
</script>
<style>
</style>