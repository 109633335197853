<template>
  <div id="components-form-demo-advanced-search1">
    <a-modal v-model="formAddOptions.visible" title="新增待办事项" width="800px">
      <a-spin :spinning="formAddOptions.spinning">
        <a-form :form="formAdd" :label-col="{ span:6 }" :wrapper-col="{ span:18 }" labelAlign="right">

          <a-form-item label="" style="display:none">
            <a-input v-decorator="['id']" />
            <a-input v-decorator="['townId',{ initialValue:this.$route.query.townId }]" />
          </a-form-item>

          <a-row type="flex" justify="space-between">
            <a-col :span="20">
              <a-form-item label="巡查队伍">
                <a-select v-decorator="['teamId', {rules:[{ required: true, message: '请选择巡查队伍!' }],}]" placeholder="请选择巡查队伍" style="width:100%">
                  <a-select-option v-for="item in teamData" :value="item.value">{{item.name}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="20">
              <a-form-item label="规定巡查时间">
                <a-date-picker v-decorator="['xcsj', {rules:[{ required: true, message: '请选择规定巡查时间!' }],}]" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="20">
              <a-form-item label="巡查站闸">
                <a-select v-decorator="['zzbh',{ initialValue:this.$route.query.bh }]" placeholder="请选择巡查站闸" style="width:100%" :disabled="true">
                  <a-select-option v-for="item in zzData" :value="item.value">{{item.name}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
      <template slot="footer">
        <a-button key="back" @click="formAddOptions.visible=false;formAddOptions.dateVisible=false;">关闭</a-button>
        <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
      </template>
    </a-modal>

    <div style="text-align:center;font-size:30px;">
      <h1>{{this.$route.query.name}} 生成巡查任务查看</h1>
    </div>
    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="">
            <a-select v-decorator="['teamId']" placeholder="队伍" :disabled="formAddOptions.viewMode" style="width:100%">
              <a-select-option v-for="item in teamData" :value="item.value">{{item.name}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-range-picker :placeholder="['开始时间','结束时间']" @change="onRangePickerChange" v-decorator="['createTime']" />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-select v-decorator="['state']" placeholder="状态" style="width:100%">
              <a-select-option value="0">未查</a-select-option>
              <a-select-option value="1">未完成</a-select-option>
              <a-select-option value="2">已查完</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-select v-decorator="['issd']" placeholder="任务生成方式" style="width:100%">
              <a-select-option value="1">手动</a-select-option>
              <a-select-option value="0">自动</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="[24,12]">
        <a-col :span="18" :style="{ textAlign: 'left' }">
          <a-button type='' @click="back" style='margin-right: 5px'>返回</a-button>
          <a-button type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button>
        </a-col>
        <a-col :span="6" :style="{ textAlign: 'right' }">
          <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
          <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px',display:'none' }" @click="toggle">
            {{ expand ?'收起':'展开' }}
            <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>
    <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="20" :total="50" rowKey="id" :loading="loading" :scroll="scroll" @change="pageCurrentChange" position="bottom" tableLayout="fixed">
      <span slot="action" slot-scope="text, record">
        <a-button :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right: 5px'>
          <a-icon :type="button.iconLink" />
        </a-button>
      </span>
      <span slot="createTimes" slot-scope="text, record">
        {{formatDate(text)}}
      </span>
      <span slot="state" slot-scope="text, record">
        <a-tag v-if="record.rated-record.reality==0&&record.rated!=0&&record.reality!=0" color="green">已查完</a-tag>
        <a-tag v-if="record.rated-record.reality>0&&record.reality!=0" color="orange">未完成</a-tag>
        <a-tag v-if="record.reality==0" color="red">未查</a-tag>
      </span>
      <span slot="issd" slot-scope="text, record">
        <span v-if="text==1">手动</span>
        <span v-if="text==0">自动</span>
      </span>
    </a-table>
  </div>
</template>
<script>
const strWhere = 'del=0'
export default {
  data() {
    return {
      scroll: {},
      townData: [],
      zzData: [],
      teamData: [],
      showexport: false,
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'rownum',
          key: 'rownum',
          fixed: 'left',
          width: 100
        },
        {
          title: '巡查队伍',
          dataIndex: 'team',
          key: 'team'
        },
        {
          title: '巡查任务生成时间',
          dataIndex: 'createTime',
          key: 'createTime',
          scopedSlots: { customRender: 'createTimes' }
        },
        {
          title: '规定检查工程数',
          dataIndex: 'rated',
          key: 'rated'
        },
        {
          title: '实际检查工程数',
          dataIndex: 'reality',
          key: 'reality'
        },
        {
          title: '状态',
          dataIndex: 'state',
          key: 'state',
          scopedSlots: { customRender: 'state' }
        },
        {
          title: '任务生成方式',
          dataIndex: 'issd',
          key: 'issd',
          scopedSlots: { customRender: 'issd' }
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      StartTime: '',
      EndTime: '',
      loading: true,
      action: 'add',
      previewVisible: false,
      previewImage: '',
      fileList: [],
      strWhere,
      expand: false,
      formAddOptions: {
        visible: false,
        submitLoading: false,
        submitVisible: true,
        viewMode: false,
        spinning: false
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search2' }),
      formAdd: this.$form.createForm(this, { name: 'advanced_search3' }),
      pagination: {
        total: 0,
        pageSize: 15, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom'
      }
    }
  },
  mounted() {
    console.log(this.$route)
    if (this.$route.meta.buttons) {
      for (let i = 0; i < this.$route.meta.buttons.length; i++) {
        if (this.$route.meta.buttons[i].tag == 'inline') {
          this.inlineButtons.push(this.$route.meta.buttons[i])
        } else {
          this.buttons.push(this.$route.meta.buttons[i])
        }
      }
    }

    this.init()
    this.actions()
    window.onresize = () => {
      this.init()
    }
    this.bindList()
  },
  created() {
    this.bindDropDownData()
  },
  methods: {
    bindDropDownData() {
      var _this = this

      this.$axios({
        url: '/api/zzgl/GetXcTeam',
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.teamData = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })

      this.$axios({
        url: '/api/Zzgl/getZzList',
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.zzData = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    btnClick(text, item) {
      console.log(text)
      let _this = this
      switch (text) {
        case '手动添加待办事项':
          this.openDlg()
          break
        case '删除':
          this.$confirm({
            title: '操作确认',
            content: '确定要删除吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.del(item)
            }
          })
          break
      }
    },
    onRangePickerChange(date, dateString) {
      this.StartTime = dateString[0]
      this.EndTime = dateString[1]
    },
    bindList: async function () {
      this.formSearch.validateFields((error, values) => {
        error = 1
        // console.log(values)
        this.strWhere = "del=0 and zzbh='" + this.$route.query.bh + "'"
        for (var obj in values) {
          if (values[obj]) {
            if (obj == 'teamId') {
              this.strWhere += " and teamId='" + values[obj] + "'"
            }
            if (obj == 'createTime') {
              this.strWhere +=
                ' and ' +
                obj +
                " between '" +
                this.StartTime +
                "' and '" +
                this.EndTime +
                "'"
            }
            if (obj == 'state') {
              if (values[obj] == '0') {
                this.strWhere += ' and reality=0'
              }
              if (values[obj] == '1') {
                this.strWhere += ' and rated-reality>0 and reality<>0'
              }
              if (values[obj] == '2') {
                this.strWhere +=
                  ' and rated-reality=0 and record.rated<>0 and record.reality<>0'
              }
            }
            if (obj == 'issd') {
              this.strWhere += " and issd='" + values[obj] + "'"
            }
          }
        }
        console.log(this.strWhere)
        this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
      })
      this.loading = true
      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere)
      this.strWhere = this.strWhere.data
      await this.InitGrid()
    },
    InitGrid: function () {
      var _that = this
      _that.loading = true
      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName: 'v_zhsw_xcgl_xczz',
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          filesFields: 'bh',
          strWhere: _that.strWhere
        }
      })
        .then(function (response) {
          console.log(response)
          _that.loading = false
          if (response.status === 200) {
            _that.tableData = response.data.data
            _that.pagination.total = response.data.count
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    openDlg: async function (record, action) {
      this.formAdd.resetFields()
      this.fileList = []
      this.formAddOptions.submitVisible = true
      this.formAddOptions.viewMode = false
      if (record !== undefined) {
        // 从后台取数据赋值
        this.formAddOptions.spinning = true
        var obj = await this.$jsc.DesEncrypt(
          this,
          'id=zhangbiao' + record.id + 'zhangbiao'
        )
        obj = obj.data
        this.$axios
          .get(
            '/api/GetList/getListByTable?tableName=v_zhsw_xcgl_xcPlan&strWhere=' +
              obj +
              '&filesFields=bh'
          )
          .then(response => {
            if (response.status == 200) {
              if (response.data.code == 0) {
                this.formAddOptions.visible = true
                var result = response.data.data
                console.log(response.data)
                this.function_jcpl(result[0].jg_unit.toString())
                setTimeout(() => {
                  this.formAdd.setFieldsValue({
                    id: result[0].id,
                    jg_unit: result[0].jg_unit.toString(),
                    zxsj: result[0].zxsj,
                    jg_num: result[0].jg_num,
                    teamId: result[0].teamId,
                    startTime_y: parseInt(result[0].startTime.split('-')[0]),
                    startTime_r: parseInt(result[0].startTime.split('-')[1]),
                    endTime_y: parseInt(result[0].endTime.split('-')[0]),
                    endTime_r: parseInt(result[0].endTime.split('-')[1])
                  })
                  this.editgetText('a')
                  this.formAddOptions.spinning = false
                }, 100)
              } else {
                this.$message.error(response.data.msg)
                this.formAddOptions.spinning = false
              }
            } else {
              // vue.prototype.$message.info('This is an error message');
            }
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error)
          })
        if (action === 'view') {
          // 预览模式
          this.formAddOptions.submitVisible = false
          this.formAddOptions.viewMode = true
        }
      } else {
        this.formAddOptions.visible = true
      }
    },
    formAddSubmit() {
      this.formAdd.validateFields((err, values) => {
        values.xcsj = values['xcsj'].format('YYYY-MM-DD')
        if (!err) {
          this.formAddOptions.submitLoading = true
          this.$axios({
            url: '/api/zzgl/InsSdXczz',
            method: 'post',
            data: values
          })
            .then(res => {
              if (res.status == 200) {
                if (res.data.code === 0) {
                  this.$message.info('保存成功')
                  this.formAddOptions.visible = false
                  this.formAddOptions.submitLoading = false
                  this.formAddOptions.dateVisible = false
                  this.bindList()
                } else {
                  console.log(res)
                  this.$message.info(res.data.msg)
                  //throw new Error(res.data.msg);
                }
              } else {
                throw new Error('服务器超时2')
              }
            })
            .catch(error => {
              // 请求失败处理
              this.$message.error(error.message)
              this.formAddOptions.submitLoading = false
            })
        }
      })
    },
    back() {
      this.$router.push({ path: 'pbjh_sz' })
    },
    formSearchSubmit(e) {
      e.preventDefault()
      this.pagination.current = 1
      this.bindList()
    },
    pageCurrentChange(pagination) {
      this.pagination.current = pagination.current
      this.bindList()
    },
    formSearchReset() {
      this.formSearch.resetFields()
      this.strWhere = 'del=0'
      this.bindList()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    formatDate(value) {
      return this.$jsc.formatDate(value)
    },
    del(record) {
      this.$axios({
        url: '/api/Comon/deltag',
        method: 'post',
        data: {
          tableName: 'zhsw_xcgl_xczz',
          field: 'id',
          value: record.id,
          source: 'web',
          loginid: ''
        }
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('删除成功')
              this.bindList()
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },
    init() {
      var that = this
      size()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector(
            '#components-form-demo-advanced-search1>form'
          )
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('p')[0] - 270
          }
        })
      }
    },
    actions() {
      //if (this.inlineButtons.length == 0) {
      //    this.columns.pop();
      //}
    }
  }
}
</script>
<style>
.ant-calendar-picker {
  width: 100%;
}
</style>