<template>
    <div class="content1">
        <div style="width:800px; margin:0px auto;">
            统计年份：<a-date-picker placeholder="请选择年"
                                mode="year"
                                format="YYYY"
                                v-model="queryParam.years"
                                :open="dataopen"
                                @openChange="openChangeYear"
                                @panelChange="panelChangeYear" style="width:175px;" />

            <!--<span class="spanCss">区镇：</span>
    <a-select v-decorator="['townId']" placeholder="请选择区镇" class="selectCss" @change="Change_townId" default-value="201123134145209677">
        <a-select-option v-for="item in townData" :value="item.value">{{item.name}}</a-select-option>
    </a-select>

    <span class="spanCss">站闸：</span>
    <a-select v-decorator="['zzbh']" placeholder="请选择站闸" class="selectCss" @change="Change_zzbh">
        <a-select-option value="">请选择</a-select-option>
        <a-select-option v-for="item in zzData" :value="item.value">{{item.name}}</a-select-option>
    </a-select>

    <span class="spanCss">项目类型：</span>
    <a-select v-decorator="['xmlx']" placeholder="请选择项目类型" class="selectCss" @change="Change_xmlx">
        <a-select-option value="">请选择</a-select-option>
        <a-select-option v-for="item in xmlxData" :value="item.value">{{item.name}}</a-select-option>
    </a-select>-->


            <div style="text-align:center;font-size:30px; margin-top:20px;">
                <h1>工程问题汇总统计</h1>
            </div>
            <table class="mytable" style="width:100%">
                <tr>
                    <th>序号</th>
                    <th>工程名称</th>
                    <th>发现问题数</th>
                </tr>
                <tr v-for="(item,index) in datasource" :key="index">
                    <td>{{index+1}}</td>
                    <td>{{item.dictName}}</td>
                    <td>{{item.sl}}</td>
                </tr>
                <!--<tr v-for="(item,index) in datasource" :key="index">
            <td>{{index+1}}</td>
            <td>{{item.xmlx+'/'+item.zzmc+'/'+item.town}}</td>
            <td>{{item.y1}}</td>
        </tr>-->
                <tr>
                    <td colspan="2">合计</td>
                    <td>{{total}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        data() {
            return {
                dataopen: false, // 默认是否打开弹框
                year: '',
                queryParam: {
                    years: new Date().getFullYear().toString(),
                },
                datasource: [],
                qzLoginid: '201123134145209677',
                zzbh: '',
                xmlx: '',
                townData: [],
                zzData: [],
                xmlxData: [],
            }
        },
        computed: {
            total: function () {
                let t=0;
                this.datasource.forEach(function (val, index, arr) {
                    t += val.sl;
                });
                return t;
            }
        },
        created() {
            this.getdata(this.queryParam.years);
        },

        methods: {
            // 点击选择框事件 弹出日期组件选择年
            openChangeYear(status) {
                if (status) {
                    this.dataopen = true;
                } else {
                    this.dataopen = false;
                }
            },
            // 选择年之后 关闭弹框
            panelChangeYear(value) {
                this.queryParam.years = value;
                this.dataopen = false;
                this.getdata(new Date(value).getFullYear())
            },
            onChange(date, dateString) {
                console.log(date, dateString);
            },
            getdata(year) {
                //var year = new Date(this.queryParam.years).getFullYear();
                this.$axios({
                    url: "/api/tj/getwthztjdata?yyear="+year+"&mmonth="
                }).then(res => {
                    if (res.data.code == 0) {
                        this.datasource=res.data.data;
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .mytable {
        width: 800px;
    }

        .mytable td,th {
            border: 1px solid #e8e8e8;
            padding: 8px;
            text-align: center;
        }
</style>
