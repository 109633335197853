<template>
    <div style="width:100%; height:100%;">
        <div id="MyMap" style="width:100%; height:100%;"></div>
    </div>
</template>
<script>
    import * as esriLoader from 'esri-loader'
    var locationPath = location.pathname.replace(/\/[^\/]+$/, "");
    //var options = {
    //    url: 'http://118.31.71.54:8090/library/4.15/init.js',
    //        dojoConfig: {
    //    async: true,
    //        packages: [
    //            {
    //                name: "app",
    //                location: "http://118.31.71.54:8044/v0/app"
    //            },
    //             {
    //               name:"app",
    //               location:""
    //             }
    //        ]
    //    }
    //}
    
    esriLoader.loadScript({
        url: 'http://118.31.71.54:8090/library/4.15/init.js',
        dojoConfig: {
            async: true,
            packages: [
                {
                    name: "app",
                    location: "http://118.31.71.54:8044/v0/app"
                },
                // {
                //   name:"app",
                //   location:""
                // }
            ]
        }
    });
    


    esriLoader.loadCss('http://118.31.71.54:8090/library/4.15/esri/themes/light/main.css');
    esriLoader.loadCss('http://118.31.71.54:8044/v0/GRSHGISLibs.css');

    export default {
        data() {
            return {

            }
        },
        created: function () {
            esriLoader.loadModules(
                [
                    "http://118.31.71.54:8091/KSSmartWaterOneMapAPI/v1.4/app/MapInitial.js",
                    "http://118.31.71.54:8091/KSSmartWaterOneMapAPI/v1.4/app/KSSmartWater/ksSWInitial.js",
                    "http://118.31.71.54:8044/v0/app/KSSmartWater/test.js",
                    "http://118.31.71.54:8091/KSSmartWaterOneMapAPI/v1.4/app/test/pntlyrext2.js",
                ]).then(
                    ([
                        MI, ksSWInitial, test, PntFtLayerExt
                    ]) => {
                        var mi = new MI({
                            container: "MyMap",
                            useBSW: true,
                            mapsource: 1,
                            maptype: 1,
                            OMKey: "11",
                            tolerance:30
                            // view_click_handler:function(arg){
                            //   console.log(arg);
                            // }
                        });
                        //mi.initialAllCommonWidget();  //初始化所有Widget
                        var xcw = new ksSWInitial({
                            mi: mi,
                            //baseIdentifyHandler: function (graphic, x, y, lyrname) {
                            //    //水系图层点击回调 河道/湖泊/闸站等基础图层
                            //    console.log(graphic.attributes);
                            //},
                            //dbIdentifyHandler: function (graphic, x, y, lyrname) {
                            //    //业务图层点击回调 水位计图层回调在此写
                            //    console.log(graphic.attributes);
                            //}
                        });
                        xcw.initial();  //智慧水利相关功能初始化

                        xcw.setAllBaseSubLayerVisibility(false);  //关闭所有水系基础图层，配置为true，则打开所有图层
                        xcw.setAllDBSubLayerVisibility(false);  //关闭所有业务图层
                        //根据target参数，打开需要的图层数据
                        xcw.setBaseWaterSubLayerVisibility("River", true); //打开河道图层，配置为false，则关闭河道图层，第一个参数为指代图层的target参数
                        xcw.setBaseWaterSubLayerVisibility("Lake", true); //打开湖泊图层
                        xcw.setBaseWaterSubLayerVisibility("TownBoundary", true); //打开乡镇界线图层
                        xcw.setBaseWaterSubLayerVisibility("Mask", true); //打开掩膜图层
                        //。。。。其他需要打开的图层，参考CommonWebAPI中的target参数，进行配置
                        //打开水位计图层
                        xcw.setDBSubLayerVisibility("SWJ", true);
                        //其他需要打开的业务图层，按照上面的方法进行配置
                        //设置行政区及掩膜
                        xcw.setBoundary("昆山市");  //设置昆山市掩膜
                        xcw.zoomtoXZQ("昆山市");  //定位至昆山市

                        //xcw.setAllBaseSubLayerVisibility(false); //关闭所有水系基础图层
                        //xcw.setAllDBSubLayerVisibility(false);  //关闭所有业务图层
                        //xcw.setDBLyrShowInMap(["SWJ"]);  //水位计
                        
                        //xcw.setDefExpression("SWJ", "TownName='高新区'"); 
                        //xcw.setDBLyrShowInMap(["SWJ"]);  //雨量计
                        //xcw.setBaseWaterLyrShowInMap(["TownBoundary"]);
                        //setBaseWaterLayerShowInMap(['TownBoundary', 'Mask'])
                        //mi.zoomtoXZQ("周市镇");
                        //xcw.setBoundary("昆山市"); //设置昆山市掩膜
                        //xcw.setBaseWaterLyrShowInMap(["TownBoundary"]);
                        //xcw.zoomtoXZQ("周市镇"); //定位至周市镇
                        //xcw.setBaseWaterSubLayerVisibility("GHSX", true); //打开闸站图层
                        //xcw.setDefExpression("GHSX", "JGCompany='昆山市淀山湖防洪工程管理处'"); //只显示管理单位为淀防处的闸站
                        //zoomto用法
                        //public zoomto(ext: Extent): void;
                        //public zoomto(graphic: Graphic): void;
                        //public xcw.zoomto(lng: number, lat: number, zoom: number): void;
                        //public zoomto(xmin: number, xmax: number, ymin: number, ymax: number): void;
                        //xcw.setDBLyrShowInMap([]); //移除所有业务图层
                        //xcw.setBoundary("淀防处"); //设置淀防处掩膜
                        //xcw.zoomtoXZQ("淀防处"); //定位至淀防处
                        //xcw.setBaseWaterSubLayerVisibility("Sluice", true); //打开闸站图层
                        //xcw.setDefExpression("Sluice", "JGCompany='昆山市淀山湖防洪工程管理处'"); //只显示管理单位为淀防处的闸站
                        //// 使用pntLayerExt扩展图层站点
                        //var tstUrl = 'http://118.31.71.54:8054/api/TestDataSample/GetSamplePnt';  //此为测试点url,返回json数组
                        //var pntFTLYR = new PntFtLayerExt({
                        //    mi: mi,  //传递地图初始化对象
                        //    id: 'layerId5',  //图层id,图层的唯一描述,可根据自己的需要传递
                        //    dataUrl: tstUrl,  //数据url
                        //    // data:[{Longtitude:120,Latitude:31},{Longtitude:120.5,Latitude:31.5}],   //数据 该项羽dataUrl二选一.此处直接传递数组
                        //    x_Field: 'Longtitude',  //用于展点的X字段
                        //    y_Field: 'Latitude',  //用于展点的y字段
                        //    markerPicUrl: "https://static.arcgis.com/images/Symbols/Shapes/BlackStarLargeB.png",   //地图图标url
                        //    // markerPicUrl: '../../images/tst.gif',
                        //    // renderer: uqr,  //地图渲染器 非专业用户不需要使用该属性
                        //    enableCluster: true,  //是否开启聚合功能
                        //    // labelNm: 'PrjType',
                        //    // labelTxtSymbol: SymbolAssist.GetTxtSymbol({
                        //    //     fontNm: 'Josefin Slab',
                        //    // }),

                        //    title: '测试图层',  //图层标题
                        //});

                }).catch(err => {
                    console.log(err);
                })
        }
    }

    function aaa() {
        xcw.zoomto("120.9808441", "31.39981901", 14);
    }
</script>
<style>
    .ant-layout-content {
        padding: 0px !important;
        margin: 0px !important;
    }
</style>
