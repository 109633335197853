<template>
    <div>
        <a-menu mode="horizontal" id="topmenu">
            <a-menu-item key="mai11112121l" @click="showjcewm">检查端二维码</a-menu-item>
            <a-menu-item key="mai111121212" @click="showglewm">管理端二维码</a-menu-item>
            <a-menu-item key="mai111121213" @click="showblewm">外包公司端二维码</a-menu-item>
            <template v-for="topButton in topButtons">
                <a-menu-item :key="topButton.id" @click="btnClick(topButton)">{{topButton.title}}</a-menu-item>
            </template>
        </a-menu>
        <a-modal v-model="jcewm.visible" title="" :footer="null" :closable="false" :width="548">
            <a :href="$axios.defaults.baseURL+'/mobile/xcd/index.html'" target="_blank">
                <VueQr :text="$axios.defaults.baseURL+'/mobile/xcd/index.html'" colorDark="black" :size="500" />
            </a>
        </a-modal>

        <a-modal v-model="glewm.visible" title="" :footer="null" :closable="false" :width="548">
            <a :href="$axios.defaults.baseURL+'/mobile/gld/index.html'" target="_blank">
                <VueQr :text="$axios.defaults.baseURL+'/mobile/gld/index.html'" colorDark="black" :size="500" />
            </a>
        </a-modal>

        <a-modal v-model="wbewm.visible" title="" :footer="null" :closable="false" :width="548">
            <a :href="$axios.defaults.baseURL+'/mobile/wbd/index.html'" target="_blank">
                <VueQr :text="$axios.defaults.baseURL+'/mobile/wbd/index.html'" colorDark="black" :size="500" />
            </a>
        </a-modal>
    </div>
</template>
<script>
    import VueQr from 'vue-qr'
    export default {
        //name: 'hdshsytop',
        data() {
            return {
                jcewm: {
                    visible: false
                },
                glewm: {
                    visible: false
                },
                wbewm: {
                    visible: false
                }
            }
        },
        props: ["topButtons"],
        components: {
            VueQr
        },
        created: function () {
            //console.log(this.topButtons);
        },
        methods: {
            showjcewm() {
                this.jcewm.visible = true;
            },
            showglewm() {
                this.glewm.visible = true;
            },
            showblewm() {
                this.wbewm.visible = true;
            },
            btnClick(item) {
                //switch (value) {
                //    case "地图点位查看":
                //        this.$router.push({ path: "/hdshsy/map_xcry" });
                //        break;
                //}
                console.log(item);
                this.$router.push({ path: "/gcyxgl/hdshsy/map" });
            }
        }
    }
</script>
<style>
    .ant-layout-header .ant-menu-item-active {
        color: #FFE08A !important;
        border-bottom: 2px solid #FFE08A !important;
    }
    .ant-layout-header .ant-menu-item-selected {
        color: #FFE08A !important;
        border-bottom: 2px solid #FFE08A !important;
    }
</style>