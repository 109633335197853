<template>
  <div id="components-form-demo-advanced-search">

    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="">
            <a-month-picker v-decorator="['khsj']" :format="monthFormat" placeholder="考核月份" :maxLength="40" />
          </a-form-item>
        </a-col>
        <a-col :span="6" style="padding-top:4px;">
          <a-button type="primary" :style="{ width: '48px' }" html-type="submit" icon="search"></a-button>
          <a-button type="" :style="{ width: '48px', marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
        </a-col>
      </a-row>
    </a-form>

    <div class="search-result-list" :style="{ marginTop: '0px' }">
      <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="10" :total="50" rowKey="id" :loading="loading" :scroll="scroll" @change="pageCurrentChange" position="bottom" tableLayout="fixed">

        <span slot="titleTxt" slot-scope="text, record">
          <a @click="()=>$router.push('kh3List?yyear='+record.yyear+'&mmonth='+record.mmonth)">{{record.yyear}}年{{record.mmonth}}月的考核记录排名表</a>
        </span>
      </a-table>
    </div>

  </div>
</template>
<script>
import moment from 'moment'
const loading = true
const strWhere = 'del=0'
const action = 'add'
export default {
  data() {
    return {
      monthFormat: 'YYYY/MM',
      showexport: false,
      scroll: {
        x: 800
      },
      check_ywba: true,
      opration: '',
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'rownum',
          width: 70,
          key: 'rownum'
        },
        {
          title: '考核时间',
          dataIndex: 'yyear',
          width: 300,
          key: 'yyear',
          scopedSlots: { customRender: 'titleTxt' }
        },
        {
          title: '考核站闸数',
          dataIndex: 'khzzcount',
          width: 200,
          key: 'khzzcount'
        }
        //{
        //    title: "平均分",
        //    dataIndex: "pjf",
        //    width: 150,
        //    key: "pjf",
        //}

        //{
        //    title: "操作",
        //    key: "action",
        //    fixed: "right",
        //    width: 120,
        //    scopedSlots: { customRender: "action" },
        //},
      ],
      loading,
      action,
      strWhere,
      expand: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom'
      }
    }
  },
  mounted() {
    //将上面的按钮和表格内的按钮分出来
    console.log(this.$route.meta.buttons)
    if (this.$route.meta.buttons) {
      for (let i = 0; i < this.$route.meta.buttons.length; i++) {
        if (this.$route.meta.buttons[i].tag == 'inline') {
          this.inlineButtons.push(this.$route.meta.buttons[i])
        } else {
          this.buttons.push(this.$route.meta.buttons[i])
        }
      }
    }

    this.init()
    this.actions()
    window.onresize = () => {
      this.init()
    }
    this.bindList()
  },
  components: {},
  created() {},
  methods: {
    moment,
    bindList: async function () {
      this.formSearch.validateFields((error, values) => {
        error = 1
        console.log(values)
        this.strWhere = '1=1 '
        for (var obj in values) {
          if (values[obj]) {
            if (obj == 'khsj') {
              this.strWhere +=
                " and yyear = '" +
                moment(values[obj]).format('YYYY') +
                "' and mmonth = '" +
                moment(values[obj]).format('MM') +
                "'"
            } else {
              this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
            }
          }
        }
        console.log(this.strWhere)
        this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
      })
      this.loading = true

      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere)
      this.strWhere = this.strWhere.data
      await this.InitGrid()
    },
    InitGrid: function () {
      var _that = this
      _that.loading = true

      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName: 'v_zzgl_xcgl_khResult',
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          filesFields: '',
          strWhere: _that.strWhere
        }
      })
        .then(function (response) {
          console.log(response)
          _that.loading = false
          if (response.status === 200) {
            _that.tableData = response.data.data
            _that.pagination.total = response.data.count
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },

    formSearchSubmit(e) {
      console.log(e)
      e.preventDefault()
      this.pagination.current = 1
      this.bindList()
    },
    formSearchReset() {
      this.formSearch.resetFields()
      this.strWhere = 'del=0'
      this.bindList()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    pageCurrentChange(pagination) {
      console.log(pagination)
      //return;
      this.pagination.current = pagination.current
      this.bindList()
    },
    del(record) {
      this.$axios({
        url: '/api/Comon/deltag',
        method: 'post',
        data: {
          tableName: 'zzgl_xckh_Option',
          field: 'id',
          value: record.id,
          source: 'web',
          loginid: ''
        }
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('删除成功')
              this.bindList()
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },

    init() {
      var that = this
      size()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector(
            '#components-form-demo-advanced-search>form'
          )
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('p')[0] - 270
          }
        })
      }
    },
    actions() {
      if (this.inlineButtons.length == 0) {
        //this.columns.pop();
      }
    }
  }
}
</script>
<style>
.ant-layout-footer {
  display: none;
}

#components-form-demo-advanced-search {
  background: white;
  box-sizing: border-box;
  padding: 20px 20px 0;
}

.ant-form-item {
  margin-bottom: 12px;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: !important;
}

/*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 1px 1px 1px 1px !important;
}
</style>