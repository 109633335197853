<template>
  <div id="components-form-demo-advanced-search">
    <a-modal v-model="formAddOptions.visible" title="详情" width="800px">
      <a-spin :spinning="formAddOptions.spinning">
        <a-form :form="formAdd" :label-col="{ span:8 }" :wrapper-col="{ span:16 }" labelAlign="left">
          <a-form-item label="" style="display:none">
            <a-input v-decorator="['id']" />
          </a-form-item>

          <a-row type="flex" justify="space-between">
            <a-col :span="11">
              <a-form-item label="姓名">
                <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入姓名' },{max:50,message:'最大长度为50'}]}]" placeholder="姓名" :disabled="formAddOptions.viewMode" />
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item label="登录账号">
                <a-input v-decorator="['loginName', { rules: [{ pattern: new RegExp(/^[a-zA-Z][a-zA-Z0-9_]{4,15}$/,'g'), message: '用户名格式不正确，字母开头，允许5-16字节，允许字母数字下划线' }],}]" placeholder="登录账号" :disabled="formAddOptions.viewMode" />
              </a-form-item>
            </a-col>

          </a-row>
          <a-row type="flex" justify="space-between">
            <a-col :span="11">
              <a-form-item label="性别">
                <a-select style="width:100%" v-decorator="['sex',{ rules: [{ required: true, message: '请选择性别'}]},]" placeholder="请选择" :disabled="formAddOptions.viewMode">
                  <a-select-option value="男">
                    男
                  </a-select-option>
                  <a-select-option value="女">
                    女
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item label="联系电话">
                <a-input placeholder="联系电话" v-decorator="['lxdh',{ rules: [{ required: true, message: ' ' },{validator:phoneCheck.bind(this)}],},]" style="width: 100%" :disabled="formAddOptions.viewMode">

                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between">
            <a-col :span="11">
              <a-row>
                <a-form-item label="身份证号">
                  <a-input v-decorator="['sfzh', { rules: [{ required: true, message: ' ' },{validator:SFZCheck.bind(this)}],}]" placeholder="身份证号码" :disabled="formAddOptions.viewMode" />
                </a-form-item>
              </a-row>
            </a-col>

            <a-col :span="11">
              <a-form-item label="生日">
                <a-date-picker :disabled="formAddOptions.viewMode" style="width: 100%;" v-decorator="['birthday', { rules: []}]" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">

            <a-col :span="11">
              <a-form-item label="所属队伍">
                <a-select style="width:100%" placeholder="请选择所属队伍" @change="handleTeamsChange" :disabled="formAddOptions.viewMode" show-search option-filter-prop="children" :filter-option="filterOption" v-decorator="web_decorator_teams">
                  <a-select-option v-for="items in select_teams" :key="items.value">
                    {{items.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <!--<a-form-item label="所属企业">
                                <a-input v-decorator="['companyName', { rules: [{ required: false, message: '请输入企业名称' },{max:200,message:'最大长度为200'}]}]" placeholder="所属企业" :disabled="formAddOptions.viewMode" />
                            </a-form-item>-->
              <a-form-item label="所属企业">
                <a-select style="width:100%" placeholder="请选择维保企业" :disabled="formAddOptions.viewMode" show-search option-filter-prop="children" :filter-option="filterOption" v-decorator="web_decorator_qymc">
                  <a-select-option v-for="items in select_data" :key="items.value">
                    {{items.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

          </a-row>
          <a-row type="flex" justify="space-between">
            <a-col :span="11">
              <a-form-item label="直属上级">
                <a-select style="width:100%" placeholder="请选择直属上级" :disabled="formAddOptions.viewMode" show-search option-filter-prop="children" :filter-option="filterOption" v-decorator="web_decorator_leader">
                  <a-select-option v-for="items1 in select_leader" :key="items1.value">
                    {{items1.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item label="职务">
                <a-select style="width:100%" v-decorator="['job',{ rules: [{ required: true, message: '请选择职务'}]},]" placeholder="请选择职务" :disabled="formAddOptions.viewMode">
                  <a-select-option value="队长">
                    队长
                  </a-select-option>
                  <a-select-option value="副队长">
                    副队长
                  </a-select-option>
                  <a-select-option value="队员">
                    队员
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

          </a-row>
          <a-row type="flex" justify="space-between">
            <a-col :span="11">
              <a-form-item label="站闸">
                <a-select placeholder="请选择站闸" :disabled="formAddOptions.viewMode" show-search option-filter-prop="children" :filter-option="filterOption" v-decorator="web_decorator_zhanzha">
                  <a-select-option v-for="items in select_zhanzha" :key="items.value">
                    {{items.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="11">
              <a-form-item label="账号状态">

                <a-radio-group :disabled="formAddOptions.viewMode" v-decorator="['state',{}]">
                  <a-radio :value="0">
                    正常
                  </a-radio>
                  <a-radio :value="1">
                    禁用
                  </a-radio>
                  <a-radio :value="2">
                    锁定
                  </a-radio>

                </a-radio-group>
              </a-form-item>

            </a-col>

          </a-row>

        </a-form>

        <a-modal :visible="previewVisible" :footer="null" @cancel="filesCancelPreview">
          <img alt="人像" style="width: 120px;height:120px;" :src="previewImage" />
        </a-modal>
        <a-row>
          <a-col :span="11">
            <a-divider orientation="left">头像</a-divider>
            <a-upload name="avatar" accept="image/*" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :customRequest="customFilesUpload2" @change="handleChange" :disabled="formAddOptions.viewMode">
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width:120px;height:120px;" />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传头像
                </div>
              </div>
            </a-upload>
          </a-col>
          <a-col :span="11">
            <a-divider orientation="left">刷脸头像</a-divider>
            <a-upload name="avatar" accept="image/*" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :customRequest="customFilesUpload2" @change="handleChange" :disabled="formAddOptions.viewMode">
              <img v-if="imageUrl1" :src="imageUrl1" alt="avatar" style="width:120px;height:120px;" />
              <!-- <div v-else>
							        <a-icon :type="loading ? 'loading' : 'plus'" />
							        <div class="ant-upload-text">
							            上传头像
							        </div>
							    </div> -->
            </a-upload>
          </a-col>
        </a-row>
        <!-- 	<a-divider orientation="left">头像</a-divider>
				<a-upload name="avatar" accept="image/*"
				          list-type="picture-card"
				          class="avatar-uploader"
				          :show-upload-list="false"
				          :customRequest="customFilesUpload2"
				          @change="handleChange"
						  :disabled="formAddOptions.viewMode"
				          >
				    <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width:120px;height:120px;"/>
				    <div v-else>
				        <a-icon :type="loading ? 'loading' : 'plus'" />
				        <div class="ant-upload-text">
				            上传头像
				        </div>
				    </div>
				</a-upload> -->
      </a-spin>
      <template slot="footer">
        <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
        <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
      </template>
    </a-modal>
    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
      <a-row :gutter="24" type="flex" justify="space-between">
        <a-col :span="5">
          <a-form-item label="">
            <a-select style="width:100%" placeholder="所属队伍" show-search option-filter-prop="children" :filter-option="filterOption" v-decorator="['teamId']">
              <a-select-option v-for="items in select_teams" :key="items.value">
                {{items.name}}
              </a-select-option>
            </a-select>
          </a-form-item>

        </a-col>
        <a-col :span="5">
          <a-form-item label="">
            <a-select style="width:100%" v-decorator="['job']" placeholder="职务">
              <a-select-option value="队长">
                队长
              </a-select-option>
              <a-select-option value="副队长">
                副队长
              </a-select-option>
              <a-select-option value="队员">
                队员
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="">
            <a-input placeholder="姓名" v-decorator="[`name`]" />
          </a-form-item>
        </a-col>

        <a-col :span="5">
          <a-form-item label="">
            <a-input placeholder="联系电话" v-decorator="[`lxdh`]"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="5">
          <a-form-item label="">
            <a-input placeholder="身份证号码" v-decorator="[`sfzh`]"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="5">
          <a-form-item label="">
            <a-range-picker id="xc_time" :placeholder="['开始时间','结束时间']" @change="onRangePickerChange" v-decorator="['posttime']" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="[24,12]">
        <a-col :span="6" :style="{ textAlign: 'left' }">
          <a-button type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button>

        </a-col>
        <a-col :span="18" :style="{ textAlign: 'right' }">
          <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
          <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
            {{ expand ?'收起':'展开' }}
            <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>
    <div class="search-result-list" :style="{ marginTop: '0px' }">
      <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="20" :total="50" rowKey="id" :loading="tableLoading" :scroll="scroll" @change="pageCurrentChange" position="bottom" tableLayout="fixed">
        <a slot="staffName" slot-scope="text,record" @click="openDlg(record,'view')">{{ text }}</a>
        <!-- <span slot="action" slot-scope="text, record">
					<a-popconfirm title="是否通过审核?" ok-text="通过" cancel-text="不通过" @confirm="confirm(record)" @cancel="cancel(record)">
					    <a-button type='primary' size="small" style='margin-right:5px;display:inline'>审核</a-button>
					</a-popconfirm>
					<a-button v-if="button.title=='重置密码'" type='primary' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right:5px;display:inline'>{{button.title}}</a-button>
                    <a-button v-if="button.title!='重置密码'" :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right:5px;display:inline'><a-icon :type="button.iconLink" /></a-button>
					
                </span> -->
        <span slot="staffPhoto_local" slot-scope="text, record">
          <img v-if="text" :src="baseUrl1+text" style="height:50px;width:50px;" />
        </span>
        <span slot="posttime" slot-scope="text,record">{{$jsc.formatDate_isTime_new(text)}}</span>
        <!-- <span slot="state" slot-scope="text, record">
				    <a-tag v-if="text==2" color="orange">锁定</a-tag>
				    <a-tag v-if="text==0" color="green">正常</a-tag>
				    <a-tag v-if="text==1" color="red">禁用</a-tag>
				</span>
				<span slot="shzt" slot-scope="text, record">
				    <a-tag v-if="text==2" color="green">通过</a-tag>
				    <a-tag v-if="text==0" color="gray">未审核</a-tag>
				    <a-tag v-if="text==1" color="red">不通过</a-tag>
				</span> -->
      </a-table>
    </div>
  </div>
</template>
<script>
const strWhere = 'del=0'
const action = 'add'
export default {
  data() {
    return {
      showexport: false,
      scroll: {
        y: 900,
        x: 900
      },
      StartTime: '',
      EndTime: '',
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [],
      select_data: [],
      select_teams: [],
      select_leader: [],
      select_zhanzha: [],
      imageUrl: '', //'http://58.211.49.230:8051/upload/2020/10/15/193321/%E5%9B%9B%E8%AE%AE%E4%B8%A4%E5%85%AC%E5%BC%80.png',
      imagePath: '',
      imageUrl1: '', //刷脸头像
      baseUrl: this.$axios.defaults.fileURL,
      baseUrl1: this.$axios.defaults.baseURL,
      columns: [
        {
          title: '序号',
          dataIndex: 'rownum',
          width: 60,
          key: 'rownum'
        },
        {
          title: '头像',
          dataIndex: 'staffPhoto_local',
          key: 'staffPhoto_local',
          width: 80,
          scopedSlots: { customRender: 'staffPhoto_local' }
        },
        {
          title: '姓名',
          dataIndex: 'staffName',
          key: 'staffName',
          width: 100,
          scopedSlots: { customRender: 'staffName' }
        },
        // {
        //     title: '登录账号',
        //     dataIndex: 'loginName',
        //     width: 150,
        //     key: 'loginName'
        // },
        {
          title: '站闸',
          dataIndex: 'doorName',
          width: 150,
          key: 'doorName'
        },
        // {
        //     title: '队伍',
        //     dataIndex: 'team',
        //     width: 130,
        //     key: 'team'
        // },
        //                {
        //                    title: '所属公司',
        //                    dataIndex: 'companyName',
        //                    width: 150,
        //                    key: 'companyName'
        //                },
        {
          title: '时间',
          dataIndex: 'posttime',
          width: 150,
          key: 'posttime',
          scopedSlots: { customRender: 'posttime' }
        },
        // {
        //     title: '审核状态',
        //     dataIndex: 'shzt',
        //     width: 100,
        //     key: 'shzt',
        // 	scopedSlots: { customRender: 'shzt' }
        // },
        {
          title: '操作',
          width: 240,
          key: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableLoading: false,
      loading: false,
      action,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      strWhere,
      expand: false,
      formAddOptions: {
        visible: false,
        submitLoading: false,
        submitVisible: true,
        viewMode: false,
        spinning: false
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
      formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
      pagination: {
        total: 0,
        pageSize: 15, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom'
      },

      web_decorator_teams: [
        'teamId',
        { rules: [{ required: true, message: '请选择所属队伍' }] }
      ],
      web_decorator_leader: ['leaderId', { rules: [{}] }],
      web_decorator_zhanzha: [
        'zzbh',
        { rules: [{ required: true, message: '请选择站闸' }] }
      ],
      select_data: [],
      web_decorator_qymc: [
        'companybh',
        { rules: [{ required: true, message: '请选择维保公司' }] }
      ]
    }
  },

  mounted() {
    console.log(this.$route.meta)
    //将上面的按钮和表格内的按钮分出来
    if (this.$route.meta.buttons) {
      for (let i = 0; i < this.$route.meta.buttons.length; i++) {
        if (this.$route.meta.buttons[i].tag == 'inline') {
          this.inlineButtons.push(this.$route.meta.buttons[i])
        } else {
          this.buttons.push(this.$route.meta.buttons[i])
        }
      }
    }

    this.init()
    this.actions()
    window.onresize = () => {
      this.init()
    }
    this.getzhanzhalist()
    this.getteamslist()
    this.bindList()

    //console.log(this.$route);
    //console.log(this.buttons);
    //console.log(this.inlineButtons);
  },
  created: function () {
    this.$axios({
      url: '/api/Zzgl/getWbCompany',
      method: 'get'
    })
      .then(res => {
        if (res.status == 200) {
          if (res.data.code === 0) {
            this.select_data = res.data.data
          } else {
            console.log(res)
            throw new Error('服务器超时2')
          }
        } else {
          throw new Error('服务器超时2')
        }
      })
      .catch(error => {
        // 请求失败处理
      })
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    confirm(re) {
      // const value=2
      this.shenhe(2, re)
    },
    cancel(re) {
      // const value=1
      // console.log(re)
      this.shenhe(1, re)
    },
    shenhe(value, re) {
      const { id } = re
      const loginid = this.$User.user.userInfo.loginid
      this.$axios({
        url: '/api/comon/ExamineRyxx',
        method: 'post',
        data: { value, id, loginid, tableName: 'zhsw_xcryxx', field: 'shzt' }
      }).then(res => {
        // console.log(res)
        this.$message.info(res.data.msg)
        this.bindList()
      })
    },
    onRangePickerChange(date, dateString) {
      this.StartTime = dateString[0]
      this.EndTime = dateString[1]
    },
    btnClick(text, item) {
      let _this = this
      switch (text) {
        case '新增':
          this.openDlg()
          break
        case '编辑':
          this.openDlg(item)
          break
        case '删除':
          this.$confirm({
            title: '操作确认',
            content: '确定要删除 ' + item.name + ' 吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.del(item)
            }
          })
          break
        case '重置密码':
          this.$confirm({
            title: '操作确认',
            content: '确定要重置 ' + item.name + ' 的密码吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.resetPwd(item)
            }
          })
          break
      }
    },
    resetPwd(value) {
      // console.log(value); return;

      this.$axios({
        url: '/api/zzgl/modiXcrPassword',
        method: 'post',
        data: {
          loginid: this.$User.user.userInfo.loginid,
          value: value.id,
          type: 'reset'
        }
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('重置成功')
              // this.bindList();
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    image_remove() {
      this.imageUrl = ''
      this.imagePath = ''
    },
    phoneCheck(rule, value, callbackFn) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!reg.test(value)) {
        callbackFn('请输入正确的手机号码')
        return
      }
      callbackFn()
    },
    SFZCheck(rule, value, callbackFn) {
      const reg = /^(\d{18}|\d{15}|\d{17}x)$/
      if (!reg.test(value)) {
        callbackFn('请输入正确的身份证号码')
        return
      }
      callbackFn()
    },
    // handleChange(value) {
    // 				console.log(value);
    // 				console.log(value.key,"__",value.label.trim());
    // 			},
    handleChange(info) {
      if (info.file.status === 'uploading') {
        //this.tableLoading = true;
        return
      }
      if (info.file.status === 'done') {
        this.getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl
          //this.tableLoading = false;
          console.log(info.file.originFileObj)
        })
      }
    },
    bindList: async function () {
      this.formSearch.validateFields((error, values) => {
        error = 1
        console.log(values)
        this.strWhere = 'del=0 '
        for (var obj in values) {
          if (values[obj]) {
            if (obj == 'teamId') {
              this.strWhere += ' and ' + obj + '=' + values[obj]
            } else if (
              obj == 'posttime' &&
              this.StartTime != '' &&
              this.EndTime != ''
            ) {
              this.strWhere +=
                ' and ' +
                obj +
                " between '" +
                this.StartTime +
                "' and '" +
                this.EndTime +
                " 23:59:59'"
            } else {
              this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
            }
          }
        }

        console.log('strWhere=' + this.strWhere)
        this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
      })
      this.tableLoading = true

      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere)
      this.strWhere = this.strWhere.data
      await this.InitGrid()
    },
    InitGrid: function () {
      var _that = this
      _that.tableLoading = true
      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName: 'v_zhsw_xcryxx_xcjl',
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          // filesFields: 'bh',
          strWhere: _that.strWhere
        }
      })
        .then(function (response) {
          console.log(response)
          _that.tableLoading = false
          if (response.status === 200) {
            _that.tableData = response.data.data
            _that.pagination.total = response.data.count
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    formSearchSubmit(e) {
      e.preventDefault()
      this.pagination.current = 1
      this.bindList()
    },
    formSearchReset() {
      this.formSearch.resetFields()
      this.strWhere = 'del=0'
      this.bindList()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    pageCurrentChange(pagination) {
      this.pagination.current = pagination.current
      this.bindList()
    },
    del(record) {
      this.$axios({
        url: '/api/Comon/deltag',
        method: 'post',
        data: {
          tableName: 'zhsw_xcryxx',
          field: 'id',
          value: record.id,
          source: 'web',
          loginid: ''
        }
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('删除成功')
              this.bindList()
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },
    openDlg: async function (record, action) {
      this.formAdd.resetFields()

      this.fileList = []
      this.formAddOptions.submitVisible = true
      this.formAddOptions.viewMode = false
      if (record !== undefined) {
        //从后台取数据赋值
        this.formAddOptions.spinning = true
        var obj = await this.$jsc.DesEncrypt(
          this,
          'id=zhangbiao' + record.id + 'zhangbiao'
        )
        obj = obj.data
        this.$axios
          .get(
            '/api/GetList/getListByTable?tableName=v_zhsw_xcryxx_xcjl&strWhere=' +
              obj +
              '&filesFields=bh'
          )
          .then(response => {
            if (response.status == 200) {
              if (response.data.code == 0) {
                this.formAddOptions.visible = true
                var result = response.data.data
                console.log(response.data)
                setTimeout(() => {
                  // result[0].yyqk = true
                  // var objArr = { qymc: result[0].qymc, lxr: result[0].lxr, yyqk: true }
                  // this.formAdd.setFieldsValue(objArr)
                  // console.log(JSON.stringify(result[0]))
                  this.getleaderlist(result[0].teamId)

                  this.formAdd.setFieldsValue(result[0])

                  this.formAdd.setFieldsValue({ state: result[0].state })
                  this.imageUrl =
                    result[0].headImgPath == ''
                      ? ''
                      : this.$axios.defaults.fileURL + result[0].headImgPath
                  this.imageUrl1 =
                    result[0].staffPhoto_local == ''
                      ? ''
                      : this.$axios.defaults.baseURL +
                        result[0].staffPhoto_local
                  this.imagePath =
                    result[0].headImgPath == '' ? '' : result[0].headImgPath

                  this.editgetText('a')
                  this.formAddOptions.spinning = false
                }, 100)
              } else {
                this.$message.error(response.data.msg)
                this.formAddOptions.spinning = false
              }
            } else {
              // vue.prototype.$message.info('This is an error message');
            }
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error)
          })
        if (action === 'view') {
          // 预览模式
          this.formAddOptions.submitVisible = false
          this.formAddOptions.viewMode = true
        }
      } else {
        this.formAdd.setFieldsValue({ state: 1 })
        this.formAddOptions.visible = true
      }
    },
    filesCancelPreview() {
      this.previewVisible = false
    },
    async filesPreview(file) {
      //console.log(file.ext)
      // if (!file.url && !file.preview) {
      //   file.preview = await getBase64(file.originFileObj)
      // }
      // this.previewImage = file.url || file.preview
      // this.previewVisible = true
      location.href = file.url
    },
    filesChange: function (file) {
      if (file.file.status === 'removed') {
        this.fileList = file.fileList
      }
    },
    customFilesUpload(data) {
      const formData = new FormData()
      formData.append('file', data.file)
      formData.append('token', 'aiufpaidfupipiu') // 随便写一个token示例
      this.saveFile(formData)
    },
    customFilesUpload2(data) {
      //const formData = new FormData()
      //formData.append('file', data.file)
      //formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
      //         this.saveFile2(formData)

      this.$jsc.picCompress(data.file, 600, this.saveFile2)
    },
    formAddSubmit() {
      this.formAdd.validateFields((err, values) => {
        //if (this.imagePath == '') {
        //    this.$message.error('请上传头像');
        //    return;
        //}
        if (!err) {
          this.formAddOptions.submitLoading = true

          values.stationId = ''
          values.townId = this.$User.user.userInfo.townId
          values.headImgPath = this.imagePath
          // values.state=this.state
          // values.birthday=values["birthday"].format('YYYY-MM-DD')
          this.$axios({
            url: '/api/zzgl/EditXcry',
            method: 'post',
            data: values
          })
            .then(res => {
              if (res.status == 200) {
                if (res.data.code === 0) {
                  this.$message.info('保存成功')
                  this.formAddOptions.visible = false
                  this.formAddOptions.submitLoading = false
                  this.bindList()
                } else {
                  console.log(res)
                  throw new Error('服务器超时2')
                }
              } else {
                throw new Error('服务器超时2')
              }
            })
            .catch(error => {
              // 请求失败处理
              this.$message.error(error.message)
              this.formAddOptions.submitLoading = false
            })
        }
      })
    },
    saveFile(formData) {
      var that = this
      this.$axios({
        method: 'post',
        url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
        data: formData
      })
        .then(res => {
          if (res.status == 200) {
            var num = that.fileList.length
            that.fileList.push(res.data.data)
            // that.uploadgetText('a', num, res.data.data);
          } else {
            throw new Error('服务器器超时')
          }
        })
        .catch(error => {
          that.$message.error(error.message)
          console.log(error)
        })
    },
    saveFile2(formData) {
      var that = this
      this.$axios({
        method: 'post',
        url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
        data: formData
      })
        .then(res => {
          if (res.status == 200) {
            console.log(res)
            this.imageUrl = res.data.data.url
            this.imagePath = res.data.data.path
          } else {
            throw new Error('服务器器超时')
          }
        })
        .catch(error => {
          that.$message.error(error.message)
          console.log(error)
        })
    },
    getzhanzhalist() {
      this.$axios({
        url: '/api/zzgl/getZzList',
        method: 'get'
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code === 0) {
              this.select_zhanzha = res.data.data
            } else {
              console.log(res)
              throw new Error('服务器超时2')
            }
          } else {
            throw new Error('服务器超时2')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
          this.formAddOptions.submitLoading = false
        })
    },
    getteamslist() {
      this.$axios({
        url: '/api/zzgl/GetXcTeam',
        method: 'get'
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code === 0) {
              this.select_teams = res.data.data
            } else {
              console.log(res)
              throw new Error('服务器超时2')
            }
          } else {
            throw new Error('服务器超时2')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
          this.formAddOptions.submitLoading = false
        })
    },
    handleTeamsChange(value) {
      let teamId = value
      this.select_leader = []
      this.getleaderlist(teamId)
    },
    getleaderlist(teamId) {
      this.$axios({
        url: '/api/zzgl/GetXcLeader?teamId=' + teamId,
        method: 'get'
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code === 0) {
              if (res.data.data != []) {
                this.select_leader = res.data.data
              }
            } else {
              console.log(res)
              throw new Error('服务器超时2')
            }
          } else {
            throw new Error('服务器超时2')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
          this.formAddOptions.submitLoading = false
        })
    },
    init() {
      var that = this
      size()
      // this.getcompanylist()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector(
            '#components-form-demo-advanced-search>form'
          )
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('p')[0] - 270
          }
        })
      }
    },
    /**
     * @param {String} classname；upload的类名
     */
    editgetText(classname) {
      this.$nextTick(() => {
        var els = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span'
        )
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        //console.log(els)
        for (var i = 0, len = els.length; i < len; i++) {
          var type = els[i].textContent.split('.')[1]
          if (type !== 'png' && type !== 'jpg') {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = els[i].textContent
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + '...' + type
            }
            cel.innerText = temptext
            spanels[i].prepend(cel)
          }
        }
      })
    },
    /**
     * @param {String} classname；upload的类名
     * @param {Number} num；原有的已上传文件数量
     * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
     */
    uploadgetText(classname, num, response) {
      this.$nextTick(() => {
        //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        var tempNodeList = []
        for (var i = 0; i < spanels.length - num; i++) {
          // console.log(spanels[num+i])
          tempNodeList.push(spanels[num + i])
          for (var j = 0; j < tempNodeList.length; j++) {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = response.name
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + '...' + response.ext
            }
            cel.innerText = temptext
            tempNodeList[j].prepend(cel)
          }
        }
      })
    },
    actions() {
      if (this.inlineButtons.length == 0) {
        this.columns.pop()
      }
    }
  }
}

//function getBase64(file) {
//    return new Promise((resolve, reject) => {
//        const reader = new FileReader()
//        reader.readAsDataURL(file)
//        reader.onload = () => resolve(reader.result)
//        reader.onerror = error => reject(error)
//    })
//}
</script>
<style>
.ant-layout-footer {
  display: none;
}

#components-form-demo-advanced-search {
  background: white;
  box-sizing: border-box;
  padding: 20px 20px 0;
}

.ant-form-item {
  margin-bottom: 12px;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: !important;
}

/*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 1px 1px 1px 1px !important;
}
</style>