<template>
  <div id="components-form-demo-advanced-search">
    <a-modal v-model="formAddOptions.visible" :title="opration" width="800px">
      <a-spin :spinning="formAddOptions.spinning">
        <a-form :form="formAdd" :label-col="{ span:6 }" :wrapper-col="{ span:18 }" labelAlign="right">

          <a-form-item label="" style="display:none">
            <a-input v-decorator="['id']" />
            <!--<a-input v-decorator="['zzbh',{ initialValue:this.$route.query.bh }]" />
        <a-input v-decorator="['townId',{ initialValue:this.$route.query.townId }]" />-->
          </a-form-item>

          <a-row type="flex" justify="space-between">
            <a-col :span="20">
              <a-form-item label="预案名称">
                <a-input v-decorator="['title', { rules: [{ required: true, message: '请输入预案名称' }]}]" placeholder="请输入预案名称" :maxLength="40" />
              </a-form-item>
            </a-col>

            <a-col :span="20" style="display:none;">
              <a-form-item label="区镇">
                <a-select v-decorator="['townId',{ rules: [{ required: false, message: '请选择区镇'}]},]" placeholder="请选择区镇" :disabled="formAddOptions.viewMode">
                  <a-select-option v-for="item in townData" :value="item.value">{{item.name}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="20">
              <a-form-item label="检查频率">
                <a-select v-decorator="['jg_unit',{ rules: [{ required: true, message: '请选择检查频率'}]},]" placeholder="请选择检查频率" style="width:100%" @change="Change_jcpl">
                  <a-select-option value="0">N天一次</a-select-option>
                  <a-select-option value="1">N周一次</a-select-option>
                  <a-select-option value="2">N月一次</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between" v-if="formAddOptions.dateVisible">
            <a-col :span="20">
              <a-form-item :label="week_month_text">
                <a-select v-decorator="['zxsj',{ rules: [{ required: true, message: '请选择'+week_month_text}]},]" :placeholder="'请选择'+week_month_text" style="width:100%">
                  <a-select-option v-if="date_week" v-for="(item,index) in dateWeek_Data" :value="String(item.value)">{{item.name}}</a-select-option>
                  <a-select-option v-if="date_month" v-for="(item,index) in 28" :value="String(index+1)">{{index+1}}号</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="20">
              <a-form-item label="N的值">
                <a-input-number :min="1" :max="N_max" v-decorator="['jg_num', { rules: [{ required: true, message: '请输入N的值' }]}]" placeholder="请输入N的值"></a-input-number>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="20">
              <a-row>
                <a-form-item label="开始时间">
                  <a-row type="flex">
                    <a-col :span="11">
                      <a-select v-decorator="['startTime_y',{ rules: [{ required: true, message: '请选择月份'}]},]" placeholder="月份" style="width:100%;">
                        <a-select-option v-for="(item,index) in 12" :value="index+1">{{index+1}}月</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :span="2"></a-col>
                    <a-col :span="11">
                      <a-select v-decorator="['startTime_r',{ rules: [{ required: true, message: '请选择日期'}]},]" placeholder="日期" style="width:100%;">
                        <a-select-option v-for="(item,index) in 31" :value="index+1">{{index+1}}号</a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="20">
              <a-row>
                <a-form-item label="结束时间">
                  <a-row type="flex">
                    <a-col :span="11">
                      <a-select v-decorator="['endTime_y',{ rules: [{ required: true, message: '请选择月份'}]},]" placeholder="月份" style="width:100%;">
                        <a-select-option v-for="(item,index) in 12" :value="index+1">{{index+1}}月</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :span="2"></a-col>
                    <a-col :span="11">
                      <a-select v-decorator="['endTime_r',{ rules: [{ required: true, message: '请选择日期'}]},]" placeholder="日期" style="width:100%;">
                        <a-select-option v-for="(item,index) in 31" :value="index+1">{{index+1}}号</a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>

          <a-row type="flex" justify="space-between">
            <a-col :span="20">
              <a-form-item label="站闸是否开启">
                <p v-if="isOpen==0">
                  <a-switch checked-children="开" un-checked-children="关" @click="status_edit" />
                </p>
                <p v-else>
                  <a-switch checked-children="开" un-checked-children="关" :checked="switch_isbool" @click="status_edit" />
                </p>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
      <template slot="footer">
        <a-button key="back" @click="formAddOptions.visible=false;formAddOptions.dateVisible=false;">关闭</a-button>
        <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
      </template>
    </a-modal>

    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="">
            <a-input v-decorator="['title']" placeholder="请输入预案名称" :maxLength="40" />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
            <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
          </a-form-item>
        </a-col>
        <!--<a-col :span="6">
        <a-form-item label="">
            <a-select v-decorator="['state']" placeholder="状态" style="width:100%">
                <a-select-option value="0">未查</a-select-option>
                <a-select-option value="1">未完成</a-select-option>
                <a-select-option value="2">已查完</a-select-option>
            </a-select>
        </a-form-item>
    </a-col>
    <a-col :span="6">
        <a-form-item label="">
            <a-select v-decorator="['issd']" placeholder="任务生成方式" style="width:100%">
                <a-select-option value="1">手动</a-select-option>
                <a-select-option value="0">自动</a-select-option>
            </a-select>
        </a-form-item>
    </a-col>-->
      </a-row>

      <a-row :gutter="[24,12]">
        <a-col :span="18" :style="{ textAlign: 'left' }">
         <!-- <a-button type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button> -->
        <a-button v-if="role>10" type='primary' @click="btnClick('新增')" style='margin-right: 5px'>新增</a-button>
		</a-col>
        <!--<a-col :span="6" :style="{ textAlign: 'right' }">
                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    <a :style="{ marginLeft: '8px', fontSize: '12px',display:'none' }" @click="toggle">
                        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                    </a>
                </a-col>-->
      </a-row>
    </a-form>

    <div class="search-result-list" :style="{ marginTop: '0px' }">
      <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="10" :total="50" rowKey="id" :loading="loading" :scroll="scroll" @change="pageCurrentChange" position="bottom" tableLayout="fixed">
      <!--  <span slot="action" slot-scope="text, record">
          <a-button :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right: 5px'>
            <a-icon :type="button.iconLink" />
          </a-button>
        </span> -->
        <span slot="isOpen" slot-scope="text,record">
          <a-tag v-if="record.isOpen==0" color="red">关闭</a-tag>
          <a-tag v-if="record.isOpen==1" color="green">开启</a-tag>
          <!--<a-switch  v-if="record.isOpen==0" checked-children="开" un-checked-children="关" @click="status_edit" />
                    <a-switch v-if="record.isOpen==1" checked-children="开" un-checked-children="关" default-checked @click="status_edit" />-->
        </span>
        <span slot="startTime" slot-scope="text,record">
          <span v-if="text.indexOf('-')>-1">{{text.split('-')[0]+'月'+text.split('-')[1]+'日'}}</span>
          <span v-else>时间有误：{{text}}</span>
        </span>
        <span slot="endTime" slot-scope="text,record">
          <span v-if="text.indexOf('-')>-1">{{text.split('-')[0]+'月'+text.split('-')[1]+'日'}}</span>
          <span v-else>时间有误：{{text}}</span>
        </span>
		<span slot="action" slot-scope="text, record">
		   <!-- <a-button type="primary" size="small" style='margin:0 5px' @click='lookproject(record)'>查看详情</a-button> -->
		    <a-button v-if="role>10" type='primary' title="编辑" size="small" key="编辑" @click="btnClick('编辑',record)" style='margin-right: 5px'><a-icon type="edit" /></a-button>
		    <a-button v-if="role>10" type='danger' title="删除" size="small" key="删除" @click="btnClick('删除',record)" style='margin-right: 5px'><a-icon type="delete" /></a-button>
		</span>
      </a-table>
    </div>

    <a-modal v-model="view_fields.viewModel" title="检查项查看" width="70%">
      <div>
        <a-card :title="view_fields.title" style="width: 100%">
          <!--<a slot="jcxsl" slot-scope="text, record" @click="jump_xmgl_list(record)">更多</a>-->
          <p v-for="(item,index) in view_fields.data">
            {{index+1}}、{{item.info}}<br />
            <a v-for="item2 in item.files_bh" :href="item2.url" target="_blank"><img style="width:100px;height:100px;margin-left:10px;" :src="item2.url" /></a>
          </p>
        </a-card>
      </div>
      <template slot="footer">
        <a-button key="back" @click="view_fields.viewModel=false">关闭</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
const columns = [
  {
    title: '序号',
    dataIndex: 'rownum',
    width: 70,
    key: 'rownum'
  },
  {
    title: '预案名称',
    dataIndex: 'title',
    width: 100,
    key: 'title'
  },
  {
    title: '检查频率',
    dataIndex: 'pl',
    width: 100,
    key: 'pl',
    scopedSlots: { customRender: 'pl' }
  },
  {
    title: '开始时间',
    dataIndex: 'startTime',
    width: 100,
    key: 'startTime',
    scopedSlots: { customRender: 'startTime' }
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    key: 'endTime',
    width: 100,
    scopedSlots: { customRender: 'endTime' }
  },
  {
    title: '站闸是否开启',
    dataIndex: 'isOpen',
    width: 200,
    key: 'isOpen',
    scopedSlots: { customRender: 'isOpen' }
  },
  {
    title: '操作',
    key: 'action',
    fixed: 'right',
    width: 200,
    scopedSlots: { customRender: 'action' }
  }
]

const loading = true
const strWhere = 'del=0'
const action = 'add'
export default {
  data() {
    return {
      role:0,
      showexport: false,
      scroll: {},
      view_fields: {
        viewModel: false,
        title: '检查项',
        data: []
      },
      opration: '',
      townData: [],
      zzData: [],
      xmlxData: [],
      qz_ifshow: false,
      qzLoginid: '',
      strWhere2: '',
      isOpen: 0,
      date_week: false,
      date_month: false,
      switch_isbool: true,
      N_max: 10000,
      town_zzmc: '',
      //actionList: {
      //    add: false,
      //    remove: false,
      //    edit: false
      //},
      week_month_text: '',
      teamData: [],
      dateWeek_Data: [
        { name: '周一', value: 1 },
        { name: '周二', value: 2 },
        { name: '周三', value: 3 },
        { name: '周四', value: 4 },
        { name: '周五', value: 5 },
        { name: '周六', value: 6 },
        { name: '周日', value: 7 }
      ],
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [],
      columns,
      loading,
      action,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      strWhere,
      expand: false,
      formAddOptions: {
        visible: false,
        submitLoading: false,
        submitVisible: true,
        viewMode: false,
        spinning: false,
        dateVisible: false
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
      formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom'
      }
    }
  },
  mounted() {
    //将上面的按钮和表格内的按钮分出来
    console.log(this.$route.meta.buttons)
    if (this.$route.meta.buttons) {
      for (let i = 0; i < this.$route.meta.buttons.length; i++) {
        if (this.$route.meta.buttons[i].tag == 'inline') {
          this.inlineButtons.push(this.$route.meta.buttons[i])
        } else {
          this.buttons.push(this.$route.meta.buttons[i])
        }
      }
    }
 
    
    this.init()
    this.actions()
    window.onresize = () => {
      this.init()
    }
    this.bindList()
    //if (this.$route.query.zzbh) {
    //    var zzbh = this.$route.query.zzbh
    //    this.formSearch.setFieldsValue({
    //        "zzbh": zzbh,
    //    })
    //}

    var user=localStorage.getItem("userInfo");
    var usjson=JSON.parse(user)
    this.role=usjson.role;
  },
  created() {
    this.bindDropDownData()
  },
  methods: {
    status_edit(e) {
      if (e) {
        this.isOpen = 1
      } else {
        this.isOpen = 0
      }
    },
    bindDropDownData() {
      var _this = this
      this.$axios({
        url: '/api/Zzgl/GetTown',
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.townData = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })

      this.$axios({
        url: '/api/zzgl/GetXcTeam',
        method: 'get'
      })
        .then(function (response) {
          if (response.status === 200) {
            _this.teamData = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    views: async function (value) {
      this.view_fields.viewModel = true
      this.view_fields.title = value.town + '/' + value.zzmc + '/' + value.xmlx
      this.view_fields.data = []
      this.strWhere2 = "del=0 and xmbh='" + value.bh + "'"
      this.strWhere2 = this.strWhere2.replace(/'/g, 'zhangbiao')
      this.strWhere2 = await this.$jsc.DesEncrypt(this, this.strWhere2)
      this.strWhere2 = this.strWhere2.data
      await this.load_viewData()
    },
    load_viewData: function () {
      var _that = this
      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName: 'v_zzgl_zzxx_xmInfo',
          page: 1,
          limit: 1000,
          filesFields: 'bh',
          strWhere: _that.strWhere2
        }
      })
        .then(function (response) {
          console.log(response.data.data)
          if (response.status === 200) {
            _that.view_fields.data = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    btnClick(text, item) {
      let _this = this
      this.opration = text
      switch (text) {
        case '新增':
          this.openDlg()
          break
        case '编辑':
          this.openDlg(item)
          break
        case '查看':
          this.openDlg(item, 'view')
          break
        case '删除':
          this.$confirm({
            title: '操作确认',
            content: '确定要删除?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.del(item)
            }
          })
          break
      }
    },
    bindList: async function () {
      this.formSearch.validateFields((error, values) => {
        error = 1
        this.strWhere = 'del=0 '
        for (var obj in values) {
          if (values[obj]) {
            this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
          }
        }
        this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
      })
      this.loading = true

      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere)

      this.strWhere = this.strWhere.data
      await this.InitGrid()
    },
    InitGrid: function () {
      var _that = this
      _that.loading = true
      console.log(_that.strWhere)
      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName: 'v_zhsw_xcgl_yaxx',
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          filesFields: '',
          strWhere: _that.strWhere
        }
      })
        .then(function (response) {
          console.log(response)
          _that.loading = false
          if (response.status === 200) {
            _that.tableData = response.data.data
            _that.pagination.total = response.data.count
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    Change_jcpl(value) {
      this.formAdd.setFieldsValue({ zxsj: '' })
      this.function_jcpl(value)
    },
    function_jcpl(value) {
      switch (value) {
        case '1':
          this.formAddOptions.dateVisible = true
          this.date_week = true
          this.date_month = false
          this.N_max = 7
          this.week_month_text = '星期几'
          break
        case '2':
          this.formAddOptions.dateVisible = true
          this.date_month = true
          this.date_week = false
          this.N_max = 12
          this.week_month_text = '一个月中的几号'
          break
        default:
          this.formAddOptions.dateVisible = false
          this.N_max = 10000
          break
      }
    },
    back() {
      this.$router.push({ path: '/hdshsy/pbjh_sz' })
    },
    onRangePickerChange(date, dateString) {
      this.StartTime = dateString[0]
      this.EndTime = dateString[1]
    },
    onShowSizeChange(current, pageSize) {
      console.log(current, pageSize)
      return
    },
    formSearchSubmit(e) {
      e.preventDefault()
      this.pagination.current = 1
      this.bindList()
    },
    formSearchReset() {
      this.formSearch.resetFields()
      this.strWhere = 'del=0'
      this.bindList()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    pageCurrentChange(pagination) {
      console.log(pagination)
      //return;
      this.pagination.current = pagination.current
      this.bindList()
    },
    del(record) {
      this.$axios({
        url: '/api/Comon/deltag',
        method: 'post',
        data: {
          tableName: 'zhsw_xcgl_yaxx',
          field: 'id',
          value: record.id,
          source: 'web',
          loginid: ''
        }
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('删除成功')
              this.bindList()
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },
    openDlg: async function (record, action) {
      var _this = this
      this.formAdd.resetFields()
      this.fileList = []
      this.formAddOptions.submitVisible = true
      this.formAddOptions.viewMode = false
      if (record !== undefined) {
        // 从后台取数据赋值
        this.formAddOptions.spinning = true
        var obj = await this.$jsc.DesEncrypt(
          this,
          'id=zhangbiao' + record.id + 'zhangbiao'
        )
        obj = obj.data
        this.$axios
          .get(
            '/api/GetList/getListByTable?tableName=v_zhsw_xcgl_yaxx&strWhere=' +
              obj +
              '&filesFields=bh'
          )
          .then(response => {
            if (response.status == 200) {
              if (response.data.code == 0) {
                this.formAddOptions.visible = true
                var result = response.data.data
                console.log(response.data)
                this.function_jcpl(result[0].jg_unit.toString())
                setTimeout(() => {
                  this.formAdd.setFieldsValue({
                    id: result[0].id,
                    title: result[0].title,
                    townId: result[0].townId,
                    jg_unit: result[0].jg_unit.toString(),
                    zxsj: result[0].zxsj.toString(),
                    jg_num: result[0].jg_num,
                    teamId: result[0].teamId,
                    startTime_y: parseInt(result[0].startTime.split('-')[0]),
                    startTime_r: parseInt(result[0].startTime.split('-')[1]),
                    endTime_y: parseInt(result[0].endTime.split('-')[0]),
                    endTime_r: parseInt(result[0].endTime.split('-')[1])
                  })
                  this.isOpen = result[0].isOpen == true ? 1 : 0
                  this.editgetText('a')
                  this.formAddOptions.spinning = false
                }, 100)
              } else {
                this.$message.error(response.data.msg)
                this.formAddOptions.spinning = false
              }
            } else {
              // vue.prototype.$message.info('This is an error message');
            }
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error)
          })
        if (action === 'view') {
          // 预览模式
          this.formAddOptions.submitVisible = false
          this.formAddOptions.viewMode = true
        }
      } else {
        this.formAddOptions.visible = true
      }
    },
    formAddSubmit() {
      this.formAdd.validateFields((err, values) => {
        if (!err) {
          //if (values.startTime_y < 10) { values.startTime_y = "0" + values.startTime_y; }
          //if (values.startTime_r < 10) { values.startTime_r = "0" + values.startTime_r; }
          //if (values.endTime_y < 10) { values.endTime_y = "0" + values.endTime_y; }
          //if (values.endTime_r < 10) { values.endTime_r = "0" + values.endTime_r; }
          values['startTime'] = values.startTime_y + '-' + values.startTime_r
          values['endTime'] = values.endTime_y + '-' + values.endTime_r
          values.isOpen = this.isOpen
          //console.log(values); return;
          this.formAddOptions.submitLoading = true
          this.$axios({
            url: '/api/zzgl/EditXcYa',
            method: 'post',
            data: values
          })
            .then(res => {
              if (res.status == 200) {
                if (res.data.code === 0) {
                  this.$message.info('保存成功')
                  this.formAddOptions.visible = false
                  this.formAddOptions.submitLoading = false
                  this.formAddOptions.dateVisible = false
                  this.bindList()
                } else {
                  console.log(res)
                  this.$message.info(res.data.msg)
                  //throw new Error(res.data.msg);
                }
              } else {
                throw new Error('服务器超时2')
              }
            })
            .catch(error => {
              // 请求失败处理
              this.$message.error(error.message)
              this.formAddOptions.submitLoading = false
            })
        }
      })
    },

    filesCancelPreview() {
      this.previewVisible = false
    },
    async filesPreview(file) {
      //console.log(file.ext)
      // if (!file.url && !file.preview) {
      //   file.preview = await getBase64(file.originFileObj)
      // }
      // this.previewImage = file.url || file.preview
      // this.previewVisible = true
      location.href = file.url
    },
    filesChange: function (file) {
      if (file.file.status === 'removed') {
        this.fileList = file.fileList
      }
    },
    customFilesUpload(data) {
      const formData = new FormData()
      formData.append('file', data.file)
      //formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
      this.saveFile(formData)
    },
    saveFile(formData) {
      this.$axios({
        method: 'post',
        url: 'http://192.168.0.220:8002/api/FileSet/Upload',
        data: formData
      })
        .then(res => {
          if (res.status == 200) {
            var num = this.fileList.length
            this.fileList.push(res.data.data)
            this.uploadgetText('a', num, res.data)
          } else {
            throw new Error('服务器器超时')
          }
        })
        .catch(error => {
          this.$message.error(error.message)
          console.log(error)
        })
    },
    init() {
      var that = this
      size()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector(
            '#components-form-demo-advanced-search>form'
          )
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('p')[0] - 270
          }
        })
      }
    },
    /**
     * @param {String} classname；upload的类名
     */
    editgetText(classname) {
      this.$nextTick(() => {
        var els = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span'
        )
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        //console.log(els)
        for (var i = 0, len = els.length; i < len; i++) {
          var type = els[i].textContent.split('.')[1]
          if (type !== 'png' && type !== 'jpg') {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = els[i].textContent
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + '...' + type
            }
            cel.innerText = temptext
            spanels[i].prepend(cel)
          }
        }
      })
    },
    /**
     * @param {String} classname；upload的类名
     * @param {Number} num；原有的已上传文件数量
     * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
     */
    uploadgetText(classname, num, response) {
      this.$nextTick(() => {
        //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
        var spanels = document.querySelectorAll(
          '.' +
            classname +
            ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info'
        )
        var tempNodeList = []
        for (var i = 0; i < spanels.length - num; i++) {
          // console.log(spanels[num+i])
          tempNodeList.push(spanels[num + i])
          for (var j = 0; j < tempNodeList.length; j++) {
            var cel = document.createElement('span')
            cel.style.display = 'block'
            var temptext = response.name
            if (temptext.length > 8) {
              temptext = temptext.substr(0, 3) + '...' + response.ext
            }
            cel.innerText = temptext
            tempNodeList[j].prepend(cel)
          }
        }
      })
    },
    actions() {
      // if (this.inlineButtons.length == 0) {
      //   columns.pop()
      // }
    }
  }
}

//function getBase64(file) {
//    return new Promise((resolve, reject) => {
//        const reader = new FileReader()
//        reader.readAsDataURL(file)
//        reader.onload = () => resolve(reader.result)
//        reader.onerror = error => reject(error)
//    })
//}
</script>
<style>
.ant-layout-footer {
  display: none;
}

#components-form-demo-advanced-search {
  background: white;
  box-sizing: border-box;
  padding: 20px 20px 0;
}

.ant-form-item {
  margin-bottom: 12px;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: !important;
}

/*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 1px 1px 1px 1px !important;
}
</style>