<template>
    <div style="width:100%; height:100%;">
       防汛仓库
    </div>
</template>
<script>
    export default {
        data() {
            return {

            }
        },
        created: function () {
            console.log(111111);
        }
    }
</script>
