<template>
    <div>
        <a-modal v-model="formAddOptions.visible" :title="formAddOptions.title" width="800px">
            <a-form :form="formAdd">
                <div style="display:none">
                    <a-form-item>
                        <a-input v-decorator="['bh']" />
                    </a-form-item>
                </div>

                <a-row justify="space-between" :gutter="16">
                    <a-col :span="24">
                        <a-form-item label="工单标题">
                            <a-input v-decorator="['title', { rules: [{ required: true, message: '请输入工单标题' }]}]" placeholder="工单标题" :disabled="formAddOptions.viewMode" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-item label="工单内容">
                            <a-textarea v-decorator="['info', { rules: [{ required: true, message: '请输入工单内容' }]}]" style="resize: none;height: 100px;" placeholder="工单内容" :disabled="formAddOptions.viewMode" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="24" v-show="formAddOptions.showFile">
                        <a-upload class='a'
                                  :multiple="false"
                                  list-type="picture-card"
                                  :file-list="fileList"
                                  @preview="filesPreview"
                                  @change="filesChange"
                                  :customRequest="customFilesUpload">
                            <div v-if="fileList.length < 8">
                                <a-icon type="plus" />
                                <div class="ant-upload-text">
                                    上传附件
                                </div>
                            </div>
                        </a-upload>
                    </a-col>
                    <template v-if="formAddOptions.showcl">
                        <a-col :span="12">
                            <a-form-item label="处理人">
                                <a-select v-decorator="['zbdwLoginid',{ rules: [{ required: true, message: '请选择处理人'}]},]" placeholder="请选择处理人" :disabled="formAddOptions.viewMode" :options="recData">
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item label="到期时间">
                                <a-date-picker style="width: 100%;" v-decorator="['clsx', { rules: [{ required: true, message: '请输入到期时间' }]}]" placeholder="到期时间" :disabled="formAddOptions.viewMode" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-item label="派单说明">
                                <a-textarea style="resize: none;height: 100px;" :disabled="formAddOptions.viewMode" v-decorator="['sendContent', { rules: [{ required: false, message: '请输入派单说明' }]}]" placeholder="派单说明" />
                            </a-form-item>
                        </a-col>

                    </template>
                </a-row>

            </a-form>
            <template slot="footer">
                <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
                <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
            </template>
        </a-modal>
        <a-modal :visible="previewVisible" :footer="null" @cancel="filesCancelPreview">
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>

    </div>
</template>
<script>
    import moment from 'moment';
    export default {
        data() {
            return {
                loading: '',
                action: '',
                previewVisible: false,
                previewImage: '',
                fileList: [],
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false,
                    showcl: true,
                    showFile: true,
                    title: "新建工单",
                },
                recData: [
                    //{
                    //    label: "城区水利站", value: "21913114017898782"
                    //}
                ],
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
            }
        },
        props: {
            'dataOptions': Object,
        },
        mounted() {

        },
        beforeUpdate() {
            //this.formAdd.setFieldsValue({ zbdwLoginid: '21913114017898782' });

        },
        created() {
            this.bindrecData();
            //if (typeof (this.dataOptions.showFile))
        },
        methods: {
            bindrecData() {
                this.$axios({
                    method: 'get',
                    url: this.$axios.defaults.baseURL + "/api/Comon/getUser_new",
                    params: {
                        kind: "单位用户",
                        ssdepartid:"810",
                    }
                }).then((res) => {
                    for (var i = 0; i <= res.data.data.length; i++) {
                        this.recData.push({ label: res.data.data[i].text, value: res.data.data[i].value });
                    }
                    console.log(this.recData);
                }).catch((error) => {

                })
            },
            open() {
                if (typeof(this.dataOptions.showFile) == "boolean")
                {
                    this.formAddOptions.showFile = this.dataOptions.showFile;
                }
                if (this.dataOptions.bh != "") {
                    this.formAddOptions.title = "编辑工单";
                    this.formAddOptions.showcl = false;
                    this.$axios({
                        url: "/api/work/getList_v_commonWork",
                        method: "post",
                        data: {
                            where: { bh: this.dataOptions.bh }
                        },
                    }).then((res) => {
                        this.loading = false;
                        if (res.status === 200) {
                            if (res.data.code == 0) {
                                setTimeout(() => {
                                    this.formAdd.setFieldsValue(res.data.data.list[0]);
                                    //this.formAdd.setFieldsValue({id:"1212"})
                                }, 0);

                            }
                            else {
                                this.$message.error("数据加载失败");
                            }
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                }
                else {
                    this.formAddOptions.title = "新建工单";
                    this.formAddOptions.showcl = true;
                    this.fileList = [];
                    setTimeout(() => {
                        this.formAdd.setFieldsValue({ title: this.dataOptions.title });
                        this.formAdd.setFieldsValue({ info: this.dataOptions.info });
                        this.formAdd.setFieldsValue({ clsx: moment().add(1, 'days').format('YYYY-MM-DD') });
                    }, 100);
                    this.formAdd.resetFields();
                }
                this.formAddOptions.visible = true;
            },
            formAddSubmit() {
                this.formAdd.validateFields((err, values) => {
                    console.log(values);
                    if (!err) {
                        values.files_bh = this.fileList
                        values.sendSource = "PC";
                        values.module = this.dataOptions.module;
                        values.infobh = this.dataOptions.infobh;
                        this.formAddOptions.submitLoading = true
                        this.$axios({
                            url: '/api/work/pd',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    if (values.bh == "" || values.bh == undefined) {
                                        this.$message.success('派单成功');
                                    } else {
                                        this.$message.success('编辑成功');
                                    }
                                    this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false;
                                    this.$parent.bindList();

                                } else {
                                    console.log(res);
                                    throw new Error("服务器超时2");
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })
                    }
                })
            },
            resetFields() {
                this.formAdd.resetFields();
            },
            filesCancelPreview() {
                this.previewVisible = true
            },
            filesPreview(file) {
                location.href = file.url;
            },
            filesChange: function (file) {
                if (file.file.status === 'removed') {
                    this.fileList = file.fileList
                }
            },
            customFilesUpload(data) {
                const formData = new FormData()
                formData.append('file', data.file)
                this.saveFile(formData)
            },
            saveFile(formData) {
                this.$axios(
                    {
                        method: 'post',
                        url: this.$axios.defaults.fileURL + "/api/FileSet/Upload",
                        data: formData
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            //console.log(res);
                            var num = this.fileList.length
                            this.fileList.push(res.data.data)
                            this.uploadgetText('a', num, res.data.data);
                        }
                        else {
                            throw new Error("服务器器超时");
                        }
                    })
                    .catch((error) => {
                        this.$message.error(error.message);
                        console.log(error)
                    })
            },
            /**
             * @param {String} classname；upload的类名
             */
            editgetText(classname) {
                this.$nextTick(() => {
                    var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    //console.log(els)
                    for (var i = 0, len = els.length; i < len; i++) {
                        var type = els[i].textContent.split('.')[1]
                        if (type !== 'png' && type !== 'jpg') {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = els[i].textContent
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + type
                            }
                            cel.innerText = temptext
                            spanels[i].prepend(cel)
                        }
                    }
                })
            },
            /**
             * @param {String} classname；upload的类名
             * @param {Number} num；原有的已上传文件数量
             * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
             */
            uploadgetText(classname, num, response) {
                if (response.ext == ".jpg" || response.ext == ".png" || response.ext == ".gif" || response.ext == ".jpeg") {
                    return false;
                }
                this.$nextTick(() => {
                    //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    var tempNodeList = []
                    for (var i = 0; i < spanels.length - num; i++) {
                        // console.log(spanels[num+i])
                        tempNodeList.push(spanels[num + i])
                        for (var j = 0; j < tempNodeList.length; j++) {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = response.name
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + response.ext
                            }
                            cel.innerText = temptext
                            tempNodeList[j].prepend(cel)
                        }
                    }
                })
            },
        }
    }

</script>
<style scoped>
    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }
</style>