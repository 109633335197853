<template>
    <div class="layout">
        <div class="sider">
            <ul>
                <li 
                    v-for="(item, index) in pointList" 
                    :key="item.ChanPubId" 
                    :class="{ active: indexSelect ===  index }"
                    @click="selectPoint(item, index)"
                >
                    <a-icon type="video-camera" />
                    {{ item.NickName }}
                </li>
            </ul>
        </div>
        <div class="content">
            <template v-if="pointList.length">
                <h3>{{ pointList[indexSelect].NickName }}</h3>
            </template>
            <div class="chart-line-1" ref="chartLine1"></div>
            <div class="chart-line-2" ref="chartLine2"></div>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts'
    import SQLCompiler from '@/assets/js/SQLCompiler.js'
    export default {
        data() {
            return {
                pointList: [],
                indexSelect: 0
            }
        },
        methods: {
            selectPoint (point, i) {
                this.indexSelect = i
                this.getDataForTrend(point.NickName)
            },
            async getPointList () {
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'v_wlw_camera',
                        page: 1,
                        limit: 10000,
                        strWhere: await SQLCompiler.encrypt(`del=0 and BindGroupName='水葫芦检测点位'`)
                    }
                })
                    .then(res => {
                        if (res.status === 200 && res.data.code === 0) {
                            const pointList = res.data.data
                            console.log(pointList);
                            if (pointList.length !== 0 ) {
                                this.getDataForTrend(pointList[0].NickName)
                            }
                            this.pointList = pointList
                        }
                    })
            },
            getDataForTrend (devName) {
                this.$axios({
                    url: '/api/hdsp/tj_shlqstck',
                    method: 'get',
                    params: { devName }
                }).then(res => {
                    if (res.status === 200 && res.data.code === 0) {
                        const { data } = res.data

                        this.drawChart({
                            domRef: 'chartLine1',
                            titleText: '每日趋势图',
                            xAxisData: data[0].map(t => t.text),
                            seriesData1: data[0].map(t => t.value),
                            seriesData2: data[0].map(t => t.value2),
                        })

                        this.drawChart({
                            domRef: 'chartLine2',
                            titleText: '每月趋势图',
                            xAxisData: data[1].map(t => t.text),
                            seriesData1: data[1].map(t => t.value),
                            seriesData2: data[1].map(t => t.value2),
                        })
                    }
                })
            },
            drawChart (config) {
                const { domRef, titleText, xAxisData, seriesData1, seriesData2 } = config

                const instance = echarts.getInstanceByDom(this.$refs[domRef])
                instance && instance.clear()
                const chart = echarts.init(this.$refs[domRef])

                const option = {
                    title: {
                        text: titleText,
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'axis',
                        // formatter: params => {
                        //     let relVal = params[0].name
                        //     const units = ['平方米', '吨']
                        //     for (var i = 0, l = params.length; i < l; i++) {
                        //         relVal += `<br /> ${params[i].marker} ${params[i].seriesName}：${params[i].value} ${units[i]}`
                        //     }
                        //     return relVal
                        // }
                    },
                    legend: {
                        top: '1%',
                        left: '30%'
                    },
                    grid: {
                        left: '2%',
                        right: '2%',
                        top: '18%',
                        bottom: '5%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisData,
                        boundaryGap: true,
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            margin: 12,
                            rotate: 30
                        }
                    },
                    yAxis: [
                        {
                            name: '面积(平方米)',
                            type: 'value'
                        },
                        {
                            name: '重量(吨)',
                            type: 'value',
                            // inverse: true,
                            // nameLocation: 'start'
                        }
                    ],
                    series: [
                        {
                            name: '面积',
                            type: 'line',
                            data: seriesData1
                        },
                        {
                            name: '重量',
                            type: 'line',
                            yAxisIndex: 1,
                            data: seriesData2
                        },
                    ]
                }

                chart.setOption(option)

                this.$once('hook:beforeDestroy', () => chart && chart.dispose())
            }
        },
        created () {
            this.getPointList()
        }
    }
</script>

<style lang="less" scoped>
    .layout {
        display: flex;
        min-width: 1500px;
        height: 100%;
        user-select: none;
        .sider {
            width: 240px;
            height: inherit;
            border-right: 1px solid #ccc;


            ul {
                padding-right: 15px; 
                li {
                    font-size: 16px;
                    line-height: 2;
                    text-indent: .5em;
                    border-radius: 5px;
                    cursor: pointer;

                    &.active {
                        background: #1890ff;
                        color: #fff;
                    }

                    &:active {
                        opacity: .7;
                    }
                }
            }
        }

        .content {
            flex: 1;
            display: flex;
            flex-flow: column;
            justify-content: space-around;
            align-items: center;

            h3 {
                font-size: 24px;
                font-weight: 900;
                color: #23b8e5;
            }

            .chart-line-1 {
                width: 1200px;
                height: 325px;
            }

            .chart-line-2 {
                width: 1200px;
                height: 325px;
            }
        }
    }
</style>