<template>
    <div id="components-form-demo-advanced-search">
        
        <a-spin :tip="spin.message" :spinning="spin.spinning">

            <div style="display:flex;">
                <a-button style="width:100px; z-index:9999;" type="" @click="()=>$router.go(-1)">返回</a-button>
                <p style="font-size:20px;font-weight:bold;color:rgba(0,0,0,0.85); width:50%; padding-left:16px;">{{view_fields.title}}</p>
                <div style="text-align:right; width:50%">
                    <a-button type="primary" @click="dcpdf();">
                        导出PDF
                    </a-button>
                </div>
            </div>

            <a-collapse :bordered="true" v-model="activeKey">
                <template #expandIcon="props">
                    <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                </template>
                <a-collapse-panel v-for="item,index in view_fields.data" :key="String(index)" :header="item.town+'-'+item.zzmc+'-'+item.xmlx" :style="customStyle">
                    <a-row v-for="item2,index2 in item.xcxx">
                        <a-col :span="12">
                            <p>
                                {{index2+1}}、{{item2.xcx}}
                                <div>
                                    <a v-for="item3 in item2.filesPath"><img style="width:100px;height:100px;margin-left:25px;" :src="item3.url" @click="picyl(item3.url)" onerror="onerror=null;src='http://192.168.0.220:8002/upload/2020/12/1/184731/error_img.png'" /></a>
                                </div>
                                <div>
                                    <span style="margin-left:25px;">备注：{{item2.remark}}</span>
                                </div>
                            </p>
                        </a-col>
                        <a-col :span="12">
                            <a-tag v-if="item2.isCheck==false" color="gray" style="width:80px;text-align:center;">
                                未检查
                            </a-tag>
                            <template v-else>
                                <a-tag v-if="item2.isError==0||item2.isError==null" color="green" style="width:80px;text-align:center;">
                                    <a-icon type="check-circle" theme="twoTone" two-tone-color="#52c41a" />
                                    无问题
                                </a-tag>
                                <a-tag v-else color="red" style="width:80px;text-align:center;">
                                    <a-icon type="close-circle" theme="twoTone" two-tone-color="red" />
                                    有问题
                                </a-tag>
                            </template>
                            <!--<p><a-icon v-if="item2.isCheck==false" type="check-circle" theme="twoTone" two-tone-color="#52c41a" />无问题</p>
                        <p v-else><a-icon type="close-circle" theme="twoTone" two-tone-color="red" />有问题</p>-->
                        </a-col>
                    </a-row>
                </a-collapse-panel>
            </a-collapse>

            <a-modal v-model="pic_visible" title="图片预览" :footer="null" width="650px">
                <div style="text-align:center;"><img :src="pic_url" /></div>
            </a-modal>
        </a-spin>
    </div>
</template>
<script>
    const loading = true
    const strWhere = 'del=0'
    const action = 'add'
    export default {
        data() {
            return {
                showexport: false,
                scroll: {
                    x: 2000,
                    y: 900
                },
                view_fields: {
                    viewModel: false,
                    title: this.$route.query.titles,
                    data:[]
                },
                pic_visible:false,
                pic_url:"",
                activeKey:[],
                customStyle:'background: #fff;border-radius: 4px;margin-bottom: 0px;border: 1;overflow: hidden',
                opration: '',
                townData: [],
                zzData: [],
                xmlxData: [],
                qz_ifshow: false,
                qzLoginid: '',
                strWhere2:'',
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                tableData: [],
                loading,
                action,
                previewVisible: false,
                previewImage: '',
                fileList: [],
                strWhere,
                expand: false,
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
                spin: {
                    message: "加载中...",
                    spinning:false,
                }
            }
        },
        mounted() {
            this.init()
            window.onresize = () => {
                this.init()
            }
        },
        created() {
            this.load_viewData();
        },
        methods: {
            dcpdf() {
                this.spin.spinning = true;
                this.spin.message = "正在生成PDF，请稍候...";
                this.$axios({
                    url: this.$axios.defaults.fileURL + "/api/dcpages/htmlTopdf_dfcxcd?xcbh=" + this.$route.query.xczzbh,
                    method: 'get',
                }).then(res => {
                    console.log(res.data.data);
                    //location.href = res.data;
                    this.spin.spinning = false;
                    window.open(res.data.data);
                }).catch(error => { // 请求失败处理
                    this.$message.error("生成失败");
                    this.spin.spinning=false;
                })
            },
            picyl(url){
                this.pic_visible=true;
                this.pic_url=url;
            },
            load_viewData() {
                var _that = this
                var xczzbh = this.$route.query.xczzbh;
                var probh = this.$route.query.probh ? this.$route.query.probh : "";
                this.$axios({
                    url: '/api/Zzgl/GetXczzInfo?xczzbh=' + xczzbh + '&probh=' + probh,
                    method: 'get',
                }).then(function (response) {
                    console.log(response.data.data); //return;
                    if (response.status === 200) {
                        _that.view_fields.data = response.data.data
                        for (var i = 0; i < response.data.data.length; i++) {
                            _that.activeKey.push(i);
                        }


                        //设为已读
                        _that.$axios({
                            url: '/api/dfc/wbgsxc/read?id='+response.data.data[0].id,
                            method: 'get',
                        }).then(function (response) {
                            if (response.status === 200) {

                            }
                        }).catch(function (error) {
                            console.log(error)
                        })
                        //设为已读end

                    }
                }).catch(function (error) {
                    console.log(error)
                })
            },
            init() {
                var that = this
                size()
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 270
                        }
                    })
                }
            },
            /**
             * @param {String} classname；upload的类名
             */
            editgetText(classname) {
                this.$nextTick(() => {
                    var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    //console.log(els)
                    for (var i = 0, len = els.length; i < len; i++) {
                        var type = els[i].textContent.split('.')[1]
                        if (type !== 'png' && type !== 'jpg') {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = els[i].textContent
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + type
                            }
                            cel.innerText = temptext
                            spanels[i].prepend(cel)
                        }
                    }
                })
            },
            /**
             * @param {String} classname；upload的类名
             * @param {Number} num；原有的已上传文件数量
             * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
             */
            uploadgetText(classname, num, response) {
                this.$nextTick(() => {
                    //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    var tempNodeList = []
                    for (var i = 0; i < spanels.length - num; i++) {
                        // console.log(spanels[num+i])
                        tempNodeList.push(spanels[num + i])
                        for (var j = 0; j < tempNodeList.length; j++) {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = response.name
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + response.ext
                            }
                            cel.innerText = temptext
                            tempNodeList[j].prepend(cel)
                        }
                    }
                })
            },
            actions() {
                if (this.inlineButtons.length == 0) {
                    columns.pop();
                }
            }
        }
    }

    //function getBase64(file) {
    //    return new Promise((resolve, reject) => {
    //        const reader = new FileReader()
    //        reader.readAsDataURL(file)
    //        reader.onload = () => resolve(reader.result)
    //        reader.onerror = error => reject(error)
    //    })
    //}
</script>
<style>
    .ant-layout-footer {
        display: none;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
        padding: 20px 20px 0;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        font-size: 18px;
    }

    .ant-collapse > .ant-collapse-item p {
        font-size: 15px;
    }
    /*页面字体大小*/
    .ant-collapse > .ant-collapse-item p, .ant-collapse {
        font-size: 16px;
    }

        .ant-collapse > .ant-collapse-item > .ant-collapse-header {
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
        }

    .ant-tag {
        font-size: 15px;
    }
    /*结束*/
</style>