<template>
    <a-layout class="layout" id="layout">

        <a-layout-sider 
            id="layoutSider"
            class="layout_sider" 
            width="450" 
            v-model="showSideBar" 
            collapsible 
            :collapsedWidth="0" 
            :trigger="null" >

            <h3 class="layout_sider_title">水情</h3>

            <div class="layout_sider_content" id="layoutSiderContent">
                <a-collapse expand-icon-position="right" :activeKey="activeKey" id="summaryBar" @change="onAdjust">
                        <a-collapse-panel  header="概述" key="1" >
                            <table border="1"  class="layout_sider_content_table" >
                                <tr>
                                    <th colspan="2">水位站</th>
                                    <td>{{summary.point}}个</td>
                                </tr>
                                <tr>
                                    <th colspan="2">超过警戒水位站</th>
                                    <td>{{summary.overPoint}}个</td>
                                </tr>
                                <tr>
                                    <th>最高水位站</th>
                                    <td>{{ summary.highPoint.name }} </td>
                                    <td>{{ formatWater(summary.highPoint.value) }} m</td>
                                </tr>
                                <tr>
                                    <th>最低水位站</th>
                                    <td>{{ summary.lowPoint.name  }}</td>
                                    <td>{{ formatWater(summary.lowPoint.value) }} m</td>
                                </tr>
                            </table>
                        </a-collapse-panel>
                </a-collapse>

                <a-row type="flex" justify="center" align="middle" style="height: 80px;">
                    <a-col>
                        城市：
                    </a-col>
                    <a-col>
                        <a-select :value="sdSelected" @change="onChange" style="width: 120px">
                            <a-select-option 
                                v-for="item in sd"
                                :key="item.id" 
                                :value="item.value">
                                {{item.name}}
                            </a-select-option>
                        </a-select>
                    </a-col>
                </a-row>

                <a-collapse activeKey="1"  expand-icon-position="right" id="ctBar">
                        <a-collapse-panel key="1" header="实时信息" :showArrow="false" disabled id="ctBar">
                            <a-table 
                                :columns="columns"
                                :data-source="td"
                                :pagination = "pagination"
                                @change="onChangePage"
                                :scroll="scroll"
                                bordered
                                rowKey="id"
                                :customRow="onRowClick">
                                <span slot="time" slot-scope="value">{{$jsc.formatDate_yMhm(value)}}</span>
                                <span slot="warn" slot-scope="value">{{ formatWater(value) }} m</span>
                                <span slot="current" slot-scope="value">{{ formatWater(value) }} m</span>
                                <span slot="action" slot-scope="record" @click.stop="onClickDetail(record)">
                                    <a-button type='primary' size="small">详情</a-button>
                                </span>
                            </a-table>
                        </a-collapse-panel>
                </a-collapse>
            </div>
            
            <!-- 切换开关 -->
            <div  class="layout_sider_switch" :class="{switch_on: !showSideBar}"  @click="onSwitch">
                <a-icon type="left" class="layout_sider_switch_icon" :rotate="switchIconRotate" />
            </div>
        </a-layout-sider>

        <a-layout-content class="layout_content">
            <div id="MyMap" style="height:100%;"></div>
        </a-layout-content>

        <!-- 点击地图水位计展示数据 -->
        <a-modal 
            v-model="showWlg"  
            :footer="null"
            :closable="false"
            centered
            @cancel="showWlgData = {}">
            <p slot="title">
                <a-icon type="flag" :style="{ color: '#1E90FF' }" />
                {{ showWlgData ? showWlgData.address : '未查询到监测点信息' }}
            </p>
            <p>时间：{{ showWlgData ? showWlgData.lastTime : '未查询到监测点信息' }}</p>
            <p>水位（米）：{{ showWlgData ? formatWater(showWlgData.currentData) : '未查询到监测点信息' }}</p>
            <p>区域：{{ showWlgData ? showWlgData.region : '未查询到监测点信息' }}</p>
            <p v-if="showWlgData && showWlgData.groupCode === 'ksslj'">河道：{{ showWlgData ? showWlgData.river : '未查询到监测点信息' }}</p>
            <p v-else>圩区：{{ showWlgData ? showWlgData.polder : '未查询到监测点信息' }}</p>
            <p v-if="showWlgData && showWlgData.groupCode === 'ksslj'">来源：市防办</p>
        </a-modal>

        <!-- 水位计详情 -->
        <a-modal 
            v-model="showDetail" 
            title="水情监测点" 
            :closable="false" 
            :footer="null"
            :width="900">

                <a-row v-if="dd.length" :gutter="[8, 16]">

                    <a-col :span="8" style="fontSize: 18px;">
                        <span style="color:dodgerblue">时间：</span>{{dd[0].lastTime}}
                    </a-col>
                    <a-col :span="8" style="fontSize: 18px;">
                        <span style="color:dodgerblue">警戒水位：</span>{{formatWater(dd[0].warningData)}} m
                    </a-col>
                    <a-col :span="8" style="fontSize: 18px;">
                        <span style="color:dodgerblue">实时水位：</span>{{formatWater(dd[0].currentData)}} m
                    </a-col>
                    <a-col :span="8" style="fontSize: 18px;">
                        <span style="color:dodgerblue">站点：</span>{{dd[0].address}}
                    </a-col>
                    <a-col :span="8" style="fontSize: 18px;" v-if="dd[0].groupCode==='ksslj'">
                        <span style="color:dodgerblue">来源：</span>{{dd[0].region}}
                    </a-col>

                </a-row>
            <div style="width:100%;height:400px;margin-top:20px;" ref="echart"></div>
        </a-modal>
    </a-layout>
</template>

<script>
    import * as esriLoader from 'esri-loader'
    import * as echarts from 'echarts'

    esriLoader.loadScript({
        url: 'http://118.31.71.54:8090/library/4.15/init.js',
        dojoConfig: {
            async: true,
            packages: [
                {
                    name: "app",
                    location: "http://118.31.71.54:8044/v0/app"
                }
            ]
        }
    })
    esriLoader.loadCss('http://118.31.71.54:8090/library/4.15/esri/themes/light/main.css')
    esriLoader.loadCss('http://118.31.71.54:8044/v0/GRSHGISLibs.css')
    let loadModulesUrl = [
        'http://118.31.71.54:8091/KSSmartWaterOneMapAPI/v1.5/app/MapInitial.js',
        'http://118.31.71.54:8091/KSSmartWaterOneMapAPI/v1.5/app/KSSmartWater/ksSWInitial.js',
        'http://118.31.71.54:8091/KSSmartWaterOneMapAPI/v1.5/app/test/pntlyrext2.js',
        'http://118.31.71.54:8090/library/4.15/esri/renderers/ClassBreaksRenderer.js',
        'http://118.31.71.54:8090/library/4.15/esri/symbols/PictureMarkerSymbol.js'
    ]

    let columns = [
        {
            title: '站点',
            dataIndex: 'address',
            width: 148,
            ellipsis: true,
        },
        {
            title: '警戒水位',
            dataIndex: 'warningData',
            width: 90,
            scopedSlots: { customRender: 'warn' }
        },
        {
            title: '实时水位',
            dataIndex: 'currentData',
            width: 90,
            scopedSlots: { customRender: 'current' }
        },
        {
            title: '更新时间',
            dataIndex: 'lastTime',
            width: 120,
            scopedSlots: { customRender: 'time' }
        },
        {
            title: '操作',
            key: 'action',
            width: 80,
            scopedSlots: { customRender: 'action' }
        }
    ]

    export default {
        data () {
            return {
                showSideBar: false,
                switchIconRotate: 0,
                activeKey: 0,
                sdSelected: 'ksslj',
                allData: [],
                sd: [],
                td: [],
                pagination: {
                    total: 0,
                    pageSize: 10, // 每页中显示10条数据
                    current: 1,
                },
                columns,
                scroll: { x: 528, y: 1 },
                showWlg: false,
                showWlgData: [],
                showDetail: false,
                dd: [],
                summary: {
                    point: 0,
                    overPoint: 0,
                    highPoint: {
                        name: '',
                        value: 0
                    },
                    lowPoint: {
                        name: '',
                        value: 0
                    }
                }
            }
        },
        methods: {
            onSwitch () {
                this.showSideBar = !this.showSideBar
                if (!this.showSideBar) {

                }
                this.switchIconRotate += 180
            },
            
            onChange (value) {
                this.findListBySd(this.sdSelected = value)
                this.pagination.current = 1
            },

            // 查询下拉框数据
            findSd () {
                this.$axios({
                    url: 'http://58.210.32.10:8002/api/hdsq/GetSlzqzxx',
                    method: 'get'
                }).then(res => {
                    this.sd = res.data.data
                    
                    this.sd.forEach(s => {
                        this.$axios({
                            url: 'http://58.210.32.10:8081/api-polder/getPoints',
                            method: 'post',
                            params: {
                                regionCode: s.value
                            }
                        }).then(res => {
                            this.allData = [...this.allData, ...res.data.data]
                        })
                    })
                })
            },

            // 查询下拉框对应的列表
            findListBySd (value) {
                this.$axios({
                    url: 'http://58.210.32.10:8081/api-polder/getPoints',
                    method: 'post',
                    params: {
                        regionCode: value
                    }
                })
                    .then(res => {
                        this.td = res.data.data
                    })
                    .catch(err => err)
            },

            onChangePage ({ current }) {
                this.pagination.current = current
            },

            onRowClick (record) {
                return {
                    on: {
                        click: () => {
                            this.mi.zoomto(record.lng, record.lat, 15)
                        }
                    }
                }
            },

            onClickDetail({ address }) {
                this.showDetail = true

                this.dd = this.allData.filter(s => s.address === address)

                this.$nextTick(() => {
                    if (!this.dd.length) {
                        this.onBindEcharts([], [], [], this.$refs.echart)
                        return this.$message.error("未检测到对应的监测点")
                    }

                    let e = echarts.init(this.$refs.echart)
                    e.showLoading()

                    this.$axios({
                        url: 'http://58.210.32.10:8081/api-polder/getHisData',
                        method: 'post',
                        data: { 
                            startTime: '',
                            endTime: '', 
                            pointId: this.dd[0].id
                        }
                    }).then(res => {
                        let data = res.data.data
                        let xArr = [], wArr = [], cArr = []
                        
                        if (!data.length) {
                            this.$message.info("暂无历史数据")
                            e.hideLoading()
                            return this.onBindEcharts([], [], [], this.$refs.echart)
                        }

                        for (let i = data.length - 12; i < data.length; i++) {
                            xArr.push(this.$jsc.formatDate_isTime(data[i].monitorTime))
                            wArr.push(this.formatWater(data[i].warningData))
                            cArr.push(this.formatWater(data[i].data))
                        }

                        this.onBindEcharts(xArr, wArr, cArr, this.$refs.echart)
                    })
                })
                
            },

            onBindEcharts (xArr, wArr, cArr, dom) {
                let e = echarts.init(dom)
                let option = {
                    color: ['#09bcb7', '#ff999a'],
                    grid: {
                        top: '20px',
                        left: '20px',
                        right: '20px',
                        bottom: '20px',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            label: {
                                backgroundColor: '#eee'
                            }
                        },
                    },
                    legend: {
                        show: true,
                        icon: 'circle',
                        right: 140,
                        itemWidth: 8,
                        itemHeight: 8,
                        textStyle: {
                            color: '#555'
                        },
                        data: ['实时水位','警戒水位']
                    },
                    calculable: true,
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#eee'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#555'
                            },
                            interval: 0,
                            rotate: 40
                        },
                        data: xArr
                    },
                    yAxis: {
                        type: 'value',
                        axistick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#eee'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#555'
                            },
                            formatter: function (value) {
                                var texts = [];
                                value = value + " m";
                                texts.push(value);
                                return texts;
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                type: 'dashed',
                                color: ['#999']
                            }
                        }
                    },
                    series: [{
                        type: 'line',
                        name: '实时水位',
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1, [{
                                    offset: 0,
                                    color: 'rgba(90, 163, 216, 1)'
                                },
                                {
                                    offset: 0.8,
                                    color: 'rgba(255, 255, 255, 1)'
                                }
                            ], false
                            ),
                            shadowColor: 'rgba(0,0,0,0.01)',
                            shadowBlur: 10

                        },
                        smooth: true,
                        lineStyle: {
                            color: 'dodgerblue'
                        },
                        data: cArr
                    }, {
                        type: 'line',
                        name: '警戒水位',
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1, [{
                                    offset: 0,
                                    color: 'rgba(255,153,154,0.7)'
                                },
                                {
                                    offset: 0.8,
                                    color: 'rgba(255,153,154,0.01)'
                                }
                            ], false
                            ),
                            shadowColor: 'rgba(0,0,0,0.01)',
                            shadowBlur: 10

                        },
                        smooth: true,
                        lineStyle: {
                            color: '#ff999a'
                        },
                        data: wArr
                    }]
                }
                e.setOption(option)
                e.hideLoading()
            },
            
            onBindDetail (data) {
                let summary = {}

                summary.point = data.length
                
                data.sort((a, b) => {
                    return a.currentData - b.currentData
                })
                let highPoint = data[data.length - 1]
                let lowPoint = data.find(d => d.currentData)
                summary.highPoint = {
                    name: highPoint.address,
                    value: highPoint.currentData
                }
                summary.lowPoint = {
                    name: lowPoint.address,
                    value: lowPoint.currentData
                }

                summary.overPoint = 0
                data.forEach(d => {
                    let { currentData,  warningData } = d
                    if (currentData >= warningData) {
                        summary.overPoint++
                    }
                })

                this.summary = { ...summary }
                
                this.activeKey = 1
                
                this.onAdjust()
            },

            findMap () {
                esriLoader.loadModules(loadModulesUrl)
                    .then(([MI, ksSWInitial, pntlyrext2, ClassBreaksRenderer, PictureMarkerSymbol]) => {
                        let mi = new MI({
                            zoom: 10,
                            center_lng: 120.999999,
                            center_lat: 31.322222,
                            container: "MyMap",
                            maptype: 1,
                            OMKey: "11"
                        })
                        this.mi = mi

                        let xcw = new ksSWInitial({ mi })
                        xcw.initial();  //智慧水利相关功能初始化
                        xcw.setAllBaseSubLayerVisibility(false) //关闭所有水系基础图层
                        xcw.setAllDBSubLayerVisibility(false) //关闭所有业务图层
                        xcw.setBaseWaterSubLayerVisibility('River', true) //打开河道图层，配置为false，则关闭河道图层，第一个参数为指代图层的target参数
                        xcw.setBaseWaterSubLayerVisibility('Lake', true) //打开湖泊图层
                        xcw.setBaseWaterSubLayerVisibility('TownBoundary', true) //打开乡镇界线图层
                        xcw.setBaseWaterSubLayerVisibility('Mask', true) //打开掩膜图层
                        xcw.setBoundary('昆山市')
                        //配置分级渲染器
                        let renderer = new ClassBreaksRenderer({
                            field: 'lv' //此处为分级字段
                        })
                        renderer.addClassBreakInfo({
                            minValue: 1,
                            maxValue: 1,
                            symbol: new PictureMarkerSymbol({
                                url: '/img/marker2.png',
                                width: '30px',
                                height: '30px'
                            }),
                            label: '级别1'
                        })
                        renderer.addClassBreakInfo({
                            minValue: 2,
                            maxValue: 2,
                            symbol: new PictureMarkerSymbol({
                                url: '/img/marker7.png',
                                width: '30px',
                                height: '30px'
                            }),
                            label: '级别2'
                        })

                        this.$axios({
                            url: 'http://58.210.32.10:8081/api-polder/getPoints',
                            method: 'post',
                        }).then(res => {
                            this.onBindDetail(res.data.data)

                            let data = res.data.data.filter(item => {
                                return item.groupCode == 'ksslj'
                            })
                            data.forEach(d => {
                                d.lv = d.currentData >= d.warningData ? 2 : 1
                            })

                            let lyr = new pntlyrext2({
                                mi, //传递地图初始化对象
                                data, //数据 该项与dataUrl二选一.此处直接传递数组
                                renderer,  //地图渲染器 非专业用户不需要使用该属性
                                x_Field: 'lng', //用于展点的X字段
                                y_Field: 'lat', //用于展点的y字段
                                enableCluster: true, //是否开启聚合功能
                            })

                            lyr.initialClickOrMove({
                                view_click_handler: (g) => {
                                    this.showWlgData = g.attributes
                                    this.showWlg = true
                                }
                            })
                        })
                    })
            },

            formatWater (value = 0) {
                return value && (value / 1000).toFixed(2)
            },

            onAdjust () {
                let layoutH = document.querySelector('#layout').offsetHeight
                let summaryBarH = document.querySelector('#summaryBar').offsetHeight
                this.scroll.y = layoutH - (298 - summaryBarH) - 360 
            },

            onWindowAdjust () {
                let layoutH = document.querySelector('#layout').offsetHeight
                let summaryBarH = document.querySelector('#summaryBar').offsetHeight
                this.scroll.y = layoutH - summaryBarH - 360 
            }
        },
        created () {
            this.findSd()
            this.findListBySd(this.sdSelected)
        },
        mounted () {
            this.findMap()

            window.onresize = this.onWindowAdjust
            
        }
    }
</script>

<style lang="less" scoped>
    .layout {
        overflow: hidden;
        height: 100%;

        &_sider {
            background-color: #fff;
            box-shadow: 2px 0 8px -2px rgba(0, 0, 0, 0.15);
            
            & /deep/ .ant-collapse-header {
                background-color: #3A7FEA;
                color: #fff;
                cursor: auto;
            }

            &_content {
                padding: 20px 16px;
                
                &_table {
                    width: 360px; 
                    margin: 0 auto;
                    border: 1px solid #E8E8E8;
                    border-collapse: collapse;

                    td {
                        text-align: center;
                    }

                    th, td {
                        padding:10px;
                    }
                }

            }

            &_title {
                margin: 0 16px;
                padding: 10px 0;
                font-size: 18px;
                color: #3465D1;
                text-align: center;
                border-bottom: 1px solid #3465D1;
            }

            &_switch {
                position: absolute;
                top: 50%;
                right: -30px;
                z-index: 999;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                text-align: center;
                background-image: linear-gradient(to right, transparent 50%, #3565D1 50%);

                &:active {
                    opacity: 0.7;
                }

                &_icon {
                    position: absolute;
                    top: 50%;
                    right: 2px;
                    font-size: 28px;
                    color: #fff;
                    transform: translateY(-50%);

                    & /deep/ svg {
                        transition: transform 0.3s !important;
                    }
        }

            }

            .switch_on {
                background-image: linear-gradient(to right, transparent 50%, #E6E6E6 50%);
            }
        }
    }

</style>