<template>
    <div id="components-form-demo-advanced-search">
        <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
            <a-row :gutter="24" style="width: 1100px;margin: 0 auto;" type="flex" justify="space-between">
                <a-col :span="7" style="padding-left: 0;">
                    <a-form-item label="">
                        <a-select v-decorator="['townId']" placeholder="请选择区镇" style="width:100%" :disabled="qz_ifVisible" @change="select_qz">
                            <a-select-option v-for="item in townData" :value="item.value">{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="7">
                    <a-form-item label="">
                        <a-select v-decorator="['zzbh']" placeholder="请选择站闸" style="width:100%">
                            <a-select-option v-for="item in zzData" :value="item.value">{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="7">
                    <a-form-item label="">
                        <a-select v-decorator="['xmlx']" placeholder="请选择项目类型" style="width:100%">
                            <a-select-option v-for="item in xmlxData" :value="item.value">{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="3" style="display: flex;justify-content: flex-end;margin-top: 4px;padding-right: 0;">
                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    <a v-if="false" :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                    </a>
                </a-col>
            </a-row>
            <a-row :gutter="24" v-if="expand">
            </a-row>
            <!--<a-row :gutter="[24,12]">
                <a-col :span="18" :style="{ textAlign: 'right' }">
                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                    </a>
                </a-col>
            </a-row>-->

            <div class="codecontent">
                <div v-if="'d'+index" v-for="(item,index) in ewmData" style="text-align:center;">
                    <div :id="'d'+index">
                        <a-card :title="item.town+'/'+item.zzmc+'/'+item.xmlx" :bordered="false" style="width: 235px">
                            <VueQr v-if="Descypt_ewmData[index]" :text="Descypt_ewmData[index]" colorDark="black" :size="200" />
                        </a-card>
                    </div>
                    <div>
                        <a v-if="'a'+index" :id="'a'+index" @click="downloads('d'+index,item.town+'/'+item.zzmc+'/'+item.xmlx)" style="font-size:16px;">下载</a>
                    </div>
                </div>
            </div>
        </a-form>
    </div>
</template>

<script>
    import VueQr from 'vue-qr'
    const loading = true
    const strWhere = 'del=0'
    const action = 'add'
    export default {
        data() {
            return {
                showexport: false,
                scroll: {
                    x: 2000,
                    y: 900
                },
                ewmData: [],
                opration: '',
                townData: [],
                zzData: [],
                xmlxData: [],
                qzLoginid: '',
                qz_ifVisible: false,
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                tableData: [],
                Descypt_ewmData: [],
                loading,
                action,
                previewVisible: false,
                previewImage: '',
                fileList: [],
                strWhere,
                expand: false,
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
                pagination: {
                    total: 0,
                    pageSize: 15, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
                    showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: 'bottom'
                }
            }
        },
        components: {
            //html2canvas,
            VueQr,
        },
        mounted() {
            //将上面的按钮和表格内的按钮分出来
            for (let i = 0; i < this.$route.meta.buttons.length; i++) {
                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                }
                else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.init()
            window.onresize = () => {
                this.init()
            }
            this.bindList();
            this.bindDropDownData();
            //if (this.$User.user.userInfo.role == 820) {
            //    this.qzLoginid = this.$User.user.userInfo.loginid;
            //    this.bind_qzData();
            //    this.formSearch.setFieldsValue({ townId: this.$User.user.userInfo.loginid });
            //    this.qz_ifVisible = true;
            //} else {
            //    this.bind_qzData();
            //}
            this.bind_qzData();
        },
        methods: {
            downloads(did, titles) {
                //全部下载
                //var _this = this;
                //for (let i = 0; i < _this.ewmData.length; i++) {
                //    (function () {
                //        var ii = i;
                //        h_canvas(document.getElementById('d' + ii)).then(canvas => {
                //            let link = document.createElement("a");
                //            document.body.appendChild(link);
                //            link.href = canvas.toDataURL();
                //            link.download = _this.ewmData[i].town + '/' + _this.ewmData[i].zzmc + '/' + _this.ewmData[i].xmlx;
                //            link.style.display = "none";
                //            link.click();
                //        })
                //    })(i)
                //}

                //单个下载
                this.$html2canvas(document.getElementById(did)).then(canvas => {
                    let link = document.createElement("a");
                    document.body.appendChild(link);
                    link.href = canvas.toDataURL();
                    link.download = titles;
                    link.style.display = "none";
                    link.click();
                })
            },
            bindDropDownData() {
                var _this = this;
                this.$axios({
                    url: '/api/Zzgl/GetTown',
                    method: 'get',
                }).then(function (response) { if (response.status === 200) { _this.townData = response.data.data } }).catch(function (error) { console.log(error) })

                this.bind_qzData();

                this.$axios({
                    url: '/api/comon/getDictName?pid=1017',
                    method: 'get',
                }).then(function (response) { if (response.status === 200) { _this.xmlxData = response.data.data } }).catch(function (error) { console.log(error) })
            },
            bind_qzData() {
                var _this = this;
                this.$axios({
                    url: '/api/Zzgl/getZzxx?townId=' + this.qzLoginid,
                    method: 'get',
                }).then(function (response) { if (response.status === 200) { _this.zzData = response.data.data } }).catch(function (error) { console.log(error) })
            },
            select_qz(value) {
                this.qzLoginid = value;
                this.bind_qzData();
            },
            bindList: async function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1
                    this.strWhere = 'del=0 '
                    for (var obj in values) {
                        if (values[obj]) {
                            this.strWhere += " and " + obj + " ='" + values[obj] + "'"
                        }
                    }
                    console.log(this.strWhere)
                    this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
                })
                this.loading = true

                this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere);
                console.log(this.strWhere);
                this.strWhere = this.strWhere.data;
                await this.InitGrid()
            },
            async InitGrid() {
                var _that = this
                _that.loading = true
                _that.Descypt_ewmData = [];//清空二维码内容数组
                this.$axios({
                    url: '/api/Zzgl/GetEwmList?strWhere=' + _that.strWhere,
                    method: 'get',
                }).then(function (response) {
                    console.log(response);
                    _that.loading = false
                    if (response.status === 200) {
                        var data = response.data.data;
                        _that.ewmData = data
                        for (var i = 0; i < data.length; i++) {
                            var json = { "townId": data[i].townId, "zzbh": data[i].zzbh, "xmlx": data[i].xmlx, "xmbh": data[i].bh };
                            var result = _that.$jsc.DesEncrypt(_that, JSON.stringify(json));
                            result.then(res => {
                                _that.Descypt_ewmData.push(res.data);
                            })
                        }
                    }
                }).catch(function (error) {
                    console.log(error)
                })

            },
            formSearchSubmit(e) {
                e.preventDefault()
                this.pagination.current = 1
                this.bindList()
            },
            formSearchReset() {
                this.formSearch.resetFields()
                this.strWhere = 'del=0'
                this.bindList()
            },
            toggle() {
                this.expand = !this.expand
                this.init()
            },
            init() {
                var that = this
                size()
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 270
                        }
                    })
                }
            },
        }

    }
</script>
<style>
    .codecontent {
        display: flex;
        flex-wrap: wrap;
        background: rgba(200,200,169,0.1);
        padding: 16px;
        width: 1100px;
        margin: 0 auto;
    }

        .codecontent .ant-card {
            /* background: #eee; */
            background: white;
        }

        .codecontent .ant-card-head-title {
            text-align: center;
        }

        .codecontent .ant-card-head {
            border-bottom: 1px solid #eee;
        }

        .codecontent > div {
            padding: 16px;
        }

    .ant-card-body {
        padding: 12px;
    }
</style>