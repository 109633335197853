<template>
	<div>
		<div class="top">重要警告:非专业人士请不要随便删除或修改！如需修改请先咨询开发公司。</div>
		<div class="first">
			<a-input placeholder="请输入字典大类" v-model="firstnode"></a-input>
			<a-button type="primary" @click="additem">添加</a-button>
		</div>
		<div>
		    <a-collapse accordion>
		      <!-- <a-collapse-panel v-for="(item,index) in collapseList" :key="index" :header="item.title?item.title:''">
		        
		      </a-collapse-panel> -->
		    </a-collapse>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				firstnode:'',
				collapseList:[]
			}
		},
		methods:{
			additem(){
				this.collapseList.push({title:this.firstnode})
				// console.log(this.firstnode)
			}
		}
	}
</script>

<style>
	.top{
		color: red;
		font-size: 20px;
		height: 50px;
		line-height: 50px;
		border-bottom: 1px solid #eee;
		margin-bottom: 10px;
	}
	.first{
		display: flex;
	}
	.first>input{
		width: 200px;
	}
	.first button{
		margin-left: 10px;
	}
</style>
