<template>
    <div class="rootbox">

        <a-steps :current="step">
            <a-step v-for="item in steps" :key="item.title" >
                <h2 slot="title">{{ item.title }}</h2>
            </a-step>
        </a-steps>


        <div class="steps-content" ref="stepsContent">

            <template v-if="step === 0">
                <div class="steps steps-1">
                    <div class="steps-1-content">
                        <span>请选择工程类型：</span>
                        <a-radio-group v-model="projType">
                            <a-radio value="排涝站">
                                排涝站
                            </a-radio>
                            <a-radio value="防洪闸">
                                防洪闸
                            </a-radio>
                            <a-radio value="堤防">
                                堤防
                            </a-radio>
                        </a-radio-group>
                    </div>
                    <a-button type="primary" @click="clickNext" :disabled="!projType">
                        下一步
                    </a-button>
                </div>
            </template>

            <template v-if="step === 1">
                <div class="steps steps-2">
                    <a-form layout="inline" :form="form">
                        <a-form-item >
                            <a-input v-decorator="['Name']" placeholder="请输入工程名称"></a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-space >
                                <a-button type="primary" icon="search" @click="operForm('search')">搜索</a-button>
                                <a-button icon="redo" @click="operForm('reset')">重置</a-button>
                            </a-space>
                        </a-form-item>
                    </a-form>
                    <a-table
                        size="small"
                        :columns="columns" 
                        :data-source="dataSource" 
                        :rowClassName="rowClassName"
                        :rowKey="record => record.rnn"
                        :pagination="pagination"
                        :row-selection="rowSelection"
                        :customRow="customRow"
                        :scroll="scroll"
                        :loading="loading"
                        @change="changePage">
                    </a-table>

                    <div style="textAlign:center;marginTop:15px;">
                        <a-space>
                            <a-button @click="clickPrev">
                                上一步
                            </a-button>
                            <a-button type="primary" @click="clickNext" :disabled="!proj">
                                下一步
                            </a-button>
                        </a-space>
                    </div>
                </div>
            </template>

            <template v-if="step === 2">
                <div class="steps steps-3">
                    <div class="scroll-area">
                        <a-collapse accordion>
                            <a-collapse-panel 
                                v-for="(item) in question" 
                                :key="item.id" 
                                :header="item.dictName">
                                <a-list item-layout="vertical" :data-source="item.children">
                                    <a-list-item slot="renderItem" slot-scope="record">
                                        <div>{{ record.dictName }}</div>
                                        <a slot="extra" @click.prevent="addRecord(record)">添加巡察记录</a>
                                            <a-card style="width:100%;height:115px;" size="small" v-for="(rc, rci) in record.children" :key="rci">
                                                <div class="text">
                                                    <span>问题描述：{{ rc.info }}</span>
                                                    <span>扣分：{{ rc.kf }}</span>
                                                </div>
                                                <div class="imgs">
                                                    <span>现场照片：</span>
                                                    <a :href="img.url" v-for="(img,rcfi) in rc.files" :key="rcfi">
                                                        <img  :src="img.url" alt="现场照片">
                                                    </a>
                                                </div>
                                                <a-button size="small" icon="delete" type="danger" @click="onDelete(record.children, rci)" />
                                            </a-card>
                                    </a-list-item>
                                </a-list>
                            </a-collapse-panel>
                        </a-collapse>
                    </div>
                    <div class="btn-group">
                        <a-space>
                            <a-button @click="clickPrev">
                                上一步
                            </a-button>
                            <a-button type="primary" @click="onSubmit" :disabled="!proj">
                                提交
                            </a-button>
                        </a-space>
                    </div>
                </div>
            </template>


        </div>

        <!-- 添加问题对话框 -->
        <a-modal v-model="visible" title="添加巡察记录" centered destroyOnClose @ok="onConfirm">
            <a-form :form="quForm" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14 }" v-if="recordSelect">
                <a-form-item label="是否存在问题">
                    <a-switch 
                        v-decorator="['iswt',  { valuePropName: 'checked', initialValue: false }]"  
                        checked-children="有" 
                        un-checked-children="无"
                        @change="checked => hasQu = checked"
                    />
                </a-form-item>

                <a-form-item label="描述">
                    <a-textarea
                    v-decorator="['info', { rules: [{ required: true, message: '请输入问题描述!' }] }]"
                    :rows="4" 
                    style="resize:none;"/>
                </a-form-item>

                <template v-if="hasQu">
                    <a-form-item label="扣分" :extra="recordSelect.dictValue1">
                        <a-input-number v-decorator="['kf', { initialValue: 0 }]" :min="0" :max="+recordSelect.dictValue2" />
                        <span class="ant-form-text">标准分值：{{ recordSelect.dictValue2 }}</span>
                    </a-form-item>
                </template>


                <a-form-item label="问题图片" extra="上传图片不能超过4张">
                    <a-upload
                        v-decorator="[ 'files', { valuePropName: 'fileList', getValueFromEvent: e => e.fileList.slice(0, 4) }]"
                        :action="$axios.defaults.fileURL + '/api/FileSet/Upload'" 
                        multiple
                        list-type="picture">
                        <a-button><a-icon type="upload" /> 点击上传 </a-button>
                    </a-upload>
                </a-form-item>

            </a-form>
        </a-modal>
        

    </div>
</template>

<script>
    import user  from '@/user.js'

    const columns = Object.freeze([
        [
            { title: '工程名称', dataIndex: 'Name', ellipsis: true },
            { title: '监管单位',dataIndex: 'JGCompany', ellipsis: true },
            { title: '村社区',dataIndex: 'Village', ellipsis: true },
            { title: '乡镇',dataIndex: 'Town', ellipsis: true },
            { title: '圩区',dataIndex: 'Polder', ellipsis: true },
            { title: '河道',dataIndex: 'Location',ellipsis: true },
        ],
        [
            { title: '工程名称',dataIndex: 'Name', ellipsis: true },
            { title: '联圩',dataIndex: 'UnitedPolder', ellipsis: true },
            { title: '河湖',dataIndex: 'RLName', ellipsis: true },
            { title: '岸别',dataIndex: 'Side', ellipsis: true }
        ]
    ])

    const DICT_ID = {
        ['排涝站']: 2256,
        ['防洪闸']: 2223,
        ['堤防']: 2222,
    }
    export default {
        data() {
            return {
                step: 0,
                steps:[
                    { title: '工程类型' },
                    { title: '工程项目' },
                    { title: '问题反馈' }
                ],
                // 工程类型
                projType: '',
                // 工程项目
                form: this.$form.createForm(this, { name: 'search' }),
                loading: false,
                columns: [],
                pagination: { 
                    current: 1, 
                    pageSize: 15,
                    total: 0,
                    showTotal: total => `总共${total}条`,
                    scrollToFirstRowOnChange: true
                },
                scroll: { x: true, y: 0 },
                dataSource: [],
                proj: null,
                selectedRowKeys: [],
                // 问题描述
                question: [],
                recordSelect: {},
                visible: false,
                quForm: this.$form.createForm(this, { name: 'qu' }),
                hasQu: false
            }
        },
        computed: {
            rowSelection() {
                return {
                    type: 'radio',
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: (selectedRowKeys, selectedRows) => {
                        this.proj = selectedRows[0]
                        this.selectedRowKeys = selectedRowKeys
                    }
                }
            },
            town () {
                const { town, role } = this.$User.user.userInfo
                console.log(this.$User.user.userInfo);
                return role > 810 ? '' : town
            }
        },
        methods: {
            async clickNext () {
                this.step++

                if (this.step === 1) {
                    this.columns = this.projType === '堤防' ? columns[1] : columns[0]
                    this.getProjData()
                }

                if (this.step === 2) {
                    this.question = await this.getDict(DICT_ID[this.projType])
                }
            },

            clickPrev () {
                this.step--
                this.proj = null
                this.pagination.current = 1
                this.selectedRowKeys = []
            },

            operForm (action) {
                const { isFieldsTouched,  resetFields} = this.form

                if (!isFieldsTouched()) return
                if (action === 'reset') {
                    this.pagination.current = 1
                    resetFields()
                }
                this.getProjData()
            },

            calcScrollY () {
                this.scroll.y = document.body.offsetHeight - 450
            },
            rowClassName (record, index) {
                let className = "light-row"
                if (index % 2 === 1) className = "dark-row"
                return className
            },
            customRow (record) {
                return {
                    on: {
                        click: () => {
                            this.selectedRowKeys = [record.rnn]
                            this.proj = record
                        }
                    }
                }
            },
            changePage (page) {
                this.pagination.current = page.current
                this.getProjData()
            },
            getProjData () {
                this.loading = true
                const { current, pageSize } = this.pagination
                const { getFieldsValue } = this.form

                this.$axios({
                    url: '/api/GetList' + (this.projType === '堤防' ? '/get_v_base_dfxx_gr_List' : '/get_v_base_zzxx_gr_List'),
                    method: 'post',
                    data: {
                        pageIndex: current,
                        pageSize,
                        where: {
                            ...getFieldsValue(),
                            [this.projType === '堤防' ? 'GSD': 'Town']: this.town
                        }
                    }
                })
                    .then(res => {
                        if (res.status !== 200 || res.data.code !== 0) return 

                        const { list, total } = res.data.data
                        this.dataSource = list
                        this.pagination.total = total

                    })
                    .catch(err => err)
                    .finally(() => this.loading = false)
            },

            async getDict (id) {
                return this.$axios({
                    url: '/api/comon/GetDict',
                    method: 'get',
                    params: { id }
                }).then(async res => {
                    let { code, data } = res.data
                    if (code !== 0) return
                    for (const item of data) {
                        let temp = await this.getDict(item.id)
                        if (temp && temp.length !== 0) {
                            item.children = await temp
                        }
                    }
                    return data
                }).catch(err => err)
            },

            addRecord (record) {
                this.visible = true
                this.hasQu = false
                this.recordSelect = record
            },

            onConfirm () {
                this.quForm.validateFields((err, value) => {
                    if (err) return

                    if (!value.iswt) {
                        value.kf = 0
                    }

                    value.iswt = +value.iswt

                    if(value.files) {
                        value.files = value.files.map(file => ({ ...file.response.data }))
                    }
                    
                    if (!this.recordSelect.children) {
                        this.recordSelect.children = []
                    }

                    this.recordSelect.children.push(value)
                    this.visible = false
                    this.$forceUpdate()
                })
            },

            onDelete (qu, i) {
                qu.splice(i, 1)
                this.$forceUpdate()
            },

            onSubmit () {
                let rcxcInfoItem = []
                this.question.forEach(item1 => {
                    for (const item2 of item1.children) {
                        if (!item2.children) continue
                        for (const item3 of item2.children) {
                            rcxcInfoItem.push({
                                ...item3,
                                dictId1: item1.id,
                                dictId2: item2.id,
                                title: item1.dictName
                            })
                        }
                    }
                })
                if (rcxcInfoItem.length === 0) return this.$message.warn('请添加巡察记录~')

                let userInfo = user.user.userInfo

                let data = {
                    gclx: this.projType,
                    xcgc: this.proj.Name,
                    xcgc_x: this.proj.jx,
                    xcgc_y: this.proj.jy,
                    xcgcid: this.proj.BID,
                    xcr: userInfo.realName,
                    xcrDepart: userInfo.departName,
                    xcrLoginid: userInfo.loginid,
                    rcxcInfoItem
                }

                this.$axios({
                    url: '/api/rcxc/rcxcInfoAdd',
                    method: 'post',
                    data
                }).then(res => {
                    if (res.status !== 200 || res.data.code !== 0) return 
                    this.$message.success('提交成功')
                    const { gclx, xccj } = res.data.data
                    const xccjIndex = this._.indexOf(['镇级', '市级'], xccj)
                    const gclxIndex = this._.indexOf(['排涝站', '防洪闸', '堤防'], gclx)
                    this.$router.push(`/gcyxgl/slgcxczgxt/patrolsystem_${xccjIndex}${gclxIndex}`)
                })
            }
        },
        mounted () {
            this.calcScrollY()
            window.addEventListener('resize', this._.debounce(this.calcScrollY, 150))
            this.$once('hook:beforeDestroy', () => window.removeEventListener('resize', this.calcScrollY))
        },
    }
</script>

<style lang="less" scoped>
    .rootbox {
        display: flex;
        flex-direction: column;
        padding: 10px 30px;
        height: 100%;
    }

    .steps-content {
        overflow: hidden;
        flex: 1;
        margin: 20px 0;
        padding: 10px;
        background-color: #FAFAFA;

        .steps {
            height: 100%;
        }

        .steps-1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transform: scale(2);

            &-content {
                transform: scale(1.12);
                margin-bottom: 30px;
                span {
                    font-size: 16px;
                    color: #272727;
                }
            }
        }

        .steps-2 {
            overflow-y: auto;
        }

        .steps-3 {
            position: relative;
            padding-bottom: 60px;

            .scroll-area {
                height: 100%;
                overflow-y: auto;
            }

            /deep/ .ant-card {
                margin-top: 10px;
                .text {
                    display: flex;
                    justify-content: space-between;

                    span:first-child {
                        font-size: 14px;
                        flex-basis: 80%;
                    }
                }

                .imgs {
                    margin-top: 10px;
                    img {
                        width: 60px;
                        height: 60px;
                        vertical-align: top;
                        margin-right: 8px;
                    }
                }

                .ant-btn {
                    position: absolute;
                    right: 12px;
                    bottom: 12px;
                }
            } 

            .btn-group {
                position: absolute;
                left: 50%;
                bottom: 15px;
                transform: translateX(-50%);
            }
        }

    }

    // 隔行变色
    /deep/ .light-row { 
        background-color: #fff;
    }
    /deep/ .dark-row {
        background-color: #fafafa;
    }
</style>