<template>
    <div class="userSelecter">
            <a-modal :title="options.title" :width="options.width"
                     :visible="isopen"
                     @ok="handleOk"
                     @cancel="handleCancel" :bodyStyle="{padding:'0'}">
                <a-layout>
                    <a-layout-header style="background:none; padding:0px 0px 0px 0px;">
                        <a-form layout="inline" :form="form" @submit="handleSubmit" style=" padding-top:15px; text-align:right;">
                            <a-form-item>
                                <a-select default-value="0" style="width: 120px" @change="handleChange">
                                    <a-select-option value="0">
                                        人员级单位
                                    </a-select-option>
                                    <a-select-option value="1">
                                        仅人员
                                    </a-select-option>
                                    <a-select-option value="2">
                                        仅单位
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item style="margin-right:10px;">
                                <a-input-search placeholder="关键字搜索..." enter-button @search="onSearch" style="width:400px;" />
                            </a-form-item>
                        </a-form>

                    </a-layout-header>
                    <a-layout>
                        <a-layout-sider style="background:none;padding:8px;flex:none;max-width:none" :style="{'width': options.leftWidth+'px'}" class="left">
                            <a-card title="对象分类" :bordered="false" style="width: 100%">
                                <div :style="{'height': card_body_height+'px'}">
                                    <a-collapse v-model="activeKey" :accordion="true" :bordered="false">
                                        <a-collapse-panel key="1" header="系统分组">
                                            <div class="userSelecter_tree" :style="{'height': tree_height+'px'}">
                                                <a-tree :tree-data="treeData" :blockNode="true" @select="tree_select" :default-expanded-keys="['00001']" />
                                            </div>
                                        </a-collapse-panel>
                                        <a-collapse-panel key="2" header="自定义分组" :disabled="false">
                                            <div class="userSelecter_tree" :style="{'height': tree_height+'px'}">
                                                <a-tree :tree-data="treeData" :blockNode="true" @select="tree_select" :default-expanded-keys="['00002']" />
                                            </div>
                                        </a-collapse-panel>
                                   
                                    </a-collapse>
                                </div>
                            </a-card>
                        </a-layout-sider>
                        <a-layout-content style="padding:8px 0px 8px 0px;">
                            <a-card title="已选对象" :bordered="false" style="width: 100%">
                                <a slot="extra" @click="content_selectAll()" v-if="!options.single">全选</a>
                                <div :style="{'height': card_body_height+'px'}">
                                    <ul class="userSelecter_content" :style="{'height': card_body_height+'px'}">
                                        <li v-for="(item,index) in userData"><a-button :type="content_getSelectedState(item)" @click="content_clickUser(item)">{{item.text}}</a-button></li>
                                    </ul>
                                </div>
                            </a-card>
                        </a-layout-content>
                        <a-layout-sider style="background:none;padding:8px;">
                            <a-card title="已选对象" :bordered="false" style="width: 100%">
                                <a slot="extra" @click="right_clearAll()">清除</a>
                                <div :style="{'height': card_body_height-14+'px'}">
                                    <ul class="right_content">
                                        <li v-for="(item,index) in userDataSelected" @click="right_clear(item)">{{item.text}}</li>
                                    </ul>
                                </div>
                            </a-card>
                        </a-layout-sider>
                    </a-layout>
                </a-layout>
            </a-modal>
    </div>
</template>
<script>
    export default {
        name: 'userSelecter',
        props: {
            'sured': Function,
            'dataOptions': Object,
        },
        data() {
            return {
                isopen: false,
                activeKey: 1,               
                treeData : [],
                userDataSelected: [],
                userData: [],
                options: {
                    width: this.dataOptions.width ? this.dataOptions.width : 1100,
                    height: this.dataOptions.height ? this.dataOptions.height : 600,
                    leftWidth: this.dataOptions.leftWidth ? this.dataOptions.leftWidth : 300,
                    leftUrl: this.dataOptions.leftUrl,
                    contentUrl: this.dataOptions.contentUrl,
                    title: this.dataOptions.title ? this.dataOptions.title : "对象选择器",
                    single: this.dataOptions.single ? this.dataOptions.single : false,
                    
                },
            }
        },
        mounted() {
            
        },
        computed: {
            card_body_height: function () {
                return this.options.height - 55 - 8 - 48-53-10;
            },
            tree_height: function () {
                return this.options.height - 55 - 8 - 48-53-10-46-46-22;
            }
        },
        created: function () {
            this.$axios({
                url: this.options.leftUrl,
                method: 'get',
            }).then(res => {
                console.log(res);
                this.treeData = res.data.data;
            }).catch(error => { // 请求失败处理
                //this.$message.error(error.message);
                //this.formAddOptions.submitLoading = false
                });

            this.userDataSelected = [{ text: '张浦镇', loginid: '201228104026863957' }]
        },
        methods: {
            open() {
                var _this=this
                this.isopen = true;
                //setTimeout(()=>{
                //    var col = document.getElementsByClassName("userSelecter_tree");
                //    console.log(col);
                //    col.forEach(function (e) {
                //        e.style.height = _this.options.heigth+'px';
                //    });
                //}, 200);
                
                
            },
            handleOk() {
                this.sured(this.userDataSelected);
                this.isopen = false;
            },
            handleCancel() {
                this.isopen = false;
            },
            tree_select(selectedKeys, e) {
                //console.log(e.selectedNodes[0].data.props.dataRef);
                console.log(e.selectedNodes[0].data.props.dataRef);
                let obj = e.selectedNodes[0].data.props.dataRef;
                var strWhere = "";
                for (var key in obj) {
                    if (obj.hasOwnProperty(key) && key !="children"){
                        strWhere += "&" + key + "=" + obj[key] + "";
                    }
                }
                console.log(strWhere);
                this.$axios({
                    url: this.options.contentUrl + "?" + strWhere,
                    method: 'get',
                }).then(res => {
                    this.userData = res.data.data;
                }).catch(error => { // 请求失败处理
                    //this.$message.error(error.message);
                    //this.formAddOptions.submitLoading = false
                });
            },
            content_clickUser(item) {
                if (this.options.single) {
                    this.userDataSelected = [];
                }
                let tempArr = [];
                let iscz = false;
                for (var j = 0; j < this.userDataSelected.length; j++) {
                    if (item.loginid == this.userDataSelected[j].loginid) {
                        iscz = true;
                    }
                    else {
                        tempArr.push(this.userDataSelected[j]);
                    }
                }

                if (iscz) {
                    this.userDataSelected = tempArr;
                }
                else {
                    this.userDataSelected.push(item);
                }

            },
            content_getSelectedState(item) {//获取人员的选中状态
                let reval = "default";
                for (var j = 0; j < this.userDataSelected.length; j++) {
                    if (item.loginid == this.userDataSelected[j].loginid) {
                        reval = "primary";
                        break;
                    }
                }
                console.log(reval);
                return reval;
            },
            content_selectAll() {
                for (var j = 0; j < this.userData.length; j++) {
                    let ifcz = false;
                    for (var i = 0; i < this.userDataSelected.length; i++) {
                        if (this.userData[j].loginid == this.userDataSelected[i].loginid) {
                            ifcz = true;
                        }
                    }
                    if (!ifcz) {
                        this.userDataSelected.push(this.userData[j]);
                    }
                }
            },
            right_clearAll() {
                this.userDataSelected = [];
            },
            right_clear(item) {
                var tempArr = [];
                for (var j = 0; j < this.userDataSelected.length; j++) {
                    if (item.loginid != this.userDataSelected[j].loginid) {
                        tempArr.push(this.userDataSelected[j]);
                    }
                }
                this.userDataSelected = tempArr;
            }
        }
    }
</script>
<style type="text/css">
    .userSelecter_content li {
        display: inline-block;
        margin: 8px;
    }

    .right_content li {
        margin: 8px;
        list-style-type: none;
    }

    .ant-card-head {
        height: 47px;
        /*border:1px solid #cccccc;*/
    }

    .ant-card-head-wrapper {
        height: 47px;
    }

    .ant-card {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
    }

    .ant-card-body {
        overflow: auto;
    }

    .userSelecter_tree {
        /*height: 500px !important;*/
        overflow: auto;
    }

    .ant-card-body {
        padding: 1px 0px 0px 0px;
    }

    /*.ant-modal-body {
        padding: 0px !important;
    }*/
</style>