<template>
    <div id="components-form-demo-advanced-search">

        <div style="text-align:center;">
            <span style="font-size:25px;font-weight:bold;">{{this.$route.query.wzmc}} 出/入库记录</span>
        </div>
        <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
            <!--<a-row :gutter="24">
                <a-col :span="6">
                    <a-form-item label=""><a-input placeholder="检查内容" v-decorator="['info']"></a-input></a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24" v-if="expand">
            </a-row>-->
            <a-row :gutter="[24,12]">
                <a-col :span="12" :style="{ textAlign: 'left' }">
                    <a-button type='normal' @click="back" style='margin-right: 5px'>返回</a-button>
                </a-col>
                <!--<a-col :span="18" :style="{ textAlign: 'right' }">
                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                    </a>
                </a-col>-->
            </a-row>
        </a-form>
        <div class="search-result-list" :style="{ marginTop: '0px' }">
            <a-table :columns="columns"
                     :data-source="tableData"
                     :pagination="pagination"
                     :pageSize="10"
                     :total="50"
                     rowKey="id"
                     :loading="loading"
                     :scroll="scroll"
                     @change="pageCurrentChange"
                     position="bottom"
                     tableLayout="fixed">
                <span slot="action" slot-scope="text, record">
                    <a-button :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right: 5px'>{{button.title}}</a-button>
                </span>
                <span slot="amount" slot-scope="text, record">
                    <span>{{replace_sl(text)}}</span>
                </span>
                <span slot="sj" slot-scope="text, record">
                    <span>{{$jsc.formatDate(text)}}</span>
                </span>
                <span slot="amount" slot-scope="text, record">
                    <span>{{text}}</span>
                </span>
            </a-table>
        </div>

    </div>
</template>
<script>
    const columns = [
        {
            title: '序号',
            dataIndex: 'rownum',
            width: 70,
            key: 'rownum'
        },
        {
            title: '类型',
            dataIndex: 'type',
            width: 100,
            key: 'type',
        },
        {
            title: '数量',
            dataIndex: 'amount',
            width: 100,
            key: 'amount',
            scopedSlots: { customRender: 'amount' }
        },
        {
            title: '地点',
            dataIndex: 'address',
            width: 100,
            key: 'address',
        },
        {
            title: '时间',
            dataIndex: 'sj',
            key: 'sj',
            width: 100,
            scopedSlots: { customRender: 'sj' }
        },
        {
            title: '负责人',
            dataIndex: 'fzr',
            key: 'fzr',
            width: 100,
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 150,
            scopedSlots: { customRender: 'action' }
        }
    ]

    const loading = true
    const strWhere = 'del=0'
    const action = 'add'
    export default {
        data() {
            return {
                showexport: false,
                scroll: {
                },
                opration: '',
                opration2: '',
                townData: [],
                zzData: [],
                xmlxData: [],
                qzLoginid: '',
                teamData: [],
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                dictData:[],
                tableData: [{ title: "123" }, {title:"234"}],
                columns,
                loading,
                action,
                isready_wz: false,
                previewVisible: false,
                previewImage: '',
                fileList: [],
                strWhere,
                expand: false,
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false,
                    dateVisible: false,
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
                pagination: {
                    total: 0,
                    pageSize: 10, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
                    showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: 'bottom'
                }
            }
        },
        mounted() {
            //将上面的按钮和表格内的按钮分出来
            console.log(this.$route.meta.buttons);
            for (let i = 0; i < this.$route.meta.buttons.length; i++) {
                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                }
                else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.init()
            this.actions()
            window.onresize = () => {
                this.init()
            }
            this.bindList();
        },
        created() {
        },
        methods: {
            btnClick(text, item) {
                let _this = this;
                switch (text) {
                    case '新增':
                        this.openDlg()
                        break
                    case "查看":
                        this.openDlg(item, "view");
                        break;
                    case "删除":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定要删除?",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.del(item);
                            }
                        });
                        break;
                }
            },
            bindList: async function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1
                     console.log(values)
                    this.strWhere = 'del=0 '
                    //for (var obj in values) {
                    //    if (values[obj]) {
                    //        if (obj == "title") {
                    //            this.strWhere += " and " + obj + " like '%" + values[obj] + "%'";
                    //        } else {
                    //            this.strWhere += " and " + obj + " ='" + values[obj] + "'";
                    //        }
                    //    }
                    //}
                    this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
                    console.log(this.strWhere);
                })
                this.loading = true

                this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere);
                this.strWhere = this.strWhere.data;
                await this.InitGrid()
            },
            InitGrid: function () {
                var _that = this
                _that.loading = true
                console.log(_that.strWhere);
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'v_fxjc_wzgl_cbRecord',
                        page: _that.pagination.current,
                        limit: _that.pagination.pageSize,
                        filesFields: '',
                        strWhere: _that.strWhere
                    }
                })
                    .then(function (response) {
                        console.log(response);
                        _that.loading = false
                        if (response.status === 200) {
                            _that.tableData = response.data.data
                            _that.pagination.total = response.data.count
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            onRangePickerChange(date, dateString) {
                this.StartTime = dateString[0];
                this.EndTime = dateString[1];
            },
            onShowSizeChange(current, pageSize) {
                console.log(current, pageSize);
                return;
            },
            formSearchSubmit(e) {
                e.preventDefault()
                this.pagination.current = 1
                this.bindList()
            },
            formSearchReset() {
                this.formSearch.resetFields()
                this.strWhere = 'del=0'
                this.bindList()
            },
            toggle() {
                this.expand = !this.expand
                this.init()
            },
            pageCurrentChange(pagination) {
                console.log(pagination);
                //return;
                this.pagination.current = pagination.current
                this.bindList()
            },
            del(record) {
                this.$axios({
                    url: '/api/Comon/deltag',
                    method: 'post',
                    data: {
                        tableName: 'fxjc_wzgl_cbRecord',
                        field: 'id',
                        value: record.id,
                        source: 'web',
                        loginid: ''
                    }
                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info('删除成功');
                            this.bindList();
                        }
                        else {
                            throw new Error("服务器超时");
                        }
                    }
                    else {
                        throw new Error("服务器超时");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                })
            },
            openDlg: async function (record, action) {
                var _this = this;
                this.formAdd.resetFields()
                this.fileList = []
                this.formAddOptions.submitVisible = true
                this.formAddOptions.viewMode = false
                if (record !== undefined) {
                    // 从后台取数据赋值
                    this.formAddOptions.spinning = true
                    var obj = await this.$jsc.DesEncrypt(this, 'id=zhangbiao' + record.id + 'zhangbiao');
                    obj = obj.data;
                    this.$axios
                        .get('/api/GetList/getListByTable?tableName=v_fxjc_wzgl_wzPlan&strWhere=' + obj + '&filesFields=bh')
                        .then((response) => {
                            if (response.status == 200) {
                                if (response.data.code == 0) {
                                    this.formAddOptions.visible = true;
                                    var result = response.data.data;
                                    console.log(response.data);
                                    setTimeout(() => {
                                        //this.formAdd.setFieldsValue({
                                        //    id: result[0].id,
                                        //    title: result[0].title,
                                        //    townId: result[0].townId,
                                        //    jg_unit: result[0].jg_unit.toString(),
                                        //    zxsj: result[0].zxsj.toString(),
                                        //    jg_num: result[0].jg_num,
                                        //    teamId: result[0].teamId,
                                        //    startTime_y: parseInt(result[0].startTime.split('-')[0]),
                                        //    startTime_r: parseInt(result[0].startTime.split('-')[1]),
                                        //    endTime_y: parseInt(result[0].endTime.split('-')[0]),
                                        //    endTime_r: parseInt(result[0].endTime.split('-')[1]),
                                        //});
                                        //this.isOpen = result[0].isOpen == true ? 1 : 0;
                                        this.formAdd.setFieldsValue(result[0])
                                        this.editgetText('a')
                                        this.formAddOptions.spinning = false
                                    }, 100);
                                }
                                else {
                                    this.$message.error(response.data.msg);
                                    this.formAddOptions.spinning = false;
                                }
                            } else {
                                // vue.prototype.$message.info('This is an error message');
                            }
                        })
                        .catch(function (error) { // 请求失败处理
                            console.log(error)
                        })
                    if (action === 'view') { // 预览模式
                        this.formAddOptions.submitVisible = false
                        this.formAddOptions.viewMode = true
                    }
                }
                else {
                    this.formAddOptions.visible = true;
                }
            },
            formAddSubmit() {
                this.formAdd.validateFields((err, values) => {
                    if (!err) {
                        //console.log(values); return;
                        values.townId = this.$User.user.userInfo.townId;
                        this.formAddOptions.submitLoading = true
                        this.$axios({
                            url: '/api/fxjc/InOrOutWzxx',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('保存成功')
                                    this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false
                                    this.formAddOptions.dateVisible = false
                                    this.bindList()
                                } else {
                                    console.log(res);
                                    this.$message.info(res.data.msg)
                                    //throw new Error(res.data.msg);
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })
                    }
                })
            },
            replace_sl(value) {
                return value.toString().replace("-", "");
            },
            filesCancelPreview() {
                this.previewVisible = false
            },
            async filesPreview(file) {
                //console.log(file.ext)
                // if (!file.url && !file.preview) {
                //   file.preview = await getBase64(file.originFileObj)
                // }
                // this.previewImage = file.url || file.preview
                // this.previewVisible = true
                location.href = file.url
            },
            filesChange: function (file) {
                if (file.file.status === 'removed') {
                    this.fileList = file.fileList
                }
            },
            customFilesUpload(data) {
                const formData = new FormData()
                formData.append('file', data.file)
                //formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
                this.saveFile(formData)
            },
            back() {
                window.history.go(-1);
            },
            saveFile(formData) {
                this.$axios(
                    {
                        method: 'post',
                        url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
                        data: formData
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            var num = this.fileList.length
                            this.fileList.push(res.data.data)
                            this.uploadgetText('a', num, res.data);
                        }
                        else {
                            throw new Error("服务器器超时");
                        }
                    })
                    .catch((error) => {
                        this.$message.error(error.message);
                        console.log(error)
                    })
            },
            init() {
                var that = this
                size()
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 270
                        }
                    })
                }
            },
            /**
             * @param {String} classname；upload的类名
             */
            editgetText(classname) {
                this.$nextTick(() => {
                    var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    //console.log(els)
                    for (var i = 0, len = els.length; i < len; i++) {
                        var type = els[i].textContent.split('.')[1]
                        if (type !== 'png' && type !== 'jpg') {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = els[i].textContent
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + type
                            }
                            cel.innerText = temptext
                            spanels[i].prepend(cel)
                        }
                    }
                })
            },
            /**
             * @param {String} classname；upload的类名
             * @param {Number} num；原有的已上传文件数量
             * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
             */
            uploadgetText(classname, num, response) {
                this.$nextTick(() => {
                    //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    var tempNodeList = []
                    for (var i = 0; i < spanels.length - num; i++) {
                        // console.log(spanels[num+i])
                        tempNodeList.push(spanels[num + i])
                        for (var j = 0; j < tempNodeList.length; j++) {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = response.name
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + response.ext
                            }
                            cel.innerText = temptext
                            tempNodeList[j].prepend(cel)
                        }
                    }
                })
            },
            actions() {
                //if (this.inlineButtons.length == 0) {
                //    columns.pop();
                //}
            }
        }
    }

    //function getBase64(file) {
    //    return new Promise((resolve, reject) => {
    //        const reader = new FileReader()
    //        reader.readAsDataURL(file)
    //        reader.onload = () => resolve(reader.result)
    //        reader.onerror = error => reject(error)
    //    })
    //}
</script>
<style>
    .ant-layout-footer {
        display: none;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
        padding: 20px 20px 0;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }
</style>