<template>
	<div>
		<ul class="bzgqcard">
			<li style="background: #1972F8;">
				<div>全市泵站总数</div>
				<div>31<span>个</span></div>
			</li>
			<li style="background: #29A35A;">
				<div>泵机数量</div>
				<div>299<span>个</span></div>
			</li>
			<li style="background: #D68308;">
				<div>装机总容量</div>
				<div>912<span>kw</span></div>
			</li>
			<li style="background: #E70861;">
				<div>抽水流量</div>
				<div>158<span>m<sup>3</sup>/s</span></div>
			</li>
			<li style="background: #29A35A;">
				<div>开机流量</div>
				<div>158<span>m<sup>3</sup>/s</span></div>
			</li>
		</ul>
		<a-row class="bzgqbord">
			<a-col :span="12">
				<a-input-search
				  placeholder="请输入"
				  enter-button="搜索"
				  size="large"
				  @search="onSearch"
				/>
			</a-col>
			<a-col :span="12">
				<a-button size="large" type="primary" icon="plus" style="float: right;">新增排涝站</a-button>
			</a-col>
		</a-row>
		<a-row class="tabBord">
			<a-tabs default-active-key="全市" @change="callback" style="background: #f0f2f5;">
			  <a-tab-pane :key="item" :tab="item" v-for="(item,index) in TabList">
			    <a-row style="margin-right: -20px;">
			    	<a-col :xxl="6" :xl="8" :lg="12" v-for="item in zhanzhaList" :key="index">
			    		<a-card hoverable style="max-width: 390px;margin-bottom: 20px;">
			    		    <div class="cartit">{{item.name}}</div>
			    			<a-row>
			    				<a-col :span="12">
			    					<div class="c1">装机容量</div>
			    					<div class="cg">160kw</div>
			    				</a-col>
			    				<a-col :span="12">
			    					<div class="c1">排水流量</div>
			    					<div class="cb">12.35m<sup>3</sup>/s</div>
			    				</a-col>
			    			</a-row>
			    		</a-card>
			    	</a-col>
			    </a-row>
			  </a-tab-pane>
			</a-tabs>
		</a-row>
	</div>
	
</template>

<script>
	export default{
		data(){
			return{
				TabList:["全市","开发区","张浦镇","花桥经济开发区","高新区","陆家镇","周市镇","锦溪镇","千灯镇","巴城镇","淀山湖"],
				zhanzhaList:[
					{name:"牛桥港站闸"},
					{name:"斜路港站闸"},
					{name:"庙前港站闸"},
					{name:"金家庄北站闸"},
					{name:"小千灯浦站闸"},
					{name:"新开泾站闸"},
					{name:"泖泾港站闸"}
				]
			}
		},
		methods:{
			onSearch(val){
				// console.log(val)
			},
			callback(key) {
			  // console.log(key);
			}
		}
	}
</script>

<style>
	.bzgqcard{
		display: flex;
		justify-content: space-between;
		background: #fff;
		padding: 10px 20px;
	}
	.bzgqcard>li{
		width: 100%;
		flex: 1;
		margin-right: 20px;
		list-style: none;
		border-radius: 5px;
		color: white;
		text-align: center;
		padding: 20px 0;
		font-size: 16px;
	}
	.bzgqcard>li>div:last-child{
		font-size: 25px;
		margin-top: 10px;
	}
	.bzgqcard>li>div:last-child>span{
		font-size: 16px;
	}
	.bzgqcard>li:last-child{
		margin: 0;
	}
	.bzgqbord{
		background: white;
		padding: 20px;
	}
	.bzgqbord .ant-btn-lg{
		padding: 0 30px;
	}
	.tabBord .ant-tabs-bar{
		padding: 0 20px;
		background: #FFFFFF;
	}
	.cartit{margin-bottom: 10px;font-size: 18px;font-weight: bold;}
	.c1{color: #bbb;font-size: 16px;transform: scale(.7) translateX(-25px);}
	.cg,.cb{font-size: 19px;}
	.cg{color: #07B592;}
	.cb{color: #3AACF9;}
</style>
