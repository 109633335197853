<template>
    <router-view></router-view>
</template>
<script>
    export default {
        data() {
            return {
                //msg: "我是leave/test组件",
                //buttons: this.$route.meta.buttons
            }
        },
        created() {
            //this.$router.push('/fxjcsystem/map_fx');
            //console.log(this.$route)
        }
    }
</script>