<template>
    <layout-content-with-drawer :sider-width="498">

        <!-- 侧边栏 -->
        <template slot="sider">
            <!-- 搜索 -->
            <a-form :form="form" layout="inline" class="cond_form">

                <a-row>
                    <a-col :span="8">
                        <a-form-item>
                            <a-input v-decorator="['inspector']" placeholder="巡检人" autocomplete="off"  />
                        </a-form-item>
                    </a-col>

                    <a-col :span="16">
                        <a-form-item>
                            <a-range-picker v-decorator="['date']" showTime style="width:100%;" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <!-- 搜索按钮组 -->
                    <a-col :span="24" class="col_btn_group">
                        <a-space>
                            <a-button type="primary" icon="search" @click="onSearch">搜索</a-button>
                            <a-button icon="redo" @click="onReset">重置</a-button>
                        </a-space>
                    </a-col>
                </a-row>
            </a-form>

            <!-- 列表 -->
            <a-table
                rowKey="id"
                size="small"
                :columns="columns" 
                :data-source="dataSource"
                :row-selection="{
                    onChange: onChangeSelect
                }"
                :pagination = "pagination"
                :scroll="scroll"
                :customRow="customRow"
                @change="onChangePage">
            </a-table>
        </template>

        <!-- 内容 -->
        <template slot="content">
            <div id="map" class="wh-100p"></div>
        </template>

    </layout-content-with-drawer>
</template>

<script>
    import { loadMap } from '@/assets/js/map.js'

    import LayoutContentWithDrawer from '@/components/LayoutContentWithDrawer'

    let columns = [
        {   
            title: '巡检人',
            dataIndex: 'a',
            width: 58
        },
        {
            title: '巡检日期',
            dataIndex: 'b',
            width: 88
        },
        {
            title: '开始时间',
            dataIndex: 'c',
            width: 82
        },
        {
            title: '结束时间',
            dataIndex: 'd',
            width: 82
        },
        {
            title: '公里数',
            dataIndex: 'e',
            width: 58
        },
        {
            title: '事件数',
            dataIndex: 'f',
            width: 58
        }
    ]

    let  dataSource = [
        {
            id: 0,
            a: '123',
            b: '123',
            c: '123',
            d: '123',
            e: '123',
            f: 12
        },
        {
            id: 1,
            a: '123',
            b: '123',
            c: '123',
            d: '123',
            e: '123',
            f: 12
        },
        {
            id: 2,
            a: '123',
            b: '123',
            c: '123',
            d: '123',
            e: '123',
            f: 12
        },
    ]

    export default {
        components: {
            LayoutContentWithDrawer
        },
        data () {
            return {
                // form
                form: this.$form.createForm(this, { name: 'cond' }),
                // table
                columns: Object.freeze(columns),
                dataSource,
                pagination: {
                    total: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条`
                },
                scroll: { x: 560, y: 900 },
            }
        },
        methods: {
            initMap () {
                loadMap().then(([MI, ksSWInitial]) => {
                    let mi = new MI({
                        zoom: 10,
                        center_lng: 120.966666,
                        center_lat: 31.322222,
                        container: "map",
                        maptype: 1,
                        OMKey: "11"
                    })
                    this.mi = mi

                    let xcw = new ksSWInitial({ mi })
                    xcw.initial();  //智慧水利相关功能初始化
                    xcw.setAllBaseSubLayerVisibility(false) //关闭所有水系基础图层
                    xcw.setAllDBSubLayerVisibility(false) //关闭所有业务图层
                    xcw.setBaseWaterSubLayerVisibility('River', true) //打开河道图层，配置为false，则关闭河道图层，第一个参数为指代图层的target参数
                    xcw.setBaseWaterSubLayerVisibility('Lake', true) //打开湖泊图层
                    xcw.setBaseWaterSubLayerVisibility('TownBoundary', true) //打开乡镇界线图层
                    xcw.setBaseWaterSubLayerVisibility('Mask', true) //打开掩膜图层
                    xcw.setBoundary('昆山市')
                })
            },
            onSearch () {
                console.log('搜索')
            },
            onReset () {
                console.log('重置')
            },
            customRow (record) {
                return {
                    on: {
                        click: () => {
                            console.log('表单项', record);
                        }
                    }
                }
            },
            onChangeSelect (selectedRowKeys, selectedRows) {
                console.log(selectedRowKeys, selectedRows)
            },
            onChangePage ({ current }) {
                this.pagination.current = current
            },
        },
        created () {
            this.initMap()
        }
    }
</script>

<style lang="less" scoped>
    .cond_form {

        & /deep/ .ant-form-item {
            display: flex;
            margin-right: 0;
        }

        & /deep/ .ant-form-item-control-wrapper {
            flex: 1;
            padding: 0 3px;
        }

        .col_btn_group {
            margin: 3px 0 15px;
            padding-right: 3px;
            text-align: right;
        }
    }

    // 隔行变色
    /deep/ .light_row { 
        background-color: #fff;
    }
    /deep/ .dark_row {
        background-color: #fafafa;
    }
</style>