<template>
    <div>
        <div class="top">重要警告:非专业人士请不要随便删除或修改！如需修改请先咨询开发公司。</div>
        <div class="first">
            <a-input placeholder="请输入字典大类" v-model="firstnode"></a-input>
            <a-button type="primary" @click="addfirstnode">添加</a-button>
        </div>
        <div class="collapse">
            <a-collapse accordion>
                <a-collapse-panel v-for="(item,index) in collapseList" :key="index" :header="item.dictName?item.dictName:''">
                    <div class="first">
                        <a-button v-for="(nodes,nindex) in item.children" :key="nindex" style="margin-right: 5px;" @click="tolist(nodes)">{{nodes.dictName}}</a-button>
                        <a-input placeholder="请输入字典小类" v-model="secondnode" style="margin-left: 10px;"></a-input>
                        <a-button type="danger" @click="addsecondnode(item.id,index)">添加</a-button>
                    </div>
                </a-collapse-panel>
            </a-collapse>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                firstnode: '',
                secondnode: '',
                collapseList: []
            }
        },
        mounted() {
            this.$axios({
                url: '/api/Xtsz/GetDictClass',
                method: 'get'
            }).then(res => {
                var data = res.data.data
                this.collapseList = data
            })
        },
        methods: {
            addfirstnode() {
                this.getData(0, this.firstnode).then(res => {
                    if (res.data.code == 0) {
                        this.collapseList.push({ dictName: this.firstnode })
                    }
                })
            },
            addsecondnode(id, index) {
                this.getData(id, this.secondnode).then(res => {
                    if (res.data.code == 0) {
                        this.collapseList[index].children ? '' : this.collapseList[index].children = []
                        this.collapseList[index].children.push({ dictName: this.secondnode })
                        this.secondnode = ""
                    }
                })
            },
            tolist(node) {
                console.log(node)
                this.$router.push("/system/dict-list?id=" + node.id)
            },
            getData(pid, dictName) {
                return this.$axios({
                    url: '/api/Xtsz/EditDictClass',
                    method: 'post',
                    data: { dictName, pid }
                })
            }
        }
    }
</script>

<style>
    .top {
        color: red;
        font-size: 20px;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;
    }

    .first {
        display: flex;
    }

        .first > input {
            width: 200px;
        }

        .first button {
            margin-left: 10px;
        }

    .collapse {
        padding-top: 10px;
    }
</style>