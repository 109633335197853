<template>
    <div>
        <a-menu mode="horizontal" id="topmenu">
            <template v-for="topButton in topButtons">
                <a-menu-item :key="topButton.id" @click="btnClick(topButton)">{{topButton.title}}</a-menu-item>
            </template>
        </a-menu>
    </div>
</template>
<script>
    export default {
        //name: 'hdshsytop',
        data() {
            return {
            }
        },
        props: ["topButtons"],
        components: {
        },
        created: function () {
            //console.log(this.topButtons);
        },
        methods: {
            btnClick(item) {
                //switch (value) {
                //    case "地图点位查看":
                //        this.$router.push({ path: "/hdshsy/map_xcry" });
                //        break;
                //}
                //console.log(item);
                //this.$router.push({ path: "/gcyxgl/hdshsy/map" });
                window.open(item.path);
            }
        }
    }
</script>
