<template>
    <div>
        <a-form :form="formSearch" layout="inline" style="marginBottom:15px;">
            <a-form-item>
                <a-input v-decorator="['title']" placeholder="预警名称" />
            </a-form-item>

            <a-form-item>
                <a-select placeholder="发布类型" v-decorator="['fblx']" style="width:174px !important;">
                    <a-select-option value="发布预警">发布预警</a-select-option>
                    <a-select-option value="解除预警">解除预警</a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item>
                <a-select v-decorator="['zhlx']" placeholder="灾害类型" style="width:174px !important;">
                    <a-select-option
                        v-for="item in disasterTypes"
                        :key="item.name"
                        :value="item.value"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item>
                <a-select placeholder="响应等级" v-decorator="['xydj']" style="width:174px !important;">
                    <a-select-option value="I级响应">I级响应</a-select-option>
                    <a-select-option value="II级响应">II级响应</a-select-option>
                    <a-select-option value="III级响应">III级响应</a-select-option>
                    <a-select-option value="IV级响应">IV级响应</a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item style="width:236px !important;">
                <a-range-picker v-decorator="['createTime']" :placeholder="['发布时间 Start', '发布时间 End']" />
            </a-form-item>

            <a-form-item >
                <a-space>
                    <a-button type="primary" icon="search" @click="operForm('search')">搜索</a-button>
                    <a-button @click="operForm('reset')">清空筛选</a-button>
                </a-space>
            </a-form-item>

        </a-form>

        <a-table
            rowKey="rownum"
            :columns="columns" 
            :data-source="dataSource" 
            :loading="loading"
            :scroll="scroll"
            :pagination="pagination"
            @change="changePage">

            <template slot="fblx" slot-scope="text">
                <a-tag :color="text === '发布预警' ? 'volcano' : 'geekblue'">
                    {{ text }}
                </a-tag>
            </template>

            <template slot="yjnr" slot-scope="text">
                <div class="txt-cut" v-html="text"></div>
            </template>

            <template slot="action" slot-scope="text, record">
                <a-button size="small" icon="setting" @click="setTableItem(record)"></a-button>
            </template>

        </a-table>

        <a-modal
            title="预警处置"
            :visible="visible"
            centered
            @ok="confirmSet"
            @cancel="visible = false"
        >

            <a-form :form="formSet" layout="horizontal" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
                <a-form-item  v-show="false">
                    <a-input v-decorator="['bh']"/>
                </a-form-item>

                <a-form-item v-show="false">
                    <a-input v-decorator="['id']" />
                </a-form-item>

                <a-form-item v-show="false">
                    <a-input v-decorator="['lx1', { initialValue: '防汛条例' }]" />
                </a-form-item>

                <a-form-item label="预警名称">
                    <a-input 
                        v-decorator="['title',  { rules: [{ required: true, message: '请填写预警名称' }] }]" 
                        placeholder="请输入预警名称"
                    />
                </a-form-item>

                <a-form-item label="发布类型">
                    <a-select 
                        v-decorator="['fblx', { rules: [{ required: true, message: '请填写发布类型' }] }]"
                        placeholder="请选择发布类型"
                    >
                        <a-select-option value="发布预警">发布预警</a-select-option>
                        <a-select-option value="解除预警">解除预警</a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="灾害类型">
                    <a-select 
                        v-decorator="['zhlx', { rules: [{ required: true, message: '请填写灾害类型' }] }]" 
                        placeholder="请选择灾害类型"
                    >
                        <a-select-option
                            v-for="item in disasterTypes"
                            :key="item.name"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="响应等级">
                    <a-select 
                        v-decorator="['xydj', { rules: [{ required: true, message: '请填写响应等级' }] }]"
                        placeholder="请选择响应等级" 
                    >
                        <a-select-option value="I级响应">I级响应</a-select-option>
                        <a-select-option value="II级响应">II级响应</a-select-option>
                        <a-select-option value="III级响应">III级响应</a-select-option>
                        <a-select-option value="IV级响应">IV级响应</a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="预警内容简要">
                    <a-textarea 
                        v-decorator="['yjnr']" 
                        :rows="4"
                        placeholder="预警内容简要"
                        :maxLength="500"
                    />
                </a-form-item>

            </a-form>
                                <!-- @preview="filesPreview"
                    @change="filesChange"
                    :customRequest="customFilesUpload" -->

            <a-divider orientation="left">附件</a-divider>
            <a-upload
                :action="`${$axios.defaults.fileURL}/api/FileSet/Upload`"
                @change="upload"
                :disabled="uploadFiles.length !== 0"
            >
                <a-button> <a-icon type="upload" />上传</a-button>
            </a-upload>

        </a-modal>

    </div>
</template>

<script>
    import SQLCompiler from '@/assets/js/SQLCompiler.js'
    const columns = Object.freeze([
        {
            title: '序号',
            dataIndex: 'rownum',
            width: 80,
            align: 'center'
        },
        {
            title: '预警名称',
            dataIndex: 'title',
            width: 300,
            ellipsis: true,
            scopedSlots: { customRender: 'title' }
        },
        {
            title: '发布类型',
            dataIndex: 'fblx',
            width: 120,
            align: 'center',
            scopedSlots: { customRender: 'fblx' },
        },
        {
            title: '响应等级',
            dataIndex: 'xydj',
            width: 120,
            align: 'center',
            scopedSlots: { customRender: 'xydj' },
        },
        {
            title: '灾害类型',
            dataIndex: 'zhlx',
            width: 120,
            align: 'center',
            scopedSlots: { customRender: 'zhlx' },
        },
        {
            title: '发布时间',
            dataIndex: 'createTime',
            width: 180,
        },
        {
            title: '预警内容简要',
            dataIndex: 'yjnr',
            width: 240,
            scopedSlots: { customRender: 'yjnr' },
        },
        {
            title: '操作',
            fixed: 'right',
            width: 60,
            scopedSlots: { customRender: 'action' },
        }
    ])

    const SQL_OPT = {
        'title': 'like',
        'fblx': 'value',
        'zhlx': 'value',
        'xydj': 'value',
        'createTime': 'range'
    }
    export default {
        data() {
            return {
                disasterTypes: [],
                formSearch: this.$form.createForm(this, { name: 'formSearch' }),
                
                dataSource: [],
                columns,
                scroll: { 
                    x: '120%', 
                    y: 0,
                    scrollToFirstRowOnChange: true
                },
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                    showTotal: total => `总共${total}条`
                },
                loading: true,

                visible: false,
                formSet: this.$form.createForm(this, { name: 'formSet' }),
                uploadFiles: []
            }
        },
        methods: {
            //获取灾害类型
            getDisasterTypes() {
                this.$axios({
                    url: "api/comon/GetDictName?pid=2150",
                    method: "get",
                })
                    .then(res => {
                        if (res.status !== 200 || res.data.code !== 0) return
                        this.disasterTypes = res.data.data
                    })
                    .catch(err => err)
            },
            operForm (action) {
                const { isFieldsTouched,  resetFields} = this.formSearch
                if (!isFieldsTouched()) return

                if (action === 'reset') {
                    resetFields()
                }
                this.pagination.current = 1
                this.getTableData()
            },
            changePage (page) {
                this.pagination.current = page.current
                this.getTableData()
            },
            formatFieldsValue (fields) {
                if (fields.createTime) {
                    fields.createTime = { 
                        start: fields.createTime[0].format('YYYY-MM-DD'), 
                        end: fields.createTime[1].format('YYYY-MM-DD') + ' 23:59:59'
                    }
                }
                return fields
            },
            calcScrollY () {
                this.scroll.y = document.body.offsetHeight - 342
            },
            async getTableData () {
                this.loading = true
                const { current, pageSize } = this.pagination
                const { getFieldsValue } = this.formSearch
                this.$axios({
                    url: "/api/GetList/getListByTable",
                    method: "get",
                    params: {
                        tableName: "v_fxjc_yjxg_yjxyInfo",
                        page:current,
                        limit: pageSize,
                        strWhere: await SQLCompiler.encrypt(this.formatFieldsValue(getFieldsValue()), SQL_OPT),
                    },
                })
                    .then(res => {
                        if (res.status !== 200 || res.data.code !== 0) return
                        const { count, data } = res.data
                        this.pagination.total = count
                        this.dataSource = data
                    })
                    .catch(err => err)
                    .finally(() => this.loading = false)
            },
            setTableItem (record) {
                this.visible = true
                setTimeout(() => {
                    const { setFieldsValue, getFieldsValue } = this.formSet
                    setFieldsValue(this._.pick(record, this._.keys(getFieldsValue())))
                }, 300)
            },
            upload(info) {
                const { status, response } = info.file
                if (status === 'error') {
                    return this.$message.error(`${info.file.name} file upload failed.`);
                }
                response && this.uploadFiles.push(response.data)
            },
            confirmSet () {
                const { validateFields } = this.formSet
                validateFields((errors, values) => {
                    if (errors) return
                    values.files = this.uploadFiles
                    console.log(values);
                    this.$axios({
                        url: "/api/fxjc/EditYjxgYjxyInfo",
                        method: "post",
                        data: values,
                    })
                        .then(res => {
                            if (res.status !== 200 || res.data.code !== 0) return
                            this.$message.success('保存成功')
                            this.getTableData()
                            this.visible = false
                        })
                        .catch(err => err)
                })
            }
        },
        created () {
            this.getDisasterTypes()
            this.getTableData()
        },
        mounted () {
            this.calcScrollY()
            window.addEventListener('resize', this._.debounce(this.calcScrollY, 150))
            this.$once('hook:beforeDestroy', () => window.removeEventListener('resize', this.calcScrollY))
        },
    }
</script>

<style lang="less" scoped>
    .txt-cut {
        display:-webkit-box;  
        overflow:hidden;  
        text-overflow:ellipsis;  
        -webkit-line-clamp:1;  
        -webkit-box-orient:vertical;
    }
</style>