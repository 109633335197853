<template>
    <div>
        <a-form :form="formSearch" layout="inline" style="marginBottom: 25px;">
            <a-form-item>
                <a-input v-decorator="['username']" placeholder="姓名"></a-input>
            </a-form-item>
            <a-form-item>
                <a-select v-decorator="['sex']" placeholder="性别" style="width: 174px !important;">
                    <a-select-option value="男">男</a-select-option>
                    <a-select-option value="女">女</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item>
                <a-input v-decorator="['loginName']" placeholder="账户"></a-input>
            </a-form-item>
            <a-form-item>
                <a-select v-decorator="['role']" placeholder="角色" style="width: 174px !important;">
                    <a-select-option :value="400">区镇巡查整改人员</a-select-option>
                    <a-select-option :value="500">区镇巡查人员</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item>
                <a-select 
                    v-decorator="['state']" 
                    placeholder="状态"
                    style="width: 174px !important;"
                >
                    <a-select-option :value="0">启用</a-select-option>
                    <a-select-option :value="1">禁用</a-select-option>
                    <a-select-option :value="2">锁定</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item>
                <a-space>
                    <a-button type="primary" @click="operFormSearch('search')">搜索</a-button>
                    <a-button @click="operFormSearch('reset')">重置</a-button>
                </a-space>
            </a-form-item>
        </a-form>

        <a-button 
            type="primary" 
            style="marginBottom: 10px;"
            @click="operTable('add')"
        >
            新增
        </a-button>

        <a-table
            :columns="columns"
            :data-source="dataSource"
            :rowKey="(record, index) => index"
            :scroll="scroll"
            :pagination="pagination"
            @change="({ current }) => { pagination.current=current;getData() }"
        >
            <template slot="state" slot-scope="text">
                <a-tag :color="['green', 'red', 'orange'][text]">
                    {{ ['启用', '禁用', '锁定'][text] }}
                </a-tag>
            </template>
            <template slot="action" slot-scope="text, record">
                <a-space>
                    <a-button icon="edit" @click="operTable('edit', record)" />
                    <a-button type="danger" icon="delete" @click="operTable('del', record)" />
                </a-space>
            </template>
        </a-table>

        <a-modal 
            v-model="visible" 
            title="巡察人员管理"
            centered
            destroyOnClose
            :width="1200"
            @ok="handleOk"
        >
            <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" >
                <a-form-item v-show="false"><a-input v-decorator="['loginid']" /></a-form-item>
                <a-row :gutter="24">
                    <a-col :span="8">
                        <a-form-item label="账号">
                            <a-input 
                                v-decorator="[
                                    'loginName', 
                                    { rules: [{ required: true, message: '请填写账号' }] }
                                ]" 
                                :maxLength="12"
                                placeholder="请输入账号"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="角色">
                            <a-select v-decorator="['role', { rules: [{ required: true, message: '请填写角色' }] }]" placeholder="请选择角色">
                                <a-select-option :value="400">区镇巡查整改人员</a-select-option>
                                <a-select-option :value="500">区镇巡查人员</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="所属单位">
                            <a-select
                                disabled
                                v-decorator="['departLoginid', { initialValue: userInfo.departLoginid }]" 
                                placeholder="请选择所属单位"
                            >
                                <a-select-option :value="userInfo.departLoginid">{{ userInfo.departName }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    
                </a-row>
                <a-row :gutter="24">
                    <a-col :span="8">
                        <a-form-item label="姓名">
                            <a-input 
                                v-decorator="[
                                    'username', 
                                    { rules: [{ required: true, message: '请填写姓名' }]}
                                ]" 
                                :maxLength="25"
                                placeholder="请输入姓名"  
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="性别">
                            <a-radio-group v-decorator="['sex', { initialValue:'男' }]">
                                <a-radio value="男">男</a-radio>
                                <a-radio value="女">女</a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="人员性质">
                            <a-select v-decorator="['ryxz']" placeholder="请选择人员性质">
                                <a-select-option value="公务员">公务员</a-select-option>
                                <a-select-option value="事业编制">事业编制</a-select-option>
                                <a-select-option value="编外定岗人员">编外定岗人员</a-select-option>
                                <a-select-option value="编外警辅人员">编外警辅人员</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    
                </a-row>
                <a-row :gutter="24">
                    <a-col :span="8">
                        <a-form-item label="科室">
                            <a-input v-decorator="['keshi']" placeholder="请输入科室" :maxLength="40" 
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="职级">
                            <a-input v-decorator="['zhiji']" placeholder="请输入职级" :maxLength="40" 
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="职称">
                            <a-input v-decorator="['zc']" placeholder="请输入职称" :maxLength="40" />
                        </a-form-item>
                    </a-col>
                    
                </a-row>
                <a-row :gutter="24">
                    <a-col :span="8">
                        <a-form-item label="职务">
                            <a-input v-decorator="['zw']" placeholder="请输入职务" :maxLength="40" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="第一学历">
                            <a-input v-decorator="['xueli']" placeholder="请输入第一学历" :maxLength="40" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="第二学历">
                            <a-input v-decorator="['xueli2']" placeholder="请输入第二学历" :maxLength="40" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="24">
                    <a-col :span="8">
                        <a-form-item label="政治面貌">
                            <a-select v-decorator="['zzmm']" placeholder="请选择政治面貌">
                                <a-select-option value="中共党员">中共党员</a-select-option>
                                <a-select-option value="中共预备党员">中共预备党员</a-select-option>
                                <a-select-option value="共青团员">共青团员</a-select-option>
                                <a-select-option value="无党派人士">无党派人士</a-select-option>
                                <a-select-option value="群众">群众</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="身份证号">
                            <a-input v-decorator="['idcard']" placeholder="请输入身份证号" :maxLength="40" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="籍贯">
                            <a-input v-decorator="['native']" placeholder="请输入籍贯" :maxLength="40" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="24">
                    <a-col :span="8">
                        <a-form-item label="民族">
                            <a-input v-decorator="['mz']" placeholder="请输入民族" :maxLength="40" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="电子邮箱">
                            <a-input v-decorator="['email']" placeholder="请输入电子邮箱"  :maxLength="40" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="担任现职时间">
                            <a-date-picker v-decorator="['nowworktime']" valueFormat="YYYY-MM-DD" style="width: 100%;" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="24">
                    <a-col :span="8">
                        <a-form-item label="参加工作时间">
                            <a-date-picker v-decorator="['worktime']" valueFormat="YYYY-MM-DD" style="width: 100%;" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="出生日期">
                            <a-date-picker v-decorator="['birthday']" valueFormat="YYYY-MM-DD" style="width: 100%;" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="入党时间">
                            <a-date-picker v-decorator="['dytime']" valueFormat="YYYY-MM-DD" style="width: 100%;" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="24">
                    <a-col :span="8">
                        <a-form-item label="办公电话">
                            <a-input v-decorator="['officeTel']" placeholder="请输入办公电话" :maxLength="40" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="手机号码">
                            <a-input v-decorator="['phone']" placeholder="请输入手机号码" :maxLength="40" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="手机号码2">
                            <a-input v-decorator="['phone2']" placeholder="请输入手机号码2" :maxLength="40" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="24">
                    <a-col :span="8">
                        <a-form-item label="账号状态">
                            <a-radio-group v-decorator="['state', { initialValue: 0 }]">
                                <a-radio :value="0">启用</a-radio>
                                <a-radio :value="1">禁用</a-radio>
                                <a-radio :value="2">锁定</a-radio>
                            </a-radio-group>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item label="头像">
                            <div class="avatar-box" v-if="avatar" @mouseenter="visibleAvatar=true" @mouseleave="visibleAvatar=false">
                                <div class="oper-box" v-show="visibleAvatar">
                                    <a-space>
                                        <a :href="$axios.defaults.fileURL + avatar" target="_blank">
                                            <a-icon type="eye" class="oper-icon" />
                                        </a>
                                        <a-icon type="delete" class="oper-icon" @click="avatar=''" />
                                    </a-space>
                                </div>
                                <img 
                                    :src="$axios.defaults.fileURL + avatar" 
                                    alt="头像" 
                                    class="avatar"
                                    
                                />
                            </div>
                            <a-upload
                                :action="$axios.defaults.fileURL + '/api/FileSet/Upload'"
                                list-type="picture-card"
                                :show-upload-list="false"
                                @change="upload"
                            >
                                <div class="upload-btn" v-if="!avatar">
                                    <a-icon type="plus" />
                                    <div class="ant-upload-text">上传</div>
                                </div>
                            </a-upload>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
        </a-modal>
    </div>
</template>

<script>

    import SQLCompiler from '@/assets/js/SQLCompiler'

    const columns = [
        {
            dataIndex: 'rownum',
            title: '序号',
            width: 80,
            align: 'center'
        },
        {
            dataIndex: 'username',
            title: '姓名',
            width: 120,
            align: 'center'
        },
        {
            dataIndex: 'sex',
            title: '性别',
            width: 80,
            align: 'center'
        },
        {
            dataIndex: 'loginName',
            title: '账号',
            width: 120,
            align: 'center'
        },
        {
            dataIndex: 'roleTxt',
            title: '角色',
            width: 145,
        },
        {
            dataIndex: 'departName',
            title: '所属单位',
            width: 120,
            align: 'center'
        },
        {
            dataIndex: 'state',
            title: '状态',
            width: 100,
            align: 'center',
            scopedSlots: { customRender: 'state' }
        },
        {
            title: '操作',
            width: 100,
            fixed: 'right',
            scopedSlots: { customRender: 'action' },
        }
    ]

    const SQLOption = {
        'username': 'like',
        'sex': 'value',
        'loginName': 'like',
        'role': 'value',
        'state': 'value',
    }

    export default {
        data() {
            return {
                // form
                formSearch: this.$form.createForm(this, { name: 'formSearch' }),
                // table
                columns,
                dataSource: [
                    {
                        a: 1,
                        b: 2,
                        c: 3,
                        d: 4,
                        e: 5
                    }
                ],
                scroll: {
                    y: 0,
                    scrollToFirstRowOnChange: true
                },
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                    showTotal: total => `总共 ${total} 条`
                },
                // modal
                visible: false,
                form: this.$form.createForm(this, { name: 'form' }),
                avatar: '',
                visibleAvatar: false
            }
        },
        computed: {
            userInfo () {
                return this.$User.user.userInfo 
            }
        },
        methods: {
            // form
            operFormSearch (action) {
                const { isFieldsTouched,  resetFields} = this.formSearch

                if (!isFieldsTouched()) return
                if (action === 'reset') {
                    resetFields()
                }
                this.pagination.current = 1
                this.getData()
            },
            // table
            calcScrollY () {
                this.scroll.y = document.body.offsetHeight - 400
            },
            operTable (action, record) {
                if (action === 'add') {
                    this.visible = true
                    this.avatar = ''
                }

                if (action === 'edit') {
                    this.visible = true
                    this.$nextTick(() => {
                        const { getFieldsValue,  setFieldsValue} = this.form
                        setFieldsValue(this._.pick(record, this._.keys(getFieldsValue())))
                        this.avatar = this._.get(record, 'headimg_path')
                    })
                }

                if (action === 'del') {
                    this.$confirm({
                        title: '提示',
                        content: '是否删除',
                        onOk: () => {
                            this.$axios.post('/api/Comon/deltag', {
                                tableName: 'sys_login',
                                field: 'loginid',
                                value: record.loginid,
                                source: 'web',
                            })
                                .then(res => {
                                    if (res.status !== 200 || res.data.code !== 0) return this.$message.error('提交失败')
                                    this.$message.success('提交成功')
                                    this.getData()
                                })
                        },
                    })
                }
            },
            async getData () {
                const prefix = `del=0 and departLoginid='${this.$User.user.userInfo.loginid}'`

                const { current, pageSize } = this.pagination
                return this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'v_sys_userInfo',
                        page: current,
                        limit: pageSize,
                        strWhere: await SQLCompiler.encrypt(this.formSearch.getFieldsValue(), SQLOption, prefix)
                    }
                })
                    .then(res => {
                        if (res.status !== 200 || res.data.code !== 0) return
                        this.pagination.total = res.data.count
                        this.dataSource = res.data.data
                    })
            },
            // modal
            upload ({ file, fileList }) {
                if (file.status === 'removed') {
                    this.avatar = ''
                }
                if (file.status === 'done') {
                    this.avatar = this._.get(this._.first(fileList), 'response.data.path')
                }
            },
            handleOk () {
                this.form.validateFields((err, values) => {
                    if (err) return
                    this._.set(values, 'headimg_path', this.avatar)
                    this.$axios.post('/api/SysSet/EditUserInfo', values)
                        .then(res => {
                            if (res.status !== 200 || res.data.code !== 0) return this.$message.error('提交失败')
                            this.$message.success('提交成功')
                            this.visible = false
                            this.getData()
                        })
                })
            }
        },
        created () {
            this.getData()
        },
        mounted () {
            this.calcScrollY()
            window.addEventListener('resize', this._.debounce(this.calcScrollY, 150))
            this.$once('hook:beforeDestroy', () => window.removeEventListener('resize', this.calcScrollY))
        }
    }
</script>

<style lang="less" scoped>
    .avatar-box {
        position: relative;
        margin-bottom: -15px;
        padding: 8px;
        width:102px;
        height:102px;
        border: 1px solid #d9d9d9;
        .oper-box {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, .5);
            display: flex;
            justify-content: center;
            align-items: center;

            .oper-icon {
                color: #fff;
                font-size: 16px;
                cursor: pointer;
            }
        }
        .avatar {
            width: 100%;
            height: 100%;
            background: #fff;
        }
    }
    .upload-btn  {
        .ant-upload-text {
            margin-top: 8px;
            color: #666;
        }
        i {
            font-size: 32px;
            color: #999;
        }
    }
</style>