<template>
    <div id="components-form-demo-advanced-search">
        <a-modal v-model="formAddOptions.visible" :title="opration" width="800px">
            <a-spin :spinning="formAddOptions.spinning">
                <a-form :form="formAdd" :label-col="{ span:7 }" :wrapper-col="{ span:17 }" labelAlign="left">
                    <a-form-item label="" style="display:none">
                        <a-input v-decorator="['id']" />
                    </a-form-item>
                    <a-row type="flex" justify="space-between">
                        <a-col :span="11">
                            <a-form-item label="区镇">
                                <!--<a-select v-if="qz_ifshow" v-decorator="['townId',{  initialValue:qzLoginid },{ rules: [{ required: true, message: '请选择区镇'}]},]" placeholder="请选择区镇" :disabled="qz_ifshow" style="width:100%">
                    <a-select-option v-for="item in townData" :value="item.value">{{item.name}}</a-select-option>
                </a-select>
                <a-select v-else v-decorator="['townId',{ rules: [{ required: true, message: '请选择区镇'}]},]" placeholder="请选择区镇" :disabled="formAddOptions.viewMode" style="width:100%">
                    <a-select-option v-for="item in townData" :value="item.value">{{item.name}}</a-select-option>
                </a-select>-->
                                <a-select v-decorator="['townId',{  initialValue:qzLoginid },{ rules: [{ required: true, message: '请选择区镇'}]},]" placeholder="请选择区镇" :disabled="formAddOptions.viewMode" style="width:100%" @change="select_qz">
                                    <a-select-option v-for="item in townData" :value="item.value">{{item.name}}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="11">
                            <a-form-item label="堤防">
                                <a-select v-decorator="['zzbh',{  initialValue:zzbh },{ rules: [{ required: true, message: '请选择堤防'}]},]" placeholder="请选择堤防" :disabled="formAddOptions.viewMode" style="width:100%">
                                    <a-select-option v-for="item in dfData" :value="item.value">{{item.name}}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row type="flex" justify="space-between">
                        <a-col :span="11">
                            <a-form-item label="堤防段范围">
                                <a-input v-decorator="['xmlx', { rules: [{ required: true, message: '请输入堤防段范围' }]}]" placeholder="请输入堤防段范围" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :span="11">
                            <a-form-item label="起止位置">
                                <a-input v-decorator="['address', { rules: [{ required: true, message: '请输入起止位置' }]}]" placeholder="请输入堤起止位置" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-between">
                        <a-col :span="11">
                            <a-form-item label="桩号">
                                <a-input v-decorator="['num', { rules: [{ required: true, message: '请输入桩号' }]}]" placeholder="请输入桩号" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :span="11">
                            <a-form-item label="管理责任人">
                                <a-input v-decorator="['zzr', { rules: [{ required: false, message: '请输入管理责任人' }]}]" placeholder="请输入堤管理责任人" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
                            </a-form-item>
                        </a-col>
                    </a-row>



                    <a-row type="flex" justify="space-between">
                        <a-col :span="11">
                            <a-form-item label="经度">
                                <a-input v-decorator="['x']" placeholder="请输入经度" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :span="11">
                            <a-form-item label="纬度">
                                <a-input v-decorator="['y']" placeholder="请输入纬度" :disabled="formAddOptions.viewMode" :maxLength="40"></a-input>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <!--<a-divider orientation="left">附件上传</a-divider>-->
                </a-form>
                <!--<a-upload class='a'
                          :disabled="formAddOptions.viewMode" name="a"
                          :multiple="false"
                          list-type="picture-card"
                          :file-list="fileList"
                          @preview="filesPreview"
                          @change="filesChange"
                          :customRequest="customFilesUpload">
                    <div v-if="fileList.length < 8">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">
                            上传图片
                        </div>
                    </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="filesCancelPreview">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>-->
            </a-spin>
            <template slot="footer">
                <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
                <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
            </template>
        </a-modal>
        <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
            <a-row :gutter="24">
                <a-col :span="8">
                    <a-form-item label="">
                        <!--<a-select v-if="qz_ifshow" v-decorator="['townId',{  initialValue:qzLoginid }]" placeholder="请选择区镇" :disabled="qz_ifshow" style="width:100%">
                <a-select-option v-for="item in townData" :value="item.value">{{item.name}}</a-select-option>
            </a-select>
            <a-select v-else v-decorator="['townId']" placeholder="请选择区镇" :disabled="formAddOptions.viewMode" style="width:100%" @change="select_qz">
                <a-select-option v-for="item in townData" :value="item.value">{{item.name}}</a-select-option>
            </a-select>-->
                        <a-select v-decorator="['townId']" placeholder="请选择区镇" :disabled="formAddOptions.viewMode" style="width:100%" @change="select_qz">
                            <a-select-option v-for="item in townData" :value="item.value">{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="">
                        <a-select v-decorator="['zzbh']" placeholder="请选择堤防" style="width:100%">
                            <a-select-option v-for="item in dfData" :value="item.value">{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="">
                        <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                        <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24" v-if="expand">
            </a-row>
            <a-row :gutter="[24,12]">
                <a-col :span="6" :style="{ textAlign: 'left' }">
                    <a-button type='normal' @click="$router.push({path:'df-list'});" style='margin-right: 5px'>返回</a-button>
                    <a-button type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button>
                </a-col>
                <!--<a-col :span="18" :style="{ textAlign: 'right' }">
                    <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
                    <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
                    <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                        {{ expand ?'收起':'展开' }}<a-icon :type="expand ? 'up' : 'down'" />
                    </a>
                </a-col>-->
            </a-row>
        </a-form>
            <a-table :columns="columns"
                     :data-source="tableData"
                     :pagination="pagination"
                     :pageSize="10"
                     :total="50"
                     rowKey="id"
                     :loading="loading"
                     :scroll="scroll"
                     @change="pageCurrentChange"
                     position="bottom"
                     tableLayout="fixed">
                <span slot="action" slot-scope="text, record">
                    <a-button size="small" key="检查项" @click="jump_xmgl_list(record)" style='margin-right: 5px' type="primary">检查项查看</a-button>
                    <a-button :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right: 5px'><a-icon :type="button.iconLink" /></a-button>
                </span>
                <span slot="jcxsl" slot-scope="text, record">
                    <a-tag color="green" style="width:50px;text-align:center;cursor:pointer;" @click="jump_xmgl_list(record)">
                        {{text}}&nbsp;项
                    </a-tag>
                    <!--<a-button type='primary' size="small" style='margin-right: 5px' @click="jump_xmgl_list(record)">{{text}}项</a-button>-->
                    <!--<router-link :to="{ name: 'xmgl-list', params: { townId:record.townId, zzbh:record.zzbh, xmlx: record.xmlx }}">{{text}}项</router-link>-->
                </span>
                <a slot="xmlx" slot-scope="text,record" @click="views(record)">{{ text }}</a>
                <a slot="pdcs" slot-scope="text,record" @click="f_pdcs(record.bh)">{{record.pdcs}}</a>
            </a-table>

        <a-modal v-model="view_fields.viewModel" title="检查项查看" width="60%">
            <div>
                <a-card :title="view_fields.title" style="width: 100%">
                    <!--<a slot="jcxsl" slot-scope="text, record" @click="jump_xmgl_list(record)">更多</a>-->
                    <p v-for="(item,index) in view_fields.data">
                        {{index+1}}、{{item.info}}<br />
                        <a v-for="item2 in item.files_bh" :href="item2.url" target="_blank"><img style="width:100px;height:100px;margin-left:10px;" :src="item2.url" /></a>
                    </p>
                </a-card>
            </div>
            <template slot="footer">
                <a-button key="back" @click="view_fields.viewModel=false">关闭</a-button>
            </template>
        </a-modal>
    </div>
</template>
<script>
    const columns = [
        {
            title: '序号',
            dataIndex: 'rn',
            width: 60,
            key: 'rn'
        },
        {
            title: '堤防段范围 ',
            dataIndex: 'xmlx',
            width: 150,
            key: 'xmlx',
            scopedSlots: { customRender: 'xmlx' }
        },
        {
            title: '起止位置 ',
            dataIndex: 'address',
            width: 100,
            key: 'address',
        },
        {
            title: '桩号',
            dataIndex: 'num',
            width: 100,
            key: 'num',
        },
        {
            title: '管理责任人 ',
            dataIndex: 'zzr',
            width: 100,
            key: 'zzr',
        },
        {
            title: '检查项数量',
            dataIndex: 'jcxsl',
            width: 100,
            key: 'jcxsl',
            scopedSlots: { customRender: 'jcxsl' }
        },
        {
            title: '所属堤防',
            dataIndex: 'zzmc',
            width: 100,
            key: 'zzmc'
        },
        {
            title: '所属区镇',
            dataIndex: 'town',
            key: 'town',
            width: 100,
            scopedSlots: { customRender: 'town' }
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 200,
            scopedSlots: { customRender: 'action' }
        }
    ]

    const loading = true
    const strWhere = 'del=0'
    const action = 'add'
    export default {
        data() {
            return {
                showexport: false,
                scroll: {
                    x: 900,
                    y: 900
                },
                view_fields: {
                    viewModel: false,
                    title: '检查项',
                    data: []
                },
                opration: '',
                townData: [],
                dfData: [],
                xmlxData: [],
                qz_ifshow: false,
                qzLoginid: this.$route.query.townId,
                zzbh: this.$route.query.zzbh,
                strWhere2: '',
                //actionList: {
                //    add: false,
                //    remove: false,
                //    edit: false
                //},
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                tableData: [],
                columns,
                loading,
                action,
                previewVisible: false,
                previewImage: '',
                fileList: [],
                strWhere,
                expand: false,
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
                pagination: {
                    total: 0,
                    pageSize: 10, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
                    showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: 'bottom'
                }
            }
        },
        mounted() {
            //将上面的按钮和表格内的按钮分出来
            var _this = this;
            console.log(this.$route.meta.buttons);
            for (let i = 0; i < this.$route.meta.buttons.length; i++) {
                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                }
                else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.init()
            this.actions()
            window.onresize = () => {
                this.init()
            }
            this.bindList();
            this.bindDropDownData();
            if (this.$route.query.townId) {
                this.bind_zzxx(this.$route.query.townId);
                _this.formSearch.setFieldsValue({ townId: _this.$route.query.townId, zzbh: _this.$route.query.zzbh, })
            } else {
                this.bind_zzxx("");
            }
        },
        created() {
        },
        methods: {
            f_pdcs(value) {
                this.$router.push("/hdshsy/workSingle-List?isEnd=0&xmbh=" + value);
            },
            select_qz(value) {
                this.formSearch.setFieldsValue({ zzbh: "请选择堤防", })
                this.bind_zzxx(value);
            },
            bindDropDownData() {
                var _this = this;
                this.$axios({
                    url: '/api/Zzgl/GetTown',
                    method: 'get',
                }).then(function (response) { if (response.status === 200) { _this.townData = response.data.data } }).catch(function (error) { console.log(error) })

                //this.$axios({
                //    url: '/api/comon/getDictName?pid=1017',
                //    method: 'get',
                //}).then(function (response) { if (response.status === 200) { _this.xmlxData = response.data.data } }).catch(function (error) { console.log(error) })

            },
            bind_zzxx(value) {
                var _this = this;
                this.$axios({
                    url: '/api/Zzgl/getDfxx?townId=' + value,
                    method: 'get',
                }).then(function (response) { if (response.status === 200) { _this.dfData = response.data.data } }).catch(function (error) { console.log(error) })
            },
            views: async function (value) {
                this.view_fields.viewModel = true;
                this.view_fields.title = value.town + "/" + value.zzmc + "/" + value.xmlx;
                this.view_fields.data = [];
                this.strWhere2 = "del=0 and xmbh='" + value.bh + "'"
                this.strWhere2 = this.strWhere2.replace(/'/g, 'zhangbiao');
                this.strWhere2 = await this.$jsc.DesEncrypt(this, this.strWhere2);
                this.strWhere2 = this.strWhere2.data;
                await this.load_viewData();
            },
            load_viewData: function () {
                var _that = this
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'v_zzgl_zzxx_xmInfo',
                        page: 1,
                        limit: 1000,
                        filesFields: 'bh',
                        strWhere: _that.strWhere2
                    }
                }).then(function (response) {
                    console.log(response.data.data);
                    if (response.status === 200) {
                        _that.view_fields.data = response.data.data
                    }
                }).catch(function (error) {
                    console.log(error)
                })
            },
            jump_xmgl_list(value) {
                this.$router.push({ path: 'dfdw-list?townId=' + value.townId + '&zzbh=' + value.zzbh + '&xmlx=' + value.xmlx + '&xmbh=' + value.bh + '&town=' + value.town + '&zzmc=' + value.zzmc });
            },
            btnClick(text, item) {
                let _this = this;
                this.opration = text;
                switch (text) {
                    case '新增':
                        this.openDlg()
                        break
                    case "编辑":
                        this.openDlg(item);
                        break;
                    case "查看":
                        this.openDlg(item, "view");
                        break;
                    case "删除":
                        this.$confirm({
                            title: '操作确认',
                            content: "确定要删除 " + item.xmlx + " 吗?",
                            okText: '确认',
                            cancelText: '取消',
                            onOk() {
                                _this.del(item);
                            }
                        });
                        break;
                }
            },
            bindList: async function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1
                    // console.log(values)
                    this.strWhere = 'del=0 '
                    if (this.$route.query.zzbh) {
                        this.strWhere += "and zzbh='" + this.$route.query.zzbh + "'";
                    }
                    for (var obj in values) {
                        if (values[obj]) {
                            if (obj == "info") {
                                this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
                            } else {
                                this.strWhere += " and " + obj + "='" + values[obj] + "'"
                            }

                        }
                    }
                    this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
                    console.log(this.strWhere);
                })
                this.loading = true

                this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere);
                this.strWhere = this.strWhere.data;
                await this.InitGrid()
            },
            InitGrid: function () {
                var _that = this
                _that.loading = true
                console.log(_that.strWhere);
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'v_zzgl_zzxx_xmfl',
                        page: _that.pagination.current,
                        limit: _that.pagination.pageSize,
                        filesFields: '',
                        strWhere: _that.strWhere
                    }
                })
                    .then(function (response) {
                        console.log(response)
                        _that.loading = false
                        if (response.status === 200) {
                            _that.tableData = response.data.data
                            _that.pagination.total = response.data.count
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            onShowSizeChange(current, pageSize) {
                console.log(current, pageSize);
                return;
            },
            formSearchSubmit(e) {
                e.preventDefault()
                this.pagination.current = 1
                this.bindList()
            },
            formSearchReset() {
                this.formSearch.resetFields()
                this.strWhere = 'del=0'
                this.bindList()
            },
            toggle() {
                this.expand = !this.expand
                this.init()
            },
            pageCurrentChange(pagination) {
                console.log(pagination);
                //return;
                this.pagination.current = pagination.current
                this.bindList()
            },
            del(record) {
                this.$axios({
                    url: '/api/Comon/deltag',
                    method: 'post',
                    data: {
                        tableName: 'zzgl_zzxx_xmfl',
                        field: 'id',
                        value: record.id,
                        source: 'web',
                        loginid: ''
                    }
                }).then(res => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info('删除成功');
                            this.bindList();
                        }
                        else {
                            throw new Error("服务器超时");
                        }
                    }
                    else {
                        throw new Error("服务器超时");
                    }
                }).catch(error => { // 请求失败处理
                    this.$message.error(error.message);
                })
            },
            openDlg: async function (record, action) {
                this.formAdd.resetFields()
                this.fileList = []
                this.formAddOptions.submitVisible = true
                this.formAddOptions.viewMode = false
                if (record !== undefined) {
                    // 从后台取数据赋值
                    this.formAddOptions.spinning = true
                    var obj = await this.$jsc.DesEncrypt(this, 'id=zhangbiao' + record.id + 'zhangbiao');
                    obj = obj.data;
                    this.$axios
                        .get('/api/GetList/getListByTable?tableName=v_zzgl_zzxx_xmfl&strWhere=' + obj + '&filesFields=bh')
                        .then((response) => {
                            if (response.status == 200) {
                                if (response.data.code == 0) {
                                    this.formAddOptions.visible = true;
                                    var result = response.data.data;
                                    console.log(response.data);
                                    setTimeout(() => {
                                        result[0].yyqk = true
                                        // var objArr = { qymc: result[0].qymc, lxr: result[0].lxr, yyqk: true }
                                        // this.formAdd.setFieldsValue(objArr)
                                        this.formAdd.setFieldsValue(result[0])
                                        //this.fileList = result[0].files_bh
                                        this.editgetText('a')
                                        this.formAddOptions.spinning = false
                                    }, 100);
                                }
                                else {
                                    this.$message.error(response.data.msg);
                                    this.formAddOptions.spinning = false;
                                }
                            } else {
                                // vue.prototype.$message.info('This is an error message');
                            }
                        })
                        .catch(function (error) { // 请求失败处理
                            console.log(error)
                        })
                    if (action === 'view') { // 预览模式
                        this.formAddOptions.submitVisible = false
                        this.formAddOptions.viewMode = true
                    }
                }
                else {
                    this.formAddOptions.visible = true;
                }
            },
            formAddSubmit() {
                this.formAdd.validateFields((err, values) => {
                    if (!err) {
                        console.log(values);
                        values.files_bh = this.fileList
                        this.formAddOptions.submitLoading = true
                        this.$axios({
                            url: '/api/Zzgl/EditXmfl',
                            method: 'post',
                            data: values
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code === 0) {
                                    this.$message.info('保存成功')
                                    this.formAddOptions.visible = false
                                    this.formAddOptions.submitLoading = false
                                    this.bindList()
                                } else {
                                    console.log(res);
                                    throw new Error("服务器超时2");
                                }
                            }
                            else {
                                throw new Error("服务器超时2");
                            }
                        }).catch(error => { // 请求失败处理
                            this.$message.error(error.message);
                            this.formAddOptions.submitLoading = false
                        })

                    }
                })
            },

            filesCancelPreview() {
                this.previewVisible = false
            },
            async filesPreview(file) {
                //console.log(file.ext)
                // if (!file.url && !file.preview) {
                //   file.preview = await getBase64(file.originFileObj)
                // }
                // this.previewImage = file.url || file.preview
                // this.previewVisible = true
                location.href = file.url
            },
            filesChange: function (file) {
                if (file.file.status === 'removed') {
                    this.fileList = file.fileList
                }
            },
            customFilesUpload(data) {
                const formData = new FormData()
                formData.append('file', data.file)
                //formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
                this.saveFile(formData)
            },
            saveFile(formData) {
                this.$axios(
                    {
                        method: 'post',
                        url: 'http://192.168.0.220:8002/api/FileSet/Upload',
                        data: formData
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            var num = this.fileList.length
                            this.fileList.push(res.data.data)
                            this.uploadgetText('a', num, res.data);
                        }
                        else {
                            throw new Error("服务器器超时");
                        }
                    })
                    .catch((error) => {
                        this.$message.error(error.message);
                        console.log(error)
                    })
            },
            init() {
                var that = this
                size()
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 270
                        }
                    })
                }
            },
            /**
             * @param {String} classname；upload的类名
             */
            editgetText(classname) {
                this.$nextTick(() => {
                    var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    //console.log(els)
                    for (var i = 0, len = els.length; i < len; i++) {
                        var type = els[i].textContent.split('.')[1]
                        if (type !== 'png' && type !== 'jpg') {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = els[i].textContent
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + type
                            }
                            cel.innerText = temptext
                            spanels[i].prepend(cel)
                        }
                    }
                })
            },
            /**
             * @param {String} classname；upload的类名
             * @param {Number} num；原有的已上传文件数量
             * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
             */
            uploadgetText(classname, num, response) {
                this.$nextTick(() => {
                    //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    var tempNodeList = []
                    for (var i = 0; i < spanels.length - num; i++) {
                        // console.log(spanels[num+i])
                        tempNodeList.push(spanels[num + i])
                        for (var j = 0; j < tempNodeList.length; j++) {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = response.name
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + response.ext
                            }
                            cel.innerText = temptext
                            tempNodeList[j].prepend(cel)
                        }
                    }
                })
            },
            actions() {
                if (this.inlineButtons.length == 0) {
                    columns.pop();
                }
            }
        }
    }

    //function getBase64(file) {
    //    return new Promise((resolve, reject) => {
    //        const reader = new FileReader()
    //        reader.readAsDataURL(file)
    //        reader.onload = () => resolve(reader.result)
    //        reader.onerror = error => reject(error)
    //    })
    //}
</script>
<style>
    .ant-layout-footer {
        display: none;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
        padding: 20px 20px 0;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }
</style>