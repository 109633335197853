<template>
    <div style="width:100%; height:100%;">
        
        <h1 v-if="aaa">防汛系统首页</h1>
        <a  @click="pathTo('/database/db1')">子模块1</a>
        <router-view>
        </router-view>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                aaa: true
            }
        },
        created: function () { },
        methods: {
            pathTo(path) {
                this.aaa = false;
                this.$router.push(path)
            }
        }
    }
    </script>