import { render, staticRenderFns } from "./dict-list.vue?vue&type=template&id=31cd65f8&"
import script from "./dict-list.vue?vue&type=script&lang=js&"
export * from "./dict-list.vue?vue&type=script&lang=js&"
import style0 from "./dict-list.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports