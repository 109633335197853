<template>
    <div id="components-form-demo-advanced-search">
        <h1 style="font-size:24px; text-align:center;">{{bigtitle[TableListSearchModel.where.kind-1]}}</h1>
        <a-form :form="formSearch"
                @submit="formSearchSubmit"
                :label-col="{ span: 0 }"
                :wrapper-col="{ span: 24 }">
            <a-row :gutter="24">
                <a-col :span="6">
                    <a-form-item label="">
                        <a-input placeholder="检查站闸" v-decorator="['zzmc']" />
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label="">
                        <a-range-picker :placeholder="['检查开始时间','检查结束时间']" v-decorator="['createTime']" style="width:100%" />
                    </a-form-item>
                </a-col>
                <a-col :span="6">
                    <a-form-item label="">
                        <a-select placeholder="检查类型" v-decorator="['kind']">
                            <a-select-option value="1">经常检查</a-select-option>
                            <a-select-option value="2">定期检查</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="6" style="padding-top:4px;">
                    <a-button type="primary"
                              :style="{ width: '48px' }"
                              html-type="submit"
                              icon="search"></a-button>
                    <a-button type=""
                              :style="{ width: '48px', marginLeft: '8px' }"
                              @click="formSearchReset"
                              icon="reload"></a-button>
                </a-col>
                
            </a-row>
            <a-button type='primary' @click="btnClick('导出')" style='margin-right: 5px'>导出</a-button>
        </a-form>
        

        <div class="search-result-list" :style="{ marginTop: '0px' }">
            <a-table :columns="columns"
                     :data-source="tableData"
                     :pagination="pagination"
                     :pageSize="10"
                     :total="50"
                     rowKey="id"
                     :loading="loading"
                     :scroll="scroll"
                     @change="pageCurrentChange"
                     position="bottom"
                     tableLayout="fixed">

                <span slot="kindTxt" slot-scope="text, record">
                    <span v-if="text==1">经常检查</span>
                    <span v-if="text==2">定期检查</span>
                </span>
                <span slot="isErrorTxt" slot-scope="text, record">
                    <span v-if="text==false">无</span>
                    <span v-if="text==true">有</span>
                </span>
                <span slot="zzmcTxt" slot-scope="text, record">
                    <a @click="lookproject(record)">{{text}}</a>
                </span>
                <span slot="action" slot-scope="text, record">
                    <a-button type="primary" size="small" style='margin:0 5px' @click='lookproject(record)'>查看详情</a-button>
                    <a-button v-if="role>10" type='primary' title="编辑" size="small" key="编辑" @click="btnClick('编辑',record)" style='margin-right: 5px'><a-icon type="edit" /></a-button>
                    <a-button v-if="role>10" type='danger' title="删除" size="small" key="删除" @click="btnClick('删除',record)" style='margin-right: 5px'><a-icon type="delete" /></a-button>
                </span>
            </a-table>
        </div>

    </div>
</template>
<script>
    import moment from 'moment';
    const loading = true;
    const action = "add";
    export default {
        data() {
            return {
                role:0,
                monthFormat: 'YYYY/MM',
                showexport: false,
                scroll: {
                    x: 800,
                },
                
                check_ywba: true,
                opration: "",
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                tableData: [],
                bigtitle: ['站闸经常检查表', '站闸定期检查表'],
                columns: [
                    {
                        title: "序号",
                        dataIndex: "rnn",
                        width: 70,
                        key: "rnn",
                    },
                    {
                        title: "检查站闸",
                        dataIndex: "zzmc",
                        width: 200,
                        key: "zzmc",
                        scopedSlots: { customRender: "zzmcTxt" },
                    },
                    {
                        title: "记录人",
                        dataIndex: "createUser",
                        width: 150,
                        key: "createUser",

                    },
                    {
                        title: "检查时间",
                        dataIndex: "createTime",
                        width: 200,
                        key: "createTime",
                    },

                    {
                        title: "检查类型",
                        dataIndex: "kind",
                        width: 150,
                        key: "kind",
                        scopedSlots: { customRender: "kindTxt" },
                    },
                    {
                        title: "检查项数",
                        dataIndex: "checkCount",
                        width: 150,
                        key: "checkCount",
                    },
                    {
                        title: "有无问题",
                        dataIndex: "isError",
                        width: 150,
                        key: "isError",
                        scopedSlots: { customRender: "isErrorTxt" },
                    },
                    {
                        title: "操作",
                        key: "action",
                        fixed: "right",
                        width: 200,
                        scopedSlots: { customRender: "action" },
                    },
                ],
                loading,
                action,
                TableListSearchModel: {
                    pageIndex: 1,
                    pageSize: 10,
                    fields: "*",
                    where: {
                    }
                },
                queryParam: {
                    kind: this.$route.query.kind
                },
                expand: false,
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: "advanced_search" }),
                pagination: {
                    total: 0,
                    pageSize: 10, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "50", "100"], // 每页中显示的数据
                    showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: "bottom",
                },
                backData: {
                    currentPageIndex: 1,
                    searchData: {},
                },
            };
        },
        mounted() {
            this.formSearch.setFieldsValue({ kind: this.queryParam.kind });
            var obj = this.getCallData();
            console.log(obj);
            this.formSearch.setFieldsValue(obj.searchData);
            this.pagination.current = obj.currentPageIndex;
            this.TableListSearchModel.pageIndex = obj.currentPageIndex;
            this.bindList();
            var user=localStorage.getItem("userInfo");
console.log('user',user);
  var usjson=JSON.parse(user)
    this.role=usjson.role;
        },
        watch: {
            '$route.query.kind'(newVal, oldVal) {
                localStorage.setItem("backData", "{\"currentPageIndex\":1}");
                this.formSearch.resetFields();
                this.formSearch.setFieldsValue({ "kind": newVal });
                this.TableListSearchModel.pageIndex = 1;
                this.pagination.current = 1;
                this.bindList();
            },
            '$route'(to, from) {
                
            }
        },
        components: {
        },
        created() {
            //this.TableListSearchModel.where.kind = this.$route.query.kind;
            //将上面的按钮和表格内的按钮分出来

            

            for (let i = 0; i < this.$route.meta.buttons.length; i++) {
                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                } else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.init();
            this.actions();
            
            window.onresize = () => {
                this.init();
            };

            
        },
        methods: {
            getCallData: function () {
                console.log(localStorage.getItem("backData"));
                var reval;
                try {

                    reval = JSON.parse(localStorage.getItem("backData"));
                    if (reval.currentPageIndex == undefined) {
                        reval.currentPageIndex = 1;
                    }
                }
                catch (ex) {
                    console.log(ex);
                    reval = { currentPageIndex: 1 };
                }
                return reval;

            },
            dc() {
                this.$axios({
                    url: "/api/GetList/dc_dfc_selfxc_xczz_List?fileName=" + this.bigtitle[this.TableListSearchModel.where.kind - 1],
                    method: 'post',
                    data: this.TableListSearchModel.where
                }).then((response)=> {
                    if (response.status === 200) {
                        console.log(response.data);
                        let link = document.createElement("a");
                        document.body.appendChild(link);
                        link.href = response.data.data;
                        link.download = this.bigtitle[this.TableListSearchModel.where.kind - 1];
                        link.style.display = "none";
                        link.click();
                    }
                }).catch(function (error) { console.log(error) })
            },
            setCallData: function () {
                this.backData.currentPageIndex = this.pagination.current;
                this.backData.searchData = this.formSearch.getFieldsValue();
                localStorage.setItem("backData", JSON.stringify(this.backData));
                
            },
            lookproject: function (record) {
                this.$router.push("zzcheck?bh="+record.bh+"&action=view");
            },
            moment,
            bindList: function () {
                
                this.formSearch.validateFields((error, values) => {

                    error = 1;
                    this.TableListSearchModel.where = {};
                    for (var obj in values) {
                        if (values[obj]) {
                            if (obj == "createTime") {
                                this.TableListSearchModel.where[obj] = moment(values[obj][0]).format("L") + "-" + moment(values[obj][1]).format("L");
                            } else {
                                this.TableListSearchModel.where[obj] = values[obj];
                            }
                        }
                    }
                    //console.log(this.TableListSearchModel);
                });
                this.loading = true;
                this.InitGrid();
            },
            InitGrid: function () {
                this.loading = true;
                this.$axios({
                    url: "/api/GetList/get_v_dfc_selfxc_xczz_List",
                    method: "post",
                    data: this.TableListSearchModel,

                }).then((res) => {
                    //console.log(res);
                    this.loading = false;
                    if (res.status === 200) {
                        this.tableData = res.data.data.list;
                        this.pagination.total = res.data.data.total;
                        
                        
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            btnClick(text, item) {
                let _this = this;
                switch (text) {
                    case "查看":
                        this.views(item);
                        break;
                    case "编辑":
                        this.$router.push({ path: "zzcheck?bh=" + item.bh+"&kind="+item.kind });
                        break;
                    case "删除":
                        this.$confirm({
                            title: '操作确认',
                            content: "确认要真的删除吗?",
                            okText: '是',
                            cancelText: '否',
                            onOk() {
                                _this.del(item);
                            }
                        });
                        break;
                    case "导出":
                        this.$confirm({
                            title: '操作确认',
                            content: "是否导出?",
                            okText: '是',
                            cancelText: '否',
                            onOk() {
                                _this.dc();
                            }
                        });
                        break;
                }
            },
            formSearchSubmit(e) {
                console.log(e);
                e.preventDefault();
                this.pagination.current = 1;
                this.TableListSearchModel.pageIndex = 1;
                this.backData.currentPageIndex = 1;
                this.bindList();
                this.setCallData();
            },
            formSearchReset() {
                this.formSearch.resetFields();
                this.bindList();
            },
            toggle() {
                this.expand = !this.expand;
                this.init();
            },
            pageCurrentChange(pagination) {
                this.pagination.current = pagination.current;
                this.TableListSearchModel.pageIndex = pagination.current;
                this.bindList();
                this.setCallData();
                
            },
            del(record) {
                this.$axios({
                    url: "/api/Comon/deltag",
                    method: "post",
                    data: {
                        tableName: "dfc_selfxc_xczz",
                        field: "bh",
                        value: record.bh,
                        source: "web",
                        loginid: "",
                    },
                }).then((res) => {
                    if (res.status == 200) {
                        if (res.data.code == 0) {
                            this.$message.info("删除成功");
                            this.bindList();
                        } else {
                            throw new Error("服务器超时");
                        }
                    } else {
                        throw new Error("服务器超时");
                    }
                }).catch((error) => {
                    // 请求失败处理
                    this.$message.error(error.message);
                });
            },

            init() {
                var that = this;
                size();
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight;
                    that.$nextTick(() => {
                        var el = document.querySelector(
                            "#components-form-demo-advanced-search>form"
                        );
                        if (el) {
                            that.scroll.y =
                                innerHeight - getComputedStyle(el).height.split("p")[0] - 300;
                        }
                    });
                }
            },
            actions() {
                if (this.inlineButtons.length == 0) {
                    //this.columns.pop();
                }
            },
        },
    };

</script>
<style scoped>
    .ant-layout-footer {
        display: none;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }
</style>