<template>
    <layout-content-with-drawer :sider-width="498">

        <!-- 侧边栏  -->
        <template slot="sider">
            <!-- 搜索 -->
            <a-form :form="form" layout="inline" class="cond_form">
                <a-row>
                    <a-col :span="8">
                        <a-form-item>
                            <a-select v-decorator="['polder']" placeholder="所在圩区">
                                <a-select-option value="1">1</a-select-option>
                                <a-select-option value="2">2</a-select-option>
                                <a-select-option value="3">3</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item>
                            <a-select v-decorator="['river']" placeholder="所在河道">
                                <a-select-option value="1">1</a-select-option>
                                <a-select-option value="2">2</a-select-option>
                                <a-select-option value="3">3</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item>
                            <a-input v-decorator="['inspector']" placeholder="巡检人" autocomplete="off"  />
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item>
                            <a-input v-decorator="['sgName']" placeholder="站闸名称" autocomplete="off"  />
                        </a-form-item>
                    </a-col>

                    <a-col :span="16">
                        <a-form-item>
                            <a-range-picker v-decorator="['date']" showTime style="width:100%;" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <!-- 搜索按钮组 -->
                    <a-col :span="24" class="col_btn_group">
                        <a-space>
                            <a-button type="primary" icon="search" @click="onSearch">搜索</a-button>
                            <a-button icon="redo" @click="onReset">重置</a-button>
                        </a-space>
                    </a-col>
                </a-row>
            </a-form>

            <!-- 列表 -->
            <a-table
                rowKey="id"
                size="small"
                :columns="columns" 
                :data-source="dataSource"
                :pagination = "pagination"
                :scroll="scroll"
                :customRow="customRow"
                :rowClassName="rowClassName"
                @change="onChangePage">
                <template slot="action" slot-scope="record">
                    <a-button 
                        type="primary" 
                        icon="file-search"
                        size="small"
                        @click.stop="onLookup(record)" />
                </template>
            </a-table>
        </template>

        <template slot="content">
            <div id="map" class="wh-100p"></div>
        </template>

        <template slot="extra">
            <a-modal
                centered
                :visible="showDetail"
                :footer="null"
                @cancel="showDetail=false">
                <a-row class="detail_modal_title">
                    <a-col :span="24">青龙港站闸</a-col>
                    <a-col :span="6">巡检人: 吴元胜 </a-col>
                    <a-col :span="10">巡检时间: 2021-04-25 08:44:41</a-col>
                </a-row>
                <div class="detail_modal_content">
                    <a-divider orientation="left">安全生产</a-divider>
                    <ul class="detail_modal_content_list">
                        <li>
                            <span>建筑物、构筑物存在裂缝、严重变形、剥落、露筋、渗漏等现象</span>
                            <span>否</span>
                        </li>
                        <li>
                            <span>配电间安全用具配套不齐全</span>
                            <span>否</span>
                        </li>
                    </ul>
                    <a-divider orientation="left">人员管理</a-divider>
                    <ul class="detail_modal_content_list">
                        <li>
                            <span>建筑物、构筑物存在裂缝、严重变形、剥落、露筋、渗漏等现象</span>
                            <span>否</span>
                        </li>
                        <li>
                            <span>配电间安全用具配套不齐全</span>
                            <span>否</span>
                        </li>
                    </ul>
                    <a-divider orientation="left">站容站貌</a-divider>
                    <ul class="detail_modal_content_list">
                        <li>
                            <span>建筑物、构筑物存在裂缝、严重变形、剥落、露筋、渗漏等现象</span>
                            <span>否</span>
                        </li>
                        <li>
                            <span>配电间安全用具配套不齐全</span>
                            <span>否</span>
                        </li>
                    </ul>
                    <a-divider orientation="left">设备管理</a-divider>
                    <ul class="detail_modal_content_list">
                        <li>
                            <span>建筑物、构筑物存在裂缝、严重变形、剥落、露筋、渗漏等现象</span>
                            <span>否</span>
                        </li>
                        <li>
                            <span>配电间安全用具配套不齐全</span>
                            <span>否</span>
                        </li>
                    </ul>
                    <a-divider orientation="left">组织管理</a-divider>
                    <ul class="detail_modal_content_list">
                        <li>
                            <span>建筑物、构筑物存在裂缝、严重变形、剥落、露筋、渗漏等现象</span>
                            <span>否</span>
                        </li>
                        <li>
                            <span>配电间安全用具配套不齐全</span>
                            <span>否</span>
                        </li>
                    </ul>
                </div>
            </a-modal>
        </template>
    </layout-content-with-drawer>
</template>

<script>
    import { loadMap } from '@/assets/js/map.js'
    import LayoutContentWithDrawer from '@/components/LayoutContentWithDrawer'

    let columns = [
        {   
            title: '站闸名称',
            dataIndex: 'a'
        },
        {
            title: '所在圩区',
            dataIndex: 'b'
        },
        {
            title: '所在河道',
            dataIndex: 'c'
        },
        {
            title: '巡检人',
            dataIndex: 'd'
        },
        {
            title: '事件数',
            dataIndex: 'e'
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            scopedSlots: { customRender: 'action' }
        },
    ]

    export default {
        components: {
            LayoutContentWithDrawer
        },
        data () {
            return {
                // form
                form: this.$form.createForm(this, { name: 'cond' }),
                // table
                columns: Object.freeze(columns),
                dataSource: [
                    {
                        id: 0,
                        a: '123',
                        b: '123',
                        c: '123',
                        d: '123',
                        e: '123'
                    },
                    {
                        id: 1,
                        a: '123',
                        b: '123',
                        c: '123',
                        d: '123',
                        e: '123'
                    },
                    {
                        id: 2,
                        a: '123',
                        b: '123',
                        c: '123',
                        d: '123',
                        e: '123'
                    }
                ],
                pagination: {
                    total: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条`
                },
                scroll: { x: 480, y: 900 },
                // modal
                showDetail: false
            }
        },
        methods: {
            initMap () {
                loadMap().then(([MI, ksSWInitial]) => {
                    let mi = new MI({
                        zoom: 10,
                        center_lng: 120.966666,
                        center_lat: 31.322222,
                        container: "map",
                        maptype: 1,
                        OMKey: "11"
                    })
                    this.mi = mi

                    let xcw = new ksSWInitial({ mi })
                    xcw.initial();  //智慧水利相关功能初始化
                    xcw.setAllBaseSubLayerVisibility(false) //关闭所有水系基础图层
                    xcw.setAllDBSubLayerVisibility(false) //关闭所有业务图层
                    xcw.setBaseWaterSubLayerVisibility('River', true) //打开河道图层，配置为false，则关闭河道图层，第一个参数为指代图层的target参数
                    xcw.setBaseWaterSubLayerVisibility('Lake', true) //打开湖泊图层
                    xcw.setBaseWaterSubLayerVisibility('TownBoundary', true) //打开乡镇界线图层
                    xcw.setBaseWaterSubLayerVisibility('Mask', true) //打开掩膜图层
                    xcw.setBoundary('昆山市')
                    
                })
            },
            onSearch () {
                console.log('搜索')
            },
            onReset () {
                console.log('重置')
            },
            onLookup (record) {
                this.showDetail = true
                console.log('详情', record);
            },
            rowClassName (record, index) {
                let className = "dark_row"
                if (index % 2 === 1) className = "light_row"
                return className
            },
            customRow (record) {
                return {
                    on: {
                        click: () => {
                            console.log('表单项', record);
                        }
                    }
                }
            },
            onChangePage ({ current }) {
                this.pagination.current = current
            },
        },
        created () {
            this.initMap()
        }
    }
</script>

<style lang="less" scoped>
    .cond_form {

        & /deep/ .ant-form-item {
            display: flex;
            margin-right: 0;
        }

        & /deep/ .ant-form-item-control-wrapper {
            flex: 1;
            padding: 0 3px;
        }

        .col_btn_group {
            margin: 3px 0 15px;
            padding-right: 3px;
            text-align: right;
        }
    }

    // 隔行变色
    /deep/ .light_row { 
        background-color: #fff;
    }
    /deep/ .dark_row {
        background-color: #fafafa;
    }

    .detail_modal {
        &_title /deep/ .ant-col:first-child {
            font-size:20px;
            font-weight:bold;
        }

        &_content {
            height: 500px;
            overflow-y:scroll;

            & /deep/ .ant-divider-inner-text {
                color: #40A9FF;
            }

            &_list {
                li {
                    display: flex;
                    padding: 0 28px; 

                    span:first-child {
                        flex: 1;
                    }

                    span:last-child {
                        width: 60px;
                        text-align: right;
                    }
                }

            }

        }
    }

  
</style>