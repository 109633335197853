<template>
    <div class="root-box">
        <!-- 标志 -->
        <img 
            src="/img/end.png" 
            alt="已办结" 
            class="sign"
            v-show="record.isEnd == 2">

        <!-- 工单信息 -->
        <a-descriptions  :column="3">
            <a-divider slot="title" orientation="left">
                <span style="fontSize:20px;color:#1890FF;">巡查问题内容</span>
            </a-divider>
            <a-descriptions-item
                v-for="wi in viewInfo" 
                :key="wi.createTime"
                :label="wi.name"
                :span="wi.span">
                <template v-if="Array.isArray(wi.value)">
                    <a 
                    class="current-img-link"
                    target="_blank" 
                    v-for="subItem in wi.value" 
                    :key="subItem.url" 
                    :href="subItem.url">
                        <img :src="subItem.url" :alt="subItem.name">
                    </a>
                </template>
                <template v-else>
                    {{ wi.value }}
                </template>
            </a-descriptions-item>
        </a-descriptions>

        <!-- 工单流程 -->
        <div>
            <a-divider slot="title" orientation="left">
                <span style="fontSize:20px;color:#1890FF;">工单流程</span>
            </a-divider>

            <a-timeline style="paddingTop: 15px;">
                <a-timeline-item
                    style="fontSize: 16px;"
                    v-for="item in record.proList" 
                    :key="item.id">
                    <div>{{ item.createTime.slice(0, 10) }} </div>
                    <div class="timeline-item-row"> 
                        <span>{{ item.send }}</span>：
                        <span style="color:#333;">{{ item.sendinfo }}</span>
                        <div>
                            <img 
                                class="current-img"
                                v-for="subItem in item.files_bh" 
                                :key="subItem.id" 
                                :src="subItem.url" 
                                alt="现场照片">

                        </div>
                    </div>
                    <div class="timeline-item-row">
                        <span>{{ item.rec }}</span>：
                        <span>{{ item.recinfo || '未回复' }}</span> [ {{ item.rectime && item.rectime.slice(0, 10) }} ]
                    </div>
                </a-timeline-item>
            </a-timeline>
        </div>

        <!-- 工单代办 -->
        <div v-if="status==1">
            <a-divider slot="title" orientation="left">
                <span style="fontSize:20px;color:#1890FF;">整改结果回复</span>
            </a-divider>


            <a-form :form="form2" @submit.prevent="assign">

                <!-- <a-form-item label="下一步">
                    <a-select  
                        v-decorator="['step', { rules: [{ required: true, message: '请输入下一步！' }] }]" 
                        @change="changeSelect" 
                        style="width: 160px !important;">
                        <a-select-option value="办结申请">
                            办结申请
                        </a-select-option>
                        <a-select-option value="延期申请">
                            延期申请
                        </a-select-option>
                        <a-select-option value="阶段性反馈">
                            阶段性反馈
                        </a-select-option>
                    </a-select>
                </a-form-item> -->

                <!-- 办结申请 -->
                <!-- <template v-if="viewLabel[step]">
                    <a-form-item :label="viewLabel[step][0]" v-if="viewLabel[step][0]">
                        <a-date-picker :allowClear="false"
                        v-decorator="['sj', { rules: [{ required: true, message: `请输入${viewLabel[step][0]}！` }] }]" />
                    </a-form-item>

                    <a-form-item :label="viewLabel[step][1]">
                        <a-textarea v-decorator="['recinfo', { rules: [{ required: true, message: `请输入${viewLabel[step][1]}！` }] }]" :rows="4" style="resize:none;" />
                    </a-form-item>
                </template> -->

                <a-form-item label="整改结果">
                    <a-radio-group v-decorator="['recStatus', { initialValue: '2' }]">
                        <a-radio value="2">是</a-radio>
                        <a-radio value="1">否</a-radio>
                    </a-radio-group>
                </a-form-item>

                <a-form-item label="整改措施">
                    <a-textarea v-decorator="['recinfo',  { rules: [{ required: true, message: '请输入整改措施！' }] }]" :rows="4" style="resize:none;" />
                </a-form-item>

                <a-form-item label="整改结果照片">
                    <a-upload
                        :action="$axios.defaults.fileURL + '/api/FileSet/Upload'"
                        list-type="picture"
                        multiple
                        :fileList=fileList
                        @change="imgChange"
                        @preview="imgPreview">
                        <a-button  v-if="fileList.length < 8"><a-icon type="upload" />上传</a-button>
                    </a-upload>
                    <a-modal :visible="previewModal" :footer="null" @cancel="previewModal=false" centered>
                        <img alt="预览图片" style="width: 100%" :src="imgUrl" />
                    </a-modal>
                </a-form-item>

                <a-form-item>
                    <a-button type="primary" html-type="submit">提交</a-button>
                </a-form-item>
                
            </a-form>
        </div>

        <!-- 工单审核 -->
        <div v-if="status==2">
            <a-divider slot="title" orientation="left">
                <span style="fontSize:20px;color:#1890FF;">工单审批</span>
            </a-divider>
            <a-form :form="form1" @submit.prevent="review">

                <a-form-item label="审批结果">
                    <a-radio-group v-decorator="['recStatus', { initialValue: '2' }]">
                        <a-radio value="2">同意</a-radio>
                        <a-radio value="1">不同意</a-radio>
                    </a-radio-group>
                </a-form-item>

                <a-form-item label="审批说明">
                    <a-textarea v-decorator="['recinfo', { rules: [{ required: true, message: '请输入审批说明！' }] }]" :rows="4" style="resize:none;" />
                </a-form-item>

                <a-form-item>
                    <a-button type="primary" html-type="submit">提交</a-button>
                </a-form-item>
                
            </a-form>
        </div>
        
    </div>
</template>

<script>
    import SQLCompiler from '@/assets/js/SQLCompiler.js'
    const fieldMapper = {
        title: { name: '工单名称', span: 3 },
        orderNum: { name: '工单编号', span: 1 },
        xcgc: { name: '巡查工程', span: 1 },
        xccj: { name: '巡查层级', span: 1 },
        createUser: { name: '派单人员', span: 1 },
        createTime: { 
            name: '派单时间',
            span: 1,
            format(value) {
                return value.slice(0, 10)
            }
        },
        currentrec: { name: '整改人员' },
        clsx: { 
            name: '整改时间',
            span: 1,
            format(value) {
                return value.slice(0, 10)
            }
        },
        isEnd: { 
            name: '整改状态',
            span: 2,
            format(value) {
                return value === 2 ? '已整改' : '正在整改'
            }
        },
        currentImgs: {
            name: '问题照片',
            span: 3,
        }
    }

    const handleData = data => {
        return Object.keys(fieldMapper).map(fm => {
            let { name, format,span } = fieldMapper[fm]
            return {
                name,
                span,
                value: format ? format(data[fm]) : data[fm]
            }
        })
    }

    // const viewLabel = {
    //     '办结申请': [ undefined, '办结结果'],
    //     '延期申请': [ '延期时间', '延期理由'],
    //     '阶段性反馈': [ undefined, '内容反馈']
    // }

    export default {
        data() {
            return {
                status: 0,
                record: {}, // 工单记录
                viewInfo: null, // 工单信息
                // 审核
                form1: this.$form.createForm(this, { name: 'review' }),
                // 代办
                form2: this.$form.createForm(this, { name: 'assign' }),
                // viewLabel: Object.freeze(viewLabel),
                step: '',
                // 图片上传
                fileList: [],
                imgUrl: '',
                previewModal: false
            }
        },
        methods: {
            imgChange({ fileList }) {
                this.fileList = fileList.slice(0, 8)
            },
            imgPreview(file) {
                if (!file.response) return
                this.imgUrl = file.response.data.url
                this.previewModal = true
            },
            changeSelect (value) {
                this.step = value
                this.form2.resetFields()
            },
            // ajax
            findRecord() {
                this.$axios({
                    url: '/api/workorder/GetWorkOrderInfo',
                    method: 'get',
                    params: { 
                        orderbh: this.$route.query.orderbh
                    }
                }).then(async res => {
                    let { code, data } = res.data
                    if (code !== 0) return
                    this.record = data
                    data.currentImgs = await this.getCurrentImgs(data.itembh)
                    console.log(data);
                    this.viewInfo = handleData(data)
                }).catch(err => err)
            },
            async getCurrentImgs (bh) {
                return this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'v_slgcxczg_rcxcItem',
                        page: 1,
                        limit: 10000,
                        strWhere: await SQLCompiler.encrypt(` bh='${bh}' `),
                        filesFields: 'bh'
                    },
                })
                    .then(res => {
                        let { code, data } = res.data
                        if (code !== 0) return
                        return data[0].files_bh
                    })
                    .catch(err => err)
            },
            assign () {
                this.form2.validateFields((err, values) => {
                    if (err) return 
                    let { orderbh, bh} = this.$route.query
                    let { proid: id, infobh } = this.record
                    let { recinfo, sj, recStatus  } = values
                    if ( sj ) sj = values.sj.format('YYYY-MM-DD')
                    let files_bh = this.fileList.map(file => {
                        return file.response.data
                    })

                    this.$axios({
                        url: '/api/workorder/reply',
                        method: 'post',
                        data: {
                            id,
                            infobh,
                            orderbh,
                            bh,
                            recStatus,
                            step: '办结申请',
                            recinfo,
                            sj,
                            files_bh,
                        }
                    })
                        .then(res => {
                            if (res.data.code !== 0) return
                            this.$message.success("提交成功")
                            this.$router.go(-1)
                        })
                        .catch(err => err)
                })
            },
            review () {
                this.form1.validateFields((err, values) => {
                    if (err) return 
                    let { orderbh, bh } = this.$route.query
                    const { proid: id, infobh } = this.record
                    const { recStatus, recinfo } = values

                    this.$axios({
                        url: '/api/workorder/sp',
                        method: 'post',
                        data: {
                            id,
                            infobh,
                            orderbh,
                            bh,
                            recStatus,
                            recinfo
                        }
                    })
                        .then(res => {
                            if (res.data.code !== 0) return
                            this.$message.success("提交成功")
                            this.$router.go(-1)
                        })
                        .catch(err => err)
                })
            },
        },
        created() {
            this.status = this.$route.query.status || -1
            this.$nextTick(() => {
                this.findRecord()
            })
        }
    }
</script>

<style lang="less" scoped>

    .root-box {
        position: relative;

        .sign {
            position: absolute;
            top: -10px;
            right: 6px;
        }
    }

    /deep/ .ant-descriptions-item-label {
        font-size: 16px;
        color: #1C1C1C;
    }

    .timeline-item-row {
        margin-top: 3px;

        &:last-child {
            margin-top: 10px;
        }

        // 名称
        span:first-child {
            color:  #EB2F96;
        }

        // 内容
        span:last-child {
            color:  #333;
        }

        .current-img {
            margin-top: 5px;
            margin-right: 5px;
            width: 100px;
            height: 100px;
        }
    }

    /deep/ .ant-form {

        .ant-form-item {
            display: flex;

            .ant-form-item-label {
                width: 100px;
                text-align: left;
            }

            .ant-input, .ant-upload-list {
                width: 240px;
            }

            .ant-btn[type="submit"] {
                margin-left: 100px;
            }
        }
    }

    .current-img-link {
        img {
            vertical-align: top;
            width: 100px;
            height: 100px;
        }

        &:not(:last-child) {
            margin-right: 5px;
        }
    }
</style>