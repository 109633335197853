<template>
  <div id="components-form-demo-advanced-search1">
    <a-form :form="formSearch" @submit="formSearchSubmit" :label-col="{ span:0 }" :wrapper-col="{ span:24 }">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="闸泵站名称" v-decorator="[`Name`]" />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item>
            <a-select v-decorator="['type']" placeholder="请选择闸站类型" :disabled="formAddOptions.viewMode" style="width:100%">
              <a-select-option value="水闸">
                水闸
              </a-select-option>
              <a-select-option value="泵站">
                泵站
              </a-select-option>
              <a-select-option value="站闸">
                站闸
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="所在区镇" v-decorator="['town']"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="所在村社区" v-decorator="['village']"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24" v-if="expand">
        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="监管单位" v-decorator="['JgCompany']"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="地点" v-decorator="['location']"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="总户号" v-decorator="['toatlHu']"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item label="">
            <a-input placeholder="线路" v-decorator="['powerLine']"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[24,12]">
        <a-col :span="6" :style="{ textAlign: 'left' }">
          <a-button type='primary' v-for='button in buttons' @click="btnClick(button.title)" style='margin-right: 5px' :key="button.title">{{button.title}}</a-button>
        </a-col>
        <a-col :span="18" :style="{ textAlign: 'right' }">
          <a-button type="primary" :style="{ width:'48px' }" html-type="submit" icon="search"></a-button>
          <a-button type="" :style="{ width:'48px',marginLeft: '8px' }" @click="formSearchReset" icon="reload"></a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
            {{ expand ?'收起':'展开' }}
            <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>
    <a-modal v-model="formAddOptions.visible" title="新增" width="1300px">
      <a-spin :spinning="formAddOptions.spinning">
        <a-form :form="formAdd" :label-col="{ span:7 }" :wrapper-col="{ span:17 }" labelAlign="left">
          <a-form-item label="" style="display:none">
            <a-input v-decorator="['id']" />
          </a-form-item>
          <a-row type="flex" justify="space-between" :gutter="32">
            <a-col :span="8">
              <a-form-item label="闸泵站名称">
                <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入闸泵站名称' }]}]" placeholder="闸泵站名称" :disabled="formAddOptions.viewMode" :maxLength="20" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="闸站类型">
                <a-select v-decorator="['type',{ rules: [{ required: true, message: '请输入闸站类型'}]},]" placeholder="请选择" :disabled="formAddOptions.viewMode" style="width:100%">
                  <a-select-option value="排涝站">
                    排涝站
                  </a-select-option>
                  <a-select-option value="防洪闸">
                    防洪闸
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="所在区镇">
                <!-- <a-input v-decorator="['town', { rules: [{ required: true, message: '请输入所在区镇' }]}]" placeholder="所在区镇" :disabled="formAddOptions.viewMode" :maxLength="20" /> -->
                <a-select v-decorator="['townId',{ rules: [{ required: true, message: '请输入闸站类型'}]},]" placeholder="请选择区镇" :disabled="formAddOptions.viewMode || formAddOptions.townEdit" style="width:100%">
                  <a-select-option v-for="(item,index) in xzlist" :key="index" :value="item.value">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between" :gutter="32">
            <a-col :span="8">
              <a-form-item label="所在村社区">
                <a-input v-decorator="['village', { rules: [{ required: true, message: '请输入所在村社区' }]}]" placeholder="所在村社区" :disabled="formAddOptions.viewMode" :maxLength="20" style="width:100%" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="监管单位">
                <a-input v-decorator="['JgCompany', { rules: [{ required: false, message: '请输入监管单位' }]}]" placeholder="监管单位" :disabled="formAddOptions.viewMode" :maxLength="20" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="地点">
                <a-input v-decorator="['location', { rules: [{ required: false, message: '请输入地点' }]}]" placeholder="地点" :disabled="formAddOptions.viewMode" :maxLength="20" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between" :gutter="32">
            <a-col :span="8">
              <a-form-item label="总户号">
                <a-input v-decorator="['toatlHu', { rules: [{ required: false, message: '请输入总户号' }]}]" placeholder="总户号" :disabled="formAddOptions.viewMode" :maxLength="20" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="线路">
                <a-input v-decorator="['powerLine', { rules: [{ required: false, message: '请输入线路' }]}]" placeholder="线路" :disabled="formAddOptions.viewMode" :maxLength="20" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="变压器KVA">
                <a-input type="number" v-decorator="['kva', { rules: [{ required: false, message: '请输入变压器KVA' }]}]" placeholder="变压器KVA" :disabled="formAddOptions.viewMode" :maxLength="20" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between" :gutter="32">
            <a-col :span="8">
              <a-form-item label="孔数">
                <a-input-number v-decorator="['apertureNumber', { rules: [{ required: false, message: '请输入孔数' }]}]" placeholder="孔数" :disabled="formAddOptions.viewMode" :max="9999" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="孔径">
                <a-input-number v-decorator="['apertureSize', { rules: [{ required: false, message: '请输入孔径' }]}]" placeholder="孔径" :disabled="formAddOptions.viewMode" :max="9999" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="门顶高">
                <a-input-number v-decorator="['gateHeight', { rules: [{ required: false, message: '请输入门顶高' }]}]" placeholder="门顶高" :disabled="formAddOptions.viewMode" :max="9999" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between" :gutter="32">
            <a-col :span="8">
              <a-form-item label="底板高">
                <a-input-number v-decorator="['bHeight', { rules: [{ required: false, message: '请输入底板高' }]}]" placeholder="底板高" :disabled="formAddOptions.viewMode" :max="9999" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="闸门形式">
                <a-input v-decorator="['sluiceType', { rules: [{ required: false, message: '请输入闸门形式' }]}]" placeholder="闸门形式" :disabled="formAddOptions.viewMode" :maxLength="20" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="水泵型号">
                <a-input v-decorator="['pumpType', { rules: [{ required: false, message: '请输入水泵型号' }]}]" placeholder="水泵型号" :disabled="formAddOptions.viewMode" :maxLength="20" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between" :gutter="32">
            <a-col :span="8">
              <a-form-item label="水泵台数">
                <a-input-number v-decorator="['eCount', { rules: [{ required: false, message: '请输入水泵台数' }]}]" placeholder="水泵台数" :disabled="formAddOptions.viewMode" :max="9999" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="水泵口径">
                <a-input-number v-decorator="['pumpCaliber', { rules: [{ required: false, message: '请输入水泵口径' }]}]" placeholder="水泵口径" :disabled="formAddOptions.viewMode" :max="9999" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="电动机KW/台">
                <a-input-number v-decorator="['electricMotor', { rules: [{ required: false, message: '请输入电动机' }]}]" placeholder="电动机KW/台" :disabled="formAddOptions.viewMode" :max="9999" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between" :gutter="32">
            <a-col :span="8">
              <a-form-item label="排涝流量m3/s">
                <a-input-number v-decorator="['plll', { rules: [{ required: false, message: '请输入排涝流量' }]}]" placeholder="排涝流量" :disabled="formAddOptions.viewMode" :max="9999" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="引水流量m3/s">
                <a-input-number v-decorator="['ysll', { rules: [{ required: false, message: '请输入引水流量' }]}]" placeholder="引水流量" :disabled="formAddOptions.viewMode" :max="9999" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="应急电源">
                <a-switch checked-children="有" un-checked-children="无" v-decorator="['emergencyPower',{ valuePropName: 'checked',initialValue:false }]" />
                <!-- <a-input v-decorator="['emergencyPower', { rules: [{ required: true, message: '请输入应急电源' }]}]" placeholder="应急电源" :disabled="formAddOptions.viewMode"   /> -->
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between" :gutter="32">
            <a-col :span="8">
              <a-form-item label="建设/改造时间">
                <a-input placeholder="输入年份" style="width: 100%;" :disabled="formAddOptions.viewMode" v-decorator="['constructDate', { rules: [{ required: false, message: '建设/改造时间' }]}]" />
                <!-- <a-date-picker mode="date" style="width: 100%;" v-decorator="['constructDate', { rules: [{ required: false, message: '建设/改造时间' }]}]" placeholder="建设/改造时间" :disabled="formAddOptions.viewMode" /> -->
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="概算投资万元">
                <a-input-number v-decorator="['estimateInvestment', { rules: [{ required: false, message: '请输入概算投资万元' }]}]" placeholder="概算投资万元" :disabled="formAddOptions.viewMode" :max="9999" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="安全鉴定">
                <a-switch checked-children="有" un-checked-children="无" v-decorator="['safetyAssessment',{ valuePropName: 'checked',initialValue:false }]" />
                <!-- <a-input v-decorator="['safetyAssessment', { rules: [{ required: true, message: '请输入安全鉴定' }]}]" placeholder="安全鉴定" :disabled="formAddOptions.viewMode"   /> -->
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="start" :gutter="32">
            <a-col :span="8">
              <a-form-item label="鉴定时间">
                <a-input placeholder="输入年份" style="width: 100%;" :disabled="formAddOptions.viewMode" v-decorator="['assessmentTime', { rules: [{ required: false, message: '建设/改造时间' }]}]" />
                <!-- <a-date-picker style="width: 100%;" v-decorator="['assessmentTime', { rules: [{ required: false, message: '鉴定时间' }]}]" placeholder="鉴定时间" :disabled="formAddOptions.viewMode" /> -->
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="鉴定结果">
                <a-input v-decorator="['assessmentResult', { rules: [{ required: false, message: '请输入鉴定结果' }]}]" placeholder="鉴定结果" :disabled="formAddOptions.viewMode" />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="责任人">
                <a-input v-decorator="['zrr', { rules: [{ required: false, message: '请输入责任人' }]}]" placeholder="责任人" :disabled="formAddOptions.viewMode" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="start" :gutter="32">
            <a-col :span="8">
              <a-form-item label="经度">
                <a-input placeholder="经度" style="width: 100%;" :disabled="formAddOptions.viewMode" v-decorator="['x', { rules: [{ required: false, message: '' }]}]" />
                <!-- <a-date-picker style="width: 100%;" v-decorator="['assessmentTime', { rules: [{ required: false, message: '鉴定时间' }]}]" placeholder="鉴定时间" :disabled="formAddOptions.viewMode" /> -->
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="纬度">
                <a-input v-decorator="['y', { rules: [{ required: false, message: '' }]}]" placeholder="纬度" :disabled="formAddOptions.viewMode" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
      <template slot="footer">
        <a-button key="back" @click="formAddOptions.visible=false">关闭</a-button>
        <a-button key="submit" type="primary" :loading="formAddOptions.submitLoading" @click="formAddSubmit" v-if="formAddOptions.submitVisible">确定</a-button>
      </template>
    </a-modal>
    <a-table :columns="columns" :data-source="tableData" :pagination="pagination" :pageSize="20" :total="50" rowKey="id" :loading="loading" :scroll="scroll" @change="pageCurrentChange" position="bottom" tableLayout="fixed">
      <span slot="xuhao" slot-scope="text, record">{{record.rownum}}</span>
      <a slot="Name" slot-scope="text,record" @click="openDlg(record,'view')">{{ text }}</a>
      <span slot="emergency" slot-scope="text, record">{{record.emergencyPower?"有":"无"}}</span>
      <span slot="safety" slot-scope="text, record">{{record.safetyAssessment?"有":"无"}}</span>
      <span slot="action" slot-scope="text, record">
        <a-button type="primary" size="small" style='margin:0 5px' @click='lookproject(record)'>工程查看</a-button>
        <a-button :type='button.className' size="small" v-for='button in inlineButtons' :key="button.title" @click="btnClick(button.title,record)" style='margin-right: 5px'>
          <a-icon :type="button.iconLink" />
        </a-button>
      </span>
    </a-table>
  </div>
</template>
<script>
const strWhere = 'del=0'
export default {
  data() {
    return {
      scroll: {
        x: 3000,
        y: 900
      },
      showexport: false,
      buttons: [],
      inlineButtons: [],
      actionArry: [],
      tableData: [],
      columns: [
        {
          title: '序号',
          fixed: 'left',
          width: 70,
          scopedSlots: { customRender: 'xuhao' }
        },
        {
          title: '闸泵站名称',
          dataIndex: 'name',
          key: 'name',
          width: 150,
          fixed: 'left',
          scopedSlots: { customRender: 'Name' }
        },
        {
          title: '闸站类型',
          dataIndex: 'type',
          key: 'type'
        },
        {
          title: '所在区镇',
          dataIndex: 'town',
          key: 'town'
        },
        {
          title: '所在村社区',
          dataIndex: 'village',
          width: 150,
          key: 'village'
        },
        {
          title: '监管单位',
          dataIndex: 'JgCompany',
          key: 'JgCompany'
        },
        {
          title: '地点',
          dataIndex: 'location',
          width: 200,
          key: 'location'
        },
        {
          title: '总户号',
          dataIndex: 'toatlHu',
          key: 'toatlHu'
        },
        {
          title: '线路',
          key: 'powerLine',
          dataIndex: 'powerLine'
        },
        {
          title: '变压器KVA',
          key: 'kva',
          dataIndex: 'kva'
        },
        {
          title: '孔数',
          key: 'apertureNumber',
          dataIndex: 'apertureNumber'
        },
        {
          title: '孔径',
          key: 'apertureSize',
          dataIndex: 'apertureSize'
        },
        {
          title: '门顶高',
          key: 'gateHeight',
          dataIndex: 'gateHeight'
        },
        {
          title: '底板高',
          key: 'bHeight',
          dataIndex: 'bHeight'
        },
        {
          title: '闸门形式',
          key: 'sluiceType',
          dataIndex: 'sluiceType'
        },
        {
          title: '水泵型号',
          key: 'pumpType',
          dataIndex: 'pumpType'
        },
        {
          title: '水泵台数',
          key: 'eCount',
          dataIndex: 'eCount'
        },
        {
          title: '水泵口径',
          key: 'pumpCaliber',
          dataIndex: 'pumpCaliber'
        },
        {
          title: '应急电源',
          key: 'emergencyPower',
          dataIndex: 'emergencyPower',
          scopedSlots: { customRender: 'emergency' }
        },
        {
          title: '建设/改造时间',
          key: 'constructDate',
          width: 200,
          dataIndex: 'constructDate'
        },
        {
          title: '概算投资万元',
          key: 'estimateInvestment',
          width: 150,
          dataIndex: 'estimateInvestment'
        },
        {
          title: '安全鉴定',
          key: 'safetyAssessment',
          dataIndex: 'safetyAssessment',
          scopedSlots: { customRender: 'safety' }
        },
        {
          title: '鉴定时间',
          key: 'assessmentTime',
          width: 200,
          dataIndex: 'assessmentTime'
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 185,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: true,
      action: 'add',
      previewVisible: false,
      previewImage: '',
      fileList: [],
      strWhere,
      expand: false,
      formAddOptions: {
        visible: false,
        submitLoading: false,
        submitVisible: true,
        viewMode: false,
        spinning: false,
        townEdit: false
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formSearch: this.$form.createForm(this, { name: 'advanced_search2' }),
      formAdd: this.$form.createForm(this, { name: 'advanced_search3' }),
      pagination: {
        total: 0,
        pageSize: 15, // 每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
        position: 'bottom'
      },
      xzlist: [],
      backData: {
        currentPageIndex: 1,
        searchData: {}
      }
    }
  },
  created() {
    // 模拟后台请求
    // this.bindList();
    // this.InitGrid()
    this.getTown()
  },
  mounted() {
    var obj = JSON.parse(localStorage.getItem('backData'))
    this.formSearch.setFieldsValue(obj.searchData)
    this.pagination.current = obj.currentPageIndex
    if (this.$route.meta.buttons) {
      for (let i = 0; i < this.$route.meta.buttons.length; i++) {
        if (this.$route.meta.buttons[i].tag == 'inline') {
          this.inlineButtons.push(this.$route.meta.buttons[i])
        } else {
          this.buttons.push(this.$route.meta.buttons[i])
        }
      }
    }

    this.init()
    this.actions()
    window.onresize = () => {
      this.init()
    }
    this.bindList()
  },
  methods: {
    setCallData: function () {
      this.backData.currentPageIndex = this.pagination.current
      this.backData.searchData = this.formSearch.getFieldsValue()
      localStorage.setItem('backData', JSON.stringify(this.backData))
    },
    getTown() {
      this.$axios({
        url: '/api/zzgl/GetTown',
        method: 'get'
      }).then(res => {
        if (res.data.code == 0) {
          this.xzlist = res.data.data
        }
      })
    },
    lookproject(item) {
      localStorage.setItem('backData', '{"currentPageIndex":1}')
      this.$router.push(
        '/gcyxgl/hdshsy/xmgl-list-parent?townId=' +
          item.townId +
          '&zzbh=' +
          item.bh
      )
    },
    btnClick(text, item) {
      console.log(text)
      let _this = this
      switch (text) {
        case '新增':
          this.openDlg()
          break
        case '编辑':
          this.openDlg(item)
          break
        case '删除':
          this.$confirm({
            title: '操作确认',
            content: '确定要删除 ' + item.name + ' 吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              _this.del(item)
            }
          })
          break
      }
    },
    bindList: async function () {
      this.formSearch.validateFields((error, values) => {
        error = 1
        // console.log(values)
        this.strWhere = "del=0 and source='站闸'"
        for (var obj in values) {
          if (values[obj]) {
            this.strWhere += ' and ' + obj + " like '%" + values[obj] + "%'"
          }
        }
        console.log(this.strWhere)
        this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
      })
      this.loading = true
      this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere)
      this.strWhere = this.strWhere.data
      await this.InitGrid()
    },
    InitGrid: function () {
      var _that = this
      _that.loading = true
      this.$axios({
        url: '/api/GetList/getListByTable',
        method: 'get',
        params: {
          tableName: 'zzgl_zzxx',
          page: _that.pagination.current,
          limit: _that.pagination.pageSize,
          filesFields: 'bh',
          strWhere: _that.strWhere
        }
      })
        .then(function (response) {
          console.log(response)
          _that.loading = false
          if (response.status === 200) {
            _that.tableData = response.data.data
            _that.pagination.total = response.data.count
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    formSearchSubmit(e) {
      e.preventDefault()
      this.pagination.current = 1
      this.bindList()
      this.setCallData()
    },
    pageCurrentChange(pagination) {
      this.pagination.current = pagination.current
      this.bindList()
      this.setCallData()
    },
    formSearchReset() {
      this.formSearch.resetFields()
      this.strWhere = 'del=0'
      this.bindList()
      this.setCallData()
    },
    toggle() {
      this.expand = !this.expand
      this.init()
    },
    openDlg: async function (record, action) {
      this.formAdd.resetFields()
      this.fileList = []
      this.formAddOptions.submitVisible = true
      this.formAddOptions.viewMode = false
      if (record !== undefined) {
        // 从后台取数据赋值
        this.formAddOptions.spinning = true
        var obj = await this.$jsc.DesEncrypt(
          this,
          'id=zhangbiao' + record.id + 'zhangbiao'
        )
        obj = obj.data
        this.$axios
          .get(
            '/api/GetList/getListByTable?tableName=zzgl_zzxx&strWhere=' +
              obj +
              '&filesFields=bh'
          )
          .then(response => {
            if (response.status == 200) {
              if (response.data.code == 0) {
                this.formAddOptions.visible = true
                var result = response.data.data
                console.log(result[0])
                setTimeout(() => {
                  result[0].yyqk = true
                  result[0].emergencyPower =
                    result[0].emergencyPower == 'true' ? true : false
                  result[0].safetyAssessment =
                    result[0].safetyAssessment == 'true' ? true : false
                  // var objArr = { qymc: result[0].qymc, lxr: result[0].lxr, yyqk: true }
                  // this.formAdd.setFieldsValue(objArr)
                  this.formAdd.setFieldsValue(result[0])
                  this.fileList = result[0].files_bh
                  this.formAddOptions.townEdit = true
                  this.formAddOptions.spinning = false
                }, 1000)
              } else {
                this.$message.error(response.data.msg)
                this.formAddOptions.spinning = false
              }
            } else {
              // vue.prototype.$message.info('This is an error message');
            }
          })
          .catch(function (error) {
            // 请求失败处理
            console.log(error)
          })
        if (action === 'view') {
          // 预览模式
          this.formAddOptions.submitVisible = false
          this.formAddOptions.viewMode = true
        }
      } else {
        this.formAddOptions.visible = true
        this.formAddOptions.townEdit = false
      }
    },
    formAddSubmit() {
      this.formAdd.validateFields((err, values) => {
        if (!err) {
          values.files_bh = this.fileList
          values.source = '站闸'
          this.formAddOptions.submitLoading = true
          this.$axios({
            url: '/api/zzgl/EditZzxxInfo',
            method: 'post',
            data: values
          })
            .then(res => {
              console.log(res)
              if (res.status == 200) {
                if (res.data.code === 0) {
                  this.$message.info('保存成功')
                  this.formAddOptions.visible = false
                  this.formAddOptions.submitLoading = false
                  this.bindList()
                } else {
                  console.log(res)
                  throw new Error('服务器超时2')
                }
              } else {
                throw new Error('服务器超时2')
              }
            })
            .catch(error => {
              // 请求失败处理
              this.$message.error(error.message)
              this.formAddOptions.submitLoading = false
            })
        }
      })
    },
    del(record) {
      this.$axios({
        url: '/api/Comon/deltag',
        method: 'post',
        data: {
          tableName: 'zzgl_zzxx',
          field: 'id',
          value: record.id,
          source: 'web',
          loginid: ''
        }
      })
        .then(res => {
          if (res.status == 200) {
            if (res.data.code == 0) {
              this.$message.info('删除成功')
              this.bindList()
            } else {
              throw new Error('服务器超时')
            }
          } else {
            throw new Error('服务器超时')
          }
        })
        .catch(error => {
          // 请求失败处理
          this.$message.error(error.message)
        })
    },
    init() {
      var that = this
      size()
      function size() {
        var innerHeight = window.innerHeight || document.body.innerHeight
        that.$nextTick(() => {
          var el = document.querySelector(
            '#components-form-demo-advanced-search1>form'
          )
          if (el) {
            that.scroll.y =
              innerHeight - getComputedStyle(el).height.split('p')[0] - 270
          }
        })
      }
    },
    actions() {
      if (this.inlineButtons.length == 0) {
        this.columns.pop()
      }
    }
  }
}
</script>
<style>
</style>