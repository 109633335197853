<template>
    <div style="height:100%;" ref="rootBox">
        
        <!-- 搜索 -->
        <a-form :form="form" layout="inline" style="marginBottom: 30px;">

            <a-form-item>
                <a-input v-decorator="['xcgc']" autocomplete="off"  placeholder="巡查工程"  />
            </a-form-item>

            <a-form-item>
                <a-input v-decorator="['townName']" autocomplete="off"  placeholder="所属区镇"  />
            </a-form-item>

            <a-form-item>
                <a-range-picker v-decorator="['xcsj']"  :placeholder="['巡查开始时间', '巡查结束时间']" style="width:224px" />
            </a-form-item>

            <!-- 搜索按钮组 -->
            <a-form-item>
                <a-space >
                    <a-button type="primary" icon="search" @click="onSearch">搜索</a-button>
                    <a-button icon="redo" @click="onReset">重置</a-button>
                </a-space>
            </a-form-item>

        </a-form>

        <!-- 列表 -->
        <a-table
            rowKey="id"
            :columns="columns" 
            :data-source="dataSource"
            :pagination = "pagination"
            :scroll="scroll"
            :loading="loading"
            :rowClassName="rowClassName"
            @change="onChangePage">

            <template slot="xcgc" slot-scope="text, record">
                <router-link 
                    :to="{ 
                        path: './city_assess_detail', 
                        query: { 
                            bh: record.bh, 
                            type: $route.path.slice(-1),
                            townName: record.townName,
                            xcgc: record.xcgc,
                            xcsj: record.xcsjView,
                            total: record.total
                        } 
                    }">
                    {{ text }}
                </router-link>
            </template>

            <template slot="other_jf" slot-scope="text, record">
                <TableCellEdit :text="text" @change="value => changePoints(value, record, 1)" />
            </template>

            <template slot="other_kf" slot-scope="text, record">
                <TableCellEdit :text="text" @change="value => changePoints(value, record, 2)" />
            </template>
        </a-table>
    </div>
</template>

<script>
    import SQLCompiler from '@/assets/js/SQLCompiler.js'
    
    import TableCellEdit from '../common/TableCellEdit.vue'

    export default {
        components: {
            TableCellEdit,
        },
        data() {
            return {
                SQLOption: {
                    'xcgc': 'like',
                    'townName': 'like',
                    'xcsj': 'range'
                },
                 // form
                form: this.$form.createForm(this, { name: 'formSearch' }),
                // table
                columns: Object.freeze( [
                    {   
                        title: '序号',
                        dataIndex: 'rownum',
                        width: 80,
                        align: 'center'
                    },
                    {
                        title: '巡查工程',
                        dataIndex: 'xcgc',
                        ellipsis: true,
                        width: 180,
                        scopedSlots: { customRender: 'xcgc' }
                    },
                    {
                        title: '所属区镇',
                        dataIndex: 'townName',
                        width: 120,
                        align: 'center'
                    },
                    {
                        title: '评估得分',
                        dataIndex: 'total',
                        align: 'center',
                        width: 120,
                    },
                    {
                        title: '巡查扣分',
                        dataIndex: 'xckf',
                        align: 'center',
                        width: 120,
                    },
                    {
                        title: '加分',
                        dataIndex: 'other_jf',
                        align: 'center',
                        width: 120,
                        scopedSlots: { customRender: 'other_jf' }
                    },
                    {
                        title: '扣分',
                        dataIndex: 'other_kf',
                        align: 'center',
                        width: 120,
                        scopedSlots: { customRender: 'other_kf' }
                    },
                    {
                        title: '巡查时间',
                        dataIndex: 'xcsjView',
                        align: 'center',
                        width: 120

                    }
                ]),
                dataSource: [],
                pagination: {
                    total: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条`
                },
                scroll: { 
                    y: 1400,
                },
                loading: true
            }
        },
        methods: {
            checkCond() {
                return this.form.isFieldsTouched()
            },
            getCond () {
                let field = this.form.getFieldsValue()

                 // 处理时间格式
                if (field.xcsj) {
                    let start = field.xcsj[0].format('YYYY-MM-DD')
                    let end = field.xcsj[1].format('YYYY-MM-DD') + ' 23:59:59'
                    field.xcsj = { start, end }
                }

                return field
            },
            clearCond () {
                this.form.resetFields()
            },
            onSearch () {
                if (!this.checkCond()) return
                this.pagination.current = 1
                this.getDataOfTable()
            },
            onReset () {
                if (!this.checkCond()) return
                this.clearCond()
                this.pagination.current = 1
                this.getDataOfTable()
            },
            rowClassName (record, index) {
                let className = "dark-row"
                if (index % 2 === 1) className = "light-row"
                return className
            },
            onChangePage ({ current }) {
                this.pagination.current = current
                this.getDataOfTable()
            },
            changePoints (value, record, type) {
                this.$axios({
                    url: '/api/rcxc_tj/other_df',
                    method: 'get',
                    params: {
                        id: record.id,
                        type,
                        fs: value
                    }
                }).then(res => {
                    const { code } = res.data
                    if (code !== 0) return
                    const checkPointsSet = type === 1 ? 'other_jf' : 'other_kf'
                    const diffPoint = value - record[checkPointsSet]
                    record.total += type === 1 ? diffPoint : -diffPoint
                    record[checkPointsSet] = value
                }).catch(err => err)
            },
            adjust () {
                this.$nextTick(() => {
                    this.scroll.y = this.$refs.rootBox.offsetHeight - 200
                })
            },
            async getDataOfTable () {
                this.loading = true
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'v_slgcxczg_rcxcInfo_tj_df',
                        page: this.pagination.current,
                        limit: 10,
                        strWhere: await SQLCompiler.encrypt(this.getCond(), this.SQLOption, `gclx=${this.$route.path.slice(-1)}`)
                    },
                })
                    .then(res => {
                        let { code, count, data } = res.data
                        if (code !== 0) return
                        this.pagination.total = count
                        this.dataSource = data.map(d => {
                            return {
                                ...d,
                                xcsjView: d.xcsj.slice(0, 10)
                            }
                        })
                        this.$emit('loadend')
                    })
                    .catch(err => err)
                    .finally(() =>  this.loading = false)
            },
            onRouteChange () {
                this.pagination.current = 1
                this.clearCond()
                this.getDataOfTable()
            }
        },
        watch: {
            $route: {  handler: 'onRouteChange', immediate: true }
        },
        created () {
            
            this.getDataOfTable()
            this.$on('loadend', () => {
                this.adjust()
                window.addEventListener('resize', this.adjust)
            })
        },
        beforeDestroy () {
            window.removeEventListener('resize', this.adjust)
        },
    }
</script>

<style lang="less" scoped>
    // 隔行变色
    /deep/ .light-row { 
        background-color: #fff;
    }
    /deep/ .dark-row {
        background-color: #fafafa;
    }

</style>