<template>
    <div v-if="info.length !== 0">

        <a-divider slot="title" orientation="left">
            <span style="color:#EC2FA2;">工单信息</span>
        </a-divider>

        <a-descriptions :column="2">
            <a-descriptions-item
                v-for="item in info" 
                :key="item.name"
                :label="item.name">
                <template v-if="Array.isArray(item.value)">
                    <a 
                    class="current-img-link"
                    target="_blank" 
                    v-for="subItem in item.value" 
                    :key="subItem.url" 
                    :href="subItem.url">
                        <img :src="subItem.url" :alt="subItem.name">
                    </a>
                </template>
                <template v-else>
                    {{ item.value }}
                </template>
            </a-descriptions-item>
        </a-descriptions>

        <a-timeline style="paddingTop: 15px;">
            <a-timeline-item
                style="fontSize: 16px;"
                v-for="p in process" 
                :key="p.id">
                <div>{{ p.createTime_view }} </div>
                <div class="timeline-item-row"> 
                    <span>{{ p.send }}</span>：
                    <span style="color:#333;">{{ p.sendinfo }}</span>
                    <div>
                        <a v-for="pic in p.files_bh" :key="pic.id" :href="pic.url" target="_blank">
                            <img class="current-img" :src="pic.url" alt="现场照片">
                        </a>
                    </div>
                </div>
                <div class="timeline-item-row">
                    <span>{{ p.rec }}</span>：
                    <span>{{ p.recinfo || '未回复' }}</span> 
                    【{{ p.rectime_view }}】
                </div>
            </a-timeline-item>
        </a-timeline>

    </div>


</template>

<script>
    import SQLCompiler from '@/assets/js/SQLCompiler.js'

    const infoField = {
        orderNum: '工单编号',
        xcgc: '巡查工程',
        xccj: '巡查层级',
        createUser: '派单人员',
        createTime_view: '派单时间',
        isEnd_view: '工单状态'
    }

    export default {
        props: ['bh'],
        data() {
            return {
                info: [],
                process: []
            }
        },
        methods: {
            async getInfo() {
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'v_zhsw_workOrder',
                        page: 1,
                        limit: 10000,
                        strWhere: await SQLCompiler.encrypt(` del=0 and bh='${this.bh}' `),
                        filesFields: 'bh'
                    }
                }).then(async res => {
                    let { code, data } = res.data

                    if (code !== 0) return
                    let current = data[0]
                    // current.currentImgs = await this.getCurrentImgs(current.itembh)

                    let { createTime, isEnd } = current
                    current.createTime_view = createTime.slice(0, 10)
                    current.isEnd_view = isEnd === 2 ? '已办结' : '未办结'
                    
                    this.info = Object.keys(infoField).map(key => {
                        return {
                            name: infoField[key],
                            value: current[key]
                        }
                    })

                }).catch(err => err)
            },
            async getProcess() {
                return this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: 'v_zhsw_workProcess',
                        page: 1,
                        limit: 10000,
                        strWhere: await SQLCompiler.encrypt(` del=0 and orderbh='${this.bh}' `),
                        sort: await SQLCompiler.encrypt('id asc'),
                        filesFields: 'bh'
                    }
                }).then(res => {
                    let { code, data } = res.data

                    if (code !== 0) return
                    this.process = data.map(d => {
                        d.createTime_view = d.createTime.slice(0, 10)
                        d.rectime_view = d.rectime && d.rectime.slice(0, 10)
                        return d
                    })
                }).catch(err => err)
            },
            // async getCurrentImgs (bh) {
            //     return this.$axios({
            //         url: '/api/GetList/getListByTable',
            //         method: 'get',
            //         params: {
            //             tableName: 'v_slgcxczg_rcxcItem',
            //             page: 1,
            //             limit: 10000,
            //             strWhere: await SQLCompiler.encrypt(` bh='${bh}' `),
            //             filesFields: 'bh'
            //         },
            //     })
            //         .then(res => {
            //             let { code, data } = res.data
            //             if (code !== 0) return
            //             return data[0].files_bh
            //         })
            //         .catch(err => err)
            // }
        },
        created () {
            this.$nextTick(() => {
                this.getInfo()
                this.getProcess()
            })
        },
    }
</script>

<style lang="less" scoped>
    .timeline-item-row {
        margin-top: 3px;

        &:last-child {
            margin-top: 10px;
        }

        // 名称
        span:first-child {
            color:  #2db7f5;
        }

        // 内容
        span:last-child {
            color:  #333;
        }

        .current-img {
            margin-top: 5px;
            margin-right: 5px;
            width: 100px;
            height: 100px;
        }
    }

    .current-img-link {
        img {
            vertical-align: top;
            width: 100px;
            height: 100px;
        }

        &:not(:last-child) {
            margin-right: 5px;
        }
    }


</style>