<template>
    <div style=" position:relative;">
        <a-spin :tip="spin.message" :spinning="spin.spinning">
            <h1>{{bigtitle[iData.kind-1]}}</h1>
            <a-button style="width:100px; z-index:9999; position:absolute; top:5px; right:0px;" type="primary" @click="dcpdf" v-if="ifjy">导出</a-button>
            <a-button style="width:100px; z-index:9999; position:absolute; top:5px; left:0px;" type="" @click="()=>$router.go(-1)">返回</a-button>
            <div>
                <a-form layout="inline">
                    <div style="float:left;">
                        <a-form-item label="检查站闸">
                            {{iData.zzmc}}
                        </a-form-item>
                    </div>
                    <div style="float:right;">
                        <a-form-item label="检查人">
                            {{iData.createUser}}
                        </a-form-item>
                        <a-form-item label="检查时间">
                            {{iData.createTime}}
                        </a-form-item>
                    </div>
                    <div style="clear:both;"></div>
                </a-form>
            </div>

            <template v-for="(item,index) in data">
                <a-card :title="item.dictName2" style="margin-top:12px;">
                    <!--<template v-for="(subItem,index) in item.test">
                <a-divider v-if="index>0" />
                <div>
                    <div style="float:left;">
                        <p>
                            照片：
                            <template v-for="(imgitem,index) in subItem.filesList">
                                <a target="_blank" :href="imgitem.url"><img :src="imgitem.url" style="height:100px; width:100px;" /></a>
                            </template>
                        </p>
                    </div>
                    <div style="float:right;">
                        <a-button type="danger" @click="del(subItem)">删除</a-button>
                    </div>
                </div>
            </template>-->
                    <!--<p v-if="item.test.length==0">无扣分</p>-->
                    <div></div>
                    <template v-for="(subItem,index1) in item.item">
                        <ul>
                            <li>
                                <div><b style="font-size:16px;">{{index1+1}}.{{subItem.info}}</b></div>
                                <input name="infobh" :value="subItem.infobh" type="hidden" />
                                <input name="info" :value="subItem.info" type="hidden" />
                                存在问题：<textarea name="r1" style="width:100%; height:70px;" :xmlx="subItem.xmlx" :xmbh="subItem.xmbh" maxlength="200">{{subItem.result1}}</textarea>
                                处理意见：<textarea name="r2" style="width:100%; height:70px;">{{subItem.result1}}</textarea>
                            </li>
                        </ul>
                    </template>
                </a-card>

            </template>
            <div style="margin-top:20px; text-align:center;">
                <a-button style="width:200px;" type="primary" @click="submit" v-if="!ifjy">提交</a-button>
            </div>
        </a-spin>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                data: [],
                dfData: [],
                iData: {},
                form: {
                    bh: this.$route.query.bh,
                    item: [],
                    kind: this.$route.query.kind
                },
                spin: {
                    message: "加载中...",
                    spinning: false,
                },
                action: this.$route.query.action,
                ifjy: false,
                bigtitle: ['站闸经常检查表', '站闸定期检查表'],
            };
        },
        computed: {

        },
        mounted() {
            this.initData();
            this.bindData();

            
            
        },
        created() {
        },
        updated() {
            if (this.action == "view") {
                this.ifjy = true;
                var r1 = document.getElementsByName("r1");
                var r2 = document.getElementsByName("r2");
                for (var i = 0; i < r1.length; i++) {
                    r1[i].setAttribute("disabled", this.ifjy);
                    r2[i].setAttribute("disabled", this.ifjy);
                }
            }
        },
        methods: {
            dcpdf() {
                this.spin.spinning = true;
                this.spin.message = "正在生成PDF，请稍候...";
                this.$axios({
                    url: this.$axios.defaults.fileURL + "/api/dcpages/htmlTopdf_dfc_rcxc_zz?bh=" + this.form.bh,
                    method: 'get',
                }).then(res => {
                    console.log(res.data.data);
                    //location.href = res.data;
                    this.spin.spinning = false;
                    window.open(res.data.data);
                }).catch(error => { // 请求失败处理
                    this.$message.error("生成失败");
                    this.spin.spinning = false;
                })
            },

            submit() {
                var r1 = document.getElementsByName("r1");
                var r2 = document.getElementsByName("r2");
                var infobh = document.getElementsByName("infobh");
                var info = document.getElementsByName("info");
                for (var i = 0; i < r1.length; i++) {
                    this.form.item.push({ infobh: infobh[i].value, info: info[i].value, result1: r1[i].value, result2: r2[i].value, xmlx: r1[i].getAttribute("xmlx"), xmbh: r1[i].getAttribute("xmbh") });
                }
                //console.log(this.form);
                //return false;
                this.spin.spinning = true;
                this.$axios({
                    url: '/api/selfxc/savexczz',
                    method: 'post',
                    data: this.form,
                }).then(res => {
                    if (res.data.code == 0) {
                        this.$message.info("保存成功");
                        this.spin.spinning = false;
                        this.$router.push("zzcheckList?kind=" + this.iData.kind);
                    }
                    else {
                    }
                }).catch((err) => {
                    this.spin.spinning = false;
                });
            },

            initData() {
                this.$axios({
                    url: "/api/GetList/get_v_dfc_selfxc_xczz_List",
                    method: "post",
                    data: { where: { bh: this.form.bh } }
                }).then((res) => {

                    if (res.data.code == 0) {
                        this.iData = res.data.data.list[0];
                    }
                }).catch((err) => { });

            },
            bindData() {
                this.$axios({
                    url: '/api/selfxc/dfc_selfxc_xczzItemGroup?pbh=' + this.form.bh,
                    method: 'get',
                }).then((res) => {
                    if (res.data.code == 0) {
                        this.data = res.data.data;
                    }
                }).catch(function (error) {
                    console.log(error)
                });
            },
            del(record) {
                var _this = this;
                this.$confirm({
                    title: '操作确认',
                    content: "确定要删除 " + record.kfyy + " 吗?",
                    okText: '确认',
                    cancelText: '取消',
                    onOk() {
                        _this.$axios({
                            url: '/api/Comon/deltag',
                            method: 'post',
                            data: {
                                tableName: 'zzgl_xcgl_test',
                                field: 'id',
                                value: record.id,
                                source: 'web',
                                loginid: ''
                            }
                        }).then(res => {
                            if (res.status == 200) {
                                if (res.data.code == 0) {
                                    _this.$message.info('删除成功');
                                    _this.bindData();
                                }
                                else {
                                    throw new Error("服务器超时");
                                }
                            }
                            else {
                                throw new Error("服务器超时");
                            }
                        }).catch(error => { // 请求失败处理
                            _this.$message.error(error.message);
                        });
                    }
                });



            }
        },
    };
</script>
<style scoped>
    h1 {
        font-size: 24px;
        text-align: center;
    }

    h2 {
        text-align: right;
    }
</style>