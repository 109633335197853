<template>
    <div style="width:100%; height:100%;">
        <div id="MyMap" style="width:100%; height:100%;"></div>
    </div>
</template>
<script>
    import * as esriLoader from 'esri-loader'

    var locationPath = location.pathname.replace(/\/[^\/]+$/, "");
    //var options = {
    //    url: 'http://118.31.71.54:8090/library/4.15/init.js',
    //        dojoConfig: {
    //    async: true,
    //        packages: [
    //            {
    //                name: "app",
    //                location: "http://118.31.71.54:8044/v0/app"
    //            },
    //             {
    //               name:"app",
    //               location:""
    //             }
    //        ]
    //    }
    //}

    esriLoader.loadScript({
        url: 'http://118.31.71.54:8090/library/4.15/init.js',
        dojoConfig: {
            async: true,
            packages: [
                {
                    name: "app",
                    location: "http://118.31.71.54:8044/v0/app"
                },
                // {
                //   name:"app",
                //   location:""
                // }
            ]
        }
    });
    esriLoader.loadCss('http://118.31.71.54:8090/library/4.15/esri/themes/light/main.css');
    esriLoader.loadCss('http://118.31.71.54:8044/v0/GRSHGISLibs.css');



    export default {
        data() {
            return {

            }
        },
        created: function () {
            esriLoader.loadModules(
                [
                    "http://118.31.71.54:8044/v0/app/MapInitial.js", "http://118.31.71.54:8044/v0/app/KSSmartWater/ksSWInitial.js", "http://118.31.71.54:8044/v0/app/KSSmartWater/test.js", "http://118.31.71.54:8091/KSSmartWaterOneMapAPI/v1/app/GLayer/PntFtLayerExt.js"
                ]).then(
                    ([
                        MI, ksSWInitial, test, PntFtLayerExt
                    ]) => {
                        var mi = new MI({
                            container: "MyMap",
                            useBSW: false,
                            mapsource: 1,
                            maptype: 1,
                            OMKey: "11",
                            // view_click_handler:function(arg){
                            //   console.log(arg);
                            // }
                        });
                        mi.initialAllCommonWidget();  //初始化所有Widget
                        var xcw = new ksSWInitial({
                            mi: mi
                        });
                        xcw.initial();  //智慧水利相关功能初始化
                        //xcw.setAllBaseSubLayerVisibility(false); //关闭所有水系基础图层

                        //// 使用pntLayerExt扩展图层站点
                        //var tstUrl = 'http://118.31.71.54:8054/api/TestDataSample/GetSamplePnt';  //此为测试点url,返回json数组
                        //var pntFTLYR = new PntFtLayerExt({
                        //    mi: mi,  //传递地图初始化对象
                        //    id: 'layerId5',  //图层id,图层的唯一描述,可根据自己的需要传递
                        //    dataUrl: tstUrl,  //数据url
                        //    // data:[{Longtitude:120,Latitude:31},{Longtitude:120.5,Latitude:31.5}],   //数据 该项羽dataUrl二选一.此处直接传递数组
                        //    x_Field: 'Longtitude',  //用于展点的X字段
                        //    y_Field: 'Latitude',  //用于展点的y字段
                        //    markerPicUrl: "https://static.arcgis.com/images/Symbols/Shapes/BlackStarLargeB.png",   //地图图标url
                        //    // markerPicUrl: '../../images/tst.gif',
                        //    // renderer: uqr,  //地图渲染器 非专业用户不需要使用该属性
                        //    enableCluster: true,  //是否开启聚合功能
                        //    // labelNm: 'PrjType',
                        //    // labelTxtSymbol: SymbolAssist.GetTxtSymbol({
                        //    //     fontNm: 'Josefin Slab',
                        //    // }),

                        //    title: '测试图层',  //图层标题
                        //});

                    })
        }
    }
</script>
<style>
    .ant-layout-content {
        padding: 0px !important;
        margin: 0px !important;
    }
</style>
