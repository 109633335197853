import * as esriLoader from 'esri-loader'

esriLoader.loadScript({
    url: 'http://180.97.207.33:8091/arcgis_js_api/library/4.17/init.js',
    dojoConfig: {
        async: true,
        packages: [{
            name: 'app',
            location: 'http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/app'
        }]
    }
});

esriLoader.loadCss('http://180.97.207.33:8091/arcgis_js_api/library/4.17/esri/themes/light/main.css')
esriLoader.loadCss('http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/GRSHGISLibs.css')

let loadModulesUrl = [
    'http://180.97.207.33:8091/arcgis_js_api/library/4.17/esri/renderers/ClassBreaksRenderer.js',
    'http://180.97.207.33:8091/arcgis_js_api/library/4.17/esri/symbols/PictureMarkerSymbol.js',
    'http://180.97.207.33:8091/arcgis_js_api/library/4.17/esri/symbols/TextSymbol.js',
    'http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/app/MapInitial.js',
    'http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/app/KSSmartWater/ksSWInitial.js',
    'http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/app/test/pntlyrext2.js',
    'http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/app/Assist/GeometryAssist.js',
    'http://180.97.207.33:8091/KSSmartWaterOneMapAPI/v1.9/app/Assist/GraphicsAssist.js',
]

export function loadMap() {
    return new Promise(resolve => {
        esriLoader.loadModules(loadModulesUrl)
            .then(([MapInitial, ksSWInitial, pntlyrext2, ClassBreaksRenderer, PictureMarkerSymbol, TextSymbol, GeometryAssist, GraphicsAssist]) => {
                resolve({
                    MapInitial, 
                    ksSWInitial,
                    pntlyrext2,
                    ClassBreaksRenderer,
                    PictureMarkerSymbol,
                    TextSymbol,
                    GeometryAssist, 
                    GraphicsAssist
                })
            })
    })
}