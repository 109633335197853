<template>
    <layout-content-with-drawer :sider-width="1095">

        <!-- 侧边栏  -->
        <template slot="sider">
            <!-- 搜索 -->
            <a-form :form="form" layout="vertical"  :wrapperCol="{span: 23}" >
                <a-row>
                    <a-col :span="8">
                        <a-form-item label="工单号">
                            <a-input v-decorator="['orderNum']" autocomplete="off" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item label="巡查标题">
                            <a-input v-decorator="['xctitle']" autocomplete="off"  />
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item label="巡查工程">
                            <a-input v-decorator="['xcgc']" autocomplete="off"  />
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item label="巡查人员">
                            <a-input v-decorator="['xcr']" autocomplete="off"  />
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item label="巡查时间">
                            <a-range-picker v-decorator="['xcsj']" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="8">
                        <a-form-item label="工单状态">
                            <a-select v-decorator="['isEnd']">
                                <a-select-option value="value">
                                    已办结
                                </a-select-option>
                                <a-select-option value="not">
                                    未办结
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                    <!-- 搜索按钮组 -->
                    <a-col :span="24" >
                        <a-form-item style="textAlign:right;">
                            <a-space >
                                <a-button type="primary" icon="search" @click="onSearch">搜索</a-button>
                                <a-button icon="redo" @click="onReset">重置</a-button>
                            </a-space>
                        </a-form-item>
                    </a-col>

                </a-row>
            </a-form>

            <!-- 列表 -->
            <a-button 
                icon="plus-square" 
                type="primary" 
                style="marginBottom:10px;" 
                @click="$router.push('./patrol_add')">
                新增
            </a-button>
            <a-table
                class="cover-table"
                rowKey="id"
                size="small"
                :columns="columns" 
                :data-source="dataSource"
                :scroll="scroll"
                :customRow="customRow"
                :rowClassName="rowClassName"
                :pagination="false">
                
                <!-- 操作按钮组 -->
                <template slot="action" slot-scope="record">
                    <a-space>
                        <a-button 
                            size="small"
                            
                            style="backgroundColor: #2095FF;color:#fff"
                            @click.stop="openModal(record)">
                            详情
                        </a-button>
                        <a-button 
                            size="small"
                            style="backgroundColor: royalblue;color:#fff"
                            :style="{opacity: record.isDispatch_view ? 1 : .3}"
                            :disabled="!record.isDispatch_view"
                            @click.stop="dispatch(record)">
                            派单
                        </a-button>
                        <a-button 
                            size="small"
                            style="backgroundColor: teal;color:#fff"
                            :style="{opacity: record.isReply_view ? 1 : .3}"
                            :disabled="!record.isReply_view"
                            @click.stop="assign(record)">
                            回复
                        </a-button>
                        <a-button 
                            size="small" 
                            style="backgroundColor: darkorange;color:#fff"
                            :style="{opacity: record.isReview_view ? 1 : .3}"
                            :disabled="!record.isReview_view"
                            @click.stop="review(record)">
                            审批
                        </a-button>
                    </a-space>
                </template>
            </a-table>

            <a-pagination
                class="cover-pagination"
                :currrent="pagination.current"
                :total="pagination.total"
                :show-total="total => `总共 ${total} 条`"
                :page-size="pagination.pageSize"
                @change="changePage"
            />
        </template>

        <template slot="content">
            <div class="wh-100p" id="container"></div>
                <!-- <el-amap class="amap-box" :vid="'amap-vue'"></el-amap> -->
                <!-- <a-alert class="alert" :message="pointName" type="info"/> -->
        </template>

        <template slot="extra">
            <!-- 详情 -->
            <a-modal 
                class="cover-modal"
                v-model="showing"
                title="总览"
                :centered="true"
                :footer="null"
                :width="820"
                :destroyOnClose="true"
                @cancel="showing = false">

                <div class="modal-content">
                    <a-space direction="vertical">
                        <patrol-info :bh="bhGroup[0]"></patrol-info>
                        <order-info :bh="bhGroup[1]"></order-info>
                    </a-space>
                </div>

            </a-modal>
        </template>

    </layout-content-with-drawer>
</template>

<script>
    import SQLCompiler from '@/assets/js/SQLCompiler.js'
    import user from '@/user';

    import LayoutContentWithDrawer from '@/components/LayoutContentWithDrawer'
    import PatrolInfo from '../common/PatrolInfo'
    import OrderInfo from '../common/OrderInfo'

    const columns = [
        {   
            title: '序号',
            dataIndex: 'rownum',
            width: 80,
            align: 'center'
        },
        {
            title: '工单号',
            dataIndex: 'orderNum_view',
            width: 120
        },
        {
            title: '巡查标题',
            dataIndex: 'xctitle',
            ellipsis: true
        },
        {
            title: '巡查工程',
            dataIndex: 'xcgc',
            ellipsis: true
        },
        {
            title: '巡查人员',
            dataIndex: 'xcr',
            align: 'center',
            width: 120,
        },
        {
            title: '巡查时间',
            dataIndex: 'xcsj_view',
            width: 120,
        },
        {
            title: '工单状态',
            dataIndex: 'isEnd_view',
            width: 100
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 230,
            scopedSlots: { customRender: 'action' }
        }
    ]

    const SQLOption = {
        'orderNum': 'like',
        'xctitle': 'like',
        'xcgc': 'like',
        'xcr': 'like',
        'xcsj': 'range',
        'isEnd': 'value'
    }

    export default {
        components: {
            LayoutContentWithDrawer,
            PatrolInfo,
            OrderInfo
        },
        data () {
            return {
                dynamicBtns: [],
                // 地图
                map: {},
                pointName: '巡查工程',
                // form
                form: this.$form.createForm(this, { name: 'cond' }),
                // table
                columns,
                dataSource: [],
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                    showTotal: total => `共 ${total} 条`
                },
                scroll: { 
                    x: '125%', 
                    y: 0,
                },
                // 对话框
                showing: false,
                bhGroup: []
            }
        },
        methods: {
            // initMap () {
            //     loadMap().then(({ MapInitial, ksSWInitial, GeometryAssist, GraphicsAssist }) => {
            //         let mi = new MapInitial({
            //             zoom: 9,
            //             center_lng: 120.9808441,
            //             center_lat: 31.34081901,
            //             container: "map",
            //             maptype: 1,
            //             OMKey: "11"
            //         })

            //         let xcw = new ksSWInitial({ mi })
            //         xcw.initial();  //智慧水利相关功能初始化
            //         xcw.setAllBaseSubLayerVisibility(false) //关闭所有水系基础图层
            //         xcw.setAllDBSubLayerVisibility(false) //关闭所有业务图层
            //         xcw.setBaseWaterSubLayerVisibility('River', true) //打开河道图层，配置为false，则关闭河道图层，第一个参数为指代图层的target参数
            //         xcw.setBaseWaterSubLayerVisibility('Lake', true) //打开湖泊图层
            //         xcw.setBaseWaterSubLayerVisibility('TownBoundary', true) //打开乡镇界线图层
            //         xcw.setBoundary('昆山市')

            //         this.map = {
            //             mi,
            //             GeometryAssist,
            //             GraphicsAssist
            //         }
            //     })
            // },
            validCond() {
                return this.form.isFieldsTouched()
            },
            getCond () {
                let field = this.form.getFieldsValue()

                 // 处理时间格式
                if (field.xcsj) {
                    let start = field.xcsj[0].format('YYYY-MM-DD')
                    let end = field.xcsj[1].format('YYYY-MM-DD') + ' 23:59:59'
                    field.xcsj = { start, end }
                }

                // 状态
                if (field.isEnd) {
                    SQLOption['isEnd'] = field.isEnd
                    field.isEnd = 2
                }

                return field
            },
            clearCond () {
                this.form.resetFields()
            },
            onSearch () {
                if (!this.validCond()) return
                this.pagination.current = 1
                this.getDataSource()
            },
            onReset () {
                if (!this.validCond()) return
                this.clearCond()
                this.pagination.current = 1
                this.getDataSource()
            },
            openModal (record) {
                this.showing = true
                this.bhGroup = [record.bh, record.orderbh]
            },
            dispatch (record) {
                this.$router.push({
                    path: './patrol_dispath', 
                    query: { infobh: record.infobh, bh: record.bh }
                })
            },
            assign (record) {
                this.$router.push({
                    path: './WorkOrderRecord', 
                    query: { orderbh: record.orderbh, bh: record.currentProcessbh, status: 1 }
                })
            },
            review (record) {
                this.$router.push({
                    path: './WorkOrderRecord', 
                    query: { orderbh: record.orderbh, bh: record.currentProcessbh, status: 2 }
                })
            },
            rowClassName (record, index) {
                let className = "dark-row"
                if (index % 2 === 1) className = "light-row"
                return className
            },
            customRow (record) {
                return {
                    on: {
                        click: () => {
                            this.drawPoint(record)
                        }
                    }
                }
            },
            changePage (page) {
                this.pagination.current = page
                this.getDataSource()
            },
            initMap () {
                let AMap = this.$map
                new AMap.Geocoder().getLocation('昆山', (status, result) => {
                        let { lat, lng } = result.geocodes[0].location
                        let map = new AMap.Map('container', {
                            zoom: 10,
                            center: [lng, lat]
                        })

                        this.map = map

                        

                        map.addControl(new AMap.ToolBar({
                            visible: true,
                            position: 'RB'
                        }))
                        
                        // 区域
                        let district = new AMap.DistrictSearch({
                            level: 'biz_area' ,
                            extensions: 'all',  
                            subdistrict: 0, 
                        })

                        district.search('昆山', (status, result) => {
                            let polygons = []
                            let bounds = result.districtList[0].boundaries

                            if (bounds) {
                                bounds.forEach(item => {
                                    polygons.push(new AMap.Polygon({
                                            strokeWeight: 1,
                                            path: item,
                                            fillOpacity: 0.4,
                                            fillColor: '#80d8ff',
                                            strokeColor: '#0091ea'
                                        })
                                    )
                                })

                                map.add(polygons)
                            }
                        })
                })
            },
            drawPoint ({ xcgc_x, xcgc_y, xcgc }) {
                let AMap = this.$map
                let position = [xcgc_x, xcgc_y]
                
                this.map.setZoomAndCenter(16, position)
                if (this.marker) this.map.remove(this.marker)
                this.marker = new AMap.Marker({
                    icon: "/img/point.png",
                    position
                })

                this.marker.setMap(this.map)

                let infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(16, -35)})
                this.marker.on('click', () => {
                    infoWindow.setContent(xcgc)
                    infoWindow.open(this.map, position)
                })
            },
            calcScrollY () {
                this.scroll.y = document.body.offsetHeight - 472
            },
            // ajax
            async getDataSource () {
                this.loading = true
                const { current, pageSize } = this.pagination

                const id = user.user.userInfo.loginid
                // const str = id === '21651643316433' ? '' : ``
                const [xccj, gclx] = this.$route.path.slice(-2)
                // const preSQL = `xccj='${['镇级', '市级'][xccj]}' and gclx='${['排涝站', '防洪闸', '堤防'][gclx]}' ${str}`
                const preSQL = `xccj='${['镇级', '市级'][xccj]}' and gclx='${['排涝站', '防洪闸', '堤防'][gclx]}' and (xcrloginid='${id}' or mycy=1)`
                this.$axios({
                    url: '/api/GetList/getListByTable',
                    method: 'get',
                    params: {
                        tableName: `f_slgcxczg_rcxcItem('${id}')`,
                        page: current,
                        limit: pageSize,
                        strWhere: await SQLCompiler.encrypt(this.getCond(), SQLOption, preSQL)
                    }
                })
                    .then(res => {
                        let { code, count, data } = res.data
                        if (code !== 0) return
                        
                        let xccj = this.$route.path.slice(-2, -1)

                        data.forEach(d => {
                            let { 
                                xcsj, 
                                orderNum, 
                                isEnd, 
                                currentrecLoginid, 
                                currentStep 
                            } = d
                            d.xcsj_view = xcsj.slice(0, 10)
                            d.orderNum_view = orderNum ? orderNum : '无'
                            d.isEnd_view = isEnd === 2 ? '已办结': '未办结'
                            d.isReply_view = isEnd !== 2 && currentrecLoginid === id && currentStep === '派单'
                            d.isReview_view =  isEnd !== 2 && currentrecLoginid === id && currentStep === '办结申请'
                            // d.isDispatch_view = isEnd === -1 && id === '21651643316433'
                            d.isDispatch_view = this.dynamicBtns[0].isShow === 1 && isEnd === -1
                            // d.isDispatch_view = isEnd === -1
                        })
                        this.dataSource = data
                        this.pagination.total = count
                        this.$emit('loadend')
                    })
                    .catch(err => err)
                    .finally(() =>  this.loading = false)
            }
        },
        watch: {
            $route: {  
                handler () {
                    this.dynamicBtns = this.$route.meta.buttons
                    this.getDataSource()
                    this.initMap()
                    this.onReset()
                }, 
                immediate: true
            }
        },
        created () {
            this.initMap()
        },
        mounted () {
            this.calcScrollY()
            window.addEventListener('resize', this._.debounce(this.calcScrollY, 150))
            this.$once('hook:beforeDestroy', () => window.removeEventListener('resize', this.calcScrollY))
        }
    }
</script>

<style lang="less" scoped>

    // 覆盖form样式
    /deep/ .ant-form {
        margin-bottom: 15px;
        .ant-form-item {
            margin-bottom: 4px;
        }
    }

    // 隔行变色
    /deep/ .light-row { 
        background-color: #fff;
    }
    /deep/ .dark-row {
        background-color: #fafafa;
    }

    .cover-pagination::v-deep {
        position: absolute;
        right: 10px;
        bottom: 0;
        min-width: 432px;
    }

    // 地图
    #map {
        position: relative;

        .alert {
            position: absolute;
            top: 25px;
            right: 25px;
            z-index: 999;
        }
    }

    // 总览对话框
    .cover-modal::v-deep {
        .ant-modal-header {
            background: #1890FF;
        }
    }

    .modal-content {
        padding: 0 15px;
        height: 450px;
        overflow-y: auto;
    }

</style>