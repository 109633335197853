<template>
    <div id="components-form-demo-advanced-search">

        <div style="text-align:center;">
            <span style="font-size:25px;font-weight:bold;">{{this.$route.query.titles}}</span>
        </div>
        <div>
            <a-button type='normal' @click="back" style='margin: 5px'>返回</a-button>
            <table width="100%" style="text-align:center;" id="tab1">
                <tr v-if="index==0" v-for="(item,index) in tableData" style="background:#fafafa;height:57px;" class="trCss_header">
                    <td rowspan="2">{{item.name}}</td>
                    <td v-for="item2 in item.value" colspan="2">{{item2.wzmc}}</td>
                </tr>
                <tr class="trCss">
                    <td v-for="(item,index) in tableData[0].value.length*2" class="tdCss">
                        <span v-if="index%2==0">计划</span>
                        <span v-else>现存</span>
                    </td>
                </tr>
                <tr v-if="index>0" v-for="(item,index) in tableData" class="trCss">
                    <td class="tdCss">{{item.name}}</td>
                    <template v-for="(item2,index2) in item.value">
                        <td class="tdCss">
                            <span>{{item2.wzsl}}</span>
                        </td>
                        <td class="tdCss">
                            <span v-if="item2.xysl>=item2.wzsl">{{item2.xysl}}</span>
                            <span v-else style="color:red;">{{item2.xysl}}</span>
                        </td>
                    </template>
                </tr>
                <tr>
                    <td class="tdCss" style="border-bottom: solid 1px #e8e8e8">总计</td>
                    <td class="tdCss" style="border-bottom: solid 1px #e8e8e8" v-for="item in zjData">{{item}}</td>
                </tr>
            </table>
        </div>

    </div>
</template>
<script>
    const loading = true
    const strWhere = 'del=0'
    const action = 'add'
    export default {
        data() {
            return {
                showexport: false,
                tr_hj:[],
                scroll: {
                },
                view_fields: {
                    viewModel: false,
                    title: '检查项',
                    data: []
                },
                opration: '',
                townData: [],
                zzData: [],
                xmlxData: [],
                zjData: [],
                qz_ifshow: false,
                qzLoginid: '',
                strWhere2: '',
                isOpen: 0,
                date_week: false,
                date_month: false,
                switch_isbool:true,
                N_max: 10000,
                town_zzmc: '',
                //actionList: {
                //    add: false,
                //    remove: false,
                //    edit: false
                //},
                teamData: [],
                buttons: [],
                inlineButtons: [],
                actionArry: [],
                tableData: [],
                loading,
                action,
                previewVisible: false,
                previewImage: '',
                fileList: [],
                strWhere,
                expand: false,
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false,
                    dateVisible: false,
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formSearch: this.$form.createForm(this, { name: 'advanced_search' }),
                formAdd: this.$form.createForm(this, { name: 'advanced_search11' }),
                pagination: {
                    total: 0,
                    pageSize: 10, // 每页中显示10条数据
                    current: 1,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
                    showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
                    position: 'bottom'
                }
            }
        },
        mounted() {
            //将上面的按钮和表格内的按钮分出来
            console.log(this.$route.meta.buttons);
            for (let i = 0; i < this.$route.meta.buttons.length; i++) {
                if (this.$route.meta.buttons[i].tag == "inline") {
                    this.inlineButtons.push(this.$route.meta.buttons[i]);
                }
                else {
                    this.buttons.push(this.$route.meta.buttons[i]);
                }
            }
            this.init()
            this.actions()
            window.onresize = () => {
                this.init()
            }
            this.bindList();
        },
        created() {
        },
        methods: {
            bindzj(tableData) {
                var valueArr = [];
                for (var i = 1; i < tableData.length; i++) {
                    valueArr.push(tableData[i].value);
                }
                var valueLen = valueArr[0].length;
                for (var i = 0; i < valueLen; i++) {
                    var jhsl = 0;
                    var xysl = 0;
                    for (var j = 0; j < valueArr.length; j++) {
                        //console.log(valueArr[j][i].town + valueArr[j][i].wzmc + "//////" + "//////" + valueArr[j][i].wzsl + "//////" + valueArr[j][i].xysl);
                        jhsl += valueArr[j][i].wzsl;
                        xysl += valueArr[j][i].xysl;
                    }
                    this.zjData.push(jhsl);
                    this.zjData.push(xysl);
                }
                console.log(this.zjData);
            },
            bindList: async function () {
                this.formSearch.validateFields((error, values) => {
                    error = 1
                    // console.log(values)
                    this.strWhere = 'del=0 '
                    for (var obj in values) {
                        if (values[obj]) {
                            this.strWhere += " and " + obj + " like '%" + values[obj] + "%'"
                        }
                    }
                    this.strWhere = this.strWhere.replace(/'/g, 'zhangbiao')
                    console.log(this.strWhere);
                })
                this.loading = true

                this.strWhere = await this.$jsc.DesEncrypt(this, this.strWhere);
                this.strWhere = this.strWhere.data;
                await this.InitGrid()
            },
            InitGrid: function () {
                var _that = this
                _that.loading = true
                console.log(_that.strWhere);
                this.$axios({
                    url: '/api/fxjc/GetWzCbXc?planbh='+this.$route.query.planbh,
                    method: 'get',
                })
                    .then(function (response) {
                        console.log(response.data.data); //return; 
                        _that.loading = false
                        if (response.status === 200) {
                            _that.tableData = response.data.data
                            _that.pagination.total = response.data.count
                            _that.bindzj(response.data.data);
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            onRangePickerChange(date, dateString) {
                this.StartTime = dateString[0];
                this.EndTime = dateString[1];
            },
            onShowSizeChange(current, pageSize) {
                console.log(current, pageSize);
                return;
            },
            formSearchSubmit(e) {
                e.preventDefault()
                this.pagination.current = 1
                this.bindList()
            },
            formSearchReset() {
                this.formSearch.resetFields()
                this.strWhere = 'del=0'
                this.bindList()
            },
            toggle() {
                this.expand = !this.expand
                this.init()
            },
            pageCurrentChange(pagination) {
                console.log(pagination);
                //return;
                this.pagination.current = pagination.current
                this.bindList()
            },
            back() {
                window.history.go(-1);
            },
            filesCancelPreview() {
                this.previewVisible = false
            },
            async filesPreview(file) {
                //console.log(file.ext)
                // if (!file.url && !file.preview) {
                //   file.preview = await getBase64(file.originFileObj)
                // }
                // this.previewImage = file.url || file.preview
                // this.previewVisible = true
                location.href = file.url
            },
            filesChange: function (file) {
                if (file.file.status === 'removed') {
                    this.fileList = file.fileList
                }
            },
            customFilesUpload(data) {
                const formData = new FormData()
                formData.append('file', data.file)
                //formData.append('token', 'aiufpaidfupipiu')// 随便写一个token示例
                this.saveFile(formData)
            },
            saveFile(formData) {
                this.$axios(
                    {
                        method: 'post',
                        url: this.$axios.defaults.fileURL + '/api/FileSet/Upload',
                        data: formData
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            var num = this.fileList.length
                            this.fileList.push(res.data.data)
                            this.uploadgetText('a', num, res.data);
                        }
                        else {
                            throw new Error("服务器器超时");
                        }
                    })
                    .catch((error) => {
                        this.$message.error(error.message);
                        console.log(error)
                    })
            },
            init() {
                var that = this
                size()
                function size() {
                    var innerHeight = window.innerHeight || document.body.innerHeight
                    that.$nextTick(() => {
                        var el = document.querySelector('#components-form-demo-advanced-search>form')
                        if (el) {
                            that.scroll.y = innerHeight - getComputedStyle(el).height.split('p')[0] - 270
                        }
                    })
                }
            },
            /**
             * @param {String} classname；upload的类名
             */
            editgetText(classname) {
                this.$nextTick(() => {
                    var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    //console.log(els)
                    for (var i = 0, len = els.length; i < len; i++) {
                        var type = els[i].textContent.split('.')[1]
                        if (type !== 'png' && type !== 'jpg') {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = els[i].textContent
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + type
                            }
                            cel.innerText = temptext
                            spanels[i].prepend(cel)
                        }
                    }
                })
            },
            /**
             * @param {String} classname；upload的类名
             * @param {Number} num；原有的已上传文件数量
             * @param {Object} response：新上传的后台返回数据，必须包含ext属性和name属性，name表示文件名，ext表示文件类型
             */
            uploadgetText(classname, num, response) {
                this.$nextTick(() => {
                    //var els = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info>span')
                    var spanels = document.querySelectorAll('.' + classname + ' .ant-upload-list-picture-card-container>span>.ant-upload-list-item>.ant-upload-list-item-info')
                    var tempNodeList = []
                    for (var i = 0; i < spanels.length - num; i++) {
                        // console.log(spanels[num+i])
                        tempNodeList.push(spanels[num + i])
                        for (var j = 0; j < tempNodeList.length; j++) {
                            var cel = document.createElement('span')
                            cel.style.display = 'block'
                            var temptext = response.name
                            if (temptext.length > 8) {
                                temptext = temptext.substr(0, 3) + '...' + response.ext
                            }
                            cel.innerText = temptext
                            tempNodeList[j].prepend(cel)
                        }
                    }
                })
            },
            actions() {
                //if (this.inlineButtons.length == 0) {
                //    columns.pop();
                //}
            }
        }
    }

    //function getBase64(file) {
    //    return new Promise((resolve, reject) => {
    //        const reader = new FileReader()
    //        reader.readAsDataURL(file)
    //        reader.onload = () => resolve(reader.result)
    //        reader.onerror = error => reject(error)
    //    })
    //}
</script>
<style>
    .ant-layout-footer {
        display: none;
    }

    #components-form-demo-advanced-search {
        background: white;
        box-sizing: border-box;
        padding: 20px 20px 0;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }

    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
        display: !important;
    }

    /*.ant-upload-list-picture-card .ant-upload-list-item-name {
      display: block !important;
    }*/

    .ant-upload-list-picture-card .ant-upload-list-item-name {
        margin: 1px 1px 1px 1px !important;
    }
    .ant-input1 {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-variant: tabular-nums;
        list-style: none;
        font-feature-settings: 'tnum';
        position: relative;
        display: inline-block;
        width: 20%;
        height: 32px;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        line-height: 1.5;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        transition: all 0.3s;
        width: 100px;
    }
    input:focus {
        outline: none;
        border: 1px solid #1890ff;
    }
    .trCss {
        border-bottom: 1px solid #e8e8e8;
    }
    table > tr > td {
        padding: 10px 10px;
        overflow-wrap: break-word;
    }
    .wz_td {
        width: 150px;
        height: 57px;
    }
    .tdCss {
        border-right: solid 1px #e8e8e8;
        border-left: solid 1px #e8e8e8;
    }
    .trCss_header {
        border-bottom: 1px solid #e8e8e8;
    }
    .trCss_header td {
        border-right: 1px solid #e8e8e8;
        border-top: 1px solid #e8e8e8;
    }
    .trCss_header td:nth-child(1) {
        border-left: 1px solid #e8e8e8;
    }
</style>