<template>
    <div>
        <a-modal v-model="formAddOptions.visible" :title="formAddOptions.title" width="1100px">
            <a-spin :spinning="formAddOptions.spinning">
                <h1 style="text-align:center;">站闸看护人员百分考核表</h1>
                <a-form :form="formAdd"
                        :label-col="{ span: 7 }"
                        :wrapper-col="{ span: 17 }"
                        labelAlign="right">
                    <!-- <a-form-item label="" style="display:">
      <a-input v-decorator="['id']" /> -->
                    <!--<a-input v-decorator="['zzbh',{ initialValue:this.$route.query.bh }]" />
    <a-input v-decorator="['townId',{ initialValue:this.$route.query.townId }]" />-->
                    <!-- </a-form-item> -->

                    <a-row type="flex" justify="space-between">
                        <a-col :span="10">
                            <a-form-item label="被考核人员: ">
                                <a-select v-decorator="['bkhrybh',{rules:[{required:true,message:'请选择被考核人员'}]}]" placeholder="请选择被考核人员" :disabled="formAddOptions.viewMode" style="width:100%" show-search option-filter-prop="children">
                                    <a-select-option v-for="item in xcryData" :value="item.bh">{{item.name}}-{{item.team}}-{{item.companyName}}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="7">
                            <a-form-item label="站闸: ">
                                <a-select v-decorator="['zzbh',{rules:[{required:true,message:'请选择站闸'}]}]" placeholder="请选择站闸" :disabled="formAddOptions.viewMode" style="width:100%" show-search option-filter-prop="children">
                                    <a-select-option v-for="item in zzData" :value="item.value">{{item.name}}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="7">
                            <a-form-item label="考核日期: ">
                                <a-date-picker  v-decorator="['khrq',{rules:[{required:true,message:'请选择考核日期'}]}]" placeholder="请选择考核日期" :disabled="formAddOptions.viewMode" style="width:100%"/>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row type="flex" justify="space-between">
                        <a-col :span="24">
                            
                        </a-col>
                    </a-row>


                    <div class="mytable">
                        <table class="aaa">
                            <tr>
                                <td align="center">
                                    序号
                                </td>
                                <td align="center">
                                    项目名称
                                </td>
                                <td>考核内容及标准</td>
                                <td align="center">分值</td>
                                <td align="center">扣分</td>
                                <td align="center">扣分原因</td>
                                <td align="center">得分</td>
                            </tr>
                            <tr v-for="(item,index) in tempData">
                                
                                <td align="center">{{index+1}}</td>
                                <td align="center">{{item.xmmc}}</td>
                                <td>{{item.message}}</td>
                                <td align="center">{{item.score}}</td>
                                <td width="100" align="center">
                                    
                                    <a-input placeholder="" v-model="kf[index]"
                                             :maxLength="40"
                                             :disabled="formAddOptions.viewMode" type="number" />
                                </td>
                                <td width="150">
                                    <a-input placeholder="" v-model="kfyy[index]"
                                             :maxLength="40"
                                             :disabled="formAddOptions.viewMode" type="number" />
                                </td>
                                <td align="center">{{dfData[index]}}</td>
                            </tr>
                            <tr>
                                <td align="center" colspan="3">
                                    合计
                                </td>
                                <td align="center">{{scoreall}}</td>
                                <td align="center">{{kfall}}</td>
                                <td align="center"></td>
                                <td align="center">{{dfall}}</td>
                            </tr>
                        </table>
                    </div>

                </a-form>
            </a-spin>
            <template slot="footer">
                <a-button key="back"
                          @click="
            formAddOptions.visible = false;
            formAddOptions.dateVisible = false;
          ">关闭</a-button>
                <a-button key="submit"
                          type="primary"
                          :loading="formAddOptions.submitLoading"
                          @click="formAddSubmit"
                          v-if="formAddOptions.submitVisible">确定</a-button>
            </template>

            
        </a-modal>

    </div>
</template>
<script>
    import moment from 'moment'
    export default {
        data() {
            return {
                formAddOptions: {
                    visible: false,
                    submitLoading: false,
                    submitVisible: true,
                    viewMode: false,
                    spinning: false,
                    title: "新增",
                },
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                formAdd: this.$form.createForm(this, { name: "formAdd" }),
                tempData: [],
                kf: [],
                kfyy: [],
                zzData: [],
                xcryData: [],
            };
        },
        computed: {
            dfData: function () {
                let temp = [];
                this.tempData.forEach((elem, index) => {
                    temp[index] = elem.score - this.kf[index];
                    if (isNaN(temp[index])) {
                        temp[index] = elem.score;
                    }
                });
                return temp;
            },
            scoreall: function () {
                let temp = 0;
                this.tempData.forEach((elem, index) => {
                    temp += elem.score;
                });
                return temp;
            },
            kfall: function () {
                let temp = 0;
                this.kf.forEach((elem, index) => {
                    temp += parseInt(elem);
                });
                return temp;
            },
            dfall: function () {
                let temp = 0;
                this.dfData.forEach((elem, index) => {
                    temp += parseInt(elem);
                });
                return temp;
            }

        },
        mounted() {
        
        },
        created() {
            this.$axios({
                url: '/api/Zzgl/getZzList',
                method: 'get',
            }).then((response) => {
                if (response.status === 200) {
                    this.zzData = response.data.data;
                }
            }).catch(function (error) {
                console.log(error)
            });

            this.$axios({
                url: '/api/GetList/getListByTable?tableName=v_zhsw_xcryxx&page=1&limit=1500&strWhere=&strFields=id,bh,companyName,team,name',
                method: 'get',

            }).then((response) => {
                if (response.status === 200) {
                    this.xcryData = response.data.data;
                }
            }).catch(function (error) {
                console.log(error)
            });
        },
        methods: {
            openDlg: async function (record, action) {
                var _this = this;
                this.formAdd.resetFields();
                this.fileList = [];
                this.formAddOptions.submitVisible = true;
                this.formAddOptions.viewMode = false;

                //取考核模块数据
                //var obj = await this.$jsc.DesEncrypt(this, "id=zhangbiao" + record.id + "zhangbiao");
                //obj = obj.data;
                this.$axios({
                    url: "/api/GetList/getListByTable?tableName=v_zzgl_xckh_Option&pageSize=1000",
                    method: 'get'
                }).then(rsp => {
                    this.tempData = rsp.data.data;
                }).catch(err => {
                })


                if (record !== undefined) {

                    // 从后台取数据赋值
                    this.formAddOptions.spinning = true;
                    var obj = await this.$jsc.DesEncrypt(
                        this,
                        "id=zhangbiao" + record.id + "zhangbiao"
                    );
                    obj = obj.data;

                    this.$axios
                        .get(
                            "/api/GetList/getListByTable?tableName=v_zzgl_xckh_Option&strWhere=" +
                            obj +
                            "&filesFields=bh"
                        )
                        .then((response) => {
                            if (response.status == 200) {
                                if (response.data.code == 0) {
                                    this.formAddOptions.visible = true;
                                    //  _that.loading = false;
                                    var result = response.data.data;
                                    setTimeout(() => {

                                        this.formAdd.setFieldsValue(result[0]);
                                        _this.check_ywba = result[0].isReport == 1 ? true : false;
                                        this.editgetText("a");
                                        this.formAddOptions.spinning = false;
                                    }, 100);
                                } else {
                                    this.$message.error(response.data.msg);
                                    this.formAddOptions.spinning = false;
                                }
                            } else {
                                // vue.prototype.$message.info('This is an error message');
                            }
                        }).catch(function (error) {
                            // 请求失败处理
                            console.log(error);
                        });
                    if (action === "view") {
                        // 预览模式
                        this.formAddOptions.submitVisible = false;
                        this.formAddOptions.viewMode = true;
                    }
                } else {
                    this.formAddOptions.visible = true;
                }
            },
            formAddSubmit() {
                //console.log(this.kf.length);
                //for (var i = 0; i < this.kf.length; i++) {
                //    console.log(i);
                //    console.log(this.kf[i]);
                //}
                this.formAdd.validateFields((err, values) => {
                    if (!err) {
                        console.log(values);
                        //this.formAddOptions.submitLoading = true;
                        //this.$axios({
                        //    url: "api/xckh/EditKhxxInfo",
                        //    method: "post",
                        //    data: values,
                        //}).then((res) => {
                        //    if (res.status == 200) {
                        //        if (res.data.code === 0) {
                        //            this.$message.info("保存成功");
                        //            this.formAddOptions.visible = false;
                        //            this.formAddOptions.submitLoading = false;
                        //            this.formAddOptions.dateVisible = false;
                        //            this.bindList();
                        //        } else {
                        //            throw new Error("服务器超时");
                        //        }
                        //    } else {
                        //        throw new Error("服务器超时2");
                        //    }
                        //}).catch((error) => {
                        //    // 请求失败处理
                        //    this.$message.error(error.message);
                        //    this.formAddOptions.submitLoading = false;
                        //});
                    }
                });
            },

        },
    };
</script>
<style scoped>

    .mytable table {
        border: 1px solid #cccccc;
        border-collapse: collapse;
        width:100% !important;
    }

    .mytable td {
        border: 1px solid #cccccc;
        padding: 6px
    }

    .mytable th {
        background: #F5F9FB;
        padding: 5px 6px;
        font-size: 14px;
    }

</style>