<template>
    <div>
        <patrol-info :bh="$route.query.bh"></patrol-info>

        <div style="marginTop:32px">
            <a-divider slot="header" orientation="left">
                <span style="color:#EC2FA2;">交办单位</span>
            </a-divider>
            <a-form :form="form" @submit.prevent="submit">
                <a-form-item label="交办单位">
                    <a-select v-decorator="['currentrecLoginid', { rules: [{ required: true, message: '请选择交办单位!' }] }]">
                        <a-select-option :value="item.value"  v-for="item in listSelect" :key="item.name">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="整改意见">
                    <a-textarea v-decorator="['infos', { rules: [{ required: true, message: '请输入整改意见' }] }]" :rows="4" style="resize:none;" />
                </a-form-item>

                <a-form-item label="处理时限">
                    <a-date-picker v-decorator="['clsx', { rules: [{ required: true, message: '请选择处理时限' }] }]" valueFormat="YYYY-MM-DD" />
                </a-form-item>

                <a-form-item>
                    <a-button type="primary" html-type="submit">提交</a-button>
                </a-form-item>

            </a-form>
        </div>
    </div>
</template>

<script>
    import PatrolInfo from '../common/PatrolInfo'

    export default {
        components: { PatrolInfo },
        data() {
            return {
                record: {},
                form: this.$form.createForm(this, { name: 'dispatch' }),
                listSelect: [],
            }
        },
        methods: {
            getUnit() {
                this.$axios({
                    url: '/api/smallpro/GetRcxcJbdx',
                    method: 'get',
                    params: {
                        pageIndex: 1,
                        pageSize: 10000
                    }
                }).then(res => {
                    let { code, data } = res.data
                    if (code !== 0) return
                    this.listSelect = data
                }).catch((err) => err)
            },
            submit () {
                this.form.validateFields((err, values) => {
                    if (err) return
                    let { currentrecLoginid, infos, clsx } = values
                    let { bh, infobh } = this.$route.query
                    this.$axios({
                        url: '/api/workorder/pd',
                        method: 'post',
                        data: {
                            infobh,
                            itembh: bh,
                            currentrecLoginid,
                            infos,
                            clsx,
                        }
                    }).then(res => {
                        let { code } = res.data
                        if (code === 0) {
                            this.$message.success("提交成功");
                            this.$router.go(-1)
                        }
                    }).catch(err => err)
                })
            }
        },
        created () {
            this.getUnit()
        },
    }
</script>

<style lang="less" scoped>
    /deep/ .ant-form {

        .ant-form-item {
            display: flex;

            .ant-form-item-label {
                width: 120px;
                text-align: left;
            }
            .ant-select,
            .ant-input {
                width: 218px !important;
            }


            .ant-btn[type="submit"] {
                margin-left: 120px;
            }
        }
    }
</style>