<template>
    <div class="loginpage rootbox">
        <a-spin tip="正在登入..." :spinning="spinning"></a-spin>
        <div class="qr">
            <img src="/img/slj_qr.png" alt="二维码">
            <h3>防汛防旱微信端</h3>
        </div>
        <div class="logo"><img src="../public/img/logo.png" /></div>
        <div class="loginwrap" v-if="!autologin">
            <div>用户名:</div>
            <div class="username">
                <input placeholder="请输入用户名" v-model="username" maxlength="16" />
                <img src="../public/img/user.png" />
            </div>
            <div>密码:</div>
            <div class="password">
                <input type="password" placeholder="请输入密码" v-model="password" maxlength="16" />
                <img src="../public/img/password.png" />
            </div>
            <div class="code">
                <input placeholder="请输入验证码" v-model="code" maxlength="4" />
                <div @click="freshcode">
                    <!-- <img :src='$store.state.siteInfo.codebaseUrl' /> -->
                    <img :src="src" width="100%" height="100%" />
                </div>
            </div>
            <button class="btnlogin" @click="login">登录</button>
        </div>
		<div v-else style="margin-top: 150px;text-align: center;">
			 <button class="btnlogin" >单点登录验证中，请稍后....</button>
		</div>
        <span class="footer">{{$store.state.siteInfo.belongDepart}}</span>

    </div>
</template>
<script>
    import { getAsyncRoutes } from './router.js'
    export default {
        data() {
            return {
                username: "",
                password: "",
                code: "",
                spinning: false,
                time: "",
                src: "",
                guid: "",
				autologin:false
            }
        },
		beforeCreate:function(){
			
			
		},
        created: function () {
			
			if(this.$route.query.token)
			{
				
			
			  this.autologin=true;
			
			  let pthis = this;
			  this.spinning = false;
			  this.$axios({
			      url: "/api/Template/GetTokenforsingle?singletoken=" + this.$route.query.token,
			      withCredentials: true,
			      method: 'get'
			  }).then(rsp => { // 第一个异步,取token
			      if (rsp.data.code == 0) {
			          this.$User.user.token = rsp.data.data;
			          return this.$axios.get("/api/Template/GetUserMenus?token=" + this.$User.user.token.access_token + "") //第一个异步成功调用成功，执行第二个异步，取用户菜单
			      }
			      else if (rsp.data.code == 2) {
			          throw new Error("用户名或密码错误！");
			      }
			      else if (rsp.data.code == 3) {
			          throw new Error("验证码错误！");
			      }
			      else if (rsp.data.code == 4) {
			          throw new Error("验证码已失效，请刷新页面！");
			      }
			      else {
			          throw new Error('单点登录'+rsp.data.msg);
			      }
			  }).then(rsp => {
			      this.$User.user.asyncRoutes = rsp.data.data;
			  
			      this.$router.addRoutes(getAsyncRoutes(rsp.data.data));//添加用户菜单到路由
			      return this.$axios.get("/api/Template/GetUser?token=" + this.$User.user.token.access_token + "") // 第二个异步成功调用成功，执行第三个异步，取用户信息
			  }).then(response => {
			      if (response.data.code == 0) {
			          response.data.data.RefreshTime = new Date();//记录当前刷新时间，用于记录最近操作时间，用于长时间不操作的情况下自动退出
			          this.$User.user.userInfo = response.data.data;
			          this.$axios.defaults.headers.common["Authorization"] = this.$User.user.token.access_token;
			          this.$message.success('登陆成功！');
					  if(this.$route.query.sysname)
					  {
						  if(this.$route.query.sysname=='环淀山湖水利工程综合管理系统')
						  {
						  						  this.$router.push({ path: "/gcyxgl/hdshsy/global_statistics" });
						  }else if(this.$route.query.sysname=='水葫芦抓拍系统')
						  {
						  						  this.$router.push({ path: "/gcyxgl/shlzp/cameraViewList?BindGroupName=水葫芦检测点位" });
						  }else if(this.$route.query.sysname=='河道视频抓拍系统')
						  {
						  						  this.$router.push({ path: "/gcyxgl/hdsp/cameraViewList?BindGroupName=城区新增摄像头" });
						  }else if(this.$route.query.sysname=='水工程巡查整改信息系统')
						  {
						  			this.$router.push({ path: "/gcyxgl/slgcxczgxt/patrolsystem_00" });
						  }else{
						  						   this.$router.push({ path: "/desk" });
						  }
					  }else{
						   this.$router.push({ path: "/desk" });
					  } 
					  
			         
			      }
			      else {
			          throw new Error("登陆失败！");
			      }
			  }).catch(err => {
			      this.$message.error(err.message);
			      this.spinning = false;
			  })
			}
			
			
            //this.guid = this.GUID();
            this.guid = new GUID().newGUID();
            this.src = this.$axios.defaults.baseURL + "/api/Template/get?time=" + new Date().getTime() + "&guid=" + this.guid
            var that = this;
            if (this.$User.user.token != "") {
                //this.$router.push({ path: "/desk" });
            }
            document.onkeydown = function (e) {   //按下回车提交
                let key = window.event.keyCode;
                if (key == 13) {
                    that.login();
                }
            };

            this.$axios({
                url: "/api/ComLogin/updatetm",
                method: 'get'
            }).then(rsp => {
                console.log(rsp);
            });
			
			
			
        },
        methods: {
            
            freshcode() {
                this.src = ""
                setTimeout(() => {
                    this.src = this.$axios.defaults.baseURL + "/api/Template/get?time=" + new Date().getTime() + "&guid=" + this.guid
                    this.code = ""
                })
            },
            login() {
                if (this.username == "") {
                    this.$message.warn("请输入用户名");
                    return false;
                }

                if (this.password == "") {
                    this.$message.warn("请输入密码");
                    return false;
                }

                if (this.code == "") {
                    this.$message.warn("请输入验证码");
                    return false;
                }
                //console.log(this.$User.user.asyncRoutes);
                let pthis = this;
                this.spinning = true;
                this.$axios({
                    url: "/api/Template/GetToken?username=" + this.username + "&password=" + this.password + "&verifycode=" + this.code +"&guid="+this.guid,
                    withCredentials: true,
                    method: 'get'
                }).then(rsp => { // 第一个异步,取token
                    if (rsp.data.code == 0) {
                        this.$User.user.token = rsp.data.data;
                        return this.$axios.get("/api/Template/GetUserMenus?token=" + this.$User.user.token.access_token + "") //第一个异步成功调用成功，执行第二个异步，取用户菜单
                    }
                    else if (rsp.data.code == 2) {
                        throw new Error("用户名或密码错误！");
                    }
                    else if (rsp.data.code == 3) {
                        throw new Error("验证码错误！");
                    }
                    else if (rsp.data.code == 4) {
                        throw new Error("验证码已失效，请刷新页面！");
                    }
                    else {
                        throw new Error("服务器超时！");
                    }
                }).then(rsp => {
                    this.$User.user.asyncRoutes = rsp.data.data;

                    this.$router.addRoutes(getAsyncRoutes(rsp.data.data));//添加用户菜单到路由
                    return this.$axios.get("/api/Template/GetUser?token=" + this.$User.user.token.access_token + "") // 第二个异步成功调用成功，执行第三个异步，取用户信息
                }).then(response => {
                    if (response.data.code == 0) {
                        response.data.data.RefreshTime = new Date();//记录当前刷新时间，用于记录最近操作时间，用于长时间不操作的情况下自动退出
                        this.$User.user.userInfo = response.data.data;
                        this.$axios.defaults.headers.common["Authorization"] = this.$User.user.token.access_token;
                        this.$message.success('登陆成功！');
                        this.$router.push({ path: "/desk" });
                    }
                    else {
                        throw new Error("登陆失败！");
                    }
                }).catch(err => {
                    this.$message.error(err.message);
                    this.spinning = false;
                })
            }
        }
    }


    function GUID() {
        this.date = new Date();
        let sexadecimalDate, sexadecimalTime;
        /* 判断是否初始化过，如果初始化过以下代码，则以下代码将不再执行，实际中只执行一次 */
        if (typeof this.newGUID != 'function') {

            /* 生成GUID码 */
            GUID.prototype.newGUID = function () {
                this.date = new Date();
                var guidStr = '';
                sexadecimalDate = this.hexadecimal(this.getGUIDDate(), 16);
                sexadecimalTime = this.hexadecimal(this.getGUIDTime(), 16);
                for (var i = 0; i < 9; i++) {
                    guidStr += Math.floor(Math.random() * 16).toString(16);
                }
                guidStr += sexadecimalDate;
                guidStr += sexadecimalTime;
                while (guidStr.length < 32) {
                    guidStr += Math.floor(Math.random() * 16).toString(16);
                }
                return this.formatGUID(guidStr);
            }

            /*
             * 功能：获取当前日期的GUID格式，即8位数的日期：19700101
             * 返回值：返回GUID日期格式的字条串
             */
            GUID.prototype.getGUIDDate = function () {
                return this.date.getFullYear() + this.addZero(this.date.getMonth() + 1) + this.addZero(this.date.getDay());
            }

            /*
             * 功能：获取当前时间的GUID格式，即8位数的时间，包括毫秒，毫秒为2位数：12300933
             * 返回值：返回GUID日期格式的字条串
             */
            GUID.prototype.getGUIDTime = function () {
                return this.addZero(this.date.getHours()) + this.addZero(this.date.getMinutes()) + this.addZero(this.date.getSeconds()) + this.addZero(parseInt(this.date.getMilliseconds() / 10));
            }

            /*
            * 功能: 为一位数的正整数前面添加0，如果是可以转成非NaN数字的字符串也可以实现
             * 参数: 参数表示准备再前面添加0的数字或可以转换成数字的字符串
             * 返回值: 如果符合条件，返回添加0后的字条串类型，否则返回自身的字符串
             */
            GUID.prototype.addZero = function (num) {
                if (Number(num).toString() != 'NaN' && num >= 0 && num < 10) {
                    return '0' + Math.floor(num);
                } else {
                    return num.toString();
                }
            }

            /* 
             * 功能：将y进制的数值，转换为x进制的数值
             * 参数：第1个参数表示欲转换的数值；第2个参数表示欲转换的进制；第3个参数可选，表示当前的进制数，如不写则为10
             * 返回值：返回转换后的字符串
             */
            GUID.prototype.hexadecimal = function (num, x, y) {
                if (y != undefined) {
                    return parseInt(num.toString(), y).toString(x);
                } else {
                    return parseInt(num.toString()).toString(x);
                }
            }

            /*
             * 功能：格式化32位的字符串为GUID模式的字符串
             * 参数：第1个参数表示32位的字符串
             * 返回值：标准GUID格式的字符串
             */
            GUID.prototype.formatGUID = function (guidStr) {
                var str1 = guidStr.slice(0, 8) + '-',
                    str2 = guidStr.slice(8, 12) + '-',
                    str3 = guidStr.slice(12, 16) + '-',
                    str4 = guidStr.slice(16, 20) + '-',
                    str5 = guidStr.slice(20);
                return str1 + str2 + str3 + str4 + str5;
            }
        }
    }
    
</script>
<style scoped lang="less">
    .rootbox {
        overflow: hidden;
    }

    .qr {
        margin: 10px 0 0 10px;
        img

    {
        width: 120px;
        height: 120px;
    }

    h3 {
        color: #3382FF;
    }

    }

    .loginpage {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url(../public/img/login-bg.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .logo {
        position: absolute;
        width: 804px;
        height: 84px;
        top: 100px;
        left: 50%;
        margin-left: -402px;
    }

        .logo > img {
            width: 100%;
        }

    .loginwrap {
        position: absolute;
        top: 240px;
        left: 50%;
        margin-left: -216px;
        width: 432px;
        height: 390px;
        background: rgba(128,128,128,.1);
        box-shadow: 2px 2px 10px 0px rgba(18, 64, 137, 0.2);
        border-radius: 6px;
        box-sizing: border-box;
        padding: 37px 44px 40px;
        color: white;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .username, .password {
        position: relative;
    }

        .username > input, .password > input, .code > input {
            height: 45.01px;
            border: none;
            font-size: 14px;
            border-radius: 4px;
            box-sizing: border-box;
            color: black;
        }

        .username > input, .password > input {
            width: 343.68px;
            padding-left: 45px;
        }

    .code > input {
        width: 246px;
        padding-left: 14px;
    }

    .username > img, .password > img {
        position: absolute;
        left: 13px;
        top: 13px;
        width: 20px;
        height: 20px;
    }

    .code {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

        .code > div {
            width: 83px;
            height: 47px;
        }

    .btnlogin {
        width: 344px;
        height: 50px;
        background: linear-gradient(90deg, #317DFF 0%, #54A2F6 100%);
        border-radius: 5px;
        border: none;
        color: white;
        font-size: 20px;
    }

    .footer {
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 34px;
        color: white;
        font-size: 14px;
    }

    .ant-spin-spinning {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        display: inline-block;
        opacity: 1;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-top: 250px;
        background: rgba(0,0,0,.5);
    }
</style>